import React, { useState } from "react";
import * as DASHBOARD_KEYS from "../../../../config/config";
const GraphFilterDropDown = (props) => {
  const data = props?.data?.length ? props?.data : [];
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const FILTER_KEYS = DASHBOARD_CONFIG_KEYS?.FILTERS;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {props?.data?.map((list) => {
        return (
          <div
            onClick={() => props?.handleFilterSelected(list)}
            className="dashboard-dropdown-filter-options-wrapper cursor--pointer"
          >
            <h6 className="dashboard-dropdown-filter-options-text">
              {list?.[FILTER_KEYS?.NAME]}
            </h6>
          </div>
        );
      })}
    </div>
  );
};

export default GraphFilterDropDown;

// {
//   name: "Today",
//   id: 1,
//   type: "date",
// },
