import React from "react";
import { useSelector } from "react-redux";
import {
  usePostRequestMutation,
  useUpdateNotificationStatusApiMutation,
} from "../../../adapters/xhr/generalApi";
import { useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import NotificationCard from "./notificationCard";
import * as NOTIFICATION_KEYS from "../../../config/config";
import { useEffect } from "react";
import CommentsSkeleton from "../../searchResult/components/skeleton/commentsSkeleton";
const NotificationList = (props) => {
  const list = props?.Data?.length ? props?.Data : [];
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const isLoading = props?.isLoading;
  const NOTIFICATION_KEYS_API =
    NOTIFICATION_KEYS?.CONFIG?.NOTIFICATION_API_KEYS;
  // const list = [
  //   {
  //     Description:
  //       "New Vendor added New Vendor added New Vendor added New Vendor added vNew Vendor addedNew Vendor addedNew Vendor addedNew Vendor addedNew Vendor added",
  //     Created_On: 1679569411,
  //     Path: "/proposals",
  //     Read: false,
  //   },
  //   {
  //     Description: "New Vendor added",
  //     Created_On: 1679569211,
  //     Path: "/proposals",
  //     Read: true,
  //   },
  // ];
  // seenNotifications/
  const seenNotification = useEnCryptSignalTrackingApi(
    `/seenNotifications`,
    "notifications",
    useUpdateNotificationStatusApiMutation
  );
  useEffect(() => {
    seenNotification.handleTrigger({
      User_Id: userId,
      NotificationIds: [],
    });
  }, [props?.count]);
  const handleNotificationRead = (val) => {
    props?.handleNotificationRed();
    seenNotification.handleTrigger({
      User_Id: userId,
      NotificationIds: [val?.[NOTIFICATION_KEYS_API?.NOTIFICATION_ID]],
    });
  };
  return (
    <div className="notification-list-main-wrapper">
      {isLoading ? (
        <CommentsSkeleton />
      ) : (
        <>
          {list?.length > 0 ? (
            list?.map((val, index) => {
              return (
                <NotificationCard
                  handleNotificationRead={handleNotificationRead}
                  data={val}
                  index={index}
                />
              );
            })
          ) : (
            <div className="notification-card-main-wrapper">
              <h3
                style={{ color: "#d3d3d3" }}
                className="notifications-header-main-title-text"
              >
                No notifications
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationList;
