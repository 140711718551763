import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import SignInModal from "../../../utlis/hoc/signInModal/signInModal";
import TabNavigator from "../../../utlis/hoc/tabNavigator/tabNavigator";
import { GetIdFormLink, ToTitleCase } from "../../../utlis/hof/hof";

const TopicHeader = (props) => {
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const navigate = useNavigate();
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const category = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  //   const GetApiData = useEnCryptGetApi(
  //     `/getProductCategoryDescription?id=${category?.Id}`,
  //     KEYS?.END_POINTS?.CORE?.END_POINT_URL,
  //     useGetRequestQuery
  //   );

  //   const categoryData = GetApiData?.data?.data;
  const handleManageProposal = () => {
    if (authData?.data?.userExist) {
      navigate("/proposals");
    } else {
      setSignInModalVisible((prev) => !prev);
    }
  };
  return (
    <>
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible((prev) => !prev)} /> : null}
      <div className="business-profile-container grid-two-columns tablet-1-col">
        <div className="flex">
          <div style={{ flexDirection: "row", alignItems: "center" }} className="flex-list space-evenly m-l-base">
            <p className="landing-page__title--hash  m-r-base"># </p>
            <h1 className="landing-page__title--topic ">{ToTitleCase(category?.Name)}</h1>
          </div>
        </div>
        <div className="business-profile-button-container">
          <div className="business-profile-header-button" role={"button"} onClick={() => handleManageProposal()}>
            Manage Proposal
          </div>
        </div>
      </div>
      <div className="flex tab-navigator-container">
        {props?.tabData?.map((item, index) => {
          if (item?.display)
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={"topic"}
                page={props?.page}
              />
            );
        })}
      </div>
    </>
  );
};

export default TopicHeader;
