import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../../../adapters/xhr/generalApi";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../utlis/customHooks/customHooks";
import BlurbCardLarge from "../../../../utlis/hoc/blurbCard/blurbCard";
import CardList from "../../../../utlis/hoc/productCard/cardList";
import ProviderCard from "../../../../utlis/hoc/providerCard/providerCard";
import {
  EncryptId,
  GetIdFormLink,
  ReplaceBlankSpaceByHyphen,
  ToTitleCase,
} from "../../../../utlis/hof/hof";
import SearchResultFilter from "../../../searchResult/components/filter/searchResultFilter";
import { MobilePaginatedItems } from "../../../searchResult/components/pagination/paginationMobileView";
import { PaginatedItems } from "../../../searchResult/components/pagination/paginationReact";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import GlobalFilter from "./globalFilter/globalFilter";
import _ from "lodash";
import ListCount from "../../../../utlis/hoc/productCard/listCount";
import SelectedFilterList from "../../../searchResult/components/filter/selectedFilterList";
import FilterMobileView from "../../../searchResult/components/filter/mobile/filterMobileView";
import NewBlurbCard from "../../../../utlis/hoc/newBlurbCard/newBlurbCard";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import * as NO_CONTENT from "../../../../config/config";

const BlurbsTab = (props) => {
  const [pageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [noResultsData, setNoResultsData] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const VENDOR_NAME = PARAMS?.vendor;
  const productId = props?.productId;

  const ProductData = useEnCryptGetApi(
    `/getProductRelatedBlurbsOfVendors?productId=${PRODUCT?.Id}&limit=30&offset=${pageNo}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );

  const signalTrackingBlurbObject = {
    Action: `${KEYS?.PROVIDER_BLURBS.MORE} clicked`,
    page: "blurb page-product",
    section: "related blurbs",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewAllBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Target: {
        page: signalTrackingBlurbObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: productId?.Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  useEffect(() => {
    if (ProductData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(ProductData?.data?.data));
      } else {
        setLazyLoadData(ProductData?.data?.data);
      }
    } else if (typeof ProductData?.data?.data === "object" && ProductData?.data?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [ProductData?.data]);

  return (
    <div key={remountComponent}>
      {lazyLoadData?.length < 1 && (ProductData?.isLoading || ProductData?.isFetching) ? (
        <ProductSkeleton page={props?.page} />
      ) : lazyLoadData?.length > 0 ? (
        <InfiniteScrollComp
          Data={lazyLoadData}
          hasMoreData={ProductData?.data?.data?.length >= 30}
          fetchData={fetchData}
        >
          <NewBlurbCard data={lazyLoadData} parent={props?.parent} page={props?.page} section={"product Blurbs"} />
        </InfiniteScrollComp>
      ) : noResultsData ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_BLURB}</div>
        </div>
      ) : null}
      {/* {ProductData?.data?.data?.length >= 6 ? (
        <a
          class="display-block m-t-sm text-center"
          onClick={() =>
            handleViewAllBlurbLinkClicked(
              `/${ReplaceBlankSpaceByHyphen(
                VENDOR_NAME
              )}/${ReplaceBlankSpaceByHyphen(PRODUCT?.Name)}/blurbs/${EncryptId(
                PRODUCT?.Id
              )}`
            )
          }
          href={`/${ReplaceBlankSpaceByHyphen(
            VENDOR_NAME
          )}/${ReplaceBlankSpaceByHyphen(PRODUCT?.Name)}/blurbs/${EncryptId(
            PRODUCT?.Id
          )}`}
        >
          {KEYS?.PROVIDER_BLURBS.MORE}
        </a>
      ) : null} */}
    </div>
  );
};

export default BlurbsTab;
