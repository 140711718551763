import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import Dropdown from "../../../utlis/hoc/dropdown/dropdown";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";

const SelectProductCategoriesCompany = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const REQUEST_CATEGORY = useSelector((state) => state?.homeDetails?.requestCategoryStatus);
  const [searchProductCategoryText, setSearchProductCategoryText] = useState("a");
  const companyBasicDetails = props?.companyBasicDetails;
  const productCategoryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchProductCategoryText}&type=productCategory`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const selectedTopicValue = companyBasicDetails?.Product_Categories?.length
    ? companyBasicDetails?.Product_Categories?.map((val) => {
        return {
          name: val?.Product_Category_Name,
          id: val?.Product_Category_ID,
        };
      })
    : [];
  const concatIsSelectedCompatible = concatIsSelectedAllData(selectedTopicValue, productCategoryDropdownData, "id");
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.companyBasicDetails,
      Product_Categories: val?.length
        ? val?.map((val) => {
            return {
              Product_Category_Name: val?.name,
              Product_Category_ID: val?.id,
            };
          })
        : [],
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };

  return (
    <>
      <div className="m-y-sm">
        <Dropdown
          data={concatIsSelectedCompatible}
          // handleApplyClicked={handleApplyCountryClicked}
          label={"Select Product Categories"}
          handleSelectedDataOnChange={handleSelectedDataOnChange}
          selectedValue={selectedTopicValue}
          hideSaveAndClear={true}
          searchWithApi={true}
          handleSearchText={handleSearchText}
          name={"name"}
          Id={"id"}
        />
        {REQUEST_CATEGORY ? (
          <div className="category-request-submitted">
            Product category request submitted. We will reach to you once the category is live
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SelectProductCategoriesCompany;
