import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetItemsFromBoardQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../config/config";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { GetIdFormLink } from "../../utlis/hof/hof";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import BoardHeader from "./component/boardHeader";
import BoardItemList from "./component/boardItemList";
import BoardOverView from "./component/boardOverView";
import _ from "lodash";
// /boards/cloneBoard?Board_ID=123&User_ID=123
function BoardDetailPage(props) {
  const PARAMS = useParams();

  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const BOARD_KEYS = CONFIG_KEYS?.CONFIG?.BOARDS;
  const BOARD_ITEM_LIST_KEYS = BOARD_KEYS?.BOARD_LIST;
  const [selectedTab, setSelectedTab] = useState(BOARD_ITEM_LIST_KEYS.BLURBS);
  const [boardListDetails, setBoardListDetails] = useState({});
  const [filteredText, setFilteredText] = useState("");
  const BOARDS_PARAMS_DETAILS = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const BOARD_ID = BOARDS_PARAMS_DETAILS.Id;
  const BOARD_NAME = BOARDS_PARAMS_DETAILS.Name;
  const page = "board detail page";
  // /board/getBoardItemDetails?Board_ID=123
  // /boards/sharingBoard?Board_ID=123&Usre_ID=123
  const sharedBoardDetails = useEnCryptGetApi(
    `/boards/sharingBoard?Board_ID=${BOARD_ID}&User_ID=${userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetItemsFromBoardQuery
  );
  const getBoardDetails = useEnCryptGetApi(
    `/board/getBoardItemDetails?Board_ID=${BOARD_ID}&userId=${userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetItemsFromBoardQuery
  );
  const changeOrderingOfBoardItems = useEnCryptPostApi(
    `/board/orderBoardItems`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const ObjectKeys =
    typeof getBoardDetails.data?.data?.Data == "object" && Object.keys(getBoardDetails.data?.data?.Data)?.length
      ? Object.keys(getBoardDetails.data?.data?.Data)
      : [];
  function handleChangeTabClicked(val) {
    setFilteredText("");
    setBoardListDetails(getBoardDetails.data?.data);
    setSelectedTab(val);
  }
  const BREAD_CRUMB_BOARD_DETAIL = [
    { name: "Home", active: true, link: "/" },
    { name: "My Boards", active: true, link: "/board-detail/my-boards" },
    { name: BOARD_NAME, active: false },
  ];
  useEffect(() => {
    if (getBoardDetails.data) {
      if (getBoardDetails.data?.data?.Data) {
        const GetAllObjKeys = Object.keys(getBoardDetails.data?.data?.Data)?.length
          ? Object.keys(getBoardDetails.data?.data?.Data)
          : [];
        const GetTabForHighCount = GetAllObjKeys.map((newKeys) => {
          return { count: getBoardDetails.data?.data?.Data[newKeys]?.length, label: newKeys };
        });

        const sortedCount = _.orderBy(GetTabForHighCount, (list) => list?.count);
        setSelectedTab(sortedCount[sortedCount?.length - 1]?.label);
      }

      setBoardListDetails(getBoardDetails.data?.data);
    }
  }, [getBoardDetails.data]);
  const handleChangePosition = (currentIndex, newIndex, listType) => {
    if (!filteredText) {
      const selectedBoardList = boardListDetails?.Data?.[listType];
      const removedData = selectedBoardList.splice(currentIndex, 1)[0];
      selectedBoardList.splice(newIndex, 0, removedData);
      const getIds = selectedBoardList.map((list) => {
        const IdKeys =
          listType == BOARD_ITEM_LIST_KEYS?.BLURBS
            ? "blurbID"
            : listType == BOARD_ITEM_LIST_KEYS?.USE_CASES
            ? "Use_Case_Blurb_ID"
            : listType == BOARD_ITEM_LIST_KEYS?.VENDOR
            ? "Vendor_ID"
            : listType == BOARD_ITEM_LIST_KEYS?.PRODUCTS
            ? "Product_ID"
            : null;
        return list?.[IdKeys];
      });
      changeOrderingOfBoardItems.handleTrigger({
        Board_ID: BOARD_ID,
        User_ID: userId,
        Item_ID: getIds,
        Item_Type: listType,
      });
      const newUpdatedData = { ...boardListDetails?.Data, [listType]: selectedBoardList };
      setBoardListDetails({ ...boardListDetails, Data: newUpdatedData });
    }
  };
  function handleFilterItems(val) {
    setFilteredText(val);
    if (val) {
      const selectedBoardList = getBoardDetails.data?.data?.Data?.[selectedTab];
      const nameKeys =
        selectedTab == BOARD_ITEM_LIST_KEYS?.BLURBS
          ? "title_level_1"
          : selectedTab == BOARD_ITEM_LIST_KEYS?.USE_CASES
          ? "title_level_1"
          : selectedTab == BOARD_ITEM_LIST_KEYS?.VENDOR
          ? "Vendor_Name"
          : selectedTab == BOARD_ITEM_LIST_KEYS?.PRODUCTS
          ? "Product_Name"
          : null;
      const filterList = selectedBoardList.filter((list) => {
        return list?.[nameKeys].toLowerCase().includes(val.toLowerCase());
      });
      const newUpdatedData = { ...getBoardDetails.data?.data?.Data, [selectedTab]: filterList };
      setBoardListDetails({ ...getBoardDetails.data?.data, Data: newUpdatedData });
    } else {
      setBoardListDetails(getBoardDetails.data?.data);
    }
  }
  return (
    <>
      <Header page={page} show={true} />
      <div className="attached-navbar m-b-md">
        <div className="fixed-nav-container flex space-between align-items-bottom">
          <SearchHeaderBreadCrumb data={BREAD_CRUMB_BOARD_DETAIL} page={page} parent={"board detail page"} />
        </div>
      </div>
      <div className="ui container">
        {boardListDetails?.Board_Details?.Board_Deleted ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50dvh",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontSize: "2em", color: "#d3d3d3", fontWeight: "600" }}> This Board has been deleted</h1>
          </div>
        ) : (
          <>
            <BoardHeader
              boardDetails={boardListDetails}
              selectedTab={selectedTab}
              handleChangeTabClicked={handleChangeTabClicked}
              Data={ObjectKeys}
            />
            <div className="grid-business-profile mobile-1-col">
              <div className="board-list-items-main-wrapper">
                <BoardItemList
                  filteredText={filteredText}
                  handleChangePosition={handleChangePosition}
                  selectedTab={selectedTab}
                  Data={boardListDetails?.Data}
                  boardUserId={boardListDetails?.Board_Details?.User_ID}
                  page={page}
                />
              </div>
              <div className="mobile-hidden">
                <BoardOverView
                  filteredText={filteredText}
                  handleFilterItems={handleFilterItems}
                  page={page}
                  type={props?.type}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BoardDetailPage;
