import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import TextArea from "../../../../utlis/hoc/textArea/textArea";
import InputField from "../../../auth/signUp/onBoard/components/inputField";
import InputFieldNew from "../../../auth/signUp/onBoard/components/InputFieldNew";
import InputFieldProfile from "./inputFieldProfile";

const ProfessionalDetails = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const userData = props?.data;
  const EDIT_PROFILE = KEYS?.EDIT_PROFILE;
  const GetDepartmentData = useEnCryptGetApi(
    `/getDepartmentsForProfile`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const DepartmentData = GetDepartmentData?.data?.data;
  return (
    <div className="flex-row m-t-xs">
      {/* professional-details-padding */}
      {/* <div class="portal-section__header professional-details-padding ">
        <h3 class="portal-section__title">{PROFILE_DETAILS?.PROFESSIONAL_DETAILS}</h3>
      </div> */}
      <div class="portal-section__content">
        <div class="field" id="field-user__phone_number_prefix">
          <div class="field m-b-none">
            <label>{PROFILE_DETAILS?.ABOUT_ME}</label>
          </div>
          <TextArea
            value={userData?.summary}
            label={PROFILE_DETAILS?.ABOUT_ME}
            changeText={(val) => props?.handleProfessionalDetails({ ...userData, summary: val })}
            removeMargin={true}
          />
        </div>

        {/* <div class="grid-form grid-form--2-equal-cols"> */}
        <div class="field" id="field-user__phone_number_prefix">
          <div class="field m-b-none">
            <label>{PROFILE_DETAILS?.EDUCATION_LABEL}</label>
          </div>
          <InputFieldProfile
            value={userData?.education}
            label={PROFILE_DETAILS?.EDUCATION}
            changeText={(val) =>
              props?.handleProfessionalDetails({
                ...userData,
                education: val,
              })
            }
            removeBottomPadding={true}
          />
        </div>
        <div class="field" id="field-user__phone_number_prefix">
          <div class="field m-b-none">
            <label>{PROFILE_DETAILS?.JOB_TITLE}</label>
          </div>
          <InputFieldProfile
            value={userData?.jobTitle}
            label={PROFILE_DETAILS?.JOB_TITLE_LABEL}
            removeBottomPadding={true}
            changeText={(val) => props?.handleProfessionalDetails({ ...userData, jobTitle: val })}
          />
        </div>
        <div class="field" id="field-user__phone_number_prefix">
          <div class="field m-b-none">
            <label>{PROFILE_DETAILS?.COMPANY_NAME}</label>
          </div>
          <InputFieldProfile
            value={userData?.company}
            label={PROFILE_DETAILS?.COMPANY_NAME_LABEL}
            changeText={(val) =>
              props?.handleProfessionalDetails({
                ...userData,
                company: val,
              })
            }
            removeBottomPadding={true}
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ProfessionalDetails;
