import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { BreakText, GetNameInitials, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { useState } from "react";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostCommentForCardMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
const CommentInput = (props) => {
  const [error, setError] = useState("");
  const [commentText, setCommentText] = useState("");
  const [isAnonymousUser, setIsAnonymousUser] = useState(true);
  const textAreaRef = useRef(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PROPS_DATA = props?.data;
  const REPLY_COMMENT = props?.parentData;
  const cardType = props?.cardType;
  const ActionForComment =
    cardType == "blurb"
      ? SECRET_KEYS.CONFIG.COMMENT_BLURB
      : props?.cardType == "product"
      ? SECRET_KEYS.CONFIG.COMMENT_PRODUCT
      : props?.cardType == "usecase"
      ? SECRET_KEYS.CONFIG.COMMENT_USE_CASE
      : "";
  const ActionForReply =
    cardType == "blurb"
      ? SECRET_KEYS.CONFIG.REPLY_BLURB
      : cardType == "product"
      ? SECRET_KEYS.CONFIG.REPLY_PRODUCT
      : cardType == "usecase"
      ? SECRET_KEYS.CONFIG.REPLY_USE_CASE
      : "";
  // Action: COMMENT_ID ? 'CommentReply' : 'Comment',
  const page =
    props?.cardType == "blurb"
      ? "Blurb detail page"
      : props?.cardType == "product"
      ? "Product detail page"
      : props?.cardType == "usecase"
      ? "Use case detail page"
      : "";
  const PostComment = useEnCryptPostApi(
    `/comment/postComment`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const initials = GetNameInitials(
    authData?.data?.userExist
      ? authData?.data?.userName
        ? authData?.data?.userName
        : authData?.data?.firstName + " " + authData?.data?.lastName
      : "Anon Name"
  );
  const handleSendClicked = (e) => {
    e.preventDefault();
    if (commentText) {
      const rawData = {
        Parent_Comment_ID: REPLY_COMMENT?.commentGUID,
        Display_Parent_Comment_ID: REPLY_COMMENT?.commentGUID,
        User_ID: USER_ID,
        Item_ID: PROPS_DATA?.data?.[PROPS_DATA?.keyName],
        Comment: commentText,
        Item_Type: PROPS_DATA?.cardType,
        Privacy_Status: isAnonymousUser ? "Anonymous" : "Non-Anonymous",
        // Comment_GUID: USER_ID + "" + new Date().getTime() + "" + PROPS_DATA?.data?.[PROPS_DATA?.keyName],
        Comment_GUID: crypto.randomUUID(),
      };
      const Action = REPLY_COMMENT ? ActionForReply : ActionForComment;

      const Target = {
        Id: REPLY_COMMENT ? rawData?.Display_Parent_Comment_ID : rawData?.Item_ID,
        type: rawData?.Item_Type,
        page: page,
        link:
          props?.cardType == "blurb"
            ? `/${ReplaceBlankSpaceByHyphen(PROPS_DATA?.data?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
                BreakText(PROPS_DATA?.data?.title_level_1)
              )}-${PROPS_DATA?.data?.[PROPS_DATA?.keyName]}`
            : props?.cardType == "product"
            ? `/${ReplaceBlankSpaceByHyphen(PROPS_DATA?.data?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                PROPS_DATA?.data?.Product_Name,
                PROPS_DATA?.data?.Product_ID
              )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
            : props?.cardType == "usecase"
            ? `/${ReplaceBlankSpaceByHyphen(PROPS_DATA?.data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
                BreakText(PROPS_DATA?.data?.title_level_1)
              )}-${PROPS_DATA?.data?.[PROPS_DATA?.keyName]}`
            : "",
      };
      const Object = {
        Id: rawData?.Comment_GUID,
        type: REPLY_COMMENT ? "Reply" : "Comment",
        section: null,
        context: rawData?.Comment,
        page: props?.page,
      };
      PostComment?.handleTrigger(rawData);
      const signalTrackingPostData = {
        Actor: USER_ID,
        SignalCreatedAt: new Date().getTime() / 1000,
        Item_Type: REPLY_COMMENT ? "Reply" : "Comment",
        Item_Id: rawData?.Comment_GUID,
        Action: Action,
        Target: Target,
        Object: Object,
      };

      SignalTracking.handleTrigger(signalTrackingPostData);
      setCommentText("");
      if (REPLY_COMMENT) {
        props?.handleCloseInput();
      }
      props?.handleCommentCount();
      setIsAnonymousUser(false);
    }
  };

  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 10)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };

  const handleChangeText = (e) => {
    setCommentText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };

  // const onKeyDown = (event) => {
  //   if (event.keyCode === 13) {
  //     //13 is the key code for Enter
  //     event.preventDefault();
  //     if (commentText) {
  //       handleSendClicked();
  //     }
  //   }
  //   //Here you can even write the logic to select the value from the drop down or something.
  // };
  function handleCommentAsAnonymousUser() {
    setIsAnonymousUser(!isAnonymousUser);
  }
  return (
    <div className="comments-container m-b-sm">
      <div className="dtrd-navbar__avatar-box">
        <div class="dtrd-navbar__avatar-box navbar__user">
          <div class="circular-avatar">
            <div class="user-initials user-initials--primary" style={{ width: "40px", height: "40px" }}>
              {initials}
            </div>
          </div>
        </div>
      </div>
      <div className="comment-input-container">
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.6rem", width: "100%" }}
        >
          <textarea
            ref={textAreaRef}
            style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
            // onKeyDown={onKeyDown}
            rows={1}
            id="note"
            type={"text"}
            value={commentText}
            onInput={handleInput}
            onChange={(e) => handleChangeText(e)}
            placeholder={`${REPLY_COMMENT ? "Add a reply..." : "Add comment..."}`}
            className={`input-field-text ${error ? "background-error" : ""}`}
          />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "-8px" }}>
            <input
              type="checkbox"
              className="table-checkbox"
              checked={isAnonymousUser}
              onClick={handleCommentAsAnonymousUser}
            />
            <p className="filter-list-items-text m-l-half-base">{`${
              REPLY_COMMENT ? "Reply as anonymous user" : "Comment as anonymous user"
            }`}</p>
          </div>
        </div>

        <div
          onClick={handleSendClicked}
          className={`flex justify-flex-end m-l-half-base ${commentText ? "cursor--pointer" : "cursor--disable"}`}
        >
          <p
            className="comment-card-post-button m-b-sm"
            data-inverted="true"
            // data-position="left center"
            // data-tooltip={!commentText ? "Comments cannot be empty" : null}
          >
            Post
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommentInput;
