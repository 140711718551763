import React from "react";
import AddVendorMultiSelect from "./addVendorMutiSelect";

const FavoriteVendorList = (props) => {
  const proposalDetails = props?.proposalDetails;
  return (
    <AddVendorMultiSelect
      proposalDetails={proposalDetails}
      handleDetails={props?.handleDetails}
    />
  );
};

export default FavoriteVendorList;
