import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { map } from "lodash";
import RelatedUseCaseCard from "./relatedUseCaseCard";

const RelatedUseCase = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [pageNo, setPageNo] = useState(1);
  const PARAMS = useParams();
  const USER_ID = authData?.data?.userId;
  const BLURB_DETAILS = PARAMS?.name;
  let BLURB_ID = BLURB_DETAILS.split("-").at(-1);

  const GetApiData = useEnCryptGetApi(
    `/getUseCasesfromSameProductCategory?blurb_Id=${BLURB_ID}&limit=6&userId=${USER_ID}&page=${pageNo}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const relatedBlurbs = GetApiData?.data?.data?.data;

  return relatedBlurbs?.length ? (
    <>
      <p className="related-case-studies-title m-t-md">Related case studies</p>
      <div className="related-use-case-card-container">
        {relatedBlurbs?.map((item) => (
          <RelatedUseCaseCard data={item} />
        ))}
      </div>
    </>
  ) : null;
};

export default RelatedUseCase;
