import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { usePostCommentForCardMutation } from "../../../../../adapters/xhr/generalApi";
import { GetNameInitials } from "../../../../../utlis/hof/hof";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import ReadMore from "../../businessOverview/ReadMore";

const SelectedSingleChatCard = ({ item, data, replyCard, handleCommentCount, cardType }) => {
  const [commentData, setCommentData] = useState(item?.Comment);
  const [viewReplies, setViewReplies] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [commentInputVisible, setCommentInputVisible] = useState(false);
  const textAreaRef = useRef(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  //* Get comment time difference in hour, min and days for a comment
  const providedDate = new Date(item?.Date_Time * 1000);
  const currentDate = new Date();
  const diffInMs = currentDate.getTime() - providedDate.getTime();
  const diffInMins = Math.round(diffInMs / (1000 * 60));
  const diffInHours = Math.round(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.round(diffInHours / 24);

  const EditComment = useEnCryptPostApi(
    `/comment/editComment`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  const TriggerLikeComment = useEnCryptPostApi(
    `/likeManagement`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  useEffect(() => {
    setCommentData(item?.Comment);
  }, [item]);

  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 10)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };

  const handleChangeText = (e) => {
    setCommentData(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };

  const handleSaveClicked = (val) => {
    if (commentData) {
      const rawData = {
        User_ID: USER_ID,
        Comment: commentData,
        Comment_GUID: val?.commentGUID,
      };
      setEditComment((prev) => !prev);
      EditComment?.handleTrigger(rawData);
      setCommentData("");
    }
  };

  const handleCloseInput = () => {
    setCommentInputVisible(false);
    setViewReplies(true);
  };

  const handleCommentLike = () => {
    const rawData = {
      Action_Type: item?.Liked ? "dislike" : "Liked",
      Item_Type: "Comment",
      Item_Id: item?.commentGUID,
      User_Id: USER_ID,
    };
    TriggerLikeComment?.handleTrigger(rawData);
  };

  const HandleCloseEdit = () => {
    setCommentData(item?.Message_Text);
    setEditComment(false);
  };
  const initials = GetNameInitials(item?.User_Name ? item?.User_Name : "Anon Name");
  return (
    <div className="direct-list-selected-card-list-container" key={item?.Message_ID}>
      <div className="dtrd-navbar__avatar-box">
        <div class="dtrd-navbar__avatar-box navbar__user">
          <div class="circular-avatar">
            {item?.Logo && !item?.From_Vendor ? (
              <img src={item?.Logo} alt="vendor-image" className="comment-list-user-image" />
            ) : !item?.From_Vendor ? (
              <div class="user-initials user-initials--primary" style={{ width: "40px", height: "40px" }}>
                {initials}
              </div>
            ) : (
              <div style={{ width: "40px", height: "40px" }}></div>
            )}
          </div>
        </div>
      </div>
      <div style={{ flex: "1" }}>
        <div
          className={`comment-text-container--vendor ${item?.Delete_Status ? "deleted-comment-container" : ""} ${
            item?.From_Vendor ? "comment-text-container--user" : ""
          }`}
        >
          {item?.Delete_Status ? null : (
            <div className="flex space-between m-b-half-base">
              <h4 className="comment-card-username">{item?.User_Name}</h4>
              <div className="flex align-items-center">
                <p className="comment-engagement-time">
                  {diffInMins < 60
                    ? `${diffInMins ? diffInMins : 1}min`
                    : diffInHours < 24
                    ? `${diffInHours}hr`
                    : `${diffInDays}d`}
                </p>
                {item?.Edited ? <p className="comment-engagement-time">(edited)</p> : null}
                {/* <CommentOptions data={item} handleEditPressed={() => setEditComment((prev) => !prev)} /> */}
              </div>
            </div>
          )}
          {editComment ? (
            <div>
              <textarea
                //   ref={textAreaRef}
                style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
                // onKeyDown={onKeyDown}
                rows={1}
                id="note"
                type={"text"}
                value={commentData}
                autoFocus={true}
                onInput={handleInput}
                onChange={(e) => handleChangeText(e)}
                placeholder="Add comment"
                className={`input-field-text outline-input-focus m-t-mini`}
              />
              <div className={`flex justify-flex-end m-half-base`}>
                <p
                  className="comment-card-post-button"
                  style={{ margin: "0px", color: "#D13732" }}
                  role="button"
                  onClick={() => HandleCloseEdit()}
                >
                  Cancel
                </p>
                <p
                  className="comment-card-post-button"
                  data-inverted="true"
                  data-position="left center"
                  data-tooltip={!commentData ? "Comments cannot be empty" : null}
                  onClick={() => handleSaveClicked(item)}
                  style={{ margin: "0px" }}
                  role="button"
                >
                  Save
                </p>
              </div>
              <div
                onClick={() => handleSaveClicked(item)}
                className={`flex justify-flex-end m-l-half-base ${commentData ? "cursor--pointer" : "cursor--disable"}`}
              ></div>
            </div>
          ) : (
            <ReadMore>{item?.Message_Text}</ReadMore>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedSingleChatCard;

const data = [
  {
    Message_ID: 7,
    Message_Text: "",
    Date_Time: 1684132632,
    Seen: null,
    Seen_Date: null,
    From_Vendor: 1,
    User_Name: "PortDev",
    Logo: "https://www.portqii.com/wp-content/uploads/2022/02/portqii_logo_text_color_big.png",
  },
  {
    Message_ID: 6,
    Message_Text: "Hi How are you",
    Date_Time: 1683884441,
    Seen: null,
    Seen_Date: null,
    From_Vendor: 1,
    User_Name: "PortDev",
    Logo: "https://www.portqii.com/wp-content/uploads/2022/02/portqii_logo_text_color_big.png",
  },
];
