import React from "react";
import BasicInformation from "../proposalSubmit/component/basicInformation/basicInformation";
import Questions from "../proposalSubmit/component/questions/questions";
import TimelineAndVendors from "../proposalSubmit/component/timelineAndVendors";
import PreviewTimelineAndVendors from "./previewTimelineAndVednors";

function PreviewTemplate(props) {
  const proposalDetails = props?.proposalDetails;

  return (
    <div style={{ width: "100%" }}>
      <BasicInformation preview={true} proposalDetails={proposalDetails} />
      {proposalDetails?.Proposal_Question?.length ? <Questions proposalDetails={proposalDetails} /> : null}{" "}
      {proposalDetails?.Vendors?.length ? <PreviewTimelineAndVendors proposalDetails={proposalDetails} /> : null}
    </div>
  );
}

export default PreviewTemplate;
