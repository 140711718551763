import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import { useProductExpGetPostQuery } from "../../../../../../adapters/xhr/generalApi";
import SingleSelectNewDropdown from "../../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";

const AnalyticsPostList = (props) => {
  const [selectedPost, setSelectedPost] = useState({});
  const [DropdownData, setDropdownData] = useState([]);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  const ProductExpertPostsData = useEnCryptGetApi(
    `/getLinkedInPosts?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useProductExpGetPostQuery
  );
  useEffect(() => {
    const SELECT_ALL_VALUE = {
      Post_Name: "----All Post----",
      allPost: true,
    };
    if (ProductExpertPostsData?.data?.data?.data) setDropdownData([...ProductExpertPostsData?.data?.data?.data, SELECT_ALL_VALUE]);
  }, [ProductExpertPostsData?.data]);
  const handleDetails = (val) => {
    setSelectedPost(val);
    props?.handleSelectPost(val);
  };
  return (
    <div class="field-company-profile p-r m-y-lg m-x-xs" id="field-user__country_id">
      <SingleSelectNewDropdown
        handleSelectedData={(val) => handleDetails(val)}
        data={DropdownData}
        placeholder={"Select Post to view analytics"}
        // flag={true}
        selectedVal={selectedPost}
        name={"Post_Name"}
        rightSideContent={false}
        returnObject={true}
        label={"Select Post"}
      />
    </div>
  );
};

export default AnalyticsPostList;
