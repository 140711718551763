import React from "react";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import PreferencesList from "./components/preferencesList";
import { useSelector } from "react-redux";
import { useState } from "react";
import PreferencesSelection from "./components/preferencesSelection";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import { useGetUserPreferencesQuery } from "../../adapters/xhr/crudServiceApi";

const ManagePreference = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [featureActiveList, setFeatureActiveList] = useState("In App");
  const [selectedTab, setSelectedTab] = useState("In App");

  const GetUserPreferences = useEnCryptGetApi(
    `/getUserNotificationPreferences?User_ID=${authData?.data?.userId}&Persona=${authData?.data?.Persona}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetUserPreferencesQuery
  );

  const handleFeatureList = (val) => {
    setFeatureActiveList(val);
  };

  return (
    <div>
      <Header show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb parent={"ManagePreference"} data={KEYS?.BREAD_CRUMB_PREFERENCES} />
          </div>
        </div>
      </div>

      <div className="tablet-1-col container">
        <div className="flex tab-navigator-container">
          {GetUserPreferences?.data?.data?.map((item) => {
            return (
              <div className="flex m-t-base" key={item?.channel}>
                <h2
                  className={`tab-index-text ${selectedTab == item?.channel ? "tab-index-selected" : null}`}
                  onClick={() => setSelectedTab(item?.channel)}
                >
                  {item?.channelDisplayName === "Inapp" ? "App" : item?.channelDisplayName}
                </h2>
              </div>
            );
          })}
        </div>
        {/* <PreferencesList
          handleFeatureList={handleFeatureList}
          featureActiveList={featureActiveList}
          data={GetUserPreferences?.data?.data}
        /> */}
        <PreferencesSelection featureActiveList={selectedTab} data={GetUserPreferences?.data?.data} />
      </div>
    </div>
  );
};

export default ManagePreference;
