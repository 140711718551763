import React from "react";
import * as DASHBOARD_KEYS from "../../../../config/config";
const SummaryStatsCard = (props) => {
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const SUMMARY_STATS_KEYS = DASHBOARD_CONFIG_KEYS?.SUMMARY_STATS;
  const data = props?.data;
  return (
    <div className="dashboard-summary-stats-card">
      <div className="dashboard-summary-stats-title-wrapper">
        <h3 className="dashboard-summary-stats-count-text p-b-mini">
          {data?.[SUMMARY_STATS_KEYS?.COUNT]}
        </h3>
      </div>
      <div className="dashboard-summary-stats-count-wrapper">
        <h3 className="dashboard-summary-stats-title-text">
          { data?.[SUMMARY_STATS_KEYS?.TITLE]}
        </h3>
      </div>
    </div>
  );
};

export default SummaryStatsCard;
