import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useGetNotificationQuery, useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import NotificationList from "../../../../notifications/component/notificationList";
import HelpList from "./helpList";

const HelpDrawer = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  useEffect(() => {
    setShowDrawer(props?.visible);
  }, [props?.visible]);

  const GetProductDataFromApi = useEnCryptGetApi(
    `/getHelpFaq`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
    // authData?.data?.userId ? false : true
  );

  const HtmlData = typeof GetProductDataFromApi?.data?.data === "object" ? GetProductDataFromApi?.data?.data : {};
  return (
    <div
      className={`header-drawer-container ${showDrawer ? "show-drawer" : "hide-drawer"}`}
      // onClick={() => handleCloseModal()}
    >
      <div className={`header-drawer-view-container ${showDrawer ? "slideIn-drawer" : "slideOut-drawer"}`}>
        <div className="header-drawer-view-sub-container">
          <div
            style={{ borderBottom: "1px solid #d3d3d3", paddingBottom: "3px" }}
            className="flex-container space-between m-b-mini"
          >
            <h1 style={{ textAlign: "center", width: "100%" }} className="header-drawer-title">
              Help
            </h1>
            <FeatherIcon icon={"x"} size={"30"} color={"#37393f"} role="button" onClick={() => props?.handleClose()} />
          </div>
          <HelpList Data={HtmlData} isLoading={GetProductDataFromApi?.isLoading} />
          {/* GetProductDataFromApi?.data?.data */}
        </div>
      </div>
    </div>
  );
};

export default HelpDrawer;
