import React from "react";
import * as CONFIG_KEYS from "../../../../../../config/config";
const BasicInformationContent = (props) => {
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const proposalTitle = proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE];
  const proposalDescription = proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION];
  const businessNeeds = proposalDetails?.[PROPOSAL_KEYS?.SOLUTION_NEEDS]?.length
    ? proposalDetails?.[PROPOSAL_KEYS?.SOLUTION_NEEDS]
        .map((val) => {
          return val?.Item_Name;
        })
        .toString()
    : null;
  const businessDomain = proposalDetails?.[PROPOSAL_KEYS?.BUSINESS_DOMAIN];
  const businessSize = proposalDetails?.[PROPOSAL_KEYS?.BUSINESS_SIZE];
  return (
    <div
      className={`${
        props?.preview
          ? "proposal-submit-basic-details-main-wrapper--preview "
          : "proposal-submit-basic-details-main-wrapper"
      }`}
    >
      {proposalTitle ? (
        <div className="proposal-submit-basic-details-content-wrapper">
          <h3 className="proposal-submit-basic-details-title-text">Proposal Title</h3>
          <h5 className="proposal-submit-basic-details-content-text">{proposalTitle}</h5>
        </div>
      ) : null}
      {proposalDescription ? (
        <div className="proposal-submit-basic-details-content-wrapper">
          <h3 className="proposal-submit-basic-details-title-text">Proposal Description</h3>
          <h5 className="proposal-submit-basic-details-content-text">{proposalDescription}</h5>
        </div>
      ) : null}
      {businessNeeds ? (
        <div className="proposal-submit-basic-details-content-wrapper">
          <h3 className="proposal-submit-basic-details-title-text">Business needs</h3>
          <h5 className="proposal-submit-basic-details-content-text">{businessNeeds}</h5>
        </div>
      ) : null}
      {businessDomain ? (
        <div className="proposal-submit-basic-details-content-wrapper">
          <h3 className="proposal-submit-basic-details-title-text">Business Domain</h3>
          <h5 className="proposal-submit-basic-details-content-text">{businessDomain}</h5>
        </div>
      ) : null}
      {businessSize ? (
        <div className="proposal-submit-basic-details-content-wrapper">
          <h3 className="proposal-submit-basic-details-title-text">Business Size</h3>
          <h5 className="proposal-submit-basic-details-content-text">{businessSize}</h5>
        </div>
      ) : null}
    </div>
  );
};

export default BasicInformationContent;
