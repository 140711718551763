import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextArea from "../../../utlis/hoc/textArea/textArea";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";
import { ValidateFeatureListErrors } from "../../../utlis/hof/hof";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
const FeatureTable = (props) => {
  const [addFeatureErrors, setAddFeatureErrors] = useState();
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;
  const error = addFeatureErrors;
  const featureDetails = props?.data?.length ? props?.data : [];
  const productCategoryId = props?.productCategoryId;
  // https://thebox-dev.portqii.com/core/product/getProductFeatureListByCategory?productCategoryId=1
  const GetProductFeaturesFromApi = useEnCryptGetApi(
    `/product/getProductFeatureListByCategory?productCategoryId=${productCategoryId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const handleDetails = (val, i) => {
    let detailsToEdit = featureDetails?.length ? [...featureDetails] : [];
    detailsToEdit[i] = val;
    const detailsToPush = featureDetails?.map((list) => {
      if (list?.id == val?.id) {
        return val;
      } else {
        return list;
      }
    });
    props?.handleDetails(detailsToEdit);
  };
  useEffect(() => {
    const featureMain = props?.error ? props?.error : {};
    if (!_.isEmpty(featureMain)) {
      setAddFeatureErrors(featureMain);
      setTimeout(() => {
        setAddFeatureErrors({});
      }, 2000);
    }
  }, [props?.error]);
  const handleDeleteRow = (index) => {
    const detailsToDelete = [...featureDetails];
    detailsToDelete.splice(index, 1);
    props?.handleDetails(detailsToDelete);
  };
  const handleAddFeature = () => {
    if (featureDetails?.length) {
      const ValidatePricingPlans = ValidateFeatureListErrors(featureDetails[0]);

      setAddFeatureErrors(ValidatePricingPlans);
      setTimeout(() => {
        setAddFeatureErrors({});
      }, 2000);
      if (_.isEmpty(ValidatePricingPlans)) {
        featureDetails.unshift({
          Product_Feature: "",
          Feature_Description: "",
          actions: "",
          id: new Date().getTime() + authData?.data?.userId,
        });
        props?.handleDetails(featureDetails);
      }
    } else {
      featureDetails.unshift({
        Product_Feature: "",
        Feature_Description: "",
        actions: "",
        id: new Date().getTime() + authData?.data?.userId,
      });
      props?.handleDetails(featureDetails);
    }
  };
  return (
    <div className="add-product-pricing-table-add-feature-main-wrapper">
      <table className="add-product-pricing-table-main-wrapper">
        <thead className="add-product-pricing-table-header-wrapper">
          <th className="add-product-pricing-table-header-sub-wrapper">
            <h3 className="add-product-pricing-table-header-text">Feature Title</h3>
          </th>
          <th className="add-product-pricing-table-header-sub-wrapper">
            <h3 className="add-product-pricing-table-header-text">Description</h3>
          </th>
          <th className="add-product-pricing-table-header-sub-wrapper">
            <h3 className="add-product-pricing-table-header-text">Actions</h3>
          </th>
        </thead>
        <tbody style={{ paddingTop: "20px" }}>
          {featureDetails?.length
            ? featureDetails?.map((list, index) => {
                return (
                  <tr>
                    <td className="add-product-feature-table-data-plan-name p-r">
                      {/* <InputFieldProfile
                        label={"Feature Name"}
                        value={list?.Feature_Title}
                        changeText={(val) =>
                          handleDetails(
                            {
                              ...list,
                              Feature_Title: val,
                            },
                            index
                          )
                        }
                        error={index == 0 ? error?.Feature_Title : null}
                      /> */}
                      <div class="field-company-profile p-r" id="field-user__country_id">
                        <SingleSelectNewDropdown
                          handleSelectedData={(val) =>
                            handleDetails(
                              {
                                ...list,
                                Product_Feature: val?.Product_Feature,
                                Product_Feature_ID: val?.Product_Feature_ID,
                              },
                              index
                            )
                          }
                          data={
                            GetProductFeaturesFromApi?.data?.data?.length ? GetProductFeaturesFromApi?.data?.data : []
                          }
                          placeholder={"Feature Name"}
                          // flag={true}handleChangeText
                          handleChangeText={(val) => {
                            handleDetails(
                              {
                                ...list,
                                Product_Feature: val,
                                Product_Feature_ID: null,
                              },
                              index
                            );
                          }}
                          returnObject={true}
                          selectedVal={{
                            Product_Feature: list?.Product_Feature,
                            Product_Feature_ID: list?.Product_Feature_ID,
                          }}
                          name={"Product_Feature"}
                          label={"Select Feature Title"}
                          getSearchedText={true}
                          handleChangeSearchText={(val) => {
                            handleDetails(
                              {
                                ...list,
                                Product_Feature: val,
                                Product_Feature_ID: null,
                              },
                              index
                            );
                          }}
                        />
                        {error?.Product_Feature ? (
                          <span class="input-field-dropdown-error">{index == 0 ? error?.Product_Feature : null}</span>
                        ) : null}
                      </div>
                    </td>
                    <td className="add-product-feature-table-data-plan-description p-r">
                      <TextArea
                        value={list?.Feature_Description}
                        className="m-b-sm"
                        label={"Feature Description"}
                        changeText={(val) =>
                          handleDetails(
                            {
                              ...list,
                              Feature_Description: val,
                            },
                            index
                          )
                        }
                        // subTitleTop={
                        //   "Please add at least 50 characters to describe your requirement."
                        // }
                        error={index == 0 ? error?.Feature_Description : null}
                      />
                    </td>
                    <td className="add-product-feature-table-data-plan-actions-main-wrapper">
                      <div className="add-product-pricing-table-data-plan-actions-wrapper">
                        <span
                          onClick={() => handleDeleteRow(index)}
                          className="add-product-feature-table-data-plan-actions-button-wrapper"
                        >
                          <FeatherIcon icon="x" size="20" />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {error?.Feature_List_Length ? (
        <div>
          <span class="input-field-error-feature-length">{error?.Feature_List_Length}</span>
        </div>
      ) : null}
      <div onClick={handleAddFeature} className="add-product-feature-add-feature">
        + Add Feature
      </div>
    </div>
  );
};

export default FeatureTable;
