import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import MoreOptionsList from "./moreOptionsList";

function MoreOptions() {
  const [showViewMore, setShowViewMore] = useState(false);
  function handleOpenViewMore() {
    setShowViewMore(true);
  }
  function handleBlur(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowViewMore(false);
    }
  }
  function handleClose() {
    setShowViewMore(false);
  }
  return (
    <div tabIndex={0} onBlur={handleBlur} className="engagement-bar-items p-r">
      <FeatherIcon onClick={handleOpenViewMore} icon={`more-horizontal`} size="20" color={"#33343a"} role="button" />
      {showViewMore ? (
        <div className="view-more-container-chat modern-card modern-card--elevated">
          <MoreOptionsList handleClose={handleClose} />
        </div>
      ) : null}
    </div>
  );
}

export default MoreOptions;
