import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import NewProductCard from "../../../../utlis/hoc/newProductCard/newProductCard";
import { GetIdFormLink, ToTitleCase } from "../../../../utlis/hof/hof";
import SearchResultFilter from "../../../searchResult/components/filter/searchResultFilter";
import { MobilePaginatedItems } from "../../../searchResult/components/pagination/paginationMobileView";
import { PaginatedItems } from "../../../searchResult/components/pagination/paginationReact";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import GlobalFilter from "./globalFilter/globalFilter";
import _ from "lodash";
import SelectedFilterList from "../../../searchResult/components/filter/selectedFilterList";
import ListCount from "../../../../utlis/hoc/productCard/listCount";
import FilterMobileView from "../../../searchResult/components/filter/mobile/filterMobileView";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import * as NO_CONTENT from "../../../../config/config";

const ProductTab = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [remountFilter, setRemountFilter] = useState(0);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [noResultsData, setNoResultsData] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const ProductData = useEnCryptGetApi(
    `/getProductsRelatedToVendorProduct?productId=${PRODUCT?.Id}&limit=30&offset=${pageNo}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  useEffect(() => {
    if (ProductData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(ProductData?.data?.data));
      } else {
        setLazyLoadData(ProductData?.data?.data);
      }
    } else if (typeof ProductData?.data?.data === "object" && ProductData?.data?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [ProductData?.data]);
  return (
    <div key={remountComponent}>
      {lazyLoadData?.length < 1 && (ProductData?.isLoading || ProductData?.isFetching) ? (
        <ProductSkeleton />
      ) : lazyLoadData?.length > 0 ? (
        <InfiniteScrollComp
          Data={lazyLoadData}
          hasMoreData={ProductData?.data?.data?.length >= 30}
          fetchData={fetchData}
        >
          <NewProductCard section={`business-landing-page`} page={props?.page} data={lazyLoadData} />
        </InfiniteScrollComp>
      ) : noResultsData ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_SIMILAR_PRODUCT}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductTab;
