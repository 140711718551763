import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import {
  concatIsSelectedAllData,
  FilterSelectedAllData,
} from "../../../../../utlis/hof/hof";
import * as CONFIG_KEYS from "../../../../../config/config";
const AddVendorMultiSelect = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorId = authData?.data?.vendorId;
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [searchProductCategoryText, setSearchProductCategoryText] = useState(
    "a"
  );
  const PreSelectedVendorId = { Vendor_ID: vendorId };
  const selectedData = props?.proposalDetails[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
    ?.length
    ? props?.proposalDetails[ADD_PRODUCT_CONFIG_KEYS?.VENDORS].concat(
        [PreSelectedVendorId]
      )
    : [].concat([PreSelectedVendorId]);
  // path: `/getAllTopicForProductCategory?Product_Category_Id=${selectedProductCategory?.Tag_Id}/&searchText=${tagsText?.Name}`,
  // service: KEYS?.CONFIG?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
  // userInfo: {deviceId: DEVICE_INFO, userId: USER_ID},
  //   "https://thebox-dev.portqii.com/proposals/getTopicsOrProductCategory?searchText=Vi"
  const productCategoryDropdownData = useEnCryptGetApi(
    `/getAllVendors?searchText=${searchProductCategoryText}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const selectedTopicValue = props?.productBasicDetails?.tags?.length
    ? props?.productBasicDetails?.tags
    : [];
  const concatIsSelectedCompatible = FilterSelectedAllData(
    selectedData,
    productCategoryDropdownData,
    "Vendor_ID"
  );
  const handleSelectedDataOnChange = (val) => {
    const selectedVendors = props?.proposalDetails?.[
      ADD_PRODUCT_CONFIG_KEYS?.VENDORS
    ]?.length
      ? props?.proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
      : [];
    // if (val?.length) {
    //   selectedVendors.concat(val);
    // }
    props?.handleDetails({
      ...props?.proposalDetails,
      [ADD_PRODUCT_CONFIG_KEYS?.VENDORS]: val?.length
        ? selectedVendors.concat([
            {
              ...val[0],
              [ADD_PRODUCT_CONFIG_KEYS?.VENDOR_SELECTION_WAY]: "Searched",
            },
          ])
        : selectedVendors,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };
  const handleSearchWithApi = (key) => {
    setSearchProductCategoryText(key ? key : "a");
  };
  const handleDetailsProductCategory = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: val,
      tags: [],
    });
  };

  return (
    <div className="p-x-sm m-y-base p-r">
      <Dropdown
        data={concatIsSelectedCompatible}
        removeCheckBox={true}
        dropDownWrapperHeight={30}
        // handleApplyClicked={handleApplyCountryClicked}
        label={"Select Vendors"}
        //   showRightSideItems={true}
        //   rightSideItemName={"Item_Type"}
        handleSelectedDataOnChange={handleSelectedDataOnChange}
        selectedValue={selectedTopicValue}
        hideSaveAndClear={true}
        searchWithApi={true}
        handleSearchText={handleSearchText}
        name={"Vendor_Name"}
        Id={"Vendor_ID"}
        type="Vendor"
      />
    </div>
  );
};

export default AddVendorMultiSelect;
