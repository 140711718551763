import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import parse from "html-react-parser";

const SimilarFaq = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleVendor, setToggleVendor] = useState(false);
  const DATA = props?.data;

  function handleToggleAnswer() {
    setToggleVendor(!toggleVendor);
  }
  return (
    <div class="dtrd-faq__qa-wrapper" key={DATA?.FAQ_ID}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
        <p class="dtrd-faq__question">
          <a
            href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/faq/${ReplaceBlankSpaceByHyphen(
              BreakText(DATA?.Question),
              DATA?.FAQ_ID
            )}`}
          >
            {DATA?.Question}
          </a>
        </p>
        <div>
          <FeatherIcon
            icon={toggleVendor ? "chevron-down" : "chevron-right"}
            size={18}
            role="button"
            color={"#ABAFB1"}
            onClick={() => handleToggleAnswer(DATA)}
            style={{ marginLeft: "10px" }}
          />
        </div>
      </div>

      {toggleVendor ? (
        <p class="dtrd-faq__answer">
          <a
            href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/faq/${ReplaceBlankSpaceByHyphen(
              BreakText(DATA?.Question),
              DATA?.FAQ_ID
            )}`}
          >
            {parse(DATA?.Answer)}
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default SimilarFaq;
