import React from "react";
import FeatherIcon from "feather-icons-react";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { useSelector } from "react-redux";

const ShareFaq = (props) => {
  const DATA = props?.data;
  const NEW_URL = window.location.origin;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PAGE_URL = `${window.location.origin}${props?.shareLink}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(PAGE_URL);
    props?.handleToggleMenu();
  };
  return (
    <>
      <div className="share-single-item ">
        <div
          onClick={() => handleCopyToClipboard("twitter share")}
          style={{ width: "100%", padding: "5px", borderBottom: "1px solid #e1e2e9" }}
          className="flex align-items-center p-y-mini"
        >
          {/* <FeatherIcon icon="link" color={"rgb(168, 168, 168)"} size="18" role="button" style={{ margin: "0% 10px" }} /> */}
          <img
            src={require("../../../../../assets/images/Share icons/3.svg").default}
            height={25}
            width={25}
            className="m-x-base"
          />
          <p className="share-desc-title">Copy link</p>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("twitter share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <TwitterShareButton url={PAGE_URL} className="flex align-items-center">
            {/* <FeatherIcon
              icon="twitter"
              // fill={"#181527"}
              size="18"
              className="blurb-detail-social"
              role="button"
            /> */}
            <img
              src={require("../../../../../assets/images/Share icons/4.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Twitter</p>
          </TwitterShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("linkedin share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <LinkedinShareButton url={PAGE_URL} className="flex align-items-center">
            {/* <FeatherIcon fill={true} icon="linkedin" size="18" role="button" className="blurb-detail-social" /> */}
            <img
              src={require("../../../../../assets/images/Share icons/1.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Linkedin</p>
          </LinkedinShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("facebook share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <FacebookShareButton url={PAGE_URL} className="flex align-items-center">
            {/* <FeatherIcon fill={true} icon="facebook" size="18" role="button" className="blurb-detail-social" /> */}
            <img
              src={require("../../../../../assets/images/Share icons/2.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title"> Share on Facebook</p>
          </FacebookShareButton>
        </div>
      </div>
    </>
  );
};

export default ShareFaq;
