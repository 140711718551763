import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import AddSectionAndQuestionPortal from "../addSectionAndQuestionPortal/addSectionAndQuestionPortal";
const AddQuestionWithoutQuestion = (props) => {
  const [showPortal, setShowPortal] = useState(false);
  const hideAddQuestion = props?.hideAddQuestion;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const hideAddAndEditQuestion = props?.hideAddAndEditQuestion;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const proposalQuestionArray = props?.proposalQuestionArray;
  const handleSaveClicked = (val) => {
    props?.handleSaveClicked(val);
    setShowPortal(!showPortal);
  };
  const handleAddQuestionClicked = () => {
    if (!props?.disableAddQuestion) {
      setShowPortal(!showPortal);
    }
  };
  return (
    <>
      {showPortal ? (
        <AddSectionAndQuestionPortal
          handleClose={() => setShowPortal(false)}
          addType={"question"}
          handleSaveClicked={handleSaveClicked}
          data={props?.proposalQuestionArray}
        />
      ) : null}
      <div className="proposal-add-questions-add-question-container">
        <div className="proposal-add-questions-add-question-sub-container">
          <img
            class=" ls-is-cached lazyloaded"
            src={
              require("../../../../assets/images/emptyQuestionSvg.svg").default
            }
            style={{ height: "65px", width: "65px" }}
            alt={"Empty question"}
          />
          <p className="proposal-add-section-no-question-to-display-text">
            {ADD_PROPOSAL?.ADD_Q_NO_QUESTION_TO_DISPLAY}
          </p>
        </div>
        {hideAddQuestion ? null : (
          <div
            // onClick={handleAddQuestionClicked}

            className={`proposal-add-questions-add-question-sub-container`}
          >
            <div
              data-inverted="true"
              // role="button"
              data-tooltip={
                props?.disableAddQuestion
                  ? "Create at least one section to add question"
                  : null
              }
              onClick={handleAddQuestionClicked}
              className={`${
                props?.disableAddQuestion
                  ? "cursor--disable"
                  : "cursor--pointer"
              }`}
              style={{
                border: "1px solid #5570f1",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                padding: "5px 10px",
              }}
            >
              <FeatherIcon icon="plus" size="15" color="#5570f1" />
              <p className="proposal-add-question-add-question-text">
                {ADD_PROPOSAL?.ADD_Q_NEW_QUESTION}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddQuestionWithoutQuestion;
