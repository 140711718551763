import React from "react";

function DashboardTabs(props) {
  const selectedTab = props?.selectedTab;
  return (
    <div
      className="flex"
      style={{ overflowX: "auto" }}
    >
      <div className="flex m-t-base m-x-half-base">
        <h2
          className={`dashboard-filter-tab-index-text ${
            selectedTab == 1 ? "dashboard-filter-tab-index-selected" : null
          }`}
          onClick={() => props?.handleTabClicked(1)}
        >
          {"Visitors"}
        </h2>
      </div>
      <div className="flex m-t-base m-x-half-base">
        <h2
          className={`dashboard-filter-tab-index-text ${
            selectedTab == 2 ? "dashboard-filter-tab-index-selected" : null
          }`}
          onClick={() => props?.handleTabClicked(2)}
        >
          {"Engagements"}
        </h2>
      </div>
      <div className="flex m-t-base m-x-half-base">
        <h2
          className={`dashboard-filter-tab-index-text ${
            selectedTab == 3 ? "dashboard-filter-tab-index-selected" : null
          }`}
          onClick={() => props?.handleTabClicked(3)}
        >
          {"Content"}
        </h2>
      </div>
    </div>
  );
}

export default DashboardTabs;
