import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as NO_CONTENT from "../../../../config/config";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import NewProductCard from "../../../../utlis/hoc/newProductCard/newProductCard";
import ListCount from "../../../../utlis/hoc/productCard/listCount";
import { GetIdFormLink, ToTitleCase } from "../../../../utlis/hof/hof";
import SearchResultFilter from "../../../searchResult/components/filter/searchResultFilter";
import SelectedFilterList from "../../../searchResult/components/filter/selectedFilterList";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import * as SECRET_KEYS from "../../../../config/configKeys";

const ProductTab = (props) => {
  const [pageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [noResultsData, setNoResultsData] = useState(false);
  const [remountComponent, setRemountComponent] = useState(0);
  const [filterView, setFilterView] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const category = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: category?.Name,
  };

  const ProductCategoryData = useEnCryptPostApi(
    `/searchAcrossAllWithFilter`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    setLazyLoadData([]);
    setPageNo(1);
    const rawData = {
      searchText: ToTitleCase(category?.Name),
      searchById: category?.Id,
      type: PARAMS?.tab,
      filters: SelectedFilter,
      page: 1,
      sourcePage: "Product_Categories",
      userId: USER_ID,
      limit: 30,
    };
    ProductCategoryData?.handleTrigger(rawData);
  }, [category?.Name, SelectedFilter]);
  const fetchData = () => {
    setPageNo((prev) => prev + 1);
    const currentPage = pageNo + 1;
    const rawData = {
      searchText: ToTitleCase(category?.Name),
      page: currentPage,
      searchById: category?.Id,
      type: PARAMS?.tab,
      userId: USER_ID,
      limit: 30,
      filters: SelectedFilter,
      sourcePage: "Product_Categories",
    };
    ProductCategoryData?.handleTrigger(rawData);
  };
  const HandlePageNumber = (val) => {
    window.scrollTo(0, 0);
    setPageNo(val);
  };
  useEffect(() => {
    if (ProductCategoryData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(ProductCategoryData?.data?.data));
      } else {
        setLazyLoadData(ProductCategoryData?.data?.data);
      }
    } else if (typeof ProductCategoryData?.data === "object" && ProductCategoryData?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [ProductCategoryData?.data]);

  const handleFilterData = (val) => {
    HandlePageNumber(1);
    let newData = [...SelectedFilter];
    newData.push(val);
    setSelectedFilter(newData);
    setRemountComponent(Math.random());
    setFilterView(false);
  };

  const handleRemoveFilterData = (val) => {
    HandlePageNumber(1);
    const NewData = SelectedFilter?.filter((item) => {
      return val?.name !== item.name;
    });
    setSelectedFilter(NewData);
    setRemountComponent(Math.random());
    setFilterView(false);
  };

  const handleApplyChanges = (val) => {
    if (val?.length > 0) {
      const Type = val[0]?.type;
      const filterSelected = SelectedFilter.filter((list) => {
        return list?.type !== Type;
      });
      const data = _.uniqBy([...filterSelected, ...val], "name");
      setSelectedFilter(data);
    } else setSelectedFilter([]);
    setRemountComponent(Math.random());
    setFilterView(false);
  };

  const handleClearData = (val) => {
    if (SelectedFilter?.length) {
      setSelectedFilter([]);
      setRemountComponent(Math.random());
      setFilterView(false);
    }
  };

  const handleViewFilter = () => {
    setFilterView(!filterView);
  };

  useEffect(() => {
    if (SelectedFilter?.length) {
      const result = SelectedFilter.map((val) => ({
        name: val?.name,
        type: val?.type,
      }));
      handleSearchFiltersClicked(result);
    }
  }, [SelectedFilter]);

  const handleSearchFiltersClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "filters",
      Item_Id: null,
      Target: {
        page: signalTrackingProductObject?.page,
        link: null,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: JSON.stringify(val),
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return !filterView ? (
    <div className="grid-business-profile mobile-1-col tablet-landscape-1-col">
      <div key={remountComponent}>
        <div className="m-xs  flex align-items-center space-between">
          <ListCount
            page={props?.page}
            pageNo={pageNo}
            type={PARAMS?.tab}
            totalNumber={ProductCategoryData?.data?.total}
            searchText={ToTitleCase(category?.Name)}
          />
          <div
            className="product-card-follow-button only-tablet-landscape"
            role={"button"}
            onClick={() => handleViewFilter()}
          >
            <p className="product-card-follow-button-text">Filters</p>
          </div>
        </div>
        <div className="m-x-xs m-t-xs ">
          <SelectedFilterList
            page={pageNo}
            section={`search filters-${pageNo}`}
            data={SelectedFilter}
            handleRemoveFilterData={handleRemoveFilterData}
            handleClearData={handleClearData}
          />
        </div>
        {!lazyLoadData?.length &&
        (ProductCategoryData?.isLoading || typeof ProductCategoryData?.data?.data !== "object") ? (
          <ProductSkeleton />
        ) : lazyLoadData?.length > 0 ? (
          <InfiniteScrollComp
            Data={lazyLoadData}
            hasMoreData={ProductCategoryData?.data?.data?.length >= 30}
            fetchData={fetchData}
          >
            <NewProductCard tabIndex={1} section={`business-landing-page`} page={props?.page} data={lazyLoadData} />
          </InfiniteScrollComp>
        ) : noResultsData ? (
          <div className="search__result data-product-card no-content-card">
            <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.CATEGORY_PAGE_PRODUCT}</div>
          </div>
        ) : null}
        <>
          {/* <PaginatedItems
            page={pageNo}
            totalNumber={ProductCategoryData?.data?.total}
            itemsPerPage={10}
            HandlePageNumber={HandlePageNumber}
            // forcePage={0}
          /> */}
          {/* <MobilePaginatedItems
            page={pageNo}
            totalNumber={ProductCategoryData?.data?.total}
            itemsPerPage={10}
            HandlePageNumber={HandlePageNumber}
            forcePage={0}
          /> */}
        </>
      </div>
      <div className="tablet-landscape-hidden mobile-hidden">
        <SearchResultFilter
          page={pageNo}
          data={ProductCategoryData?.data?.filters}
          handleFilterData={handleFilterData}
          handleRemoveFilterData={handleRemoveFilterData}
          handleApplyChanges={handleApplyChanges}
          searchText={category?.Name}
          handleClearData={handleClearData}
        />
      </div>
    </div>
  ) : (
    <div className="business-profile-landing-page-container">
      <SearchResultFilter
        page={pageNo}
        data={ProductCategoryData?.data?.filters}
        handleFilterData={handleFilterData}
        handleRemoveFilterData={handleRemoveFilterData}
        handleApplyChanges={handleApplyChanges}
        handleClearData={handleClearData}
        searchText={category?.Name}
        tabView={true}
        handleViewFilter={() => setFilterView(false)}
      />
    </div>
  );
};

export default ProductTab;
