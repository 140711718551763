import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterModal from "./filterModal";
// import * as KEYS from "../../../../../config/config";

const FilterMobileView = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleToggleFilter = () => {
    setShowFilterModal(!showFilterModal);
  };

  return (
    <>
      <div className="grid-1-3 tablet-landscape-1-col grid-col-gap-md">
        <div className="only-mobile grid-two-columns m-l-base">
          <button
            className="outlined mute button space-between p-r-xs p-l-xs"
            data-controller="slide-in-toggle"
            data-slide-in-target=".search__filters-mobile-menu"
            onClick={() => handleToggleFilter()}
          >
            {FILTER?.FILTERS}
            <i className="feather icon icon-filter"></i>
          </button>
          {showFilterModal && (
            <FilterModal
              data={props?.data}
              onClose={() => setShowFilterModal(false)}
              handleApplyChanges={(val) => props?.handleApplyChanges(val)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FilterMobileView;
