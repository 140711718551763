import React from "react";
import SelectedCardContent from "./selectedCardContent";

function SelectedChat(props) {
  const data = props?.data;
  const featureActiveList = props?.featureActiveList;
  const VENDOR_ID = props?.VENDOR_ID;
  const activeConversionId = props?.activeConversionId;
  return (
    <div className="chat-room-main-wrapper">
      <div className="chat-room-chat-room-content-main-wrapper">
        <SelectedCardContent
          // activeConversionId={activeConversionId}
          VENDOR_ID={VENDOR_ID}
          // data={data}
          // featureActiveList={featureActiveList}
        />
      </div>
    </div>
  );
}

export default SelectedChat;
