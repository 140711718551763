import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { DecryptId, GetIdFormLink, ToTitleCase } from "../../utlis/hof/hof";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import Questions from "../productPage/component/questions/questions";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import HeaderSEO from "../SEO/header";
import AlternativeProfile from "./component/alternativeProfile/alternativeProfile";
import AlternativeVendorsList from "./component/alternativeVendors/alternativeVendors";

function AlternativeVendors(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  // const VENDOR_NAME = PARAMS?.name ? ToTitleCase(PARAMS?.name) : PARAMS?.name;
  const VENDOR_NAME = PARAMS?.name
    ? PARAMS?.name
    : PARAMS?.vendor
    ? ToTitleCase(PARAMS?.vendor?.replaceAll("-", " "))
    : "";

  const VENDOR_ID = PARAMS?.name
    ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id
    : DecryptId(PARAMS?.id)?.Id;

  return (
    <div id="app">
      {/* <HeaderSEO
        data={`${KEYS?.ALTERNATIVE_VENDOR_LIST?.TITLE} ${VENDOR_NAME}`}
      /> */}
      <Header show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb
              parent={"providerPage"}
              type={"Provider"}
              params={VENDOR_NAME}
            />
          </div>
        </div>
        <div className="container p-y-xs">
          {/* <AlternativeProfile vendorName={VENDOR_NAME} /> */}
          <AlternativeVendorsList
            vendorName={PARAMS?.name ? VENDOR_NAME?.Name : VENDOR_NAME}
            vendorId={VENDOR_ID}
          />
          {/* <div className="simple-card provider-faq m-t-md p-y-md p-x-sm">
            <Questions />
          </div> */}
        </div>
      </div>
      <Footer page={props?.page} />
    </div>
  );
}

export default AlternativeVendors;
