import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../../../../../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../../../../../utlis/hoc/textArea/textArea";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import SingleSelectNewDropdown from "../../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import _ from "lodash";
import { ReplaceBlankSpaceByHyphen } from "../../../../../../utlis/hof/hof";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../../../../../config/configKeys";
import { requestCategorySuccess } from "../../../../../../contexts/reducer/homeReducer";

const RequestCategory = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [successMessage, setSuccessMessage] = useState(null);
  const [CaptchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const recaptchaRef = useRef();
  const dispatch = useDispatch();
  const sitekey = "6Lc6rOAhAAAAAPBNLnyVjqm0S0Ziic9e6EPXDSKv";
  const RequestCategory = useEnCryptPostApi(
    `/addProductCategoryRequest`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const SearchProductCategory = useEnCryptGetApi(
    `/getProductCategory?searchText=${searchText}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    searchText?.length ? false : true
  );

  const handleSubmit = () => {
    setSuccessMessage((prev) => !prev);
    if (!formDetails?.Resource_Name && !searchText) {
      setError({ Resource_Name: "Category name is required" });
    } else if (CaptchaVerified) {
      const RAW_DATA = {
        ...formDetails,
        Resource_Name: formDetails?.Resource_Name ? formDetails?.Resource_Name : searchText,
        Requested_By: authData?.data?.userId,
        Notification_Email: authData?.data?.emailId,
        Resource_Type: "Category",
      };
      const TRIGGER_DATA = {
        Resource_Name: formDetails?.Resource_Name ? formDetails?.Resource_Name : searchText,
        Resource_Type: "Category",
        Requested_By: authData?.data?.userId,
        Notification_Email: authData?.data?.emailId,
        Additional_Information: { ...RAW_DATA },
      };
      RequestCategory?.handleTrigger(TRIGGER_DATA);
      dispatch(requestCategorySuccess(true));
    }
  };

  useEffect(() => {
    if (
      RequestCategory?.data?.Status ||
      RequestCategory?.data?.isVerified ||
      RequestCategory?.data?.isVerified === false
    )
      setTimeout(() => {
        HandleCloseModal();
      }, 5000);
  }, [RequestCategory?.data]);

  var handleSearchWithApi = _.debounce(function (key) {
    setSearchText(key);
  }, 500);

  const onChange = (value) => {
    setCaptchaVerified(true);
  };

  const HandleCloseModal = () => {
    props?.handleClose();
    // recaptchaRef.reset();
  };

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper">
          {RequestCategory?.data ? (
            <div
              style={{
                background: RequestCategory?.data?.Status || RequestCategory?.data?.isVerified ? "#008000" : "#c00",
              }}
              className="success-message-pop-up"
            >
              <h3 className="success-message-style-board">{RequestCategory?.data?.Message}</h3>
            </div>
          ) : null}
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Request category</h1>
          </div>
          <div className="request-category-container">
            <div className="m-b-sm p-r">
              {/* <InputFieldProfile
                label={"Category name"}
                value={formDetails?.Product_Category_Request_Name}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Product_Category_Request_Name: val }))}
                error={error?.Product_Category_Request_Name}
                removeBottomPadding={true}
              /> */}
              <SingleSelectNewDropdown
                handleSelectedData={(val) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    Resource_Name: val,
                  }))
                }
                data={SearchProductCategory?.data?.data}
                placeholder={"Category Name"}
                // flag={true}
                selectedVal={formDetails?.Resource_Name}
                name={"Product_Category_Name"}
                // label={"Category Name"}
                handleSearchWithApi={handleSearchWithApi}
                // handleBlurInput={handleSubmit}
                searchWithApi={true}
                // returnObject={true}
                required={true}
              />
              {RequestCategory?.data?.isVerified ? (
                <span class="input-field-info-text" style={{ color: "#fb574b" }}>
                  Category already available.{" "}
                  <a
                    href={`/category/${ReplaceBlankSpaceByHyphen(
                      RequestCategory?.data?.Product_Category_Name,
                      RequestCategory?.data?.Product_Category_ID
                    )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                    role="button"
                  >
                    {" "}
                    Click here to view
                  </a>
                </span>
              ) : null}
              {error?.Resource_Name ? <span class="input-field-text-error">{error?.Resource_Name}</span> : null}
            </div>
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Enter vendors in this category"}
                value={formDetails?.Vendors}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Vendors: val }))}
                error={error?.Vendors}
                removeBottomPadding={true}
                infoText={true}
              />
            </div>
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Enter products in this category"}
                value={formDetails?.Products}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Products: val }))}
                error={error?.Products}
                removeBottomPadding={true}
                infoText={true}
              />
            </div>
            <div className="m-b-md">
              <TextArea
                value={formDetails?.Description}
                className="m-b-xs"
                label={"Additional information"}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Description: val }))}
                removeMargin={true}
                error={error?.Description}
                subTitleTop={"Maximum 500 characters only."}
              />
            </div>

            <div className="flex align-items-center justify-center m-b-xs">
              <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} onChange={onChange} />
            </div>

            <div className="flex justify-flex-end">
              <div className="request-category-submit-button" onClick={() => handleSubmit()}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default RequestCategory;
