import React from "react";
import { useParams } from "react-router";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import ProfileHelp from "../profile/myAcc/components/profileHelp";
import Footer from "../home/blades/footer";
import ProfileHeader from "../profile/myAcc/components/profileHeader";
import { useSelector } from "react-redux";
import * as SECRET_KEYS from "../../config/configKeys";
const Boards = () => {
  const authData = useSelector((state) => state?.authDetails?.details);

  const PARAMS = useParams();
  const BREAD_CRUMB_BOARDS = [
    { name: "Home", active: true, link: "/" },
    { name: "My Account", active: true, link: "/profile" },
    { name: "Manage Boards", active: false },
  ];

  return (
    <>
      <Header show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb parent={"boards"} data={BREAD_CRUMB_BOARDS} />
          </div>
        </div>
      </div>
      <div class="page-content page-content--reset page-content--top-reset p-r">
        <div class="attached-navbar">
          <div class="container flex space-between align-items-bottom"></div>
        </div>

        <div class="container p-y-md" style={{ maxWidth: "1120px" }}>
          {authData?.data?.userExist ? null : (
            <div
              class="modern-message modern-message--positive modern-message--elevated"
              style={{ maxWidth: "1120px" }}
            >
              <i class="feather icon icon-alert-triangle message__icon"></i>
              <div class="message__content">
                <p class="new-message-text">
                  Create the free account with Octate AI and avoid losing access to the boards, bookmarks and your past
                  history.{" "}
                  <a href="/sign-up" target="_blank">
                    Sign up now!
                  </a>
                </p>
              </div>
            </div>
          )}
          <ProfileHeader text={"Manage boards"} />
          <div class="grid-auto-max tablet-1-col grid-col-gap-lg">
            <div class="my-profile__menu-items grid-three-columns tablet-2-col grid-col-gap-md grid-row-gap-md grid-row-gap-md">
              <a
                class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                href="/board-detail/bookmark/blurbs"
                style={{ marginTop: "24px", maxHeight: "185px" }}
              >
                <div class="modern-card__content text-center">
                  <i class="feather icon icon-bookmark big"></i>
                  <h4 class="m-t-xs" style={{ color: "#000" }}>
                    {" "}
                    Bookmarks{" "}
                  </h4>
                  <p class="text-sm tablet-hidden fw-400 text-grey-3">View Saved Bookmarks</p>
                </div>
              </a>
              <a
                class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                href="/board-detail/like/blurbs"
                style={{ marginTop: "24px", maxHeight: "185px" }}
              >
                <div class="modern-card__content text-center">
                  <i class="feather icon icon-heart big"></i>
                  <h4 class="m-t-xs" style={{ color: "#000" }}>
                    {" "}
                    Likes{" "}
                  </h4>
                  <p class="text-sm tablet-hidden fw-400 text-grey-3">View your likes</p>
                </div>
              </a>
              <a
                class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                href="/board-detail/my-boards"
                style={{ marginTop: "24px", maxHeight: "185px" }}
              >
                <div class="modern-card__content text-center">
                  <i class="feather icon icon-clipboard big"></i>
                  <h4 class="m-t-xs" style={{ color: "#000" }}>
                    {" "}
                    Boards{" "}
                  </h4>
                  <p class="text-sm tablet-hidden fw-400 text-grey-3">View your boards</p>
                </div>
              </a>
            </div>
            <ProfileHelp />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Boards;
