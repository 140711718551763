import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-paste-smart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useUpdateFaqMutation } from "../../../../../adapters/xhr/crudServiceApi";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import LoadingSpinner from "../../../../../utlis/spinner/loadingSpinner";
import InputFieldNew from "../../../../auth/signUp/onBoard/components/InputFieldNew";
const AddOctateFaq = (props) => {
  const [answerValue, setAnswerValue] = useState("");
  const [sequence, setSequence] = useState("");
  const [question, setQuestion] = useState("");
  const [hideFaq, setHideFaq] = useState(false);
  const [categoryDataObject, setCategoryDataObject] = useState({});
  const editData = typeof props?.editData === "object" ? props?.editData : null;
  const [error, setError] = useState(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const quillRef = useRef();
  useEffect(() => {
    if (editData) setQuestion(editData?.Question);
    setAnswerValue(editData?.Answer);
    setHideFaq(editData?.Hide_FAQ);
    setCategoryDataObject({
      Category_Name: editData?.Category_Name,
      Category_ID: editData?.Category_ID,
      Category_Sequence: editData?.Category_Sequence,
    });
    setSequence(editData?.Sequence);
  }, [editData]);
  //   https://thebox-dev.portqii.com/core/help/FAQ
  const AddOrEditFaq = editData?.Octate_FAQ_ID ? `/help/updateFaq` : `/help/FAQ`;
  const AddOrEditFaqApi = editData?.Octate_FAQ_ID ? useUpdateFaqMutation : usePostRequestMutation;
  const OnboardingDetails = useEnCryptPostApi(AddOrEditFaq, KEYS?.END_POINTS?.CORE?.END_POINT_URL, AddOrEditFaqApi);

  const GetExistingData = useEnCryptGetApi(
    `/getFaqCategories`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const faqCategoryData = KEYS?.OCTATE_FAQ_CATEGORIES;
  const handleQuestion = (val) => {
    setQuestion(val);
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (OnboardingDetails?.data?.Status) {
      props?.handleCloseModal();
    }
  }, [OnboardingDetails?.data]);

  const validateForm = (values) => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!values.Email_Address) {
      errors.question = "Email Id is required";
    } else if (!emailRegex.test(values.Email_Address)) {
      errors.question = "Please enter valid email address";
    }
    if (!values.Skeleton_Domain) {
      errors.domain = "Domain uis required";
    }
    return errors;
  };

  const handleSubmit = () => {
    if (editData?.Octate_FAQ_ID) {
      const rawData = {
        Question: question,
        Answer: answerValue,
        Octate_FAQ_ID: editData?.Octate_FAQ_ID,
        Category_Name: categoryDataObject?.Category_Name,
        Category_ID: categoryDataObject?.Category_ID,
        Sequence: sequence,
        Hide_FAQ: hideFaq,
      };
      OnboardingDetails?.handleTrigger(rawData);
    } else {
      const rawData = {
        Question: question,
        Answer: answerValue,
        Category_Name: categoryDataObject?.Category_Name,
        Category_ID: categoryDataObject?.Category_ID,
        Sequence: sequence,
        Hide_FAQ: hideFaq,
      };
      OnboardingDetails?.handleTrigger(rawData);
    }
  };

  function handleAnswerValue(value) {
    setAnswerValue(value);
  }
  //   const imageHandler = (e) => {
  //     const editor = quillRef.current.getEditor();
  //     const input = document.createElement("input");
  //     input.setAttribute("type", "file");
  //     input.setAttribute("accept", "image/*");
  //     input.click();

  //     input.onchange = async () => {
  //       const file = input.files[0];
  //       if (/^image\//.test(file.type)) {
  //         const formData = new FormData();
  //         formData.append("image", file);
  //         const url = res?.data?.url;
  //         editor.insertEmbed(editor.getSelection(), "image", url);
  //       } else {
  //         ErrorToast("You could only upload images.");
  //       }
  //     };
  //   };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [false] }],
          ["bold", "italic", "underline", "strike", "link", "clean"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <div style={{ margin: "2%" }}>
      <div className="sign-up-page-header-text m-b-sm">Create a new question</div>
      <div class="grid-1-3">
        <InputFieldNew
          label={"Sequence"}
          value={sequence}
          changeText={(val) => setSequence(val > 0 ? val : 0)}
          error={error?.sequence}
          type={"number"}
          showLabel={true}
          // required={true}
        />
        <div class="field-company-profile p-r m-b-md" id="field-user__country_id">
          <label className={`input-field-label`}>Category name</label>
          <SingleSelectNewDropdown
            handleSelectedData={(val) => setCategoryDataObject(val)}
            data={faqCategoryData}
            placeholder={"Category name"}
            returnObject={true}
            selectedVal={categoryDataObject}
            name={"Category_Name"}
            getSearchedText={false}
            // handleChangeSearchText={(val) => setVendorName(val)}
            // label={ADD_PRODUCT?.PRODUCT_SEGMENT_LABEL}
          />
        </div>
      </div>
      <InputFieldNew
        label={"Question"}
        value={question}
        changeText={(val) => handleQuestion(val)}
        error={error?.question}
        type={"text"}
        required={true}
        showLabel={true}
      />
      <div className="m-y-sm">
        <ReactQuill
          modules={modules}
          theme="snow"
          value={answerValue}
          onChange={handleAnswerValue}
          placeholder={"Enter answer"}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }} className="m-y-sm">
        <h5 style={{ margin: "0px", fontSize: "16px", fontWeight: "500", color: "#080808" }}>Hide faq</h5>
        <input
          type="checkbox"
          className="checkbox-terms-of-service"
          checked={hideFaq ? true : false}
          onClick={() => setHideFaq(!hideFaq)}
        />
      </div>

      {OnboardingDetails?.isLoading ? (
        <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="create-account-button" onClick={() => handleSubmit()} role={"button"}>
          {editData?.Octate_FAQ_ID ? "Update FAQ" : "Create FAQ"}
        </div>
      )}
    </div>
  );
};

export default AddOctateFaq;
