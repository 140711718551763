import React from "react";
import FeatherIcon from "feather-icons-react";

const InputFieldQuestionTypeList = (props) => {
  const error = props?.error;
  return (
    <div
      className={`${
        props?.removeBottomPadding
          ? "input-field-wrapper-position-relative p-r"
          : "input-field-wrapper-position-relative m-b-lg"
      }`}
    >
      <div
        id="input-field-wrapper"
        class={`input-field-wrapper ${error ? "background-error" : ""}`}
        style={{ border: "1px solid #EFF1F9" }}
      >
        <FeatherIcon
          icon={props?.icon}
          size="17"
          color={"#6E7079"}
          className="input-icons"
        />
        <input
          id={props?.label}
          type={props?.type ? props?.type : "text"}
          value={props?.value}
          name={props?.label}
          disabled={props?.disable ? props?.disable : false}
          placeholder={props?.label}
          onChange={(e) => props?.changeText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value)}
          className={`input-field-text-question-type-list ${
            error ? "background-error" : ""
          }`}
          autoComplete="off"
          style={{ paddingLeft: props?.icon ? "30px" : "10px" }}
        />
      </div>
      {error ? <span class="input-field-question-options-error">{error}</span> : null}
    </div>
  );
};

export default InputFieldQuestionTypeList;
