import _ from 'lodash';
import CryptoJS from 'react-native-crypto-js';
import * as SECRET_KEY from '../../config/config';
import * as TOKEN_KEYS from '../../config/configKeys';

// const TOKEN = KEYS?.CONFIG?.TOKEN;
const TOKEN = TOKEN_KEYS.CONFIG.TOKEN;
export const DecryptApi = (enCryptData, authData) => {
  if (enCryptData?.data) {
    const bytes = CryptoJS.AES.decrypt(enCryptData?.data, TOKEN);
    const enCryptHomeFeedData = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
    if (enCryptData?.token && authData) {
      const signInDetails = {
        ...authData?.data,
        token: enCryptData?.token,
      };
      const isTokenData = {
        data: signInDetails,
        loading: false,
      };
      return {
        data: enCryptHomeFeedData,
        token: isTokenData,
      };
    } else {
      return {
        data: enCryptHomeFeedData,
      };
    }
  } else return null;
};

export const EncryptApiGetData = (data) => {
  const cryptData = CryptoJS.AES.encrypt(JSON.stringify(data), TOKEN).toString();
  return { data: encodeURI(cryptData).replace(/\+/gi, '%2B') };
};

export const EncryptPostData = (data) => {
  const cryptData = CryptoJS.AES.encrypt(JSON.stringify(data), TOKEN).toString();
  return { data: cryptData };
};

//* stringify text to title case
export const ToTitleCase = (str) => {
  return str?.replaceAll('-', ' ')?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
  });
};

//* Returns only selected object to filtered list
export const SelectedArray = (item) => {
  const filterData = item;
  const filterKeys = Object.values(filterData)
    .map((data) => {
      const filter1 = data?.filter((val) => {
        return val?.isSelected;
      });
      if (filter1?.length) return filter1[0];
    })
    .filter((item) => item);
  return filterKeys;
};

export const ReplaceBlankSpaceByHyphen = (val, id) => {
  if (id) {
    const name = val
      ?.trim()
      ?.replace(/[\s|\W]+/g, '-')
      ?.toLowerCase();
    const ID = 1000000 + parseInt(id);
    return name + '-' + ID.toString(16);
  } else {
    return val
      ?.trim()
      ?.replace(/[\s|\W]+/g, '-')
      ?.toLowerCase();
  }
};

export function BreakText(text, limit) {
  if (text?.length <= 45) {
    return text?.trim();
  }
  var shortenedText = text?.substring(0, 45);
  var lastSpaceIndex = shortenedText?.lastIndexOf(' ');
  // If no space is found, break at the character limit
  if (lastSpaceIndex === -1) {
    return shortenedText;
  }
  return text?.substring(0, lastSpaceIndex)?.trim();
}
export const CamelCaseName = (val) => {
  const replaceHyphen = val?.replaceAll('-', ' ');
  return replaceHyphen;
};

export const GetIdFormLink = (val) => {
  const name = val
    ?.trim()
    .replace(/[\s|\W]+/g, '-')
    ?.toLowerCase();
  var Name = val;
  if (/\d/.test(val)) Name = val.slice(0, val.lastIndexOf(' '));
  // const Name = val.slice(0, val.lastIndexOf(" "));
  const Id = parseInt(name?.substr(name?.lastIndexOf('-') + 1, name?.length), 16) - 1000000;
  return { Id, Name };
};
export const EncryptId = (id) => {
  const ID = 1000000 + id;
  return ID.toString(16);
};
export const DecryptId = (id) => {
  const Id = parseInt(id, 16) - 1000000;
  return { Id };
};
export const CapitalizeFirstLetter = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const concatIsSelected = (val, list, Id) => {
  const data = list?.map((value) => {
    if (value?.[Id] == val?.[Id]) {
      return val;
    } else {
      return value;
    }
  });
  return data;
};
export const FilterSelectedData = (val) => {
  const data = val?.filter((value) => {
    return value?.isSelected;
  });

  return data;
};
export const FilterOnKeyChange = (key, list, label) => {
  const filterNameKey = label ? label : 'name';
  const data = list?.filter((value) => {
    return value?.[filterNameKey]?.toLocaleLowerCase().includes(key.toLocaleLowerCase());
  });

  return data;
};
export const ClearIsSelected = (list) => {
  const data = list?.map((value) => {
    if (value?.isSelected == true) {
      return { ...value, isSelected: false };
    } else {
      return value;
    }
  });

  return data;
};

export const validateForms = (values) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@$^*()\[\]{}\\|;:'",<.>/?`\-_=+])(?=.{8,})\S+$/;
  if (!values.emailId) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.EMAIL_REQUIRED;
  } else if (!emailRegex.test(values.emailId)) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.VALID_EMAIL;
  }
  if (!values.firstName) {
    errors.firstName = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.LAST_NAME_REQUIRED;
  }
  if (!values.password) {
    errors.password = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.PASSWORD_REQUIRED;
  } else if (!passwordRegex.test(values.password)) {
    errors.password = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.PASSWORD_PATTERN;
  }
  // if (!values.jobTitle) {
  //   errors.jobTitle = "Job title is required";
  // }
  return errors;
};

export const validateEditProfileForms = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = SECRET_KEY?.CONFIG?.EDIT_PROFILE_ERRORS?.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = SECRET_KEY?.CONFIG?.EDIT_PROFILE_ERRORS?.LAST_NAME_REQUIRED;
  }
  return errors;
};
export const validateEditProfileOnboardForms = (values) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@$^*()\[\]{}\\|;:'",<.>/?`\-_=+])(?=.{8,})\S+$/;
  if (!values.emailId) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.EMAIL_REQUIRED;
  } else if (!emailRegex.test(values.emailId)) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.VALID_EMAIL;
  }
  if (!values.firstName) {
    errors.firstName = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.LAST_NAME_REQUIRED;
  }
  if (!values.password) {
    errors.password = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.PASSWORD_REQUIRED;
  } else if (!passwordRegex.test(values.password)) {
    errors.password = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.PASSWORD_PATTERN;
  }
  return errors;
};

export const validateGetEarlyForms = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = SECRET_KEY?.CONFIG?.GET_EARLY_ACCESS_ERRORS?.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = SECRET_KEY?.CONFIG?.GET_EARLY_ACCESS_ERRORS?.LAST_NAME_REQUIRED;
  }
  if (!values.companyName) {
    errors.companyName = SECRET_KEY?.CONFIG?.GET_EARLY_ACCESS_ERRORS?.COMPANY_NAME_REQUIRED;
  }
  if (!values.email) {
    errors.email = SECRET_KEY?.CONFIG?.GET_EARLY_ACCESS_ERRORS?.EMAIL_REQUIRED;
  }
  return errors;
};

export const validateCompanyProfileLocationForm = (values) => {
  const errors = {};

  if (!values.Building_Name) {
    errors.Building_Name = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_LOCATION_ERRORS?.BUILDING_NAME_REQUIRED;
  }
  if (!values.Address) {
    errors.Address = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_LOCATION_ERRORS?.ADDRESS_REQUIRED;
  }
  if (!values.Country) {
    errors.Country = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_LOCATION_ERRORS?.COUNTRY_REQUIRED;
  }
  if (!values.Pincode) {
    errors.Pincode = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_LOCATION_ERRORS?.PINCODE_REQUIRED;
  }
  return errors;
};

export const validateSignInForm = (values) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!values.emailId) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.EMAIL_REQUIRED;
  } else if (!emailRegex.test(values.emailId)) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.VALID_EMAIL;
  }
  if (!values.password) {
    errors.password = SECRET_KEY?.CONFIG?.SIGN_UP_ERRORS?.PASSWORD_REQUIRED;
  }
  return errors;
};
export const validateForgotPassword = (values) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!values.emailId) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.EMAIL_REQUIRED;
  } else if (!emailRegex.test(values.emailId)) {
    errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.VALID_EMAIL;
  }
  return errors;
};

export const validateDomain = (name, websiteUrl) => {
  // const errors = {};
  // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  // if (!values.emailId) {
  //   errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.EMAIL_REQUIRED;
  // } else if (!emailRegex.test(values.emailId)) {
  //   errors.emailId = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.VALID_EMAIL;
  // }
  const emailDomain = name?.split('.')[0];
  let domain = '';
  if (websiteUrl) {
    domain = websiteUrl
      ?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      ?.split('/')[0]
      ?.split('.')
      .reverse()[1];
  }

  // Check if the name matches the domain
  const status = emailDomain?.toLowerCase() === domain?.toLowerCase();
  return !status;
};

export const validateFAQ = (values) => {
  const errors = {};
  if (!values.Question) {
    errors.Question = SECRET_KEY?.CONFIG?.FAQ_ERRORS?.QUESTION_REQUIRED;
  }
  if (!values.Answer) {
    errors.Answer = SECRET_KEY?.CONFIG?.FAQ_ERRORS?.ANSWER_REQUIRED;
  }
  // else if (values.Answer.length < 50) {
  //   errors.Answer = SECRET_KEY?.CONFIG?.FAQ_ERRORS?.ANSWER_MIN_LENGTH;
  // }
  return errors;
};
export const validateCreateBoard = (values) => {
  const errors = {};
  if (!values.Name) {
    errors.Name = 'Board name is required';
  }
  return errors;
};

export const GetNameInitials = (val) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = val ? [...val?.matchAll(rgx)] : [];
  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || ''))?.toUpperCase();
  return initials ?? 'AN';
};
export const GetIdFromArray = (list, id, filterId) => {
  const Data = list?.length ? list : [];
  return Data?.filter((filter) => {
    return filter[filterId] !== 'Topic';
  })?.map((value) => {
    return value[id];
  });
};
export const FilterSelectedAllData = (dataSelected, CData, Id) => {
  const selectedData = dataSelected?.length ? dataSelected : [];
  const allData = CData?.length ? CData : [];
  // selectedData?.filter((filter) => {
  //   return list?.[Id] !== filter?.[Id];
  // });
  const selectedDataNew = allData.filter((list) => {
    if (selectedData?.filter((list2) => list?.[Id] == list2?.[Id])?.length) {
      return false;
    } else return true;
  });
  return selectedDataNew;
};
export const validateCompanyProfilePage1 = (values) => {
  const errors = {};

  if (!values.Vendor_Name) {
    errors.Vendor_Name = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.ORGANIZATION_REQUIRED;
  }
  // if (!values.About) {
  //   errors.About = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.ABOUT_REQUIRED;
  // }
  // if (!values.Domain_URL) {
  //   errors.Domain_URL =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.DOMAIN_URL_REQUIRED;
  // }
  // if (!values.Business_Focus) {
  //   errors.Business_Focus =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.BUSINESS_FOCUS_REQUIRED;
  // }
  // if (!values.Founded) {
  //   errors.Founded =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.ESTABLISHED_ON_REQUIRED;
  // }
  // if (!values.Company_Size) {
  //   errors.Company_Size =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.ORGANIZATION_SIZE_REQUIRED;
  // }
  // if (!values.Vendor_Type) {
  //   errors.Vendor_Type =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE1_ERRORS?.ORGANIZATION_TYPE_REQUIRED;
  // }
  return errors;
};

export const validateProductExpertCreatePost = (values) => {
  const errors = {};
  console.log(SECRET_KEY?.CONFIG?.REACT_APP_URL);
  if (!values.Description) {
    errors.Description = SECRET_KEY?.CONFIG?.PRODUCT_EXPERT_ERRORS?.DESCRIPTION;
  }
  if (!values.Post_Name) {
    errors.Post_Name = SECRET_KEY?.CONFIG?.PRODUCT_EXPERT_ERRORS?.POST_NAME;
  }
  if (!values.Link) {
    errors.Link = SECRET_KEY?.CONFIG?.PRODUCT_EXPERT_ERRORS?.LINK;
  } else if (!values.Link.includes(SECRET_KEY?.CONFIG?.REACT_APP_URL)) {
    errors.Link = SECRET_KEY?.CONFIG?.PRODUCT_EXPERT_ERRORS?.OCTATE_LINK;
  }

  return errors;
};

export const validateCompanyProfilePage2 = (values) => {
  const errors = {};
  // if (!values.Vendor_HQ_Phone) {
  //   errors.Vendor_HQ_Phone =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE2_ERRORS?.COMPANY_PHONE_NUMBER_REQUIRED;
  // }
  // if (!values.Vendor_HQ_Country) {
  //   errors.Vendor_HQ_Country =
  //     SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE2_ERRORS?.HEADQUARTERS_REQUIRED;
  // }
  return errors;
};
export const ValidateCompanyProfileCustomer = (values) => {
  let errors = {};
  values.forEach((element, index) => {
    if ((element?.Products?.length || element?.UseCases?.length) && !element?.Customer_Name) {
      return (errors.index = index);
    }
  });
  return errors;
};

export const ValidateProductCategory = (values) => {
  let errors = {};
  values.forEach((element, index) => {
    if (!element?.name) {
      return (errors.index = index);
    }
  });
  return errors;
};

export const validateCompanyProfilePage3 = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE3_ERRORS?.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName) {
    errors.lastName = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE3_ERRORS?.LAST_NAME_REQUIRED;
  }
  if (!values.companyName) {
    errors.companyName = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE3_ERRORS?.COMPANY_NAME_REQUIRED;
  }
  if (!values.email) {
    errors.email = SECRET_KEY?.CONFIG?.COMPANY_PROFILE_PAGE3_ERRORS?.EMAIL_REQUIRED;
  }
  return errors;
};

export const validateProductProfilePage1 = (values) => {
  const errors = {};

  if (!values.Product_Name) {
    errors.Product_Name = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_NAME_REQUIRED;
  }
  // if (!values.Vendor_Name) {
  //   errors.Vendor_Name = "Vendor is required";
  // }
  if (!values.Product_Segment) {
    errors.Product_Segment = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_SEGMENT_REQUIRED;
  }
  if (!values.Product_Link) {
    errors.Product_Link = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_LINK_REQUIRED;
  }
  if (!values.Selected_Product_Category?.length) {
    errors.Selected_Product_Category = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_PRODUCT_CATEGORIES;
  }
  return errors;
};
export const validateProductProfilePageWithoutCategory = (values) => {
  const errors = {};

  if (!values.Product_Name) {
    errors.Product_Name = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_NAME_REQUIRED;
  }
  // if (!values.Vendor_Name) {
  //   errors.Vendor_Name = "Vendor is required";
  // }
  if (!values.Product_Segment) {
    errors.Product_Segment = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_SEGMENT_REQUIRED;
  }
  if (!values.Product_Link) {
    errors.Product_Link = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_LINK_REQUIRED;
  }
  // if (!values.Selected_Product_Category?.length) {
  //   errors.Selected_Product_Category = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE1_ERRORS?.PRODUCT_PRODUCT_CATEGORIES;
  // }
  return errors;
};

export const validateProductProfilePage2 = (values) => {
  const errors = {};
  if (values?.Product_Feature?.length >= 1) {
    if (!values?.Product_Feature[0]?.Feature_Group_Name) {
      errors.Feature_Group_Name = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE2_ERRORS?.FEATURE_TITLE_REQUIRED;
    }
    if (!values?.Product_Feature[0]?.Feature_List?.length) {
      errors.Feature_List_Length = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE2_ERRORS?.FEATURE_REQUIRED;
    } else {
      if (!values?.Product_Feature[0]?.Feature_List[0]?.Product_Feature) {
        errors.Product_Feature = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.FEATURE_TITLE_REQUIRED;
      }
      // if (!values?.Product_Feature[0]?.Feature_List[0]?.Feature_Description) {
      //   errors.Feature_Description = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.FEATURE_DESCRIPTION_REQUIRED;
      // }
    }
  } else if (!values?.Product_Feature?.length >= 1) {
    errors.Feature_Group_Name = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE2_ERRORS?.FEATURE_TITLE_REQUIRED;
    errors.Feature_List_Length = SECRET_KEY?.CONFIG?.COMPANY_PRODUCT_PAGE2_ERRORS?.FEATURE_REQUIRED;
  }
  return errors;
};

export const ValidatePricingPlansErrors = (values) => {
  const errors = {};

  if (!values.Plan_Name) {
    errors.Plan_Name = SECRET_KEY?.CONFIG?.PRICING_PLAN_PAGE2_ERRORS?.PLAN_NAME_REQUIRED;
  }
  if (!values.Feature_Description) {
    errors.Feature_Description = SECRET_KEY?.CONFIG?.PRICING_PLAN_PAGE2_ERRORS?.PRODUCT_DESCRIPTION_REQUIRED;
  }
  if (!values.Price) {
    errors.Price = SECRET_KEY?.CONFIG?.PRICING_PLAN_PAGE2_ERRORS?.PRODUCT_PRICING_REQUIRED;
  }
  return errors;
};

export const ValidateFeatureListErrors = (values) => {
  const errors = {};
  // if (!values.Feature_Description) {
  //   errors.Feature_Description = SECRET_KEY?.CONFIG?.FEATURE_LIST_ERRORS?.FEATURE_DESCRIPTION_REQUIRED;
  // }
  if (!values.Product_Feature) {
    errors.Product_Feature = SECRET_KEY?.CONFIG?.FEATURE_LIST_ERRORS?.FEATURE_TITLE_REQUIRED;
  }

  return errors;
};
export const validateRequestVendor = (values) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  if (!values.Resource_Name) {
    errors.Resource_Name = SECRET_KEY?.CONFIG?.REQUEST_VENDOR_ERROR?.REQUEST_NAME;
  }
  if (!values.Domain_URL) {
    errors.Domain_URL = SECRET_KEY?.CONFIG?.REQUEST_VENDOR_ERROR?.DOMAIN_URL;
  } else if (values.Domain_URL?.length) {
    const validDOmain = isValidUrl(values.Domain_URL);
    if (!validDOmain) {
      errors.Domain_URL = SECRET_KEY?.CONFIG?.REQUEST_VENDOR_ERROR?.VALID_DOMAIN_URL;
    }
  }

  if (values.Vendor_Email && !emailRegex.test(values.Vendor_Email)) {
    errors.Vendor_Email = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.VALID_EMAIL;
  }
  if (values.Notification_Email && !emailRegex.test(values.Notification_Email)) {
    errors.Notification_Email = SECRET_KEY?.CONFIG?.SIGN_IN_ERRORS?.VALID_EMAIL;
  }

  return errors;
};

export const ValidateFeatureMainSectionErrors = (values) => {
  const errors = {};

  if (!values.Feature_Group_Name) {
    errors.Feature_Group_Name = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.SECTION_TITLE_REQUIRED;
  }
  if (values?.Feature_List?.length > 0) {
    if (!values?.Feature_List[0].Product_Feature) {
      errors.Product_Feature = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.FEATURE_TITLE_REQUIRED;
    }
    if (!values?.Feature_List[0].Feature_Description) {
      errors.Feature_Description = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.FEATURE_DESCRIPTION_REQUIRED;
    }
  } else {
    errors.Feature_List_Length = SECRET_KEY?.CONFIG?.FEATURE_SECTION_ERRORS?.FEATURE_REQUIRED;
  }

  return errors;
};

export const validateBlurbSavePage = (values) => {
  const errors = {};
  if (!values.title_level_1) {
    errors.title_level_1 = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.BLURB_TITLE_REQUIRED;
  }
  if (!values.description) {
    errors.description = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.BLURB_DESCRIPTION_REQUIRED;
  } else if (values.description?.length < 10) {
    errors.description = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.BLURB_LENGTH;
  }
  if (!values.Content_Type) {
    errors.Content_Type = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.BLURB_JOURNEY;
  }

  if (!values.Page_URL) {
    errors.Page_URL = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.PAGE_URL;
  } else if (values.Page_URL) {
    if (values.Page_URL?.split('https://')[1] || values.Page_URL?.split('http://')[1]) {
    } else {
      errors.Page_URL = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.PAGE_URL_SYNTAX;
    }
  }
  if (values.call_to_action_link) {
    if (values.call_to_action_link?.split('https://')[1] || values.call_to_action_link?.split('http://')[1]) {
    } else {
      errors.call_to_action_link = SECRET_KEY?.CONFIG?.BLURB_SAVE_ERRORS?.PAGE_URL_SYNTAX;
    }
  }
  // if (!values.call_to_action_name) {
  //   errors.call_to_action_name = "Section title is required";
  // }
  // if (!values.call_to_action_link) {
  //   errors.call_to_action_link = "Section title is required";
  // }

  return errors;
};
export const validateUseCaseSave = (values) => {
  const errors = {};
  if (!values.Page_Type) {
    errors.Page_Type = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_TYPE;
  }
  if (!values.title_level_1) {
    errors.title_level_1 = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_TITLE_REQUIRED;
  }
  if (!values.description) {
    errors.description = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_DESCRIPTION_REQUIRED;
  } else if (values.description?.length < 50) {
    errors.description = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_LENGTH;
  }
  if (!values.Product_Name) {
    errors.Use_Cases_Product = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_PRODUCT;
  }
  if (!values.Page_URL) {
    errors.Page_URL = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_PAGE_URL;
  }
  if (values.call_to_action_link) {
    if (values.call_to_action_link?.split('https://')[1] || values.call_to_action_link?.split('http://')[1]) {
    } else {
      errors.call_to_action_link = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_PAGE_URL_SYNTAX;
    }
  }
  if (values.Anonymous_Customer?.Anonymous_Customer_Status) {
    if (!values.Anonymous_Customer?.Anonymous_Customer_Name) {
      errors.Anonymous_Customer_Name = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_ANON_CUSTOMER;
    }
    // if (!values.Anonymous_Customer?.Anonymous_Customer_Country) {
    //   errors.Anonymous_Customer_Country = SECRET_KEY?.CONFIG?.USE_CASE_SAVE_ERRORS?.USE_CASE_ANON_COUNTRY;
    // }
  }
  return errors;
};

export const validateRequestCustomer = (values) => {
  const errors = {};
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  if (!values.Vendor_Name) {
    errors.Vendor_Name = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_VENDOR_REQUIRED;
  }
  if (!values.Domain_URL) {
    errors.Domain_URL = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_URL_REQUIRED;
  } else if (values.Domain_URL?.length) {
    const validDOmain = isValidUrl(values.Domain_URL);
    if (!validDOmain) {
      errors.Domain_URL = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_VALID_URL;
    }
  }
  if (!values.About) {
    errors.About = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_ABOUT_REQUIRED;
  } else if (values.description?.length < 50) {
    errors.description = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_ABOUT_LENGTH;
  }
  if (!values.Founded) {
    errors.Founded = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_YEAR_REQUIRED;
  }
  if (!values.Employee_Range) {
    errors.Employee_Range = SECRET_KEY?.CONFIG?.REQUEST_CUSTOMER_ERRORS?.REQUEST_CUSTOMER_COMPANY_REQUIRED;
  }

  return errors;
};
export const validateAddProposalPage1 = (values) => {
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_PROPOSAL_ERROR_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;
  const errors = {};
  if (!values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_TITLE]?.length >= 1) {
    errors[ADD_PROPOSAL_KEYS?.PROPOSAL_TITLE] = ADD_PROPOSAL_ERROR_KEYS?.PROPOSAL_TITLE;
  }
  if (!values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION]?.length >= 1) {
    errors[ADD_PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION] = ADD_PROPOSAL_ERROR_KEYS?.PROPOSAL_DESCRIPTION;
  }
  // else if (values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION]?.length <= 50)
  //   errors[ADD_PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION] =
  //     ADD_PROPOSAL_ERROR_KEYS?.PROPOSAL_DESCRIPTION_LENGTH;
  return errors;
};
export const validateAddProposalPage2 = (values) => {
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  const ADD_PROPOSAL_ERROR_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;
  const errors = {};
  if (!values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length >= 1) {
    errors[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_LIST;
  } else if (values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length) {
    if (!values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION][0]?.[ADD_SECTION_KEYS?.SECTION_TITLE]) {
      errors[ADD_SECTION_KEYS?.SECTION_TITLE] = ADD_PROPOSAL_ERROR_KEYS?.SECTION_TITLE;
    }
    if (
      values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.filter((ele) => {
        return !ele?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length;
      })?.length
    ) {
      errors[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION] = ADD_PROPOSAL_ERROR_KEYS?.SECTION_WITHOUT_QUESTION;
      const sectionIndex = values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
        ?.map((val, index) => {
          return { ...val, index: index };
        })
        ?.filter((ele) => {
          return !ele?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length;
        });
      errors[ADD_PROPOSAL_KEYS?.SECTION_INDEX] = sectionIndex[0]?.index;
    }
  }

  return errors;
};
export const getIndexOfSectionsAndQuestions = (values) => {
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  if (values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length) {
    const sectionIndex = values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION].map((sec, index) => {
      const checkIfSectionHasQuestion = sec?.[ADD_QUESTION_KEYS?.QUESTION_LIST].length ? sec?.[ADD_QUESTION_KEYS?.QUESTION_LIST] : [];
      const questionIndex = checkIfSectionHasQuestion.map((qes, index) => {
        return { ...qes, Question_Order: index + 1 };
      });
      return { ...sec, [ADD_QUESTION_KEYS?.QUESTION_LIST]: questionIndex, Section_Order: index + 1 };
    });
    return sectionIndex.length ? sectionIndex : [];
  }
};
export const validateAddProposalPage3 = (values) => {
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  const ADD_PROPOSAL_ERROR_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;
  const errors = {};

  if (!values?.[ADD_PROPOSAL_KEYS?.VENDORS]?.length) {
    errors[ADD_PROPOSAL_KEYS?.VENDORS] = ADD_PROPOSAL_ERROR_KEYS?.VENDOR;
  }
  if (!values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length > 0) {
    errors[ADD_PROPOSAL_KEYS?.SECTION_INDEX] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_LIST;
  }
  if (
    values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.filter((ele) => {
      return !ele?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length;
    })?.length
  ) {
    const sectionIndex = values?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
      ?.map((val, index) => {
        return { ...val, index: index };
      })
      ?.filter((ele) => {
        return !ele?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length;
      });
    errors[ADD_PROPOSAL_KEYS?.SECTION_INDEX] = `${sectionIndex[0]?.[ADD_SECTION_KEYS?.SECTION_TITLE]} ${[
      ADD_PROPOSAL_ERROR_KEYS?.SECTION_WITHOUT_QUESTION_WITH_SECTION_NAME,
    ]}`;
  }
  return errors;
};
export const validateProposalQuestionFormError = (values, addType, metaData, allData) => {
  const ADD_PROPOSAL_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  const ADD_PROPOSAL_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const ADD_PROPOSAL_ERROR_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL_QUESTION_ERROR_MESSAGES;
  const errors = {};
  if (addType == 'section') {
    if (!values?.[ADD_PROPOSAL_SECTION_KEYS?.SECTION_TITLE]?.length) {
      errors[ADD_PROPOSAL_SECTION_KEYS?.SECTION_TITLE] = ADD_PROPOSAL_ERROR_KEYS?.SECTION_TITLE;
    } else {
      if (allData?.length > 0) {
        const SectionWithSameName = allData?.filter((item, index) => {
          return (
            item?.[ADD_PROPOSAL_SECTION_KEYS?.SECTION_TITLE]?.toLowerCase() ==
            (values?.[ADD_PROPOSAL_SECTION_KEYS?.SECTION_TITLE]).toLowerCase()
          );
        });
        if (SectionWithSameName?.length > 0) {
          errors[ADD_PROPOSAL_SECTION_KEYS?.SECTION_TITLE] = ADD_PROPOSAL_ERROR_KEYS?.SECTION_WITH_SAME_NAME;
        }
      }
    }
  } else {
    if (!values?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_NAME]?.length) {
      errors[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_NAME] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_TITLE;
    }
    if (!values?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_TYPE]?.length) {
      errors[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_TYPE] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_TYPE;
    }
    if (metaData) {
      if (
        values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]?.length < 2 ||
        values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]?.length == undefined
      ) {
        errors[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_META_DATA;
      }
      if (values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]?.length > 0) {
        const errorQuestionOptions = values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]
          .map((list, index) => {
            return { ...list, index: index };
          })
          .filter((filterQ) => {
            return !filterQ?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_OPTIONS] || filterQ[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_OPTIONS] == '';
          });
        if (errorQuestionOptions?.length) {
          errors[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_OPTIONS] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_META_DATA_OPTIONS;

          errors['index'] = errorQuestionOptions[0]['index'];
        }
      }
    } else if (
      values?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_TYPE] == 'Check Box' ||
      values?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_TYPE] == 'Radio Button'
    ) {
      errors[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA] = ADD_PROPOSAL_ERROR_KEYS?.QUESTION_META_DATA;
    }
  }

  return errors;
};
export const validateAddPermissionSetError = (values, allData) => {
  let errors = {};
  if (!values?.Permission_Set_Name?.length) {
    errors['Permission_Set_Name'] = 'Permission set title is required';
  } else {
    if (allData?.length > 0) {
      const SectionWithSameName = allData?.filter((item, index) => {
        return (
          item?.Permission_Set_Name?.toLowerCase() == (values?.Permission_Set_Name).toLowerCase() &&
          item?.Permission_Set_ID !== values?.Permission_Set_ID
        );
      });
      if (SectionWithSameName?.length > 0) {
        errors['Permission_Set_Name'] = 'Permission set cannot have same name';
      }
    }
  }
  return errors;
};
export const validateProposalAnswerFormError = (values, addType) => {
  const ADD_PROPOSAL_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  const ADD_PROPOSAL_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const ADD_PROPOSAL_ERROR_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL_QUESTION_ERROR_MESSAGES;
  const errors = {};
  if (addType == 'text') {
    if (!values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]?.[ADD_PROPOSAL_QUESTION_KEYS?.ANSWER]) {
      errors['Answer_Error'] = ADD_PROPOSAL_ERROR_KEYS?.ANSWER_ERROR;
    }
  } else {
    if (values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA]?.length > 0) {
      const errorQuestionOptions = values?.[ADD_PROPOSAL_QUESTION_KEYS?.PROPOSAL_META_DATA].filter((filterQ) => {
        return filterQ?.[ADD_PROPOSAL_QUESTION_KEYS?.ANSWER];
      });
      if (!errorQuestionOptions?.length) {
        errors['Answer_Error'] = ADD_PROPOSAL_ERROR_KEYS?.ANSWER_ERROR;
      }
    }
  }

  return errors;
};
export const concatIsSelectedAllData = (list, listIsSelected, Id) => {
  // const a3 = list.map((t1) => ({
  //   ...t1,
  //   ...listIsSelected.find((t2) => t1.id !== t2.id),
  // }));
  // return a3;
  const selectedData = list?.length
    ? list?.map((value) => {
        return { ...value, isSelected: true };
      })
    : [];

  const a3 = listIsSelected?.length
    ? [...selectedData, ...listIsSelected.filter((item) => !selectedData.some((other) => other?.[Id] === item?.[Id]))]
    : selectedData?.length
    ? [...selectedData]
    : [];
  return a3;
};
export const ConcatSelectedUseCase = (list, listIsSelected, Id) => {
  const selectedData = list?.length
    ? list?.map((value) => {
        return { ...value, isSelected: true };
      })
    : [];

  const a3 = listIsSelected?.length
    ? [
        ...listIsSelected.map((item) => {
          var match = selectedData.find((val) => {
            return val?.[Id] === item?.[Id];
          });
          if (match) {
            return match;
          } else {
            return item;
          }
        }),
      ]
    : selectedData?.length
    ? [...selectedData]
    : [];
  return a3;
};
export const GetTotalQuestions = (val) => {
  const ADD_PROPOSAL_QUESTION_KEYS = SECRET_KEY?.CONFIG?.ADD_QUESTION;
  const ADD_PROPOSAL_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  const questionArray = val
    ?.map((list) => {
      return list?.[ADD_PROPOSAL_QUESTION_KEYS?.QUESTION_LIST]?.length;
    })
    .reduce((a, b) => a + b, 0);
  return questionArray;
};
export const TrackQuestionAdded = (oldValue, newValue) => {
  const value1 = JSON.stringify(oldValue);
  const value2 = JSON.stringify(newValue);
  let ArrayOfIds = [];
  if (value1 !== value2) {
    const newQuestionAdded = newValue.map((val) => {
      return val.Question_List.filter((filter1) => {
        return filter1.Status !== 'Published';
      }).map((ids) => {
        if (ids?.Proposal_Question_ID) {
          ArrayOfIds.push(ids?.Question_Text);
          return ids?.Proposal_Question_ID;
        } else {
          ArrayOfIds.push(ids?.Question_Text);
          return ids?.Question_Text;
        }
      });
    });

    return {
      QuestionId: ArrayOfIds,
      Changed: true,
    };
  } else {
    return {
      QuestionId: null,
      Changed: false,
    };
  }
};
export const TrackVendorAdded = (oldValue, newValue) => {
  const value1 = JSON.stringify(oldValue);
  const value2 = JSON.stringify(newValue);
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_PROPOSAL_SECTION_KEYS = SECRET_KEY?.CONFIG?.ADD_SECTION;
  if (value1 !== value2) {
    const newQuestionAdded = newValue
      .filter((filter1) => {
        return filter1?.Status == null;
      })
      .map((list) => {
        return list?.Vendor_ID;
      });
    const DeletedVendorsOld = oldValue
      .filter((val) => {
        return val?.Status !== null;
      })
      .map((vId) => {
        return vId?.[ADD_PROPOSAL_KEYS?.VENDOR_ID];
      });
    const DeletedVendorsNew = newValue
      .filter((val) => {
        return val?.Status !== null;
      })
      .map((vId) => {
        return vId?.[ADD_PROPOSAL_KEYS?.VENDOR_ID];
      });

    const DeletedVendorIds = DeletedVendorsOld.filter((x) => !DeletedVendorsNew.includes(x));
    return {
      NewVendor: newQuestionAdded,
      DeletedVendor: DeletedVendorIds,
      Changed: true,
    };
  } else {
    return {
      QuestionId: null,
      Changed: false,
    };
  }
};
export const TrackAnswerChangedAdded = (oldValue, newValue) => {
  const value1 = JSON.stringify(oldValue);
  const value2 = JSON.stringify(newValue);
  return value1 == value2;
  // if (value1 !== value2) {
  //   const newQuestionAdded = newValue.map((val) => {
  //     return val.Question_List.filter((filter1) => {
  //       return filter1.Status !== "Published";
  //     }).map((ids) => {
  //       if (ids?.Proposal_Question_ID) {
  //         ArrayOfIds.push(ids?.Question_Text);
  //         return ids?.Proposal_Question_ID;
  //       } else {
  //         ArrayOfIds.push(ids?.Question_Text);
  //         return ids?.Question_Text;
  //       }
  //     });
  //   });

  //   return {
  //     QuestionId: ArrayOfIds,
  //     Changed: true,
  //   };
  // } else {
  //   return {
  //     QuestionId: null,
  //     Changed: false,
  //   };
  // }
};
export const CheckIfAnswerChanged = (oldValue, newValue) => {
  const value1 = JSON.stringify(oldValue);
  const value2 = JSON.stringify(newValue);
  let ArrayOfIds = [];
  if (value1 !== value2) {
    const answerEdited = newValue.map((val) => {
      return val.Question_List.filter((filter1) => {
        return filter1.edited;
      }).map((ids) => {
        if (ids?.Proposal_Question_ID) {
          ArrayOfIds.push(ids?.Proposal_Question_ID);
          return ids?.Proposal_Question_ID;
        } else {
          ArrayOfIds.push(ids?.Proposal_Question_ID);
          return ids?.Question_Text;
        }
      });
    });
    return {
      QuestionId: ArrayOfIds,
      Changed: true,
    };
  } else {
    return {
      QuestionId: null,
      Changed: false,
    };
  }
};
export const CheckIfVendorProposalPublished = (value) => {
  let NewQuesiton = false;
  value.forEach((data) => {
    var newQuestionArray = data.Question_List.filter((question) => question.Status == 'Published');

    if (newQuestionArray.length > 0) NewQuesiton = true;
  });
  return NewQuesiton;
};

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const getDomainFromUrl = (url) => {
  // Remove the protocol part (e.g., "https://", "http://")
  let domain = url?.replace(/^(https?:\/\/)?(www\.)?/, '');

  // Remove everything after the first slash ("/")
  domain = domain?.split('/')[0];

  // Remove port numbers if present
  domain = domain?.split(':')[0];

  return domain;
};
