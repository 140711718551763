import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import EmailVerificationOctate from "../emailVerification/emailVerificationOctate";
import OtpInputNew from "./otpInputNew";

const SignUpOtpVerification = (props) => {
  const tempDetails = useSelector((state) => state?.authDetails?.tempDetails);
  return tempDetails?.verifyOTP ? (
    <>
      <main>
        <div class="page-content page-content--reset ">
          <div class="splitted-page">
            <EmailVerificationOctate />
            <OtpInputNew />
          </div>
        </div>
      </main>
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default SignUpOtpVerification;
