import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetFaqQuery, useGetRequestCrudQuery } from "../../../../adapters/xhr/crudServiceApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import AddBlurbOrProductPortal from "../businessProfileHeader/components/addBlurbOrProductPortal";
import FeatherIcon from "feather-icons-react";
import ProductFaq from "./components/productFaq";
import TabHeader from "./components/tabHeader";
import VendorFaq from "./components/vendorFaq";
import * as NO_CONTENT from "../../../../config/config";
import AddOctateFaqPortal from "./components/addOctateFaqPortal";
import _ from "lodash";

const FaqTab = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showVendorFAQ, setShowVendorFAQ] = useState(true);
  const [showProductFAQ, setShowProductFAQ] = useState(false);
  const [editFAQData, setEditFAQData] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FAQ = KEYS?.FREQUENTLY_ASKED_QUESTION;
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const getOctateVendorID =
    typeof octateVendorSelector === "object" && octateVendorSelector?.length
      ? octateVendorSelector[0].Vendor_ID === vendorDetails?.Vendor_ID
      : null;
  const VENDOR_ID = props?.VENDOR_ID;
  const permissionsDetails = props?.permissionsDetails;
  const getOctateVendorIDApi = getOctateVendorID
    ? `/getHelpFaq`
    : `/getFAQforVendorsAndProducts?Vendor_Id=${VENDOR_ID}&status=${vendorDetails?.Vendor_Status}`;
  const GetApiData = useEnCryptGetApi(
    getOctateVendorIDApi,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );
  const faqCategoryData = KEYS?.OCTATE_FAQ_CATEGORIES;
  const listNew = _.sortBy(faqCategoryData, (val) => val.Category_Sequence).map((val) => {
    return val.Category_Name;
  });
  const PRODUCT_KEYS = Object.keys(GetApiData?.data?.data?.ProductsFAQ ? GetApiData?.data?.data?.ProductsFAQ : []);
  const OCTATE_CATEGORY_KEYS = typeof GetApiData?.data?.data === "object" ? Object.keys(GetApiData?.data?.data) : [];
  const handleCloseModal = () => {
    setShowModal(false);
    // document.body.style.overflow = "auto";
    setEditFAQData({});
  };

  const handleModalVisible = () => {
    // window.scroll(0, 0);
    setTimeout(() => {
      setShowModal(true);
      // document.body.style.overflow = "hidden";s
    }, 100);
  };

  const handleEditFAQ = (val) => {
    setEditFAQData(val);
    handleModalVisible();
  };

  return (
    <>
      {showModal && getOctateVendorID ? (
        <AddOctateFaqPortal VENDOR_ID={VENDOR_ID} handleCloseModal={handleCloseModal} data={editFAQData} tabIndex={9} />
      ) : showModal ? (
        <AddBlurbOrProductPortal VENDOR_ID={VENDOR_ID} handleClose={handleCloseModal} data={editFAQData} tabIndex={9} />
      ) : null}
      {/* //*Frequently asked questions for vendor */}
      <div className="business-profile-teams-container" style={{ padding: "0px 10px" }}>
        <div class="">
          {(props?.type !== "buyer" || authData?.data?.octateSuperAdmin) && (
            <TabHeader
              getOctateVendorID={getOctateVendorID}
              permissionsDetails={permissionsDetails}
              tabIndex={props?.tabIndex}
              type={props?.type}
            />
          )}
          {getOctateVendorID ? null : (
            <div class="provider-faq m-b-md">
              <div
                style={{ background: "#f1f3f9", padding: "8px 15px 8px 15px", borderRadius: "4px" }}
                className="flex space-between"
              >
                <h3 style={{ margin: "0px", color: "#45464e;" }}>
                  {FAQ?.TITLE} {vendorDetails?.Vendor_Name}
                </h3>
                <FeatherIcon
                  icon={showVendorFAQ ? "chevron-down" : "chevron-right"}
                  size="20"
                  role="button"
                  // className="blurb-detail-social"
                  color={"#ABAFB1"}
                  onClick={() => setShowVendorFAQ(!showVendorFAQ)}
                />
              </div>
              {GetApiData?.data?.data?.vendorFAQ?.length > 0 ? (
                showVendorFAQ ? (
                  <div class="dtrd-faq dtrd-faq--static">
                    {GetApiData?.data?.data?.vendorFAQ?.map((item) => {
                      return (
                        <VendorFaq data={item} permissionsDetails={permissionsDetails} handleEditFAQ={handleEditFAQ} />
                      );
                    })}
                  </div>
                ) : null
              ) : GetApiData?.data?.data?.vendorFAQ?.length === 0 ? (
                <div className="search__result data-product-card no-content-card m-t-sm">
                  <div className="no-content-text">
                    {props?.type === "buyer"
                      ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_FAQ_VENDOR
                      : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_FAQ_VENDOR}
                  </div>
                </div>
              ) : null}
            </div>
          )}

          {/* //*Frequently asked questions for product */}
          {/* {props?.type !== "buyer" && ( */}
          <div class="provider-faq ">
            <div
              style={{ background: "#f1f3f9", padding: "8px 15px 8px 15px", borderRadius: "4px" }}
              className="flex space-between"
            >
              <h3 style={{ margin: "0px" }}>{` ${FAQ?.TITLE} ${vendorDetails?.Vendor_Name} ${
                getOctateVendorID ? "" : "Products"
              }`}</h3>
              <FeatherIcon
                icon={showProductFAQ ? "chevron-down" : "chevron-right"}
                size="20"
                role="button"
                // className="blurb-detail-social"
                color={"#ABAFB1"}
                onClick={() => setShowProductFAQ(!showProductFAQ)}
              />
            </div>
            {getOctateVendorID ? (
              showProductFAQ ? (
                listNew?.map((ObjectKey) => {
                  return (
                    <ProductFaq
                      data={GetApiData?.data?.data}
                      ObjectKey={ObjectKey}
                      handleEditFAQ={handleEditFAQ}
                      type={props?.type}
                      permissionsDetails={permissionsDetails}
                      getOctateVendorID={getOctateVendorID}
                    />
                  );
                })
              ) : null
            ) : PRODUCT_KEYS?.length ? (
              showProductFAQ ? (
                PRODUCT_KEYS?.map((ObjectKey) => {
                  return (
                    <ProductFaq
                      data={GetApiData?.data?.data?.ProductsFAQ}
                      ObjectKey={ObjectKey}
                      handleEditFAQ={handleEditFAQ}
                      type={props?.type}
                      permissionsDetails={permissionsDetails}
                    />
                  );
                })
              ) : null
            ) : !PRODUCT_KEYS?.length && !getOctateVendorID ? (
              <div className="search__result data-product-card no-content-card m-t-sm">
                <div className="no-content-text">
                  {props?.type === "buyer"
                    ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_FAQ_PRODUCT
                    : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_FAQ_PRODUCT}
                </div>
              </div>
            ) : null}
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default FaqTab;
