import React from "react";
import CommentInput from "./components/commentInput";
import CommentList from "./components/commentList";
import { detectMob } from "../../hof/hof";

const Comments = (props) => {
  const cardType = props?.cardType;
  const page = props?.page;
  const MOBILE_DEVICE = detectMob();
  return (
    <div className="m-x-sm" style={MOBILE_DEVICE ? { width: "90%" } : { width: "78%" }}>
      <CommentInput cardType={cardType} page={page} data={props} handleCommentCount={props?.handleCommentCount} />
      <CommentList data={props} handleCommentCount={props?.handleCommentCount} cardType={cardType} />
    </div>
  );
};

export default Comments;
