import React from "react";

const DataProviderAdditionalInfo = (props) => {
  const DATA = props?.data;
  return (
    <>
      {DATA?.additional_Info?.length > 0 ? (
        <div className="vertical-provider-card__facts">
          {DATA?.additional_Info?.map((val, index) => {
            return (
              <>
                <div className="vertical-provider-card__fact-icon">
                  {val?.value}
                </div>
                <div className="vertical-provider-card__fact-text">
                  {val?.label}
                </div>
              </>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default DataProviderAdditionalInfo;
