import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import UserManageTable from "./components/userManageTable";
import { useSelector } from "react-redux";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { useGetRequestCrudQuery } from "../../../../adapters/xhr/crudServiceApi";
import InviteUser from "./components/inviteUser";

const UserManagement = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const [showInvited, setInvited] = useState(true);
  const [showPending, setShowPending] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);
  const [showRejected, setShowRejected] = useState(false);

  const GetApiData = useEnCryptGetApi(
    `/getUserStatusForAdmin?vendorId=${authData?.data?.vendorId}&userId=${authData?.data?.userId}&type=Invited`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );

  // const data = [
  //   {
  //     userId: 453,
  //     userName: "Niranjan",
  //     emailAddress: "Niranjan@portqii.com",
  //     date: "12-08-2023",
  //     action: [
  //       { buttonText: "Accept", buttonColor: "green" },
  //       { buttonText: "Reject", buttonColor: "red" },
  //     ],
  //   },
  // ];

  return (
    <div>
      <InviteUser />
      <div className="business-profile-teams-container">
        <div className="business-teams-header">
          <h3 className="team-management-title">Invited</h3>
          <FeatherIcon
            icon={showInvited ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setInvited((prev) => !prev)}
          />
        </div>
        {showInvited ? (
          <UserManageTable
            type={"pending"}
            tableType={"Invited"}
            tableData={GetApiData?.data}
            tableHeader={KEYS?.INVITED_TABLE_HEADER}
          />
        ) : null}
      </div>
      <div className="business-profile-teams-container">
        <div className="business-teams-header">
          <h3 className="team-management-title">Request</h3>
          <FeatherIcon
            icon={showPending ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setShowPending(!showPending)}
          />
        </div>
        {showPending ? (
          <UserManageTable
            type={"pending"}
            tableType={"Requested"}
            tableData={GetApiData?.data}
            tableHeader={KEYS?.RequestedTableHeader}
          />
        ) : null}
      </div>
      <div className="business-profile-teams-container">
        <div className="business-teams-header">
          <h3 className="team-management-title">Accepted </h3>
          <FeatherIcon
            icon={showAccepted ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setShowAccepted(!showAccepted)}
          />
        </div>
        {showAccepted ? (
          <UserManageTable
            type={"accepted"}
            tableType={"Accepted"}
            tableData={GetApiData?.data?.data?.accepted}
            tableHeader={KEYS?.AcceptedTableHeader}
          />
        ) : null}
      </div>
      <div className="business-profile-teams-container">
        <div className="business-teams-header">
          <h3 className="team-management-title">Rejected</h3>
          <FeatherIcon
            icon={showRejected ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setShowRejected(!showRejected)}
          />
        </div>
        {showRejected ? (
          <UserManageTable
            tableData={GetApiData?.data?.data?.rejected}
            tableHeader={KEYS?.RejectedTableHeader}
            type={"rejected"}
            tableType={"Rejected"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UserManagement;
