import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductProfileSkeleton = () => {
  return (
    <SkeletonTheme>
      <div>
        <div class="product-hero__header">
          <div class="product-hero__header-image">
            <Skeleton width={"100%"} height={"100%"} />
          </div>
          <div class="product-hero__header-title" style={{ width: "80%" }}>
            <Skeleton
              height={30}
              width={"50%"}
              style={{ alignSelf: "center" }}
            />
            <Skeleton
              height={15}
              width={"50%"}
              style={{ alignSelf: "center", marginTop: "2%" }}
            />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default ProductProfileSkeleton;
