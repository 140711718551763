import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "../../../../../utlis/customHooks/useLocalStorage";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";

const HeaderDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [authUser, setUser] = useLocalStorage("user");
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const navigate = useNavigate();
  const pagePath = window.location.href;

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const ProductExpertStatus = useEnCryptGetApi(
    `/getProductExpertStatus?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );

  const GetVendorStatus = useEnCryptGetApi(
    `/getVendorStatusForUser?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  useEffect(() => {
    if (GetVendorStatus?.data?.data) {
      setUser({ ...authData?.data, ...GetVendorStatus?.data?.data });
    }
  }, [GetVendorStatus?.data?.data]);

  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  useEffect(() => {
    if (ProductExpertStatus?.data?.data?.status) {
      setUser({
        ...authData?.data,
        isProductExpert: ProductExpertStatus?.data?.data?.isProductExpert,
        isProductExpertPersona: ProductExpertStatus?.data?.data?.isProductExpertPersona,
      });
    }
  }, [ProductExpertStatus?.data?.data]);

  const BUYER = [
    {
      id: 3,
      name: "Manage Proposals",
      navigate: "/proposals/my-proposal",
      activeMode: "/proposals/",
    },
    {
      id: 4,
      name: "Edit Profile",
      navigate: "/profile/edit-profile/user-profile",
      activeMode: "/profile",
    },
    {
      id: 5,
      name: "Manage Boards",
      navigate: "/user-boards",
      activeMode: "/user-boards",
    },
    {
      id: 6,
      name: "Preferences",
      navigate: "/manage-preferences",
      activeMode: "/manage-preferences",
    },
    {
      id: 6,
      name: "Sign out",
      navigate: "/sign-out",
    },
  ];

  const BUSINESS_USER = [
    {
      id: 1,
      name: "Business Profile",
      navigate: "/business-profile/blurbs",
      activeMode: "/business-profile",
    },
    {
      id: 2,
      name: "Sales Hub",
      navigate: "/sales-hub/proposals",
      activeMode: "/sales-hub/",
    },
  ];

  const MANAGE_BUSINESS = [
    {
      id: 1,
      name: "Manage Business",
      navigate: "/profile/edit-profile/business-details",
      activeMode: "/join-organization",
    },
  ];
  const ANON_USER = [
    {
      id: 7,
      name: "Edit Profile",
      navigate: "/profile/edit-profile/user-profile",
      activeMode: "/profile",
    },
    {
      id: 8,
      name: "Manage Boards",
      navigate: "/user-boards",
      activeMode: "/user-boards",
    },
    {
      id: 9,
      name: "Sign in",
      navigate: "/sign-in",
    },
  ];

  const PRODUCT_EXPERT = [
    {
      id: 10,
      name: "Product Expert",
      navigate:
        authData?.data?.isProductExpert && authData?.data?.isProductExpertPersona
          ? "/product-expert/home/posts"
          : "/product-expert/linkedin-access",
      activeMode: "/product-expert/",
    },
  ];
  const OPTION_LIST = authData?.data?.userExist
    ? (authData?.data?.requestAccepted || authData?.data?.adminStatus) && !authData?.data?.buyerView
      ? [...BUSINESS_USER, ...BUYER]
      : authData?.data?.isProductExpert || authData?.data?.isProductExpertPersona
      ? [...PRODUCT_EXPERT, ...BUYER]?.filter((item) => item?.id !== 3)
      : authData?.data?.isValidDomain && !(authData?.data?.requestAccepted || authData?.data?.adminStatus)
      ? [...MANAGE_BUSINESS, ...BUYER]
      : [...BUYER]
    : ANON_USER;
  const SignOutUser = () => {
    const signalTrackingPostData = {
      Actor: authUser?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: SECRET_KEYS?.CONFIG?.SIGN_OUT,
      Item_Type: null,
      Item_Id: null,
      User_Type: "Known",
      Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      Target: {
        page: "home",
        link: "/",
      },
      Object: {
        section: null,
        context: "Sign out",
        page: "profile page",
        Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    setTimeout(() => {
      setUser("logout");
      window.location.replace("/");
    }, 1000);
  };
  const handleNavigatePage = (val) => {
    if (val === "/sign-out") {
      SignOutUser();
    } else {
      navigate(val?.navigate);
    }
  };

  const handleCloseModal = () => {
    setVisible(false);
    props?.handleClose();
  };

  const handleSwitchPressed = () => {
    const tempData = { ...authData?.data, buyerView: !authData?.data?.buyerView };
    // dispatch(authDetails({ loading: false, data: tempData }));
    setUser(tempData);

    if (pagePath?.includes("/business-profile/")) {
      navigate(
        `/vendor/${ReplaceBlankSpaceByHyphen(vendorDetails?.Vendor_Name, vendorDetails?.Vendor_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
        }`,
        { replace: true }
      );
    } else if (pagePath?.includes("/vendor/") && vendorDetails?.Vendor_ID === authData?.data?.vendorId) {
      navigate(`/business-profile/blurbs`, { replace: true });
    } else if (pagePath?.includes("/sales-hub/")) {
      navigate(`/`, { replace: true });
    }
    handleCloseModal();
  };

  return (
    <div
      className={`header-drawer-container ${visible ? "show-drawer" : "hide-drawer"}`}
      // onClick={() => handleCloseModal()}
      //   tabIndex={0}
      //   onBlur={handleBlur}
    >
      <div className={`header-drawer-view-container ${visible ? "slideIn-drawer" : "slideOut-drawer"}`}>
        <div className="header-drawer-view-sub-container">
          <div className="flex-container space-between m-b-mini">
            <h1 className="header-drawer-title">Profile</h1>
            <a className="slide-in__close-btn closeButton" onClick={() => handleCloseModal()}>
              <i className="feather icon icon-x m-none"></i>
            </a>
          </div>
          <div className="flex-container">
            {/* <img
              src={require("../../../../../assets/images/profileIconPlaceholder.png")}
              className="profile-modal-profile-placeholder-image"
            /> */}
            <h3 className="drawer-image-initials">
              {authData?.data?.firstName && authData?.data?.userExist
                ? authData?.data?.firstName?.slice(0, 1)
                : authData?.data?.anonymousName?.slice(0, 1)}
            </h3>
            <div className="m-l-mini">
              <Link to="/profile/edit-profile/user-profile">
                <h3 className="profile-modal-username">
                  {authData?.data?.userName?.length > 1 && authData?.data?.userExist
                    ? authData?.data?.userName
                    : authData?.data?.firstName && authData?.data?.userExist
                    ? authData?.data?.firstName
                    : authData?.data?.anonymousName}
                  {authData?.data?.requestAccepted || authData?.data?.adminStatus ? (
                    <span className="profile-modal-active-mode">{authData?.data?.buyerView ? "Buyer Mode" : "Vendor Mode"}</span>
                  ) : null}
                </h3>
              </Link>
              {authData?.data?.userExist ? (
                <h4 className="header-drawer-email">{authData?.data?.emailId}</h4>
              ) : (
                <h4 className="header-drawer-create-new-account" onClick={() => navigate("/sign-up")}>
                  Create new account
                </h4>
              )}
            </div>
          </div>
          {authData?.data?.requestAccepted || authData?.data?.adminStatus ? (
            <div style={{ marginLeft: "68px" }}>
              <h3 className="switch-text-on-profile-sub-menu" onClick={() => handleSwitchPressed()}>
                Switch to {authData?.data?.buyerView ? "Business" : "Buyer"} Mode
              </h3>
            </div>
          ) : null}
          {OPTION_LIST?.map((item) => {
            return item?.navigate === "/sign-out" ? (
              <h3 className="profile-modal-drawer-items" onClick={() => SignOutUser()}>
                {item?.name}
              </h3>
            ) : (
              // <Link to={item?.navigate}>
              <h3
                className={`profile-modal-drawer-items ${pagePath?.includes(item?.activeMode) ? "profile-modal-drawer-items-active" : ""}`}
                onClick={() => handleNavigatePage(item)}
              >
                {item?.name}
              </h3>
              // </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeaderDrawer;
