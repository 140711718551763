import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useResolvedPath } from "react-router";
import { updateMetaTags } from "../../../src/components/SEO/seo";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../config/configKeys";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import InfiniteScrollComp from "../../utlis/hoc/infiniteScroll/infiniteScroll";
import NewBlurbCard from "../../utlis/hoc/newBlurbCard/newBlurbCard";
import NewProductCard from "../../utlis/hoc/newProductCard/newProductCard";
import NewUseCaseCard from "../../utlis/hoc/newUseCaseCard/newUseCaseCard";
import NewVendorCard from "../../utlis/hoc/newVendorCard/newVendorCard";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import SearchResultFilter from "./components/filter/searchResultFilter";
import NoResult from "./components/noResult";
import SearchHeaderBreadCrumb from "./components/seachHeader/searchHeaderBreadCrumb";
import SearchResultHeader from "./components/searchResultHeader/searchResultHeader";
import ProductSkeleton from "./components/skeleton/productSkeleton";
const SearchResult = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminSelector = useSelector((state) => state?.authDetails?.superAdminStatus);
  const [searchedData, setSearchedData] = useState([]);
  const USER_ID = authData?.data?.userId;
  const [PageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [tabletFilterView, setTabletFilterView] = useState(false);
  const [searchResultTab, setSearchResultTab] = useState([]);
  const scrollPosition = useScrollPosition();
  // const [DeCryptSearchResult, setDeCryptSearchResult] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.FILTERS,
    page: null,
    section: `search filters-${PageNo}`,
  };
  const PARAMS = useParams();
  const RESOLVED_PATH = useResolvedPath();
  const SearchText = PARAMS?.name?.replaceAll("-", " ");
  const query = new URLSearchParams(window.location.search);
  const TextByParams = query.get("searchedText");
  const page = `${props?.page}`;

  useEffect(() => {
    const seoBody = {
      type: "searchResult",
      SearchText: SearchText ? SearchText : TextByParams ? TextByParams : "",
    };
    updateMetaTags(seoBody);
  }, []);

  const HandlePageNumber = (val) => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setPageNo(val);
    }, 200);
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleFilterData = (val) => {
    let newData = [...SelectedFilter];
    newData.push(val);
    setSelectedFilter(newData);
    HandlePageNumber(1);
    setRemountComponent(Math.random());
    setTabletFilterView(false);
  };

  const handleRemoveFilterData = (val) => {
    HandlePageNumber(1);
    const NewData = SelectedFilter?.filter((item) => {
      return val?.name !== item.name;
    });
    setSelectedFilter(NewData);
    setRemountComponent(Math.random());
    setTabletFilterView(false);
  };

  const handleClearData = (val) => {
    if (SelectedFilter?.length) {
      setSelectedFilter([]);
      setRemountComponent(Math.random());
      setTabletFilterView(false);
    }
  };

  const handleApplyChanges = (val) => {
    if (val?.length > 0) {
      const Type = val[0]?.type;
      const filterSelected = SelectedFilter.filter((list) => {
        return list?.type !== Type;
      });
      const data = _.uniqBy([...filterSelected, ...val], "name");
      setSelectedFilter(data);
    } else setSelectedFilter([]);
    setRemountComponent(Math.random());
  };

  // https://thebox-dev.portqii.com/search_backend/getProductSearchResult
  const SearchResultData = useEnCryptPostApi(
    `/getProductSearchResult`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    setSearchedData([]);
    setPageNo(1);
    const rawData = {
      searchText: SearchText ? SearchText : TextByParams ? TextByParams : "",
      page: 1,
      type: props?.type,
      filters: SelectedFilter,
      userId: USER_ID,
      limit: 30,
      super_admin: superAdminSelector,
    };
    SearchResultData?.handleTrigger(rawData);
  }, [SearchText, TextByParams, SelectedFilter, props?.type, superAdminSelector]);

  useEffect(() => {
    if (SelectedFilter?.length) {
      const result = SelectedFilter.map((val) => ({
        name: val?.name,
        type: val?.type,
      }));
      handleSearchFiltersClicked(result);
    }
  }, [SelectedFilter]);

  const handleSearchFiltersClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "Filters",
      Item_Id: null,
      Target: {
        page: signalTrackingProductObject?.page,
        link: null,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: JSON.stringify(val),
        page: page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const tabData = KEYS?.SEARCH_RESULT_TAB;
  const fetchData = () => {
    setPageNo((prev) => prev + 1);
    const currentPage = PageNo + 1;
    const rawData = {
      searchText: SearchText ? SearchText : TextByParams ? TextByParams : "",
      page: currentPage,
      type: props?.type,
      userId: USER_ID,
      filters: SelectedFilter,
      limit: 30,
      userId: USER_ID,
    };
    SearchResultData?.handleTrigger(rawData);
  };
  useEffect(() => {
    if (SearchResultData?.data?.data?.length) {
      setSearchResultTab((prev) => [...SearchResultData?.data?.Counts]);
      if (searchedData?.length >= 20) {
        setSearchedData((prev) => prev.concat(SearchResultData?.data?.data));
      } else {
        setSearchedData(SearchResultData?.data?.data);
      }
    } else if (SearchResultData?.data?.Counts) {
      setSearchResultTab((prev) => [...SearchResultData?.data?.Counts]);
    }
  }, [SearchResultData?.data?.data]);
  const handleViewFilter = () => {
    setTabletFilterView(!tabletFilterView);
  };

  return (
    <>
      <Header page={page} type={props?.type} show={true} />
      <div className="attached-navbar">
        <div className="fixed-nav-container flex space-between align-items-bottom ui container  ">
          <SearchHeaderBreadCrumb
            page={page}
            parent={"searchPage"}
            type={props?.type}
            params={SearchText ? SearchText : TextByParams ? TextByParams : ""}
            pageNo={PageNo}
            total={SearchResultData?.data?.total}
          />
        </div>{" "}
      </div>
      {!tabletFilterView ? (
        <div className="search business-profile-landing-page-container ui container">
          <div className="m-t-xs">
            <SearchResultHeader
              key={remountComponent}
              page={page}
              pageNo={PageNo}
              searchedData={searchedData}
              type={props?.type}
              totalNumber={SearchResultData?.data?.total}
              searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
              section={`search filters-${PageNo}`}
              selectedFilter={SelectedFilter}
              handleRemoveFilterData={handleRemoveFilterData}
              handleClearData={handleClearData}
              tabData={searchResultTab}
              handleViewFilter={handleViewFilter}
              scrollPosition={scrollPosition}
              loading={SearchResultData?.isLoading}
            />
          </div>
          <div className="tablet-landscape-1-col grid-search-result mobile-1-col">
            <div key={remountComponent}>
              {searchedData?.length <= 20 && (SearchResultData?.isLoading || SearchResultData?.isFetching) ? (
                <ProductSkeleton page={page} />
              ) : searchedData?.length > 0 ? (
                props?.type === "products" ? (
                  <InfiniteScrollComp
                    Data={searchedData}
                    hasMoreData={SearchResultData?.data?.data?.length >= 30}
                    fetchData={fetchData}
                  >
                    <NewProductCard
                      section={`searched product results-${PageNo}`}
                      page={page}
                      data={searchedData}
                      searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
                    />
                  </InfiniteScrollComp>
                ) : props?.type === "vendors" ? (
                  <InfiniteScrollComp
                    Data={searchedData}
                    hasMoreData={SearchResultData?.data?.data?.length >= 30}
                    fetchData={fetchData}
                  >
                    <NewVendorCard
                      section={`searched vendor results-${PageNo}`}
                      page={page}
                      data={searchedData}
                      searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
                    />
                  </InfiniteScrollComp>
                ) : props?.type === "blurbs" ? (
                  <InfiniteScrollComp
                    Data={searchedData}
                    hasMoreData={SearchResultData?.data?.data?.length >= 30}
                    fetchData={fetchData}
                  >
                    <NewBlurbCard
                      section={`searched blurb results-${PageNo}`}
                      page={page}
                      data={searchedData}
                      searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
                    />
                  </InfiniteScrollComp>
                ) : props?.type === "use-cases" ? (
                  <InfiniteScrollComp
                    Data={searchedData}
                    hasMoreData={SearchResultData?.data?.data?.length >= 30}
                    fetchData={fetchData}
                  >
                    <NewUseCaseCard
                      section={`searched usecase results-${PageNo}`}
                      page={page}
                      data={searchedData}
                      searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
                    />
                  </InfiniteScrollComp>
                ) : null
              ) : SearchResultData?.data?.noResult ? (
                <NoResult page={page} section={props?.section} type={props?.type} />
              ) : null}

              {/* <>
                <PaginatedItems
                  page={PageNo}
                  totalNumber={SearchResultData?.data?.total}
                  itemsPerPage={10}
                  HandlePageNumber={HandlePageNumber}
                  // forcePage={0}
                />
                <MobilePaginatedItems
                  page={PageNo}
                  totalNumber={SearchResultData?.data?.total}
                  itemsPerPage={10}
                  HandlePageNumber={HandlePageNumber}
                  forcePage={0}
                />
              </> */}
            </div>
            <div className="tablet-landscape-hidden mobile-hidden">
              <SearchResultFilter
                page={page}
                data={SearchResultData?.data?.filters}
                handleFilterData={handleFilterData}
                handleRemoveFilterData={handleRemoveFilterData}
                handleApplyChanges={handleApplyChanges}
                handleClearData={handleClearData}
                searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="business-profile-landing-page-container">
          <SearchResultFilter
            page={page}
            data={SearchResultData?.data?.filters}
            handleFilterData={handleFilterData}
            handleRemoveFilterData={handleRemoveFilterData}
            handleApplyChanges={handleApplyChanges}
            handleClearData={handleClearData}
            searchText={SearchText ? SearchText : TextByParams ? TextByParams : ""}
            tabView={true}
            handleViewFilter={() => setTabletFilterView(false)}
          />
        </div>
      )}
      <Footer page={page} />
    </>
  );
};
export default memo(SearchResult);
