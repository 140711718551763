import React, { useEffect } from "react";
import * as CONFIG_KEYS from "../../../../config/config";
import _ from "lodash";
const ProposalQuestions = (props) => {
  const DATA = props?.data;
  const questionIndex = props?.questionIndex;
  const sectionIndex = props?.sectionIndex;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const STATUS = DATA?.[ADD_PROPOSAL_KEYS?.STATUS];
  const proposalDetails = props?.proposalDetails;
  const selectedQuestion = props?.selectedQuestion;
  const proposalQuestions = proposalDetails?.[
    ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION
  ]?.length
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
    : [];
  const activeIndexNumber = props?.activeIndexNumber;
  const notificationJson = props?.notificationJson;
  const activeIndex = props?.activeIndex;
  const handleQuestionClicked = (Find_S_index, Find_Q_index) => {
    if (Find_S_index !== null && Find_Q_index !== null) {
      props?.handleQuestionClicked(Find_S_index, Find_Q_index);
    } else {
      props?.handleQuestionClicked(
        sectionIndex ? sectionIndex : 0,
        questionIndex ? questionIndex : 0
      );
    }
  };
  useEffect(() => {
    if (
      selectedQuestion?.sectionIndex !== null &&
      selectedQuestion?.sectionIndex >= 0 &&
      selectedQuestion?.questionIndex !== null &&
      selectedQuestion?.questionIndex >= 0
    ) {
      handleQuestionClicked(
        selectedQuestion?.sectionIndex ? selectedQuestion?.sectionIndex : 0,
        selectedQuestion?.questionIndex ? selectedQuestion?.questionIndex : 0
      );
    } else {
      const S_Index_1 =
        notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID] &&
        (notificationJson?.["Object"]?.Action == "Question_Edited" ||
          notificationJson?.["Object"]?.Action == "Answer_Edited")
          ? notificationJson?.["Object"]?.Details?.[
              ADD_SECTION_KEYS?.SECTION_ID
            ]
          : notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID] &&
            (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
              notificationJson?.["Object"]?.Action == "Vendor_Commented")
          ? notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID]
          : null;
      const Q_index_1 =
        notificationJson?.["Object"]?.Details?.[
          ADD_QUESTION_KEYS?.QUESTION_ID
        ] &&
        (notificationJson?.["Object"]?.Action == "Question_Edited" ||
          notificationJson?.["Object"]?.Action == "Answer_Edited")
          ? notificationJson?.["Object"]?.Details?.[
              ADD_QUESTION_KEYS?.QUESTION_ID
            ]
          : notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID] &&
            (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
              notificationJson?.["Object"]?.Action == "Vendor_Commented")
          ? notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID]
          : null;
      const Find_S_index = _.findIndex(
        proposalQuestions,
        (e) => {
          return e?.[ADD_SECTION_KEYS?.SECTION_ID] == S_Index_1;
        },
        0
      );
      const Find_Q_index = _.findIndex(
        proposalQuestions[Find_S_index]?.[ADD_QUESTION_KEYS?.QUESTION_LIST],
        (e) => {
          return e?.[ADD_QUESTION_KEYS?.QUESTION_ID] == Q_index_1;
        },
        0
      );
      handleQuestionClicked(
        Find_S_index >= 0 ? Find_S_index : 0,
        Find_Q_index >= 0 ? Find_Q_index : 0
      );
    }
  }, []);
  return (
    <div
      onClick={() => handleQuestionClicked(sectionIndex, questionIndex)}
      className={`vendor-proposal-container-hover ${
        activeIndex && props?.activeSectionIndex
          ? "selected-question--background"
          : ""
      }`}
    >
      <div className="vendor-proposal-dis-flex-space-btw ">
        <h6 className="vendor-proposal-question-list-question-heading">{`Question (${questionIndex +
          1})`}</h6>
        <h6
          className={`vendor-proposal-question-list-question-status ${
            STATUS == "Published"
              ? "proposal-completed"
              : "vendor-proposal-draft"
          }`}
        >
          {STATUS == "Published" ? "Published" : "Draft"}
        </h6>
      </div>
      <div>
        <h6 className="vendor-proposal-question-list-question">
          {DATA?.[ADD_QUESTION_KEYS?.QUESTION_NAME]}
        </h6>
      </div>
    </div>
  );
};

export default ProposalQuestions;
