import React, { memo } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import * as SECRET_KEYS from "../../../../../config/configKeys";
const RequestList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DeCryptProductCategoryList = [
    { id: 1, name: "Request category", value: "requestCategory" },
    { id: 2, name: "Request vendor", value: "requestVendor" },
  ];
  const navigation = useNavigate();
  //   const signalTrackingCategoryObject = {
  //     Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
  //     page: "category page",
  //     section: "explore categories",
  //   };
  //   const signalTrackingAllCategoriesObject = {
  //     Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
  //     page: "all categories page",
  //     section: "explore categories",
  //     context: "all categories",
  //   };
  //   const SignalTracking = useEnCryptSignalTrackingApi(
  //     `/insertSignalActivityDetails`,
  //     KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
  //     usePostRequestMutation
  //   );

  //   const handleExploreCategoryLinkClicked = (val) => {
  //     const signalTrackingPostData = {
  //       Actor: USER_ID,
  //       SignalCreatedAt: new Date().toString(),
  //       Action: signalTrackingCategoryObject?.Action,
  //       Item_Type: signalTrackingCategoryObject?.page == "category page" ? "category" : "category",
  //       Item_Id: val?.Product_Category_ID,
  //       Target: {
  //         page: signalTrackingCategoryObject?.page,
  //         link: `/category/${ReplaceBlankSpaceByHyphen(val?.Product_Category_Name, val?.Product_Category_ID)}/${
  //           KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE
  //         }`,
  //       },
  //       Object: {
  //         section: signalTrackingCategoryObject?.section,
  //         context: val?.Product_Category_Name,
  //         page: props?.page,
  //       },
  //     };
  //     SignalTracking?.handleTrigger(signalTrackingPostData);
  //   };
  //   const handleExploreAllCategoriesLinkClicked = (val) => {
  //     const signalTrackingPostData = {
  //       Actor: USER_ID,
  //       SignalCreatedAt: new Date().toString(),
  //       Item_Type: "category",
  //       Item_Id: null,
  //       Action: signalTrackingAllCategoriesObject?.Action,
  //       Target: {
  //         page: signalTrackingAllCategoriesObject?.page,
  //         link: val,
  //       },
  //       Object: {
  //         section: signalTrackingAllCategoriesObject?.section,
  //         context: signalTrackingAllCategoriesObject?.context,
  //         page: props?.page,
  //       },
  //     };
  //     SignalTracking?.handleTrigger(signalTrackingPostData);
  //   };

  const handleItemPressed = (val) => {
    if (val === "requestCategory") props?.handleCategory();
    else if (val === "requestVendor") props?.handleVendor();
  };
  return (
    <div className="navbar__sub-menu" style={{ minWidth: "250px" }}>
      <div>
        {DeCryptProductCategoryList?.map((list, index) => {
          return (
            <div className="navbar__link-group" key={index}>
              <a className="navbar__link-group-title" onClick={() => handleItemPressed(list?.value)}>
                {list?.name}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default memo(RequestList);
