import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useAddBookmarkMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
const BookmarkIcon = (props) => {
  const [bookmarkData, setBookmarkData] = useState(props?.data);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;

  useEffect(() => {
    setBookmarkData(props?.data);
  }, [props?.data]);

  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.BOOKMARK_CLICKED,
    page: props?.page,
    section: props?.page,
  };

  const TriggerBookmark = useEnCryptPostApi(
    `/addOrRemoveBookmark`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useAddBookmarkMutation
  );

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  function handleBookmarkPressed() {
    // if (authData?.data?.Permissions?.Pin) {
    const rawData = {
      Item_ID: bookmarkData?.[props?.keyName],
      Created_By: USER_ID,
      Item_Type: props?.cardType,
      Action_Type: bookmarkData?.Book_Marked ? "Remove" : "Add",
    };
    TriggerBookmark.handleTrigger(rawData);
    setBookmarkData((prev) => ({
      ...prev,
      Book_Marked: !prev?.Book_Marked,
    }));
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: props?.cardType,
      Item_Id: bookmarkData?.[props?.keyName],
      Target: {
        page: "",
        link: "",
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: bookmarkData?.Book_Marked ? "Bookmark Removed" : "Bookmark Added",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    // } else {
    //   props?.handleSignInModalVisible();
    // }
  }

  return (
    <>
      <div className="engagement-bar-items" role="button" data-tooltip={"Bookmark"} data-inverted="true">
        <FeatherIcon
          icon="bookmark"
          size={15}
          color={bookmarkData?.Book_Marked ? "#5570f1" : "#323232"}
          onClick={() => handleBookmarkPressed()}
          fill={bookmarkData?.Book_Marked ? "#5570f1" : "none"}
        />
      </div>
    </>
  );
};

export default BookmarkIcon;
