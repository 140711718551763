import React from "react";
import { createSlice } from "@reduxjs/toolkit";
// Created By : SANKET
//  this component is used to handle the the data of the detail blurb selected
export const permissionsReducer = createSlice({
  name: "permissions",
  initialState: {
    allPermissions: {},
  },
  reducers: {
    allPermissionsFn: (state, action) => {
      state.allPermissions = action.payload;
    },
  },
});

export const { allPermissionsFn } = permissionsReducer.actions;
export default permissionsReducer.reducer;
