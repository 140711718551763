import React, { useState, useEffect } from "react";
import Header from "../home/header/header";
import { useSelector } from "react-redux";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import { useParams } from "react-router";
import { updateMetaTags } from "../../../src/components/SEO/seo";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import UseCase from "./components/useCase/useCase";
import Footer from "../home/blades/footer";
import RelatedUseCase from "./components/relatedUseCase/relatedUseCase";

const NewUseCaseDetail = (props) => {
  const [blurbData, setBlurbData] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const BLURB_DETAILS = PARAMS?.name;
  const TAB_INDEX = PARAMS?.tab;
  let BLURB_ID = BLURB_DETAILS?.split("-").at(-1);
  useEffect(() => {
    const seoBody = {
      type: "usecase",
      id: BLURB_ID,
      tab: TAB_INDEX,
    };
    updateMetaTags(seoBody);
  }, []);

  const GetApiData = useEnCryptGetApi(
    `/getNavigationPathForUseCaseDetailPage?id=${BLURB_ID}&PageUrlPath=${blurbData?.Page_URL_Path}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  return (
    <div id="app" className="landing-page__show ">
      <Header page={props?.page} show={true} />
      <div className="attached-navbar">
        <div className="fixed-nav-container flex space-between align-items-bottom ui container  ">
          <SearchHeaderBreadCrumb
            page={"usecaseDetail"}
            parent={"usecaseDetail"}
            type={props?.type}
            data={GetApiData?.data?.data?.length ? GetApiData?.data?.data : KEYS?.BREAD_CRUMB_USECASE_DETAIL}
          />
        </div>
      </div>
      <div className="new-use-case-detail-container">
        <UseCase page={"Usecase detail page"} handleBlurbData={(val) => setBlurbData(val)} />
        <RelatedUseCase page={props?.page} blurbData={blurbData} />
      </div>
      <Footer page={props?.page} />
    </div>
  );
};

export default NewUseCaseDetail;
