import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetBlurbsApiQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../utlis/customHooks/customHooks";
import BlurbCardLarge from "../../../../utlis/hoc/blurbCard/blurbCard";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import NewBlurbCard from "../../../../utlis/hoc/newBlurbCard/newBlurbCard";
import { EncryptId, GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import TabHeader from "./components/tabHeader";
import _ from "lodash";
import * as NO_CONTENT from "../../../../config/config";

const BusinessFeed = (props) => {
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [noResultsData, setNoResultsData] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const EDITED_BLURB = useSelector((state) => state?.homeDetails?.editBlurbData);
  const cardType = "blurb";
  const tabIndex = props?.tabIndex;
  const VENDOR_ID = props?.VENDOR_ID;
  const permissionsDetails = props?.permissionsDetails;
  const PARAMS = useParams();
  const vendorName = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Name;
  // const SearchResultData = useEnCryptPostApi(
  //   `/getAllBlurbsByVendorId`,
  //   KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
  //   usePostRequestMutation
  // );
  const SearchResultDataNew = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getBlurbsByVendorId?vendorId=14505&page=1
    `/getBlurbsByVendorId?vendorId=${VENDOR_ID}&page=${pageNo}&status=${vendorDetails?.Vendor_Status}&limit=30&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetBlurbsApiQuery
  );

  const signalTrackingBlurbObject = {
    Action: `${KEYS?.PROVIDER_BLURBS.MORE} clicked`,
    page: "blurb page-vendor",
    section: "related blurbs",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewAllBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Target: {
        page: signalTrackingBlurbObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: vendorName,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  useEffect(() => {
    if (SearchResultDataNew?.data?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(SearchResultDataNew?.data?.data?.data));
      } else {
        setLazyLoadData(SearchResultDataNew?.data?.data?.data);
      }
    } else if (
      typeof SearchResultDataNew?.data?.data?.data === "object" &&
      SearchResultDataNew?.data?.data?.data?.length === 0
    ) {
      setNoResultsData(true);
    }
  }, [SearchResultDataNew?.data?.data]);

  useEffect(() => {
    if (typeof EDITED_BLURB === "object" && Object.keys(EDITED_BLURB)?.length > 1) {
      const BLURB_INDEX = lazyLoadData?.findIndex((item) => item?.blurbID === EDITED_BLURB?.blurbID);
      let tempData = [...lazyLoadData];
      if (EDITED_BLURB?.status == "Delete") {
        tempData.splice(1, tempData[BLURB_INDEX]);
      } else {
        tempData[BLURB_INDEX] = EDITED_BLURB;
      }
      setLazyLoadData(tempData);
    }
  }, [EDITED_BLURB]);
  return (
    <div>
      <TabHeader
        permissionsDetails={permissionsDetails}
        dataLength={lazyLoadData?.length}
        tabIndex={props?.tabIndex}
        type={props?.type}
      />
      {lazyLoadData?.length < 1 && (SearchResultDataNew?.isLoading || SearchResultDataNew?.isFetching) ? (
        <ProductSkeleton page={props?.page} />
      ) : SearchResultDataNew?.data?.data?.data ? (
        <>
          {lazyLoadData?.length > 0 ? (
            <InfiniteScrollComp
              Data={lazyLoadData}
              hasMoreData={SearchResultDataNew?.data?.data?.data?.length >= 30}
              fetchData={fetchData}
            >
              <NewBlurbCard
                permissionsDetails={permissionsDetails}
                cardType={cardType}
                page={props?.page}
                data={lazyLoadData}
                parent={props?.parent}
                type={props?.type}
              />
            </InfiniteScrollComp>
          ) : noResultsData ? (
            <div className="search__result data-product-card no-content-card">
              <div className="no-content-text ">
                {props?.type === "buyer"
                  ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_BLURB
                  : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_BLURB}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default BusinessFeed;
