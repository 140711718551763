import React from "react";
import NoResult from "../../../components/searchResult/components/noResult";
import "../../../styles/new-cards.css";
import UseCaseWrapper from "./components/useCaseWrapper";

const NewUseCaseCard = (props) => {
  const USE_CASE_DATA = props?.data;
  const cardType = "usecase";
  const permissionsDetails = props?.permissionsDetails;
  return USE_CASE_DATA?.length > 0 ? (
    USE_CASE_DATA?.map((item, index) => (
      <UseCaseWrapper
        permissionsDetails={permissionsDetails}
        item={item}
        props={props}
        index={index}
        searchText={props?.searchText}
      />
    ))
  ) : (
    <NoResult cardType={cardType} page={props?.page} section={props?.section} type={"use-cases"} />
  );
};

export default NewUseCaseCard;
