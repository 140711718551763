import React from "react";
import DashboardTabs from "../dropdownFilter/dashboardTabs";

function DashboardTabsSection(props) {
  const selectedTab = props?.selectedTab;
  return (
    <div className="dashboard-filter-list-main-wrapper modern-card  p-x-mini p-y-mini">
      <DashboardTabs
        selectedTab={selectedTab}
        handleTabClicked={props?.handleTabClicked}
      />
    </div>
  );
}

export default DashboardTabsSection;
