import React, { memo } from "react";
import {
  DecryptApi,
  ReplaceBlankSpaceByHyphen,
} from "../../../../../../utlis/hof/hof";
// import * as KEYS from "../../../../../../config/config";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const CategoryList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const DeCryptProductCategoryList = DecryptApi(props?.productCategoryList);
  const navigation = useNavigate();
  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
    });
  }

  return (
    <div className="navbar__sub-menu">
      <div>
        {DeCryptProductCategoryList?.data?.map((list, index) => {
          return (
            <div className="navbar__link-group" key={index}>
              <a
                className="navbar__link-group-title"
                onClick={() =>
                  navigation(
                    `/category/${ReplaceBlankSpaceByHyphen(
                      list?.Product_Category_Name,
                      list?.Product_Category_ID
                    )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
                  )
                }
              >
                {/* <FeatherIcon icon="map-pin" className="feather icon" /> */}
                {list?.Product_Category_Name}
              </a>
              {/* <i className="feather icon icon-chevron-right "></i> */}
              {/* <SubCategoryList /> */}
            </div>
          );
        })}
      </div>

      <div className="navbar__sub-menu-footer">
        <a onClick={() => navigation(`/categories`)}>
          {KEYS?.EXPLORE_CATEGORIES?.BROWSE_ALL}
          <i className="feather icon icon-arrow-right m-r-none m-l-base"></i>
        </a>
      </div>
    </div>
  );
};
export default memo(CategoryList);
