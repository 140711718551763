import React from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { Link } from "react-router-dom";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import * as SECRET_KEYS from "../../../../config/configKeys";
const MatchingProducts = (props) => {
  const [productsVisible, setProductsVisible] = useState(true);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_PRODUCT_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const DATA = props?.data;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorProductClicked = (value) => {
    const prodName = value?.Product_Name;
    const prodId = value?.Product_ID;
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "Product",
      Item_Id: prodId,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          prodName,
          prodId
        )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return DATA?.Products?.length ? (
    <div className="vendor-card-matching-product-container">
      <div
        className={`vendor-card-matching-product-header ${
          productsVisible ? "product-matches-open" : `product-matches-close`
        }`}
      >
        <h5 className="matching-product-text-count">
          {DATA?.Product_Count
            ? `${DATA?.Product_Count} products`
            : `${DATA?.Products?.length} product${DATA?.Products?.length > 1 ? "s" : ""} `}
        </h5>
        <FeatherIcon
          icon={`${productsVisible ? "chevron-up" : "chevron-down"}`}
          size="20"
          className="vendor-card-matching-product-chevron"
          onClick={() => setProductsVisible(!productsVisible)}
          role="button"
        />
      </div>
      {productsVisible ? (
        <div className="vendor-car-product-list">
          {DATA?.Products?.map((item) => {
            return (
              <div onClick={() => handleVendorProductClicked(item)}>
                <a
                  href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                    item?.Product_Name,
                    item?.Product_ID
                  )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                >
                  <div className="vendor-car-product-list-view">
                    <FeatherIcon
                      icon={"circle"}
                      size="7"
                      fill={true}
                      color={"#8B8D97"}
                      className="product-list-disc-icon"
                    />
                    <p className="vendor-card-product-list-item">{item?.Product_Name}</p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default MatchingProducts;
