import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetProposalTeamsApiQuery } from "../../../../adapters/xhr/generalApi";

import * as ADD_SECTION__KEYS from "../../../../config/config";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import Header from "../../../addProposal/component/proposalSubmit/component/header";
import AddUser from "./addUser";
import TeamsList from "./teamsList";
const ManageTeams = (props) => {
  const [showSection, setShowSection] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const userId = authData?.data?.userId;
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const PROPOSAL_USER_ID = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.CREATED_BY];
  const PROPOSAL_ID = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID];
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const getTeamsList = useEnCryptGetApi(
    `/getSharedProposalInformation?Proposal_ID=${PROPOSAL_ID}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetProposalTeamsApiQuery
  );
  const TeamListData = getTeamsList?.data?.data?.length ? getTeamsList?.data?.data : [];
  const DUMMY_DATA = [
    {
      User_Name: "Sanket Rannore",
      Email: "sanket.rannore@portqii.com",
      User_ID: 35,
      Access: "owner",
      Id: 1,
      Status: "Owner",
    },
    {
      User_Name: "Rahul Madihalli",
      Email: "rahul.madihalli@portqii.com",
      User_ID: 325,
      Access: "can_view",
      Id: 2,
      Status: "Joined",
    },
    {
      User_Name: "prasad",
      Email: "prasad.shetty@portqii.com",
      User_ID: null,
      Access: "can_edit",
      Id: 1,
      Status: "Pending",
    },
  ];
  // /getSharedProposalInformation
  return (
    <div style={{ width: "100%" }}>
      <Header
        USER_CAN_EDIT={USER_CAN_EDIT}
        PROPOSAL_TYPE={PROPOSAL_TYPE}
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        hideEdit={true}
        headerTitle={"Manage Teams"}
      />
      {showSection && USER_CAN_EDIT ? (
        <div style={{ borderBottom: "1px solid #e7ebef", zIndex: -1 }}>
          <AddUser proposalDetails={proposalDetails} />
        </div>
      ) : null}
      {showSection && !getTeamsList?.isLoading ? (
        <div style={{ zIndex: 1 }}>
          <TeamsList USER_CAN_EDIT={USER_CAN_EDIT} proposalDetails={proposalDetails} Data={TeamListData} />
        </div>
      ) : null}
    </div>
  );
};

export default ManageTeams;
