import React from "react";
import NoResult from "../../../components/searchResult/components/noResult";
import "../../../styles/new-cards.css";
import VendorCardWrapper from "./components/vendorCardWrapper";

const NewVendorCard = (props) => {
  const VENDOR_DATA = props?.data;
  const cardType = "vendor";

  return VENDOR_DATA?.length > 0 ? (
    VENDOR_DATA?.map((item, index) => <VendorCardWrapper item={item} props={props} index={index} />)
  ) : (
    <NoResult cardType={cardType} page={props?.page} section={props?.section} type={"vendors"} />
  );
};

export default NewVendorCard;
