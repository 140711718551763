import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { usePostBoardMutation } from "../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import CreateNewBoard from "../../utlis/hoc/Boards/createNewBoard";
import { useNavigate } from "react-router";
import ShareBoard from "./shareBoard";
import moment from "moment";
function NewBoardCard(props) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const [EditBoardData, setEditBoardData] = useState({});
  const [EditModalVisible, setEditModalVisible] = useState(false);
  const item = props?.item;
  const Board_Image = item?.Board_Image ? item?.Board_Image : null;
  const navigate = useNavigate();
  const time = moment(item?.Updated_At ? item?.Updated_At * 1000 : new Date().getTime()).fromNow();
  const DeleteBoard = useEnCryptPostApi(`/deleteBoard`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostBoardMutation);
  const handleDeleteBoard = (val) => {
    // const rawData = {
    //   ...val,
    // };
    // DeleteBoard?.handleTrigger(rawData);
  };
  // Board_Image:
  const handleEditBoard = (val) => {
    setEditBoardData(val);
    setEditModalVisible((prev) => !prev);
  };
  return (
    <div className="new-board-container">
      <img
        // class="provider-card__logo ls-is-cached lazyloaded"
        alt="Board image"
        height={"60px"}
        width={"60px"}
        src={Board_Image ? Board_Image : KEYS?.CORRUPT_IMAGE_ICON}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = require("../../../src/assets/images/boardBlankImage.jpg");
        }}
        style={{
          objectFit: "contain",
          opacity: 0.8,
          alignItems: "center",
          borderRadius: "10px",
        }}
      />
      <div
        onClick={() => navigate(`/board/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Board_ID)}`)}
        className="new-board-details-container"
      >
        <div className="flex align-items-center">
          <p className="new-board-item-title">{item.Name}</p>
          {item?.Private ? (
            <FeatherIcon
              icon={"lock"}
              size={15}
              // className={"new-board-item-edit-icon"}
              // role="button"
              // onClick={() => handleEditBoard(item)}
              color={"#080808"}
              style={{ marginLeft: "5px", marginBottom: "3px", width: "20px" }}
            />
          ) : null}
        </div>

        <p className="new-board-item-description">{item.Description}</p>
      </div>

      {item?.Private ? null : (
        <div className="">
          <ShareBoard data={item} />
        </div>
      )}

      {EditModalVisible ? (
        <CreateNewBoard
          data={EditBoardData}
          edit={Object.keys(EditBoardData)?.length ? true : false}
          handleClose={() => setEditModalVisible((prev) => !prev)}
        />
      ) : null}
    </div>
  );
}

export default NewBoardCard;
