import React from "react";

const ChangeRolesList = (props) => {
  const data = props?.data;
  return (
    <>
      {data?.map((list) => {
        return (
          <div className="share-single-item">
            <div
              className="action-button-container"
              onClick={() => props?.handleActionClicked(list)}
              style={{ width: "100%", padding: "5px" }}
            >
              <p className="share-desc-title">{list?.Permission_Set_Name}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ChangeRolesList;
