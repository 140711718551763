import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import CompanyProfile from "../../../companyProfile/companyProfile";

const CreateNewVendorPortal = (props) => {
  return ReactDom.createPortal(
    <>
      <div
        className="ui dimmer modals page visible active portalModal"
        // style={{
        //   width: "100%",
        //   height: "100%",
        //   position: "absolute",
        //   top: "0px",
        // }}
      >
        <CompanyProfile
          pageToNavigate={props?.pageToNavigate}
          handleClose={props?.handleClose}
          action={props?.action}
          domainUrl={props?.domainUrl}
        />
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default CreateNewVendorPortal;
