import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import SelectProduct from "./selectProduct";
import SelectUseCase from "./selectUseCase";
import FeatherIcon from "feather-icons-react";
import { detectMob } from "../../../../../utlis/hof/hof";

const AddCustomerLayout = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [searchCustomer, setSearchCustomer] = useState("");

  const DATA = props?.data;

  const handleChangeCustomerData = (val) => {
    props?.handleChangeDetails(
      {
        ...DATA,
        ...val,
      },
      props?.index
    );
  };

  const handleChangeProduct = (val) => {
    props?.(
      {
        ...DATA,
        Products: val?.Product_ID,
      },
      props?.index
    );
  };
  const handleChangeUseCase = (val) => {
    props?.handleChangeDetails(
      {
        ...DATA,
        Use_Cases: val?.Use_Case_Blurb_ID,
      },
      props?.index
    );
  };

  const handleSearchWithApi = (key) => {
    setSearchCustomer(key);
  };
  const Domain_URL = props?.businessData?.domainURL
    ? props?.businessData?.domainURL
    : `https://${props?.validateEmailId}`;

  const CustomerList = useEnCryptGetApi(
    `/getCrunchbaseVendorList?searchText=${searchCustomer}&domainURL=${Domain_URL}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery,
    searchCustomer?.length >= 3 ? false : true
  )?.data?.data;

  const IS_MOBILE = detectMob();

  return (
    <div className="flex align-items-center">
      <div className={`${IS_MOBILE ? "customer-entry-container" : "grid-three-columns"}`} style={{ width: "98%" }}>
        <div class="field p-r m-y-xs" id="field-user__email">
          <SingleSelectNewDropdown
            handleSelectedData={(val) => handleChangeCustomerData(val)}
            placeholder={"Select Customer"}
            // flag={true}
            selectedVal={DATA}
            name={"Customer_Name"}
            Id={"Customer_Domain"}
            label={"Customer"}
            data={CustomerList}
            handleSearchWithApi={handleSearchWithApi}
            searchWithApi={true}
            returnObject={true}
            enableViewDomain={true}
            infoText={"Please type minimum 3 characters to search for vendor"}
          />
          {props?.companyErrors?.index === props?.index ? (
            <span class="input-field-dropdown-error">Please select Customer</span>
          ) : null}
        </div>
        <SelectProduct
          productBasicDetails={DATA?.Products}
          handleDetails={(val) => handleChangeProduct(val)}
          keyName={"Product_ID"}
        />
        <SelectUseCase
          productBasicDetails={DATA?.Use_Cases}
          productData={DATA?.Products}
          handleDetails={(val) => handleChangeUseCase(val)}
          keyName={"Use_Case_Blurb_ID"}
        />
      </div>
      {/* {props?.index >= 1 ? ( */}
      <FeatherIcon
        icon={"x"}
        size="20"
        color={"#8B8D97"}
        className="customer-delete-icon"
        onClick={() => props?.handleDeletePressed(props?.index)}
      />
      {/* ) : null} */}
    </div>
  );
};

export default AddCustomerLayout;
