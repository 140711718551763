import React from "react";
import { useSelector } from "react-redux";
import Duration from "../../../../assets/svgIcons/duration";
import Location from "../../../../assets/svgIcons/location";
import PieChart from "../../../../assets/svgIcons/pieChart";
import Star from "../../../../assets/svgIcons/star";
// import * as KEYS from "../../../../config/config";
function ProductHighlights(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const productHighlight = props?.allProducts;
  const PRODUCT_DATA = KEYS?.PRODUCT_DATA;
  return (
    <div className="flex-container space-between">
      {productHighlight?.Has_APIs ? (
        <div class="vertical-product-card-for-provider__facts">
          <PieChart />
          <div class="vertical-product-card__fact-text">
            {PRODUCT_DATA?.HAS_API}
          </div>
        </div>
      ) : null}

      {productHighlight?.Has_Free_Trial ? (
        <div class="vertical-product-card-for-provider__facts">
          <Star />
          <div class="vertical-product-card__fact-text">
            {PRODUCT_DATA?.HAS_FREE_TRIAL}
          </div>
        </div>
      ) : null}
      {productHighlight?.Has_Community ? (
        <div class="vertical-product-card-for-provider__facts">
          {/* <Location /> */}
          <img
            class=" ls-is-cached lazyloaded"
            style={{ height: "20px", width: "22px" }}
            data-src={require("../../../../assets/images/communityIcon.png")}
            src={require("../../../../assets/images/communityIcon.png")}
            alt={"community icon"}
          />
          <div class="vertical-product-card__fact-text">
            {PRODUCT_DATA?.HAS_COMMUNITY}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProductHighlights;

//      <>
// {productHighlight?.map((list , index) => {
//   return (
//     <div class="vertical-product-card-for-provider__facts" key={index}>
//       {list?.type == "document" ? (
//         <PieChart />
//       ) : list?.type == "quality" ? (
//         <Star/>
//       ) : list?.type == "country" ? (
//         <Location />
//       ) : list?.type == "duration" ? (
//         <Duration />
//       ) : null}
//       <div class="vertical-product-card__fact-text">{list?.name}</div>
//     </div>
//   );
// })}
// </>
