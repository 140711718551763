import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SmallCardSkeleton = (props) => {
  const ArrayLength = props?.skeletonLength ? props?.skeletonLength : 0;
  return (
    <SkeletonTheme>
      {Array(ArrayLength)
        .fill()
        .map((item, index) => (
          <div>
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3%",
              }}
            >
              <p
                style={{
                  width: "100px",
                  alignSelf: "center",
                  height: "100px",
                }}
              >
                <Skeleton
                  height={"98%"}
                  width={"98%"}
                  style={{ alignSelf: "center" }}
                />
              </p>
              <p style={{ width: "70%", marginLeft: 5, marginTop: 10 }}>
                <Skeleton width={"80%"} height={20} />
                <Skeleton height={15} width={"50%"} />
              </p>
            </div>
            <p style={{ marginTop: 10 }}>
              <Skeleton width={"95%"} count={3} />
            </p>
            <div style={{ marginTop: "3%" }}>
              <Skeleton height={35} width={"50%"} count={1} />
            </div>
          </div>
        ))}
    </SkeletonTheme>
  );
};

export default SmallCardSkeleton;
