import React from "react";
const PostLikeTable = (props) => {
  const tableHeader = Object?.keys(props?.tableData?.[0]);
  const tableData = props?.tableData;

  const EpochDateConvertor = (val) => {
    let date = new Date(val * 1000);
    let options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    const converted = date.toLocaleDateString("en-GB", options);
    return converted;
  };

  return (
    <>
      <div className="m-y-xs user-management-table-container">
        <table className="user-management-table">
          <thead className="border-table">
            {tableHeader?.map((item) => {
              return (
                <th className="table-header-text">
                  <div className="table-header-content">
                    {item}
                    {/* <img
                      src={require("../../../../../assets/images/sort.svg").default}
                      style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                      role={"button"}
                      onClick={() => handleSortTableByColumn(item)}
                      alt="sort"
                    /> */}
                  </div>
                </th>
              );
            })}
          </thead>

          {tableData?.map((item) => {
            return (
              <tr>
                {tableHeader?.map((table) => {
                  if (table === "Time") {
                    return <td className="table-body-text">{EpochDateConvertor(item[table])}</td>;
                  } else if (table === "Profile Link")
                    return (
                      <td className="table-body-text" role="button" onClick={() => window.open(item[table])}>
                        {item[table]}
                      </td>
                    );
                  else return <td className="table-body-text">{item[table]}</td>;
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default PostLikeTable;
