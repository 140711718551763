import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import ProductTopics from "./productTopics";
import * as SECRET_KEYS from "../../../config/configKeys";
const CardContent = (props) => {
  const headerData = props?.Data;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: "vendor page",
    section: props?.section,
  };
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.PRODUCT_NAME_CLICKED,
    page: "product page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "vendor",
      Item_Id: headerData?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleProductNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "product",
      Item_Id: headerData?.Product_ID,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <div className="data-product-card__facts">
        <a
          className="data-product-card__title"
          onClick={() =>
            handleProductNameLinkClicked(
              `/${ReplaceBlankSpaceByHyphen(headerData?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                headerData?.Product_Name,
                headerData?.Product_ID
              )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
            )
          }
          href={`/${ReplaceBlankSpaceByHyphen(headerData?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            headerData?.Product_Name,
            headerData?.Product_ID
          )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
        >
          <h3>{headerData?.Product_Name}</h3>
        </a>
        <div className="data-product-card__sub-title">
          by{" "}
          <a
            onClick={() =>
              handleVendorNameLinkClicked(
                `/vendor/${ReplaceBlankSpaceByHyphen(headerData?.Vendor_Name, headerData?.Vendor_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                }`
              )
            }
            href={`/vendor/${ReplaceBlankSpaceByHyphen(headerData?.Vendor_Name, headerData?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`}
          >
            {headerData?.Vendor_Name}
          </a>
        </div>

        <div className="data-product-card__text">{headerData?.Product_Description}</div>
        <ProductTopics Data={headerData} handleTopicPressed={props?.handleTopicPressed} />

        {/* <div className="data-product-card__key-metrics">
          <div className="data-product-card__key-metric-text">
            <em>251</em> Million Individuals
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CardContent;
