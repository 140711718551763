import React from "react";
var __html = require("./termsHtml.js");
var template = { __html: __html };

const Terms = () => {
  return (
    <>
      <div dangerouslySetInnerHTML={template} />
    </>
  );
};

export default Terms;
