import React from "react";
import { useSelector } from "react-redux";
import RoundSpinner from "../../../../utlis/spinner/roundSpinner";

const LoadingDetails = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const JOIN_ORGANIZATION = KEYS?.JOIN_ORGANIZATION;
  return (
    <div className="flex align-items-center m-b-mini">
      <RoundSpinner />

      <h1 className="otp-verification-title" style={{ margin: "0% 30px" }}>
        Finding Your Organisation
      </h1>
    </div>
  );
};

export default LoadingDetails;
