import React, { memo, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import RequestList from "./requestList";
import RequestCategory from "../../../../searchResult/components/allCategories/component/requestCategory/requestCategory";
import RequestVendor from "../../../blades/requestVendor/requestVendor";
import { detectMob } from "../../../../../utlis/hof/hof";
const Request = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [requestVendorModalVisible, setRequestVendorModalVisible] = useState(false);
  const MOBILE = detectMob();

  return (
    <div className="navbar__item navbar__sub-menu-toggle navbar__mega-menu" data-controller="sub-menu-toggle">
      <div className="navbar__item-content">
        <div className="relative flex align-items-center">
          {MOBILE ? <i className="feather icon icon-git-pull-request"></i> : null}
          {/* <FeatherIcon icon="align-left" className="feather icon" /> */}
          <span className="navbar__item-title">Request</span>
        </div>
      </div>
      {/* {true ? ( */}
      <RequestList
        page={props?.page}
        handleCategory={() => setRequestModalVisible((prev) => !prev)}
        handleVendor={() => setRequestVendorModalVisible((prev) => !prev)}
      />
      {/* ) : null} */}
      {requestModalVisible ? <RequestCategory handleClose={() => setRequestModalVisible((prev) => !prev)} /> : null}
      {requestVendorModalVisible ? (
        <RequestVendor handleClose={() => setRequestVendorModalVisible((prev) => !prev)} />
      ) : null}
    </div>
  );
};
export default Request;
