import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import ProductsCards from "../../../../../../utlis/hoc/productCardSmall/productsCards";
import { GetIdFormLink } from "../../../../../../utlis/hof/hof";

function PopularCard(props) {
  const vendorName = props?.vendorName;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const PRODUCT_NAME = PARAMS?.name?.replaceAll("-", " ");

  // https://thebox-dev.portqii.com/search_backend/getRelatedProducts??type=pricing&product_category_id=1

  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getPopularVendorsForCategory?product_category_id=1&page=1
    // https://thebox-dev.portqii.com/search_backend/getPopularProductForCategory?product_category_id=1&page=1
    `/getPopularProductForCategory?product_category_id=${props?.categoryId}&page=1&limit=6`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const popularProductsInCategory = GetApiData?.data?.data?.data;
  return (
    <>
      {popularProductsInCategory?.length
        ? popularProductsInCategory.map((products) => {
            return (
              <ProductsCards
                page={props?.page}
                section={props?.section}
                allProducts={products}
                type={"Products"}
              />
            );
          })
        : null}
    </>
  );
}

export default PopularCard;
