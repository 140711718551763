import React from "react";
import { useNavigate } from "react-router";
import NoResult from "../../../components/searchResult/components/noResult";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import CtaButton from "../blurbCardSmall/component/ctaButton";
import EngagementBar from "../engagementBar/engagementBar";
import BlurbMedia from "./component/blurbMedia";
import Content from "./component/content";

function UseCaseCardList(props) {
  const Data = props?.data;
  const cardType = props?.cardType;
  const navigate = useNavigate();
  const tabIndex = props?.tabIndex;
  return (
    <article
      id="data_product_card_5a631303-2cc6-4c6e-8939-be6b97fdf4ef"
      className="search__result data-product-card modern-card--elevated"
      key={Data?.Created_At}
    >
      <main>
        <Content cardType={cardType} page={props?.page} section={props?.section} data={Data} />
        <BlurbMedia cardType={cardType} page={props?.page} section={props?.section} data={Data} />
      </main>
      <div className="data-blurb-footer-section">
        <div className="data-blurb-footer-section-width-engagement">
          <EngagementBar
            tabIndex={tabIndex}
            cardType={cardType}
            // shareLink={`blurb-detail/${ReplaceBlankSpaceByHyphen(
            //   Data?.title_level_1
            // )}-${Data?.blurbID}`}
            data={Data}
            page={props?.page}
            section={props?.section}
          />
        </div>
        <div className="data-blurb-footer-section-width-cta">
          <CtaButton cardType={cardType} page={props?.page} section={props?.section} allProducts={Data} />
        </div>
      </div>
    </article>
  );
}

export default UseCaseCardList;
