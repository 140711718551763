import React from "react";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../../../config/config";
const CommentMoreOptionsList = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const PROPOSAL_COMMENT_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL_COMMENTS_KEYS;
  return (
    <>
      {props?.list?.[PROPOSAL_COMMENT_KEYS?.USER_ID] == userId && (
        <>
          <div className="share-single-item">
            <div
              className="action-button-container"
              onClick={() => props?.handleActionClicked("edit")}
              style={{ width: "100%", padding: "5px" }}
            >
              <p className="share-desc-title">Edit</p>
            </div>
          </div>
          <div className="share-single-item">
            <div
              className="action-button-container"
              onClick={() => props?.handleActionClicked("delete")}
              style={{ width: "100%", padding: "5px" }}
            >
              {props?.blurbsPostData?.isLoading ? (
                <p className="share-desc-title">loading...</p>
              ) : (
                <p className="share-desc-title">Delete</p>
              )}
            </div>
          </div>
        </>
      )}
      <div className="share-single-item">
        <div
          className="action-button-container"
          onClick={() => props?.handleActionClicked("reply")}
          style={{ width: "100%", padding: "5px" }}
        >
          <p className="share-desc-title">Reply</p>
        </div>
      </div>
    </>
  );
};

export default CommentMoreOptionsList;
