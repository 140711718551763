import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const SignInLeftContainer = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const SIGN_UP_BANNER = KEYS?.SIGN_UP_SCREEN;
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div class="splitted-page__aside">
        <div className="signUp-vertical-line"></div>
        <div class="splitted-page__container signup-split-container">
          <div></div>
          <div className="signUp-image-container">
            <img
              class="octateImage"
              data-src={require("../../../../assets/images/octateActiveLogo.png")}
              src={require("../../../../assets/images/octateActiveLogo.png")}
              alt={"Octate logo"}
              onClick={() => navigate("/")}
              role="button"
            />
            <p className="subText-signup">{SIGN_UP_BANNER?.OCTATE_BANNER_TITLE_START}</p>
            <p className="subText-signup">{SIGN_UP_BANNER?.OCTATE_BANNER_TITLE_END}</p>
          </div>

          <div>
            <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.DISCOVER_PRODUCTS}</p>
              <FeatherIcon icon="search" size="25" color="#060606" className="signUp-icons" />
            </div>
            <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.FOLLOW_TOPICS}</p>
              <FeatherIcon icon="hash" size="25" color="#060606" className="signUp-icons" />
            </div>
            <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.CURATE_BOARDS}</p>
              <FeatherIcon icon="plus-circle" size="25" color="#060606" className="signUp-icons" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignInLeftContainer;
