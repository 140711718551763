import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import EngagementBar from "../../../../utlis/hoc/engagementBar/engagementBar";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import Comments from "../../../../utlis/hoc/comments/comments";
import { useState } from "react";
import UseCaseCard from "./useCaseCard";
import UseCaseSocial from "./useCaseSocial";
import UseCaseTitle from "./useCaseTitle";
import UseCaseHeader from "./useCaseHeader";
import UseCaseImage from "./useCaseImage";
import UseCaseCTA from "./useCaseCTA";
import UseCaseDetailPageInfo from "./useCaseDetailPageInfo";
import UseCaseDescription from "./useCaseDescription";
import BlurbTags from "../../../blurbDetail/components/blurb/blurbTags";
import UseCaseCardTopEngagement from "./useCaseCardTopEngagement";

const UseCase = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const [viewComments, setViewComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);

  const USER_ID = authData?.data?.userId;

  //to get the id from the url of blurb id
  const BLURB_DETAILS = PARAMS?.name;
  let BLURB_ID = BLURB_DETAILS?.split("-").at(-1);

  const GetApiData = useEnCryptGetApi(
    `/getUseCaseBlurb?blurbID=${BLURB_ID}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const Data = GetApiData?.data?.data;
  props?.handleBlurbData(Data);
  const handleComment = () => {
    setCommentCount((prev) => prev + 1);
  };

  return Data ? (
    <>
      <UseCaseCard>
        <div className="only-mobile">
          <UseCaseSocial data={Data} />
        </div>
        <UseCaseTitle data={Data} />
        <UseCaseHeader data={Data} page={props?.page} />
        <BlurbTags data={Data} page={props?.page} />
        <UseCaseDetailPageInfo data={Data} page={props?.page} section={props?.section} />
        <UseCaseCardTopEngagement
          shareLink={`/${ReplaceBlankSpaceByHyphen(Data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
            BreakText(Data?.title_level_1?.trim())
          )}-${Data?.blurbID}`}
          page={props?.page}
          data={Data}
          cardType={"usecase"}
          engageSource={"usecase engagement from detail page"}
          keyName={"blurbID"}
          handleViewComments={() => setViewComments((prev) => !prev)}
          commentCount={commentCount}
        />
        {/* <UseCaseImage data={Data} page={props?.page} /> */}
        <UseCaseDescription data={Data} page={props?.page} />
        <div className="blurb-detail-footer-container">
          <UseCaseCTA data={Data} page={props?.page} />
          <div style={{ width: "300px", marginBottom: "30px" }} className="flex-container">
            <EngagementBar
              shareLink={`/${ReplaceBlankSpaceByHyphen(Data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
                BreakText(Data?.title_level_1?.trim())
              )}-${Data?.blurbID}`}
              page={props?.page}
              data={Data}
              cardType={"usecase"}
              engageSource={"usecase engagement from detail page"}
              keyName={"blurbID"}
              handleViewComments={() => setViewComments((prev) => !prev)}
              commentCount={commentCount}
            />
          </div>
        </div>
        {viewComments && (
          <Comments
            cardType={"usecase"}
            data={Data}
            page={props?.page}
            searchText={props?.searchText}
            keyName={"blurbID"}
            handleCommentCount={(val) => handleComment(val)}
            fullWidth={true}
          />
        )}
      </UseCaseCard>
    </>
  ) : null;
};

export default UseCase;
