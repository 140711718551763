import React from "react";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import BlurbCardLarge from "../../../../utlis/hoc/blurbCard/blurbCard";
import { useParams } from "react-router";
import NewBlurbCard from "../../../../utlis/hoc/newBlurbCard/newBlurbCard";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import * as NO_CONTENT from "../../../../config/config";

const ProductCategoryRelatedBlurbs = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [noResultsData, setNoResultsData] = useState(false);
  const PARAMS = useParams();
  const USER_ID = authData?.data?.userId;
  const BLURB_DETAILS = PARAMS?.name;
  let BLURB_ID = BLURB_DETAILS.split("-").at(-1);

  const GetApiData = useEnCryptGetApi(
    `/getBlurbsfromSameProductCategory?blurb_Id=${BLURB_ID}&limit=6&userId=${USER_ID}&page=${pageNo}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const relatedBlurbs = GetApiData?.data?.data?.data;
  const TAG_CATEGORY = props?.blurbData?.tags?.length ? props?.blurbData?.tags?.filter((val) => val?.Level === 3) : [];

  useEffect(() => {
    if (GetApiData?.data?.data?.data?.length) {
      if (lazyLoadData?.length >= 6) {
        setLazyLoadData((prev) => prev.concat(GetApiData?.data?.data?.data));
        setDataLoading(false);
      } else {
        setLazyLoadData(GetApiData?.data?.data?.data);
        setDataLoading(false);
      }
    } else if (typeof GetApiData?.data === "object" && !Object?.keys(GetApiData?.data)?.length) {
      setNoResultsData(true);
      setDataLoading(false);
    } else {
      setDataLoading(false);
    }
  }, [GetApiData?.data?.data]);

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };

  return (
    <div className="p-t-md">
      {/* <h2 className="p-l-xs">{KEYS?.BLURB_DETAIL?.PRODUCT_CATEGORY_RELATED}</h2> */}
      {/* <div class="provider-profile__subtitle">
        {KEYS?.BLURB_DETAIL?.PRODUCT_CATEGORY_RELATED_SUBTITLE} .
      </div> */}

      {GetApiData?.isLoading || dataLoading ? (
        <ProductSkeleton page={props?.page} loadingLength={6} />
      ) : lazyLoadData?.length > 0 ? (
        <>
          {TAG_CATEGORY[0]?.Name ? (
            <div className="search__info m-b-mini">
              <h3 className="p-l-xs">
                Category : <span className="blurb-detail-page-product-tab-product-name">{TAG_CATEGORY[0]?.Name}</span>
              </h3>
            </div>
          ) : null}
          <InfiniteScrollComp
            Data={lazyLoadData}
            hasMoreData={GetApiData?.data?.data?.data?.length >= 6}
            fetchData={fetchData}
          >
            <NewBlurbCard data={lazyLoadData} parent={props?.parent} page={"Blurb detail"} section={"Related Blurbs"} />
          </InfiniteScrollComp>
        </>
      ) : noResultsData ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BLURB_DETAIL_CATEGORY_RELATED}</div>
        </div>
      ) : null}
      {(GetApiData?.isLoading || GetApiData?.isFetching) && <ProductSkeleton page={props?.page} loadingLength={6} />}
    </div>
  );
};

export default ProductCategoryRelatedBlurbs;
