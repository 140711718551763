import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import SectionMoreOptions from "./sectionMoreOptions";

const SectionMoreOptionsPortal = (props) => {
  const tabIndex = props?.tabIndex;
  const addType = props?.addType;
  const handleSaveClicked = (val) => {
    props?.handleSaveClicked(val);
  };
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        style={{ background: "rgba(222,222,222,0.2)" }}
      >
        <SectionMoreOptions
          allData={props?.allData}
          handleClose={() => props?.handleClose()}
          handleDeleteClicked={() => props?.handleDeleteClicked()}
          addType={addType}
          data={props?.data}
          handleSaveClicked={handleSaveClicked}
          questionListIndex={props?.questionListIndex}
          questionLength={props?.questionLength}
        />
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default SectionMoreOptionsPortal;
