import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import Dropdown from "../../../utlis/hoc/dropdown/dropdown";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";

const MultiSelectCustomer = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [searchProductCategoryText, setSearchProductCategoryText] =
    useState("");
  const error = props?.error;
  const KEY_NAME = props?.keyName ? props?.keyName : "Use_Case_Customers";

  const Domain_URL = props?.productBasicDetails?.Domain_URL
    ? props?.productBasicDetails?.Domain_URL
    : `https://${props?.validateEmailId}`;

  const productCategoryDropdownData = useEnCryptGetApi(
    `/getCrunchbaseVendorList?searchText=${searchProductCategoryText}&domainURL=${Domain_URL}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery,
    searchProductCategoryText?.length >= 3 ? false : true
  )?.data?.data;

  const selectedTopicValue = props?.productBasicDetails?.[KEY_NAME]?.length
    ? props?.productBasicDetails?.[KEY_NAME]
    : [];

  const concatIsSelectedCompatible = concatIsSelectedAllData(
    selectedTopicValue,
    productCategoryDropdownData,
    "Customer_Domain"
  );

  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      [KEY_NAME]: val,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };

  return (
    <div className={`${props?.minimizeMargin ? "m-t-md m-b-xs p-r" : "m-y-md p-r"}`}>
      {props?.productBasicDetails?.[KEY_NAME]?.length ? (
        <label for="user__email" className="company-details-label-input-field-filled-absolute">
          {"Customers"}
        </label>
      ) : null}
      <Dropdown
        data={concatIsSelectedCompatible}
        // handleApplyClicked={handleApplyCountryClicked}
        label={"Select Customers"}
        handleSelectedDataOnChange={handleSelectedDataOnChange}
        selectedValue={selectedTopicValue}
        hideSaveAndClear={true}
        searchWithApi={true}
        handleSearchText={handleSearchText}
        name={"Customer_Name"}
        Id={"Customer_Domain"}
        error={error?.[KEY_NAME]}
        filterText={"Search Company"}
        enableViewDomain={true}
        searchData={productCategoryDropdownData?.length ? false : true}
      />
    </div>
  );
};

export default MultiSelectCustomer;
