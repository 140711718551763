import React from "react";
import FeatherIcon from "feather-icons-react";
import { useEnCryptPostApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import ShareDetail from "./shareDetail";
import { useState } from "react";
import * as SECRET_KEYS from "../../../../config/configKeys";
const ShareIcon = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleMenu, setToggleMenu] = useState(false);
  const USER_ID = authData?.data?.userId;
  const itemData = props?.data;

  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.SHARE_CLICKED,
    page: props?.page,
    section: "blurb detail",
  };
  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleShareBlurbClicked = (val) => {
    setToggleMenu(!toggleMenu);
  };
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  const shareSignalTracking = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: props?.cardType,
      Item_Id: props?.data?.[props?.keyName],
      Target: {
        page: props?.cardType == "product" ? "product page" : "blurb page",
        link: props?.shareLink,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: val,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <div
        tabIndex={0}
        onBlur={handleBlur}
        className="engagement-bar-items"
        role="button"
        style={{ position: "relative" }}
        data-tooltip={"Share"}
        data-inverted="true"
      >
        <FeatherIcon icon="share" size={15} color={"#323232"} onClick={() => handleShareBlurbClicked()} />
        {toggleMenu && (
          <div className="share-container modern-card modern-card--elevated">
            <ShareDetail
              shareSignalTracking={shareSignalTracking}
              shareLink={props?.shareLink}
              cardType={props?.cardType}
              handleToggleMenu={handleToggleMenu}
              data={itemData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShareIcon;
