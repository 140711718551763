import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import SectionMoreOptionsPortal from "./sectionMoreOptionsPortal";
const AddSection = (props) => {
  const [showPortal, setShowPortal] = useState(false);
  const [sectionWithNoQuestionError, setSectionWithNoQuestionError] = useState(false);
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const [showEditPortal, setShowEditPortal] = useState({
    showEditPortal: false,
    index: null,
  });
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  // || PROPOSAL_TYPE == "question"
  const hideAddSection = PROPOSAL_TYPE == "answer" || !USER_CAN_EDIT ? true : false;
  const hideAddNewSection = PROPOSAL_TYPE == "answer" || !USER_CAN_EDIT ? true : false;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const ADD_SECTION_CONFIG_ERROR_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;

  const sectionIndex = props?.sectionIndex;
  const proposalDetails = props?.proposalDetails;
  const canAddSection = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
    ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION][
        proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length - 1
      ]?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_LIST]?.length > 0
      ? true
      : false
    : true;

  const handleSaveClicked = (val) => {
    let questionArray = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
      ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]
      : [];
    questionArray.push(val);
    props?.handleDetails({
      ...proposalDetails,
      [[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]]: questionArray,
    });
    setShowPortal(!showPortal);
  };
  const handleOpenAddSectionPortal = () => {
    if (canAddSection) {
      setShowPortal(!showPortal);
    } else {
      setSectionWithNoQuestionError(true);
      setTimeout(() => {
        setSectionWithNoQuestionError(false);
      }, 3000);
    }
  };
  const handleEditClicked = (val) => {
    setShowEditPortal({ showEditPortal: true, index: val });
  };
  const handleEditSaveClicked = (val) => {
    let questionArray = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
      ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]
      : [];
    questionArray[showEditPortal?.index] = val;
    props?.handleDetails({
      ...proposalDetails,
      [[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]]: questionArray,
    });
    setShowEditPortal({ showEditPortal: false, index: null });
  };
  const handleDeleteClicked = (val) => {
    props?.handleDeleteQuestionClicked(showEditPortal?.index);
    setShowEditPortal({ showEditPortal: false, index: null });
  };
  return (
    <>
      {showPortal ? (
        <SectionMoreOptionsPortal
          handleClose={() => setShowPortal(false)}
          addType={"section"}
          allData={proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]}
          handleSaveClicked={handleSaveClicked}
        />
      ) : null}
      {showEditPortal?.showEditPortal ? (
        <SectionMoreOptionsPortal
          handleClose={() => setShowEditPortal({ showEditPortal: false, index: null })}
          addType={"section"}
          questionListIndex={true}
          handleSaveClicked={handleEditSaveClicked}
          handleDeleteClicked={handleDeleteClicked}
          questionLength={showEditPortal?.index + 1}
          data={proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION][showEditPortal?.index]}
          allData={proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]}
        />
      ) : null}
      {proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
        ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.map((list, index) => {
            return (
              <div
                data-tooltip={list?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]}
                className={`proposal-add-question-section-title-wrapper cursor--pointer ${
                  sectionIndex == index ? "section-active" : "section-in-active"
                } `}
              >
                {/* ${index == 0 ? "" : "m-x-mini"} */}
                <p
                  onClick={() => props?.handleSectionTitleClicked(index)}
                  className="proposal-add-question-section-title-text"
                >
                  {list?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]}
                </p>
                {/* () => props?.handleDeleteQuestionClicked(index) */}
                {hideAddSection ||
                (list?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_LIST]?.length
                  ? list?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_LIST][0]?.[ADD_PROPOSAL_CONFIG_KEYS?.STATUS] ==
                    "Published"
                    ? true
                    : false
                  : false) ? null : (
                  <FeatherIcon
                    onClick={() => handleEditClicked(index)}
                    icon="edit-3"
                    size="18"
                    color={`${sectionIndex == index ? "#ffff" : "#6E7079"}`}
                  />
                )}
              </div>
            );
          })
        : null}
      {hideAddNewSection ? null : (
        <div
          className="cursor--pointer"
          onClick={handleOpenAddSectionPortal}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px 10px",
            minWidth: "150px",
          }}
        >
          <FeatherIcon icon="plus" size="15" color="#5570f1" />
          <p className="proposal-add-section-add-question-text">{ADD_PROPOSAL?.ADD_Q_NEW_SECTION}</p>
        </div>
      )}

      {sectionWithNoQuestionError ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px 10px",
          }}
        >
          <p className="proposal-add-section-add-question-text-error">
            {`${ADD_SECTION_CONFIG_ERROR_KEYS?.ADD_ONE_QUESTION_SECTION} (${
              proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
            })`}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default AddSection;
