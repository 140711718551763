import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useGetMyBoardsDataQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../customHooks/customHooks";
import BoardCard from "./boardCard";
import "../../../../styles/boards.css";
function BoardList(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const cardType = props?.cardType;
  const keyName = props?.keyName;
  const data = props?.data;
  const filterText = props?.filterText;
  const boardListData = props?.boardListData;
  const isLoading = props?.isLoading;
  const page = props?.page;
  return (
    <Fragment>
      {isLoading ? (
        <div className="boards-list-no-results-main-wrapper">
          <h1 className="boards-list-no-results-text">{`Loading...`}</h1>
        </div>
      ) : boardListData?.length ? (
        boardListData?.map((list) => {
          return (
            <BoardCard
              page={page}
              dataType={props?.dataType}
              handleItemsSuccessfullyAdded={props?.handleItemsSuccessfullyAdded}
              data={data}
              keyName={keyName}
              cardType={cardType}
              boardData={list}
            />
          );
        })
      ) : filterText?.length ? (
        <div className="boards-list-no-results-main-wrapper">
          <h1 className="boards-list-no-results-text--no-boards">{`No results found for ${filterText}`}</h1>
        </div>
      ) : (
        <div className="boards-list-no-results-main-wrapper">
          <h1 className="boards-list-no-results-text--no-boards">{`No boards added`}</h1>
        </div>
      )}
    </Fragment>
  );
}

export default BoardList;
