import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../config/configKeys";
import { usePostRequestMutation, useUpdateUserEmailMutation } from "../../adapters/xhr/generalApi";
import { validateRequestVendor } from "../../utlis/hof/hof";
import { useNavigate } from "react-router";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import { tempAuthDetails } from "../../contexts/reducer/authReducer";
import * as CONFIG_KEYS from "../../config/config";
const VerifyOTP = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const VALIDATE_OTP = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE.VALIDATE_WORK_EMAIL;
  const [authUser, setUser] = useLocalStorage("user");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [error, setError] = useState(null);
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const emailAddress = tempAuthData?.data?.emailId;
  const showRequestVendorPortal = props?.showRequestVendorPortal;
  // const VerifyOtpPostApi = useEnCryptPostApi(
  //   `/user/OTPVerificationOnUpdateEmail`,
  //   KEYS?.END_POINTS?.CORE?.END_POINT_URL,
  //   useUpdateUserEmailMutation
  // );

  const handleInput = (val, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (regex.test(val)) {
      switch (inputNum) {
        case 1:
          setOtp1(val);
          break;
        case 2:
          setOtp2(val);
          break;
        case 3:
          setOtp3(val);
          break;
        case 4:
          setOtp4(val);
          break;
        case 5:
          setOtp5(val);
          break;
        case 6:
          setOtp6(val);
          break;
        default:
          break;
      }
    }
  };
  const handleFocus = (inputNum) => {
    const input = document.getElementById(`otp${inputNum - 1}`);
    input.focus();
  };
  const clearState = (inputNum) => {
    switch (inputNum) {
      case 1:
        if (otp1) {
          setOtp1("");
          break;
        } else {
          setOtp1("");
        }
        break;
      case 2:
        if (otp2) {
          setOtp2("");
          break;
        } else {
          setOtp1("");
          handleFocus(inputNum);
        }
        break;
      case 3:
        if (otp3) {
          setOtp3("");
          break;
        } else {
          setOtp2("");
          handleFocus(inputNum);
        }
        break;

      case 4:
        if (otp4) {
          setOtp4("");
          break;
        } else {
          setOtp3("");
          handleFocus(inputNum);
        }
        break;
      case 5:
        if (otp5) {
          setOtp5("");
          break;
        } else {
          setOtp4("");
          handleFocus(inputNum);
        }
        break;
      case 6:
        if (otp6) {
          setOtp6("");
          break;
        } else {
          setOtp5("");
          handleFocus(inputNum);
        }
        break;
      default:
        break;
    }
  };
  const handleKeyDown = (event, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (event.key === "Backspace" || event.key === "Delete") {
      setOtpError(false);
      clearState(inputNum);
    } else if (regex.test(event.key)) {
      setTimeout(() => {
        const input = document.getElementById(`otp${inputNum + 1}`);
        input.focus();
      }, 5);
    }
  };
  const VerifyOtpPostApi = useEnCryptPostApi(
    `/user/OTPVerificationOnUpdateEmail`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useUpdateUserEmailMutation
  );
  useEffect(() => {
    if (VerifyOtpPostApi?.data?.status) {
      dispatch(
        tempAuthDetails({
          data: { ...tempAuthData?.data },
          loading: true,
          verifyOTP: false,
          joinOrg: true,
          resetPassword: true,
        })
      );
      setUser({ ...authUser?.data, emailId: VerifyOtpPostApi?.data?.emailId, isValidDomain: true });
      props?.handleChangePage(4);
      // props?.handleClose();
    } else if (!VerifyOtpPostApi?.data?.userExist && VerifyOtpPostApi?.data?.message) {
      setOtpError(true);
      setError({ otpError: VerifyOtpPostApi?.data?.message });
    }
  }, [VerifyOtpPostApi?.data]);

  const handleSubmit = () => {
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    const rawData = {
      emailId: tempAuthData?.data?.emailId,
      userId: tempAuthData?.data?.userId,
      OTP: parseInt(otpValue),
    };
    VerifyOtpPostApi?.handleTrigger(rawData);
  };
  useEffect(() => {
    if (VerifyOtpPostApi?.data?.status)
      setTimeout(() => {
        HandleCloseModal();
      }, 5000);
  }, [VerifyOtpPostApi?.data]);

  const HandleCloseModal = () => {
    props?.handleClose();
  };
  const handlePasteOtp = (event) => {
    event.preventDefault();
    const pasteText = event.clipboardData
      .getData("text/plain")
      .trim()
      .slice(0, 6);
    for (let i = 0; i < 6 && i < pasteText.length; i++) {
      handleInput(pasteText[i], i + 1);
    }
  };
  return (
    <div className="request-vendor-portal--main-wrapper">
      {/* {VerifyOtpPostApi?.data ? (
        <div
          style={{
            background: VerifyOtpPostApi?.data?.Status ? "#008000" : "#c00",
          }}
          className="success-message-pop-up"
        >
          <h3 className="success-message-style-board">{VerifyOtpPostApi?.data?.Message}</h3>
        </div>
      ) : null} */}
      <div className="add-to-board-container-header-wrapper">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <h1 className="request-new-vendor-header-text">{VALIDATE_OTP.SUBMIT_REQUEST}</h1>
      </div>
      <div className="request-category-container">
        <div className="m-b-sm">
          <div className="input-field-wrapper-position-relative">
            <div className="flex space-between m-b-md " onPaste={handlePasteOtp}>
              <input
                id="otp1"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp1}
                onChange={(e) => handleInput(e.target.value, 1)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 1)}
                autoFocus
                pattern="\d*"
              />
              <input
                id="otp2"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp2}
                onChange={(e) => handleInput(e.target.value, 2)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 2)}
                pattern="\d*"
              />
              <input
                id="otp3"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp3}
                onChange={(e) => handleInput(e.target.value, 3)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 3)}
                pattern="\d*"
              />
              <input
                id="otp4"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp4}
                onChange={(e) => handleInput(e.target.value, 4)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 4)}
                pattern="\d*"
              />
              <input
                id="otp5"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp5}
                onChange={(e) => handleInput(e.target.value, 5)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 5)}
                pattern="\d*"
              />
              <input
                id="otp6"
                type="tel"
                className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                autoComplete="off"
                value={otp6}
                onChange={(e) => handleInput(e.target.value, 6)}
                maxLength={1}
                onKeyDown={(e) => handleKeyDown(e, 6)}
                pattern="\d*"
              />
            </div>
            {otpError ? (
              <p className="input-field-text-error" style={{ top: "80px" }}>
                {error?.otpError}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex justify-flex-end">
          <div className="request-category-submit-button" onClick={() => handleSubmit()}>
            {VALIDATE_OTP.SUBMIT_REQUEST_BUTTON}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
