import React, { useState } from "react";

import * as ADD_SECTION__KEYS from "../../../../config/config";
import Header from "../../../addProposal/component/proposalSubmit/component/header";
import TimelineAndLicense from "./timelineAndLicense";
const TimelineParent = (props) => {
  const [showSection, setShowSection] = useState(false);
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  return (
    <div style={{ width: "100%" }}>
      <Header
        USER_CAN_EDIT={USER_CAN_EDIT}
        PROPOSAL_TYPE={PROPOSAL_TYPE}
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        hideEdit={true}
        headerTitle={"Timeline and License"}
      />
      {/*  proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length */}
      {showSection ? (
        <TimelineAndLicense
          USER_CAN_EDIT={USER_CAN_EDIT}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
        />
      ) : null}
    </div>
  );
};

export default TimelineParent;
