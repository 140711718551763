import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../../../../utlis/customHooks/useLocalStorage";
import { GetNameInitials } from "../../../../../utlis/hof/hof";
import NavItems from "../../../../authHome/header/component/navItems/navItems";
import HeaderDrawer from "../headerDrawer/headerDrawer";
import ProfileHoverDetails from "../request/profileHoverDetails";

const ProfileDesktop = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [authUser, setUser] = useLocalStorage("user");
  const [hoverOnProfile, setHoverOnProfile] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const authData = useSelector((state) => state?.authDetails?.details);
  const dispatch = useDispatch();

  const initials = GetNameInitials(
    authData?.data?.userName?.length > 1 && authData?.data?.userExist
      ? authData?.data?.userName
      : authData?.data?.firstName?.length
      ? authData?.data?.firstName + " " + authData?.data?.lastName
      : authData?.data?.anonymousName
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const Data = [
    {
      id: 1,
      name: "Business Profile",
      navigate: "/business-profile/blurbs",
    },
    {
      id: 2,
      name: "Sales Hub",
      navigate: "/sales-hub/proposals",
    },
    {
      id: 3,
      name: "Manage Proposals",
      navigate: "/proposals/my-proposal",
    },
    {
      id: 4,
      name: "Edit Profile",
      navigate: "/profile/edit-profile",
    },
    {
      id: 5,
      name: "Manage Boards",
      navigate: "/user-boards",
    },
    {
      id: 6,
      name: "Preferences",
      navigate: "/manage-preferences",
    },
    {
      id: 6,
      name: "Sign out",
      navigate: "/sign-out",
    },
  ];

  const handleModalVisible = () => {
    // if (authData?.data?.userExist) {
    setModalVisible((prev) => !prev);
    // } else {
    //   navigate("/sign-in");
    // }
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleModalVisible(false);
    }
  };
  const handleSwitchPressed = () => {
    const tempData = { ...authData?.data, buyerView: !authData?.data?.buyerView };
    // dispatch(authDetails({ loading: false, data: tempData }));
    setUser(tempData);
  };

  const SignOutUser = () => {
    const signalTrackingPostData = {
      Actor: authUser?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: SECRET_KEYS?.CONFIG?.SIGN_OUT,
      Item_Type: null,
      Item_Id: null,
      User_Type: "Known",
      Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      Target: {
        page: "home",
        link: "/",
      },
      Object: {
        section: null,
        context: "Sign out",
        page: "profile page",
        Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    setTimeout(() => {
      setUser("logout");
      window.location.replace("/");
    }, 1500);
  };

  const handleNavigatePage = (val) => {
    if (val === "/sign-out") {
      SignOutUser();
    } else {
      navigate(val);
    }
  };
  return (
    <>
      <a className="navbar__item">
        <div className="navbar__item-content">
          <NavItems page={props?.page} />
          <a
            className="navbar__item navbar__sub-menu-toggle navbar__mega-menu navbar__menu"
            data-controller="sub-menu-toggle"
          >
            {(authData?.data?.userName && authData?.data?.userExist) ||
            (authData?.data?.firstName && authData?.data?.userExist) ||
            true ? (
              <div className="navbar__item-content">
                <div className="relative flex align-items-center">
                  <div
                    className="dtrd-navbar__avatar-box "
                    // onClick={() => navigate("/profile")}
                    onClick={() => handleModalVisible()}
                  >
                    <div class="dtrd-navbar__avatar-box navbar__user">
                      <div class="circular-avatar">
                        <div class="user-initials user-initials--primary ">{initials}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <ProfileHoverDetails
              page={props?.page}
              handleCardClicked={handleModalVisible}
              userName={authData?.data?.userName ? authData?.data?.userName : ""}
              emailAddress={authData?.data?.emailId}
            />

            {/* {authData?.data?.userName || authData?.data?.userExist || authData?.data?.firstName ? (
              <div className="tablet-hidden navbar__item-title">
                <div
                  className="sign-in-text"
                  onClick={() => handleModalVisible()}
                  style={{ color: authData?.data?.userExist || true ? "#fff" : "#FFB527" }}
                >
                  {authData?.data?.userName?.length > 1 && authData?.data?.userExist
                    ? ""
                    : authData?.data?.firstName && authData?.data?.userExist
                    ? ""
                    : authData?.data?.anonymousName}
                </div>
              </div>
            ) : (
              <div className="tablet-hidden">
                <div className="navbar__item-content">
                  <div className="relative flex align-items-center">
                    <div
                      onMouseEnter={() => setHoverOnProfile(true)}
                      onMouseLeave={() => setHoverOnProfile(false)}
                      onClick={() => navigate("/sign-in")}
                    >
                      <div style={{ background: "transparent" }}>
                        <div>
                          {hoverOnProfile ? (
                            <img
                              width="35px"
                              height="35px"
                              src={require("../../../../../assets/images/noun-sign-up-4518651-FFB527.svg").default}
                              alt={"Sign up image"}
                            ></img>
                          ) : (
                            <img
                              width="35px"
                              height="35px"
                              src={require("../../../../../assets/images/noun-sign-up-4518651-FFFFFF.svg").default}
                              alt={"Sign up image"}
                            ></img>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </a>
        </div>
      </a>

      {modalVisible ? <HeaderDrawer handleClose={() => setModalVisible(false)} visible={modalVisible} /> : null}
    </>
  );
};
export default ProfileDesktop;
