import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import CommentsHeader from "./commentsHeader";
import CommentsList from "./commentsList";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import { useGetCommentsQuery, useGetRequestQuery } from "../../../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import CommentFooter from "./commentFooter";
import BoxSpinner from "../../../../../../utlis/spinner/boxSpinner";
const Comments = (props) => {
  const [toggleSection, setToggleSection] = useState(true);
  const [commentListData, setCommentListData] = useState([]);
  const [replyData, setReplyData] = useState(null);
  const commentsEndRef = useRef(null);
  const proposalType = props?.proposalType;
  const selectedQuestion = props?.selectedQuestion;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const Vendor_Id = props?.Vendor_Id;
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const QUESTION_ID = selectedQuestion?.Proposal_Question_ID;
  const proposalId = props?.proposalId;
  const selectedSection = props?.selectedSection;
  const handleToggleSection = (val) => {
    setToggleSection(!toggleSection);
  };
  const removeReply = (val) => {
    setReplyData(null);
  };
  const proposalCommentList =
    proposalType == "answer"
      ? `/proposal/comments?ID=${Vendor_Id}_${QUESTION_ID}&Type=Proposal&offset=0&Vendor_ID=${Vendor_Id}&userId=${userId}`
      : `/proposal/comments?ID=${Vendor_Id}_${QUESTION_ID}&Type=Proposal&offset=0&Vendor_ID=${Vendor_Id}&userId=${userId}`;
  const GetProductDataFromApi = useEnCryptGetApi(
    proposalCommentList,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetCommentsQuery
  );
  useEffect(() => {
    if (GetProductDataFromApi.data) {
      setCommentListData(GetProductDataFromApi.data?.data);
    }
  }, [GetProductDataFromApi.data]);
  const handleSendClicked = (val) => {
    let data = commentListData?.length ? [...commentListData] : [];
    data.push(val);
    setCommentListData(data);
    setTimeout(() => {
      commentsEndRef.current?.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );
    });
  };
  const handleReplyClicked = (val) => {
    setReplyData(val);
  };
  const handleComments = (val) => {
    setCommentListData(val);
  };
  return (
    <div style={{ zIndex: "999999999999999999999px" }}>
      <CommentsHeader toggleSection={toggleSection} proposalId={proposalId} handleToggleSection={handleToggleSection} />
      {toggleSection ? (
        <>
          <>
            {GetProductDataFromApi?.isLoading || GetProductDataFromApi?.isFetching ? (
              <BoxSpinner />
            ) : (
              <CommentsList
                notificationJson={props?.notificationJson}
                proposalId={proposalId}
                handleReplyClicked={handleReplyClicked}
                handleComments={handleComments}
                proposalType={proposalType}
                ref={commentsEndRef}
                Vendor_Id={Vendor_Id}
                CommentListData={commentListData}
              />
            )}
          </>

          <CommentFooter
            selectedSection={selectedSection}
            proposalId={proposalId}
            removeReply={removeReply}
            replyData={replyData}
            proposalType={proposalType}
            QUESTION_ID={QUESTION_ID}
            Vendor_Id={Vendor_Id}
            handleSendClicked={handleSendClicked}
            CommentListData={commentListData}
          />
        </>
      ) : null}
    </div>
  );
};

export default Comments;
