import React, { useEffect, useState } from "react";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import ChangeAccessList from "./changeAccessList";
import * as NOTIFICATION_KEYS from "../../../../config/config";
import FeatherIcon from "feather-icons-react";
const staticData = [
  {
    Name: "Can View",
    Internal_Name: "can_view",
    id: 1,
  },
  {
    Name: "Can Edit",
    Internal_Name: "can_edit",
    id: 2,
  },
];

function AddUserAccessTypeList(props) {
  const TEAMS_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [toggleMenu, setToggleMenu] = useState(false);
  const actionSelected = props?.actionSelected;
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  // useEffect(() => {
  //   setActionSelected(props?.data?.[TEAMS_KEYS_API?.ACCESS]);
  // }, [props?.data]);
  const handleActionClicked = (val) => {
    // props.handleActionClicked(val);
    props?.handleActionSelected(val?.Internal_Name);
    setTimeout(() => {
      setToggleMenu(false);
    }, 100);
  };
  const handleShareBlurbClicked = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <div tabIndex={0} onBlur={handleBlur} className="change-type-main-wrapper-items p-r">
      <div className="add-user-teams-add-user-access-display-text-wrapper" onClick={() => handleShareBlurbClicked()}>
        <p className="team-add-user-access-display-text">{actionSelected == "can_view" ? "Can View" : "Can Edit"}</p>
        {toggleMenu ? (
          <FeatherIcon icon={"chevron-up"} size="20" color={"#37393f"} />
        ) : (
          <FeatherIcon icon={"chevron-down"} size="20" color={"#37393f"} />
        )}
      </div>
      {toggleMenu && (
        <div className="view-more-container  modern-card modern-card--elevated">
          <ChangeAccessList
            handleActionClicked={handleActionClicked}
            handleToggleMenu={handleToggleMenu}
            data={staticData}
          />
        </div>
      )}
    </div>
  );
}

export default AddUserAccessTypeList;
