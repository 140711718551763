import { api } from "../../contexts/redux/slice";
export const searchService = api.injectEndpoints({
  endpoints: (builder) => ({
    getRequest: builder.query({
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postRequest: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
    }),
    putRequest: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
    }),
    putPutUpdateUserProfile: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
    }),
    addProductsApi: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["addProducts"],
    }),
    addProductsPutApi: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      // invalidatesTags: ["addProducts"],
    }),
    getProductsApi: builder.query({
      providesTags: ["addProducts"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postRequestImage: builder.mutation({
      query: (body) => ({
        url: `/core/updateUserProfileImage`,
        method: "POST",
        body,
      }),
    }),
    deleteRequestImage: builder.mutation({
      query: (body) => ({
        url: `/core/removeUserProfileImage`,
        method: "POST",
        body,
      }),
    }),
    blurbProcessor: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["addBlurbs"],
    }),
    blurbProcessorPut: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      // invalidatesTags: ["addBlurbs"],
    }),
    blurbProcessor1: builder.mutation({
      query: (body) => ({
        url: "BlurbProcessor/approveUpdatedBlurb",
        method: "POST",
        body,
      }),
      invalidatesTags: ["addBlurbs"],
    }),
    getBlurbsApi: builder.query({
      providesTags: ["addBlurbs"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    getProposal: builder.query({
      providesTags: ["addProposal"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    addProposalApi: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["addProposal"],
    }),
    addProposalPutApi: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["addProposal"],
    }),
    getComments: builder.query({
      providesTags: ["comments"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    addCommentsApi: builder.mutation({
      invalidatesTags: ["comments"],
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
    }),
    updateNotificationStatusApi: builder.mutation({
      invalidatesTags: ["notification"],
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
    }),
    getNotification: builder.query({
      providesTags: ["notification"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    addBookmark: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Boards"],
    }),
    getBoardsData: builder.query({
      providesTags: ["Boards"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    PostBoard: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["myBoards"],
    }),
    PutBoard: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["myBoards"],
    }),
    updateUserEmail: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
    }),
    getMyBoardsData: builder.query({
      providesTags: ["myBoards"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    getItemsFromBoard: builder.query({
      providesTags: ["BoardItems"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    addItemsToBoard: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["BoardItems"],
    }),
    addItemsToBoardPut: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["BoardItems"],
    }),
    postCommentForCard: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["cardComments"],
    }),
    getCommentsForCard: builder.query({
      providesTags: ["cardComments"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postProposalTeamsApi: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["proposalTeams"],
    }),
    getProposalTeamsApi: builder.query({
      providesTags: ["proposalTeams"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    getUpdatedProposal: builder.query({
      providesTags: ["updateProposal"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    updateProposalApi: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["updateProposal"],
    }),
    productExpGetPost: builder.query({
      providesTags: ["posts"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    productExpCreateNewPost: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["posts"],
    }),
    deleteProductExpertDraft: builder.mutation({
      query: (getQuery) => ({
        url: `api/delete`,
        method: "DELETE",
        params: getQuery,
      }),
      invalidatesTags: ["posts"],
    }),
  }),
});
export const {
  useGetRequestQuery,
  useUpdateUserEmailMutation,
  usePutRequestMutation,
  usePostRequestMutation,
  usePostRequestImageMutation,
  useDeleteRequestImageMutation,
  useAddProductsApiMutation,
  useGetProductsApiQuery,
  useBlurbProcessor1Mutation,
  useGetBlurbsApiQuery,
  useBlurbProcessorMutation,
  useGetProposalQuery,
  useAddProposalApiMutation,
  useGetCommentsQuery,
  useAddCommentsApiMutation,
  useGetNotificationQuery,
  useUpdateNotificationStatusApiMutation,
  useGetBoardsDataQuery,
  useAddBookmarkMutation,
  useGetMyBoardsDataQuery,
  usePostBoardMutation,
  useGetItemsFromBoardQuery,
  useAddItemsToBoardMutation,
  useGetCommentsForCardQuery,
  usePostCommentForCardMutation,
  usePostProposalTeamsApiMutation,
  useGetProposalTeamsApiQuery,
  useGetUpdatedProposalQuery,
  useUpdateProposalApiMutation,
  useAddProductsPutApiMutation,
  useBlurbProcessorPutMutation,
  usePutBoardMutation,
  useAddItemsToBoardPutMutation,
  usePutPutUpdateUserProfileMutation,
  useAddProposalPutApiMutation,
  useProductExpCreateNewPostMutation,
  useProductExpGetPostQuery,
  useDeleteProductExpertDraftMutation,
} = searchService;
