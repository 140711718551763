import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import BoardRoundSpinner from "../../../../utlis/spinner/boardRoundSpinner";
function ShowGraphsWithFilter(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorId = authData?.data?.vendorId;
  const dashboardFilterDetails = useSelector((state) => state?.dashboardDetails?.dashboardFilter);
  // /getPerDayPageVisitsCount
  const selectedTab = props?.selectedTab;
  const selectedTabType = selectedTab == 1 ? "visitor" : selectedTab == 2 ? "engagement" : "content";
  const getOverView = useEnCryptGetApi(
    // getPerDayPageVisitsCount?vendorId=155&filters=[{name: "All Time",
    // id: 1,type: "date", },]
    `/getPerDayPageVisitsCount?vendorId=${vendorId}&filter=${JSON.stringify(
      dashboardFilterDetails
    )}&type=${selectedTabType}${selectedTab == 1 ? `&graphFilter=${JSON.stringify(props?.graphFilterDetails)}` : ""} `,
    KEYS?.END_POINTS?.DASHBOARD?.END_POINT_URL,
    useGetRequestQuery
  );
  const Label = getOverView.data?.data?.Label;
  const GraphData = getOverView.data?.data?.Data;
  const DataSets = GraphData?.length
    ? GraphData.map((set, index) => {
        return {
          label: set?.Label,
          data: set?.Count?.length ? set?.Count : [0, 0, 0, 0, 0, 0, 0],
          borderWidth:2,
          borderColor:
            index == 1
              ? "rgb(64, 38, 255)"
              : index == 2
              ? "rgb(176, 38, 255)"
              : index == 3
              ? "rgba(255, 136, 38)"
              : index == 4
              ? "rgb(226, 24, 24)"
              : "rgb(254,157,82)",
          backgroundColor:
            index == 1
              ? "rgba(64, 38, 255, 0.5)"
              : index == 2
              ? "rgba(176, 38, 255,0.5)"
              : index == 3
              ? "rgba(255, 136, 38,0.5)"
              : index == 4
              ? "rgba(226, 24, 24, 0.5)"
              : "rgba(254,157,82,0.5)",
        };
      })
    : [
        {
          label: "no Data",
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: "rgb(255, 181, 39)",
          backgroundColor: "rgba(255, 181, 39,0.5)",
        },
      ];
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const labels =
    Label && Label?.length > 0 ? Label : [("January", "February", "March", "April", "May", "June", "July")];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const data = {
    labels,
    datasets: DataSets,
  };

  return (
    <div
      className={`${getOverView?.isLoading || getOverView?.isFetching ? "dashboard-card-is-loading p-r" : ""}`}
    >
      {getOverView?.isLoading || getOverView?.isFetching ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            // top: "15px",
          }}
        >
          <BoardRoundSpinner />
        </div>
      ) : null}
      <Line options={options} data={data} />
    </div>
  );
}

export default ShowGraphsWithFilter;

// [
//   {
//     label: "Vendors Views",

//     data: [10, 23, 56, 34, 55, 35, 12],
//     borderColor: "rgb(255, 181, 39)",
//     backgroundColor: "rgba(255, 181, 39,0.5)",
//   },
//   {
//     label: "Product Views",

//     data: [10, 30, 50, 60, 20, 30, 20],
//     borderColor: "rgb(53, 162, 235)",
//     backgroundColor: "rgba(53, 162, 235, 0.5)",
//   },
// ],
