import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const items = [...Array(33).keys()];

export function MobilePaginatedItems({
  itemsPerPage,
  HandlePageNumber,
  totalNumber,
}) {
  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalNumber / itemsPerPage));
  }, [totalNumber, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalNumber.length;
    setItemOffset(newOffset);
    HandlePageNumber(event.selected + 1);
  };

  return (
    <>
      <div class="text-center only-mobile">
        {/* <div class="dtrd-menu pagination"> */}
        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          // pageRangeDisplayed={3}
          // marginPagesDisplayed={2}
          pageCount={pageCount ? pageCount : 0}
          previousLabel="< Previous"
          pageClassName="item"
          // pageLinkClassName="page-link"
          //   previousClassName="icon item"
          previousLinkClassName="icon item"
          //   nextClassName="icon item"
          nextLinkClassName="icon item"
          breakLabel="..."
          breakClassName="item"
          // breakLinkClassName="page-link"
          // containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          className="dtrd-menu pagination"
        />
        {/* </div> */}
      </div>
    </>
  );
}
