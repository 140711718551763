import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
// import * as KEYS from "../../../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { useNavigate } from "react-router";
const TopicList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const navigation = useNavigate();
  const signalTrackingSearchObject = {
    Action: SECRET_KEYS.CONFIG.SEARCH_CLICKED,
    page: "search page",
    section: "search box",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleSearchClicked = (item) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingSearchObject?.Action,
      Item_Type: "Topic",
      Item_Id: item?.id,
      Target: {
        page: signalTrackingSearchObject?.page,
        link: `/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}`,
      },
      Object: {
        section: signalTrackingSearchObject?.section,
        context: item?.name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    // navigation(`/search/blurbs/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}`);
  };
  return (
    <>
      <div className="result-group">
        {props?.list?.map((item) => {
          const itemNameWithHyphen = item?.name.replace(/ /g, "-");
          const inputText = props?.inputText?.replace(/[.*+?^${}()|[\]\\]/g, "") || "";
          const parts = itemNameWithHyphen?.split(new RegExp(`/${inputText}/`, "gi"));

          return (
            <div onClick={() => handleSearchClicked(item)}>
              <a
                className="result result--inverted cursor--pointer"
                href={`/search/blurbs/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}`}
              >
                <div className="content">
                  <i className="feather icon icon-search "></i>
                  {parts.map((part, i) => {
                    return (
                      <span
                        key={i}
                        style={{
                          fontWeight: part.toLowerCase() !== props?.inputText.toLowerCase() ? "bold" : "normal",
                          textAlign: "start",
                          padding:
                            part[0] == "-"
                              ? "0px 0px 0px 5px"
                              : part[part?.length - 1] == "-"
                              ? "0px 5px 0px 0px"
                              : "0px 0px 0px 0px",
                        }}
                      >
                        {part?.replaceAll("-", " ")}
                      </span>
                    );
                  })}
                </div>
                <i className="feather icon icon-arrow-right "></i>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default TopicList;
