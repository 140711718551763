import React, { useState } from "react";
import * as DASHBOARD_KEYS from "../../../../../config/config";
const DropdownContent = (props) => {
  const data = props?.data?.length ? props?.data : [];
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const FILTER_KEYS = DASHBOARD_CONFIG_KEYS?.FILTERS;
  const [filterList, setFilterList] = useState([
    {
      name: "All Time",
      id: 1,
      type: "date",
    },
    {
      name: "One Year",
      id: 1,
      type: "date",
    },
    {
      name: "6 Months",
      id: 1,
      type: "date",
    },
    {
      name: "3 Months",
      id: 1,
      type: "date",
    },
    {
      name: "1 Month",
      id: 1,
      type: "date",
    },
    {
      name: "7 Days",
      id: 1,
      type: "date",
    },
    {
      name: "Today",
      id: 1,
      type: "date",
    },
  ]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {filterList?.map((list) => {
        return (
          <div
            onClick={() => props?.handleFilterSelected(list)}
            className="dashboard-dropdown-filter-options-wrapper cursor--pointer"
          >
            <h6 className="dashboard-dropdown-filter-options-text">
              {list?.[FILTER_KEYS?.NAME]}
            </h6>
          </div>
        );
      })}
    </div>
  );
};

export default DropdownContent;

// {
//   name: "Today",
//   id: 1,
//   type: "date",
// },
