import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { validateForgotPassword } from "../../../../utlis/hof/hof";
import InputFieldNew from "../../signUp/onBoard/components/InputFieldNew";
import _ from "lodash";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
import * as SECRET_KEYS from "../../../../config/configKeys";

const VerifyEmailForm = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const RESET_PASSWORD = KEYS?.RESET_PASSWORD;
  const authData = useSelector((state) => state?.authDetails?.details);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ForgotPassword = useEnCryptPostApi(`/user/forgotPassword`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const dispatchTempData = async (val) => {
    const tempData = (await ForgotPassword?.data?.userExist) ? true : false;
    if (tempData) {
      dispatch(
        tempAuthDetails({
          data: {
            ...ForgotPassword?.data,
          },
          source: props?.page,
          target: "/reset-password",
          verifyOTP: true,
          resetPassword: true,
        })
      );
      navigate("/email-verification", { replace: true });
    } else if (!tempData) {
      setError({
        emailId: ForgotPassword?.data?.message,
      });
    }
  };

  useEffect(() => {
    dispatchTempData();
  }, [ForgotPassword?.data?.userExist]);

  //key changed by rahul there
  const handleSubmit = () => {
    const rawData = {
      emailId: userEmail,
    };
    const validate = validateForgotPassword({ emailId: userEmail });
    setError(validate);
    if (_.isEmpty(validate)) ForgotPassword?.handleTrigger(rawData);
  };
  return (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        <h4 className="already-account-text m-b-mini ">
          {RESET_PASSWORD?.DONT_HAVE_A_CREATE_ACCOUNT}{" "}
          <a href="/sign-up" style={{ color: "#1371c3" }}>
            {RESET_PASSWORD?.SIGN_UP}
          </a>
        </h4>
        <div class="user-registration-container">
          <div className="sign-up-page-header-text m-b-sm m-t-md ">{RESET_PASSWORD?.FORGOT_PASSWORD_TITLE}</div>
          {/* <div className="sign-up-page-text m-b-sm">
          Please provide following details
        </div> */}
          <div className="sign-up-page-text m-b-md">{RESET_PASSWORD?.FORGOT_PASSWORD_SUB_TITLE}</div>
          <InputFieldNew
            label={RESET_PASSWORD?.EMAIL_ADDRESS}
            value={userEmail}
            changeText={(val) => setUserEmail(val)}
            error={error?.emailId}
            icon={"mail"}
            type={"email"}
            maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
          />
          {ForgotPassword?.isLoading ? (
            <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className="create-account-button m-t-md" role={"button"} onClick={() => handleSubmit()}>
              {RESET_PASSWORD?.RESET_PASSWORD}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailForm;
