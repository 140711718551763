import React from "react";
import { createSlice } from "@reduxjs/toolkit";
//  this component is used to handle the the data of the detail blurb selected
export const HomeReducer = createSlice({
  name: "homeReducer",
  initialState: {
    productCategoryData: [],
    categoryData: [],
    productDetails: [],
    topicsData: [],
    utmUrl: "",
    vendorData: {},
    editBlurbData: {},
    editProductData: {},
    editUseCaseData: {},
    requestCategoryStatus: false,
    socket: "",
    chatSocket: "",
    customerData: {},
  },
  reducers: {
    productData: (state, action) => {
      state.productCategoryData = action.payload;
    },
    allCategoryData: (state, action) => {
      state.categoryData = action.payload;
    },
    productDetails: (state, action) => {
      state.productDetails = action.payload;
    },
    selectedTopic: (state, action) => {
      const data = state.topicsData.some((item) => item?.tagId === action.payload.tagId);
      if (!data) state.topicsData.push(action.payload);
    },
    removeTopic: (state, action) => {
      state.topicsData = state.topicsData.filter((item) => item.tagId !== action.payload.tagId);
    },
    clearTopic: (state, action) => {
      state.topicsData = [];
    },
    utmUrlFunction: (state, action) => {
      state.utmUrl = action.payload;
    },
    vendorDetails: (state, action) => {
      state.vendorData = action.payload;
    },
    editBlurbDetails: (state, action) => {
      state.editBlurbData = action.payload;
    },
    editProductDetails: (state, action) => {
      state.editProductData = action.payload;
    },
    editUseCaseDetails: (state, action) => {
      state.editUseCaseData = action.payload;
    },
    socketFn: (state, action) => {
      state.socket = action.payload;
    },
    chatSocketFn: (state, action) => {
      state.chatSocket = action.payload;
    },
    requestCategorySuccess: (state, action) => {
      state.requestCategoryStatus = action.payload;
    },
    addCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
  },
});

export const {
  productData,
  allCategoryData,
  productDetails,
  selectedTopic,
  removeTopic,
  clearTopic,
  utmUrlFunction,
  vendorDetails,
  editBlurbDetails,
  editUseCaseDetails,
  editProductDetails,
  socketFn,
  chatSocketFn,
  requestCategorySuccess,
  addCustomerData,
} = HomeReducer.actions;
export default HomeReducer.reducer;
