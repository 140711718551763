import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../../../utlis/customHooks/customHooks";
import PortalDiv from "../../../../../../businessProfile/components/tabs/components/portalDiv";
import PostLikeTable from "./postLikeTable";
import { utils, writeFile } from "xlsx";
import BoxSpinner from "../../../../../../../utlis/spinner/boxSpinner";

const PostCommentList = ({ handleCloseModal, data }) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const POST_URL = data?.LinkedIn_Post_URN;

  const PostCommentActor = useEnCryptGetApi(
    `/getLinkedInPostActorDetails?postURN=${POST_URL}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );

  const handleExportData = () => {
    if (PostCommentActor?.data?.data?.Comments?.length) {
      const ws = utils.json_to_sheet(PostCommentActor?.data?.data?.Comments);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "LinkedIn-Post-Comments.xlsx");
    }
  };
  return (
    <PortalDiv>
      <div className="add-to-board-modal portalModal">
        <div
          className="add-to-board-container--main-wrapper"
          style={{ width: "auto", minHeight: "auto", minWidth: "50%", maxHeight: "80%" }}
        >
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => handleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text" style={{ marginTop: "0px" }}>
              Comments
            </h1>
          </div>
          {/* <div className="request-category-container">
            <div className="flex align-items-center">
              <img
                src={require("../../../../../../../assets/images/profileIconPlaceholder.png")}
                className="linked-in-posts-card-user-image"
              />
              <p className="linkedIn-post-card-header" style={{ marginLeft: "5px" }}>
                Niranjan
                <br></br>
                <h3 className="created-by-on-each-card">{"24/10/2020"}</h3>
              </p>
            </div>
          </div> */}
          {PostCommentActor?.isLoading ? (
            <BoxSpinner />
          ) : PostCommentActor?.data?.data?.Comments?.length ? (
            <PostLikeTable tableData={PostCommentActor?.data?.data?.Comments} />
          ) : (
            <div className="no-data-likes-message">No comments yet, but stay tuned! Comments update every hour. Thanks for sharing!</div>
          )}
          {superAdminStatus ? (
            <h4 className="download-excel-button" onClick={() => handleExportData()}>
              Download to excel
            </h4>
          ) : null}
        </div>
      </div>
    </PortalDiv>
  );
};

export default PostCommentList;
