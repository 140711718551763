import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import CreateNewVendorPortal from "../../../auth/joinOraganization/components/createNewVendorPortal";
import { Navigate, useNavigate } from "react-router";
import EditBusinessProfile from "./editBusinessProfile";
import ShowMoreText from "react-show-more-text";
import ReadMore from "./ReadMore";
import { useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import RelatedTopics from "./relatedTopics";
import * as CONFIG_KEYS from "../../../../config/config";
import * as SECRET_KEYS from "../../../../config/configKeys";
const BusinessDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const UrlUtmData = useSelector((state) => state?.homeDetails?.utmUrl);
  const authData = useSelector((state) => state?.authDetails?.details);
  const BUSINESS_DETAILS = KEYS?.BUSINESS_LANDING_PAGE_OVERVIEW;
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const USER_ID = authData?.data?.userId;
  const permissionsDetails = props?.permissionsDetails;
  const CanEditOverView = permissionsDetails;
  const navigate = useNavigate();
  const page = props?.page;
  const signalTrackingBlurbObject = {
    Action: SECRET_KEYS.CONFIG.CTA_LINK_CLICKED,
    page: props?.page,
    section: "Business Overview",
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const utmQuery = vendorDetails?.Domain_URL?.includes("?");
  const CTA_LINK = ` ${vendorDetails?.Domain_URL?.replace(/\/$/, "")}${utmQuery ? "&" : "?"}${
    UrlUtmData && UrlUtmData
  }&utm_id=${vendorDetails?.Vendor_ID}`;

  const handleDomainPressed = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Target: {
        page: CTA_LINK,
        link: CTA_LINK,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: val,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    window.open(CTA_LINK, "_blank");
  };
  return (
    <div className="business-aside-container">
      {showModal && CanEditOverView?.Update ? <EditBusinessProfile handleClose={() => setShowModal(false)} /> : null}
      <aside>
        <div className="flex space-between m-b-mini">
          <h3 className="overview-title">{BUSINESS_DETAILS?.OVERVIEW_TITLE}</h3>
          {CanEditOverView?.Update ? (
            <FeatherIcon
              icon="edit-2"
              size="20"
              role="button"
              // className="blurb-detail-social"
              color={"#ABAFB1"}
              onClick={() => setShowModal(true)}
            />
          ) : null}
        </div>
        <div className="m-b-xs">
          {vendorDetails?.About ? (
            <>
              <h5 className="business-profile-headers">{BUSINESS_DETAILS?.DESCRIPTION}</h5>
              {/* <ShowMoreText
                lines={5}
                more="Show more"
                less="Show less"
                anchorClass="show-more-less-clickable"
              >
                <p className="business-profile-description">
                  {vendorDetails?.About}
                </p>
              </ShowMoreText> */}
              <ReadMore>{vendorDetails?.About}</ReadMore>
            </>
          ) : null}
        </div>
        {vendorDetails?.Product_Categories?.length > 0 ? (
          <div className="m-y-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.PRODUCT_CATEGORY}</h5>
            <ul className="business-profile-list-item business-profile-description">
              {vendorDetails?.Product_Categories?.map((item) => {
                return <li className="business-profile-description">{item?.Product_Category_Name}</li>;
              })}
            </ul>
          </div>
        ) : null}
        {vendorDetails?.Domain_URL ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.WEBSITE}</h5>
            <a
              // href={vendorDetails?.Domain_URL}
              role={"button"}
              onClick={() => handleDomainPressed(vendorDetails?.Domain_URL)}
              target="_blank"
            >
              {vendorDetails?.Domain_URL}
            </a>
          </div>
        ) : null}
        {vendorDetails?.Industry ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.INDUSTRY}</h5>
            <p className="business-profile-description "> {vendorDetails?.Industry}</p>
          </div>
        ) : null}
        {vendorDetails?.Founded ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ESTABLISHED}</h5>
            <p className="business-profile-description ">{vendorDetails?.Founded}</p>
          </div>
        ) : null}
        {vendorDetails?.Company_Size ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ORGANIZATION_SIZE}</h5>
            <p className="business-profile-description "> {vendorDetails?.Company_Size}</p>
          </div>
        ) : null}
        {vendorDetails?.Vendor_Type ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ORG_TYPE}</h5>
            <p className="business-profile-description "> {vendorDetails?.Vendor_Type}</p>
          </div>
        ) : null}
        {vendorDetails?.Vendor_HQ_Country ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.HEADQUARTERS}</h5>
            <p className="business-profile-description ">{vendorDetails?.Vendor_HQ_Country}</p>
          </div>
        ) : null}
        {vendorDetails?.Vendor_HQ_Phone && props?.type !== "buyer" ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.CONTACT}</h5>
            <p className="business-profile-description ">{vendorDetails?.Vendor_HQ_Phone}</p>
          </div>
        ) : null}
        {vendorDetails?.Location?.length > 1 ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.OTHER_LOCATION}</h5>
            <p className="business-profile-description ">{vendorDetails?.Location?.length - 1}</p>
          </div>
        ) : null}
        {vendorDetails?.Employee_Range ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.EMPLOYEE_RANGE}</h5>
            <p className="business-profile-description ">{vendorDetails?.Employee_Range}</p>
          </div>
        ) : null}
        {vendorDetails?.Revenue_Range ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.REVENUE_RANGE}</h5>
            <p className="business-profile-description ">{vendorDetails?.Revenue_Range}</p>
          </div>
        ) : null}
      </aside>
      {vendorDetails?.Vendor_ID ? <RelatedTopics page={page} ID={vendorDetails?.Vendor_ID} /> : null}
    </div>
  );
};

export default BusinessDetails;
