import React from "react";
import PreferencesSwitch from "./preferencesSwitch";
import { ToTitleCase } from "../../../utlis/hof/hof";

const PreferenceItem = (props) => {
  const SECTION_DATA = props?.data;
  return (
    <div className="preference-selection-sub-container">
      <h3 className="preferences-section-header">{ToTitleCase(SECTION_DATA?.sectionDisplayName)}</h3>
      {SECTION_DATA?.sectionElements?.map((item) => (
        <div className="preferences-section-options-container" key={item?.sectionElementID}>
          <h4 className="preferences-section-options">{item?.sectionElementDisplayName}</h4>
          <PreferencesSwitch checked={item?.sectionElementDisplayValue} data={item} channel={props?.channel} />
        </div>
      ))}
    </div>
  );
};

export default PreferenceItem;
