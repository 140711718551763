import React, { useState } from "react";
import * as ROLE_CONFIG from "../../../../../config/config";
import { useDispatch } from "react-redux";
import { roleSelected } from "../../../../../contexts/reducer/authReducer";

const RoleSelection = (props) => {
  const [vendorRoles, setVendorRoles] = useState(ROLE_CONFIG?.CONFIG?.SIGN_UP_ROLES);
  const dispatch = useDispatch();
  const handleSelectRole = (val) => {
    setVendorRoles((prevData) => {
      return prevData.map((obj) => {
        if (obj?.id === val?.id) {
          return { ...obj, selected: !obj?.selected };
        }
        return { ...obj, selected: false };
      });
    });
    const TEMP_DATA = { ...val, selected: !val?.selected };
    props?.handleRole(TEMP_DATA);
    dispatch(roleSelected(TEMP_DATA?.selected ? TEMP_DATA : {}));
  };
  return (
    <div className="m-b-sm">
      {/* <h4>Role:</h4> */}
      <div className="flex flex-wrap">
        {vendorRoles?.map((item) => {
          return (
            <div
              className={`proposal-add-question-section-title-wrapper cursor--pointer m-r-half-base m-b-half-base ${
                item?.selected ? "section-active" : "section-in-active"
              } `}
              key={item?.id}
            >
              <p onClick={() => handleSelectRole(item)} className="proposal-add-question-section-title-text">
                {item?.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoleSelection;
