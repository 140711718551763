import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
// import * as KEYS from "../../../config/config";
import * as SECRET_KEYS from "../../../config/config";
const CardImage = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const imageData = props?.Data;
  const signalTrackingProductObject = {
    Action: "Vendor logo clicked",
    page: "vendor page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorLogoLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: signalTrackingProductObject?.page,
      Item_Id: imageData?.Vendor_ID,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <div className="data-product-card__trust">
        <div className="data-product-card__trust-logo">
          <a
            onClick={() =>
              handleVendorLogoLinkClicked(
                `/vendor/${ReplaceBlankSpaceByHyphen(imageData?.Vendor_Name, imageData?.Vendor_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                }`
              )
            }
            href={`/vendor/${ReplaceBlankSpaceByHyphen(imageData?.Vendor_Name, imageData?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`}
          >
            <img
              alt={`${imageData?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
              className="ls-is-cached lazyloaded"
              // data-src={imageData?.Account_Logo}
              src={imageData?.Account_Logo ? imageData?.Account_Logo : KEYS?.CORRUPT_IMAGE_ICON}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
              }}
            />
          </a>{" "}
        </div>

        {/* <div className="provider__rating-summary">
          <img
            className="provider__rating-icon ls-is-cached lazyloaded"
            alt="Start icon"
            data-src={require("../../../assets/images/star-filled.svg").default}
            src={require("../../../assets/images/star-filled.svg").default}
          />
          <span className="provider__rating-summary-score">
            {imageData?.ratingScore}
          </span>
          <span className="provider__rating-summary-count">
            ({imageData?.ratingCount})
          </span>
        </div> */}
      </div>
    </>
  );
};

export default CardImage;
