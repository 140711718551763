import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { EncryptId, ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import ProductFeature from "./productFeatures";
import ProductPricing from "./productPricing";
import * as SECRET_KEYS from "../../../config/configKeys";
const CardPricing = (props) => {
  const PricingDetails = props?.Data;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VIEW_BLURB_CLICKED,
    page: "blurb page-product",
    section: props?.section,
  };
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.VIEW_PRODUCT_CLICKED,
    page: "product page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "blurb",
      Item_Id: PricingDetails?.Product_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleViewProductLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "product",
      Item_Id: PricingDetails?.Product_ID,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <div className="data-product-card__convince">
        {PricingDetails?.price ? (
          <ProductPricing data={PricingDetails} />
        ) : PricingDetails?.features ? (
          <ProductFeature data={PricingDetails} />
        ) : null}
        <div>
          <a
            className="small fluid outlined button m-b-base"
            onClick={() =>
              handleViewBlurbLinkClicked(
                `/${ReplaceBlankSpaceByHyphen(PricingDetails?.Vendor_Name)}/${ReplaceBlankSpaceByHyphen(
                  PricingDetails?.Product_Name
                )}/blurbs/${EncryptId(PricingDetails?.Product_ID)}`
              )
            }
            href={`/${ReplaceBlankSpaceByHyphen(PricingDetails?.Vendor_Name)}/${ReplaceBlankSpaceByHyphen(
              PricingDetails?.Product_Name
            )}/blurbs/${EncryptId(PricingDetails?.Product_ID)}`}
          >
            {" "}
            {KEYS?.ACTION_BUTTONS.REQUEST}
          </a>

          <a
            className="small fluid primary button m-b-base"
            onClick={() =>
              handleViewProductLinkClicked(
                `/${ReplaceBlankSpaceByHyphen(PricingDetails?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                  PricingDetails?.Product_Name,
                  PricingDetails?.Product_ID
                )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
              )
            }
            href={`/${ReplaceBlankSpaceByHyphen(PricingDetails?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
              PricingDetails?.Product_Name,
              PricingDetails?.Product_ID
            )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
          >
            {KEYS?.ACTION_BUTTONS?.VIEW_PRODUCT} ➔
          </a>
        </div>
      </div>
    </>
  );
};

export default CardPricing;
