import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useLocalStorage } from "../../../../utlis/customHooks/useLocalStorage";

const ProfileHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [authUser, setUser] = useLocalStorage("user");
  const navigate = useNavigate();
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignOutUser = () => {
    const signalTrackingPostData = {
      Actor: authUser?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: SECRET_KEYS?.CONFIG?.SIGN_OUT,
      Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      Item_Type: null,
      Item_Id: null,
      User_Type: "Known",
      Target: {
        page: "home",
        link: "/",
      },
      Object: {
        section: null,
        context: "Sign out",
        page: "profile page",
        Persona: authUser?.data?.Persona == "Business user" ? "Business user" : "Buyer",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    setTimeout(() => {
      setUser("logout");
      window.location.replace("/");
    }, 1500);
  };
  return (
    <div class="page-header">
      <h1 class="page-title">{props?.text ? props?.text : KEYS?.MY_PROFILE_PAGE.PROFILE_HEADER.PAGE_TITLE}</h1>

      {/* <a
        class="small modern outlined transparent button"
        rel="nofollow"
        data-method="delete"
        // href="/users/sign-out"
        onClick={() => SignOutUser()}
      >
        {KEYS?.MY_PROFILE_PAGE.PROFILE_HEADER.LOGOUT_BUTTON}
      </a> */}
    </div>
  );
};

export default ProfileHeader;
