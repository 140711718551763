import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import HeaderSEO from "../../components/SEO/header";

const PrivateRoutes = (props) => {
  const location = useLocation();

  const authData = useSelector((state) => state?.authDetails?.details);

  return (
    <>
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      {authData?.data?.userExist ? (
        <Outlet />
      ) : (
        <Navigate to="/sign-in" replace state={{ path: location.pathname }} />
      )}
    </>
  );
};

export default PrivateRoutes;
