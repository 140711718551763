import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { EncryptApiGetData, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
// import * as KEYS from "../../../../../config/config";
import SearchInputDropDown from "../../../blades/search/component/searchInputDropDown";
import HeaderSearchType from "./headerSearchType";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
import * as SECRET_KEYS from "../../../../../config/configKeys";
const HeaderSearch = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const [searchedTextValue, setSearchedTextValue] = useState(
    props?.page?.includes("search results") ? PARAMS?.name?.replaceAll("-", " ") : ""
  );
  const [enCryptSearchedText, setEnCryptSearchedText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const PROVIDERS = KEYS?.HEADER_SEARCH_TYPE.PROVIDER;
  const PRODUCTS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PRODUCT_LABEL;
  const PROVIDERS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PROVIDER_LABEL;
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const [changeType, setChangeType] = useState(
    props?.type
      ? props?.type == PRODUCTS
        ? PRODUCTS_LABEL
        : props?.type === "vendors"
        ? PROVIDERS_LABEL
        : props?.type
      : PRODUCTS_LABEL
  );
  const signalTrackingSearchObject = {
    Action: SECRET_KEYS.CONFIG.SEARCH_CLICKED,
    page: "search page",
    section: "search box",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingSearchObject?.Action,
      Item_Type: null,
      Item_Id: null,
      Target: {
        page: signalTrackingSearchObject?.page,
        link: `/search/${changeType.toLocaleLowerCase()}/${ReplaceBlankSpaceByHyphen(
          searchedTextValue ? searchedTextValue : ""
        )}`,
      },
      Object: {
        section: signalTrackingSearchObject?.section,
        context: searchedTextValue,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    navigation(
      `/search/${props?.type ? props?.type.toLocaleLowerCase() : "blurbs"}/${ReplaceBlankSpaceByHyphen(
        searchedTextValue ? searchedTextValue : ""
      )}`
    );
    // setSearchedTextValue("");
    inputRef.current.blur();
  };
  const {
    data: searchedTextData,
    isError: searchedTextIsError,
    isLoading: searchedTextLoading,
    isFetching: searchedTextIsFetching,
  } = useGetRequestQuery(enCryptSearchedText, {
    skip: enCryptSearchedText?.data && searchedTextValue ? false : true,
  });
  const handleChangeType = (val) => {
    setChangeType(val);
  };
  // const GetApiData = useEnCryptGetApi(
  //   `/completeSearch?searchText=${searchedTextValue}&userId=27&limit=5`,
  //   KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
  //   useGetRequestQuery
  // );

  const handleChange = (e) => {
    const enCryptData = {
      // completeSearch?searchText=account&userId=27&boardID=286&limit=5
      path: `/completeSearch?searchText=${e.target.value}&userId=27&limit=5`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };

    const enCryptSearchedText = EncryptApiGetData(enCryptData);
    setEnCryptSearchedText(enCryptSearchedText);
    setSearchedTextValue(e.target.value);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 500);
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  return (
    <div className="navbar__item dtrd-navbar__search" style={{ maxWidth: "600 !important" }}>
      <form className="dtrd-form" onSubmit={handleSubmit}>
        <div className="autocomplete-search">
          <div className="autocomplete-search__input">
            {/* <div className="field" id="field-search_context">
              <HeaderSearchType
                handleChangeType={handleChangeType}
                changeType={changeType}
              />
            </div> */}
            <div className="field full-width" id="field-keywords">
              <input
                ref={inputRef}
                className="autocomplete-search__prompt"
                placeholder={KEYS?.SEARCH_INPUT.PLACEHOLDER}
                id="keywords"
                type="text"
                name="keywords"
                value={searchedTextValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <button type="submit">
              <i className="feather icon icon-search"></i>
            </button>
          </div>
          <div
            className={`${
              searchedTextValue?.length && showDropdown
                ? "autocomplete-search__results transition visible "
                : "autocomplete-search__results"
            }`}
            style={{
              display: `${searchedTextValue?.length && showDropdown ? "block" : "none"}`,
            }}
          >
            {searchedTextLoading ? null : searchedTextIsFetching ? null : searchedTextIsError ? null : searchedTextData?.data &&
              showDropdown ? (
              <SearchInputDropDown page={props?.page} searchedList={searchedTextData} inputText={searchedTextValue} />
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};
export default HeaderSearch;
