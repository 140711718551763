import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { updateMetaTags } from '../../../../src/components/SEO/seo';
import { usePostRequestMutation } from '../../../adapters/xhr/generalApi';
import { useEnCryptSignalTrackingApi } from '../../customHooks/customHooks';
import { GetIdFormLink } from '../../hof/hof';
import * as SECRET_KEYS from '../../../config/configKeys';

const TabNavigator = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const navigate = useNavigate();
  const tabIndex = PARAMS?.tab ? PARAMS?.tab : props?.tabIndex;
  const tabItem = props?.tabItem;
  const NAVIGATE_PATH = props?.navigatePath;

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: `${props?.page}`,
  };

  const handleUrlForPage = (val, itemType) => {
    switch (itemType) {
      case 'product':
        return `/${PARAMS?.vendor}/product/${PARAMS?.name}/${val}`;
      case 'searchResult':
        return `/search/${val}${PARAMS?.name ? `/${PARAMS?.name}` : ''}`;
      case 'productExpert':
        return `/product-expert/home/${val}`;
      case 'board-detail':
        return `/board-detail${PARAMS?.type ? `/${PARAMS?.type}` : ''}/${val}`;
      case 'blurbDetail':
        return `${PARAMS?.vendor ? `/${PARAMS?.vendor}` : ''}/blurb${PARAMS?.name ? `/${PARAMS?.name}` : ''}/${val}`;
      case 'usecase':
        return `${PARAMS?.vendor ? `/${PARAMS?.vendor}` : ''}/usecase${PARAMS?.name ? `/${PARAMS?.name}` : ''}/${val}`;
      default:
        return `/${itemType}${PARAMS?.name ? `/${PARAMS?.name}` : ''}/${val}`;
    }
  };

  const handleSignalTracking = (item, path) => {
    const TARGET_PATH = handleUrlForPage(item, path);
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: 'tab navigation',
      Item_Id: null,
      Target: {
        page: `${path}-${item}`,
        link: TARGET_PATH,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: 'tab switched',
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const handleNavigatePage = (val) => {
    // window.scrollTo(0, 0);
    handleSignalTracking(val, NAVIGATE_PATH);
    const idObject = GetIdFormLink(PARAMS?.name?.replaceAll('-', ' '));
    var seoBody = {
      type: NAVIGATE_PATH,
      id: idObject?.Id,
      tab: val,
      SearchText: idObject?.Name,
    };
    if (seoBody.type == 'blurbDetail' || seoBody.type == 'usecase') {
      const BLURB_DETAILS = PARAMS?.name;
      const TAB_INDEX = PARAMS?.tab;
      let BLURB_ID = BLURB_DETAILS?.split('-').at(-1);
      seoBody.id = BLURB_ID;
      updateMetaTags(seoBody);
    } else {
      updateMetaTags(seoBody);
    }

    // const NAVIGATE_URL = handleUrlForPage(val, NAVIGATE_PATH);
  };

  return (
    <div className='flex m-t-base' key={tabItem?.value}>
      <Link to={handleUrlForPage(tabItem?.value, NAVIGATE_PATH)}>
        {tabItem?.label?.length > 20 && tabItem?.label?.length > 23 ? (
          <h2
            className={`tab-index-text ${tabIndex == tabItem?.value ? 'tab-index-selected' : null}`}
            onClick={() => handleNavigatePage(tabItem?.value)}
            data-inverted='true'
            data-tooltip={tabItem?.label}
            data-position='right center'
          >
            {`${tabItem?.label?.slice(0, 20)}...`}
          </h2>
        ) : (
          <>
            <h2
              className={`tab-index-text ${tabIndex == tabItem?.value ? 'tab-index-selected' : null}`}
              onClick={() => handleNavigatePage(tabItem?.value)}
            >
              {tabItem?.label}
            </h2>
          </>
        )}
      </Link>
    </div>
  );
};

export default TabNavigator;
