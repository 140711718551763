import React from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import BlurbTags from "../../../../blurbDetail/components/blurb/blurbTags";

const PopularUseCaseContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;

  const signalTrackingBlurbObject = {
    Action: "Usecase title clicked",
    page: props?.page,
    section: props?.section,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Item_Type: "Use case",
      Item_Id: DATA?.blurbID,
      Target: {
        page: "Use case",
        link: `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
          BreakText(DATA?.title_level_1)
        )}-${DATA?.blurbID}`,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: val?.title_level_1,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div className="blurb-card-content-container" style={{ width: "100%" }}>
      <div onClick={() => handleBlurbLinkClicked(DATA)}>
        <a
          href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
            BreakText(DATA?.title_level_1?.trim())
          )}-${DATA?.blurbID?.trim()}`}
        >
          {/* <h1 className="new-case-study-card-title">{DATA?.title_level_1}</h1> */}
          {(props?.page === "product page-use-cases" || props?.page === "vendor page") && DATA?.Page_Type ? (
            <div className="flex additional-info-vendor-page use-case-type-margin">
              <FeatherIcon
                icon={"book"}
                size="12"
                style={{ marginRight: "5px" }}
                // className="blurb-detail-social"
              />
              <p data-inverted data-tooltip={"Type of Page"} className="use-case-card__disclaimer">
                {DATA?.Page_Type}
              </p>
            </div>
          ) : null}
          {DATA?.description ? <h2 className="new-blurb-card-description">{parse(DATA?.description)}</h2> : null}
        </a>
      </div>

      {/* <UseCaseInfo data={DATA} page={props?.page} section={props?.section} /> */}
      <BlurbTags data={DATA} />
    </div>
  );
};

export default PopularUseCaseContent;
