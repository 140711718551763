import React, { useEffect, useState } from "react";
import _ from "lodash";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import { usePostBoardMutation, usePostRequestMutation, usePutBoardMutation } from "../../../adapters/xhr/generalApi";
import InputFieldNew from "../../../components/auth/signUp/onBoard/components/InputFieldNew";
import * as CONFIG_KEYS from "../../../config/config";
import * as ENV_KEYS from "../../../config/configKeys";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen, validateCreateBoard } from "../../hof/hof";
import LoadingSpinner from "../../spinner/loadingSpinner";
import TextArea from "../textArea/textArea";
const CreateNewBoard = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const BoardPermissions = allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS];
  const [boardData, setBoardData] = useState({ Private: false });
  const [nameErrorMessage, setNameErrorMessage] = useState(null);
  const [error, setError] = useState({});
  const SuperAdminPermissions = allPermissionsDetails?.vendorId;
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;
  const clone = props?.clone;
  const CanCreateBoard =
    props?.page == "business profile" || (props?.page == "vendor page" && SuperAdminPermissions) ? true : false;
  const canCreateBoardButton = CanCreateBoard && BoardPermissions ? BoardPermissions?.Create : false;
  const RequestMethod = props?.edit ? usePutBoardMutation : usePostBoardMutation;
  const RequestApi = props?.edit ? `/updateBoardDetails` : `/createBoard`;
  const CreateNewBoard = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, RequestMethod);
  const cloneBoardsApi = useEnCryptPostApi(
    `/boards/copyBoardItems`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostBoardMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    if (clone) {
      const rawData = {
        ...boardData,
        User_ID: authData?.data?.userId,
      };
      const validate = validateCreateBoard(rawData);
      setError(validate);
      if (_.isEmpty(validate)) cloneBoardsApi?.handleTrigger(rawData);
    } else {
      const rawData = {
        ...boardData,
        User_ID: authData?.data?.userId,
        Vendor_ID: canCreateBoardButton ? vendorId : null,
        Updated_At: parseInt(new Date().getTime() / 1000),
      };
      const validate = validateCreateBoard(rawData);
      setError(validate);

      if (_.isEmpty(validate)) CreateNewBoard?.handleTrigger(rawData);
    }
  };

  useEffect(() => {
    if (props?.edit) {
      setBoardData(props?.data);
    }
  }, [props?.data]);

  useEffect(() => {
    if (CreateNewBoard?.data?.status) {
      props?.handleClose();
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: props?.edit ? ENV_KEYS.CONFIG.BOARD_UPDATED : ENV_KEYS.CONFIG.BOARD_CREATED,
        Item_Type: canCreateBoardButton ? "Vendor board" : "Board",
        Item_Id: CreateNewBoard?.data?.data?.Board_ID,
        Target: {
          page: "Board detail page",
          link: `/board/${ReplaceBlankSpaceByHyphen(boardData?.Name, CreateNewBoard?.data?.Board_ID)}`,
        },
        Object: {
          section: null,
          context: props?.edit ? `Board updated` : `Board created`,
          page: "/business-profile/boards",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else {
      setNameErrorMessage(CreateNewBoard?.data?.message);
      setTimeout(() => {
        setNameErrorMessage(null);
      }, 5000);
    }
  }, [CreateNewBoard?.data]);
  useEffect(() => {
    if (cloneBoardsApi?.data?.status) {
      props?.handleClose();
    } else {
      setNameErrorMessage(cloneBoardsApi?.data?.message);
      setTimeout(() => {
        setNameErrorMessage(null);
      }, 5000);
    }
  }, [cloneBoardsApi?.data]);

  const handleChangeData = (val) => {
    setBoardData((prev) => ({ ...prev, ...val }));
  };

  return ReactDom.createPortal(
    <div className="add-to-board-modal portalModal">
      <div className="create-board-container">
        {nameErrorMessage ? (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              background: "#c00",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          >
            <h3 className="success-message-style-board">{nameErrorMessage}</h3>
          </div>
        ) : null}
        <h1 className="create-new-board-header-text">
          {clone ? "Copy board" : props?.edit ? "Edit board" : "Create new board"}
        </h1>
        <form className="m-t-sm" onSubmit={onSubmit}>
          <div className="sign-up-page-text m-b-xs">
            <div className="create-board-label-header">
              <label>Board Name</label>
            </div>
            <InputFieldNew
              label={"Board Name"}
              value={boardData?.Name}
              changeText={(val) => handleChangeData({ Name: val })}
              error={error?.Name}
              icon={"clipboard"}
              type={"text"}
            />
          </div>
          <div className="sign-up-page-text m-b-sm">
            <div className="create-board-label-header">
              <label>Board Description</label>
            </div>
            <TextArea
              value={boardData?.Description}
              className="m-b-xs"
              label={"Board Description"}
              changeText={(val) => handleChangeData({ Description: val })}
              removeMargin={true}
              error={error?.Description}
            />
          </div>
          {canCreateBoardButton ? null : (
            <div className="flex align-items-center">
              <input
                type="checkbox"
                name="standard_attribute"
                value={boardData?.Private}
                checked={boardData?.Private}
                onChange={(val) => handleChangeData({ Private: !boardData?.Private })}
                className="filter-checkbox"
                role={"button"}
              />
              <div className="flex flex-column">
                <span className={`filter-list-items-text filter-list-items-text-selected`}>Make private</span>
                <span className={`create-new-board-private-subtitle`}>Only you can see private boards.</span>
              </div>
            </div>
          )}

          <div className="flex align-items-center justify-flex-end m-t-mini">
            <div className=" create-board-cancel-button" role={"button"} onClick={() => props?.handleClose()}>
              Cancel
            </div>
            {CreateNewBoard.isLoading || cloneBoardsApi.isLoading ? (
              <div
                className="create-board-save-button"
                style={{
                  height: "2.6rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "5rem",
                }}
              >
                <LoadingSpinner size={"small"} />
              </div>
            ) : (
              <div className="create-board-save-button" onClick={(e) => onSubmit(e)}>
                {props?.edit ? "Save" : "Create"}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>,

    document.getElementById("portal")
  );
};

export default CreateNewBoard;
