import React from 'react';
import { useSelector } from 'react-redux';

const ViewMoreList = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);

  const handleVisitPageURl = (val) => {
    props?.handleViewOptions();
    window.open(val?.Page_URL, '_blank');
  };
  const handleNewLinkedInPost = (val) => {
    props?.handleLinkedInPost();
  };
  return (
    <>
      <div className='share-single-item transition'>
        <div className='action-button-container' onClick={() => handleVisitPageURl(props?.data)} style={{ width: '100%', padding: '5px' }}>
          <p className='share-desc-title'>View Page</p>
        </div>
        {authData?.data?.isProductExpert ? (
          <div
            className='action-button-container'
            onClick={() => handleNewLinkedInPost(props?.data)}
            style={{ width: '100%', padding: '5px' }}
          >
            <p className='share-desc-title'>New linkedIn post</p>
          </div>
        ) : null}
      </div>
      {/* <div className="share-single-item">
        <div
          className="action-button-container"
          onClick={() => props?.handleActionClicked("delete")}
          style={{ width: "100%", padding: "5px" }}
        >
          {props?.blurbsPostData?.isLoading ? (
            <p className="share-desc-title">loading...</p>
          ) : (
            <p className="share-desc-title">Delete</p>
          )}
        </div>
      </div> */}
    </>
  );
};

export default ViewMoreList;
