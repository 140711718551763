import React from "react";
import logo from "../../assets/images/logoGif.gif";

const PageLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f8f9fc",
        height: "100vh",
      }}
    >
      <img src={logo} alt="Error 404 image" />
    </div>
  );
};

export default PageLoading;
