import React from "react";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import DataProviderCard from "../../../../utlis/hoc/providerCardSmall/dataProviderCard";
// import * as KEYS from "../../../../config/config";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import { DecryptId, GetIdFormLink } from "../../../../utlis/hof/hof";
import { useState } from "react";
import SmallCardSkeleton from "../../../../utlis/hoc/skeleton/smallCardSkeleton";
function AlternativeVendorsList(props) {
  // getAllPopularDataProvider
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const vendorName = props?.vendorName;
  const vendorId = props?.vendorId;
  const [limit, setLimit] = useState(10);
  const GetApiData = useEnCryptGetApi(
    `/getCompetitors?vendorId=${vendorId}&limit=${limit}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const alternativeVendors = GetApiData?.data;
  return (
    <>
      <h2>
        {KEYS?.ALTERNATIVE_VENDOR_LIST?.TITLE} {vendorName}
      </h2>
      {/* <div class="provider-alternatives grid-three-columns col-gap-md tablet-1-col"> */}
      <DataProviderCard
        parent={"alternativeVendor"}
        data={alternativeVendors}
        isLoading={GetApiData?.isLoading}
        isFetching={GetApiData?.isFetching}
        skeletonLength={10}
      />

      <a
        class="display-block m-t-sm text-center"
        onClick={() => setLimit(limit + 10)}
        role={"button"}
      >
        {KEYS?.VENDOR_ALTERNATIVE.MORE}
      </a>
      {/* </div> */}
    </>
  );
}

export default AlternativeVendorsList;
