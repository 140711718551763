import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
// import * as KEYS from "../../../../config/config";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ToTitleCase } from "../../../../utlis/hof/hof";
function Features(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  //    `/getAllPopularProductCategory?limit=10`
  const productId = props?.productId;
  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getAllPopularProductCategory?limit=10
    `/getFeaturesOnProduct?product_ID=${productId?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const productFeatures = GetApiData?.data?.data ? GetApiData?.data?.data : [];
  // const productFeatures = [
  //   {
  //     Product_Feature_ID: 1,
  //     Product_Feature_Name: "Customer support",
  //   },
  //   {
  //     Product_Feature_ID: 1,
  //     Product_Feature_Name: "Advance analytics",
  //   },
  //   {
  //     Product_Feature_ID: 1,
  //     Product_Feature_Name: "Dedicated assistant",
  //   },
  //   {
  //     Product_Feature_ID: 1,
  //     Product_Feature_Name: "Artificial Intelligence",
  //   },
  //   {
  //     Product_Feature_ID: 1,
  //     Product_Feature_Name: "Chat widget",
  //   },
  // ];

  return productFeatures?.length > 5 ? (
    <section class="content-section">
      <h2 class="header">{"Features"}</h2>
      <div>
        <div class="product-content__use_cases">
          {productFeatures?.slice(0, 5)?.map((item) => {
            return (
              <a className="tag-label tag-label--yellow" key={item?.Feature_ID}>
                <span>{ToTitleCase(item?.Product_Feature)}</span>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  ) : productFeatures?.length ? (
    <section class="content-section">
      <h2 class="header">{"Features"}</h2>
      <div>
        <div class="product-content__use_cases">
          {productFeatures?.map((item) => {
            return (
              <a className="tag-label tag-label--yellow" key={item?.Feature_ID}>
                <span>{ToTitleCase(item?.Product_Feature)}</span>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  ) : null;
}

export default Features;
