import React from "react";

function CategoriesSvg() {
  return (
    <svg
      class="icon-menu"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 7.25C2.08579 7.25 1.75 7.58579 1.75 8C1.75 8.41421 2.08579 8.75 2.5 8.75V7.25ZM9.5 8.75C9.91421 8.75 10.25 8.41421 10.25 8C10.25 7.58579 9.91421 7.25 9.5 7.25V8.75ZM2.5 8.75H9.5V7.25H2.5V8.75Z"
        fill="#1371C3"
      ></path>
      <path
        d="M2.5 12.25C2.08579 12.25 1.75 12.5858 1.75 13C1.75 13.4142 2.08579 13.75 2.5 13.75V12.25ZM14.5 13.75C14.9142 13.75 15.25 13.4142 15.25 13C15.25 12.5858 14.9142 12.25 14.5 12.25V13.75ZM2.5 13.75H14.5V12.25H2.5V13.75Z"
        fill="#1371C3"
      ></path>
      <path
        d="M2.5 2.25C2.08579 2.25 1.75 2.58579 1.75 3C1.75 3.41421 2.08579 3.75 2.5 3.75V2.25ZM14.5 3.75C14.9142 3.75 15.25 3.41421 15.25 3C15.25 2.58579 14.9142 2.25 14.5 2.25V3.75ZM2.5 3.75H14.5V2.25H2.5V3.75Z"
        fill="#1371C3"
      ></path>
    </svg>
  );
}

export default CategoriesSvg;
