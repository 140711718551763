import React from "react";

const AddFeatureHeader = (props) => {
  return (
    <div className="add-product-pricing-header-wrapper">
      <div className="add-product-pricing-header-pricing-text-wrapper">
        <h3 className="add-product-pricing-header-pricing-text">Features</h3>
      </div>
      <div className="add-product-pricing-header-pricing-add-plans-button-wrapper">
        <span
          onClick={props?.addPlansClicked}
          className="add-product-feature-header-add-button"
        >
          Add Section
        </span>
      </div>
    </div>
  );
};

export default AddFeatureHeader;
