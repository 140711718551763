import React, { useRef, useState } from "react";
import ReferenceSection from "./component/refrenceSection";
import SearchHeader from "./component/searchHeader";
import SearchInput from "./component/searchInput";

const Search = (props) => {
  return (
    <div className="dtrd-landing-page">
      <section className="landing-page__top-hero">
        <div className="container">
          <div className="flex align-items-center justify-center tablet-1-col">
            <div className="search-input-header-container">
              <SearchHeader page={props?.page} />
              <SearchInput page={props?.page} />
            </div>
          </div>
          <ReferenceSection page={props?.page} />
        </div>
      </section>
    </div>
  );
};
export default Search;
