import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import ExpertPost from "../../../../productExpert/home/components/tabs/posts/expertPost";
import { useSelector } from "react-redux";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";

const ProductExpertList = (props) => {
  const [expertPostVisible, setExpertPostVisible] = useState(false);
  const Data = props?.data;

  return (
    <>
      <div className="product-expert-user-list-container">
        <div className="business-teams-header product-expert-user-names" onClick={() => setExpertPostVisible((prev) => !prev)}>
          <h3 className="team-management-title">{Data?.Full_Name}</h3>
          <FeatherIcon
            icon={expertPostVisible ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setExpertPostVisible((prev) => !prev)}
          />
        </div>
        {expertPostVisible ? <ExpertPost userId={Data?.User_ID} disableHeader={true} /> : null}
      </div>
    </>
  );
};

export default ProductExpertList;
