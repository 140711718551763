import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToTitleCase } from "../../../../utlis/hof/hof";
// import * as KEYS from "../../../../config/config";

const SelectedFilterList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  //   const [SelectedArray, setSelectedArray] = useState(props?.data);
  const SelectedArray = props?.data;

  const handleRemoveItem = (val) => {
    props?.handleRemoveFilterData({ ...val, isSelected: false });
  };
  return SelectedArray?.length > 0 ? (
    <>
      <div className="m-b-base" id="active-filters-list">
        {SelectedArray?.map((item) => {
          return (
            <a
              className="button primary outlined transparent small pill mute thin p-r-base p-l-xs m-r-half-base m-b-base"
              //   href="/search/products?keywords=product"
              key={item?.name}
            >
              <span>{ToTitleCase(item?.name)}</span>

              <i
                className="m-l-half-base feather icon icon-x"
                onClick={() => handleRemoveItem(item)}
              ></i>
            </a>
          );
        })}

        <a
          className="inline-flex align-items-center link-xs fw-600 m-b-base"
          //   href="/search/products?keywords=product"
          role="button"
          onClick={() => props?.handleClearData()}
        >
          <i className="feather icon icon-x"></i>
          {FILTER?.CLEAR_ALL}
        </a>
      </div>
    </>
  ) : null;
};

export default SelectedFilterList;
