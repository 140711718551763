import React from "react";
import FeatherIcon from "feather-icons-react";

const InputFieldFilterProposal = (props) => {
  const error = props?.error;
  return (
    <div className="input-field-wrapper-position-relative">
      <div
        id="input-field-wrapper"
        class={`input-field-wrapper ${error ? "background-error" : ""}`}
        style={{
          border: "1px solid #EFF1F9",
          borderRadius: "5px",
          margin: "0px 10px",
        }}
      >
        <FeatherIcon
          icon={props?.icon}
          size="16"
          color={"#6E7079"}
          className="input-icons-search-filter-proposal-table"
        />
        <input
          id={props?.label}
          type={props?.type ? props?.type : "text"}
          value={props?.value}
          name={props?.label}
          disabled={props?.disable ? props?.disable : false}
          placeholder={props?.label}
          onChange={(e) => props?.changeText(e.target.value)}
          className={`input-field-text-search-proposal-filter ${
            error ? "background-error" : ""
          }`}
          autoComplete="off"
          style={{ paddingLeft: props?.icon ? "30px" : "10px" }}
        />
      </div>
      {error ? <span class="input-field-text-error">{error}</span> : null}
    </div>
  );
};

export default InputFieldFilterProposal;
