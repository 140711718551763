import React from "react";
import { useSelector } from "react-redux";
import { useEnCryptGetApi } from "../../../customHooks/customHooks";
import { useGetCommentsForCardQuery } from "../../../../adapters/xhr/generalApi";
import BoxSpinner from "../../../spinner/boxSpinner";
import InitialCommentCard from "./InitialCommentCard";

const CommentList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PROPS_DATA = props?.data;

  const GetAllComments = useEnCryptGetApi(
    `/comment/getAllComments?Item_ID=${PROPS_DATA?.data?.[PROPS_DATA?.keyName]}&User_ID=${USER_ID}&offset=5&limit=5`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useGetCommentsForCardQuery
  );
  const COMMENT_DATA = GetAllComments?.data?.data;

  return GetAllComments?.isLoading ? (
    <BoxSpinner />
  ) : (
    COMMENT_DATA?.map((item) => (
      <div key={crypto.randomUUID}>
        <InitialCommentCard item={item} data={PROPS_DATA} handleCommentCount={props?.handleCommentCount} cardType={props?.cardType} />
      </div>
    ))
  );
};

export default CommentList;
