import React from "react";

const ProductPricing = (props) => {
  return (
    <>
      <div className="data-product-card__convince-pricing-details">
        <div className="pricing-plan">
          <div className="pricing-plan__label">Starts at</div>
          <div className="pricing-plan__quote">
            <span className="pricing-plan__price">
              ${props?.data?.price}
              <span className="pricing-plan__unit"> / Thousand</span>
            </span>
          </div>
        </div>
        <div className="pricing-model">
          <i className="feather icon icon-check "></i>50% revenue share
        </div>
      </div>
    </>
  );
};

export default ProductPricing;
