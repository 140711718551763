import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import AddProposal from "../../addProposal/addProposal";

const AddProposalPortal = (props) => {
  const vendorDetails = props?.vendorDetails;
  return ReactDom.createPortal(
    <>
      <div className=" ui dimmer modals page visible active portalModal">
        <AddProposal
          vendorDetails={vendorDetails}
          handleClose={() => props?.handleClose()}
        />
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")
  );
};

export default AddProposalPortal;
