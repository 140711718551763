import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useSelector } from "react-redux";
import { GetNameInitials } from "../../../utlis/hof/hof";

function ChatHeader(props) {
  const authData = useSelector((state) => state?.authDetails?.details);

  const initials = GetNameInitials(
    authData?.data?.userName ? authData?.data?.userName : authData?.data?.firstName + " " + authData?.data?.lastName
  );
  return (
    <div className="direct-message-chat-header-container" onClick={() => props?.handleChangeView()}>
      <div>
        <div className="dtrd-navbar__avatar-box">
          <div class="navbar__item" style={{ height: "auto" }}>
            <div class="circular-avatar">
              <div class="user-initials user-initials--primary">{initials}</div>
            </div>
            <h1 className="direct-message-chat-header">Messages</h1>
          </div>
        </div>
      </div>
      <div>
        {/* <FeatherIcon icon="plus-square" size="20" color={"#33343a"} className={"m-r-base"} role="button" /> */}
        <FeatherIcon
          icon={`${props?.listView ? "chevron-down" : "chevron-up"}`}
          size="20"
          color={"#33343a"}
          role="button"
        />
      </div>
    </div>
  );
}

export default ChatHeader;
