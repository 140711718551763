import React from 'react';
import { useSelector } from 'react-redux';

const ProductExpDetails = () => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  return (
    <>
      <div className='flex flex-wrap space-between m-y-xs'>
        <div
          className='flex'
          style={{
            width: '80%',
          }}
        >
          <img
            class=' ls-is-cached lazyloaded'
            src={require('../../../../../assets/images/OctateLogo.png')}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
              backgroundColor: '#f5f5f5',
              borderRadius: '5px',
              border: '1px solid #f5f5f5',
            }}
          />

          <div className='flex-list space-evenly m-l-sm'>
            <h1 class='product-hero__header-title-name'>{authData?.data?.userName}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductExpDetails;
