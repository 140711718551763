import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import EmailVerificationOctate from "../signUp/emailVerification/emailVerificationOctate";
import ResetPasswordForm from "./components/resetPasswordForm";

const ResetPassword = () => {
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  return tempAuthData?.resetPassword ? (
    <main>
      <div class="page-content page-content--reset ">
        <div class="splitted-page">
          <EmailVerificationOctate />
          <ResetPasswordForm />
        </div>
      </div>
    </main>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ResetPassword;
