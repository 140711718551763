import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import TextArea from "../../../utlis/hoc/textArea/textArea";
import { validateDomain } from "../../../utlis/hof/hof";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import CompanyProfileImage from "./companyProfileImage";
import InputTypeDate from "./inputTypeDate";
import SelectProductCategoriesCompany from "./selectProductCategoriesCompany";

const CompanyBasicDetails = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  // const [error, setError] = useState(null);
  const error = props?.companyErrors;
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const StaticDropdownData = props?.StaticDropdownData;
  // const companyBasicDetails = props?.data;
  const companyBasicDetails = props?.companyDetails;

  const COMPANY_PROFILE = KEYS?.COMPANY_PROFILE;
  const GetCountryCode = useEnCryptGetApi(
    `/getCountryDialingCode`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const CountryCode = GetCountryCode?.data?.data;
  const GetCountryData = useEnCryptGetApi(
    `/s3Processor/getCountryList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const DropDownData = GetCountryData?.data?.data?.allCountries;

  // const checkDomainStatus = validateDomain(
  //   props?.validateEmailId,
  //   companyBasicDetails?.Domain_URL
  // );

  return (
    <div className="company-profile-basic-details-main-container">
      <div className="company-profile-basic-details-left-side-container">
        <div>
          <h5 class="company-header-sub-title">{COMPANY_PROFILE?.BASIC_INFORMATION_LABEL}</h5>
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {companyBasicDetails?.Vendor_Name ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {COMPANY_PROFILE?.ORGANIZATION_NAME_LABEL}
            </label>
          ) : null}
          <InputFieldProfile
            disable={props?.validateEmailId ? false : authData?.data?.User_Status === "Onhold" ? true : false}
            label={COMPANY_PROFILE?.ORGANIZATION_NAME_LABEL}
            value={companyBasicDetails?.Vendor_Name}
            changeText={(val) =>
              props?.handleDetails({
                ...companyBasicDetails,
                Vendor_Name: val,
              })
            }
            error={error?.Vendor_Name}
            required={true}
          />
        </div>
        <div class="field p-r  m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {companyBasicDetails?.Domain_URL ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {COMPANY_PROFILE?.COMPANY_WEBSITE_LABEL}
            </label>
          ) : null}
          <InputFieldProfile
            disable={true}
            label={COMPANY_PROFILE?.COMPANY_WEBSITE_LABEL}
            value={
              companyBasicDetails?.Domain_URL ? companyBasicDetails?.Domain_URL : `https://${props?.validateEmailId}`
            }
            changeText={(val) => props?.handleDetails({ ...companyBasicDetails, Domain_URL: val })}
            error={error?.Domain_URL}
          />
          {/* {checkDomainStatus &&
          props?.action === "createNew" &&
          !error?.Domain_URL ? (
            <span class="input-field-text-warning">
              Email domain and website do not match
            </span>
          ) : null} */}
        </div>
        <div class="field p-r m-y-md" id="field-user__phone_number_prefix">
          {companyBasicDetails?.About ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {COMPANY_PROFILE?.ABOUT}
            </label>
          ) : null}
          <TextArea
            value={companyBasicDetails?.About}
            className="m-b-sm"
            label={COMPANY_PROFILE?.ABOUT_LABEL}
            changeText={(val) => props?.handleDetails({ ...companyBasicDetails, About: val })}
            subTitleTop={KEYS?.COMPANY_PROFILE?.MIN_CHARS_FOR_ABOUT}
            error={error?.About}
          />
        </div>
        {/* <div
          class="field-company-profile p-r m-y-base"
          id="field-user__country_id"
        >
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...companyBasicDetails,
                Business_Focus: val,
              })
            }
            data={StaticDropdownData?.Organization_Focus}
            placeholder={COMPANY_PROFILE?.BUSINESS_FOCUS}
            // flag={true}
            selectedVal={companyBasicDetails?.Business_Focus}
            name={"name"}
            label={COMPANY_PROFILE?.BUSINESS_FOCUS_LABEL}
          />
          {error?.Business_Focus ? (
            <span class="input-field-dropdown-error">
              {error?.Business_Focus}
            </span>
          ) : null}
        </div> */}

        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__country_id">
          {companyBasicDetails?.Product_Categories?.length > 0 ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              Product Categories
            </label>
          ) : null}
          <SelectProductCategoriesCompany
            handleDetails={props?.handleDetails}
            companyBasicDetails={companyBasicDetails}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__country_id">
          {companyBasicDetails?.Founded ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {COMPANY_PROFILE?.ESTABLISHED_ON}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.ESTABLISHED_ON_LABEL}
            value={companyBasicDetails?.Founded}
            changeText={(val) =>
              props?.handleDetails({
                ...companyBasicDetails,
                Founded: val,
              })
            }
            error={error?.Founded}
          />
        </div>
        <div class="field-company-profile p-r m-y-base m-b-lg" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...companyBasicDetails,
                Employee_Range: val,
              })
            }
            data={StaticDropdownData?.Organization_Size}
            placeholder={COMPANY_PROFILE?.ORGANIZATION_SIZE_LABEL}
            // flag={true}
            selectedVal={companyBasicDetails?.Employee_Range}
            name={"name"}
            label={COMPANY_PROFILE?.ORGANIZATION_SIZE}
          />
          {error?.Employee_Range ? <span class="input-field-dropdown-error">{error?.Employee_Range}</span> : null}
        </div>
        <div class="field-company-profile p-r m-y-base m-b-lg" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...companyBasicDetails,
                Vendor_Type: val,
              })
            }
            data={StaticDropdownData?.Organization_Type}
            placeholder={COMPANY_PROFILE?.ORGANIZATION_TYPE_LABEL}
            // flag={true}
            selectedVal={companyBasicDetails?.Vendor_Type}
            name={"name"}
            label={COMPANY_PROFILE?.ORGANIZATION_TYPE}
          />
          {error?.Vendor_Type ? <span class="input-field-dropdown-error">{error?.Vendor_Type}</span> : null}
        </div>
      </div>
      <div className="company-profile-basic-details-right-side-container">
        <h3 className="company-profile-feature-services-content-title">{COMPANY_PROFILE?.UPLOAD_LABEL_LOGO}</h3>
        <CompanyProfileImage
          handleDetails={props?.handleDetails}
          image={props?.companyDetails}
          handleSelectedImage={props?.handleSelectedImage}
        />
      </div>
    </div>
  );
};

export default CompanyBasicDetails;
