import React, { useEffect, useRef } from "react";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import LoadingSpinner from "../../../utlis/spinner/loadingSpinner";
// import * as KEYS from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useLocalStorage } from "../../../utlis/customHooks/useLocalStorage";
import * as SECRET_KEYS from "../../../config/configKeys";
const OtpVerificationModal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const signUpComplete = SECRET_KEYS.CONFIG.SIGN_UP_COMPLETE;
  const OTP_VERIFY = KEYS?.OTP_VERIFICATION;
  const OTP = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [authUser, setUser] = useLocalStorage("user");
  // const redirectPath = location?.state?.path || "/";
  const redirectPath = "/";
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);

  const VerifyOtpPostApi = useEnCryptPostApi(
    `/user/signInOTPVerification`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    if (VerifyOtpPostApi?.data?.userExist) {
      const signalTrackingPostData = {
        Actor: authUser?.data?.userId,
        Action: signUpComplete,
        Item_Type: null,
        Item_Id: null,
        User_Type: "Known",
        Persona: "Buyer",
        Target: {
          page: "home page",
          link: "/",
        },
        Object: {
          section: null,
          context: `signup complete`,
          page: null,
          Persona: VerifyOtpPostApi?.data?.Persona,
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      setUser(VerifyOtpPostApi?.data);
      //   navigate(redirectPath, { replace: true });
      props?.handleClose();
    }
  }, [VerifyOtpPostApi?.data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const rawData = {
      emailId: tempAuthData?.data?.emailId,
      OTP: OTP?.current?.value,
    };

    try {
      VerifyOtpPostApi?.handleTrigger(rawData);
    } catch (error) {
      return;
    }
  };
  return (
    <>
      <div className="modern-card modern-card--elevated sign-in-modal">
        <div style={{ display: "flex", flexFlow: "row-reverse", padding: 5 }}>
          <a className="slide-in__close-btn closeButton" onClick={() => props?.handleClose()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <div className="modern-card__content modern-card__content--p-lg">
          <h1 className="text-center">{OTP_VERIFY?.LOG_IN}</h1>
          {VerifyOtpPostApi?.data ? (
            !VerifyOtpPostApi?.data?.status ? (
              <div class="ui icon message visible warning m-t-xs">
                <i class="feather icon icon-alert-triangle "></i>
                <div class="content">
                  <p>{VerifyOtpPostApi?.data?.message}</p>
                </div>
              </div>
            ) : null
          ) : null}
          <form className="dtrd-form--modern m-t-md dtrd-form" onSubmit={onSubmit}>
            <div className="field" id="field-user__password">
              <label for="user__password">{OTP_VERIFY?.OTP}</label>
              <div className="icon-wrapper icon-wrapper--left">
                <input
                  type="password"
                  placeholder="******"
                  icon_left="lock"
                  autocomplete="off"
                  id="user__password"
                  name="user[password]"
                  maxLength="6"
                  ref={OTP}
                />
                <i className="feather icon icon-lock icon--left m-r-none"></i>
              </div>
            </div>

            {VerifyOtpPostApi?.isLoading ? (
              <div className="button primary fluid">
                <LoadingSpinner />
              </div>
            ) : (
              <input
                type="submit"
                name="commit"
                value="Verify OTP"
                className="button primary fluid"
                data-disable-with="Login"
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default OtpVerificationModal;
