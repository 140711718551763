// import * as KEYS from "../../../config/config";
import React from "react";
import { useSelector } from "react-redux";
import ProviderCardList from "./components/providerCardList";
import { ReplaceBlankSpaceByHyphen } from "../../../utlis/hof/hof";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../config/configKeys";
function ProviderSection(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.VIEW_ALL_VENDORS_CLICKED,
    page: "search page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleAllVendorLogoLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: props?.vendorName,
      Item_Id: props?.categoryId,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.vendorName,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div>
      <div className="ui container">
        <div className="landing-page__section-header">
          <div className="section-header__content">
            <a
              onClick={() =>
                handleAllVendorLogoLinkClicked(`/search/vendors/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`)
              }
              href={`/search/vendors/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`}
            >
              <h2 className="landing-page__section-title">
                {KEYS?.CATEGORY_PAGE.PROVIDER_SECTION.TITLE_START} {props?.vendorName}{" "}
                {KEYS?.CATEGORY_PAGE.PROVIDER_SECTION.TITLE_END}
              </h2>
              <div className="landing-page__section-subtitle">
                {KEYS?.CATEGORY_PAGE.PROVIDER_SECTION.SUBTITLE_START_LBL} {props?.vendorName}{" "}
                {KEYS?.CATEGORY_PAGE.PROVIDER_SECTION.SUBTITLE_END_LBL}
              </div>
            </a>
          </div>
        </div>
        <div>
          <ProviderCardList page={props?.page} section={props?.section} categoryId={props?.categoryId} />
        </div>
        <div className="text-center">
          <a
            className="button primary outlined small transparent m-t-md"
            onClick={() =>
              handleAllVendorLogoLinkClicked(`/search/vendors/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`)
            }
            href={`/search/vendors/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`}
          >
            {KEYS?.CATEGORY_PAGE.SHOW_ALL_BTN_LBL}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProviderSection;
