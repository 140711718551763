import React from "react";
import "../../styles/direct-message.css";
import ChatHeader from "./chatHeader/chatHeader";
import ChatList from "./chatList/chatList";
import { useState } from "react";
import ChatRoom from "./chatRoom/chatRoom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import VendorSearchBar from "./vendorSearchBar";
import { isMobile } from "react-device-detect";
import { useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../config/configKeys";
function DirectMessage() {
  const [listView, setListView] = useState(false);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;

  const buyerView = !authData?.data?.buyerView && authData?.data?.Persona == "Business User" ? false : true;
  const showChatRoom =
    typeof selectedVendorSelector === "object" && Object.keys(selectedVendorSelector).length > 1 ? true : false;
  return (
    <div className="direct-message-main-wrapper-main-container">
      {showChatRoom && !isMobile ? (
        <div
          className={`direct-message-main-wrapper-selected-vendor  ${
            showChatRoom ? null : "direct-message-main-wrapper-minimized"
          }`}
        >
          <ChatRoom handleChangeView={() => setListView((prev) => !prev)} listView={listView} />
        </div>
      ) : null}
      {isMobile ? (
        <>
          {showChatRoom ? (
            <div
              className={`direct-message-main-wrapper-selected-vendor  ${
                showChatRoom ? null : "direct-message-main-wrapper-minimized"
              }`}
            >
              <ChatRoom handleChangeView={() => setListView((prev) => !prev)} listView={listView} />
            </div>
          ) : null}
          {showChatRoom ? null : (
            <div
              className={`direct-message-main-wrapper  ${listView ? null : "direct-message-main-wrapper-minimized"}`}
            >
              <ChatHeader handleChangeView={() => setListView((prev) => !prev)} listView={listView} />
              <VendorSearchBar />
              {listView ? <ChatList /> : null}
            </div>
          )}
        </>
      ) : (
        <div className={`direct-message-main-wrapper  ${listView ? null : "direct-message-main-wrapper-minimized"}`}>
          <ChatHeader handleChangeView={() => setListView((prev) => !prev)} listView={listView} />
          <VendorSearchBar />

          {listView ? <ChatList /> : null}
        </div>
      )}
    </div>
  );
}

export default DirectMessage;
