import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import BlurbOptions from "../../../../utlis/hoc/blurbCard/component/blurbOptions";

const BlurbSocial = (props) => {
  const PAGE_URL = window.location.href;
  const [showLink, setShowLink] = useState(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href + "?utm_source=octate_link");
    setShowLink(true);
    setTimeout(() => {
      setShowLink(false);
    }, 1000);
  };
  return (
    <div className="social-container">
      <TwitterShareButton url={`${PAGE_URL}?utm_source=twitter`} title={props?.data?.title_level_1}>
        {/* <FeatherIcon
          icon="twitter"
          // fill={"#181527"}
          size="15"
          className="blurb-detail-social"
          role="button"
        /> */}
        <img
          src={require("../../../../assets/images/Share icons/4.svg").default}
          height={30}
          width={30}
          className="m-x-base"
          alt={"twitter share"}
        />
      </TwitterShareButton>
      <LinkedinShareButton url={`${PAGE_URL}?utm_source=linkedin`} title={props?.data?.title_level_1}>
        {/* <FeatherIcon fill={true} icon="linkedin" size="15" role="button" className="blurb-detail-social" /> */}
        <img
          src={require("../../../../assets/images/Share icons/1.svg").default}
          height={30}
          width={30}
          className="m-x-base"
          alt={"linkedin share"}
        />
      </LinkedinShareButton>
      <FacebookShareButton url={`${PAGE_URL}?utm_source=facebook`} title={props?.data?.title_level_1}>
        {/* <FeatherIcon role="button" icon="facebook" fill={true} size="15" className="blurb-detail-social" /> */}
        <img
          src={require("../../../../assets/images/Share icons/2.svg").default}
          height={30}
          width={30}
          className="m-x-base"
          alt={"facebook share"}
        />
      </FacebookShareButton>
      {/* <FeatherIcon
        icon="more-horizontal"
        role="button"
        size="18"
        className="blurb-detail-social"
      /> */}
      <div className="p-r">
        <img
          src={require("../../../../assets/images/Share icons/3.svg").default}
          height={30}
          width={30}
          className="m-x-base"
          role="button"
          onClick={() => handleCopyToClipboard()}
          alt={"share"}
        />
        {showLink ? (
          <h5
            style={{
              position: "absolute",
              top: "5px",
              left: "0px",
              padding: "5px 5px",
              color: "#1371c3",
              width: "max-content",
              background: "#CED0E0",
              borderRadius: "5px",
            }}
          >
            Link copied
          </h5>
        ) : null}
      </div>
      <BlurbOptions data={props?.data} />
    </div>
  );
};

export default BlurbSocial;
