import { React, useState, useEffect } from "react";
// import * as KEYS from "../../../../../../../config/config";
import CategoriesList from "./categoriesList";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllProductCategoryQuery } from "../../../../../../../adapters/xhr/searchService";
import {
  DecryptApi,
  EncryptApiGetData,
  ToTitleCase,
} from "../../../../../../../utlis/hof/hof";
import { allCategoryData } from "../../../../../../../contexts/reducer/homeReducer";

function SearchCategories(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const dispatch = useDispatch();

  const [enCryptProductCategory, setEnCryptProductCategory] = useState("");
  const [DeCryptProductCategoryList, setDeCryptProductCategoryList] =
    useState();

  const [searchData, setSearchData] = useState();

  const {
    data: allProductCategoryData,
    isError: allProductCategoryIsError,
    isLoading: allProductCategoryIsLoading,
  } = useGetAllProductCategoryQuery(
    { enCryptProductCategory },
    { skip: enCryptProductCategory?.data ? false : true }
  );

  useEffect(() => {
    const enCryptData = {
      // https://thebox-dev.portqii.com/search_backend/getAllProductCategory
      path: `/getAllProductCategory?limit=1000`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };
    const enCryptProductCategory = EncryptApiGetData(enCryptData);
    setEnCryptProductCategory(enCryptProductCategory);
  }, []);

  useEffect(() => {
    const DeCryptData = DecryptApi(allProductCategoryData);
    if (DeCryptData?.data) {
      setDeCryptProductCategoryList(DeCryptData);
      dispatch(allCategoryData(DeCryptData));
      setSearchData(DeCryptData.data);
      props?.handleListCount(DeCryptData.data?.length);
    }
  }, [allProductCategoryData]);

  const handleInputText = (e) => {
    if (e.target.value) {
      const searchData = DeCryptProductCategoryList?.data?.filter((val) => {
        if (
          val?.Product_Category_Name?.toLowerCase().includes(
            e.target.value?.toLowerCase()
          )
        ) {
          return val;
        }
      });

      setSearchData(searchData);
    } else {
      setSearchData(DeCryptProductCategoryList.data);
    }
  };

  return (
    <div>
      <form className="dtrd-form" onSubmit={(e) => e.preventDefault()}>
        <div className="field" id="field-category_keywords">
          <label className="category_keywords">What kind of product are you looking for?</label>
          <input
            placeholder="Search for a product category"
            id="category_keywords"
            type="text"
            name="category_keywords"
            // value={searchedTextValue}
            onChange={handleInputText}
          />
        </div>
      </form>
      <CategoriesList data={searchData} />
    </div>
  );
}

export default SearchCategories;
