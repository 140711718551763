import React, { memo, useCallback, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";

const AddProductCategoryWithTopic = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [searchProductCategoryText, setSearchProductCategoryText] = useState("a");
  const [searchText, setSearchText] = useState("");
  const CATEGORY_DATA = props?.data ?? {};

  const productCategoryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchProductCategoryText}&type=productCategory`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;

  const productTopicsDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchText}&type=subProductCategory&verticalId=1&Product_Category_ID=[${CATEGORY_DATA?.id}]`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    searchText?.length < 3 ? true : false
  );

  const handleSearchWithApi = useCallback((key) => {
    setSearchProductCategoryText(key);
  });
  const handleSearchTopicWithApi = useCallback((key) => {
    setSearchText(key);
  });

  const handleDetailsProductCategory = (val) => {
    var DATA = props?.productBasicDetails?.Selected_Product_Category
      ? [...props?.productBasicDetails?.Selected_Product_Category]
      : [val];
    if (props?.productBasicDetails?.Selected_Product_Category) {
      DATA[props?.dataIndex] = val;
    }
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: DATA,
    });
  };

  const handleSelectedTopic = (val) => {
    var DATA = props?.productBasicDetails?.Selected_Product_Category
      ? [...props?.productBasicDetails?.Selected_Product_Category]
      : [val];
    if (props?.productBasicDetails?.Selected_Product_Category) {
      DATA[props?.dataIndex] = { ...CATEGORY_DATA, Tag: val };
    }
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: DATA,
    });
  };

  const handleUserDefined = (val) => {
    var DATA = props?.productBasicDetails?.Selected_Product_Category
      ? [...props?.productBasicDetails?.Selected_Product_Category]
      : [val];
    if (props?.productBasicDetails?.Selected_Product_Category) {
      DATA[props?.dataIndex] = { ...CATEGORY_DATA, Tag: { Name: val } };
    }
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: DATA,
    });
  };

  const handleRemoveItem = (val) => {
    var DATA = [...props?.productBasicDetails?.Selected_Product_Category];
    DATA.splice(props?.dataIndex, 1);
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: DATA
    });
  };
  return (
    <>
      <div className="grid-two-columns p-r" style={{ marginRight: "20px" }}>
        <div class="field-company-profile p-r m-y-sm" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) => handleDetailsProductCategory(val)}
            data={productCategoryDropdownData}
            placeholder={"Select product category"}
            // flag={true}
            selectedVal={CATEGORY_DATA}
            name={"name"}
            // rightSideContent={true}
            // rightName={"Journey_Stage"}
            returnObject={true}
            label={"Product Category"}
            handleSearchWithApi={(val) => handleSearchWithApi(val)}
            searchWithApi={true}
            type={"ProductCategory"}
          />
          {props?.error?.index && props?.error?.index === props?.dataIndex ? (
            <span class="input-field-dropdown-error">{"Product Category is required"}</span>
          ) : null}
        </div>
        <div class="field-company-profile p-r m-y-sm" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) => handleSelectedTopic(val)}
            data={productTopicsDropdownData?.data?.data}
            placeholder={"Sub Category"}
            selectedVal={CATEGORY_DATA?.Tag}
            name={"Name"}
            label={"Sub Category"}
            returnObject={true}
            rightSideContent={true}
            rightName={"Product_Category_Name"}
            handleChangeText={(val) => handleUserDefined(val)}
            handleSearchWithApi={(val) => handleSearchTopicWithApi(val)}
            searchWithApi={true}
          />
        </div>
        {props?.dataIndex > 0 ? (
          <FeatherIcon
            icon={"x"}
            size="17"
            color={"#6E7079"}
            className={"sub-category-close-icon"}
            onClick={() => handleRemoveItem(CATEGORY_DATA)}
          />
        ) : null}
      </div>
    </>
  );
};

export default memo(AddProductCategoryWithTopic);
