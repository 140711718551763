import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import CreateNewBoard from "../Boards/createNewBoard";

function EditBoardCard(props) {
  const [showModal, setShowModal] = useState(false);
  const { boardCardDetails } = props;
  return (
    <>
      <FeatherIcon
        icon={"edit"}
        size={20}
        className={"board-item-edit-icon m-b-sm"}
        role="button"
        onClick={() => setShowModal(!showModal)}
        color={"#080808"}
      />
      {showModal ? (
        <CreateNewBoard
          data={boardCardDetails}
          edit={Object.keys(boardCardDetails)?.length ? true : false}
          page={props?.page}
          handleClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  );
}

export default EditBoardCard;
