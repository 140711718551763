import { ToTitleCase } from "../../../../utlis/hof/hof";
import React, { useState } from "react";
import PermissionResourceSwitch from "./pemissionResourceSwitch";
import FeatherIcon from "feather-icons-react";
import PermissionControls from "./permissionControls";
const PermissionResource = (props) => {
  const SECTION_DATA = props?.data;
  return (
    <div className="preference-selection-sub-container">
      {SECTION_DATA?.map((list, index) => (
        <PermissionControls
          permissionSetId={props?.permissionSetId}
          resourceId={list?.Resource_ID}
          data={list}
          resourceIndex={index}
        />
      ))}
    </div>
  );
};

export default PermissionResource;
