import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import {
  FilterSelectedAllData,
  GetIdFromArray,
} from "../../../../../utlis/hof/hof";
import * as CONFIG_KEYS from "../../../../../config/config";
const RecommendedVendorList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  // /proposalRecommendedVendors?productCategory=[2,3,19]
  const proposalDetails = props?.proposalDetails;
  const businessNeedsId = GetIdFromArray(
    proposalDetails?.Business_Needs,
    "Item_ID",
    "Item_Type"
  );
  const selectedData = props?.proposalDetails[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
    ?.length
    ? props?.proposalDetails[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
    : [];
  const newArray = JSON.stringify([...businessNeedsId]);
  const businessSizeApi = useEnCryptGetApi(
    `/proposalRecommendedVendors?productCategory=${newArray}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  // const businessSizeApi = [];
  const handleVendorDetails = (val) => {
    let selectedVendorArray = selectedData;
    selectedVendorArray?.push({
      ...val,
      [ADD_PRODUCT_CONFIG_KEYS?.VENDOR_SELECTION_WAY]: "Recommendation",
    });
    props?.handleVendorDetails(selectedVendorArray);
  };
  const filterSelectedVendors = FilterSelectedAllData(
    selectedData,
    businessSizeApi,
    "Vendor_ID"
  );
  return (
    <div className="recommended-vendor-vendor-list-main-wrapper">
      {filterSelectedVendors?.length ? (
        filterSelectedVendors?.map((list) => {
          return (
            <div className="recommended-vendor-vendor-list-wrapper">
              <p
                onClick={() => handleVendorDetails(list)}
                className="recommended-vendor-vendor-list-vendor-text"
              >
                {list?.Vendor_Name}
              </p>
            </div>
          );
        })
      ) : (
        <div className="recommended-vendor-vendor-list-no-recommendation-wrapper">
          <h3 className="recommended-vendor-vendor-list-no-recommendation-text">
            No Recommendations
          </h3>
        </div>
      )}
    </div>
  );
};

export default RecommendedVendorList;
