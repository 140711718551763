import React, { useEffect, useState } from "react";
import InputFieldFilterProposal from "../../../../proposals/component/inputFieldFilterProposal";
import SelectedVendorList from "./selectedVendorList";
import * as CONFIG_KEYS from "../../../../../config/config";
const SelectedVendors = (props) => {
  const proposalDetails = props?.proposalDetails;
  const [selectedVendorWithFilter, setSelectedVendorWithFilter] = useState([]);
  const [searchedVendorText, setSearchedVendorText] = useState("");
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const companyErrors = props?.companyErrors;
  const handleDeleteClicked = (val) => {
    props?.handleDetails({
      ...proposalDetails,
      [ADD_PRODUCT_CONFIG_KEYS?.VENDORS]: val,
    });
  };
  useEffect(() => {
    setSelectedVendorWithFilter(
      proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
    );
  }, [proposalDetails]);
  //   props?.handleDetails({
  //   ...proposalDetails,
  //   filterText: val,
  // })
  const handleTextChanges = (val) => {
    if (val && proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]?.length) {
      const filteredSelectedVendors = proposalDetails?.[
        ADD_PRODUCT_CONFIG_KEYS?.VENDORS
      ]?.filter((fil) => {
        return fil?.[ADD_PRODUCT_CONFIG_KEYS?.VENDOR_NAME]
          .toLocaleLowerCase()
          .includes(val?.toLocaleLowerCase());
      });
      setSelectedVendorWithFilter(filteredSelectedVendors);
    } else {
      setSelectedVendorWithFilter(
        proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]
      );
    }
    setSearchedVendorText(val);
  };
  return (
    <div className="proposal-submit-selected-vendor-main-wrapper">
      <div className="proposal-submit-selected-vendor-content-wrapper">
        <div>{`Selected ${
          proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]?.length > 1
            ? "Vendors"
            : "Vendor"
        } ${
          proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]?.length
            ? `(${proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]?.length})`
            : ""
        }`}</div>
        <div className="dis-f-r">
          <InputFieldFilterProposal
            label={"Search"}
            icon={"search"}
            value={props?.proposalDetails?.filterText}
            changeText={(val) => handleTextChanges(val)}
          />
        </div>
      </div>
      <SelectedVendorList
        companyErrors={companyErrors}
        proposalDetails={props?.proposalDetails}
        selectedVendorWithFilter={selectedVendorWithFilter}
        handleDeleteClicked={handleDeleteClicked}
        searchedText={searchedVendorText}
      />
    </div>
  );
};

export default SelectedVendors;
