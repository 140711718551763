import React, { useEffect, useState } from 'react';
import TrackingTable from './components/trackingTable';
import { useSelector } from 'react-redux';
import { useEnCryptGetApi } from '../../../../utlis/customHooks/customHooks';
import { useGetRequestQuery } from '../../../../adapters/xhr/generalApi';
import InfiniteScrollComp from '../../../../utlis/hoc/infiniteScroll/infiniteScroll';
import { useMemo } from 'react';
import { Navigate } from 'react-router';
import OnboardVendor from './components/onboardVendor';
import { useGetSuperAdminDataQuery } from '../../../../adapters/xhr/crudServiceApi';
import AddOctateFaqPortal from './components/addOctateFaqPortal';
import ProductExpertList from './components/productExpertList';

const SuperAdmin = () => {
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [faqModalVisible, setFaqModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [selectedTab, setSelectedTab] = useState('proposal');
  const [tableHeader, setTableHeader] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const USER_ID = authData?.data?.userId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const END_POINT_TYPE = selectedTab == 'comments' ? 'comment' : selectedTab == 'feedback' ? 'feedback' : null;
  const END_POINT =
    selectedTab == 'proposal'
      ? 'getCreatedProposalsForDashboard'
      : selectedTab == 'comments' || selectedTab == 'feedback'
      ? 'getCommentsAndFeedbackForDashboard'
      : selectedTab == 'chat'
      ? 'getDirectMessages'
      : selectedTab == 'request'
      ? 'getRequestResourceForDashboard'
      : selectedTab == 'license'
      ? 'getVendorLicenseInfo'
      : 'getCreatedProposalsForDashboard';

  const FINAL_END_POINT =
    selectedTab === 'vendors'
      ? `/getNewlyAddedVendors`
      : selectedTab === 'domain'
      ? `/getDomainMismatchedUsers`
      : `/${END_POINT}?page=${pageNo}&userId=${USER_ID}${END_POINT_TYPE ? `&type=${END_POINT_TYPE}` : ''}`;

  const SERVICE =
    selectedTab === 'vendors' || selectedTab === `domain`
      ? KEYS?.END_POINTS?.CORE?.END_POINT_URL
      : KEYS?.END_POINTS?.DASHBOARD?.END_POINT_URL;
  const GetApiData = useEnCryptGetApi(`${FINAL_END_POINT}`, SERVICE, useGetSuperAdminDataQuery, selectedTab === 'product' ? true : false);

  const ProductExpertListData = useEnCryptGetApi(
    `/getAllProductExpertUsers`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );

  useEffect(() => {
    if (GetApiData?.data?.data?.length > 0) {
      if (lazyLoadData?.length >= 10 && selectedTab !== 'vendors' && selectedTab !== 'domain') {
        setLazyLoadData((prev) => prev.concat(GetApiData?.data?.data));
      } else {
        setLazyLoadData(GetApiData?.data?.data);
      }
      setTableHeader((prev) => {
        if (GetApiData?.data?.data?.length) return Object.keys(GetApiData?.data?.data[0]);
      });
    } else {
      setLazyLoadData([]);
    }
  }, [GetApiData?.data?.data]);

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  const DASHBOARD_TABS = [
    {
      label: 'Product Expert',
      value: 'product',
      display: true,
    },
    {
      label: 'Proposals',
      value: 'proposal',
      display: true,
    },
    {
      label: 'Feedback',
      value: 'feedback',
      display: true,
    },
    {
      label: 'Chat',
      value: 'chat',
    },
    {
      label: 'Comments',
      value: 'comments',
    },
    {
      label: 'Request Resource',
      value: 'request',
    },
    {
      label: 'License',
      value: 'license',
    },
    {
      label: 'New Vendor Request',
      value: 'vendors',
    },
    {
      label: 'Domain mismatch',
      value: 'domain',
    },
  ];

  const handleChangeTab = (val) => {
    setSelectedTab(val?.value);
    setPageNo(1);
    setLazyLoadData([]);
    setTableHeader([]);
  };

  const handleModalVisible = () => {
    setModalVisible((prev) => !prev);
  };
  function handleFaqModalVisible() {
    setFaqModalVisible(true);
  }
  return superAdminStatus ? (
    <div className='business-profile-container' key={crypto.randomUUID}>
      <button className='business-profile-header-button m-t-base' onClick={() => handleModalVisible()}>
        Add Vendor
      </button>
      <button className='business-profile-header-button m-t-base cursor--pointer' onClick={() => handleFaqModalVisible()}>
        Add FAQ
      </button>
      <div className='flex tab-navigator-container'>
        {DASHBOARD_TABS.map((item) => {
          return (
            <div className='flex m-t-base' key={item?.value}>
              <h2
                className={`tab-index-text ${selectedTab == item?.value ? 'tab-index-selected' : null}`}
                onClick={() => handleChangeTab(item)}
              >
                {item?.label}
              </h2>
            </div>
          );
        })}
      </div>
      {selectedTab === 'product' ? (
        <div>
          {ProductExpertListData?.data?.data?.data?.map((item) => (
            <ProductExpertList data={item} />
          ))}
        </div>
      ) : (
        <div key={tableHeader}>
          <InfiniteScrollComp Data={lazyLoadData} hasMoreData={GetApiData?.data?.data?.length >= 10} fetchData={fetchData}>
            <TrackingTable data={lazyLoadData} tableHeader={tableHeader} selectedTab={selectedTab} />
          </InfiniteScrollComp>
        </div>
      )}
      {modalVisible ? <OnboardVendor handleCloseModal={() => setModalVisible((prev) => !prev)} /> : null}
      {faqModalVisible ? <AddOctateFaqPortal handleCloseModal={() => setFaqModalVisible(false)} /> : null}
    </div>
  ) : (
    <Navigate to='/' />
  );
};

export default SuperAdmin;
