import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom/client";

const UseCaseReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const maxTextLength = text?.length;
  const minTextLength = 150;
  const showShowMore = maxTextLength - minTextLength > 10;
  return (
    <h1 style={{ fontSize: showShowMore ? "32px" : "42px" }} className="new-use-case-detail-title m-t-sm">
      {isReadMore && showShowMore ? text?.slice(0, 150) : text}
      {showShowMore && (
        <span style={{ fontSize: "15px" }} onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "  read more" : " read less"}
        </span>
      )}
    </h1>
  );
};

export default UseCaseReadMore;
