import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../../../../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../../../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import _ from "lodash";
import { validateRequestVendor } from "../../../../../utlis/hof/hof";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import LicensePlan from "./license/licensePlan";
import AddUserTable from "./license/addUserTable";

const ChooseLicense = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [enteredEmail, setEnteredEmail] = useState([]);
  const [licenseCount, setLicenseCount] = useState(1);
  const [displayAddUser, setDisplayAddUser] = useState(false);

  const handleCloseModal = () => {
    props?.handleClose();
  };

  const handleNextStep = (val) => {
    setLicenseCount(val);
    setDisplayAddUser((prev) => !prev);
  };

  const handleGoBack = (val) => {
    setEnteredEmail(val);
    setDisplayAddUser((prev) => !prev);
  };

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper" style={{ minWidth: "45%" }}>
          <div className="request-category-container" style={{ height: "100%" }}>
            {!displayAddUser ? (
              <LicensePlan
                handleListCount={(val) => handleNextStep(val)}
                handleCloseModal={handleCloseModal}
                licenseCount={licenseCount}
              />
            ) : (
              <AddUserTable
                licenseCount={licenseCount}
                handleCloseModal={handleCloseModal}
                handleViewPreviousStep={(val) => handleGoBack(val)}
                enteredEmail={enteredEmail}
              />
            )}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default ChooseLicense;
