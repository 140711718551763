import React from "react";
import VendorListTable from "./vendorListTable";
import * as CONFIG_KEYS from "../../../../config/config";
import AddVendorMultiSelect from "../../../addProposal/component/proposalSubmit/component/addVendorMutiSelect";
import { GetTotalQuestions } from "../../../../utlis/hof/hof";
const VendorListPage = (props) => {
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const proposalTableHeader = [
    {
      name: "Vendor Name",
      id: 1,
    },
    {
      name: "Total Questions",
      id: 1,
    },
    {
      name: "Closed Questions",
      id: 1,
    },
    {
      name: "Status",
      id: 1,
    },
  ];

  const proposalTableData = proposalDetails?.[PROPOSAL_KEYS?.VENDORS];
  const totalQuestion = GetTotalQuestions(
    proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION] ? proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION] : []
  );
  // [
  //   {
  //     Vendor_Name: "Cashbook",
  //     Status: "In Progress",
  //     Total_Questions: 10,
  //     Answered_Questions: 5,
  //     Vendor_ID: 10,
  //   },
  // ];
  return (
    <div>
      {USER_CAN_EDIT ? (
        <div className="vendor-proposal-dis-flex-space-btw" style={{ textAlign: "start" }}>
          <h2 className="question-proposal-vendor-header-label">Vendors</h2>
          <div style={{ minWidth: "300px", width: "50%" }}>
            <AddVendorMultiSelect
              USER_CAN_EDIT={USER_CAN_EDIT}
              proposalDetails={proposalDetails}
              handleDetails={props?.handleDetails}
            />
          </div>
        </div>
      ) : null}

      <div className="proposal-table-container-main-wrapper" style={{ textAlign: "start" }}>
        <VendorListTable
          USER_CAN_EDIT={USER_CAN_EDIT}
          totalQuestion={totalQuestion}
          proposalDetails={proposalDetails}
          proposalTableData={proposalTableData}
          proposalTableHeader={proposalTableHeader}
          handleDetails={props?.handleDetails}
          handleActiveRowData={props?.handleActiveRowData}
        />
      </div>
    </div>
  );
};

export default VendorListPage;
