import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import TabNavigator from "../../../../utlis/hoc/tabNavigator/tabNavigator";
import BusinessProfileDetails from "./components/businessProfileDetails";
const BusinessProfileHeader = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  let BUSINESS_PROFILE_TAB = props?.tabData;
  const navigatePath = props?.navigatePath;
  const permissionsDetails = props?.permissionsDetails;
  const CanViewPermissions = permissionsDetails?.[PERMISSIONS_KEYS.PERMISSIONS]?.Read
    ? { display: PERMISSIONS_KEYS.PERMISSIONS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.PERMISSIONS_TAB, Read: false };
  const CanViewTeams = permissionsDetails?.[PERMISSIONS_KEYS.TEAMS]?.Read
    ? { display: PERMISSIONS_KEYS.TEAMS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.TEAMS_TAB, Read: false };
  const CanViewProduct = permissionsDetails?.[PERMISSIONS_KEYS.PRODUCT]?.Read
    ? { display: PERMISSIONS_KEYS.PRODUCT_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.PRODUCT_TAB, Read: false };
  const CanViewVendors = permissionsDetails?.[PERMISSIONS_KEYS.VENDOR]?.Read
    ? { display: PERMISSIONS_KEYS.VENDOR_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.VENDOR_TAB, Read: false };
  const CanViewBlurbs = permissionsDetails?.[PERMISSIONS_KEYS.BLURBS]?.Read
    ? { display: PERMISSIONS_KEYS.BLURBS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.BLURBS_TAB, Read: false };
  const CanViewFaq = permissionsDetails?.[PERMISSIONS_KEYS.FAQ]?.Read
    ? { display: PERMISSIONS_KEYS.FAQ_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.FAQ_TAB, Read: false };
  const CanViewUseCases = permissionsDetails?.[PERMISSIONS_KEYS.USE_CASE]?.Read
    ? { display: PERMISSIONS_KEYS.USE_CASE_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.USE_CASE_TAB, Read: false };
  const CanViewBoards = permissionsDetails?.[PERMISSIONS_KEYS.BOARDS]?.Read
    ? { display: PERMISSIONS_KEYS.BOARDS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.BOARDS_TAB, Read: false };
  const CanViewCustomers = permissionsDetails?.[PERMISSIONS_KEYS.CUSTOMERS]?.Read
    ? { display: PERMISSIONS_KEYS.CUSTOMERS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.CUSTOMERS_TAB, Read: false };
  const CanViewProposals = permissionsDetails?.[PERMISSIONS_KEYS.PROPOSAL]?.Read
    ? { display: PERMISSIONS_KEYS.PROPOSAL_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.PROPOSAL_TAB, Read: false };
  const CanViewDashboard = permissionsDetails?.[PERMISSIONS_KEYS.DASHBOARD]?.Read
    ? { display: "Analytics", Read: true }
    : { display: "Analytics", Read: false };
  const CanViewVendorChat = permissionsDetails?.[PERMISSIONS_KEYS.VENDOR_CHAT]?.Read
    ? { display: PERMISSIONS_KEYS.VENDOR_CHAT_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.VENDOR_CHAT_TAB, Read: false };
  const canViewSuperAdmin = superAdminStatus
    ? { display: "Super Admin", Read: true }
    : { display: "Super Admin", Read: false };
  const MOBILE_DEVICE = isMobile;

  return (
    <div className="business-profile-container">
      <BusinessProfileDetails
        permissionsDetails={permissionsDetails?.[[PERMISSIONS_KEYS.BUSINESS_PROFILE]]}
        tabIndex={props?.tabIndex}
        VENDOR_ID={props?.VENDOR_ID}
        type={props?.type}
      />
      <div className="flex tab-navigator-container">
        {BUSINESS_PROFILE_TAB?.map((item, index) => {
          if (item?.label == CanViewBlurbs?.display && CanViewBlurbs?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewProduct?.display && CanViewProduct?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewFaq?.display && CanViewFaq?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewPermissions?.display && CanViewPermissions?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewTeams?.display && CanViewTeams?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewBlurbs?.display && CanViewBlurbs?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewUseCases?.display && CanViewUseCases?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewBoards?.display && CanViewBoards?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewCustomers?.display && CanViewCustomers?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewProposals?.display && CanViewProposals?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewVendorChat?.display && CanViewVendorChat?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == CanViewDashboard?.display && CanViewDashboard?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (item?.label == canViewSuperAdmin?.display && canViewSuperAdmin?.Read) {
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
          } else if (props?.type === "buyer" && (item?.display || MOBILE_DEVICE))
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={navigatePath}
                page={props?.page}
              />
            );
        })}
      </div>
    </div>
  );
};

export default BusinessProfileHeader;
