import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CreateNewBoard from '../../../../utlis/hoc/Boards/createNewBoard';

const BoardTabHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const permissionsDetails = props?.permissionsDetails;
  const tabIndex = props?.tabIndex;
  const showOnlyVendor = props?.showOnlyVendor;
  const AddingProductOrBlurbLabel =
    tabIndex == 'blurbs'
      ? 'Add Blurb'
      : tabIndex == 'products'
      ? 'Add Product'
      : tabIndex == 'use-cases'
      ? 'Add Case Studies'
      : tabIndex == 'faq'
      ? 'Add FAQ'
      : null;

  const tabHeaderLabel =
    tabIndex == 'blurbs'
      ? 'Blurbs'
      : tabIndex == 'products'
      ? 'Products'
      : tabIndex == 'use-cases'
      ? 'Case Studies'
      : tabIndex == 'faq'
      ? ''
      : tabIndex == 'alternate-vendor'
      ? 'Alternate Vendors'
      : null;
  const handleNewPostClicked = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    // document.body.style.overflow = "auto";
  };
  function handleShowVendorBoardClicked() {
    props?.handleShowVendorBoardClicked();
  }
  return (
    <>
      {showModal && permissionsDetails && permissionsDetails?.Create ? (
        <CreateNewBoard const page={props?.page} handleClose={() => setShowModal(false)} />
      ) : null}
      <div className='flex space-between m-y-xs' style={tabIndex != 'faq' ? { marginLeft: '10px' } : null}>
        <h3 style={{ margin: 'auto 0px' }}>Boards</h3>
        {permissionsDetails?.Create ? (
          <button className={`business-profile-create-new-blue-button`} onClick={() => handleNewPostClicked()}>
            Add boards
          </button>
        ) : null}
      </div>
      {!permissionsDetails?.Create ? (
        <div className='board-header-check-box-only-vendor m-y-xs' style={tabIndex != 'faq' ? { marginLeft: '10px' } : null}>
          <input
            type='checkbox'
            name='standard_attribute'
            onChange={handleShowVendorBoardClicked}
            checked={showOnlyVendor}
            className='select-vendor-board-checkbox'
            role={'button'}
          />
          <h3 style={{ fontSize: '14px', margin: 'auto 0px' }}>Show vendor created boards</h3>
        </div>
      ) : null}
    </>
  );
};

export default BoardTabHeader;
