import React from "react";
import { useSelector } from "react-redux";
import CtaButton from "../../blurbCardSmall/component/ctaButton";

function BlurbMedia(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const Data = props?.data;
  const blurbImage = Data?.image_src;
  return (
    <>
      <div
        className="data-product-card__convince"
        style={{ justifyContent: "space-between", display: "flex" }}
      >
        <div>
          <img
            class="provider-card__logo ls-is-cached lazyloaded"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "150px",
              padding: "5px 5px 15px 5px",
            }}
            alt="Blurb image"
            data-src={blurbImage ? blurbImage : KEYS?.CORRUPT_IMAGE_ICON}
            src={blurbImage ? blurbImage : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        </div>
        {/* <CtaButton allProducts={Data} /> */}
        {/* <div>
          <a className="small fluid outlined button">
            {" "}
            {KEYS?.ACTION_BUTTONS.REQUEST}
          </a>
        </div> */}
      </div>
    </>
  );
}

export default BlurbMedia;
