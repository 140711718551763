import React from "react";
import { createSlice } from "@reduxjs/toolkit";
// Created By : SANKET
//  this component is used to handle the the data of the detail blurb selected
export const authReducer = createSlice({
  name: "auth",
  initialState: {
    details: {
      loading: true,
      data: {},
    },
    tempDetails: {
      data: {},
    },
    uxConfig: {},
    octateMatrix: [],
    superAdminStatus: false,
    selectedRole: {},
  },
  reducers: {
    authDetails: (state, action) => {
      state.details = action.payload;
    },
    tempAuthDetails: (state, action) => {
      state.tempDetails = action.payload;
    },
    uxConfigFn: (state, action) => {
      state.uxConfig = action.payload;
    },
    octateMatrixFn: (state, action) => {
      state.octateMatrix = action.payload;
    },
    userSuperAdminFn: (state, action) => {
      state.superAdminStatus = action.payload;
    },
    roleSelected: (state, action) => {
      state.selectedRole = action.payload;
    },
  },
});

export const { authDetails, tempAuthDetails, uxConfigFn, octateMatrixFn, userSuperAdminFn ,roleSelected } = authReducer.actions;
export default authReducer.reducer;
