import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import HeaderSEO from "../../components/SEO/header";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import LoadingSpinner from "../../utlis/spinner/loadingSpinner";
import PageLoading from "../../utlis/spinner/pageLoading";
import { useEffect } from "react";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";

const ValidateUser = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const location = useLocation();

  const GetApiData = useEnCryptGetApi(
    `/checkInviteStatus?User_ID=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  useEffect(() => {
    if (GetApiData?.data?.data?.Status) {
      setUser({ ...authUser?.data, userExist: true });
    }
  }, [GetApiData?.data]);
  return GetApiData?.data?.data ? (
    <>
      {GetApiData?.data?.data?.Status ? (
        <Outlet />
      ) : GetApiData?.data?.data?.Status === false && authData?.data?.userExist ? (
        <Navigate to="/" replace />
      ) : (
        <Navigate to="/sign-in" replace state={{ path: location.pathname }} />
      )}
    </>
  ) : (
    <PageLoading />
  );
};

export default ValidateUser;
