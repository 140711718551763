import React from "react";
import ReactDom from "react-dom";
import OctateBusiness from "../../../../octateBusiness/octateBusiness";

const OnboardVendor = (props) => {
  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper" style={{ minWidth: "45%", height: "90%" }}>
          <div className="request-category-container" style={{ height: "100%" }}>
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => props?.handleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <OctateBusiness handleCloseModal={() => props?.handleCloseModal()} />
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default OnboardVendor;
