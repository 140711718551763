import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { useDeleteFaqMutation, useUpdateFaqMutation } from "../../../../../adapters/xhr/crudServiceApi";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import ConfirmDeleteModal from "../../../../../utlis/hoc/engagementBar/components/confirmDeleteModal";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import ShareFaq from "./shareFaq";
const VendorFaq = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [toggleVendor, setToggleVendor] = useState(false);
  const [toggleShare, setToggleShare] = useState(false);
  const DATA = props?.data;
  const permissionsDetails = props?.permissionsDetails;
  const getOctateVendorID = props?.getOctateVendorID;
  const hideFaq = getOctateVendorID ? `/help/hideFaq` : `/deleteFrequentlyAskedQuestions?FAQ_ID=${DATA?.FAQ_ID}`;
  const hideFaqMutation = getOctateVendorID ? useUpdateFaqMutation : useDeleteFaqMutation;
  const DeleteFaq = useEnCryptPostApi(hideFaq, KEYS?.END_POINTS?.CORE?.END_POINT_URL, hideFaqMutation);
  const handleDeleteFAQ = (val) => {
    setDeleteModalVisible((prev) => !prev);
  };

  const handleDeleteItem = (val) => {
    if (getOctateVendorID) {
      DeleteFaq?.handleTrigger({
        Octate_FAQ_ID: DATA?.Octate_FAQ_ID,
      });
    } else {
      DeleteFaq?.handleTrigger();
    }
    handleDeleteFAQ();
  };
  function handleToggleAnswer() {
    setToggleVendor(!toggleVendor);
  }
  function handleShareFaq() {
    setToggleShare(!toggleShare);
  }
  const handleBlurShare = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleShare(false);
    }
  };
  return (
    <>
      {deleteModalVisible ? (
        <ConfirmDeleteModal closeModal={() => handleDeleteFAQ()} handleSubmit={() => handleDeleteItem()} type={"faq"} />
      ) : null}
      <div style={{ marginRight: "15px" }} class="dtrd-faq__qa-wrapper" key={DATA?.FAQ_ID}>
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}
        >
          <p class="dtrd-faq__question">
            {getOctateVendorID ? (
              DATA?.Question
            ) : (
              <a
                href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/faq/${ReplaceBlankSpaceByHyphen(
                  BreakText(DATA?.Question),
                  DATA?.FAQ_ID
                )}`}
              >
                {DATA?.Question}
              </a>
            )}

            <span style={{ position: "absolute" }}>
              {permissionsDetails?.Update ? (
                <FeatherIcon
                  icon="edit-3"
                  size="20"
                  role="button"
                  color={"#ABAFB1"}
                  onClick={() => props?.handleEditFAQ(DATA)}
                  style={{ marginLeft: "10px" }}
                />
              ) : null}
              {permissionsDetails?.Delete ? (
                <FeatherIcon
                  icon="trash"
                  size="18"
                  role="button"
                  color={"#fb574b"}
                  onClick={() => handleDeleteFAQ(DATA)}
                  style={{ marginLeft: "10px" }}
                />
              ) : null}

              <span tabIndex={0} onBlur={handleBlurShare} className="p-r">
                <FeatherIcon
                  icon="share"
                  size="18"
                  role="button"
                  color={"#1371c3"}
                  onClick={() => handleShareFaq(DATA)}
                  style={{ marginLeft: "10px" }}
                />
                {toggleShare ? (
                  <div className="share-container modern-card modern-card--elevated">
                    <ShareFaq
                      handleToggleMenu={handleShareFaq}
                      shareLink={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/faq/${ReplaceBlankSpaceByHyphen(
                        BreakText(DATA?.Question),
                        DATA?.FAQ_ID
                      )}`}
                    />
                  </div>
                ) : null}
              </span>
            </span>
          </p>
          <div>
            <FeatherIcon
              icon={toggleVendor ? "chevron-down" : "chevron-right"}
              size="18"
              role="button"
              color={"#ABAFB1"}
              onClick={() => handleToggleAnswer(DATA)}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>

        {toggleVendor && getOctateVendorID ? (
          <div class="dtrd-faq__question-octae-faq" style={{ textDecoration: "none" }}>
            {parse(DATA?.Answer)}
          </div>
        ) : toggleVendor ? (
          <p class="dtrd-faq__answer">
            {DATA?.Answer ? (
              <a
                href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/faq/${ReplaceBlankSpaceByHyphen(
                  BreakText(DATA?.Question, 20),
                  DATA?.FAQ_ID
                )}`}
              >
                {parse(DATA?.Answer)}
              </a>
            ) : null}
          </p>
        ) : null}
      </div>
    </>
  );
};

export default VendorFaq;
