import React, { useEffect } from "react";
import * as BaseUrl from "./config/configKeys";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/home/home";
import SearchResult from "./components/searchResult/searchResult";
import { useLocalStorage } from "./utlis/customHooks/useLocalStorage";
import PageNotFound from "./utlis/pageNotFound/pageNotFound";
// import * as KEYS from "./config/config";
import AlternativeVendors from "./components/alternativeVendors/alternativeVendors";
import Onboarding from "./components/auth/signUp/onBoard/onboarding";
import SignUpOtpVerification from "./components/auth/signUp/otpVerification/signUpOtpVerification";
import CategoriesPage from "./components/categoriesPage/categoriesPage";
import ProductPage from "./components/productPage/productPage";
import Profile from "./components/profile/myAcc/profile";
import AllCategories from "./components/searchResult/components/allCategories/allCategories";
import PrivateRoutes from "./utlis/navigation/privateRoutes";
import CookieConsent from "react-cookie-consent";
import { browserName } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "./adapters/xhr/generalApi";
import ProductCategories from "./components/ProductCategories/productCategories";
import UserInvite from "./components/UserInvite/userInvite";
import ValidateUser from "./components/UserInvite/validateUser";
import AddProduct from "./components/addProduct/addProduct";
import AnswerProposal from "./components/answerProposal/anwerProposal";
import InvitePassword from "./components/auth/invite/invitePassword/invitePassword";
import JoinOrganization from "./components/auth/joinOraganization/joinOrganization";
import ResetPassword from "./components/auth/resetPassword/resetPassword";
import VerifyEmail from "./components/auth/resetPassword/verifyEmail";
import SignIn from "./components/auth/signIn/signIn";
import BlurbDetail from "./components/blurbDetail/blurbDetail";
import BlurbsPage from "./components/blurbsPage/blurbsPage";
import BoardDetailPage from "./components/boardDetailPage/boardDetailPage";
import BoardDetail from "./components/boardDetailView/boardDetail";
import Boards from "./components/boards/boards";
import BusinessProfile from "./components/businessProfile/businessProfile";
import CompanyProfile from "./components/companyProfile/companyProfile";
import EditQuestionProposal from "./components/editProposal/editQuestionProposal";
import FaqDetailPage from "./components/faqDetailpage/faqDetailPage";
import ManagePreference from "./components/managePreference/managePreference";
import ManageProposals from "./components/manageProposals/manageProposals";
import MyBoards from "./components/myBoards/myBoards";
import NewUseCaseDetail from "./components/newUseCaseDetail/newUseCaseDetail";
import BusinessOtp from "./components/octateBusiness/businessOtp";
import LinkedInAccess from "./components/productExpert/signUp/components/linkedInAccess";
import ProductExpSignUp from "./components/productExpert/signUp/productExpSignUp";
import ProductPageNew from "./components/productPageNew/productPage";
import EditProfileNew from "./components/profile/editProfile/editProfile";
import EditProfile from "./components/profile/editProfileOld/editProfile";
import Proposals from "./components/proposals/proposals";
import ViewAllProducts from "./components/providers/component/viewAllProducts/viewAllProducts";
import SalesHub from "./components/salesHub/salesHub";
import SharedProposals from "./components/sharedProposal/proposals";
import Privacy from "./components/termsAndCondition/privacy";
import Terms from "./components/termsAndCondition/terms";
import TopicPage from "./components/topicPage/topicPage";
import { octateMatrixFn, uxConfigFn } from "./contexts/reducer/authReducer";
import { utmUrlFunction } from "./contexts/reducer/homeReducer";
import DirectMessageWrapper from "./directMessageWrapper";
import { useEnCryptGetApi, useEnCryptPostApi } from "./utlis/customHooks/customHooks";
import PrivateRoutesOnlyBusiness from "./utlis/navigation/privateRouteOnlyBusiness";
import ValidateProposal from "./utlis/navigation/validateProposal";
import ValidateVendorProposal from "./utlis/navigation/validateVendorProposal";
import PageLoading from "./utlis/spinner/pageLoading";
import "./styles/user-defined.css";
import ProductExp from "./components/productExpert/home/productExp";
import UserActive from "./components/productExpert/signUp/components/userActive";
const App = () => {
  const [authUser, setUser] = useLocalStorage("user");
  // const PRODUCTS = KEYS?.CONFIG?.HEADER_SEARCH_TYPE?.PRODUCT;
  // const PROVIDERS = KEYS?.CONFIG?.HEADER_SEARCH_TYPE?.PROVIDER;
  const dispatch = useDispatch();
  const DEFAULT_HEADER_TITLE = null;
  const HOME = "home page";
  const PRODUCT_PAGE = "product page";
  const SEARCH_RESULTS = "search results page";
  const PROFILE = "profile page";
  const VENDOR_PAGE = "vendor page";
  const LOGIN = "login page";
  const ALL_CATEGORIES = "all categories";
  const CATEGORIES = "categories page";
  const VIEW_ALL_PRODUCTS = "view all products of vendor";
  const ALTERNATIVE_VENDORS = "alternative vendors";
  const SIGNUP_OTP_VERIFICATION = "signup otp verification";
  const SIGN_UP = "sign up page";
  const BLURB_DETAIL = "blurb detail page";
  const BLURBS_PAGE = "blurbs page";
  const GET_EARLY_ACCESS = "get early access";
  const RESET_PASSWORD = "reset password";
  const FORGOT_PASSWORD_VERIFY_EMAIL = "forgot password verify email";
  const JOIN_ORGANIZATION = "join organization page";
  const BUSINESS_PROFILE = "business profile";
  const SALES_HUB = "sales hub";
  const COMPANY_PROFILE = "Company profile";
  const FAQ_DETAIL_PAGE = "FAQ detail page";
  const PROPOSALS = "proposals";
  const BUYER = "buyer";
  const TOPICS = "Topic";
  const BOARDS = "board";
  const BOOKMARKS = "bookmarks";
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const GetConfigData = useEnCryptGetApi(`/getUXConfig`, "core", useGetRequestQuery);
  const GetIP = useEnCryptGetApi(`/getUXConfig`, "core", useGetRequestQuery);
  const VerifyOtpPostApi = useEnCryptPostApi(`/anonymous/signupnew`, "core", usePostRequestMutation);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const getData = async () => {
    console.log("get object called");
    // const res = await axios.get("https://geolocation-db.com/json/");
    VerifyOtpPostApi.handleTrigger({
      Skipped: true,
      DeviceId: `${browserName}`,
      // -${res.data.IPv4}
    });
  };
  // useEffect(() => {
  //   const socket = io(`${BaseUrl?.CONFIG?.BASE_END_POINT}`, {
  //     path: "/notifications/socket.io/",
  //   });
  //   socket.emit("getNotificationCount", userId);
  //   dispatch(socketFn(socket));
  //   return () => socket.close();
  // }, [userId]);
  // useEffect(() => {
  //   const socket2 = io(`${BaseUrl?.CONFIG?.BASE_END_POINT}`, {
  //     path: "/directmessage_backend/socket.io/",
  //   });
  //   if (vendorId) {
  //     socket2.emit("join", `vendorId_${vendorId}`);
  //   }
  //   socket2.emit("join", `userId_${userId}`);
  //   dispatch(chatSocketFn(socket2));
  //   return () => socket2.close();
  // }, [userId]);

  useEffect(() => {
    if (!authData?.data?.anonymousName && !VerifyOtpPostApi.data) getData();
    else if (!authUser?.data && !VerifyOtpPostApi.data) {
      getData();
    } else if (!authUser?.data?.anonymousName && VerifyOtpPostApi?.data) {
      setUser(VerifyOtpPostApi.data);
    }
  }, [VerifyOtpPostApi?.data]);

  // useEffect(() => {
  //   if (
  //     authUser?.data?.userId &&
  //* to get utm parameter and set it to redux
  const GetApiData = useEnCryptGetApi(`/getUTMParameters?type=detailed view`, "core", useGetRequestQuery);

  useEffect(() => {
    dispatch(utmUrlFunction(GetApiData?.data?.data?.data));
  }, [GetApiData?.data]);

  useEffect(() => {
    if (authUser?.data?.userId && BaseUrl?.CONFIG?.BROWSER_SPEED_BASE_END_POINT && window?.location?.href?.includes("octate")) {
      var trovoURL = `${BaseUrl?.CONFIG?.BROWSER_SPEED_BASE_END_POINT}`;
      trovoURL = trovoURL.replace("user_identifier", authUser?.data?.userId);
      console.log(trovoURL);
      fetch(trovoURL, { mode: "no-cors" });
    }
  }, [authUser?.data?.userId]);

  // https://thebox-dev.portqii.com/core/getOctateStats
  const getOctateMatrix = useEnCryptGetApi(`/getOctateStats`, "core", useGetRequestQuery);

  const handleAcceptCookie = () => {
    if (window?.location?.href?.includes("octate")) {
      // window['ga-disable-G-N5HN6EJ5WW'] = false;
      // const TRACKING_ID ='G-N5HN6EJ5WW';
      //ReactGA.initialize(TRACKING_ID);
      // alert("cookie Accepted by user");
      const s = document.createElement("script");
      s.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-9Z9JHV94X3");
      s.async = true;
      document.head.appendChild(s);
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      // gtag("config", "G-N5HN6EJ5WW");
      gtag("config", "G-9Z9JHV94X3", {
        user_id: authUser?.data?.userId,
      });
    } else {
    }
  };
  useEffect(() => {
    dispatch(octateMatrixFn(getOctateMatrix?.data?.data));
  }, [getOctateMatrix?.data]);
  useEffect(() => {
    dispatch(uxConfigFn(GetConfigData?.data?.data));
  }, [GetConfigData?.data?.data]);
  // dispatch(uxConfigFn(GetConfigData?.data?.data));
  // dispatch(octateMatrixFn(getOctateMatrix?.data?.data));

  const PRODUCTS = KEYS?.BASE_END_POINT ? KEYS?.HEADER_SEARCH_TYPE?.PRODUCT : "products";
  const PROVIDERS = KEYS?.BASE_END_POINT ? KEYS?.HEADER_SEARCH_TYPE?.PROVIDER : "vendors";
  const BLURBS = "blurbs";
  const USE_CASE = "use-cases";
  const router = createBrowserRouter([
    {
      // path: "/",
      element: <PrivateRoutes headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: "/profile",
          element: <Profile page={PROFILE} />,
          errorElement: <PageNotFound />,
        },

        {
          path: `/manage-preferences`,
          element: <ManagePreference />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    {
      path: "/profile/edit-profile/:tab",
      element: true ? <EditProfileNew page={PROFILE} /> : <EditProfile page={PROFILE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/user-boards`,
      element: <Boards page={BOARDS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/:vendor/faq/:name",
      element: <FaqDetailPage page={FAQ_DETAIL_PAGE} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/faq/:name",
      element: <FaqDetailPage page={FAQ_DETAIL_PAGE} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      // path: "/",
      element: <PrivateRoutesOnlyBusiness headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: `/shared-proposals`,
          element: <SharedProposals page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
        {
          path: `/proposals/:tab`,
          element: <Proposals page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
        {
          path: `/proposals`,
          element: <Proposals page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
        {
          path: `/manage-proposals`,
          element: <ManageProposals page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    {
      // path: "/",
      element: <ValidateProposal headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: `/proposal/buyer/:name`,
          element: <EditQuestionProposal page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    {
      // path: "/",
      element: <ValidateUser headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: `/user-invite`,
          element: <UserInvite />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    {
      // path: "/",
      element: <ValidateVendorProposal headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: `/proposal/vendor/:name`,
          element: <AnswerProposal page={PROPOSALS} />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    // EditQuestionProposal
    {
      path: "/",
      element: <Home page={HOME} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/sign-in",
      element: <SignIn page={LOGIN} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/profile/company-profile",
      element: <CompanyProfile page={COMPANY_PROFILE} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    // AddProduct
    {
      path: "/profile/add-product",
      element: <AddProduct page={COMPANY_PROFILE} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/sign-up",
      element: <Onboarding page={SIGN_UP} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${PRODUCTS}/:name`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${PRODUCTS}`} type={PRODUCTS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${PROVIDERS}/:name`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${PROVIDERS}`} type={PROVIDERS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${PRODUCTS}`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${PRODUCTS}`} type={PRODUCTS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${PROVIDERS}`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${PROVIDERS}`} type={PROVIDERS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${BLURBS}/:name`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${BLURBS}`} type={BLURBS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${BLURBS}`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${BLURBS}`} type={BLURBS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${USE_CASE}/:name`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${USE_CASE}`} type={USE_CASE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/search/${USE_CASE}`,
      element: <SearchResult page={`${SEARCH_RESULTS}-${USE_CASE}`} type={USE_CASE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/categories`,
      element: <AllCategories page={ALL_CATEGORIES} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },

    {
      path: `/vendor/:name`,
      element: <BusinessProfile page={VENDOR_PAGE} type={BUYER} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/vendor/:name/:tab`,
      element: <BusinessProfile page={VENDOR_PAGE} type={BUYER} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/all-products/:id`,
      element: <ViewAllProducts page={VIEW_ALL_PRODUCTS} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/board/:name`,
      element: <BoardDetailPage page={"board-detail-page"} />,
      errorElement: <PageNotFound />,
    },
    // {
    //   path: `/category/:name/${PROVIDERS}`,
    //   element: (
    //     <ProductCategories
    //       page={`${CATEGORIES}-${PROVIDERS}`}
    //       type={PROVIDERS}
    //     />
    //   ),
    //   errorElement: <PageNotFound />,
    // },
    {
      path: `/category/:name`,
      element: <ProductCategories page={`${CATEGORIES}}`} type={PRODUCTS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/category/:name/:tab`,
      element: <ProductCategories page={`${CATEGORIES}`} type={PRODUCTS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/topic/:name`,
      element: <ProductCategories page={TOPICS} type={TOPICS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/topic/:name/:tab`,
      element: <TopicPage page={TOPICS} type={TOPICS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/product/:name`,
      element: <ProductPageNew page={PRODUCT_PAGE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/product/:name/:tab`,
      element: <ProductPageNew page={PRODUCT_PAGE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/alternative-vendors/:id`,
      element: <AlternativeVendors page={ALTERNATIVE_VENDORS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/email-verification`,
      element: <SignUpOtpVerification page={SIGNUP_OTP_VERIFICATION} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    // {
    //   path: `/user-details`,
    //   element: <Onboarding page={SIGN_UP} headerTitle={DEFAULT_HEADER_TITLE} />,
    //   errorElement: <PageNotFound />,
    // },
    {
      path: `/:vendor/blurb/:name/:tab`,
      element: <BlurbDetail page={BLURB_DETAIL} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/blurb/:name`,
      element: <BlurbDetail page={BLURB_DETAIL} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/:vendor/:product/blurbs/:id", //product blurbs
      element: <BlurbsPage page={`${BLURBS_PAGE}-${PRODUCTS}`} type="product" />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/:vendor/blurbs/:id",
      element: <BlurbsPage page={`${BLURBS_PAGE}-${PROVIDERS}`} type="vendor" />,
      errorElement: <PageNotFound />,
    },
    // {
    //   path: "/get-early-access",
    //   element: <GetEarlyAccess page={GET_EARLY_ACCESS} />,
    //   errorElement: <PageNotFound />,
    // },
    {
      path: "/reset-password",
      element: <ResetPassword page={RESET_PASSWORD} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/verify-email",
      element: <VerifyEmail page={FORGOT_PASSWORD_VERIFY_EMAIL} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/join-organization",
      element: <JoinOrganization page={JOIN_ORGANIZATION} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/business-profile/:tab",
      element: <BusinessProfile page={BUSINESS_PROFILE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/sales-hub/:tab",
      element: <SalesHub page={SALES_HUB} />,
      errorElement: <PageNotFound />,
    },
    // {
    //   path: `/super-user`,
    //   element: <SuperUser />,
    //   errorElement: <PageNotFound />,
    // },
    // {
    //   path: `/proposals`,
    //   element: <Proposals page={PROPOSALS} />,
    //   errorElement: <PageNotFound />,
    // },

    {
      path: `/board-detail/:type/:tab`,
      element: <BoardDetail />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/board-detail/my-boards`,
      element: <MyBoards />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/terms`,
      element: <Terms />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/privacy`,
      element: <Privacy />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/invite-business-user`,
      element: <InvitePassword />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/business-email-verification`,
      element: <BusinessOtp />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/usecase/:name/:tab`,
      element: <NewUseCaseDetail />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/:vendor/usecase/:name`,
      element: <NewUseCaseDetail />,
      errorElement: <PageNotFound />,
    },

    {
      path: "/product-expert",
      errorElement: <PageNotFound />,
      children: [
        {
          path: `sign-up`,
          element: <ProductExpSignUp />,
          errorElement: <PageNotFound />,
        },
        {
          path: `linkedin-access`,
          element: <LinkedInAccess />,
          errorElement: <PageNotFound />,
        },
        {
          path: `home/:tab`,
          element: <ProductExp />,
          errorElement: <PageNotFound />,
        },
        {
          path: `user-exist`,
          element: <UserActive />,
          errorElement: <PageNotFound />,
        },
      ],
    },
    //! old path for seo
    {
      path: `/all-categories`,
      element: <AllCategories page={ALL_CATEGORIES} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/categories-page/:name/${PROVIDERS}`,
      element: <CategoriesPage page={`${CATEGORIES}-${PROVIDERS}`} type={PROVIDERS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/categories-page/:name`,
      element: <CategoriesPage page={`${CATEGORIES}-${PRODUCTS}`} type={PRODUCTS} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/product/:name`,
      element: <ProductPage page={PRODUCT_PAGE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/vendor/blurbs/:name",
      element: <BlurbsPage page={`${BLURBS_PAGE}-${PROVIDERS}`} type="vendor" />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/product/blurbs/:name", //product blurbs
      element: <BlurbsPage page={`${BLURBS_PAGE}-${PRODUCTS}`} type="product" />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/alternative-vendors/:name`,
      element: <AlternativeVendors page={ALTERNATIVE_VENDORS} />,
      errorElement: <PageNotFound />,
    },

    {
      path: `/vendor/:name/all-products`,
      element: <ViewAllProducts page={VIEW_ALL_PRODUCTS} headerTitle={DEFAULT_HEADER_TITLE} />,
      errorElement: <PageNotFound />,
    },
    {
      path: `/blurb-detail/:name`,
      element: <BlurbDetail page={BLURB_DETAIL} />,
      errorElement: <PageNotFound />,
    },
    //! Do not add new routes over here , Please add above previous comment
  ]);
  const router1 = createBrowserRouter([
    {
      path: `*`,
      element: <PageLoading />,
      errorElement: <PageLoading />,
    },
  ]);
  return (
    <div id="app">
      {KEYS?.BASE_END_POINT ? (
        <DirectMessageWrapper>
          <RouterProvider router={router} />
          <CookieConsent
            location="bottom"
            // buttonText="Sure man!!"
            enableDeclineButton
            cookieName="octate"
            style={{ background: "#2B373B", zIndex: "1000" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            onAccept={handleAcceptCookie}
          >
            We use cookies to enhance your experience on our platform. <span style={{ fontSize: "10px" }}></span>
          </CookieConsent>
        </DirectMessageWrapper>
      ) : (
        <RouterProvider router={router1} />
      )}
    </div>
  );
};

export default App;
