import React, { useState } from "react";
import * as CONFIG_KEYS from "../../../config/config";
import NewBlurbCard from "../../../utlis/hoc/newBlurbCard/newBlurbCard";
import NewProductCard from "../../../utlis/hoc/newProductCard/newProductCard";
import NewUseCaseCard from "../../../utlis/hoc/newUseCaseCard/newUseCaseCard";
import NewVendorCard from "../../../utlis/hoc/newVendorCard/newVendorCard";
import BlurbCardList from "./blurbCardList";
import BoardHeader from "./boardHeader";
import ProductCardList from "./productCardList";
import UseCaseCardList from "./useCaseCardList";
import VendorCardList from "./vendorCardList";
function BoardItemList(props) {
  const BOARD_KEYS = CONFIG_KEYS?.CONFIG?.BOARDS;
  const BOARD_ITEM_LIST_KEYS = BOARD_KEYS?.BOARD_LIST;
  const Data = props?.Data;
  const selectedTab = props?.selectedTab;
  const ObjectKeys = typeof Data == "object" && Object.keys(Data)?.length ? Object.keys(Data) : [];
  const filteredText = props?.filteredText;

  return (
    <>
      {ObjectKeys.map((list) => {
        return (
          <div>
            {selectedTab == list && list == BOARD_ITEM_LIST_KEYS.BLURBS ? (
              <BlurbCardList
                filteredText={filteredText}
                handleChangePosition={props?.handleChangePosition}
                cardType={"blurb"}
                page={props?.page}
                boardUserId={props?.boardUserId}
                data={Data?.[list]}
              />
            ) : selectedTab == list && list == BOARD_ITEM_LIST_KEYS?.PRODUCTS ? (
              <ProductCardList
                filteredText={filteredText}
                boardUserId={props?.boardUserId}
                handleChangePosition={props?.handleChangePosition}
                page={props?.page}
                data={Data?.[list]}
                cardType={"product"}
              />
            ) : selectedTab == list && list == BOARD_ITEM_LIST_KEYS.USE_CASES ? (
              <UseCaseCardList
                filteredText={filteredText}
                boardUserId={props?.boardUserId}
                handleChangePosition={props?.handleChangePosition}
                cardType={"use-case"}
                page={props?.page}
                data={Data?.[list]}
              />
            ) : selectedTab == list && list == BOARD_ITEM_LIST_KEYS.VENDOR ? (
              <VendorCardList
                filteredText={filteredText}
                boardUserId={props?.boardUserId}
                handleChangePosition={props?.handleChangePosition}
                cardType={"vendors"}
                page={props?.page}
                data={Data?.[list]}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );
}

export default BoardItemList;
