import React, { useState } from "react";
import AddNewPermissionSetPortal from "./addNewPermissionPortal";
import FeatherIcon from "feather-icons-react";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { usePostUserPermissionsMutation, usePutUserPermissionsMutation } from "../../../../adapters/xhr/crudServiceApi";
import { useSelector } from "react-redux";
function PermissionSetCard(props) {
  const index = props?.index;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const item = props?.data;
  const [openPermissionSetPortal, setOpenPermissionSetPortal] = useState(false);
  const handleAddNewPermissionSet = () => {
    setOpenPermissionSetPortal(true);
    props?.handleAddNewPermissionSet();
  };
  const AddPermissionSetPostApi = useEnCryptPostApi(
    `/permission/updatePermissionSet`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePutUserPermissionsMutation
  );
  // /permission/deletePermissionSet
  const RemovePermissionSetPostApi = useEnCryptPostApi(
    `/permission/deletePermissionSet`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPermissionsMutation
  );
  function handleDeleteClicked(val) {
    let rawData = { ...val };
    delete rawData.Resource;
    RemovePermissionSetPostApi.handleTrigger(val);
    setOpenPermissionSetPortal(false);
  }
  function handleSaveClicked(val) {
    let rawData = { ...val };
    delete rawData.Resource;
    AddPermissionSetPostApi.handleTrigger(val);
    setOpenPermissionSetPortal(false);
  }
  const handleEditPermissionSet = (val) => {
    setOpenPermissionSetPortal(true);
  };
  return (
    <div class="edit-profile-feature-list-container-flex">
      <div
        onClick={() => props?.handleFeatureList(index + 1)}
        className={`permission-set-wrapper ${
          props?.featureActiveList == index + 1 ? "active-list-wrapper" : ""
        } p-x-mini p-y-mini`}
      >
        <p className="permission-set-title-text p-l-base"> {item?.Permission_Set_Name}</p>
        {item?.Can_Edit ? (
          <FeatherIcon onClick={handleEditPermissionSet} icon="edit-3" size="20" color={"#fff"} />
        ) : null}
      </div>

      {openPermissionSetPortal ? (
        <AddNewPermissionSetPortal
          allData={props?.allData}
          handleClose={() => setOpenPermissionSetPortal(false)}
          handleDeleteClicked={handleDeleteClicked}
          handleSaveClicked={handleSaveClicked}
          data={item}
        />
      ) : null}
    </div>
  );
}

export default PermissionSetCard;
