import React from "react";
import NewBoardCard from "./newBoardCard";

function AllBoardsList(props) {
  const keys = props?.label;
  const data = props?.data[keys]?.length ? props?.data[keys] : [];
  return (
    <div className={` new-board-list-main-list-section-sub-wrapper`}>
      {data.map((item) => {
        return <NewBoardCard item={item} type={keys} />;
      })}
    </div>
  );
}

export default AllBoardsList;
