import React, { useEffect, useRef, useState } from "react";
import { validateForms } from "../../utlis/hof/hof";
import _ from "lodash";
import { useNavigate } from "react-router";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import { tempAuthDetails } from "../../contexts/reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../config/configKeys";
import LoadingSpinner from "../../utlis/spinner/loadingSpinner";
import InputFieldNew from "../auth/signUp/onBoard/components/InputFieldNew";
import SingleSelectNewDropdown from "../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import PasswordField from "../auth/signUp/onBoard/components/passwordField";
import RoleSelection from "../auth/signUp/onBoard/components/roleSelection";
import * as ENV_KEYS from "../../config/configKeys";
const OctateBusiness = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [role, setRole] = useState({});
  const [vendor, setVendor] = useState("");
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [domainName, setDomainName] = useState("");
  const [website, setWebsite] = useState("");
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  const OnboardingDetails = useEnCryptPostApi(`/CreateNewUser`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const GetCountryCode = useEnCryptGetApi(`/getCountryDialingCode`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, useGetRequestQuery);

  const GetExistingData = useEnCryptGetApi(
    `/getExistingVendors?Skeleton_Domain=${vendorName}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    vendorName?.length > 0 ? false : true
  );
  const CountryCode = GetCountryCode?.data?.data;

  const handleInputEmail = (val) => {
    setEmailId(val);
    const [, domain] = val?.split("@");
    setDomainName(domain);
    setWebsite(`https://${domain}`);
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (OnboardingDetails?.data?.Status) {
      props?.handleCloseModal();
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.VENDOR_INVITED,
        Item_Type: null,
        Item_Id: null,
        User_Type: "Known",
        Persona: "Business user",
        Target: {
          page: null,
          link: null,
          id: OnboardingDetails?.data?.userId,
          vendorId: OnboardingDetails?.data?.VendorId,
        },
        Object: {
          section: null,
          context: `Vendor invited`,
          page: "/business-profile/teams",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [OnboardingDetails?.data]);

  const validateForm = (values) => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!values.Email_Address) {
      errors.emailId = "Email Id is required";
    } else if (!emailRegex.test(values.Email_Address)) {
      errors.emailId = "Please enter valid email address";
    }
    if (!values.Skeleton_Domain) {
      errors.domain = "Domain uis required";
    }
    return errors;
  };

  const handleSubmit = () => {
    const rawData = {
      Device_Id: authData?.data?.deviceId,
      Persona: "Business User",
      First_Name: firstName,
      Last_Name: lastName,
      Email_Address: emailId,
      Phone_Number: phoneNumber,
      Phone_Code: phoneCode,
      Vendor_ID: vendor?.Vendor_ID,
      Vendor_Name: vendor?.Vendor_Name,
      Skeleton_Domain: domainName,
      website: website,
      Business_Role: role?.name,
    };

    const validate = validateForm(rawData);
    setError(validate);

    if (_.isEmpty(validate)) {
      OnboardingDetails?.handleTrigger(rawData);
    }
  };

  return (
    <div style={{ margin: "2%" }}>
      <div className="sign-up-page-header-text m-b-sm">Invite vendor to Octate AI</div>
      <div class="grid-two-columns">
        <InputFieldNew
          label={ONBOARDING?.FIRST_NAME}
          value={firstName}
          changeText={(val) => setFirstName(val)}
          error={error?.firstName}
          showLabel={true}
          // required={true}
        />
        <InputFieldNew
          label={ONBOARDING?.LAST_NAME}
          value={lastName}
          changeText={(val) => setLastName(val)}
          error={error?.lastName}
          // required={true}
          showLabel={true}
        />
      </div>
      <InputFieldNew
        label={props?.consumerAccount ? ONBOARDING?.EMAIL_ADDRESS : ONBOARDING?.WORK_EMAIL}
        value={emailId}
        changeText={(val) => handleInputEmail(val)}
        error={error?.emailId}
        icon={"mail"}
        type={"email"}
        required={true}
        showLabel={true}
        maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
      />
      <InputFieldNew
        label={"Domain name"}
        value={domainName}
        changeText={(val) => setDomainName(val)}
        required={true}
        showLabel={true}
        error={error?.domain}
      />
      <div class="field-company-profile p-r m-b-md" id="field-user__country_id">
        <label className={`input-field-label`}>Vendor Name</label>
        <SingleSelectNewDropdown
          handleSelectedData={(val) => setVendor(val)}
          data={GetExistingData?.data?.data}
          placeholder={"Vendor Name"}
          returnObject={true}
          // flag={true}
          selectedVal={vendorName}
          name={"Vendor_Name"}
          getSearchedText={true}
          handleChangeSearchText={(val) => setVendorName(val)}

          // label={ADD_PRODUCT?.PRODUCT_SEGMENT_LABEL}
        />
      </div>
      <div className="p-r">
        <label className={`input-field-label`}>Role</label>
        <RoleSelection handleRole={(val) => setRole(val)} />
      </div>
      {/* <InputFieldNew label={"Role"} value={role} changeText={(val) => setRole(val)} showLabel={true} /> */}
      <div class="grid-1-3">
        <SingleSelectNewDropdown handleSelectedData={(val) => setPhoneCode(val)} placeholder="+1" data={CountryCode} type={"number"} />
        <InputFieldNew
          label={ONBOARDING?.PHONE_NUMBER}
          value={phoneNumber}
          changeText={(val) => setPhoneNumber(val?.replace(/[^0-9]/g, ""))}
          error={error?.phoneNumber}
          icon={"smartphone"}
          type={"tel"}
        />
      </div>

      <InputFieldNew label={"Website"} value={website} changeText={(val) => setWebsite(val)} showLabel={true} />

      {OnboardingDetails?.isLoading ? (
        <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="create-account-button" onClick={() => handleSubmit()} role={"button"}>
          {" "}
          {ONBOARDING?.CREATE_ACC_BUTTON}
        </div>
      )}
    </div>
  );
};

export default OctateBusiness;
