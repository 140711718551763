import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../config/configKeys";
import { usePostRequestMutation, useUpdateUserEmailMutation } from "../../adapters/xhr/generalApi";
import { validateForgotPassword, validateRequestVendor } from "../../utlis/hof/hof";
import InputFieldNew from "../auth/signUp/onBoard/components/InputFieldNew";
import * as CONFIG_KEYS from "../../config/config";
import { tempAuthDetails } from "../../contexts/reducer/authReducer";
const VerifiedEmail = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const VERIFIED_EMAIL = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE.VERIFIED_EMAIL;
  const dispatch = useDispatch();

//   useEffect(() => {
//     const closeModal = setTimeout(() => {
//       props?.handleClose();
//     });
//     return clearTimeout(closeModal);
//   }, 5000);
  const handleSubmit = (val) => {
    props?.handleClose();
  };

  const HandleCloseModal = () => {
    props?.handleClose();
  };

  return (
    <div className="request-vendor-portal--main-wrapper">
      <div className="add-to-board-container-header-wrapper">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <h1 className="request-new-vendor-header-text">{VERIFIED_EMAIL?.SUBMIT_REQUEST}</h1>
      </div>
      <div className="request-category-container">
        <div className="m-b-sm">
          <h5 className="req-vendor-portal-desc-text">{VERIFIED_EMAIL.DESCRIPTION}</h5>
        </div>
        <div className="flex justify-flex-end">
          <div className="request-category-submit-button" onClick={() => handleSubmit(3)}>
            {VERIFIED_EMAIL?.SUBMIT_REQUEST_BUTTON}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedEmail;
