import React, { useEffect, useState } from "react";
// import * as KEYS from "../../../config/config";
import { DecryptApi, EncryptApiGetData, GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../utlis/hof/hof";
import { useGetRequestQuery, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import * as SECRET_KEYS from "../../../config/configKeys";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../utlis/customHooks/customHooks";
import ProductsCards from "../../../utlis/hoc/productCardSmall/productsCards";

const ProductData = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PRODUCT_DATA = KEYS?.PRODUCT_DATA;
  const signalTrackingProductsObject = {
    Action: SECRET_KEYS.CONFIG.ALL_PRODUCTS,
    page: "search page",
    section: props?.section,
    context: "all products",
  };

  const GetApiData = useEnCryptGetApi(
    `/getAllPopularProducts?userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleAllProductsLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductsObject?.Action,
      Item_Type: "product",
      Item_Id: null,
      Target: {
        page: signalTrackingProductsObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductsObject?.section,
        context: signalTrackingProductsObject?.context,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  // useEffect(() => {
  //   const enCryptData = {
  //     // https://thebox-dev.portqii.com/search_backend/getAllPopularProducts
  //     path: `/getAllPopularProducts`,
  //     service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
  //     userInfo: { deviceId: "ffbe445", userId: 234 },
  //   };
  //   const enCryptProductCategory = EncryptApiGetData(enCryptData);
  //   setGetQuery(enCryptProductCategory);
  // }, []);

  // const DeCryptProductCategoryList = DecryptApi(allProductCategoryData);
  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function(txt) {
      return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
    });
  }
  return (
    <div>
      <div className="landing-page__section landing-page__benefits">
        <div className="ui container" style={{ maxWidth: "1120px" }}>
          <div className="flex space-between align-items-end">
            <div className="landing-page__section-header">
              <div className="section-header__content">
                <h2 className="landing-page__section-title">{PRODUCT_DATA?.HEADER}</h2>
                <div className="landing-page__section-subtitle">{PRODUCT_DATA?.SUB_HEADER}</div>
              </div>
            </div>
          </div>

          <div className="grid-two-columns grid-col-gap-sm grid-row-gap-md tablet-landscape-1-col m-t-sm">
            {GetApiData?.data?.data?.length
              ? GetApiData?.data?.data?.map((products) => {
                  return (
                    <ProductsCards
                      page={props?.page}
                      allProducts={products}
                      action={props?.action}
                      section={props?.section}
                      type={"RelatedProducts"}
                    />
                  );
                })
              : null}
          </div>
          {GetApiData?.data?.data?.length >= 6 ? (
            <div className="m-t-sm">
              <a
                className="button primary outlined"
                onClick={() => handleAllProductsLinkClicked("/search/products")}
                href="/search/products"
              >
                {PRODUCT_DATA?.SEARCH_ALL}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductData;
