import React from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { GetIdFormLink } from "../../utlis/hof/hof";

const MetaSEO = (props) => {
  const headerTitle = props?.title
    ? `${props?.title} | octate.ai`
    : "octate.ai";
  return (
    <Helmet>
      {/* <meta charSet="utf-8" />
      <title>{headerTitle}</title>

      {window.location.href &&
      !window.location.href.includes("octate") ? (<meta name="robots" content="noindex, nofollow" />
      ):null}

      {props?.description &&
      window.location.href &&
      window.location.href.includes("octate") ? (
        <meta name="description" content={props?.description} />
      ) : null}

      {props?.Keywords &&
      window.location.href &&
      window.location.href.includes("octate") ? (
        <meta name="keywords" content={props?.Keywords?.map((item) => item)} />
      ) : null}

      {props?.title &&
      window.location.href &&
      window.location.href.includes("octate") ? (
        <meta property="og:title" content={props?.title} />
      ) : null}

      {props?.description &&
      window.location.href &&
      window.location.href.includes("octate") ? (
        <meta property="og:description" content={props?.description} />
      ) : null}

      {props?.description &&
      window.location.href &&
      window.location.href.includes("octate") ? (
        <meta
          name="og:keywords"
          content={props?.Keywords?.map((item) => item)}
        />
      ) : null}

      <meta property="og:url" content={window?.location?.href} />
      <meta property="og:site_name" content="octate.ai" />
      <meta property="og:locale" content="en_US" />
      <link rel="canonical" content={window?.location?.href} /> */}
    </Helmet>
  );
};

export default MetaSEO;
