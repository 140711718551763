import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import InputFieldFilterProposal from "../inputFieldFilterProposal";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import { FilterSelectedData } from "../../../../utlis/hof/hof";
import ConfirmDeletePortal from "./confirmDeletePortal";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { useAddProposalApiMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
const ProposalTableHeader = (props) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorId = authData?.data?.vendorId;
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PROPOSAL_HEADER_KEYS = KEYS?.BUYER_PROPOSAL_LANDING_PAGE;
  const proposalSearchFilters = props?.proposalSearchFilters;
  const proposalTableData = props?.proposalTableData;
  const checkIfCanDelete = FilterSelectedData(props?.proposalTableData);
  // Proposal_ID
  const handleDeleteClicked = () => {
    if (checkIfCanDelete?.length) {
      setShowConfirmDeleteModal(!showConfirmDeleteModal);
    }
  };
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const DELETE_API = PROPOSAL_TYPE == "answer" ? `/proposal/vendorReject` : `/deleteProposals`;
  const vendorPostData = useEnCryptPostApi(
    DELETE_API,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useAddProposalApiMutation
  );
  useEffect(() => {
    if (vendorPostData?.data?.status) {
      setShowConfirmDeleteModal(false);
      // setTimeout(() => {
      //   navigate("/");
      // }, 2000);
    }
  }, [vendorPostData?.data]);
  const handleActionsClicked = (val) => {
    if (val == "close") {
      setShowConfirmDeleteModal(false);
    } else if (val == "delete") {
      const valuesToDelete = checkIfCanDelete;
      if (PROPOSAL_TYPE == "answer") {
        vendorPostData.handleTrigger({
          Proposal_ID: valuesToDelete?.map((list) => {
            return list?.[API_KEYS?.PROPOSAL_ID];
          }),
          Vendor_ID: vendorId,
        });
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          SignalCreatedAt: new Date().getTime() / 1000,
          Action: SECRET_KEYS.CONFIG.VENDOR_DELETE_PROPOSAL,
          Target: {
            Id: null,
            type: "vendor",
            page: "proposal page",
            link: "/proposals",
          },
          Object: {
            type: "Proposal",
            Id: valuesToDelete?.map((list) => {
              return list?.[API_KEYS?.PROPOSAL_ID];
            }),
            [ADD_PROPOSAL_KEYS?.VENDOR_ID]: vendorId,
            section: null,
            context: "Proposal Deleted",
            page: "vendor proposal page",
          },
        };
        // vendorPostData.handleTrigger({
        //   Proposal_ID: valuesToDelete?.map((list) => {
        //     return list?.[API_KEYS?.PROPOSAL_ID];
        //   }),
        // });

        SignalTracking?.handleTrigger(signalTrackingPostData);
      } else {
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          SignalCreatedAt: new Date().getTime() / 1000,
          Action: SECRET_KEYS.CONFIG.BUYER_DELETE_PROPOSAL,
          Target: {
            Id: null,
            type: "vendor",
            page: "business profile",
            link: "/sales-hub/proposals",
          },
          Object: {
            type: "Proposal",
            Id: valuesToDelete?.map((list) => {
              return list?.[API_KEYS?.PROPOSAL_ID];
            }),
            section: null,
            context: "Proposal Deleted",
            page: "buyer proposal page",
          },
        };
        vendorPostData.handleTrigger({
          Proposal_ID: valuesToDelete?.map((list) => {
            return list?.[API_KEYS?.PROPOSAL_ID];
          }),
        });
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
    }
  };
  return (
    <div className="proposal-summary-header-main-wrapper p-b-md">
      {showConfirmDeleteModal ? (
        <ConfirmDeletePortal
          handleClose={() => setShowConfirmDeleteModal(false)}
          handleActionsClicked={handleActionsClicked}
          checkIfCanDelete={checkIfCanDelete}
          vendorPostData={vendorPostData}
        />
      ) : null}
      <div className="hide-in-mobile">
        <h3 className="proposal-table-main-header-proposal-text">{PROPOSAL_HEADER_KEYS?.PROPOSALS}</h3>
      </div>

      <div className="dis-f-r proposal-table-main-header-input-filed-action-button-container">
        <div className="dis-f-r add-padding-in-mobile">
          <InputFieldFilterProposal
            label={"Search"}
            icon={"search"}
            value={proposalSearchFilters?.filterText}
            changeText={(val) =>
              props?.handleDetails({
                ...proposalSearchFilters,
                filterText: val,
              })
            }
          />
        </div>
        {/* <div className="proposal-table-main-header-action-button-container dis-f-r m-x-mini cursor--pointer">
          <FeatherIcon icon="send" size={14} color={"#757575"} />
          <p className="proposal-table-main-header-content-share-text p-l-base">
            Share
          </p>
        </div> */}
        <div
          data-tooltip={checkIfCanDelete?.length ? "Delete" : "Select at least one proposal"}
          data-position="left center"
          data-inverted="true"
          role="button"
          onClick={handleDeleteClicked}
          className={`proposal-table-main-header-action-button-container dis-f-r ${
            checkIfCanDelete?.length ? "cursor--pointer" : "cursor--disable"
          }`}
        >
          <FeatherIcon icon="trash" size={14} color={"#757575"} />
          <p className="proposal-table-main-header-content-share-text p-l-base">Delete</p>
        </div>
      </div>
    </div>
  );
};

export default ProposalTableHeader;
