import React from "react";
import { useState } from "react";

const ProductExpViewMore = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleShareBlurbClicked = (val) => {
    setToggleMenu(!toggleMenu);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  return (
    <div className="product-exp-view-more-container">
      <div tabIndex={0} onBlur={handleBlur} className="engagement-bar-items p-r">
        <span className="engagement-view-more-text" onClick={() => handleShareBlurbClicked()}>
          View More
        </span>
        {toggleMenu && (
          <div className="view-more-container  modern-card modern-card--elevated">
            <div className="share-single-item">
              <div
                className="action-button-container"
                onClick={() => [props?.handleActionClicked("edit"), handleShareBlurbClicked()]}
                style={{ width: "100%", padding: "5px" }}
              >
                <p className="share-desc-title">Edit</p>
              </div>
            </div>
            <div className="share-single-item">
              <div
                className="action-button-container"
                onClick={() => [props?.handleActionClicked("delete"), handleShareBlurbClicked()]}
                style={{ width: "100%", padding: "5px" }}
              >
                <p className="share-desc-title">Delete</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductExpViewMore;
