import React from "react";
import FeatherIcon from "feather-icons-react";

const InputFieldNew = (props) => {
  const error = props?.error;
  const REQUIRED = props?.required;

  return (
    <div className="m-b-md input-field-wrapper-position-relative">
      {props?.showLabel ? (
        <label className={`input-field-label ${error ? "error" : ""} `} for={props?.label}>
          {props?.label}
        </label>
      ) : null}
      <div class={`input-field-wrapper ${error ? "background-error" : ""}`}>
        <FeatherIcon icon={props?.icon} size="17" color={"#6E7079"} className="input-icons" />
        <input
          id={props?.label}
          type={props?.type ? props?.type : "text"}
          value={props?.value}
          name={props?.label}
          placeholder={props?.label}
          onChange={(e) => props?.changeText(e.target.value)}
          className={`input-field-text ${error ? "background-error" : ""} ${REQUIRED ? "required-border-right" : ""}`}
          autoComplete="off"
          style={props?.icon ? { paddingLeft: "30px" } : { paddingLeft: "10px" }}
          maxLength={props?.maxLength ?? null}
        />
      </div>
      {error ? (
        <span class="input-field-text-error" style={props?.showLabel ? { top: "64px" } : {}}>
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default InputFieldNew;
