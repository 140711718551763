import React from "react";
import UseCaseInfoContent from "./useCaseInfoContent";

const UseCaseInfo = (props) => {
  const USE_CASE_DATA = props?.data;

  return (
    <>
      <div
        className="grid-three-columns-auto use-case-more-information-container"
        style={props?.page == "Usecase detail page" ? { padding: "10px 0px" } : {}}
      >
        <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"users"}
          name="Customers"
          navigate={"products"}
          keyName="Use_Case_Customers"
          page={props?.page}
          section={props?.section}
          array={true}
        />
        <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"zap"}
          navigate={"blurbs"}
          name="Industry"
          keyName={"Use_Case_Industry"}
          page={props?.page}
          section={props?.section}
          object={true}
        />
        {/* <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"tag"}
          navigate={"blurbs"}
          name="Product Name"
          keyName={"Product_Name"}
          page={props?.page}
          section={props?.section}
        /> */}
      </div>
    </>
  );
};

export default UseCaseInfo;
