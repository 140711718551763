import React from "react";
import { createSlice } from "@reduxjs/toolkit";
//  this component is used to handle the the data of the detail blurb selected
export const notificationReducer = createSlice({
  name: "notification",
  initialState: {
    notificationTarget: {},
    vendorProposalSelectedTab: 1,
  },
  reducers: {
    notificationTargetFn: (state, action) => {
      state.notificationTarget = action.payload;
    },
    vendorProposalSelectedTabFn: (state, action) => {
      state.vendorProposalSelectedTab = action.payload;
    },
  },
});

export const { notificationTargetFn, vendorProposalSelectedTabFn } = notificationReducer.actions;
export default notificationReducer.reducer;
