import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import * as CONFIG_KEYS from "../../../../../config/config";
import ShowAnswerCheckbox from "./showAnswerCheckbox";
import moment from "moment";
const VendorResponse = (props) => {
  const selectedQuestion = props?.selectedQuestion;
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const [showSection, setShowSection] = useState(true);
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const AnswerText =
    selectedQuestion?.[ADD_QUESTION_KEYS?.PROPOSAL_META_DATA]?.[
      ADD_QUESTION_KEYS?.ANSWER
    ];
  const handleToggleSection = (val) => {
    setShowSection(!showSection);
  };
  return (
    <>
      <div className="vendor-proposal-dis-flex-space-btw answer-proposal-selected-question-question-container">
        <h6 className="proposal-submit-page-header-title">Vendor Response</h6>
        <FeatherIcon
          onClick={handleToggleSection}
          icon={showSection ? "chevron-down" : "chevron-right"}
          size="20"
          role="button"
          // className="blurb-detail-social"
          color={"#130F26"}
          // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
        />
      </div>
      {showSection ? (
        <>
          {selectedQuestion?.[ADD_QUESTION_KEYS?.QUESTION_TYPE] ==
            "Check Box" ||
          selectedQuestion?.[ADD_QUESTION_KEYS?.QUESTION_TYPE] ==
            "Radio Button" ? (
            <ShowAnswerCheckbox
              inputType={
                selectedQuestion?.[ADD_QUESTION_KEYS?.QUESTION_TYPE] ==
                "Check Box"
                  ? "checkbox"
                  : "radio"
              }
              fields={selectedQuestion}
            />
          ) : (
            <div>
              {AnswerText ? (
                <h6 className="vendor-proposal-question-list-question--question-text">
                  {selectedQuestion?.[ADD_QUESTION_KEYS?.QUESTION_TYPE] ==
                  "Date Picker"
                    ? moment(AnswerText * 1000).format("DD MMM yyyy")
                    : AnswerText}
                </h6>
              ) : (
                <h6 className="vendor-proposal-question-list-question--question-text--not-answered">
                  Not Answered
                </h6>
              )}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default VendorResponse;
