import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useSelector } from "react-redux";
import LikeIcon from "../../../../utlis/hoc/engagementBar/components/likeIcon";
import { useState } from "react";
import BookmarkIcon from "../../../../utlis/hoc/engagementBar/components/bookmarkIcon";
import SignInModal from "../../../../utlis/hoc/signInModal/signInModal";
import * as CONFIG_KEYS from "../../../../config/config";
import moment from "moment";
import BoardIcon from "../../../../utlis/hoc/engagementBar/components/boardIcon";
import ShareIcon from "../../../../utlis/hoc/engagementBar/components/shareIcon";
import UseCaseSocial from "./useCaseSocial";
import { isMobileOnly } from "react-device-detect";
const UseCaseCardTopEngagement = (props) => {
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(true);
  const ENGAGEMENT_DATA = props?.data;
  const cardType = props?.cardType;
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const BoardPermissions = allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS];
  const CanUpdateBoard =
    allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS] && props?.page == "business profile" ? BoardPermissions?.Update : true;
  const tabIndex = props?.tabIndex;
  const permissionsDetails = props?.permissionsDetails;
  const DATA = props?.data;
  const CreatedAt = DATA?.Created_At ? moment(DATA?.Created_At * 1000).format("DD MMM YYYY hh:mm ") : "";
  const UpdatedAt = DATA?.Modify_At ? moment(DATA?.Modify_At * 1000).format("DD MMM YYYY hh:mm ") : CreatedAt ? CreatedAt : "";

  return (
    <div className="flex space-between flex-wrap" style={{ marginTop: "20px" }}>
      <div className="engagement-bar-top-container">
        <>
          <div
            className="engagement-bar-items"
            role="button"
            onClick={() => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })}
            data-tooltip={"Comment"}
            data-inverted="true"
          >
            <FeatherIcon icon="message-square" size={15} color={"#323232"} />
            {/* <p className="engagement-bar-items-count" style={{ color: "#606060" }}>
          {Math.floor(Math.random() * 50) + 1}
        </p> */}
            <p className="engagement-bar-items-count">{ENGAGEMENT_DATA?.Comment_Count ? ENGAGEMENT_DATA?.Comment_Count : null}</p>
          </div>
        </>
        <LikeIcon
          cardType={cardType}
          data={props?.data}
          page={props?.page}
          handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
          keyName={props?.keyName}
        />
        <BookmarkIcon
          handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
          cardType={cardType}
          data={props?.data}
          page={props?.page}
          engageSource={props?.engageSource}
          keyName={props?.keyName}
        />
        <ShareIcon
          shareLink={props?.shareLink}
          cardType={props?.cardType}
          data={props?.data}
          page={props?.page}
          engageSource={props?.engageSource}
          keyName={props?.keyName}
        />
        {CanUpdateBoard ? (
          <BoardIcon
            cardType={cardType}
            data={props?.data}
            page={props?.page}
            keyName={props?.keyName}
            handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
            engageSource={props?.engageSource}
          />
        ) : null}

        {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible(false)} /> : null}
        {popUpVisible && false ? (
          <div className="use-case-detail-page-engagement-popup">
            {/* <FeatherIcon
              icon={"x"}
              size={isMobileOnly ? "15" : "20"}
              className={"use-case-detail-page-engagement-close"}
              onClick={() => setPopUpVisible((prev) => !prev)}
            /> */}
            <p>Liked this {ENGAGEMENT_DATA?.Page_Type?.toLowerCase()}? Add to a board and share with your team mates.</p>

            <p className="okay-pop-up-text" onClick={() => setPopUpVisible((prev) => !prev)}>
              Got it!
            </p>
          </div>
        ) : null}
      </div>
      <div className="mobile-hidden">
        <UseCaseSocial data={DATA} />
      </div>
    </div>
  );
};

export default UseCaseCardTopEngagement;
