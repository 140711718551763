import React from "react";
import MultiSelectCustomer from "../../addUseCases/component/multiSelectCustomer";

const AddingCustomer = (props) => {
  return (
    <div
      //  style={{ width: "100%", padding: "0px 50% 0px 30px" }}
      className="company-profile-basic-details-main-container"
    >
      <div className="product-profile-basic-details-left-side-container">
        <div>
          <h5 class="company-header-sub-title" style={{ paddingBottom: "0px" }}>
            Add your Customers over here
          </h5>
        </div>
        <MultiSelectCustomer
          productBasicDetails={props?.data}
          handleDetails={props?.handleDetails}
          keyName={props?.keyName}
          validateEmailId={props?.validateEmailId}
        />
      </div>
    </div>
  );
};

export default AddingCustomer;
