import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import "../../../../styles/proposal.css";
import AddProposalPortal from "../addProposalPortal";
const ProposalHeader = (props) => {
  const [showAddProposalModal, setShowAddProposalModal] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PROPOSAL_HEADER_KEYS = KEYS?.BUYER_PROPOSAL_LANDING_PAGE;
  //   const PROPOSAL_KEYS=CONFIG_KEYS?.CONFIG?
  const handleAddProposalModal = () => {
    setShowAddProposalModal(!showAddProposalModal);
  };
  return (
    <div className="proposal-header-wrapper">
      {showAddProposalModal ? (
        <AddProposalPortal handleClose={() => setShowAddProposalModal(false)} />
      ) : null}
      <div>
        <h3 className="proposal-header-my-proposal-text">
          {PROPOSAL_HEADER_KEYS?.MY_PROPOSAL}
        </h3>
      </div>
      <div className="proposal-header-button-main-container">
        <div onClick={handleAddProposalModal} className="cursor--pointer">
          <p className="proposal-header-new-proposal-button m-x-mini">
            {PROPOSAL_HEADER_KEYS?.NEW_PROPOSAL}
          </p>
        </div>
        {/* <div>
          <p className="proposal-header-assign-proposal-button">
            {PROPOSAL_HEADER_KEYS?.ASSIGN_PROPOSAL}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default ProposalHeader;
