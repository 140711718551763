// import logo from "../../assets/images/octateActiveLogo.png";

module.exports = `<!DOCTYPE html>
<html>

<head>
  <title></title>
  <meta content="summary_large_image" name="twitter:card" />
  <meta content="website" property="og:type" />
  <meta content="" property="og:description" />
  <meta content="https://gsniradtzr.preview-postedstuff.com/V2-X3L5-3z2S-2b1V-442u/" property="og:url" />
  <meta content="https://pro-bee-beepro-thumbnail.getbee.io/messages/736995/720126/1603960/8158762_large.jpg"
    property="og:image" />
  <meta content="" property="og:title" />
  <meta content="" name="description" />
  <meta charset="utf-8" />
  <meta content="width=device-width" name="viewport" />
  <link rel="icon" type="image/x-icon/ico" href="./assets/images/OctateIcon.ico" sizes="100x100" />
  <link href="../src/styles/user-defined.css" rel="stylesheet" />
  <link href="../src/styles/application-octate.css" rel="stylesheet"/>
  <style>
    .bee-row,
    .bee-row-content {
      position: relative;
    }

    .bee-html-block {
      text-align: center;
    }

    .bee-row-1,
    .bee-row-1 .bee-row-content,
    .bee-row-2,
    .bee-row-3 {
      background-repeat: no-repeat;
    }

    body {
      background-color: transparent;
      color: #000;
      font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    }

    a {
      color: #8a3c90;
    }

    * {
      box-sizing: border-box;
    }

    body,
    h1,
    h2,
    h3,
    p {
      margin: 0;
    }

    .bee-row-content {
      max-width: 710px;
      margin: 0 auto;
      display: flex;
    }

    .bee-row-content .bee-col-w12 {
      flex-basis: 100%;
    }

    .bee-image {
      overflow: auto;
    }

    .bee-image .bee-center {
      margin: 0 auto;
    }

    .bee-row-3 .bee-col-1 .bee-block-1 {
      width: 100%;
    }

    .bee-image img {
      display: block;
      width: 100%;
    }

    @media (max-width: 730px) {
      .bee-row-content:not(.no_stack) {
        display: block;
      }
    }

    .bee-row-1 .bee-row-content {
      color: #000;
    }

    .bee-row-1 .bee-col-1,
    .bee-row-2 .bee-col-1,
    .bee-row-3 .bee-col-1 {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .bee-row-1 .bee-col-1 .bee-block-1 {
      text-align: center;
      width: 100%;
    }

    .bee-row-2 .bee-row-content,
    .bee-row-3 .bee-row-content {
      background-repeat: no-repeat;
      border-radius: 0;
      color: #000;
    }
  </style>
</head>

<body>
  <div class="page-content page-content--reset" >
    <div class="splitted-page">
      <div class="splitted-page__aside" style="position: relative; height: 100vh;">

        <div class="signUp-vertical-line"></div>
        <div class="splitted-page__container signup-split-container">
          <div></div>
          <div class="signUp-image-container">
            <img class="octateImage" src=${"https://octate.ai/images/octate_logo/octateLogo.svg"} alt="Octate logo" style="width: 70px"/>
            <p class="subText-signup">World's largest platform of curated content</p>
            <p class="subText-signup">for solution discovery.</p>
          </div>
          <div>
            <div class="signUp-footer">
              <p class="subText-signup">Discover Products</p>
            </div>
            <div class="signUp-footer">
              <p class="subText-signup">Follow Topics</p>
            </div>
            <div class="signUp-footer">
              <p class="subText-signup">Hello Curate Boards</p>
            </div>
          </div>
      </div>
      </div>

      <div class="bee-page-container" style="height: 100vh; overflow: scroll; width: 68%;">
  <div class="bee-page-container">
    <div class="bee-row bee-row-1">
      <div class="bee-row-content">
        <div class="bee-col bee-col-1 bee-col-w12">
          <div class="bee-block bee-block-1 bee-heading">
            <h1 style="
                  color: #8a3c90;
                  direction: ltr;
                  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                  font-size: 38px;
                  font-weight: 700;
                  letter-spacing: normal;
                  line-height: 120%;
                  text-align: center;
                  margin-top: 0;
                  margin-bottom: 0;
                ">
              <span class="tinyMce-placeholder">Privacy Policy</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bee-row bee-row-2">
      <div class="bee-row-content">
        <div class="bee-col bee-col-1 bee-col-w12">
          <div class="bee-block bee-block-1 bee-spacer">
            <div class="spacer" style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bee-row bee-row-3">
      <div class="bee-row-content">
        <div class="bee-col bee-col-1 bee-col-w12">
          <div class="bee-block bee-block-1 bee-image">
            <img alt="" class="bee-center bee-autowidth"
              src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/736995_720126/octateai_v2.0.svg"
              style="max-width: 250px" />
          </div>
          <div class="bee-block bee-block-2 bee-spacer">
            <div class="spacer" style="height: 25px"></div>
          </div>
          <div class="bee-block bee-block-3 bee-html-block">
            <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
            <meta content="Microsoft Word 15 (filtered)" name="Generator" />
            <style>
              <!--
              /* Font Definitions */
              @font-face {
                font-family: "Cambria Math";
                panose-1: 2 4 5 3 5 4 6 3 2 4;
              }

              @font-face {
                font-family: Calibri;
                panose-1: 2 15 5 2 2 2 4 3 2 4;
              }

              @font-face {
                font-family: Verdana;
                panose-1: 2 11 6 4 3 5 4 4 2 4;
              }

              @font-face {
                font-family: "Segoe UI";
                panose-1: 2 11 5 2 4 2 4 2 2 3;
              }

              /* Style Definitions */
              p.MsoNormal,
              li.MsoNormal,
              div.MsoNormal {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                text-align: justify;
                line-height: 150%;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
              }

              h1 {
                mso-style-link: "Heading 1 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                text-align: justify;
                text-indent: -35.45pt;
                line-height: 150%;
                page-break-after: avoid;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
                color: black;
                text-transform: uppercase;
              }

              h2 {
                mso-style-link: "Heading 2 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                text-align: justify;
                text-indent: -35.45pt;
                line-height: 150%;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
                color: black;
                font-weight: normal;
              }

              h3 {
                mso-style-link: "Heading 3 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                text-align: justify;
                text-indent: -35.45pt;
                line-height: 150%;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
                color: black;
                font-weight: normal;
              }

              h4 {
                mso-style-link: "Heading 4 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                text-align: justify;
                text-indent: -35.45pt;
                line-height: 150%;
                font-size: 10.0pt;
                font-family: "Verdana", sans-serif;
                color: black;
                font-weight: normal;
              }

              h5 {
                mso-style-link: "Heading 5 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 35.45pt;
                text-align: justify;
                text-indent: -70.9pt;
                line-height: 150%;
                font-size: 10.0pt;
                font-family: "Verdana", sans-serif;
                color: black;
                font-weight: normal;
              }

              h6 {
                mso-style-link: "Heading 6 Char";
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 35.45pt;
                text-align: justify;
                text-indent: -70.9pt;
                line-height: 150%;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
                font-weight: normal;
              }

              p.MsoHeading7,
              li.MsoHeading7,
              div.MsoHeading7 {
                mso-style-link: "Heading 7 Char";
                margin-top: 2.0pt;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .9in;
                text-align: justify;
                text-indent: -.9in;
                line-height: 150%;
                page-break-after: avoid;
                font-size: 12.0pt;
                font-family: "Calibri Light", sans-serif;
                color: #1F3763;
                font-style: italic;
              }

              p.MsoHeading8,
              li.MsoHeading8,
              div.MsoHeading8 {
                mso-style-link: "Heading 8 Char";
                margin-top: 2.0pt;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: 1.0in;
                text-align: justify;
                text-indent: -1.0in;
                line-height: 150%;
                page-break-after: avoid;
                font-size: 10.5pt;
                font-family: "Calibri Light", sans-serif;
                color: #272727;
              }

              p.MsoHeading9,
              li.MsoHeading9,
              div.MsoHeading9 {
                mso-style-link: "Heading 9 Char";
                margin-top: 2.0pt;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: 1.1in;
                text-align: justify;
                text-indent: -1.1in;
                line-height: 150%;
                page-break-after: avoid;
                font-size: 10.5pt;
                font-family: "Calibri Light", sans-serif;
                color: #272727;
                font-style: italic;
              }

              p.MsoToc1,
              li.MsoToc1,
              div.MsoToc1 {
                margin-top: 6.0pt;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: 0in;
                line-height: 150%;
                font-size: 12.0pt;
                font-family: "Calibri", sans-serif;
                font-weight: bold;
                font-style: italic;
              }

              p.MsoFooter,
              li.MsoFooter,
              div.MsoFooter {
                mso-style-link: "Footer Char";
                margin: 0in;
                text-align: justify;
                font-size: 12.0pt;
                font-family: "Verdana", sans-serif;
              }

              a:link,
              span.MsoHyperlink {
                color: #0563C1;
                text-decoration: underline;
              }

              p.MsoListParagraph,
              li.MsoListParagraph,
              div.MsoListParagraph {
                margin-top: 6.0pt;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: .5in;
                line-height: 150%;
                font-size: 11.0pt;
                font-family: "Calibri", sans-serif;
              }

              p.MsoListParagraphCxSpFirst,
              li.MsoListParagraphCxSpFirst,
              div.MsoListParagraphCxSpFirst {
                margin-top: 6.0pt;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                line-height: 150%;
                font-size: 11.0pt;
                font-family: "Calibri", sans-serif;
              }

              p.MsoListParagraphCxSpMiddle,
              li.MsoListParagraphCxSpMiddle,
              div.MsoListParagraphCxSpMiddle {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                line-height: 150%;
                font-size: 11.0pt;
                font-family: "Calibri", sans-serif;
              }

              p.MsoListParagraphCxSpLast,
              li.MsoListParagraphCxSpLast,
              div.MsoListParagraphCxSpLast {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: .5in;
                line-height: 150%;
                font-size: 11.0pt;
                font-family: "Calibri", sans-serif;
              }

              span.Heading1Char {
                mso-style-name: "Heading 1 Char";
                mso-style-link: "Heading 1";
                font-family: "Verdana", sans-serif;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
              }

              span.FooterChar {
                mso-style-name: "Footer Char";
                mso-style-link: Footer;
                font-family: "Verdana", sans-serif;
              }

              .MsoChpDefault {
                font-family: "Calibri", sans-serif;
              }

              .MsoPapDefault {
                margin-top: 6.0pt;
                margin-right: 0in;
                margin-bottom: 6.0pt;
                margin-left: 0in;
                line-height: 150%;
              }

              /* Page Definitions */
              @page WordSection1 {
                size: 595.3pt 841.9pt;
                margin: 1.0in 1.0in 1.0in 1.0in;
                border: solid windowtext 1.5pt;
                padding: 24.0pt 24.0pt 24.0pt 24.0pt;
              }

              div.WordSection1 {
                page: WordSection1;
              }

              /* List Definitions */
              ol {
                margin-bottom: 0in;
              }

              ul {
                margin-bottom: 0in;
              }
              -->
            </style>
            <div class="WordSection1">
              <h1 style="margin-left: 35.45pt">
                <span style="line-height: 150%">1<span style="font: 7pt 'Times New Roman'">           
                  </span></span><a name="_Toc113455538"><span lang="EN-IN">INTRODUCTION</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">We are delighted that you have shown interest in the
                  services of Octate.ai (hereinafter referred to as “Octate”,
                  “we”, “us”, “our”), which are provided </span><span style="line-height: 150%">via </span><span
                  lang="EN-IN"><a href="https://Octate.ai/"><span lang="EN-US"
                      style="line-height: 150%">https://Octate.ai/</span></a></span><span style="line-height: 150%">
                  and the services provided through the website and through
                  our software, APIs, or otherwise and mobile website and
                  mobile applications related, linked, or otherwise connected
                  thereto (collectively the “Platform”)</span><span style="line-height: 150%">. Data protection is a
                  particularly high priority for
                  Octate. The use of the Platform of Octate is possible
                  without any indication of personal data; however, if a data
                  subject wants to use special services via our Platform,
                  processing of personal data may become necessary. If the
                  processing of personal data is necessary, and there is no
                  statutory basis for such processing, we generally obtain
                  consent from the data subject.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The processing of personal data, such as the name, address,
                  email address, or telephone number of a data subject shall
                  always be in line with the General Data Protection
                  Regulation (GDPR), California Consumer Privacy Act (CCPA),
                  and in accordance with the country-specific data protection
                  regulations applicable to Octate. Utilizing this data
                  protection declaration, our enterprise would like to inform
                  the general public of the nature, scope, and purpose of the
                  personal data we collect, use, and process. Furthermore,
                  data subjects are informed through this data protection
                  declaration of the rights to which they are entitled.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">As the controller, Octate has implemented numerous
                  technical and organizational measures to ensure the complete
                  protection of personal data processed through this Platform.
                  However, Internet-based data transmissions may, in
                  principle, have security gaps, so absolute protection may
                  not be guaranteed. For this reason, every data subject is
                  free to transfer personal data to us via alternative means,
                  e.g., by telephone.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455539">2<span style="font: 7pt 'Times New Roman'">            </span><span
                    style="text-transform: none">DEFINITIONS</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">The data protection declaration of Octate is based on the
                  terms used by the European legislator for the adoption of
                  the General Data Protection Regulation (GDPR). Our data
                  protection declaration should be legible and understandable
                  for the general public, as well as our customers and
                  business partners. To ensure this, we would like to first
                  explain the terminology used. In this data protection
                  declaration, we use, among other things, the following
                  terms:</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">A)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Personal data</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Personal data means any information relating to an
                  identified or identifiable natural person (“data subject”).
                  An identifiable natural person is one who can be identified,
                  directly or indirectly, in particular by reference to an
                  identifier such as a name, an identification number,
                  location data, an online identifier, or to one or more
                  factors specific to the physical, physiological, genetic,
                  mental, economic, cultural, or social identity of that
                  natural person.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">B)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Data subject</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Data subject is any identified or identifiable natural
                  person whose personal data is processed by the controller
                  responsible for the processing.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">C)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Processing</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Processing is any operation or set of operations that are
                  performed on personal data or on sets of personal data,
                  whether or not by automated means, such as collection,
                  recording, organization, structuring, storage, adaptation or
                  alteration, retrieval, consultation, use, disclosure by
                  transmission, dissemination or otherwise making available,
                  alignment or combination, restriction, erasure, or
                  destruction.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">D)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Restriction of processing</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Restriction of processing is the marking of stored personal
                  data with the aim of limiting their processing in the
                  future.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">E)<span style="font: 7pt 'Times New Roman'">  
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Profiling</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Profiling means any form of automated processing of
                  personal data consisting of the use of personal data to
                  evaluate certain personal aspects relating to a natural
                  person, in particular, to analyze or predict aspects
                  concerning that natural person’s performance at work,
                  economic situation, health, personal preferences, interests,
                  reliability, behavior, location or movements.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">F)<span style="font: 7pt 'Times New Roman'">  
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Pseudonymization</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Pseudonymization is the processing of personal data in such
                  a manner that the personal data can no longer be attributed
                  to a specific data subject without the use of additional
                  information, provided that such additional information is
                  kept separately and is subject to technical and
                  organizational measures to ensure that the personal data are
                  not attributed to an identified or identifiable natural
                  person.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">G)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Controller or controller responsible for the
                  processing</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Controller or controller responsible for the processing is
                  the natural or legal person, public authority, agency, or
                  other body which, alone or jointly with others, determines
                  the purposes and means of the processing of personal data;
                  where the purposes and means of such processing are
                  determined by Union or Member State law, the controller or
                  the specific criteria for its nomination may be provided for
                  by Union or Member State law.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">H)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Processor</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Processor is a natural or legal person, public authority,
                  agency, or other body which processes personal data on
                  behalf of the controller.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">I)<span style="font: 7pt 'Times New Roman'">   
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Recipient</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Recipient is a natural or legal person, public authority,
                  agency, or another body, to which the personal data are
                  disclosed, whether a third party or not. However, public
                  authorities which may receive personal data in the framework
                  of a particular inquiry in accordance with Union or Member
                  State law shall not be regarded as recipients; the
                  processing of those data by those public authorities shall
                  be in compliance with the applicable data protection rules
                  according to the purposes of the processing.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">J)<span style="font: 7pt 'Times New Roman'">  
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Third-party</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Third-party is a natural or legal person, public authority,
                  agency, or body other than the data subject, controller,
                  processor, and persons who, under the direct authority of
                  the controller or processor, are authorized to process
                  personal data.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">K)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Consent</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Consent of the data subject is any freely given, specific,
                  informed, and unambiguous indication of the data subject’s
                  wishes by which he or she, by a statement or by clear
                  affirmative action, signifies agreement to the processing of
                  personal data relating to him or her.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455542">3<span style="font: 7pt 'Times New Roman'">            </span><span
                    style="text-transform: none">COLLECTION OF GENERAL DATA AND INFORMATION</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">The Platform of Octate collects a series of general data
                  and information when a data subject or automated system
                  calls up the Platform. This general data and information are
                  stored in the server log files. The general data collected
                  may be (1) the browser types and versions used, (2) the
                  operating system used by the accessing system, (3) the
                  website from which an accessing system reaches our Platform
                  (so-called referrers), (4) the sub-websites, (5) the date
                  and time of access to the Platform, (6) an Internet protocol
                  address (IP address), (7) the Internet service provider of
                  the accessing system, (8)</span>
                <span style="line-height: 150%">names, (9) email address, (10) Payment method details such
                  as credit card details (11) phone numbers and, (12) any
                  other similar data and information that may be used in the
                  event of attacks on our information technology systems. In
                  addition to the abovementioned general data, we also collect
                  the following information (1) First Name, (2) Last Name, (3)
                  Email Address, (4) Phone Number, (5) Country, (6) Language,
                  (7) Company, (8)</span>
                <span style="line-height: 150%">
                  Job Title, (9) Job Role, (10) Profile Image.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">All personal information that you provide to us must be
                  true, complete, and accurate, and you must notify us of any
                  changes to such personal information.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">When using these general data and information, Octate does
                  not draw any conclusions about the data subject. Rather,
                  this information is needed to (1) deliver the content of our
                  Platform correctly, (2) optimize the content of our Platform
                  as well as its advertisement, (3) ensure the long-term
                  viability of our information technology systems and website
                  technology, (4) for analytics purposes, (5) to facilitate
                  account creation, and logon process, (6) request feedback
                  (7) as necessary for the basic functionality of the Platform
                  and account management (billing our clients) and, (8)
                  provide law enforcement authorities with the information
                  necessary for criminal prosecution in case of a
                  cyber-attack. Therefore, Octate analyses anonymously
                  collected data and information statistically, with the aim
                  of increasing the data protection and data security of our
                  enterprise and to achieve an optimal level of protection for
                  the personal data we process. The anonymous data of the
                  server log files are stored separately from all personal
                  data provided by a data subject.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455543">4<span style="font: 7pt 'Times New Roman'">            </span>CONTACT
                  POSSIBILITY VIA THE </a><span style="line-height: 150%">Platform</span>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">The Platform of Octate contains information that enables
                  quick electronic contact to our enterprise, as well as
                  direct communication with us, which also includes a general
                  address of the so-called electronic mail (email address). If
                  a data subject contacts the controller by email or via a
                  contact form, the personal data transmitted by the data
                  subject are automatically stored. Such personal data
                  transmitted on a voluntary basis by a data subject to the
                  data controller are stored for the purpose of processing or
                  contacting the data subject. There is no transfer of this
                  personal data to third parties.</span>
              </p>
              <p class="MsoNormal">
                <b><span style="line-height: 150%">Right to Delete.</span></b><span style="line-height: 150%">
                  You have the right to request that we delete the personal
                  information we have collected from you (and direct our
                  service providers to do the same).</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If you are under 18 years of age, reside in California, and
                  have a registered account with the Platform, you have the
                  right to request the removal of unwanted data that you
                  publicly post on the Platform. To request the removal of
                  such data, please contact us using the contact information
                  provided below, and include the email address associated
                  with your account and a statement that you reside in
                  California. We will make sure the data is not publicly
                  displayed on the Platform, but please be aware that the data
                  may not be completely or comprehensively removed from all
                  our systems (e.g., backups, etc.).</span>
              </p>
              <p class="MsoNormal">
                <b><span style="line-height: 150%">Other Rights.</span></b><span style="line-height: 150%">
                  California Civil Code Section 1798.83, also known as the
                  “Shine The Light” law, permits our users who are California
                  residents to request and obtain from us, once a year and
                  free of charge, information about categories of personal
                  information (if any) we disclosed to third parties for
                  direct marketing purposes and the names and addresses of all
                  third parties with which we shared personal information in
                  the immediately preceding calendar year. If you are a
                  California resident and would like to make such a request,
                  please submit your request in writing to us using the
                  contact information provided in this document.
                </span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Certain information may be exempt from such requests under
                  applicable law. For example, we need certain types of
                  information so that we can provide the Platform to you. If
                  you ask us to delete it, you may no longer be able to access
                  or use the Platform.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">To exercise your rights under the California Consumer
                  Privacy Act, please contact us by either sending us an
                  e-mail at </span><span lang="EN-IN"><a href="mailto:privacy-support@octate.ai"><span lang="EN-US"
                      style="line-height: 150%">privacy-support@octate.ai</span></a></span><span
                  style="line-height: 150%">. Please provide your full name and e-mail address so that
                  we may respond to your request as quickly as possible. You
                  may be required to verify your identify before we fulfill
                  your request. To do so, you will need to provide us with
                  certain account information, such as your full name and
                  email address. You can also designate an authorized agent to
                  make a request on your behalf. To do so, you must provide us
                  with written authorization for the agent to act on your
                  behalf. You will still need to verify your identity directly
                  with us.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455545"><span lang="EN-IN">5<span style="font: 7pt 'Times New Roman'">           
                    </span></span><span lang="EN-IN">Security</span></a>
              </h1>
              <p class="MsoNormal" style="margin-top: 6pt">
                <span lang="EN-IN" style="line-height: 150%">This </span><span style="line-height: 150%">Platform
                </span><span lang="EN-IN" style="line-height: 150%">ensures that data is encrypted when leaving the
                </span><span style="line-height: 150%">Platform</span><span lang="EN-IN" style="line-height: 150%">.
                  This process involves the converting of information or
                  data into a code to prevent unauthorized access. This </span><span style="line-height: 150%">Platform
                </span><span lang="EN-IN" style="line-height: 150%">follows this process and employs secure methods to
                  ensure
                  the protection of all credit and debit card transactions.
                  Encryption methods such as SSL are utilized to protect
                  customer data when in transit to and from this </span><span style="line-height: 150%">Platform
                </span><span lang="EN-IN" style="line-height: 150%">over a secure communications channel.</span>
              </p>
              <p class="MsoNormal" style="margin-top: 6pt">
                <span lang="EN-IN" style="line-height: 150%">Whilst we do everything within our power to ensure that
                  personal data is protected at all times from our </span><span
                  style="line-height: 150%">Platform</span><span lang="EN-IN" style="line-height: 150%">, we cannot
                  guarantee the security and integrity of the
                  information that has been transmitted to our </span><span
                  style="line-height: 150%">Platform</span><span lang="EN-IN" style="line-height: 150%">.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455546">6<span style="font: 7pt 'Times New Roman'">            </span><span
                    style="text-transform: none">ROUTINE ERASURE AND BLOCKING OF PERSONAL DATA</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">The data controller shall process and store the personal
                  data of the data subject only for the period necessary to
                  achieve the purpose of storage, or as far as this is granted
                  by the European legislator or other legislators in laws or
                  regulations to which the controller is subject.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If the storage purpose is not applicable, or if a storage
                  period prescribed by the European legislator or another
                  competent legislator expires, the personal data are
                  routinely blocked or erased in accordance with legal
                  requirements.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455547">7<span style="font: 7pt 'Times New Roman'">            </span><span
                    style="text-transform: none">RIGHTS OF THE DATA SUBJECT</span></a>
              </h1>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">A)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right of confirmation</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to obtain from the controller the
                  confirmation as to whether or not personal data concerning
                  him or her are being processed. If a data subject wishes to
                  avail himself of this right of confirmation, he or she may,
                  at any time, contact us or another employee of the
                  controller.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">B)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right of access</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to obtain from the controller free
                  information about his or her personal data stored at any
                  time and a copy of this information. Furthermore, the
                  European directives and regulations grant the data subject
                  access to the following information: the purposes of the
                  processing; the categories of personal data concerned; the
                  recipients or categories of recipients to whom the personal
                  data have been or will be disclosed, in particular
                  recipients in third countries or international
                  organizations; where possible, the envisaged period for
                  which the personal data will be stored, or, if not possible,
                  the criteria used to determine that period; the existence of
                  the right to request from the controller rectification or
                  erasure of personal data, or restriction of processing of
                  personal data concerning the data subject, or to object to
                  such processing; the existence of the right to file a
                  complaint with a supervisory authority; where the personal
                  data are not collected from the data subject, any available
                  information as to their source; the existence of automated
                  decision-making, including profiling, referred to in Article
                  22(1) and (4) of the GDPR and, at least in those cases,
                  meaningful information about the logic involved, as well as
                  the significance and envisaged consequences of such
                  processing for the data subject.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Furthermore, the data subject shall have a right to obtain
                  information as to whether personal data are transferred to a
                  third country or an international organization. Where this
                  is the case, the data subject shall have the right to be
                  informed of the appropriate safeguards relating to the
                  transfer.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If a data subject wishes to avail himself of this right of
                  access, he or she may at any time contact us or another
                  employee of the controller.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">C)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right to rectification</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to obtain from the controller without
                  undue delay the rectification of inaccurate personal data
                  concerning him or her. Taking into account the purposes of
                  the processing, the data subject shall have the right to
                  have incomplete personal data completed, including by means
                  of providing a supplementary statement.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If a data subject wishes to exercise this right to
                  rectification, he or she may, at any time, contact us or
                  another employee of the controller.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">D)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right to erasure (Right to be forgotten)</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to obtain from the controller the
                  erasure of personal data concerning him or her without undue
                  delay, and the controller shall have an obligation to erase
                  personal data without undue delay where one of the following
                  grounds applies, as long as the processing is not
                  necessary:</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The personal data is no longer necessary in relation to the
                  purposes for which they were collected or otherwise
                  processed.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The data subject withdraws consent to which the processing
                  is based according to point (a) of Article 6(1) of the GDPR
                  or point (a) of Article 9(2) of the GDPR, and where there is
                  no other legal ground for the processing.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The data subject objects to the processing in accordance
                  with Article 21(1) of the GDPR, and there are no overriding
                  legitimate grounds for the processing, or the data subject
                  objects to the processing pursuant to Article 21(2) of the
                  GDPR.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The personal data have been unlawfully processed.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The personal data must be erased for compliance with a
                  legal obligation in Union or Member State law to which the
                  controller is subject.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The personal data have been collected in relation to the
                  offer of information society services referred to in Article
                  8(1) of the GDPR.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If one of the aforementioned reasons applies, and a data
                  subject wishes to request the erasure of personal data
                  stored by Octate, he or she may at any time contact us or
                  another employee of the controller. Octate or another
                  employee shall promptly ensure that the erasure request is
                  complied with immediately.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Where the controller has made personal data public and is
                  obliged pursuant to Article 17(1) to erase the personal
                  data, the controller, taking account of available technology
                  and the cost of implementation, shall take reasonable steps,
                  including technical measures, to inform other controllers
                  processing the personal data that the data subject has
                  requested the erasure by such controllers of any links to,
                  or copy or replication of, those personal data, as far as
                  processing is not required. Octate or another employee will
                  arrange the necessary measures in individual cases.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">E)<span style="font: 7pt 'Times New Roman'">  
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right of restriction of processing</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to obtain from the controller
                  restriction of processing where one of the following
                  applies:</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The accuracy of the personal data is contested by the data
                  subject for a period enabling the controller to verify the
                  accuracy of the personal data.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The processing is unlawful, and the data subject opposes
                  the erasure of the personal data and requests the
                  restriction of their use instead.</span>
              </p>
              <p class="MsoNormal">
                The controller no longer needs the personal data for the
                processing, but they are required by the data subject for the
                establishment, exercise, or Defense of legal claims.
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">The data subject has objected to processing pursuant to
                  Article 21(1) of the GDPR pending the verification whether
                  the legitimate grounds of the controller override those of
                  the data subject.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If one of the conditions mentioned above is met, and a data
                  subject wishes to request the restriction of the processing
                  of personal data stored by Octate, he or she may at any time
                  contact us or another employee of the controller. Octate or
                  another employee will arrange the restriction of the
                  processing.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">F)<span style="font: 7pt 'Times New Roman'">  
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right to data portability</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to receive the personal data concerning
                  him or her, which was provided to a controller in a
                  structured, commonly used, and machine-readable format. He
                  or she shall have the right to transmit those data to
                  another controller without hindrance from the controller to
                  which the personal data have been provided. As long as the
                  processing is based on consent pursuant to point (a) of
                  Article 6(1) of the GDPR or point (a) of Article 9(2) of the
                  GDPR, or on a contract pursuant to point (b) of Article 6(1)
                  of the GDPR, and the processing is carried out by automated
                  means, as long as the processing is not necessary for the
                  performance of a task carried out in the public interest or
                  in the exercise of official authority vested in the
                  controller.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Furthermore, in exercising his or her right to data
                  portability pursuant to Article 20(1) of the GDPR, the data
                  subject shall have the right to have personal data
                  transmitted directly from one controller to another, where
                  technically feasible and when doing so does not adversely
                  affect the rights and freedoms of others.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">In order to assert the right to data portability, the data
                  subject may at any time contact Octate or another
                  employee.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">G)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right to object</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to object, on grounds relating to his or
                  her particular situation, at any time, to the processing of
                  personal data concerning him or her, which is based on point
                  (e) or (f) of Article 6(1) of the GDPR. This also applies to
                  profiling based on these provisions.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Octate</span> shall no longer
                process the personal data in the event of the objection unless
                we can demonstrate compelling legitimate grounds for the
                processing which override the interests, rights, and freedoms
                of the data subject or for the establishment, exercise, or
                Defense of legal claims.
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If Octate processes personal data for direct marketing
                  purposes, the data subject shall have the right to object at
                  any time to the processing of personal data concerning him
                  or her for such marketing. This applies to profiling to the
                  extent that it is related to such direct marketing. If the
                  data subject objects to Octate to the processing for direct
                  marketing purposes, Octate will no longer process the
                  personal data for these purposes.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">In addition, the data subject has the right, on grounds
                  relating to his or her particular situation, to object to
                  the processing of personal data concerning him or her by
                  Octate for scientific or historical research purposes or for
                  statistical purposes pursuant to Article 89(1) of the GDPR,
                  unless the processing is necessary for the performance of a
                  task carried out for reasons of public interest.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">In order to exercise the right to object, the data subject
                  may directly contact Octate or another employee. In
                  addition, the data subject is free in the context of the use
                  of information society services, and notwithstanding
                  Directive 2002/58/EC, to use his or her right to object by
                  automated means using technical specifications.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">H)<span style="font: 7pt 'Times New Roman'">  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Automated individual decision-making, including
                  profiling</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator not to be subject to a decision based
                  solely on automated processing, including profiling, which
                  produces legal effects concerning him or her, or similarly
                  significantly affects him or her, as long as the decision
                  (1) is not is necessary for entering into, or the
                  performance of, a contract between the data subject and a
                  data controller, or (2) is not authorized by Union or Member
                  State law to which the controller is subject and which also
                  lays down suitable measures to safeguard the data subject’s
                  rights and freedoms and legitimate interests, or (3) is not
                  based on the data subject’s explicit consent.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If the decision (1) is necessary for entering into, or the
                  performance of, a contract between the data subject and a
                  data controller, or (2) it is based on the data subject’s
                  explicit consent, Octate shall implement suitable measures
                  to safeguard the data subject’s rights and freedoms and
                  legitimate interests, at least the right to obtain human
                  intervention on the part of the controller, to express his
                  or her point of view and contest the decision.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If the data subject wishes to exercise the rights
                  concerning automated individual decision-making, he or she
                  may at any time directly contact Octate or another employee
                  of the controller.</span>
              </p>
              <p class="MsoListParagraph" style="
                    margin-left: 0.25in;
                    text-align: justify;
                    text-indent: -0.25in;
                  ">
                <span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">I)<span style="font: 7pt 'Times New Roman'">   
                  </span></span><span style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                    ">Right to withdraw data protection consent</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">Each data subject shall have the right granted by the
                  European legislator to withdraw his or her consent to the
                  processing of his or her personal data at any time.</span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%">If the data subject wishes to exercise the right to
                  withdraw the consent, he or she may at any time directly
                  contact Octate or another employee of the controller. </span>
              </p>
              <p class="MsoNormal">
                <span style="line-height: 150%"> </span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455551">8<span style="font: 7pt 'Times New Roman'">            </span>LEGAL BASIS FOR
                  THE PROCESSING</a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">Art. 6(1) lit. a GDPR serves as the legal basis for
                  processing operations for which we obtain consent for a
                  specific processing purpose. If the processing of personal
                  data is necessary for the performance of a contract to which
                  the data subject is a party, as is the case, for example,
                  when processing operations are necessary for the supply of
                  goods or to provide any other service, the processing is
                  based on Article 6(1) lit. b GDPR. The same applies to such
                  processing operations, which are necessary for carrying out
                  pre-contractual measures, for example, in the case of
                  inquiries concerning our services. If we are subject to a
                  legal obligation by which processing of personal data is
                  required, such as for the fulfillment of tax obligations,
                  the processing is based on Art. 6(1) lit. c GDPR. In rare
                  cases, the processing of personal data may be necessary to
                  protect the vital interests of the data subject or another
                  natural person. Then the processing would be based on Art.
                  6(1) lit. d GDPR. Finally, processing operations could be
                  based on Article 6(1) lit. f GDPR. This legal basis is used
                  for processing operations that are not covered by any of the
                  abovementioned legal grounds if the processing is necessary
                  for the purposes of the legitimate interests pursued by a
                  third party or by us, except where such interests are
                  overridden by the interests or fundamental rights and
                  freedoms of the data subject which require protection of
                  personal data. Such processing operations are particularly
                  permissible because they have been specifically mentioned by
                  the European legislator. He considered that a legitimate
                  interest could be assumed if the data subject is a client of
                  the controller (Recital 47 Sentence 2 GDPR).</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455552">9<span style="font: 7pt 'Times New Roman'">            </span><span
                    style="text-transform: none">THE LEGITIMATE INTERESTS PURSUED BY THE CONTROLLER OR BY
                    A THIRD PARTY</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">Where the processing of personal data is based on Article
                  6(1) lit. f GDPR our legitimate interest is to carry out our
                  business in favor of the well-being of all our employees and
                  the shareholders.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455553">10<span style="font: 7pt 'Times New Roman'">        </span><span
                    style="text-transform: none">PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">The criteria used to determine the period of storage of
                  personal data is the respective statutory retention period.
                  After the expiration of that period, the corresponding data
                  is routinely deleted, as long as it is no longer necessary
                  for the fulfillment of the contract or the initiation of a
                  contract. If the data subject requests for termination or
                  terminates his Octate Account, the personal data shall be
                  retained for a period of 1 year from </span><span class="MsoCommentReference"><span lang="EN-IN"
                    style="line-height: 150%">the</span></span><span class="MsoCommentReference"><span lang="EN-IN"
                    style="font-size: 8pt; line-height: 150%">
                  </span></span><span style="line-height: 150%">date of termination.</span>
              </p>
              <h1 style="margin-left: 35.45pt">
                <a name="_Toc113455554">11<span style="font: 7pt 'Times New Roman'"> </span><span style="text-transform: none">PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT; REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE TO PROVIDE SUCH DATA</span></a>
              </h1>
              <p class="MsoNormal">
                <span style="line-height: 150%">We clarify that the provision of personal data is partly required by law (e.g., tax regulations) or can also result from contractual provisions (e.g., information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when we sign a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded. Before personal data is provided by the data subject, the data subject must contact us. We will clarify to the data subject whether the provision of the personal data is required by law, contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data, and the consequences of non-provision of the personal data.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>
</body>

</html>`;
