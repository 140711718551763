import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../../config/config";
// import InputFieldQuestionTypeList from "./inputfFieldQuestionTypeList";

const ShowAnswerCheckbox = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const proposalQuestionFormError = props?.proposalQuestionFormError;
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const PROPOSAL_ADD_QUESTION = KEYS?.PROPOSAL_ADD_QUESTION;
  const fields = props?.fields;
  return (
    <div
      style={{ maxHeight: "400px", overflow: "auto", overflowX: "hidden" }}
      className="p-y-sm"
    >
      {fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
        ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.map(
            (list, index) => {
              return (
                <div className="add-question-type-main-container--proposal-answer m-b-md m-x-sm">
                  <input
                    type={props?.inputType ? props?.inputType : "checkbox"}
                    className="checkbox-input-style-proposal-add-question-type-list p-x-base"
                    checked={
                      list?.[ADD_QUESTION_CONFIG_KEYS?.ANSWER] ? true : false
                    }
                  ></input>
                  <p className="proposal-show-question-list-question-options-title">
                    {list?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]}
                  </p>
                </div>
              );
            }
          )
        : null}
    </div>
  );
};

export default ShowAnswerCheckbox;
