import React, { memo, useEffect, useState } from "react";
import { useParams, useResolvedPath } from "react-router";
// import SearchNavHeader from "./components/seachHeader/searchHeader";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import HeaderAuth from "../authHome/header/header";
// import { PaginatedItems } from "./components/pagination/paginationReact";
// import SearchResultFilter from "./components/filter/searchResultFilter";
import { useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import _ from "lodash";
import ProviderCard from "../../utlis/hoc/providerCard/providerCard";
import ListCount from "../../utlis/hoc/productCard/listCount";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
import HeaderSEO from "../SEO/header";
import SelectedFilterList from "../searchResult/components/filter/selectedFilterList";
import { MobilePaginatedItems } from "../searchResult/components/pagination/paginationMobileView";
import FilterMobileView from "../searchResult/components/filter/mobile/filterMobileView";
import ProductSkeleton from "../searchResult/components/skeleton/productSkeleton";
import { PaginatedItems } from "../searchResult/components/pagination/paginationReact";
import SearchResultFilter from "../searchResult/components/filter/searchResultFilter";
import SearchNavHeader from "../searchResult/components/seachHeader/searchHeader";
import {
  DecryptId,
  EncryptId,
  GetIdFormLink,
  ReplaceBlankSpaceByHyphen,
  ToTitleCase,
} from "../../utlis/hof/hof";
import BlurbCardLarge from "../../utlis/hoc/blurbCard/blurbCard";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import NewBlurbCard from "../../utlis/hoc/newBlurbCard/newBlurbCard";
// import MetaSEO from "../SEO/MetaSEO";

const BlurbsPage = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const TYPE = props?.type;
  const [PageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const PARAMS = useParams();
  const vendorName = PARAMS?.product
    ? PARAMS?.product?.replaceAll("-", " ")
    : PARAMS?.vendor
    ? PARAMS?.vendor?.replaceAll("-", " ")
    : GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Name;

  const productDetails = PARAMS?.name
    ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))
    : DecryptId(PARAMS?.id);

  const GetApiData = useEnCryptGetApi(
    `/getNavigationPathForBlurbPage?type=${TYPE}&id=${productDetails?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const HandlePageNumber = (val) => {
    setPageNo(val);
  };
  const handleFilterData = (val) => {
    let newData = [...SelectedFilter];
    newData.push(val);
    setSelectedFilter(newData);
    HandlePageNumber(1);
    setRemountComponent(Math.random());
  };

  const handleRemoveFilterData = (val) => {
    HandlePageNumber(1);
    const NewData = SelectedFilter?.filter((item) => {
      return val?.name !== item.name;
    });
    setSelectedFilter(NewData);
    setRemountComponent(Math.random());
  };

  const handleClearData = (val) => {
    if (SelectedFilter?.length) {
      setSelectedFilter([]);
      setRemountComponent(Math.random());
    }
  };

  const handleApplyChanges = (val) => {
    if (val?.length > 0) {
      const Type = val[0]?.type;
      const filterSelected = SelectedFilter.filter((list) => {
        return list?.type !== Type;
      });
      const data = _.uniqBy([...filterSelected, ...val], "name");
      setSelectedFilter(data);
    } else setSelectedFilter([]);
    setRemountComponent(Math.random());
  };

  //   https://thebox-dev.portqii.com/search_backend/getAllBlurbsByProductId
  const SearchResultData = useEnCryptPostApi(
    `${
      TYPE == "product" ? "/getAllBlurbsByProductId" : "/getAllBlurbsByVendorId"
    }`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );
  // const breadCrumbDummy = [
  //   {
  //     name: "Home",
  //     active: true,
  //     link: "/",
  //   },
  //   {
  //     name: ToTitleCase(PARAMS?.name ? productDetails?.Name : vendorName),
  //     active: true,
  //     link:
  //       TYPE == "product"
  //         ? `/${ReplaceBlankSpaceByHyphen(
  //           PARAMS?.name ? productDetails?.Name : vendorName
  //         )}/product/${EncryptId(productDetails?.Id)}`
  //         : `/vendor/${ReplaceBlankSpaceByHyphen(
  //           PARAMS?.name ? productDetails?.Name : vendorName,
  //           productDetails?.Id
  //         )}`,
  //   },
  // ];

  useEffect(() => {
    const rawData = {
      productId: productDetails?.Id,
      page: PageNo,
      type: "products",
      filters: SelectedFilter,
    };
    const vendorData = {
      Vendor_ID: productDetails?.Id,
      limit: 10,
      page: PageNo,
    };
    SearchResultData?.handleTrigger(TYPE == "product" ? rawData : vendorData);
  }, [productDetails?.Id, PageNo, SelectedFilter]);

  return (
    <>
      {/* <MetaSEO title={`${vendorName} product details and features`} /> */}
      {authData?.data?.userExist ? (
        <HeaderAuth page={props?.page} type={props?.type} show={true} />
      ) : (
        <Header page={props?.page} type={props?.type} show={true} />
      )}
      {/* <SearchNavHeader
        parent={"searchPage"}
        type={''}
        params={productDetails?.Name}
        pageNo={PageNo}
        total={SearchResultData?.data?.count}
      /> */}
      <div className="attached-navbar m-b-md">
        <div className="container flex space-between align-items-bottom">
          <SearchHeaderBreadCrumb
            page={props?.page}
            parent={"blurbPage"}
            type={"blurbs"}
            params={"Blurbs"}
            data={GetApiData?.data?.data}
          />
        </div>
      </div>
      <div className="only-mobile">
        <FilterMobileView
          page={props?.page}
          data={SearchResultData?.data?.filters}
          handleApplyChanges={handleApplyChanges}
        />
      </div>
      <div style={{ padding: "20px 0px 0px 0px" }} className="container search">
        <h1>
          {KEYS?.BLURBS_PAGE?.TITLE_START}{" "}
          {ToTitleCase(
            productDetails?.name ? productDetails?.name : vendorName
          )}
        </h1>
        <div className="grid-3-1 tablet-landscape-1-col grid-col-gap-md">
          <div key={remountComponent}>
            <ListCount
              page={props?.page}
              pageNo={PageNo}
              type={""}
              totalNumber={SearchResultData?.data?.count}
              searchText={
                productDetails?.name ? productDetails?.name : vendorName
              }
            />
            <SelectedFilterList
              page={props?.page}
              data={SelectedFilter}
              handleRemoveFilterData={handleRemoveFilterData}
              handleClearData={handleClearData}
            />
            <div>
              {SearchResultData?.isLoading || SearchResultData?.isFetching ? (
                <ProductSkeleton page={props?.page} />
              ) : SearchResultData?.data?.data ? (
                <>
                  {SearchResultData?.data?.data?.length ? (
                    // SearchResultData?.data?.data.map((products) => {
                    //   return (
                    //     <BlurbCardLarge
                    //       page={props?.page}
                    //       data={products}
                    //       parent={props?.parent}
                    //     />
                    //   );
                    // })
                    <NewBlurbCard
                      page={props?.page}
                      data={SearchResultData?.data?.data}
                      parent={props?.parent}
                      section={"Blurb Page"}
                    />
                  ) : (
                    <div
                      className="search__result data-product-card"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        // className="data-product-card__text"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bolder",
                          color: "GrayText",
                          alignSelf: "center",
                        }}
                      >
                        This {TYPE == "product" ? "product" : "vendor"} has no
                        blurbs
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <PaginatedItems
              page={PageNo}
              totalNumber={SearchResultData?.data?.count}
              itemsPerPage={10}
              HandlePageNumber={HandlePageNumber}
            />
            <MobilePaginatedItems
              page={PageNo}
              totalNumber={SearchResultData?.data?.count}
              itemsPerPage={10}
              HandlePageNumber={HandlePageNumber}
            />
          </div>
          {/* <SearchResultFilter
            data={SearchResultData?.data?.filters}
            handleFilterData={handleFilterData}
            handleRemoveFilterData={handleRemoveFilterData}
            handleApplyChanges={handleApplyChanges}
          /> */}
        </div>
      </div>
      <Footer page={props?.page} />
    </>
  );
};
export default memo(BlurbsPage);
