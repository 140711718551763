import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import * as ENV_KEYS from "../../../config/configKeys";
// import * as KEYS from "../../../config/config";
const DataProviderHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const signalTrackingVendorsObject = {
    Action: "vendor logo clicked",
    page: "vendor page",
    section: props?.section,
    context: DATA?.Vendor_Name,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorLogoClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorsObject?.Action,
      Item_Type: signalTrackingVendorsObject?.page,
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorsObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorsObject?.section,
        context: DATA?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <a
        className="vertical-provider-card__header"
        onClick={() =>
          handleVendorLogoClicked(
            `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`
          )
        }
        href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
        }`}
      >
        <div className="vertical-provider-card__logo-wrapper">
          <img
            alt={` ${DATA?.Vendor_Name} ${ENV_KEYS?.CONFIG?.VENDOR_LOGO}`}
            className=" lazyloaded"
            data-src={DATA?.Vendor_Logo_URL ? DATA?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
            src={DATA?.Vendor_Logo_URL ? DATA?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        </div>
        <div className="vertical-provider-card__title">
          {DATA?.Vendor_Name}
          <div className="vertical-provider-card__subtitle">
            {/* <i
                            title="United States of America"
                            className="flag us"
                          ></i> */}
            {DATA?.Industry ? <i className="feather icon icon-align-left " title="Product categories"></i> : null}
            {DATA?.Industry}
          </div>
        </div>
      </a>
    </>
  );
};

export default DataProviderHeader;
