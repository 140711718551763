import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterList from "./filterList";
import ShowMoreOptions from "./showMoreOptions";
// import * as KEYS from "../../../../config/config";

const SearchResultFilter = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  const [filterData, setFilterData] = useState([]);
  const filterKeys = Object.keys(filterData);
  const [ModalVisible, setModalVisible] = useState(false);
  const [showMoreData, setShowMoreData] = useState([]);
  const [showMoreLabel, setShowMoreLabel] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);

  useEffect(() => {
    if (props?.data) {
      setFilterData(props?.data);
      setRemountComponent(Math.random());
    }
  }, [props?.data]);

  const handleFilterData = (data) => {
    props?.handleFilterData(data);
  };
  const handleRemoveFilterData = (val) => {
    props?.handleRemoveFilterData(val);
  };

  const handleShowMorePressed = (val, label) => {
    setShowMoreData(val);
    setShowMoreLabel(label);
    if (val?.length) setModalVisible(true);
  };

  return ModalVisible && showMoreData ? (
    <ShowMoreOptions
      onClose={() => setModalVisible(false)}
      data={showMoreData}
      handleApplyChanges={(val) => props?.handleApplyChanges(val)}
      handleRemoveFilterData={handleRemoveFilterData}
      showMoreLabel={showMoreLabel}
    />
  ) : (
    <div className="filter-aside-container">
      <aside className="search__filters" key={remountComponent}>
        <div className="flex space-between m-b-xs">
          {props?.tabView ? (
            <div className="flex align-items-center">
              <FeatherIcon
                icon={"arrow-left"}
                size={20}
                color={"130F26"}
                onClick={() => props?.handleViewFilter()}
                style={{ marginRight: "15px" }}
                role={"button"}
              />
              <div className="filter-title">Filters</div>
            </div>
          ) : (
            <div className="filter-title ">Filters</div>
          )}
          <button
            className="filter-reset-button"
            onClick={() => props?.handleClearData()}
          >
            Clear all
          </button>
        </div>
        <div>
          <form
            // className="dtrd-form--modern dtrd-form"
            acceptCharset="UTF-8"
            method="get"
          >
            {filterKeys?.map((val, index) => {
              return (
                <div className="m-t-mini" key={index}>
                  <FilterList
                    label={val}
                    data={filterData?.[val]}
                    key={index}
                    handleFilterData={handleFilterData}
                    handleRemoveFilterData={handleRemoveFilterData}
                    handleShowMorePressed={handleShowMorePressed}
                    keyName={val}
                    handleApplyChanges={props?.handleApplyChanges}
                  />
                </div>
              );
            })}
          </form>
        </div>
      </aside>
    </div>
  );
};

export default SearchResultFilter;
