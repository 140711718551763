import React from "react";

const PopularList = ({ data, handleActiveCategory, activeCategory }) => {
  const CATEGORY_DATA = Object?.keys(data) ?? [];
  return (
    <div className="popular-case-studies-list-container mobile-hidden">
      {/* <img
        className=".octate-mobile"
        width="100px"
        height="30px"
        style={{ marginBottom: "35px" }}
        src={require("../../../../../assets/images/octateLogo181527.svg").default}
        alt={"octate.ai logo"}
      ></img> */}
      <h2 class="landing-page__section-title m-b-none" style={{ color: "#181527" }}>
        Popular{" "}
      </h2>
      <h2
        class="landing-page__section-title m-t-none"
        style={{ color: "#181527", borderBottom: "1px solid #ffb527", width: "fit-content" }}
      >
        Success Stories
      </h2>

      {CATEGORY_DATA?.map((item, index) => {
        // if (index === 0) handleActiveCategory(item);
        return (
          <h1
            className={`${
              item === activeCategory ? "popular-case-studies-list-item-active" : "popular-case-studies-list-item"
            }`}
            onClick={() => handleActiveCategory(item)}
          >
            {item}
          </h1>
        );
      })}
      {/* <div>
        <h1 className="popular-case-studies-list-item">Account Payable Automation</h1>
        <h1 className="popular-case-studies-list-item">Billing And Invoicing Software</h1>
        <h1 className="popular-case-studies-list-item">Account Payable Automation</h1>
      </div> */}
    </div>
  );
};

export default PopularList;
