import React, { useEffect, useState } from "react";
import { ValidatePricingPlansErrors } from "../../../utlis/hof/hof";
import PricingHeader from "./pricingHeader";
import PricingTable from "./pricingTable";
import _ from "lodash";
import { useSelector } from "react-redux";
import AddFeatureHeader from "./addFeatureHeader";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import FeatureTable from "./featureTable";
const AddFeatureCreateFeature = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const [pricingFormErrors, setPricingFormErrors] = useState({});
  const productDetails = props?.productDetails;
  const featureFormErrors = props?.featureFormErrors;
  const data = props?.data;

  // const handleDetails = (val) => {
  //   props?.handleDetails({ ...props?.productDetails, Product_Feature: val });
  // };
  const handleFeatureListDetails = (val) => {
    props?.handleDetails({ ...data, Feature_List: val, id: props?.index });
  };
  return (
    <div className="add-product-product-features-content-wrapper">
      <div className="add-product-product-features-content-data-wrapper">
        <InputFieldProfile
          // disable={true}
          label={"Section Title"}
          value={data?.Feature_Group_Name}
          changeText={(val) =>
            props?.handleDetails({
              ...data,
              Feature_Group_Name: val,
              id: props?.index,
            })
          }
          error={featureFormErrors?.Feature_Group_Name}
        />

        <FeatureTable
          error={featureFormErrors}
          data={data?.Feature_List}
          pricingFormErrors={props?.pricingFormErrors}
          productCategoryId={
            props?.productDetails?.Selected_Product_Category?.length
              ? props?.productDetails?.Selected_Product_Category[0]?.id
              : null
          }
          handleDetails={handleFeatureListDetails}
        />
      </div>
      {/* props?.index == 0 ? "cursor--disable" : */}
      <div className="add-product-product-features-content-button-wrapper">
        <div className="add-product-product-features-content-button">
          <span
            onClick={() => props?.handleDeleteFeatureSection(props?.index)}
            className={`add-product-product-features-content-button-text ${"cursor--pointer"}`}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddFeatureCreateFeature;
