import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../../../config/config";
import CommentMoreOptionsList from "./commentMoreOptionsList";
const CommentMoreOptions = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const PROPOSAL_COMMENT_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL_COMMENTS_KEYS;
  const handleActionClicked = (val) => {
    setToggleMenu(!toggleMenu);
    props?.handleActionClicked(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  return (
    <div tabIndex={0} onBlur={handleBlur} className="p-r">
      <FeatherIcon
        style={{ cursor: "pointer" }}
        onClick={() => setToggleMenu(!toggleMenu)}
        icon={"more-horizontal"}
        size="17"
      />
      {toggleMenu && (
        <div className="view-more-container-proposal-comments-list modern-card modern-card--elevated">
          <CommentMoreOptionsList
            list={props?.list}
            handleActionClicked={handleActionClicked}
          />
        </div>
      )}
    </div>
  );
};

export default CommentMoreOptions;
