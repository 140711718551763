import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useGetAllPopularProductCategoryQuery } from "../../../adapters/xhr/searchService";
import { DecryptApi, EncryptApiGetData, ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../../utlis/hof/hof";
import { productData } from "../../../contexts/reducer/homeReducer";
import { selectedVendorFn } from "../../../contexts/reducer/chatReducer";
import { useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../config/configKeys";

const Footer = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const FOOTER = KEYS?.FOOTER;
  const dispatch = useDispatch();
  const [enCryptGetPopularProductCategoryData, setEnCryptProductCategory] = useState("");
  const [DeCryptProductCategoryList, setDeCryptProductCategoryList] = useState();
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: "footer",
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const {
    data: allProductCategoryData,
    isError: allProductCategoryIsError,
    isLoading: allProductCategoryIsLoading,
  } = useGetAllPopularProductCategoryQuery(
    { enCryptGetPopularProductCategoryData },
    { skip: enCryptGetPopularProductCategoryData?.data ? false : true }
  );

  useEffect(() => {
    const enCryptData = {
      // https://thebox-dev.portqii.com/search_backend/getAllProductCategory
      path: `/getAllPopularProductCategory?limit=10`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };
    const enCryptGetPopularProductCategoryData = EncryptApiGetData(enCryptData);
    setEnCryptProductCategory(enCryptGetPopularProductCategoryData);
  }, []);

  useEffect(() => {
    const DeCryptData = DecryptApi(allProductCategoryData);
    if (DeCryptData?.data) {
      setDeCryptProductCategoryList(DeCryptData);
      dispatch(productData(DeCryptData));
    }
  }, [allProductCategoryData]);

  function handleChatWithOctateClicked() {
    const vendorData = octateVendorSelector?.length ? octateVendorSelector : [];
    dispatch(selectedVendorFn(vendorData[0]));
  }

  function handlePoliciesClicked() {
    handleSignalTracking("https://business.octate.ai/policies/");
    window.open("https://business.octate.ai/policies/");
  }

  const handleSignalTracking = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "Policies",
      Item_Id: null,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: "footer policies clicked",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <>
      <div className="footer-for-mobile"></div>
      <div
        id="app"
        className="landing-page__show"
        data-controller="scroll-position"
        data-scroll-position="4240.7998046875"
      >
        <footer>
          <div className="container" style={{ maxWidth: "1120px" }}>
            <div className="grid-five-columns tablet-2-col">
              <div className="dtrd list">
                <div className="item m-b-base">
                  <div className="header">{FOOTER?.POPULAR_CATEGORIES}</div>
                </div>
                {DeCryptProductCategoryList?.data?.slice(0, 10)?.map((item, index) => {
                  return (
                    <div className="item" key={item?.Product_Category_ID}>
                      <a
                        href={`/category/${ReplaceBlankSpaceByHyphen(
                          item?.Product_Category_Name,
                          item?.Product_Category_ID
                        )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                      >
                        {ToTitleCase(item?.Product_Category_Name)}
                      </a>
                    </div>
                  );
                })}
              </div>
              <div className="dtrd list" style={{ textAlign: "left" }}>
                <div className="item m-b-base">
                  <div className="header">{FOOTER?.OCTATE_AI}</div>
                </div>
                {/* <div className="item">
                  <a rel="noreferrer noopener">{FOOTER?.OUR_STORY}</a>
                </div>
                <div className="item">
                  <a rel="noreferrer noopener">{FOOTER?.CAREERS}</a>
                </div> */}
                <div onClick={handleChatWithOctateClicked} className="item cursor--pointer">
                  <a rel="noreferrer noopener">{FOOTER?.CONTACT_US}</a>
                </div>
                {/* <div className="item">
                  <a rel="noreferrer noopener" role={"button"} href="/privacy" target={"_blank"}>
                    {FOOTER?.PRIVACY_POLICY}
                  </a>
                </div>
                <div className="item">
                  <a>{FOOTER?.MANAGE_COOKIE}</a>
                </div>
                <div className="item">
                  <a rel="noreferrer noopener" role={"button"} href="/terms" target={"_blank"}>
                    {FOOTER?.TERMS_SERVICE}
                  </a>
                </div> */}
                <div className="item">
                  <a rel="noreferrer noopener" role={"button"} onClick={() => handlePoliciesClicked()}>
                    Policies
                  </a>
                </div>
                {/* <div className="item">
                  <a rel="noreferrer noopener" role={"button"} href="/terms" target={"_blank"}>
                    {FOOTER?.TERMS_SERVICE}
                  </a>
                </div> */}
                {/* <div className="item">
                  <a rel="noreferrer noopener">{FOOTER?.LEGAL_NOTICE}</a>
                </div> */}
              </div>
              {/* <div className="dtrd list">
                <div className="item m-b-base">
                  <div className="header">{FOOTER?.BUYERS_SOLUTION} </div>
                </div>

                <div className="item">
                  <a
                    
                    rel="noreferrer noopener"

                  >
                    {FOOTER?.BUYER_OCTATE}
                  </a>
                </div>

                <div className="item">
                  <a>{FOOTER?.DATA_CATEGORIES}</a>
                </div>

                <div className="item">
                  <a>{FOOTER?.USE_CASES}</a>
                </div>

                <div className="item">
                  <a>{FOOTER?.TOP_LISTS}</a>
                </div>
              </div>
              <div className="dtrd list">
                <div className="item m-b-base">
                  <div className="header">{FOOTER?.VENDOR_SOLUTION}</div>
                </div>
                <div className="item">
                  <a
                    
                    rel="noreferrer noopener"
                
                  >
                    {FOOTER?.VENDOR_OCTATE}
                  </a>
                </div>
                <div className="item">
                  <a
                    className="button list-your-data-button"
                    
                    rel="noreferrer noopener"
                  
                  >
                    {FOOTER?.LIST_DATA}
                  </a>
                </div>
              </div> */}
              {/* <a
                
                rel="noreferrer noopener"
                href="https://www.linkedin.com/company/octateai/"
              >
                <img
                  className="ui linkedin image lazyloaded"
                  alt="LinkedIn logo"
                  data-src={
                    require("../../../assets/images/linkedin.svg").default
                  }
                  src={require("../../../assets/images/linkedin.svg").default}
                />
              </a>{" "} */}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
