import React, { forwardRef, useEffect, useRef, useState } from "react";
import * as CONFIG_KEYS from "../../../../../../config/config";
import moment from "moment";
import { useSelector } from "react-redux";
import CommentMoreOptions from "./commentMoreOptions";
import FeatherIcon from "feather-icons-react";
import SingleCommentCard from "./singleCommentCard";
const CommentsList = forwardRef((props, ref) => {
  const [showEditOption, setShowEditOption] = useState(true);
  const [replyId, setReplyId] = useState(null);
  const replyEndRef = useRef(null);
  const notificationJson = props?.notificationJson;
  const notificationCommentId = notificationJson?.["Object"]?.Comments_ID;
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const handleReplyClicked = (val) => {
    props?.handleReplyClicked(val);
  };
  const authData = useSelector((state) => state?.authDetails?.details);
  const proposalType = props?.proposalType;

  const userId = authData?.data?.userId;
  const CommentListData = props?.CommentListData?.length
    ? props?.CommentListData
    : [];
  const PROPOSAL_COMMENT_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL_COMMENTS_KEYS;
  const handleDeleteClicked = (val) => {
    let newCommentArray = CommentListData?.length ? [...CommentListData] : [];
    newCommentArray.splice(val, 1);
    props?.handleComments(newCommentArray);
  };
  const handleEditClicked = (val, index) => {
    let newCommentArray = CommentListData?.length ? [...CommentListData] : [];
    newCommentArray[index] = val;
    props?.handleComments(newCommentArray);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScrollToReplyComment(notificationCommentId, "notification");
    }, 3000);
    return clearTimeout();
  }, [notificationCommentId]);
  const handleScrollToReplyComment = (val, not) => {
    // replyEndRef.current = val;
    if (not == "notification") {
      setReplyId(val);
      setTimeout(() => {
        replyEndRef.current?.scrollIntoView(
          {
            behavior: "smooth",
          },
          500
        );
      });
    } else {
      setReplyId(val);
      setTimeout(() => {
        replyEndRef.current?.scrollIntoView(
          {
            behavior: "smooth",
          },
          500
        );
      });
    }

    // setTimeout(() => {
    //   setReplyId(null);
    // }, 1000);
  };
  return (
    <div className="proposal-comments-comment-list-wrapper">
      {CommentListData?.map((list, index) => {
        return (
          <SingleCommentCard
            Vendor_Id={props?.Vendor_Id}
            replyId={replyId}
            ref={replyEndRef}
            handleScrollToReplyComment={handleScrollToReplyComment}
            proposalType={proposalType}
            showEditOption={(val) => setShowEditOption(val)}
            handleDeleteClicked={handleDeleteClicked}
            handleEditClicked={handleEditClicked}
            handleReplyClicked={handleReplyClicked}
            showEditOptionState={showEditOption}
            list={list}
            index={index}
          />
        );
      })}
      <div ref={ref} style={{ float: "left" }}></div>
    </div>
  );
});

export default CommentsList;
