import React from "react";
import FeatherIcon from "feather-icons-react";

const InputFieldProfile = (props) => {
  const error = props?.error;
  const infoText = props?.infoText;
  const REQUIRED = props?.required;
  return (
    <div
      className={`${
        props?.removeBottomPadding
          ? "input-field-wrapper-position-relative"
          : "input-field-wrapper-position-relative m-b-lg"
      }`}
    >
      <div
        id="input-field-wrapper"
        class={`input-field-wrapper ${error ? "background-error" : ""}`}
        style={{ border: "1px solid #EFF1F9" }}
      >
        <FeatherIcon icon={props?.icon} size="17" color={"#6E7079"} className="input-icons" />
        <input
          id={props?.label}
          type={props?.type ? props?.type : "text"}
          value={props?.value ? props?.value : props?.value}
          name={props?.label}
          disabled={props?.disable ? props?.disable : false}
          placeholder={props?.label}
          onChange={(e) => props?.changeText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value)}
          className={`input-field-text-profile ${error ? "background-error" : ""} ${
            REQUIRED ? "required-border-right" : ""
          }`}
          autoComplete="off"
          style={{ paddingLeft: props?.icon ? "30px" : "10px" }}
        />
      </div>
      {infoText ? <p className="input-field-info-text">Please use a comma when there are multiple values.</p> : null}
      {error ? <span class="input-field-text-error">{error}</span> : null}
    </div>
  );
};

export default InputFieldProfile;
