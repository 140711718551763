import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { EncryptApiGetData, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
// import * as KEYS from "../../../../../config/config";
import SearchInputDropDown from "../../../blades/search/component/searchInputDropDown";
import HeaderSearchType from "./headerSearchType";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
const HeaderSearch = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [searchedTextValue, setSearchedTextValue] = useState("");
  const [enCryptSearchedText, setEnCryptSearchedText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const PROVIDERS = KEYS?.HEADER_SEARCH_TYPE.PROVIDER;
  const PRODUCTS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PRODUCT_LABEL;
  const PROVIDERS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PROVIDER_LABEL;
  const [changeType, setChangeType] = useState(
    props?.type
      ? props?.type == PRODUCTS
        ? PRODUCTS_LABEL
        : PROVIDERS_LABEL
      : PRODUCTS_LABEL
  );
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigation(
      `/search/${changeType.toLocaleLowerCase()}/${ReplaceBlankSpaceByHyphen(
        searchedTextValue
      )}`
    );
    setSearchedTextValue("");
  };
  const {
    data: searchedTextData,
    isError: searchedTextIsError,
    isLoading: searchedTextLoading,
    isFetching: searchedTextIsFetching,
  } = useGetRequestQuery(enCryptSearchedText, {
    skip: enCryptSearchedText?.data && searchedTextValue ? false : true,
  });
  const handleChangeType = (val) => {
    setChangeType(val);
  };
  const GetApiData = useEnCryptGetApi(
    `/completeSearch?searchText=${searchedTextValue}&userId=27&limit=5`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );

  const handleChange = (e) => {
    const enCryptData = {
      // completeSearch?searchText=account&userId=27&boardID=286&limit=5
      path: `/completeSearch?searchText=${e.target.value}&userId=27&limit=5`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };

    const enCryptSearchedText = EncryptApiGetData(enCryptData);
    setEnCryptSearchedText(enCryptSearchedText);
    setSearchedTextValue(e.target.value);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 500);
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  return (
    <div
      className="navbar__item dtrd-navbar__search"
      style={{ maxWidth: "600px !important" }}
    >
      <form className="dtrd-form" onSubmit={handleSubmit}>
        <div className="autocomplete-search">
          <div className="autocomplete-search__input">
            {/* <div className="field" id="field-search_context">
              <HeaderSearchType
                handleChangeType={handleChangeType}
                changeType={changeType}
              />
            </div> */}
            <div className="field full-width" id="field-keywords">
              <input
                ref={inputRef}
                className="autocomplete-search__prompt"
                placeholder={KEYS?.SEARCH_INPUT.PLACEHOLDER}
                id="keywords"
                type="text"
                name="keywords"
                value={searchedTextValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <button type="submit">
              <i className="feather icon icon-search"></i>
            </button>
          </div>
          <div
            className={`${
              searchedTextValue?.length && showDropdown
                ? "autocomplete-search__results transition visible "
                : "autocomplete-search__results"
            }`}
            style={{
              display: `${
                searchedTextValue?.length && showDropdown ? "block" : "none"
              }`,
            }}
          >
            {searchedTextLoading ? null : searchedTextIsFetching ? null : searchedTextIsError ? null : searchedTextData?.data &&
              showDropdown ? (
              <SearchInputDropDown
                searchedList={searchedTextData}
                inputText={searchedTextValue}
              />
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};
export default HeaderSearch;
