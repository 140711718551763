import React, { useState } from 'react';
import AddBlurbOrProductPortal from '../../businessProfileHeader/components/addBlurbOrProductPortal';
import { useSelector } from 'react-redux';
import AddOctateFaqPortal from './addOctateFaqPortal';

const TabHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const permissionsDetails = props?.permissionsDetails;
  const tabIndex = props?.tabIndex;
  const getOctateVendorID = props?.getOctateVendorID;
  const AddingProductOrBlurbLabel =
    tabIndex == 'blurbs'
      ? 'Add Blurb'
      : tabIndex == 'products'
      ? 'Add Product'
      : tabIndex == 'use-cases'
      ? 'Add Case Studies'
      : tabIndex == 'faq'
      ? 'Add FAQ'
      : null;

  const tabHeaderLabel =
    tabIndex == 'blurbs'
      ? 'Blurbs'
      : tabIndex == 'products'
      ? 'Products'
      : tabIndex == 'use-cases'
      ? 'Case Studies'
      : tabIndex == 'faq'
      ? ''
      : // : tabIndex == "alternate-vendor"
        // ? "Alternate Vendors"
        null;
  const handleNewPostClicked = () => {
    if (tabIndex == 'blurbs' || tabIndex == 'products' || tabIndex == 'faq' || tabIndex == 'use-cases' || tabIndex == 10) {
      setShowModal(true);
      // document.body.style.overflow = "hidden";
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    // document.body.style.overflow = "auto";
  };
  return (
    <>
      {showModal && permissionsDetails && permissionsDetails?.Create && getOctateVendorID ? (
        <AddOctateFaqPortal handleCloseModal={handleCloseModal} />
      ) : showModal && permissionsDetails && permissionsDetails?.Create ? (
        <AddBlurbOrProductPortal tabIndex={tabIndex} handleClose={handleCloseModal} />
      ) : null}
      <div className='flex space-between m-y-xs' style={tabIndex != 'faq' ? { marginLeft: '10px' } : null}>
        <h3 style={{ margin: 'auto 0px' }}>
          {tabHeaderLabel} {props?.dataLength ? `(${props?.dataLength ? props?.dataLength : null})` : null}
        </h3>
        {permissionsDetails?.Create ? (
          <button className={`business-profile-create-new-blue-button`} onClick={() => handleNewPostClicked()}>
            {AddingProductOrBlurbLabel}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default TabHeader;
