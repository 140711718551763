import React, { memo } from "react";
import { DecryptApi, ReplaceBlankSpaceByHyphen } from "../../../../../../utlis/hof/hof";
// import * as KEYS from "../../../../../../config/config";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../../../config/configKeys";
const CategoryList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DeCryptProductCategoryList = DecryptApi(props?.productCategoryList);
  const navigation = useNavigate();
  const signalTrackingCategoryObject = {
    Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
    page: "category page",
    section: "explore categories",
  };
  const signalTrackingAllCategoriesObject = {
    Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
    page: "all categories page",
    section: "explore categories",
    context: "all categories",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleExploreCategoryLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingCategoryObject?.Action,
      Item_Type: "Category",
      Item_Id: val?.Product_Category_ID,
      Target: {
        page: signalTrackingCategoryObject?.page,
        link: `/category/${ReplaceBlankSpaceByHyphen(val?.Product_Category_Name, val?.Product_Category_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE
        }`,
      },
      Object: {
        section: signalTrackingCategoryObject?.section,
        context: val?.Product_Category_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleExploreAllCategoriesLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Item_Type: "category",
      Item_Id: null,
      Action: signalTrackingAllCategoriesObject?.Action,
      Target: {
        page: signalTrackingAllCategoriesObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingAllCategoriesObject?.section,
        context: signalTrackingAllCategoriesObject?.context,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div className="navbar__sub-menu">
      <div>
        {DeCryptProductCategoryList?.data?.map((list, index) => {
          return (
            <div onClick={() => handleExploreCategoryLinkClicked(list)} className="navbar__link-group" key={index}>
              <a
                className="navbar__link-group-title"
                href={`/category/${ReplaceBlankSpaceByHyphen(list?.Product_Category_Name, list?.Product_Category_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE
                }`}
              >
                {/* <FeatherIcon icon="map-pin" className="feather icon" /> */}
                {list?.Product_Category_Name}
              </a>
              {/* <i className="feather icon icon-chevron-right "></i> */}
              {/* <SubCategoryList /> */}
            </div>
          );
        })}
      </div>

      <div className="navbar__sub-menu-footer">
        <a onClick={() => handleExploreAllCategoriesLinkClicked(`/categories`)} href={`/categories`}>
          {KEYS?.EXPLORE_CATEGORIES.BROWSE_ALL}
          <i className="feather icon icon-arrow-right m-r-none m-l-base"></i>
        </a>
      </div>
    </div>
  );
};
export default memo(CategoryList);
