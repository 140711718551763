import React from "react";
import { FilterSelectedData } from "../../../hof/hof";
import FeatherIcon from "feather-icons-react";
const DropdownSelection = (props) => {
  const handleToggleDropdownWrapper = () => {
    props?.handleToggleDropdownWrapper(!props?.toggleDropDown);
  };
  const selectedData = FilterSelectedData(props?.dropdownData);
  const tableDropDown = props?.tableDropDown;
  const namesToShow =
    selectedData?.length >= 1 && tableDropDown
      ? selectedData.slice(0, 1)
      : selectedData?.length >= 3
      ? selectedData.slice(0, 3)
      : selectedData;
  const extraNames =
    selectedData?.length >= 1 && tableDropDown
      ? selectedData.length - 1
      : selectedData?.length >= 3
      ? selectedData.length - 3
      : 0;
  const name = props?.name;
  const Id = props?.Id;
  return (
    <span onClick={handleToggleDropdownWrapper} class="selection">
      <span
        style={{
          backgroundColor: "rgba(239, 241, 249, 0.6)",
          border: "1px solid rgba(239, 241, 249, 0.6)",
        }}
        class="select2-selection select2-selection--single next-gen-select__selection"
        role="combobox"
        aria-haspopup="true"
        aria-expanded="true"
        tabindex="0"
        aria-disabled="false"
        aria-owns="select2-country-ia-results"
        aria-activedescendant="select2-country-ia-result-lnq7-HM"
      >
        <span className={`select2-selection__rendered ${tableDropDown ? "static-main0container-width" : ""}`}>
          {/* <img
            class="next-gen-select__selected-icon ls-is-cached lazyloaded"
            data-src="/packs/media/images/icons/circular-check-filled-18df7b01c23a60c3ffabff3d32b05759.svg"
            src="/packs/media/images/icons/circular-check-filled-18df7b01c23a60c3ffabff3d32b05759.svg"
          /> */}
          <span> </span>
          {namesToShow?.length ? (
            <span
              class={`next-gen-select__selected ${
                props?.selectedSmall ? "minimizeMaxWidth" : tableDropDown ? "minimizeTableMaxWidth" : ""
              }`}
            >
              {namesToShow?.length && tableDropDown
                ? namesToShow?.map((val, i) => {
                    return i == 0 ? `${val?.[name]}` : 10;
                  })
                : namesToShow?.map((val, i) => {
                    return i == 0 ? `${val?.[name]}` : i == 1 ? `,${val?.[name]}` : i == 2 ? ` & ${val?.[name]}` : 10;
                  })}
            </span>
          ) : (
            <span class="next-gen-select__selected">{props?.label}</span>
          )}
          {extraNames !== 0 && <span class="next-gen-select__count-more">{`+ ${extraNames} more`}</span>}
        </span>
        <span class="select2-selection__arrow" role="presentation">
          {props?.toggleDropDown ? (
            <FeatherIcon icon={"chevron-up"} size="17" color={"#6E7079"} className="dropdown-icons" />
          ) : (
            <FeatherIcon icon={"chevron-down"} size="17" color={"#6E7079"} className="dropdown-icons" />
          )}
        </span>
      </span>
    </span>
  );
};

export default DropdownSelection;
