import React from "react";
import * as CONFIG_KEYS from "../../../../../config/config";
import FeatherIcon from "feather-icons-react";
const SelectedVendorList = (props) => {
  const proposalDetails = props?.proposalDetails;
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const selectedVendorWithFilter = props?.selectedVendorWithFilter;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_PROPOSAL_ERROR_KEYS =
    CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;
  const companyErrors = props?.companyErrors;
  const selectedVendors = selectedVendorWithFilter?.length
    ? selectedVendorWithFilter
    : [];
  const handleDeleteClicked = (val) => {
    let selectedVendorAfterDeleting = [
      ...proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS],
    ];
    selectedVendorAfterDeleting.splice(val, 1);
    props?.handleDeleteClicked(selectedVendorAfterDeleting);
  };
  return (
    <div className="proposal-selected-vendor-list-main-wrapper">
      {selectedVendors?.length ? (
        <div className="proposal-selected-vendor-list-wrapper">
          {selectedVendors?.map((val, index) => {
            return (
              <div className="proposal-selected-vendor-list-content-wrapper">
                <h4 className="proposal-selected-vendor-list-content-text">
                  {val?.[ADD_PRODUCT_CONFIG_KEYS?.VENDOR_NAME]}{" "}
                </h4>
                <div
                  onClick={() => handleDeleteClicked(index)}
                  className="proposal-selected-vendor-list-content-close"
                >
                  <FeatherIcon
                    icon="x"
                    size="20"
                    role="button"
                    // className="blurb-detail-social"
                    color={"#5570f1"}
                    //   onClick={() => handleEditFAQ(item)}
                    //   style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="proposal-selected-vendor-list-no-vendor-selected-wrapper">
          {companyErrors?.[ADD_PROPOSAL_KEYS?.VENDORS] ? (
            <h3 className="proposal-selected-vendor-list-no-vendor-selected-text--error">
              {ADD_PROPOSAL_ERROR_KEYS?.VENDOR}
            </h3>
          ) : (
            <h3 className="proposal-selected-vendor-list-no-vendor-selected-text">
              {proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.VENDORS]?.length
                ? `No results found for "${props?.searchedText}"`
                : "No Vendors Selected Yet"}
            </h3>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectedVendorList;
