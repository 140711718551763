import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import HeaderSEO from "../../components/SEO/header";
import { useEnCryptGetApi } from "../customHooks/customHooks";
import { GetIdFormLink } from "../hof/hof";
import PageLoading from "../spinner/pageLoading";

const ValidateVendorProposal = (props) => {
  const location = useLocation();
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const PROPOSAL_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : null;
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const VerifyProposalData = useEnCryptGetApi(
    `/proposal/verifyVendorProposal?Proposal_ID=${PROPOSAL_ID}&Vendor_ID=${vendorId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );
  const checkVerified = VerifyProposalData?.data?.data?.Verified;
  return (
    <>
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      {authData?.data?.userExist && authData?.data?.isValidDomain && PROPOSAL_ID ? (
        <>
          {VerifyProposalData?.isLoading ? (
            <PageLoading />
          ) : checkVerified ? (
            <Outlet />
          ) : !VerifyProposalData?.data?.data ? (
            <PageLoading />
          ) : (
            <Navigate to="/sales-hub/proposals" replace state={{ path: location.pathname }} />
          )}
        </>
      ) : (
        <Navigate to="/sign-in" replace state={{ path: location.pathname }} />
      )}
    </>
  );
};

export default ValidateVendorProposal;
