import React from "react";
import FeatherIcon from "feather-icons-react";
const OctateIcon = () => {
  return (
    <a
      className="navbar__item navbar__brand navbar__brand--mobile no-tap-highlight"
      href="/"
    >
      <div className="navbar__item-content">
        <img
          className=".octate-mobile"
          width="15px"
          height="15px"
          style={{ marginRight: "8px" }}
          src={require("../../../../../assets/images/OctateIcon.ico")}
          alt={"octate.ai logo"}
        ></img>
        <img
          className="octate-logo"
          width="120px"
          height="54px"
          src={require("../../../../../assets/images/OctateLogo.png")}
          alt={"octate.ai logo"}
        ></img>
      </div>
    </a>
  );
};
export default OctateIcon;
