import React from "react";
import { useSelector } from "react-redux";
import * as NOTIFICATION_KEYS from "../../../../../config/config";
import { useEffect } from "react";
import CommentsSkeleton from "../../../../searchResult/components/skeleton/commentsSkeleton";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { useUpdateNotificationStatusApiMutation } from "../../../../../adapters/xhr/generalApi";
import NotificationCard from "../../../../notifications/component/notificationCard";
import HelpCard from "./helpCard";
import HelpCategory from "./helpCategory";
import _ from "lodash";
const HelpList = (props) => {
  const list = typeof props?.Data === "object" ? Object.keys(props?.Data) : [];

  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const isLoading = props?.isLoading;
  const NOTIFICATION_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.NOTIFICATION_API_KEYS;
  const faqCategoryData = KEYS?.OCTATE_FAQ_CATEGORIES;
  const listNew = _.sortBy(faqCategoryData, (val) => val.Category_Sequence).map((val) => {
    return val.Category_Name;
  });
  return (
    <div className="notification-list-main-wrapper">
      {isLoading ? (
        <CommentsSkeleton />
      ) : (
        <div>
          <div
            style={{ color: "#080808", fontSize: "22px", paddingBottom: "10px" }}
            className="frequently-asked-questions-product-title"
          >
            {"Contents"}
          </div>
          {listNew?.length > 0 ? (
            listNew?.map((val, index) => {
              return (
                <div>
                  <HelpCategory label={val} data={props?.Data[val]} />
                </div>
              );
            })
          ) : (
            <div className="notification-card-main-wrapper">
              <h3 style={{ color: "#d3d3d3" }} className="notifications-header-main-title-text">
                We are working on it
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HelpList;
