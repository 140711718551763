import React, { useRef, useState } from "react";
import { EncryptApiGetData, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import SearchInputDropDown from "./searchInputDropDown";
import FeatherIcon from "feather-icons-react";
// import * as KEYS from "../../../../../config/config";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import * as SECRET_KEYS from "../../../../../config/configKeys";
const SearchInput = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [searchedTextValue, setSearchedTextValue] = useState("");
  const [enCryptSearchedText, setEnCryptSearchedText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const signalTrackingSearchObject = {
    Action: SECRET_KEYS.CONFIG.SEARCH_CLICKED,
    page: "search page",
    section: "search box",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const handleSubmit = (e) => {
    e?.preventDefault();
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingSearchObject?.Action,
      Item_Type: null,
      Item_Id: null,
      Target: {
        page: signalTrackingSearchObject?.page,
        link: `/search/blurbs/${ReplaceBlankSpaceByHyphen(searchedTextValue)}`,
      },
      Object: {
        section: signalTrackingSearchObject?.section,
        context: searchedTextValue,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    navigation(`/search/blurbs/${ReplaceBlankSpaceByHyphen(searchedTextValue)}`);
  };
  const {
    data: searchedTextData,
    isError: searchedTextIsError,
    isLoading: searchedTextLoading,
    isFetching: searchedTextIsFetching,
  } = useGetRequestQuery(enCryptSearchedText, {
    skip: enCryptSearchedText?.data && searchedTextValue ? false : true,
  });
  const handleChange = (e) => {
    const enCryptData = {
      // homeSearch?searchText=account&userId=27&boardID=286&limit=5
      path: `/completeSearch?searchText=${e.target.value}&userId=27&limit=5`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };
    const enCryptSearchedText = EncryptApiGetData(enCryptData);
    setEnCryptSearchedText(enCryptSearchedText);
    setSearchedTextValue(e.target.value);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  return (
    <div className="search-section">
      <form className="dtrd-form" onSubmit={handleSubmit}>
        <div className="autocomplete-search">
          <div className="autocomplete-search__input">
            <div className="field full-width" id="field-keywords">
              <input
                ref={inputRef}
                className="autocomplete-search__prompt search-input-place-holder"
                placeholder={KEYS?.SEARCH_INPUT.PLACEHOLDER}
                autoFocus="autofocus"
                id="keywords"
                type="text"
                name="keywords"
                value={searchedTextValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                autocomplete="off"
              />
            </div>
            {/* <button className="button">{KEYS?.SEARCH_INPUT.BUTTON}</button> */}
            <FeatherIcon
              icon="search"
              size="47"
              color="#FFB527"
              className="searchIcon mobile-hidden"
              role="button"
              onClick={
                () => handleSubmit()
                // navigation(
                //   `/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(
                //     searchedTextValue
                //   )}`
                // )
              }
            />
            <FeatherIcon
              icon="search"
              size="33"
              color="#FFB527"
              className="searchIcon only-mobile"
              role="button"
              onClick={
                () => handleSubmit()
                // navigation(
                //   `/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(
                //     searchedTextValue
                //   )}`
                // )
              }
            />
          </div>
          <div
            className={`${
              inputRef?.current?.value && showDropdown
                ? "autocomplete-search__results transition visible "
                : "autocomplete-search__results"
            }`}
            style={{
              display: `${inputRef?.current?.value && showDropdown ? "block" : "none"}`,
              borderRadius: "10px",
            }}
          >
            {searchedTextLoading ? null : searchedTextIsFetching ? null : searchedTextIsError ? null : searchedTextData?.data &&
              showDropdown ? (
              <SearchInputDropDown searchedList={searchedTextData} inputText={searchedTextValue} page={props?.page} />
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};
export default SearchInput;
