import React from "react";

function Title(props) {
  const title = props?.allProducts?.Product_Name;
  const category = props?.allProducts?.Product_Catagories;
  return (
    <div
    // className="flex-container space-between flex-wrap"
    >
      <a className="product-small-card-title">{title}</a>
      <div class="vertical-provider-card__subtitle">
        <i class="feather icon icon-align-left " title="Product categories"></i>
        {category[0]?.Product_Category_Name}
      </div>
    </div>
  );
}

export default Title;
