import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import { useMemo } from "react";
const FaqForm = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const error = props?.error;
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const vendorId = props?.vendorId;
  const GetApiData = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${vendorId}&status=${vendorDetails?.Status}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const [description, setDescription] = useState("");

  function handleDescription(value) {
    setDescription(value);
  }
  useEffect(() => {
    if (description?.length)
      props?.handleDetails({
        ...props?.faqDetails,
        Answer: description,
      });
    else setDescription(props?.faqDetails?.Answer);
  }, [description]);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [false] }],
          ["bold", "italic", "underline", "strike", "link", "clean"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <div className="company-profile-details-main-container">
      <div className="company-profile-basic-details-main-container">
        <div className="product-profile-basic-details-left-side-container">
          <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
            {props?.faqDetails?.Question ? (
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                Question
              </label>
            ) : null}
            <InputFieldProfile
              label={"Question"}
              value={props?.faqDetails?.Question}
              changeText={(val) =>
                props?.handleDetails({
                  ...props?.faqDetails,
                  Question: val,
                })
              }
              error={error?.Question}
            />
          </div>
          <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
            {props?.faqDetails?.Answer ? (
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                Answer
              </label>
            ) : null}
            {/* <TextArea
              value={props?.faqDetails?.Answer}
              className="m-b-sm"
              label={"Please answer to your question"}
              changeText={(val) =>
                props?.handleDetails({
                  ...props?.faqDetails,
                  Answer: val,
                })
              }
              subTitleTop={"Please add at least 50 characters"}
              error={error?.Answer}
            /> */}
            <ReactQuill
              modules={modules}
              theme="snow"
              value={description}
              onChange={handleDescription}
              placeholder={"Please answer to your question"}
            />
            {error?.Answer ? <span class="input-field-text-error-text-area">{error?.Answer}</span> : null}
          </div>
          <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleDetails({
                  ...props?.faqDetails,
                  Item_ID: val,
                })
              }
              data={GetApiData?.data?.data}
              placeholder={"Select product"}
              selectedVal={props?.faqDetails?.Item_ID}
              name={"Product_Name"}
              label={"Product Name"}
              returnObject={true}
              //   handleSearchWithApi={handleSearchWithApi}
              //   searchWithApi={true}
            />
            {error?.Industry ? <span class="input-field-dropdown-error">{error?.Industry}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqForm;
