import React, { useState } from "react";
import ReactDom from "react-dom";
import EmailVerificationModal from "./emailVerificationModal";
import OtpVerificationModal from "./otpVerificationModal";

const SignInModal = (props) => {
  const [OtpVisible, setOtpVisible] = useState(false);

  return ReactDom.createPortal(
    <>
      <div className="ui dimmer modals page visible active portalModal">
        {/* <div
          className="ui longer modal--max modal modal--left-aligned dtrd-form--modern dtrd-form transition visible active front"
          id="filter-options-modal"
        >
          <a
            className="slide-in__close-btn closeButton"
            onClick={() => props?.handleClose()}
          >
            <i className="feather icon icon-x m-none"></i>
          </a>
          <div className="header text-left">
            <div>Welcome to octate.ai</div>
            <p className="text-md m-t-base">Please sign-in to continue</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a class="ui button icon rounded large tall green">Sign In</a>
          </div>
        </div> */}

        {OtpVisible ? (
          <OtpVerificationModal handleClose={() => props?.handleClose()} />
        ) : (
          <EmailVerificationModal
            toggleScreen={() => setOtpVisible(!OtpVisible)}
            handleClose={() => props?.handleClose()}
          />
        )}
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default SignInModal;
