import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  ClearIsSelected,
  FilterOnKeyChange,
  FilterSelectedData,
} from "../../../hof/hof";
import RequestCategory from "../../../../components/searchResult/components/allCategories/component/requestCategory/requestCategory";
import RequestVendor from "../../../../components/home/blades/requestVendor/requestVendor";
import RequestCustomer from "../../../../components/companyProfile/component/vendorCustomer/components/requestCustomer";

const DropdownWrapper = (props) => {
  const [dropdownList, setDropdownList] = useState([]);
  const [filteredValue, setFilteredValue] = useState("");
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [requestVendorVisible, setRequestVendorVisible] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const name = props?.name;
  const rightSideName = props?.rightSideItemName;
  const Id = props?.Id;
  const handleChangeSelectedData = (val) => {
    const checkIsSelected = val?.isSelected ? { ...val, isSelected: false } : { ...val, isSelected: true };
    props?.handleChangeSelectedData(checkIsSelected);
  };
  const dropDownWrapperHeight = props?.dropDownWrapperHeight;
  const removeCheckBox = props?.removeCheckBox;

  const handleDataFilter = (e) => {
    if (props?.searchWithApi) {
      props?.handleSearchText(e?.target?.value);
      setFilteredValue(e?.target?.value);
    } else {
      if (e?.target?.value) {
        setFilteredValue(e?.target?.value);
        const LIST = FilterOnKeyChange(e?.target?.value, props?.dropdownData, name);
        setDropdownList(LIST);
      } else {
        setFilteredValue("");
        setDropdownList(props?.dropdownData);
      }
    }
  };
  const handleCallAfterDataChanged = () => {
    if (!props?.searchWithApi) {
      if (filteredValue) {
        setFilteredValue(filteredValue);
        const LIST = FilterOnKeyChange(filteredValue, props?.dropdownData);
        setDropdownList(LIST);
      } else {
        setFilteredValue("");
        setDropdownList(props?.dropdownData);
      }
    } else {
      setDropdownList(props?.dropdownData);
    }
  };
  useEffect(() => {
    handleCallAfterDataChanged();
  }, [props?.dropdownData]);
  const handleApplyClicked = () => {
    if (dropdownList?.length) {
      props?.handleApplyClicked(FilterSelectedData(props?.dropdownData));
    }
  };
  const handleClearClicked = () => {
    const clearSelectedData = ClearIsSelected(props?.dropdownData);
    props?.clearSelectedData(clearSelectedData);
  };
  return (
    <>
      {requestModalVisible ? <RequestCategory handleClose={() => setRequestModalVisible((prev) => !prev)} /> : null}
      {requestVendorVisible ? <RequestVendor handleClose={() => setRequestVendorVisible((prev) => !prev)} /> : null}
      {addCustomer ? <RequestCustomer handleClose={() => setAddCustomer((prev) => !prev)} /> : null}
      <span class="dropdown-wrapper" aria-hidden="true">
        <span
          class="select2-dropdown next-gen-select__dropdown select2-dropdown--below"
          dir="ltr"
          style={{
            backgroundColor: "rgb(239, 241, 249)",
            border: "1px solid #f0f0f5",
          }}
        >
          <span class="select2-search select2-search--dropdown">
            <i class="feather icon icon-search"></i>
            <input
              style={{
                backgroundColor: "rgb(239, 241, 249)",
                border: "1px solid #d3d3d3",
              }}
              class="select2-search__field"
              placeholder={props?.filterText ? props?.filterText : "Filter options"}
              type="search"
              role="textbox"
              aria-controls="select2-country-ia-results"
              aria-activedescendant="select2-country-ia-result-w2xw-MH"
              value={filteredValue}
              onChange={handleDataFilter}
            />
          </span>
          <span class={`${dropDownWrapperHeight ? "select-custom-results" : "select2-results"}`}>
            {dropdownList?.length && (!props?.searchData || !filteredValue?.length) ? (
              <ul
                class="select2-results__options"
                role="listbox"
                aria-multiselectable="true"
                id="select2-country-ia-results"
                aria-expanded="true"
                aria-hidden="false"
              >
                {dropdownList?.length &&
                  dropdownList?.map((list) => {
                    return (
                      <li
                        onClick={() => handleChangeSelectedData(list)}
                        class="select2-results__option"
                        id="select2-country-ia-result-cwh8-CK"
                        role="option"
                        aria-selected={list?.isSelected ? "true" : "false"}
                        data-select2-id="select2-country-ia-result-cwh8-CK"
                      >
                        {/* <div class="next-gen-select__checkbox"></div> */}
                        {removeCheckBox ? null : (
                          <input
                            type="checkbox"
                            className="checkbox-inputstyle m-x-mini"
                            checked={list?.isSelected ? true : false}
                          ></input>
                        )}

                        <span className="text-left">
                          {list?.[name]}{" "}
                          {props?.enableViewDomain && (
                            <span className="dropdown-domain">{list?.Customer_Skeleton_Domain}</span>
                          )}
                        </span>
                        {props?.showRightSideItems ? (
                          <span style={{ padding: "5px 8px", color: "#d3d3d3" }}>
                            {list?.[rightSideName] == "Product_Category" ? "Product Category" : "Topic"}
                          </span>
                        ) : null}
                      </li>
                    );
                  })}
              </ul>
            ) : props?.type == "Product_Category" ? (
              <span class="next-gen-select__selected">
                Didn't find right category?{" "}
                <span
                  style={{ cursor: "pointer", color: "#5570f1" }}
                  onClick={() => setRequestModalVisible((prev) => !prev)}
                >
                  Create new
                </span>
              </span>
            ) : props?.type === "Vendor" ? (
              <span class="next-gen-select__selected">
                Didn't find the vendor you are looking for?{" "}
                <span
                  style={{ cursor: "pointer", color: "#5570f1" }}
                  onClick={() => setRequestVendorVisible((prev) => !prev)}
                >
                  Request New{" "}
                </span>
              </span>
            ) : props?.name === "Customer_Name" ? (
              <span class="next-gen-select__selected">
                Didn't find the customer you are looking for?{" "}
                <span style={{ cursor: "pointer", color: "#5570f1" }} onClick={() => setAddCustomer((prev) => !prev)}>
                  Add New{" "}
                </span>
              </span>
            ) : (
              <span class="next-gen-select__selected">No result found</span>
            )}
          </span>
          {props?.hideSaveAndClear ? null : (
            <div class="next-gen-select__buttons">
              <button
                class="outlined tiny button next-gen-select__button next-gen-select__button--clear"
                type="button"
                onClick={handleClearClicked}
              >
                Clear
              </button>
              <button
                onClick={handleApplyClicked}
                class="primary tiny button next-gen-select__button next-gen-select__button--apply"
                type="button"
              >
                Apply
              </button>
            </div>
          )}
        </span>
      </span>
    </>
  );
};

export default DropdownWrapper;
