import React from "react";
import { useSelector } from "react-redux";
import { EncryptPostData } from "../../../../utlis/hof/hof";
import ProposalTable from "../../../proposals/component/proposalTable/proposalTable";

const ProposalsTab = (props) => {
  const VENDOR_ID = props?.VENDOR_ID;
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorId = authData?.data?.vendorId;
  const permissionsDetails = props?.permissionsDetails;
  const CanViewProposal = permissionsDetails?.Read ? true : false;
  return CanViewProposal ? (
    <ProposalTable
      permissionsDetails={permissionsDetails}
      handleActiveRowData={props?.handleActiveRowData}
      VENDOR_ID={vendorId}
    />
  ) : null;
};

export default ProposalsTab;
