import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
// import * as KEYS from "../../../../config/config";

function About(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ProductDetails = useSelector(
    (state) => state?.homeDetails?.productDetails
  );

  return ProductDetails?.Product_Description ? (
    <section class="content-section">
      <h2 class="header"> {KEYS?.PRODUCT_PAGE.ABOUT_PAGE.ABOUT_HEADER}</h2>
      <div>
        {/* <div class="product-content__short-description">
        {ProductDetails?.shortDescription}
      </div> */}
        <div class="product-content__description">
          {ProductDetails?.Product_Description}
        </div>
      </div>
    </section>
  ) : null;
}

export default About;
