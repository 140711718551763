import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import Products from "./component/products/products";
import ProductSection from "./component/products/productSections/productSection";
import RelatedContent from "./relatedContent/relatedContent";
import StickyNav from "./stickyNav";
// import * as KEYS from "../../config/config";
import SearchNavHeader from "../searchResult/components/seachHeader/searchHeader";
import { CapitalizeFirstLetter, GetIdFormLink } from "../../utlis/hof/hof";
import ProviderSection from "./providers/providerSection";
import PopularProducts from "./component/products/component/providerTab/components/popularProducts";
import FAQ from "./component/products/component/providerTab/components/FAQ";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import HeaderSEO from "../SEO/header";
function CategoriesPage(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [applyPadding, setApplyPadding] = useState("");
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const PROVIDERS = KEYS?.HEADER_SEARCH_TYPE.PROVIDER;
  const products = useRef(null);
  const providers = useRef(null);
  const related = useRef(null);
  const PARAMS = useParams();
  const string = PARAMS?.name?.replaceAll("-", " ");
  const providerName = GetIdFormLink(string)?.Name;
  const categoryId = GetIdFormLink(string)?.Id;
  const vendorName = CapitalizeFirstLetter(providerName);
  const TYPE = props.type;
  const handleScrollOnClick = useCallback(
    (val) => {
      setApplyPadding(val);
      if (val === "products") {
        setTimeout(() => {
          products.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }, 50);
      } else if (val === "providers") {
        setTimeout(() => {
          providers.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }, 50);
      } else if (val === "related") {
        setTimeout(() => {
          related.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }, 50);
      }
    },
    [products, providers, related]
  );
  // {
  // };

  return (
    <div
      id="app"
      className="category-page"
      // data-controller="scroll-position"
      // data-scroll-position="0"
    >
      {/* <HeaderSEO data={string} /> */}
      <Header page={props?.page} type={TYPE} show={true} />
      <div className="page-content page-content--reset page-content--top-reset">
        <SearchNavHeader
          page={props?.page}
          parent={"categoryPage"}
          type={TYPE == PROVIDERS ? PROVIDERS : PRODUCTS}
          params={string}
        />
        {TYPE == "vendors" ? null : (
          <div>
            <StickyNav
              page={props?.page}
              handleScrollOnClick={handleScrollOnClick}
              vendorName={vendorName}
            />
          </div>
        )}
        <div className="landing-page__content">
          <div
            className="landing-page__section"
            id="products"
            style={{
              paddingTop: applyPadding === "products" ? "50px" : "0px",
            }}
            ref={products}
          >
            <Products
              page={props?.page}
              categoryId={categoryId}
              vendorName={vendorName}
              type={TYPE}
              handleScrollOnClick={handleScrollOnClick}
            />
          </div>
          {TYPE == "vendors" ? (
            <>
              <div class="landing-page__section" id="products">
                <PopularProducts
                  section={"popular products"}
                  page={props?.page}
                  categoryId={categoryId}
                  vendorName={vendorName}
                />
              </div>
              <div class="landing-page__section" id="faqs">
                <FAQ page={props?.page} section={"popular products"} />
              </div>
            </>
          ) : (
            <>
              {/* <div className="landing-page__section" id="product_sections">
                <ProductSection vendorName={vendorName} />
              </div> */}

              <div
                class="landing-page__section"
                id="providers"
                style={{
                  paddingTop: applyPadding === "providers" ? "65px" : "0px",
                }}
                ref={providers}
              >
                <ProviderSection
                  section={"popular vendors"}
                  page={props?.page}
                  categoryId={categoryId}
                  vendorName={vendorName}
                />
              </div>
              {/* <div
                class="landing-page__section"
                id="related-content"
                style={{
                  paddingTop:
                    applyPadding === "related-content" ? "50px" : "0px",
                }}
                ref={related}
              >
                <RelatedContent />
              </div> */}
            </>
          )}
        </div>
      </div>
      <Footer page={props?.page} />
    </div>
  );
}

export default CategoriesPage;
