import React from "react";
import moment from "moment";
import * as NOTIFICATION_KEYS from "../../../config/config";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { notificationTargetFn } from "../../../contexts/reducer/notificationReducer";
const NotificationCard = (props) => {
  const dispatch = useDispatch();
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const index = props?.index;
  const NOTIFICATION_KEYS_API =
    NOTIFICATION_KEYS?.CONFIG?.NOTIFICATION_API_KEYS;
  const data = props?.data;
  const time = moment(
    data?.[NOTIFICATION_KEYS_API?.CREATED_ON] * 1000
  ).fromNow();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(notificationTargetFn(data));
    // setTimeout(() => {
    //   dispatch(notificationTargetFn({}));
    // }, 5000);
    navigate(data?.[NOTIFICATION_KEYS_API?.PATH]);
    // window.location.replace(data?.[NOTIFICATION_KEYS_API?.PATH]);
    props?.handleNotificationRead(data, index);
  };
  return (
    <div
      onClick={handleClick}
      className={`${
        data?.[NOTIFICATION_KEYS_API?.READ] == "Pending"
          ? "notification-card--active"
          : ""
      } notification-card-main-wrapper cursor--pointer m-y-base`}
    >
      <div className="notification-card-left-side-main-wrapper">
        <img
          className="octate-mobile--notification"
          width="auto"
          height="auto"
          style={{ marginRight: "8px" }}
          src={require("../../../assets/images/OctateIcon.ico")}
          alt={"octate.ai logo"}
        ></img>
      </div>
      <div className="notification-card-right-side-main-wrapper">
        <h3 className="notification-card-description-text">
          {data?.[NOTIFICATION_KEYS_API.DESCRIPTION]}
        </h3>
        <p className="notification-card-date-text">{time}</p>
      </div>
    </div>
  );
};

export default NotificationCard;
