import React, { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../utlis/hoc/infiniteScroll/infiniteScroll";
import InverseInfiniteScroll from "../../../utlis/hoc/infiniteScroll/inverseInfiniteScroll";
import BoxSpinner from "../../../utlis/spinner/boxSpinner";
import ChatList from "./chatList";
import ChatRoomInput from "./chatRoomInput";

function ChatRoomContent() {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const chatRoomListRef = useRef();
  const [conversationList, setConversationList] = useState([]);
  const [PageNo, setPageNo] = useState(1);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userName = authData?.data?.userName;
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  //  http://localhost:8080/getUserMessages?Conversation_ID=6&Page=1
  const Conversation_ID = selectedVendorSelector?.Conversation_ID ? selectedVendorSelector?.Conversation_ID : null;
  useEffect(() => {
    setConversationList([]);
    setPageNo(1);
  }, [selectedVendorSelector?.Conversation_ID]);
  const GetApiData = useEnCryptGetApi(
    `/getUserMessages?Conversation_ID=${Conversation_ID}&Page=${PageNo}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetRequestQuery
  );
  const postSeenConversion = useEnCryptPostApi(
    `/updateSeenMessage`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    const socketConnected = typeof chatSocket === "object" && Object.keys(chatSocket).length ? true : false;
    if (socketConnected) {
      chatSocket.emit("join", `conversion_user_${Conversation_ID}`);
    }
    postSeenConversion.handleTrigger({
      Conversation_ID: Conversation_ID,
      From_Vendor: false,
      Vendor_ID: selectedVendorSelector?.Vendor_ID,
      Date_TIme: parseInt(new Date().getTime() / 1000),
    });
  }, [Conversation_ID]);
  const concatNewArrayFn = useCallback(
    (val) => {
      if (Conversation_ID === val?.Conversation_ID) {
        const newData = { ...val, User_Name: val?.From_Vendor ? selectedVendorSelector?.Vendor_Name : userName };
        let newArray = conversationList?.length ? [...conversationList] : [];
        newArray.push(newData);
        setConversationList(newArray);
        postSeenConversion.handleTrigger({
          Conversation_ID: Conversation_ID,
          From_Vendor: false,
          Vendor_ID: selectedVendorSelector?.Vendor_ID,
          Updated_At: parseInt(new Date().getTime() / 1000),
        });
      }
    },
    [Conversation_ID, conversationList]
  );
  function handler(data) {
    const newArray = concatNewArrayFn(data);
  }
  useEffect(() => {
    if (chatSocket.connected) {
      chatSocket.on(`chat_room_message`, handler);
      return () => chatSocket.off("chat_room_message", handler);
    }
  }, [chatSocket, Conversation_ID, conversationList]);

  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      // let newData = GetApiData?.data?.data?.length ? [...GetApiData?.data?.data] : [];
      // let allNewData = typeof conversationList === "object" && conversationList?.length ? conversationList : [];
      // const concatData = newData.concat[allNewData];
      // const uniqueData = _.uniqBy(concatData, "Conversation_ID");
      const newData = [...GetApiData?.data?.data];
      const concatData = newData.concat(conversationList);
      setConversationList((prev) => concatData);
    }
  }, [GetApiData?.data?.data]);
  // const fetchData = () => {
  //   setPageNo((prev) => prev + 1);
  // };
  const goToPrevScroll = useCallback(
    (oldHeight) => {
      const viewportBottom = oldHeight?.viewportBottom;
      const viewportTop = oldHeight?.viewportTop;
      const { current } = chatRoomListRef;
      if (current && PageNo > 1) {
        setTimeout(() => {
          current.scrollTop = viewportBottom * 2 + chatRoomListRef?.current?.scrollTop;
        }, 1500);
        // current.scrollTop = current.scrollHeight  + current.scrollTop;
      }
    },
    [PageNo]
  );
  const fetchData = async (canScroll) => {
    if (GetApiData?.data?.data?.length) {
      goToPrevScroll(canScroll);
      setPageNo((prev) => prev + 1);
    }

    // goToPrevScroll(canScroll);

    // if (!canScroll) {
    //   setTimeout(() => {
    //     setPageNo((prev) => prev + 1);
    //   }, 500);
    // }
  };
  //   const allChatList = GetApiData?.data?.data?.length ? GetApiData?.data?.data : [];
  return (
    <div className="chat-room-content-main-wrapper">
      <div ref={chatRoomListRef} className="chat-room-content-list-main-wrapper">
        {/* <InverseInfiniteScroll
          Data={conversationList}
          hasMoreData={GetApiData?.data?.data?.length ? true : false}
          fetchData={fetchData}
        > */}
        <ChatList newData={GetApiData} fetchData={fetchData} allChatList={conversationList} />
        {/* </InverseInfiniteScroll> */}
        {GetApiData?.isLoading || GetApiData?.isFetching ? <BoxSpinner /> : null}
      </div>
      <div className="chat-room-input-list-main-wrapper">
        <ChatRoomInput key={selectedVendorSelector?.Conversation_ID} />
      </div>
    </div>
  );
}

export default ChatRoomContent;
