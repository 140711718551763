import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import FilterListMobile from "./filterListMobile";
// import * as KEYS from "../../../../../config/config";

const FilterModal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  const [filterData, setFilterData] = useState([]);
  const filterKeys = Object.keys(filterData);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    if (props?.data) {
      setFilterData(props?.data);
    }
  }, [props?.data]);

  const handleData = (val) => {
    setSelectedData(val);
  };

  const handleApplyChanges = () => {
    const temp = selectedData?.filter((item) => item?.isSelected);
    props?.handleApplyChanges(temp);
    props?.onClose();
  };

  const handleClearAll = () => {
    const temp = [];
    props?.handleApplyChanges(temp);
    props?.onClose();
  };

  return ReactDom.createPortal(
    <>
      <div className="container search">
        <div className="grid-1-3 tablet-landscape-1-col grid-col-gap-md">
          <div className="only-mobile">
            <div className="search__filters-mobile-menu slide-in--on">
              <div>
                <div className="slide-in__header">
                  {FILTER?.FILTER_TITLE}
                  <a className="slide-in__close-btn">
                    <i
                      className="feather icon icon-x m-none"
                      onClick={() => props?.onClose()}
                    ></i>
                  </a>
                </div>

                <form className="dtrd-form--modern dtrd-form">
                  <div className="flex space-between">
                    <a
                      className="small outlined rounded button"
                      onClick={() => handleClearAll()}

                      //   href=""
                    >
                      {FILTER?.CLEAR_ALL}
                    </a>
                    <div
                      //   type="submit"
                      //   name="commit"
                      //   value="Apply filters"
                      className="small primary button"
                      data-disable-with="Apply filters"
                      onClick={() => handleApplyChanges()}
                    >
                      {FILTER?.APPLY}
                    </div>
                  </div>
                  <div class="slide-in__divider m-t-sm m-b-sm"></div>
                  {filterKeys?.map((val, index) => {
                    return (
                      <FilterListMobile
                        label={val}
                        data={filterData?.[val]}
                        key={val}
                        handleData={handleData}
                        onClose={() => props?.onClose()}
                      />
                    );
                  })}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default FilterModal;
