import React from "react";
import { useSelector } from "react-redux";
import * as NO_CONTENT from "../../../config/config";

const NoResult = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const EMPTY_MESSAGE =
    props?.type == "blurbs"
      ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.SEARCH_RESULT_BLURB
      : props?.type == "products"
      ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.SEARCH_RESULT_PRODUCT
      : props?.type == "use-cases"
      ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.SEARCH_RESULT_USE_CASE
      : props?.type == "vendors"
      ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.SEARCH_RESULT_VENDOR
      : "";

  return (
    <>
      {/* <div className="modern-card modern-card--elevated p-x-md posting-nudge flex align-items-center justify-center m-t-md">
        <div className="modern-card__content">
          <div>
            <div className="posting-nudge__cta">
              <h3 className="m-b-sm">{EMPTY_MESSAGE}</h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="search__result data-product-card no-content-card">
        <div className="no-content-text ">{EMPTY_MESSAGE}</div>
      </div>
    </>
  );
};

export default NoResult;
