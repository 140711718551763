import React from "react";
import ProvidersActionButton from "../../../components/providers/component/providersMainContent/providerAlternatives/providersActionButton";
import SmallCardSkeleton from "../skeleton/smallCardSkeleton";
import DataProviderAdditionalInfo from "./dataProviderAdditionalInfo";
import DataProviderFooter from "./dataProviderFooter";
import DataProviderHeader from "./dataProviderHeader";
import DataProviderImage from "./dataProviderImage";
import VendorFirmographics from "../newVendorCard/components/vendorFirmographics";

const DataProviderCard = (props) => {
  const DATA = props?.data?.data;

  return (
    <>
      <div className="grid-three-columns grid-col-gap-sm grid-row-gap-md tablet-landscape-1-col m-t-sm">
        {/* //!map starts with this div */}
        {DATA?.map((item, index) => {
          return (
            <div className="vertical-provider-card modern-card modern-card--elevated" key={index}>
              {/* <DataProviderImage data={item} /> */}
              <DataProviderHeader page={props?.page} section={props?.section} data={item} />
              <div className="vertical-provider-card__text">{item?.About}</div>
              {props?.parent == "alternativeVendor" ? null : (
                <DataProviderAdditionalInfo page={props?.page} section={props?.section} data={item} />
              )}
              {props?.parent == "alternativeVendor" ? (
                <ProvidersActionButton page={props?.page} section={props?.section} data={item} />
              ) : (
                <DataProviderFooter page={props?.page} section={props?.section} data={item} />
              )}
            </div>
          );
        })}
        {props?.isFetching || props?.isLoading ? <SmallCardSkeleton skeletonLength={props?.skeletonLength} /> : null}
      </div>
    </>
  );
};

export default DataProviderCard;
