import React, { useEffect } from "react";
import { useState } from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { usePostUserPermissionsMutation } from "../../../../adapters/xhr/crudServiceApi";
const PermissionResourceSwitch = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [Preferences, setPreferences] = useState(props?.checked);
  const Can_Edit = props?.data?.Can_Edit ? true : false;
  const PreferencesPostApi = useEnCryptPostApi(
    `/permission/updatePermissions`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPermissionsMutation
  );
  useEffect(() => {
    setPreferences(props?.checked);
  }, [props?.checked]);
  const handleChange = (val) => {
    setPreferences((prev) => !prev);
    const RAW_DATA = {
      Permission_Set_ID: props?.permissionSetId,
      Resource_ID: props?.resourceId,
      Controls: { ...props?.data, Status: !Preferences },
    };
    PreferencesPostApi?.handleTrigger(RAW_DATA);
  };

  return (
    // <Switch
    //   offColor="#F1EBFF"
    //   onColor="#CAD3FC"
    //   offHandleColor="#BBC5CB"
    //   onHandleColor="#5570F1"
    //   handleDiameter={16}
    //   uncheckedIcon={false}
    //   checkedIcon={false}
    //   height={20}
    //   width={40}
    //   className="react-switch"
    //   onChange={handleChange}
    //   checked={Preferences}
    // />
    <input
      type="checkbox"
      disabled={!Can_Edit}
      className="table-checkbox"
      checked={Preferences}
      onClick={handleChange}
    />
  );
};

export default PermissionResourceSwitch;
