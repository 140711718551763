import React from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import ProductTags from "./productTags";

const ProductContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.PRODUCT_NAME_CLICKED,
    page: "product page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleProductNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "Product",
      Item_Id: DATA?.Product_ID,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div
      onClick={() =>
        handleProductNameLinkClicked(
          `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            DATA?.Product_Name,
            DATA?.Product_ID
          )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
        )
      }
      className="product-card-content-container"
    >
      <a
        href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          DATA?.Product_Name,
          DATA?.Product_ID
        )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
        className="product-card-description"
      >
        {DATA?.Product_Description ? (
          <p className="product-card-description">{parse(DATA?.Product_Description)}</p>
        ) : null}
      </a>
      <ProductTags data={DATA} page={props?.page} />
      {/* <ProductFirmographics data={DATA} page={props?.page} section={props?.section} /> */}
    </div>
  );
};

export default ProductContent;
