import React, { useEffect } from "react";
import { useState } from "react";
import "../../../styles/select_octate.css";
import DropdownSelection from "./component/dropdownSelection";
import DropdownWrapper from "./component/dropdownWrapper";
import { concatIsSelected, FilterSelectedData } from "../../hof/hof";
function Dropdown(props) {
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [dropdownData, setDropdownData] = useState(props?.data ? props?.data : []);
  const error = props?.error;
  const name = props?.name ? props?.name : "name";
  const Id = props?.Id ? props?.Id : "id";
  const removeCheckBox = props?.removeCheckBox;
  const searchWithApi = props?.searchWithApi;
  const dropDownWrapperHeight = props?.dropDownWrapperHeight;
  useEffect(() => {
    if (props?.data?.length) {
      setDropdownData(props?.data);
    } else {
      setDropdownData([]);
    }
  }, [props?.data]);
  const handleToggleDropdownWrapper = (val) => {
    setToggleDropDown(val);
  };
  const handleChangeSelectedData = (val) => {
    const onChangeData = concatIsSelected(val, dropdownData, Id);
    setDropdownData(onChangeData);
    props?.handleSelectedDataOnChange(FilterSelectedData(onChangeData));
  };
  const handleApplyClicked = (val) => {
    setToggleDropDown(false);
    props?.handleApplyClicked(val);
  };
  const clearSelectedData = (val) => {
    setDropdownData(val);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleDropDown(false);
    }
  };
  return (
    <div
      tabIndex={0}
      // onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <div className="dtrd-form">
        {/* <div class={props?.containerStyle}> */}
        <div>
          <div class="item-search-filters">
            <div class="field item-search-filters__filter" id="field-country">
              <span
                className={
                  toggleDropDown
                    ? "select2 select2-container select2-container--default select2-container--below select2-container--open"
                    : "select2 select2-container select2-container--default select2-container--below "
                }
                dir="ltr"
                style={{ width: "100%" }}
              >
                <DropdownSelection
                  handleToggleDropdownWrapper={handleToggleDropdownWrapper}
                  toggleDropDown={toggleDropDown}
                  dropdownData={dropdownData}
                  label={props?.label}
                  name={name}
                  Id={Id}
                  selectedSmall={props?.selectedSmall}
                  tableDropDown={props?.tableDropDown ? true : false}
                  type={props?.type}
                />

                <DropdownWrapper
                  dropDownWrapperHeight={dropDownWrapperHeight}
                  removeCheckBox={removeCheckBox}
                  name={name}
                  Id={Id}
                  showRightSideItems={props?.showRightSideItems}
                  rightSideItemName={props?.rightSideItemName}
                  selectedValue={props?.selectedValue}
                  handleSearchText={props?.handleSearchText}
                  searchWithApi={searchWithApi}
                  hideSaveAndClear={props?.hideSaveAndClear}
                  clearSelectedData={clearSelectedData}
                  handleApplyClicked={handleApplyClicked}
                  toggleDropDown={toggleDropDown}
                  dropdownData={dropdownData}
                  handleChangeSelectedData={handleChangeSelectedData}
                  filterText={props?.filterText}
                  enableViewDomain={props?.enableViewDomain}
                  type={props?.type}
                  searchData={props?.searchData}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      {error ? <span class="input-field-dropdown-multiselect-error">{error}</span> : null}
    </div>
  );
}

export default Dropdown;
