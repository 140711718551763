import { ToTitleCase } from "../../../../utlis/hof/hof";
import React, { useState } from "react";
import PermissionResourceSwitch from "./pemissionResourceSwitch";
import FeatherIcon from "feather-icons-react";
const PermissionControls = (props) => {
  const [showSection, setShowSection] = useState(false);
  const list = props?.data;
  return (
    <>
      <div
        style={{
          width: "50%",
          display: "flex",
          minWidth: "350px",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
          borderBottom: "1px solid #e1e2e9",
          marginTop: "15px",
          padding: "10px 0px",
        }}
      >
        <h3 className="permission-section-header ">{ToTitleCase(list?.Resource_Name)}</h3>
        <FeatherIcon
          onClick={() => setShowSection(!showSection)}
          className="cursor--pointer"
          icon={showSection ? "chevron-down" : "chevron-right"}
          size="25"
          color={"#080808"}
        />
      </div>

      {showSection &&
        list?.Controls.map((item) => {
          return (
            <div className="permission-selection-container" key={item?.Display_Name}>
              <PermissionResourceSwitch
                permissionSetId={props?.permissionSetId}
                resourceId={props?.resourceId}
                checked={item?.Status}
                data={item}
              />
              <h4 className="preferences-section-options">{item?.Display_Name}</h4>
            </div>
          );
        })}
    </>
  );
};

export default PermissionControls;
