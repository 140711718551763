import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
const PopularUseCaseHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const boardUserId = props?.boardUserId;
  const DATA = props?.data;
  const cardType = props?.cardType;
  const page = props?.page;
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };

  const handleMenuOptions = () => {
    setFeedbackModalVisible((prev) => !prev);
  };

  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "vendor",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <>
      <h1 className="new-case-study-card-title" data-inverted="true" title={DATA?.title_level_1}>
        {DATA?.title_level_1}
      </h1>
      {/* <div className="product-card-header" style={{ justifyContent: "flex-start", flexWrap: "wrap" }}>
        <div className="product-card-header-details">
          <img
            class="case-studies-card-logo"
            alt={` ${DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
            data-src={DATA?.account_logo ? DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON}
            src={DATA?.account_logo ? DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
          <div className="product-card-vendor-details" style={{ minWidth: "fit-content" }}>
            <div
              onClick={() =>
                handleVendorNameLinkClicked(
                  `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                    KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                  }`
                )
              }
            >
              <a
                href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                }`}
              >
                <p className="product-card-product-name" style={{ fontSize: "15px" }}>
                  {DATA?.Vendor_Name}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className="popular-header-for-text">for</p>
        </div>
        <div tabIndex={0} onBlur={handleBlur} className="product-card-header-options p-r">
          <img
            height="30px"
            src={DATA?.Customer_Additional_Details?.Usecase_Customer_Logo}
            alt={"customer logo"}
            style={{ objectFit: "contain", maxWidth: "120px" }}
          ></img>
        </div>
      </div> */}
    </>
  );
};

export default PopularUseCaseHeader;
