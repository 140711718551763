import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import EmailVerificationOctate from "../signUp/emailVerification/emailVerificationOctate";
import CompleteProfile from "./components/completeProfile";
import JoinOrClaimOrganization from "./components/joinOrClaimOrganization";

const JoinOrganization = () => {
  const [claimOrganization, setClaimOrganization] = useState(true);
  const tempDetails = useSelector((state) => state?.authDetails?.tempDetails);
  const authData = useSelector((state) => state?.authDetails?.details);
  // dispatch(
  //   tempAuthDetails({
  //     data: { ...authData?.data },
  //     loading: true,
  //     verifyOTP: false,
  //     joinOrg: true,
  //     resetPassword: true,
  //   })
  // );
  return authData?.data?.isValidDomain || tempDetails?.joinOrg ? (
    <>
      <main>
        <div class="page-content page-content--reset">
          <div class="splitted-page">
            {/* <OtpBanner /> */}
            <EmailVerificationOctate />
            {claimOrganization ? (
              <JoinOrClaimOrganization completeStep={() => setClaimOrganization(false)} />
            ) : (
              <CompleteProfile />
            )}
          </div>
        </div>
      </main>
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default JoinOrganization;
