import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetProductsApiQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import NewUseCaseCard from "../../../../utlis/hoc/newUseCaseCard/newUseCaseCard";
import UseCaseCardList from "../../../../utlis/hoc/useCaseCard/useCaseCardList";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import * as NO_CONTENT from "../../../../config/config";

const UseCase = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [noResultsData, setNoResultsData] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const cardType = "use-case";
  const tabIndex = props?.tabIndex;
  const VENDOR_ID = props?.vendorId;

  const GetApiData = useEnCryptGetApi(
    `/getAllUseCaseBlurbs?productId=${PRODUCT?.Id}&limit=30&vendorId=${VENDOR_ID}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetProductsApiQuery
  );

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(GetApiData?.data?.data));
      } else {
        setLazyLoadData(GetApiData?.data?.data);
      }
    } else if (typeof GetApiData?.data?.data === "object" && GetApiData?.data?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [GetApiData?.data]);

  return (
    <div>
      {lazyLoadData?.length < 0 && (GetApiData?.isLoading || GetApiData?.isFetching) ? (
        <ProductSkeleton page={props?.page} />
      ) : lazyLoadData?.length > 0 ? (
        <InfiniteScrollComp
          Data={lazyLoadData}
          hasMoreData={GetApiData?.data?.data?.length >= 30}
          fetchData={fetchData}
        >
          <NewUseCaseCard cardType={cardType} page={props?.page} data={lazyLoadData} parent={props?.parent} />
        </InfiniteScrollComp>
      ) : noResultsData ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_USE_CASE}</div>
        </div>
      ) : null}
    </div>
  );
};

export default UseCase;
