import React from "react";
import BriefCase from "../../../../../assets/svgIcons/briefCase";
import CategoriesSvg from "../../../../../assets/svgIcons/categoriesSvg";
import HashSvg from "../../../../../assets/svgIcons/hashSvg";
import ProductsSvg from "../../../../../assets/svgIcons/productsSvg";
import ProviderSvg from "../../../../../assets/svgIcons/provider";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
const ProvidersList = (props) => {
  const TYPE =
    props?.name == "Attributes"
      ? "Attributes"
      : props?.name == "Categories"
      ? "Categories"
      : props?.name == "Use Cases"
      ? "Use-Cases"
      : props?.name == "Vendors"
      ? "vendor"
      : props?.name == "Products"
      ? "product"
      : "";
  return (
    <div className="result-group">
      <div className="header">
        {props?.name == "Attributes" ? (
          <HashSvg />
        ) : props?.name == "Categories" ? (
          <CategoriesSvg />
        ) : props?.name == "Use Cases" ? (
          <BriefCase />
        ) : props?.name == "Vendors" ? (
          <ProviderSvg />
        ) : props?.name == "Products" ? (
          <ProductsSvg />
        ) : null}
        <span>{props?.name}</span>
      </div>
      {props?.list?.map((item) => {
        const parts = item?.name?.split(
          new RegExp(`(${props?.inputText})`, "gi")
        );
        return (
          <a
            className="result"
            href={`/${TYPE}/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}`}
          >
            <div className="content">
              {parts.map((part, i) => (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === props?.inputText.toLowerCase()
                      ? { fontWeight: "bold" }
                      : { fontWeight: "normal" }
                  }
                >
                  {part}
                </span>
              ))}
            </div>
            <i className="feather icon icon-arrow-right "></i>
          </a>
        );
      })}
    </div>
  );
};
export default ProvidersList;
