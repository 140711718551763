import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { usePostRequestMutation } from '../../../../adapters/xhr/generalApi';
import { useEnCryptSignalTrackingApi } from '../../../../utlis/customHooks/customHooks';
import { GetIdFormLink, ToTitleCase } from '../../../../utlis/hof/hof';
const SearchHeaderBreadCrumb = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const itemName = GetIdFormLink(props?.params);
  const CONFIG = KEYS;
  const signalTrackingBreadCrumbObject = {
    Action: 'Bread crumb clicked',
    page: props?.page,
    section: 'bread crumb',
  };

  const DATA =
    props?.parent == 'searchPage' && props?.type == 'products'
      ? CONFIG?.BREAD_CRUMB_SEARCH_PRODUCTS
      : props?.parent == 'searchPage' && props?.type == 'vendors'
      ? CONFIG?.BREAD_CRUMB_SEARCH_PROVIDERS
      : props?.parent == 'searchPage' && props?.type == 'use-cases'
      ? CONFIG?.BREAD_CRUMB_SEARCH_USE_CASE
      : props?.parent == 'providerPage'
      ? CONFIG?.BREAD_CRUMB_PROVIDERS
      : props?.parent == 'productPage'
      ? props?.data
      : props?.parent == 'categoryPage'
      ? CONFIG?.BREAD_CRUMB_CATEGORIES
      : props?.parent == 'topicPage'
      ? CONFIG?.BREAD_CRUMB_CATEGORIES
      : props?.parent == 'allCategoryPage'
      ? CONFIG?.BREAD_CRUMB_ALL_CATEGORIES
      : props?.parent == 'profile'
      ? CONFIG?.BREAD_CRUMB_PROFILE
      : props?.parent == 'editProfile'
      ? CONFIG?.BREAD_CRUMB_EDIT_PROFILE
      : props?.parent == 'blurbPage'
      ? props?.data
      : props?.parent == 'boards'
      ? props?.data
      : props?.parent == 'searchPage' && props?.type == 'blurbs'
      ? CONFIG?.BREAD_CRUMB_SEARCH_BLURBS
      : props?.parent == 'board detail page'
      ? props?.data
      : props?.parent == 'blurbDetail'
      ? props?.data
      : props?.parent == 'usecaseDetail'
      ? props?.data
      : props?.parent == 'ManagePreference'
      ? props?.data
      : props?.data
      ? props?.data
      : null;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleBreadCrumbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: val?.page,
      Item_Id: null,
      Target: {
        page: val?.page,
        link: val?.link,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: val?.name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div className='attached-navbar__breadcrumbs'>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }} className='ui breadcrumb'>
        {DATA?.length &&
          DATA.map((list) => {
            return list?.name == 'Home' ? (
              <div onClick={() => handleBreadCrumbLinkClicked(list)}>
                <a href={list?.link}>
                  <i className='feather icon icon-home '></i>
                  <span>{list?.name}</span>
                </a>
                <span className='divider'>/</span>
              </div>
            ) : list.active ? (
              <div onClick={() => handleBreadCrumbLinkClicked(list)} style={{ display: 'block' }}>
                <a href={list.link}>{list?.name?.length > 30 ? `${list?.name?.slice(0, 30)}...` : list?.name}</a>
                <span className='divider' style={isMobile ? { display: 'none' } : {}}>
                  /
                </span>
              </div>
            ) : (
              <>
                <span style={{ color: '#95a4b2' }}>{list?.name?.length > 30 ? `${list?.name?.slice(0, 30)}...` : list?.name}</span>
              </>
            );
          })}
        {/* {props?.params && props?.parent == "searchPage" ? (
          <>
            <div className="active section">
              <ListCount
                pageNo={props?.pageNo}
                type={props?.type}
                totalNumber={props?.total}
                searchText={props?.params}
              />
            </div>
          </>
        ) : null} */}
        {props?.params ? (
          <>
            <div className='active section'>{ToTitleCase(itemName?.Name)}</div>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default SearchHeaderBreadCrumb;
