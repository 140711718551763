import React from "react";
import { useSelector } from "react-redux";
import ProvidersActionButton from "../../../components/providers/component/providersMainContent/providerAlternatives/providersActionButton";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import FeatherIcon from "feather-icons-react";
import AdditionalInfo from "./additionalInfo";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import VendorFirmographics from "../newVendorCard/components/vendorFirmographics";
import * as SECRET_KEYS from "../../../config/configKeys";
import ProviderCardFirmo from "../providerCardSmall/providerCardFirmo";
// import * as KEYS from "../../../../../config/config";

function VendorCard(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const allProducts = props?.data;
  const signalTrackingVendorsObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_CARD_CLICKED,
    page: "vendor page",
    section: props?.section,
    context: props?.allProducts?.Product_Name,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorLinkClicked = (val, actions) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: actions,
      Item_Type: signalTrackingVendorsObject?.page,
      Item_Id: val?.Vendor_ID,
      Target: {
        page: signalTrackingVendorsObject?.page,
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
        }`,
      },
      Object: {
        section: signalTrackingVendorsObject?.section,
        context: val?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return allProducts?.length ? (
    <div class="provider-profile__main-content-section" id="provider-profile-alternatives">
      {/* <h2>
        {vendorName?.Name} {KEYS?.VENDOR_ALTERNATIVE.TITLE}
      </h2>
      <div class="provider-profile__subtitle">
        {KEYS?.VENDOR_ALTERNATIVE.SUB_TITLE} {vendorName?.Name}.
      </div> */}
      <div class="grid-two-columns col-gap-md tablet-1-col tablet-landscape-1-col grid-col-gap-md">
        {allProducts?.length
          ? allProducts.map((val) => {
              const vendorInfo = val;
              return (
                <div class="provider-profile__alternative">
                  <a
                    class="provider-profile__alternative__header m-b-base"
                    onClick={() => handleVendorLinkClicked(vendorInfo, "vendor card clicked")}
                    href={`/vendor/${ReplaceBlankSpaceByHyphen(vendorInfo?.Vendor_Name, vendorInfo?.Vendor_ID)}/${
                      KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                    }`}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="flex align-items-center">
                      <img
                        class="provider-profile__alternative__header-logo ls-is-cached lazyloaded"
                        data-src={vendorInfo?.Vendor_Logo_URL ? vendorInfo?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
                        src={vendorInfo?.Vendor_Logo_URL ? vendorInfo?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                        }}
                        alt={`${vendorInfo?.Vendor_Name} thumbnail`}
                      />
                      <div>
                        <div class="provider-profile__alternative__header-name m-b-half-base">
                          {vendorInfo?.Vendor_Name}
                        </div>
                        <div className="customer-card-sub-data">
                          {vendorInfo?.Industry ? (
                            <i className="feather icon icon-align-left " title="Industry"></i>
                          ) : null}
                          {vendorInfo?.Industry}
                        </div>
                        <div className=" customer-card-sub-data">
                          {vendorInfo?.Vendor_HQ_Country && props?.tabIndex === "customers" ? (
                            <>
                              <i className="feather icon icon-map-pin " title="Headquarters"></i>
                              {vendorInfo?.Vendor_HQ_Country}
                            </>
                          ) : null}
                        </div>
                        {/* <div className=" customer-card-sub-data">
                            {vendorInfo?.Customer_Source && props?.tabIndex === "customers" ? (
                              <>
                                <i className="feather icon icon-user-check "></i>
                                {vendorInfo?.Customer_Source}
                              </>
                            ) : null}
                          </div> */}
                      </div>
                    </div>
                  </a>
                  <div class="provider-profile__alternative__content">
                    <div class="provider-profile__alternative__header-description vendor-card-description-wrap-text">
                      {vendorInfo?.About}
                    </div>
                  </div>
                  <ProviderCardFirmo
                    data={vendorInfo}
                    page={"Home"}
                    section={"Popular vendor"}
                    pageType={props?.page}
                  />

                  {/* <ProvidersActionButton data={vendorInfo} /> */}
                  {/* <VendorFirmographics data={vendorInfo} page={"Home"} section={"Popular vendor"} /> */}
                  {/* <div
                    className="flex-container"
                    style={{
                      marginBottom: "5%",
                      justifyContent: "space-between",
                    }}
                  >
                    <AdditionalInfo data={vendorInfo?.Vendor_HQ_Country} icon={"map-pin"} name="Headquarter" />
                    <AdditionalInfo data={vendorInfo?.Company_Size} icon={"users"} name="Number of Employees" />
                    <AdditionalInfo
                      data={vendorInfo?.Revenue_Range}
                      // icon={"dollar-sign"}
                      name="Revenue"
                    />
                  </div> */}
                  {props?.type === "buyer" ||
                  props?.page === "product page-customers" ||
                  props?.page === "business profile" ? null : (
                    <div class="vertical-product-card-for-provider__footer grid-two-columns tablet-1-col grid-col-gap-base m-t-mini">
                      <a
                        class="vertical-product-card-for-provider__cta small primary modern button"
                        onClick={() => handleVendorLinkClicked(vendorInfo, "view vendor clicked")}
                        href={`/vendor/${ReplaceBlankSpaceByHyphen(vendorInfo?.Vendor_Name, vendorInfo?.Vendor_ID)}/${
                          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                        }`}
                      >
                        {KEYS?.VENDOR_ALTERNATIVE.VIEW}
                        <i className="feather icon icon-arrow-right "></i>
                      </a>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>
      {/* {allProducts?.length ? (
        <a
          class="display-block m-t-sm text-center"
          href={`/alternative-vendors/${ReplaceBlankSpaceByHyphen(
            vendorName?.Name,
            vendorName?.Id
          )}`}
        >
          {KEYS?.VENDOR_ALTERNATIVE.MORE}
        </a>
      ) : null} */}
    </div>
  ) : null;
}

export default VendorCard;
