import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import { validateAddPermissionSetError, validateProposalQuestionFormError } from "../../../../utlis/hof/hof";
const AddNewPermissionSet = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const addType = props?.addType;
  const [fields, setFields] = useState(addType == "section" ? {} : []);
  const [proposalQuestionFormError, setProposalQuestionFormError] = useState({});
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const data = props?.data;
  const questionListIndex = data?.Permission_Set_ID;
  const proposalBasicDetails = fields;

  useEffect(() => {
    setFields(props?.data);
  }, [data]);

  const handleSaveClicked = () => {
    let validateErrors = {};
    validateErrors = validateAddPermissionSetError(fields, props?.allData);
    if (!Object.keys(validateErrors)?.length) {
      props?.handleSaveClicked({ ...fields, User_ID: userId, Vendor_ID: vendorId });
    } else {
      setProposalQuestionFormError(validateErrors);
      setTimeout(() => {
        setProposalQuestionFormError({});
      }, 2000);
    }
  };
  function handleDeleteClicked() {
    props.handleDeleteClicked({ ...fields, User_ID: userId, Vendor_ID: vendorId });
  }
  return (
    <div className={`confirm-delete-portal-main-wrapper p-r`}>
      <div className="add-section-and-question-header-wrapper">
        <div>
          <h3 className="add-section-and-question-content-header-text">
            {addType == "section"
              ? PROPOSAL_ADD_SECTION?.SECTION_HEADER
              : `${questionListIndex ? "Edit" : "Add"} permission set`}
          </h3>
        </div>
        {questionListIndex ? (
          <div
            onClick={handleDeleteClicked}
            className="dis-f-r cursor--pointer"
            style={{
              borderRadius: "5px",
              border: "1px solid #ffff",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "5px 10px",
            }}
          >
            <p className="add-section-and-question-delete-text">{PROPOSAL_ADD_SECTION?.SECTION_DELETE}</p>
            <FeatherIcon icon="trash" size="12" color="#ffffff" />
          </div>
        ) : null}
      </div>

      <div class="field p-r m-y-company-profile-input-wrapper m-x-sm" id="field-user__email">
        {proposalBasicDetails?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE] ? (
          <label for="user__email" className="company-details-label-input-field-filled-absolute">
            Permission set name
          </label>
        ) : null}
        <InputFieldProfile
          label="Permission set name"
          value={proposalBasicDetails?.Permission_Set_Name}
          changeText={(val) =>
            setFields({
              ...fields,
              Permission_Set_Name: val,
            })
          }
          error={proposalQuestionFormError?.Permission_Set_Name}
        />
      </div>

      <div className="confirm-delete-portal-header-actions-wrapper ">
        <div onClick={handleSaveClicked}>
          <p className="m-x-sm add-section-and-question-action-save-text cursor--pointer">
            {PROPOSAL_ADD_SECTION?.SAVE}
          </p>
        </div>
        <div onClick={props?.handleClose}>
          <p className="add-section-and-question-action-cancel-text cursor--pointer">{PROPOSAL_ADD_SECTION?.CANCEL}</p>
        </div>
      </div>
    </div>
  );
};

export default AddNewPermissionSet;
