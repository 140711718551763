import React from "react";
import UserDetailsForm from "./userDetailsForm";
import EmailVerificationOctate from "../emailVerification/emailVerificationOctate";
import { useState } from "react";

const Onboarding = (props) => {
  const [consumerAccount, setConsumerAccount] = useState(true);
 
  return (
    <>
      <main>
        {/* <HeaderSEO data={props?.headerTitle} /> */}
        <div class="page-content page-content--reset ">
          <div class="splitted-page">
            {/* <OnboardBanner /> */}
            <EmailVerificationOctate consumerAccount={consumerAccount} />
            <UserDetailsForm page={props?.page} handleStatus={(val) => setConsumerAccount(val)} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Onboarding;
