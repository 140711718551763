import React, { useEffect, useState } from "react";
import BusinessDetails from "../../businessProfile/components/businessOverview/businessDetails";
import ProposalOverview from "../../businessProfile/components/proposalOverView/proposalOverview";
import AnswerProposalTab from "../../editProposal/component/answerProposalTab/answerProposalTab";
import VendorListPage from "../../editProposal/component/vendorsListPage/vendorListPage";
import * as CONFIG_KEYS from "../../../config/config";
import PreviewProposal from "../../answerProposal/component/previewProposal";
import ShowVendorProposal from "./showVendorAnswers";
import { useDispatch } from "react-redux";

const EditProposalList = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showSelectedVendorDetails, setShowSelectedVendorDetails] = useState({});
  const dispatch = useDispatch();
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const notificationJson = props?.notificationJson;
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const notificationJsonVendorId = notificationJson?.["Object"]?.[PROPOSAL_KEYS?.VENDOR_ID]
    ? {
        [PROPOSAL_KEYS?.VENDOR_ID]: notificationJson?.["Object"]?.[PROPOSAL_KEYS?.VENDOR_ID],
      }
    : null;

  const screenToShow = props?.footerNumberClicked;
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const EDIT_QUESTION_TAB = [
    { label: "Questionnaire", id: 1 },
    { label: "Vendors", id: 2 },
  ];
  const handleActiveRowData = (val, index) => {
    setShowSelectedVendorDetails(val);
  };
  const handleCloseSelectedVendor = (val) => {
    setShowSelectedVendorDetails({});
    // dispatch(notificationTargetFn({}));
  };
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  useEffect(() => {
    if (
      notificationJsonVendorId?.[PROPOSAL_KEYS?.VENDOR_ID] &&
      notificationJson?.["Object"]?.Action == "Vendor_Deleted_Proposal"
    ) {
      setActiveTabIndex(2);
    } else if (notificationJsonVendorId?.[PROPOSAL_KEYS?.VENDOR_ID]) {
      setShowSelectedVendorDetails(notificationJsonVendorId);
    }
  }, [notificationJsonVendorId?.[PROPOSAL_KEYS?.VENDOR_ID]]);
  const showVendorProposalBoolean = showSelectedVendorDetails?.[PROPOSAL_KEYS?.VENDOR_ID] ? true : false;
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {showVendorProposalBoolean ? (
        <ShowVendorProposal
          USER_CAN_EDIT={USER_CAN_EDIT}
          handleCloseSelectedVendor={handleCloseSelectedVendor}
          proposalType={"buyer"}
          notificationJson={notificationJson}
          vendorDetails={showSelectedVendorDetails}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
        />
      ) : (
        <div className="answer-proposal-grid-business-profile-reverse m-x-mini m-t-sm mobile-1-col">
          <div
            style={{
              background: "#fff",
              borderRadius: "15px",
              paddingBottom: "20px",
            }}
          >
            <div
              className="flex"
              style={{
                alignItems: "start",
                alignSelf: "start",
                padding: "10px 0px 0px 0px",
                borderBottom: "1px solid #e1e2e9",
              }}
            >
              {EDIT_QUESTION_TAB?.map((item, index) => {
                return (
                  <AnswerProposalTab
                    USER_CAN_EDIT={USER_CAN_EDIT}
                    tabItem={item}
                    index={index}
                    activeTabIndex={activeTabIndex}
                    handleActiveTab={(val) => setActiveTabIndex(val)}
                  />
                );
              })}
            </div>
            {activeTabIndex == 0 ? (
              <PreviewProposal
                USER_CAN_EDIT={USER_CAN_EDIT}
                PROPOSAL_TYPE={PROPOSAL_TYPE}
                //   validateEmailId={props?.validateEmailId}
                StaticDropdownData={StaticDropdownData}
                companyErrors={props?.companyErrors}
                handleDetails={handleDetails}
                proposalDetails={proposalDetails}
              />
            ) : (
              <VendorListPage
                USER_CAN_EDIT={USER_CAN_EDIT}
                handleDetails={props?.handleDetails}
                proposalDetails={proposalDetails}
                handleActiveRowData={handleActiveRowData}
              />
            )}
          </div>
          <div className="mobile-hidden" style={{ alignItems: "start", textAlign: "start" }}>
            <ProposalOverview
              USER_CAN_EDIT={USER_CAN_EDIT}
              PROPOSAL_TYPE={PROPOSAL_TYPE}
              connectWithOctate={true}
              type={props?.type}
              activeRowProposal={proposalDetails}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProposalList;

{
  /* screenToShow == 2 ? (
        <AddFeature
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : (
        <PlanAndPricing
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) */
}
