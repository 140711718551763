import React , {useEffect} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import StickyTab from "../../utlis/hoc/tabNavigator/stickyTab";
import { GetIdFormLink } from "../../utlis/hof/hof";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import CategoryHeader from "./components/categoryHeader/categoryHeader";
import BlurbsTab from "./components/tabs/blurbsTab";
import ProductTab from "./components/tabs/productTab";
import UseCaseTab from "./components/tabs/useCasesTab";
import VendorTab from "./components/tabs/vendorTab";
import {updateMetaTags } from "../../../src/components/SEO/seo";

const ProductCategories = (props) => {
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const scrollPosition = useScrollPosition();
  const TYPE = props?.TYPE;
  const CATEGORY = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const page = `${props?.page}-${tabIndex}`;

  useEffect(() => {
    const seoBody = {
      type : 'category',
      id : CATEGORY?.Id,
      tab : tabIndex
    }
    updateMetaTags(seoBody);
  }, []);

  return (
    <>
      <Header page={page} show={true} />
      <div className="attached-navbar ">
        <div className="fixed-nav-container flex space-between align-items-bottom ui container">
          <SearchHeaderBreadCrumb page={page} parent={"categoryPage"} type={tabIndex} params={CATEGORY?.Name} />
        </div>{" "}
      </div>
      <div className="business-profile-landing-page-container ui container">
        <CategoryHeader
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          page={page}
        />
        <StickyTab
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          tabData={KEYS?.CATEGORY_TAB}
          scrollPosition={scrollPosition}
          navigatePath={"category"}
          page={page}
        />
        {tabIndex == "products" ? (
          <ProductTab page={page} />
        ) : tabIndex == "vendors" ? (
          <VendorTab page={page} />
        ) : tabIndex == "blurbs" ? (
          <BlurbsTab page={page} />
        ) : tabIndex == "use-cases" ? (
          <UseCaseTab page={page} />
        ) : (
          <BlurbsTab page={page} />
        )}
      </div>
      <Footer page={page} />
    </>
  );
};

export default ProductCategories;
