import React, { useState } from "react";
import FiltersList from "./filtersList";
import TemplateList from "./templateList";

function ProposalTemplate(props) {
  const [allCategoriesId, setAllCategoriesId] = useState([]);
  const [page, setPage] = useState(1);
  function getAllCategoriesId(val) {
    setAllCategoriesId(val);
    setPage(1);
  }
  const handlePage = (val) => {
    setPage(val);
  };
  return (
    <div className="proposal-template-grid-business-profile m-x-mini m-t-sm mobile-1-col p-r">
      <div style={{ position: "sticky", top: "20px", height: "max-content" }}>
        <div className="mobile-hidden">
          <FiltersList getAllCategoriesId={getAllCategoriesId} />
        </div>
      </div>
      <div style={{ borderLeft: "1px solid #d3d3d3", paddingLeft: "10px" }}>
        <TemplateList
          handlePage={handlePage}
          page={page}
          allCategoriesId={allCategoriesId}
          handleFooterNumberFromTemplateClicked={props?.handleFooterNumberFromTemplateClicked}
        />
      </div>
    </div>
  );
}

export default ProposalTemplate;
