import React from "react";
import AddingCustomer from "./addingCustomers";
import CompanyBasicDetails from "./companyBasicDetails";
import CompanyCommunicationSetup from "./companyCommunicationSetup";
import SetupFeatures from "./setupFeatures";
import VendorCustomer from "./vendorCustomer/vendorCustomer";

const CompanyDetailsList = (props) => {
  const screenToShow = props?.footerNumberClicked;
  const companyDetails = props?.companyDetails;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {screenToShow == 1 ? (
        <CompanyBasicDetails
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          companyDetails={companyDetails}
          action={props?.action}
          handleSelectedImage={props?.handleSelectedImage}
        />
      ) : screenToShow == 2 ? (
        <CompanyCommunicationSetup
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          companyDetails={companyDetails}
        />
      ) : (
        // <SetupFeatures
        //   StaticDropdownData={StaticDropdownData}
        //   companyErrors={props?.companyErrors}
        //   handleDetails={handleDetails}
        //   companyDetails={companyDetails}
        // />

        // <AddingCustomer
        //   data={companyDetails}
        //   handleDetails={handleDetails}
        //   keyName={"Vendor_Customers"}
        //   validateEmailId={props?.validateEmailId}
        // />
        <VendorCustomer
          data={companyDetails}
          handleDetails={handleDetails}
          validateEmailId={props?.validateEmailId}
          companyErrors={props?.companyErrors}
          setCompanyErrors={props?.setCompanyErrors}
        />
      )}
    </div>
  );
};

export default CompanyDetailsList;
