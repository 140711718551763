import React from "react";
import { useSelector } from "react-redux";
import TabNavigator from "../../../../utlis/hoc/tabNavigator/tabNavigator";
import CategoryDetails from "./components/categoryDetails";

const CategoryHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const categoryTab = KEYS?.CATEGORY_TAB;

  return (
    <div className="business-profile-container">
      <CategoryDetails />
      <div className="flex tab-navigator-container">
        {categoryTab?.map((item, index) => {
          if (item?.display)
            return (
              <TabNavigator
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={"category"}
                page={props?.page}
              />
            );
        })}
      </div>
    </div>
  );
};

export default CategoryHeader;
