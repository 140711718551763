import "../../styles/dropdown-octate.css";
import "../../styles/application-octate.css";
import Benefit from "./blades/benefit";
import ExploreData from "./blades/exploreData";
import Search from "./blades/search/search";
import DataProvider from "./blades/dataProvider";
import UseCaseData from "./blades/useCaseData";
import ProductData from "./blades/productData";
import Footer from "./blades/footer";
import Header from "./header/header";
import HeaderSEO from "../SEO/header";
import ReferenceSection from "./blades/search/component/refrenceSection";
import GetEarlyAccess from "../auth/getEarlyAccess/getEarlyAccess";
import { useRef } from "react";
import PopularCaseStudies from "./blades/popularCaseStudies/popularCaseStudies";
function Home(props) {
  const page = props?.page;
  return (
    <div id="app" className="landing-page__show smooth-scroll">
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      <Header page={page} show={false} />
      <div id="blades" className="dtrd-landing-page">
        <Search page={page} />
        <PopularCaseStudies page={page} />
        {/* <Benefit page={page} /> */}
        <ExploreData page={page} section={"popular categories"} />
        <ProductData page={page} section={"popular product"} />
        <DataProvider page={page} section={"popular vendors"} />
        {/* <UseCaseData /> */}
        {/* <GetEarlyAccess page={page} /> */}
        <Footer page={page} />
      </div>
    </div>
  );
}

export default Home;
