import React from "react";
import * as KEYS from "../../../../../config/config";
const Banner = () => {
  const BANNER = KEYS?.CONFIG?.HEADER_BANNER;
  return BANNER?.VISIBLE ? (
    <div className="banner-container">
      <div className="octate-header">
        <div className="mobile-hidden"></div>
        <div className="flex-container flex-wrap justify-center mobile-hidden">
          <img
            src={require("../../../../../assets/images/IFX-Expo.png")}
            className="banner-image"
            alt="banner image"
            onClick={() => window.open(BANNER?.VISIT_LINK)}
          />
          <h2 className="banner-header-title-text">{BANNER?.TITLE}</h2>
          <button
            // className="banner-external-link-button"
            className="button primary outlined"
            style={{
              height: "40px",
            }}
            onClick={() => window.open(BANNER?.OCTATE_LINK)}
          >
            {BANNER?.BUTTON_TEXT}
          </button>
        </div>
        <div className="mobile-hidden"></div>
        <div className="grid-1-3 align-items-center only-mobile">
          <img
            src={require("../../../../../assets/images/IFX-Expo.png")}
            className="banner-image"
            alt="banner image"
            onClick={() => window.open(BANNER?.VISIT_LINK)}
          />
          <h2 className="banner-header-title-text">
            {BANNER?.TITLE}{" "}
            <span className="banner-header-title-button" onClick={() => window.open(BANNER?.OCTATE_LINK)}>
              {" "}
              {BANNER?.BUTTON_TEXT}
            </span>
          </h2>
        </div>
      </div>
    </div>
  ) : null;
};

export default Banner;
