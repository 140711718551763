import React from "react";
import { useSelector } from "react-redux";
import PopularCard from "../../../productSections/components/popularCard";
// import * as KEYS from "../../../../../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../../../../../../utlis/hof/hof";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../../../../config/configKeys";
function PopularProducts(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.VIEW_ALL_PRODUCTS,
    page: "search page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleAllVendorLogoLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.vendorName,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div class="ui container">
      <div class="landing-page__section-header">
        <div class="section-header__content">
          <h2 class="landing-page__section-title">
            {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.POPULAR_PRODUCT_SECTION.TITLE_START}
            {props?.vendorName}
            {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.POPULAR_PRODUCT_SECTION.TITLE_END}
          </h2>
          <div class="landing-page__section-subtitle">
            {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.POPULAR_PRODUCT_SECTION.SUBTITLE}
            {props?.vendorName}
          </div>
        </div>
      </div>
      <div class="grid-two-columns tablet-landscape-1-col grid-col-gap-md">
        <PopularCard page={props?.page} section={props?.section} categoryId={props?.categoryId} />
      </div>
      <div
        onClick={() =>
          handleAllVendorLogoLinkClicked(`/search/products/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`)
        }
        class="text-center"
      >
        <a
          class="button primary outlined small transparent m-t-md"
          href={`/search/products/${ReplaceBlankSpaceByHyphen(props?.vendorName)}`}
        >
          {KEYS?.CATEGORY_PAGE.SHOW_ALL_BTN_LBL}
        </a>
      </div>
    </div>
  );
}

export default PopularProducts;
