import React, { useEffect, useState } from "react";
import * as NOTIFICATION_KEYS from "../../../../../config/config";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import ChangeRolesList from "./changeRolesList";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import {
  useGetRequestQuery,
  usePostProposalTeamsApiMutation,
  usePostRequestMutation,
} from "../../../../../adapters/xhr/generalApi";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import { concatIsSelectedAllData } from "../../../../../utlis/hof/hof";

function ChangeRoles(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const TEAMS_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_PRODUCT_CONFIG_KEYS = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [toggleMenu, setToggleMenu] = useState(false);
  const autoWidth = props?.autoWidth ? true : false;
  const [actionSelected, setActionSelected] = useState("");
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const GetApiData = useEnCryptGetApi(
    `/permission/getPermissionSetForUserSelection?Vendor_ID=${authData?.data?.vendorId}&User_ID=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const UpdateUserRole = useEnCryptPostApi(
    `/permission/updateUserRoles`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    setActionSelected(props?.data?.Permission_Set_Name);
  }, [props?.data]);

  const handleActionClicked = (val) => {
    if (props?.invite) {
      props?.handleApplyClicked(val);
      setActionSelected(val?.Permission_Set_Name);
    } else {
      const rawData = {
        User_ID: props?.item?.userId,
        Permission_Set_ID: val?.Permission_Set_ID,
        Vendor_ID: authData?.data?.vendorId,
      };
      UpdateUserRole.handleTrigger(rawData);
      setActionSelected(val?.Permission_Set_Name);
    }
    setTimeout(() => {
      setToggleMenu(false);
    }, 100);
  };

  const handleShareBlurbClicked = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <div
      id="use-management-table"
      tabIndex={0}
      onBlur={handleBlur}
      className="engagement-bar-items p-r"
      style={{ padding: "0px" }}
    >
      <div
        className={`${
          autoWidth ? "teams-access-invite-user-display-text-wrapper" : "teams-access-display-text-wrapper"
        } `}
        onClick={() => handleShareBlurbClicked()}
      >
        <p
          className={`${
            autoWidth ? "teams-access-invite-user-display-text" : "teams-access-display-text"
          } p-r-half-base`}
        >
          {actionSelected ? actionSelected : "Select role "}
        </p>
        {toggleMenu ? (
          <FeatherIcon icon={"chevron-up"} size="17" color={"#37393f"} />
        ) : (
          <FeatherIcon icon={"chevron-down"} size="17" color={"#37393f"} />
        )}
      </div>
      {toggleMenu && (
        <div className="roles-change-access-container modern-card modern-card--elevated">
          <ChangeRolesList
            handleActionClicked={handleActionClicked}
            handleToggleMenu={handleToggleMenu}
            data={GetApiData?.data?.data}
          />
        </div>
      )}
    </div>
  );
}

export default ChangeRoles;
