import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router";
import ShowMoreText from "react-show-more-text";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import ReadMore from "../../../businessProfile/components/businessOverview/ReadMore";
import RelatedTopics from "../../../businessProfile/components/businessOverview/relatedTopics";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";

const BlurbPageBusinessDetails = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const UrlUtmData = useSelector((state) => state?.homeDetails?.utmUrl);
  const authData = useSelector((state) => state?.authDetails?.details);
  const BUSINESS_DETAILS = KEYS?.BUSINESS_LANDING_PAGE_OVERVIEW;
  const PARAMS = useParams();
  const vendorId = props?.vendorId;
  const PRODUCT_ID = props?.productId;
  const USER_ID = authData?.data?.userId;
  const navigate = useNavigate();

  const GetApiData = useEnCryptGetApi(
    `/getVendorDetailsOnVendorId?vendorId=${vendorId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const ProductData = useEnCryptGetApi(
    `/getProductDetailsForProductPage?productId=${PRODUCT_ID}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const vendorDetails = GetApiData?.data?.data;
  const PRODUCT_DATA = ProductData?.data?.data;
  const signalTrackingBlurbObject = {
    Action: SECRET_KEYS.CONFIG.CTA_LINK_CLICKED,
    page: props?.page,
    section: "Business Overview",
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const utmQuery = vendorDetails?.Domain_URL?.includes("?");
  const CTA_LINK = ` ${vendorDetails?.Domain_URL?.replace(/\/$/, "")}${utmQuery ? "&" : "?"}${
    UrlUtmData && UrlUtmData
  }&utm_id=${vendorDetails?.Vendor_ID}`;

  const handleDomainPressed = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Item_Type: "product",
      Target: {
        page: CTA_LINK,
        link: CTA_LINK,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: val,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    window.open(CTA_LINK, "_blank");
  };

  return vendorDetails ? (
    <div className="business-aside-container">
      <aside>
        {PRODUCT_DATA?.Product_Description ? (
          <div
            className="m-b-md"
            onClick={() =>
              navigate(
                `/${ReplaceBlankSpaceByHyphen(vendorDetails?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                  PRODUCT_DATA?.Product_Name,
                  PRODUCT_DATA?.Product_ID
                )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
              )
            }
            role="button"
          >
            <>
              <h5 className="overview-title">About the product</h5>
              <ReadMore>{PRODUCT_DATA?.Product_Description}</ReadMore>
            </>
          </div>
        ) : null}
        {PRODUCT_DATA?.Product_Catagories?.length > 0 ? (
          <div className="m-b-md">
            {props?.page?.includes("product page") && (
              <>
                <h5 className="overview-title">About the product category</h5>
                <p
                  className="business-profile-description"
                  onClick={() =>
                    navigate(
                      `/category/${ReplaceBlankSpaceByHyphen(
                        PRODUCT_DATA?.Product_Catagories[0]?.Product_Category_Name,
                        PRODUCT_DATA?.Product_Catagories[0]?.Product_Category_ID
                      )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
                    )
                  }
                >
                  {PRODUCT_DATA?.Product_Catagories[0]?.Product_Category_Name}
                </p>
              </>
            )}
          </div>
        ) : null}
        <div
          className="m-b-mini"
          onClick={() =>
            navigate(
              `/vendor/${ReplaceBlankSpaceByHyphen(vendorDetails?.Vendor_Name, vendorDetails?.Vendor_ID)}/blurbs`
            )
          }
          role="button"
        >
          <h3 className="overview-title">
            About the vendor
            {/* {BUSINESS_DETAILS?.OVERVIEW_TITLE} */}
          </h3>
        </div>
        <div
          className="m-b-xs"
          onClick={() =>
            navigate(
              `/vendor/${ReplaceBlankSpaceByHyphen(vendorDetails?.Vendor_Name, vendorDetails?.Vendor_ID)}/blurbs`
            )
          }
          role="button"
        >
          {vendorDetails?.About ? (
            <>
              {/* {props?.page !== "product page" && (
                <h5 className="business-profile-headers">
                  {BUSINESS_DETAILS?.DESCRIPTION}
                </h5>
              )} */}
              {/* <ShowMoreText
                lines={5}
                more="show more"
                less="show less"
                anchorClass="show-more-less-clickable"
              >
                <p className="business-profile-description">
                  {vendorDetails?.About}
                </p>
              </ShowMoreText> */}
              <ReadMore>{vendorDetails?.About}</ReadMore>
            </>
          ) : null}
        </div>
        {/* {vendorDetails?.Product_Categories?.length > 0 ? (
          <div className="m-y-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.PRODUCT_CATEGORY}</h5>
            <ul className="business-profile-list-item business-profile-description">
              {vendorDetails?.Product_Categories?.map((item) => {
                return <li className="business-profile-description">{item?.Product_Category_Name}</li>;
              })}
            </ul>
          </div>
        ) : null} */}
        {vendorDetails?.Domain_URL ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.WEBSITE}</h5>
            <a
              // href={vendorDetails?.Domain_URL}
              role={"button"}
              onClick={() => handleDomainPressed(vendorDetails?.Domain_URL)}
              target="_blank"
            >
              {vendorDetails?.Domain_URL}
            </a>
          </div>
        ) : null}
        {vendorDetails?.Industry ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.INDUSTRY}</h5>
            <p className="business-profile-description "> {vendorDetails?.Industry}</p>
          </div>
        ) : null}
        {vendorDetails?.Founded ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ESTABLISHED}</h5>
            <p className="business-profile-description ">{vendorDetails?.Founded}</p>
          </div>
        ) : null}
        {vendorDetails?.Company_Size ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ORGANIZATION_SIZE}</h5>
            <p className="business-profile-description "> {vendorDetails?.Company_Size}</p>
          </div>
        ) : null}
        {vendorDetails?.Vendor_Type ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.ORG_TYPE}</h5>
            <p className="business-profile-description "> {vendorDetails?.Vendor_Type}</p>
          </div>
        ) : null}
        {vendorDetails?.Vendor_HQ_Country ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.HEADQUARTERS}</h5>
            <p className="business-profile-description ">{vendorDetails?.Vendor_HQ_Country}</p>
          </div>
        ) : null}
        {/* {vendorDetails?.Vendor_HQ_Phone ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">
              {BUSINESS_DETAILS?.CONTACT}
            </h5>
            <p className="business-profile-description ">
              {vendorDetails?.Vendor_HQ_Phone}
            </p>
          </div>
        ) : null} */}
        {vendorDetails?.Location?.length > 1 ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.OTHER_LOCATION}</h5>
            <p className="business-profile-description ">{vendorDetails?.Location?.length - 1}</p>
          </div>
        ) : null}
        {vendorDetails?.Employee_Range ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.EMPLOYEE_RANGE}</h5>
            <p className="business-profile-description ">{vendorDetails?.Employee_Range}</p>
          </div>
        ) : null}
        {vendorDetails?.Revenue_Range ? (
          <div className="m-b-xs">
            <h5 className="business-profile-headers">{BUSINESS_DETAILS?.REVENUE_RANGE}</h5>
            <p className="business-profile-description ">{vendorDetails?.Revenue_Range}</p>
          </div>
        ) : null}
      </aside>
      {PRODUCT_DATA?.Product_ID ? <RelatedTopics ID={PRODUCT_DATA?.Product_ID} type={"products"} /> : null}
    </div>
  ) : null;
};

export default BlurbPageBusinessDetails;
