import React, { useState } from "react";
import ReactDom from "react-dom";
import TextArea from "../../../../../utlis/hoc/textArea/textArea";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import { usePostSuperAdminAcceptRejectMutation } from "../../../../../adapters/xhr/crudServiceApi";

const AcceptModal = (props) => {
  const DATA = props?.selectedData;
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const END_POINT =
    props?.selectedTab === "domain" ? "/VendorMismatchRequestHandle" : `/VendorManagement/acceptOrReject`;

  const AcceptOrRejectUser = useEnCryptPostApi(
    END_POINT,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostSuperAdminAcceptRejectMutation
  );

  const handleAcceptOrReject = (action, val) => {
    if (props?.selectedTab === "domain") {
      const RAW_DATA = {
        vendorId: DATA?.Vendor_ID,
        vendorName: DATA?.Vendor_Name,
        action: DATA?.action,
        userId: DATA?.User_ID,
        Email_Address: DATA?.Email_Address,
      };
      AcceptOrRejectUser?.handleTrigger(RAW_DATA);
    } else {
      const RAW_DATA = {
        vendorId: DATA?.Vendor_ID,
        action: DATA?.action,
        userId: DATA?.Created_By,
      };
      AcceptOrRejectUser?.handleTrigger(RAW_DATA);
    }
    props?.handleClose();
  };
  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper" style={{ minHeight: "auto" }}>
          <div className="close-icon">
            <a className="slide-in__close-btn closeButton" onClick={() => props?.handleClose()}>
              <i className="feather icon icon-x m-none"></i>
            </a>
          </div>
          <h1 className="choose-license-header-text m-t-sm m-b-xs text-center" style={{ textAlign: "center" }}>
            Are you sure you want to accept {DATA?.Vendor_Name}?
          </h1>

          <div className="flex space-evenly m-b-md">
            <div
              className="request-category-submit-button m-l-mini"
              onClick={() => props?.handleClose()}
              style={{ minWidth: "70px", textAlign: "center", color: "red" }}
            >
              Cancel
            </div>
            <div
              className="request-category-submit-button m-l-mini"
              onClick={() => handleAcceptOrReject()}
              style={{ minWidth: "70px", textAlign: "center" }}
            >
              Accept
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default AcceptModal;
