import React from "react";
import ReactDom from "react-dom";
const PortalDiv = (props) => {
  return ReactDom.createPortal(
    <div>{props?.children}</div>,
    document.getElementById("portal")
  );
};

export default PortalDiv;
