// seo.js
import * as BaseUrl from "../../config/configKeys";
 
export const updateMetaTags = (reqBody) => {
     console.log('updateMetaTags called ',window?.location?.href);
    // Your logic to update meta tags goes here
    // You can access DOM elements to modify meta tag attributes using JavaScript
    if(window?.location?.href?.includes("octate")){
        var path = `${BaseUrl?.CONFIG?.BASE_END_POINT}/search_backend/`;
        var tab  = reqBody?.tab;
        if(reqBody?.type == 'product'){
            path += `getProductPageSEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            productSEO(path);
        } else if (reqBody?.type == 'category'){
            path += `getProductCategorySEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            categorySEO(path);
        } else if (reqBody?.type == 'vendor'){
            path += `getVendorPageSEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            vendorSEO(path);
        } else if(reqBody?.type == 'allCategory'){
            var title =`Explore our product categories 2024 | octate.ai`;
            var description =  `Explore our product categories | octate.ai`;
            var Keywords = 'octate.ai , all product categories'
            document.title = title;
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
            document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
            document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
            //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
        } else if(reqBody?.type == 'searchResult'){
            var title =`Search results ${reqBody?.SearchText ? reqBody?.SearchText : ''}`;
            var description =  `Search results ${reqBody?.SearchText ? reqBody?.SearchText : ''}`;
            var Keywords = 'octate.ai'
            document.title = title;
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
            document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
            document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
            //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
        }else if(reqBody?.type == 'topic'){
            path += `getTopicPageDetailsForSEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            topicSEO(path);
        }else if(reqBody?.type == 'blurbDetail'){
            path += `getBlurbDetailsForSEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            blurbPageSEO(path ,reqBody?.tab);
        }else if(reqBody?.type == 'usecase'){
            path += `getUseCaseDetailsForSEO?id=${reqBody?.id}&type=${reqBody?.tab}`;
            usecasePageSEO(path);
        } else if(reqBody?.type == 'business-profile'){
            var title =`Business Profile ${tab}`;
            var description =  `Business Profile ${tab}`;
            var Keywords = 'octate.ai'
            document.title = title;
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
            document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex,nofollow');
            document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
            //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
        }else if(reqBody?.type == 'proposals'){
            var title =`Proposals`;
            var description =  `Proposals`;
            var Keywords = 'octate.ai'
            document.title = title;
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
            document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex,nofollow');
            document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
            //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
        }else if(reqBody?.type == 'sign-in'){
            var title =`Sign in`;
            var description =  `Sign in`;
            var Keywords = 'octate.ai'
            document.title = title;
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
            document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
            document.querySelector('meta[name="robots"]').setAttribute('content', 'index');
            document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
            //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
        }else if(reqBody?.type == 'faq'){
            path += `getFAQDetailsForSEO?id=${reqBody?.id}`;
            faqPageSEO(path);
        }
    }else{
        var title =`cruxstack`;
        var description =  `cruxstack`;
        var Keywords = 'cruxstack'
        document.title = title;
        document.querySelector('meta[property="og:description"]').setAttribute('content', description);
        document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
        document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);
        document.querySelector('meta[name="description"]').setAttribute('content', description);
        document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
        document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex,nofollow');
        document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
        //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
    }

    function faqPageSEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
               // console.log(responseBody);
                var description = responseBody.description?.substring(0,320);
                function breakText(text, limit) {
                    if (text.length <= limit) {
                      return text;
                    }
                  
                    var shortenedText = text.substring(0, limit);
                    var lastSpaceIndex = shortenedText.lastIndexOf(" ");
                  
                    // If no space is found, break at the character limit
                    if (lastSpaceIndex === -1) {
                      return shortenedText;
                    }
                  
                    return text.substring(0, lastSpaceIndex);
                  }
                  
                  var originalText = responseBody.title;
                  var breakLimit = 45;
                  
                var title = breakText(originalText, breakLimit)
                var title = `${title} | 2024 ${responseBody.vendorName}`;
                var Keywords = responseBody.keywords;
                document.title = title;

                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[property="og:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{ console.error('Error updating meta tags:', error)
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
        });
    }

    function blurbPageSEO(path , tab){
        console.log(tab);
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
                //console.log(responseBody);
                var description = responseBody.description?.substring(0,320);
                function breakText(text, limit) {
                    if (text.length <= limit) {
                      return text;
                    }
                  
                    var shortenedText = text.substring(0, limit);
                    var lastSpaceIndex = shortenedText.lastIndexOf(" ");
                  
                    // If no space is found, break at the character limit
                    if (lastSpaceIndex === -1) {
                      return shortenedText;
                    }
                  
                    return text.substring(0, lastSpaceIndex);
                  }
                  
                  var originalText = responseBody.title;
                  var breakLimit = 45;
                  
                var title = responseBody.duplicate
                  ? originalText + " | " + "2024 " + responseBody.vendorName
                  : breakText(originalText, breakLimit) + " | " + "2024 " + responseBody.vendorName;
                // var title = `${responseBody.productName?.toLowerCase() == responseBody.vendorName?.toLowerCase() ? responseBody.productCategory : responseBody.productName ? responseBody.productName + ' | ' + responseBody.vendorName : responseBody.vendorName } 2024 - ${responseBody.title}`;
                var Keywords = responseBody.keywords;
                title = responseBody.duplicate ? title : title.substring(0, 65);
                document.title = title;

                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[property="og:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                if(tab){
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex,follow');
                }else{
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                }
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{ console.error('Error updating meta tags:', error)
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
        });
    }

    function usecasePageSEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
              //  console.log(responseBody);
                var description = responseBody.description?.substring(0,320);
                function breakText(text, limit) {
                    if (text.length <= limit) {
                      return text;
                    }
                  
                    var shortenedText = text.substring(0, limit);
                    var lastSpaceIndex = shortenedText.lastIndexOf(" ");
                  
                    // If no space is found, break at the character limit
                    if (lastSpaceIndex === -1) {
                      return shortenedText;
                    }
                  
                    return text.substring(0, lastSpaceIndex);
                  }
                  
                  var originalText = responseBody.title;
                  var breakLimit = 45;
                  
                var title = breakText(originalText, breakLimit)+" | "+'2024 '+responseBody.vendorName;

                //var title = `${responseBody.productName?.toLowerCase() == responseBody.vendorName?.toLowerCase() ? responseBody.productCategory : responseBody.productName ? responseBody.productName + ' | ' + responseBody.vendorName : responseBody.vendorName } 2024 - ${responseBody.title}`;
                var Keywords = responseBody.keywords;
                title = title.substring(0,65);
                document.title = title;
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[property="og:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                if(tab){
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex,follow');
                }else{
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                }
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{ console.error('Error updating meta tags:', error)
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
        });
    }

    function topicSEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
                var description = "";
                var title = "";
                var Keywords = responseBody.keywords;
                if(tab == 'blurbs'){
                    title = `All about ${responseBody.topicName} topic 2024`;
                    description = responseBody.description ? `List of product blurbs : ${responseBody.description?.substring(0,290)}` : `No blurbs found for ${responseBody.description}`;
                  }else if(tab == 'use-cases'){
                    title = `Use cases related to ${responseBody.topicName} topic 2024`;
                    description = responseBody.description ? `List of product use cases : ${responseBody.description?.substring(0,290)}` : `No use case found for ${responseBody.description}`;
                  } else {
                    title = `All about ${responseBody.topicName} and more 2024`;
                    description = ``;
                  }
                
                document.title = title;
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error => {
                    console.error('Error updating meta tags:', error);
                    document.title = 'octate.ai';
                    document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                    document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                    document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                    document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                    document.querySelector('meta[name="description"]').setAttribute('content', '');
                    document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
            })
    }

    function vendorSEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
                var description = "";
                var title = "";
                var Keywords = responseBody.keywords;
                responseBody.description =  responseBody?.description.substring(0,320);
                if(tab == 'blurbs'){
                    title = `All about ${responseBody.vendorName}, features, use cases, alternatives 2024`;
                    description = responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim();
                }else if(tab == 'products'){
                    title = `All products of ${responseBody.vendorName} 2024`;
                    description = (responseBody?.description ? (`List of ${responseBody.vendorName} Products : `) + responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim(): `We don't have the product data for ${responseBody.vendorName}`);
                }else if(tab == 'use-cases'){
                    title = `Use cases related to ${responseBody.vendorName} products 2024`;
                    description = responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim();
                }else if(tab == 'faq'){
                    title = `Frequently asked questions about ${responseBody.vendorName} 2024`;
                    description = (responseBody?.description ? (`List of ${responseBody.vendorName} FAQs : `)  + responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim(): `We don't have the FAQ's for ${responseBody.vendorName}`);
                }else if(tab == 'alternate-vendor'){
                    title = `Similar Vendors like ${responseBody.vendorName} and more 2024`;
                    description = `List of Vendors Similar to ${responseBody.vendorName} : `+ responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim();
                }else if(tab == 'customers'){
                    title = `Customers using ${responseBody.vendorName} products 2024`;
                    description = `Companies using ${responseBody.vendorName} products : `+ responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim();
                }else if(tab == 'boards'){
                    title = `All boards related to ${responseBody.vendorName} 2024`;
                    description = `All boards related to ${responseBody.vendorName} 2024`;
                }else{
                    title = `All about ${responseBody.vendorName}, Features, Use Cases, Alternatives 2024`;
                    description = responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim();
                }
                
                document.title = title;
                document.querySelector('meta[property="og:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                if(tab){
                  document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                }else{
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
                }
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{
                console.error('Error updating meta tags:', error);
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
                
            });
    }

    function categorySEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
                var title ="";
                var description ="";
                var categoryName = responseBody.categoryName;
                var Keywords =  responseBody.keywords.join(",")  ;
                if(tab == 'blurbs'){
                    description = responseBody.description?.substring(0,320);
                    title = `All about ${categoryName} in 2024`;
                  }else if(tab == 'products'){
                    description = `Are you looking for Best ${categoryName} Software? Find the Best ${categoryName} and their features, Case Studies.`;
                    title = `Best ${categoryName} softwares in 2024`;
                  }else if(tab == 'vendors'){
                    description = `Best ${categoryName} companies for small to large businesses. Find and compare best ${categoryName} companies or softwares by features, Case Studies and customers.`; 
                    title = `${categoryName} companies in 2024`
                  }else if(tab == 'use-cases'){
                    title = `${categoryName} Uses & Case Studies in 2024`;
                    description = `Learn how businesses across industries have leveraged end-to-end ${categoryName} software to streamline processes, save time, and money.`;
                  }
                
                document.title = title;
                console.log('title ', title);
                console.log('description ' ,description);
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{
                console.error('Error updating meta tags:', error);
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
            });
    }
    
    function productSEO(path){
        fetch(path)
        .then(response => response.json())
            .then(responseBody => {
                var title ="";
                var description ="";
                responseBody.productName = responseBody.productName ;
                responseBody.vendorName = responseBody.vendorName ;
               // var modifiedProductName = responseBody.vendorName +' '+(responseBody.vendorName == responseBody.productName ? responseBody.categoryName : responseBody.productName);
                var Keywords =  responseBody.keywords.join(",")  ;
                if(tab == 'blurbs'){
                    title = `All about ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = responseBody.description?.substring(0,320);
                }else if(tab == 'features'){
                    title = `All features of ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = responseBody.description ? `${responseBody.productName} product features : ` + responseBody.description?.substring(0,260) : `No features found for ${responseBody.productName}`;
                }else if(tab == 'use-cases'){
                    title = `${responseBody.productName} product from ${responseBody.vendorName} use cases and more 2024`;
                    description = responseBody.description ? `${responseBody.productName} product use cases : ` + responseBody.description?.substring(0,260) : `No use-cases found for ${responseBody.productName}`;
                }else if(tab == 'faq'){
                    title = `Frequently asked questions about ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = (responseBody?.description ? (`List of ${responseBody.productName} FAQs : `) + responseBody?.description?.replace(/(\r\n|\n|\r)/gm, "").trim(): `No FAQs found for ${responseBody.productName} product from ${responseBody.vendorName}`) ;
                }else if(tab == 'similar-products'){ 
                    title = `Similar products like ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = responseBody.description?.substring(0,320);
                } else if(tab == 'customers'){
                    title = `Customers using ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = `Companies using ${responseBody.productName} product : `+ responseBody?.description.trim();
                }else if(tab == 'boards'){
                    title = `All boards related to ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = `All boards related to ${responseBody.productName} 2024`;
                }else{
                    title = `All about ${responseBody.productName} product from ${responseBody.vendorName} 2024`;
                    description = responseBody.description?.substring(0,320);
                }
                
                document.title = title;
                document.querySelector('meta[property="og:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[name="twitter:image"]').setAttribute('content', responseBody.mediaLink);
                document.querySelector('meta[property="og:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
                document.querySelector('meta[property="og:title"]').setAttribute('content', title);
                document.querySelector('meta[name="description"]').setAttribute('content', description);
                document.querySelector('meta[name="keywords"]').setAttribute('content', Keywords);
                if(tab){
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow');
                }else{
                    document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
                }
                document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
                //document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
            })
            .catch(error =>{
                console.error('Error updating meta tags:', error);
                document.title = 'octate.ai';
                document.querySelector('meta[property="og:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:description"]').setAttribute('content', '');
                document.querySelector('meta[name="twitter:title"]').setAttribute('content', '');
                document.querySelector('meta[property="og:title"]').setAttribute('content', 'octate.ai');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
            });
    }
     
  }
  