import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ToTitleCase } from "../../../../../utlis/hof/hof";
import * as ENV_KEYS from "../../../../../config/configKeys";
function ProductsHeader(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const vendorName = GetIdFormLink(props?.vendorName);
  const GetApiData = useEnCryptGetApi(
    `/getVendorDetailsForVendorPage?vendorName=${props?.vendorId}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const NewDataForNow = GetApiData?.data?.data;
  return (
    <div class="container p-y-xs">
      <h1>
        {KEYS?.ALL_PRODUCT_PAGE?.TITLE_START} {ToTitleCase(vendorName?.Name)}
      </h1>
      <section class="provider-data-products__header">
        <div class="provider-data-products__header-logo">
          <img
            className="ls-is-cached lazyloaded"
            alt={`${vendorName} ${ENV_KEYS?.CONFIG?.VENDOR_LOGO}`}
            data-src={NewDataForNow?.vendorLogoURL ? NewDataForNow?.vendorLogoURL : KEYS?.CORRUPT_IMAGE_ICON}
            src={NewDataForNow?.vendorLogoURL ? NewDataForNow?.vendorLogoURL : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        </div>
        <div class="provider-data-products__header-content">
          <div class="spacer">
            <h1 class="provider-data-products__header-name">{NewDataForNow?.vendorName}</h1>

            <div class="provider__trust-subheader">
              <span class="provider-verified-badge" data-tooltip="Verified">
                {/* <img
                  className="provider-verified-badge__icon ls-is-cached lazyloaded"
                  alt="Badge icon"
                  data-src={
                    require("../../../../../assets/images/shield-verified.svg")
                      .default
                  }
                  src={
                    require("../../../../../assets/images/shield-verified.svg")
                      .default
                  }
                /> */}
              </span>
            </div>
          </div>

          <div class="provider__cta">
            <a class="ui button icon rounded large tall green">{KEYS?.VENDOR_PROFILE.CONTACT}</a>
            <p class="vendor__header-optimized-indicator">
              <img
                className=" ls-is-cached lazyloaded"
                data-src={require("../../../../../assets/images/bolt-lightning.svg").default}
                src={require("../../../../../assets/images/bolt-lightning.svg").default}
                alt={"lightning icon"}
              />
              {KEYS?.VENDOR_PROFILE.RESPONSE}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductsHeader;
