import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { updateMetaTags } from '../../../src/components/SEO/seo';
import { useGetRequestQuery } from '../../adapters/xhr/generalApi';
import { useEnCryptGetApi } from '../../utlis/customHooks/customHooks';
import Footer from '../home/blades/footer';
import Header from '../home/header/header';
import SearchHeaderBreadCrumb from '../searchResult/components/seachHeader/searchHeaderBreadCrumb';
import Blurb from './components/blurb/blurb';
import BlurbPageBusinessDetails from './components/businessDeatils/blurbPageBusinessDetails';
import RelatedBlurbsTab from './components/relatedBlurbsTab';
import ProductCategoryRelatedBlurbs from './components/similarBlurbs/productCategoryRelated';
import ProductRelatedBlurbs from './components/similarBlurbs/productRelatedBlurbs';
import TopicRelatedBlurbs from './components/similarBlurbs/topicRelated';
import VendorRelatedBlurbs from './components/similarBlurbs/vendorRelated';

const BlurbDetail = (props) => {
  const [vendorId, setVendorId] = useState(0);
  const [productId, setProductId] = useState(0);
  const [blurbData, setBlurbData] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const BLURB_DETAILS = PARAMS?.name;
  const TAB_INDEX = PARAMS?.tab;
  let BLURB_ID = BLURB_DETAILS?.split('-').at(-1);
  useEffect(() => {
    const seoBody = {
      type: 'blurbDetail',
      id: BLURB_ID,
      tab: TAB_INDEX,
    };
    updateMetaTags(seoBody);
  }, []);

  const GetApiData = useEnCryptGetApi(
    `/getNavigationPathForBlurbDetailPage?id=${BLURB_ID}&PageUrlPath=${blurbData?.Page_URL_Path}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  return (
    <div id='app' className='landing-page__show '>
      <Header page={props?.page} show={true} />
      <div className='attached-navbar'>
        <div className='fixed-nav-container flex space-between align-items-bottom ui container  '>
          <SearchHeaderBreadCrumb
            page={'blurbDetail'}
            parent={'blurbDetail'}
            type={props?.type}
            data={GetApiData?.data?.data?.length ? GetApiData?.data?.data : KEYS?.BREAD_CRUMB_BLURB_DETAIL}
          />
        </div>
      </div>
      <div className='grid-2-1 tablet-landscape-1-col ui container ' style={{ columnGap: '0px' }}>
        <div>
          <Blurb
            page={props?.page}
            handleVendorId={(val) => setVendorId(val)}
            handleProductId={(val) => setProductId(val)}
            handleBlurbData={(val) => setBlurbData(val)}
          />
          {/* <RelatedBlurbs /> */}
          <RelatedBlurbsTab page={props?.page} blurbData={blurbData} />
          {TAB_INDEX === 'product' ? (
            <ProductRelatedBlurbs page={props?.page} blurbData={blurbData} />
          ) : TAB_INDEX === 'vendor' ? (
            <VendorRelatedBlurbs page={props?.page} blurbData={blurbData} />
          ) : TAB_INDEX === 'categories' ? (
            <ProductCategoryRelatedBlurbs page={props?.page} blurbData={blurbData} />
          ) : TAB_INDEX === 'topics' ? (
            <TopicRelatedBlurbs page={props?.page} />
          ) : (
            <ProductRelatedBlurbs page={props?.page} blurbData={blurbData} />
          )}
        </div>
        <div className='mobile-hidden'>
          <BlurbPageBusinessDetails vendorId={blurbData?.Vendor_ID} productId={blurbData?.Product_ID} />
        </div>
      </div>
      <Footer page={props?.page} />
    </div>
  );
};

export default BlurbDetail;
