import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import RoundSpinner from "../../../utlis/spinner/roundSpinner";
const ConfirmClosePortal = (props) => {
  const tabIndex = props?.tabIndex;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PROPOSAL_DELETE_MODAL = KEYS?.PROPOSAL_DELETE_MODAL;
  const isLoading = props?.vendorPostData?.isLoading;
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        style={{ background: "rgba(232, 230, 230, 0.2)" }}
      >
        <div className="confirm-delete-portal-main-wrapper p-r">
          <div className="confirm-delete-portal-header-wrapper">
            <div>
              <h3 className="confirm-delete-portal-header-content-header-text">
                Save this post as a draft?
              </h3>
            </div>
            <div
              onClick={() => props?.handleClose()}
              className="cursor--pointer"
            >
              <FeatherIcon icon="x" size="25" />
            </div>
          </div>
          <div className="confirm-delete-portal-header-content-wrapper p-y-sm">
            <div>
              <h3 className="confirm-delete-portal-header-content-text">
                The changes made will be saved as draft
              </h3>
            </div>
          </div>
          <div className="confirm-delete-portal-header-actions-wrapper  m-y-base ">
            {isLoading ? (
              <div className="p-x-md p-y-base">
                <RoundSpinner />
              </div>
            ) : (
              <>
                <div onClick={() => props?.handleActionsClicked("close")}>
                  <p className="m-x-sm confirm-delete-portal-header-action-delete-text cursor--pointer">
                    Discard
                  </p>
                </div>
                <div onClick={() => props?.handleActionsClicked("save")}>
                  <p className="confirm-delete-portal-header-action-cancel-text link-color cursor--pointer">
                    {props?.proposalPublished ? "Save" : "Save as draft"}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default ConfirmClosePortal;
