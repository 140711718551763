import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../config/config";
function StickyNav(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [scrollPosition, setScrollPosition] = useState(0);
  const vendorName = props?.vendorName;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleScrollOnClick = (val) => {
    props?.handleScrollOnClick(val);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div
        className={`${
          scrollPosition > 300
            ? "sticky-page-navbar active"
            : "sticky-page-navbar"
        }`}
        data-controller="sticky-navbar"
        data-navigation-watcher-target=".landing-page__title"
      >
        <div className="container">
          <div className="page-navbar">
            <div className="page-navbar__title">{vendorName}</div>

            <div className="horizontal-center">
              <nav
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="page-navbar__menu"
              >
                <div
                  className={`${
                    scrollPosition > 300 && scrollPosition < 2500
                      ? "page-navbar__link active"
                      : "page-navbar__link "
                  }`}
                  data-controller="active-anchor-link scroll-to-target"
                  data-scroll-target="#products"
                  data-scroll-target-offset="40"
                  data-active-anchor="#products"
                  onClick={() => handleScrollOnClick("products")}
                >
                  {KEYS?.CATEGORY_PAGE.STICKY_NAV.PRODUCT_LBL}
                </div>

                <div
                  className={`${
                    scrollPosition > 3000 && scrollPosition < 4000
                      ? "page-navbar__link active"
                      : "page-navbar__link "
                  }`}
                  data-controller="active-anchor-link scroll-to-target"
                  data-scroll-target="#providers"
                  data-scroll-target-offset="40"
                  data-active-anchor="#providers"
                  onClick={() => handleScrollOnClick("providers")}
                >
                  {KEYS?.CATEGORY_PAGE.STICKY_NAV.PROVIDER_LBL}
                </div>

                {/* <div
                  className={`${
                    scrollPosition > 4000 && scrollPosition < 4500
                      ? "page-navbar__link active"
                      : "page-navbar__link "
                  }`}
                  data-controller="active-anchor-link scroll-to-target"
                  data-scroll-target="#related-content"
                  data-scroll-target-offset="40"
                  data-active-anchor="#related-content"
                  onClick={() => handleScrollOnClick("related")}
                >
                  {KEYS?.CATEGORY_PAGE.STICKY_NAV.RELATED_LBL}
                </div> */}
              </nav>

              <a className="button modern orange small">
                {KEYS?.CATEGORY_PAGE.STICKY_NAV.SEARCH_BTN_LBL}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyNav;
