import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteRequestImageMutation,
  usePostRequestImageMutation,
  usePostRequestMutation,
} from "../../../../adapters/xhr/generalApi";
import {
  useEnCryptPostApi,
  useImageUploadApi,
} from "../../../../utlis/customHooks/customHooks";
const ProfilePictureDesktop = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  // const [previewUrl, setPreviewUrl] = useState("");
  const AVATAR = KEYS?.EDIT_PROFILE;
  const userData = props?.data;
  const DELETE_SVG = "../../../../assets/images/deleteImage.svg";
  const UPLOAD_SVG = "../../../../assets/images/upLoadImage.svg";

  const profileImageUpload = useImageUploadApi(usePostRequestImageMutation);
  const profileImageDelete = useImageUploadApi(useDeleteRequestImageMutation);
  // updateUserProfile
  const handleChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      props?.handleProfessionalDetails({
        ...userData,
        userProfileUrl: URL.createObjectURL(selectedFile),
      });
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("userId", authData?.data?.userId);
      formData.append("type", "profileIcon");
      profileImageUpload?.handleTrigger(formData);
    } else {
      // setIsValid(false);
    }
  };
  const handleDelete = () => {
    props?.handleProfessionalDetails({
      ...userData,
      userProfileUrl: null,
    });
    profileImageDelete?.handleTrigger({
      type: "profileIcon",
      userId: authData?.data?.userId,
    });
  };
  return (
    <>
      {/* p-x-md p-y-md m-x-md m-y-md */}
      <div class="edit-profile-main-wrapper">
        <div class="dtrd-form--modern dtrd-form">
          <div class="portal-section ">
            {/* <div class="portal-section__header">
              <p class="portal-section__title">{AVATAR?.AVATAR_TITLE}</p>
            </div> */}
            <div class="portal-section__content">
              <div class="field horizontal-center m-b-none">
                <div class="inline-block no-line-height m-b-none">
                  {/* editable-content */}
                  <div className="user-profile-picture-wrapper">
                    {/* <div class="square-avatar square-avatar--xl"> */}
                    {/* <i class="feather icon icon-user "></i> */}
                    <div className="user-profile-picture-options-icons">
                      <label htmlFor="file-upload">
                        <div className="user-profile-picture-options-icons-update">
                          <img
                            className="octate-logo "
                            width="25px"
                            height="25px"
                            src={
                              require("../../../../assets/images/upLoadImage.svg")
                                .default
                            }
                            alt={"profile image"}
                          ></img>
                        </div>
                      </label>
                      <input
                        id="file-upload"
                        type={"file"}
                        onChange={handleChange}
                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div
                      onClick={handleDelete}
                      className="user-profile-picture-options-icons-delete"
                    >
                      <img
                        className="octate-logo "
                        width="25px"
                        height="25px"
                        src={
                          require("../../../../assets/images/deleteImage.svg")
                            .default
                        }
                        alt={"profile image"}
                      />
                    </div>
                    {userData?.userProfileUrl ? (
                      <img
                        className="octate-logo "
                        width="170px"
                        height="170px"
                        src={userData?.userProfileUrl}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = require("../../../../assets/images/profileIconPlaceholder.png");
                        }}
                        alt={"profile image"}
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      <img
                        className="octate-logo "
                        width="170px"
                        height="170px"
                        src={require("../../../../assets/images/profileIconPlaceholder.png")}
                        alt={"profile image"}
                        style={{ objectFit: "contain" }}
                      />
                    )}

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePictureDesktop;
