import React from "react";
import SearchHeaderBreadCrumb from "./searchHeaderBreadCrumb";
import SearchHeaderNavBarActions from "./searchHeaderNavBarActions";
const SearchNavHeader = (props) => {
  return (
    <div className="attached-navbar m-b-md">
      <div className="container flex space-between align-items-bottom">
        <SearchHeaderBreadCrumb
          page={props?.page}
          parent={props?.parent}
          type={props?.type}
          params={props?.params}
          pageNo={props?.pageNo}
          total={props?.total}
        />
        <SearchHeaderNavBarActions
          type={props?.type}
          params={props?.params}
          parent={props?.parent}
        />
      </div>
    </div>
  );
};
export default SearchNavHeader;
