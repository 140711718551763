import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../../../../config/config";

const FilterListMobile = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  const [listData, setListData] = useState([]);
  const [sliceNumber, setSliceNumber] = useState(3);

  useEffect(() => {
    if (props?.data) setListData(props?.data);
  }, []);

  const handleChange = (val) => {
    let newData = [...listData];
    const data = newData.map((item) =>
      item?.name === val?.name ? { ...val, isSelected: !val?.isSelected } : item
    );
    setListData(data);
    props?.handleData(data);
  };

  return (
    <>
      <label className="text-lg m-t-sm" htmlFor="standard_attribute">
        {props?.label}
      </label>
      <div className="m-b-sm" id="mobile-filters-standard_attribute">
        {listData?.slice(0, sliceNumber)?.map((item, index) => {
          return (
            <div className="m-b-base" key={item?.name}>
              <input
                type="checkbox"
                name="standard_attribute"
                id={item?.name}
                value={item?.name}
                checked={item?.isSelected}
              />
              <label
                htmlFor={item.name}
                className="flex space-between align-items-baseline"
                onClick={() => handleChange(item)}
              >
                <span>{item.name}</span>
                <span className="text-alternative-gray text-xs no-line-height">
                  {item?.count}
                </span>
              </label>
            </div>
            // </div>
          );
        })}
        {listData?.length > 3 ? (
          sliceNumber <= 3 ? (
            <div>
              <a
                class="show-more-trigger link-xs fw-600"
                data-controller="slide-toggle toggle-class"
                data-toggle-class-target="#mobile-filters-standard_attribute .more-filters, #mobile-filters-standard_attribute a.show-more-trigger, #mobile-filters-standard_attribute a.show-less-trigger,"
                data-toggle-class="hidden, hidden, hidden"
                href="#"
                onClick={() => setSliceNumber(listData?.length)}
              >
                {`+${listData?.length - 3} more`}
              </a>
            </div>
          ) : (
            <div>
              <a
                class="show-more-trigger link-xs fw-600"
                data-controller="slide-toggle toggle-class"
                data-toggle-class-target="#mobile-filters-standard_attribute .more-filters, #mobile-filters-standard_attribute a.show-more-trigger, #mobile-filters-standard_attribute a.show-less-trigger,"
                data-toggle-class="hidden, hidden, hidden"
                href="#"
                onClick={() => setSliceNumber(3)}
              >
                {FILTER?.SHOW_LESS}
              </a>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default FilterListMobile;
