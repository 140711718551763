import React from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { useDispatch, useSelector } from "react-redux";
import { selectedVendorFn } from "../../contexts/reducer/chatReducer";
import RequestVendorPortal from "./requestVendorPortal";
import RequestNewVendors from "./requestNewVendor";
import RequestWorkEmail from "./requestWorkEmail";
import { FilterSelectedAllData } from "../../utlis/hof/hof";
import * as ENV_KEYS from "../../config/configKeys";
const VendorSearchBar = (props) => {
  const allUserChatListData = useSelector((state) => state?.chatDetails?.userChatList);
  const [changeText, setChangeText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [showRequestVendorPortal, setShowRequestVendorPortal] = useState({ show: false, data: {} });
  const [showRequestWorkEmailPortal, setShowRequestWorkEmailPortal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const isValidDomain = authData?.data?.isValidDomain;
  const vendorId = authData?.data?.vendorId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const dispatch = useDispatch();
  function handleChangeText(e) {
    setChangeText(e);
    setShowDropDown(true);
  }
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  function handleSelectVendorClicked(val, vendorDetails) {
    const isValidDomain = vendorDetails?.isValidDomain;
    const SelectedChatListExist = allUserChatListData?.filter((list) => {
      return list?.Vendor_ID == val?.Vendor_ID;
    });
    const selectedConversionId = SelectedChatListExist?.length
      ? { ...SelectedChatListExist[0] }
      : { ...val, Date_Time: parseInt(new Date().getTime() / 1000), Anonymous_Name: true };
    if (!isValidDomain) {
      setTimeout(() => {
        setShowDropDown(false);
      }, 500);
      setTimeout(() => {
        setShowRequestWorkEmailPortal({ show: true, data: val });
      }, [600]);
    } else if (val?.Verified) {
      dispatch(selectedVendorFn(selectedConversionId));
      setChangeText("");
      setTimeout(() => {
        setShowDropDown(false);
      }, 500);
    } else {
      setTimeout(() => {
        setShowDropDown(false);
      }, 500);
      setTimeout(() => {
        setShowRequestVendorPortal({ show: true, data: val });
      }, [600]);
    }

    const signalTrackingPostData = {
      Actor: authData?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: ENV_KEYS.CONFIG.DM_CLICKED,
      Item_Type: "Direct message vendor searched",
      Item_Id: val?.Vendor_ID,
      Target: {
        page: null,
        link: null,
      },
      Object: {
        section: "Direct message vendor searched",
        context: "Direct message vendor searched",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  }
  const skipGetQuery = changeText?.length ? false : true;
  const GetApiData = useEnCryptGetApi(
    `/getVendorLists?searchText=${changeText}&Vendor_ID=${vendorId}&User_ID=${userId}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetRequestQuery,
    skipGetQuery
  );
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowDropDown(false);
    }
  };
  function handleClose() {
    setShowRequestVendorPortal({ show: false, data: {} });
  }
  function handleCloseWorkEmail() {
    setShowRequestWorkEmailPortal(false);
  }
  const VendorListData = GetApiData?.data?.data?.length
    ? FilterSelectedAllData(allUserChatListData, GetApiData?.data?.data, "Vendor_ID")
    : [];
  //   /getVendorLists?searchText=Boo
  return (
    <>
      {showRequestVendorPortal && false ? <RequestVendorPortal handleClose={handleClose} /> : null}
      <div tabIndex={0} onBlur={handleBlur} className="input-field-wrapper-position-relative">
        <div
          id="input-field-wrapper"
          class={`input-field-wrapper`}
          style={{
            border: "1px solid #EFF1F9",
            borderRadius: "5px",
            margin: "5px 10px",
          }}
        >
          <FeatherIcon
            icon={"search"}
            size="16"
            color={"#6E7079"}
            className="input-icons-search-filter-proposal-table"
          />
          <input
            id={"Search"}
            type={"text"}
            value={changeText}
            name={"Search"}
            disabled={false}
            placeholder={"Search vendors"}
            onChange={(e) => handleChangeText(e.target.value)}
            className={`input-field-text-search-proposal-filter`}
            autoComplete="off"
            style={{ paddingLeft: "30px" }}
          />
        </div>
        {showDropDown ? (
          <div className="searched-vendor-container modern-card modern-card--elevated">
            {VendorListData.map((LIST_DATA) => {
              return (
                <div
                  onClick={() => handleSelectVendorClicked(LIST_DATA, authData?.data)}
                  className="chat-list-container"
                  key={LIST_DATA?.Conversation_ID}
                >
                  <div class="circular-avatar">
                    {LIST_DATA?.Vendor_Logo_URL ? (
                      <img
                        src={LIST_DATA?.Vendor_Logo_URL}
                        alt="vendor-image"
                        className="chat-list-vendor-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = require("../../assets/images/Vendor_Placeholder_Icon.svg").default;
                        }}
                      />
                    ) : (
                      <img
                        src={require("../../assets/images/Vendor_Placeholder_Icon.svg").default}
                        alt="vendor-image"
                        className="chat-list-vendor-image"
                      />
                    )}
                  </div>
                  <div style={{ flexGrow: "1" }}>
                    <div className="flex space-between">
                      <h3 className="chat-list-username">{LIST_DATA?.Vendor_Name}</h3>
                    </div>
                    <p className="chat-list-description">{LIST_DATA?.Verified ? "Verified" : "Unverified"}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {showRequestVendorPortal?.show ? (
          <div className="searched-vendor-request-vendor-container modern-card modern-card--elevated">
            <RequestNewVendors showRequestVendorPortal={showRequestVendorPortal} handleClose={handleClose} />
          </div>
        ) : null}
        {showRequestWorkEmailPortal ? (
          <div className="searched-vendor-request-vendor-container modern-card modern-card--elevated">
            <RequestWorkEmail showRequestVendorPortal={showRequestVendorPortal} handleClose={handleCloseWorkEmail} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default VendorSearchBar;
