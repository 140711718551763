import React from "react";
import VendorHeader from "./vendorHeader";
import VendorContent from "./vendorContent";
import VendorActions from "./vendorActions";
import MatchingProducts from "./matchingProducts";
import EngagementBar from "../../engagementBar/engagementBar";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { useSelector } from "react-redux";

const VendorCardWrapper = ({ props, item, index }) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const cardType = "vendor";
  const onDragOver = (e, id) => {
    e.preventDefault();
  };
  const onDragStart = (e, id) => {
    if (props?.page == "board detail page") {
      e.dataTransfer.setData("current index", id);
    }
  };
  const onDrop = (e, index, value) => {
    if (props?.page == "board detail page") {
      const currentIndex = e.dataTransfer.getData("current index");
      props?.handleChangePosition(currentIndex, index, value);
    }
  };
  return (
    <div
      draggable={props?.page == "board detail page" ? true : false}
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index, "Vendors")}
      className="vendor-card"
      key={item?.Vendor_ID}
    >
      <VendorHeader keyName={"Vendor_ID"} data={item} cardType={cardType} page={props?.page} section={props?.section} />
      <div className="product-card-content m-b-half-base">
        <VendorContent data={item} cardType={cardType} page={props?.page} section={props?.section} />
        <VendorActions data={item} cardType={cardType} page={props?.page} section={props?.section} />
      </div>
      <div className="new-vendor-card-engagement-bar-container m-b-xs blurb-card-engagement-wrapper">
        <EngagementBar
          shareLink={`/vendor/${ReplaceBlankSpaceByHyphen(item?.Vendor_Name, item?.Vendor_ID)}/${
            KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
          }`}
          cardType={cardType}
          page={props?.page}
          data={item}
          section={props?.section}
          searchText={props?.searchText}
          keyName={"Vendor_ID"}
          engageSource={"Vendor engagement from vendor card"}
        />
      </div>
      <MatchingProducts data={item} cardType={cardType} page={props?.page} section={props?.section} />
    </div>
  );
};

export default VendorCardWrapper;
