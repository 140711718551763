import React, { useEffect, useState } from "react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import AddUserAccessTypeList from "./addUserAccessTypeList";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import * as SECRET_KEYS from "../../../../config/configKeys";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { usePostProposalTeamsApiMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
function AddUser(props) {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const [validDomain, setIValidDomain] = useState(false);
  const [sendEmailState, setSendEmailState] = useState("");
  const proposalDetails = props?.proposalDetails;
  const [actionSelected, setActionSelected] = useState("can_view");
  const [addUserMessage, setAddUserMessage] = useState({});
  const SendEmailToTeam = useEnCryptPostApi(
    `/addSharedProposalInformation`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostProposalTeamsApiMutation
  );

  useEffect(() => {
    if (SendEmailToTeam?.data?.status) {
      const Action = SECRET_KEYS.CONFIG.BUYER_SHARES_PROPOSAL;
      const Target = {
        Id: SendEmailToTeam?.data?.Shared_User_ID,
        type: "User",
        page: "shared proposal page",
        link: `/proposal/buyer/${ReplaceBlankSpaceByHyphen(
          proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_TITLE],
          proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID]
        )}`,
      };
      const Object = {
        Id: proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID],
        type: "Proposal",
        section: null,
        context: "User Shares Proposal",
        page: "buyer proposal page",
      };
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        SignalCreatedAt: new Date().getTime() / 1000,
        Item_Type: "Buyer Proposal",
        Item_Id: proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID],
        Action: Action,
        Target: Target,
        Object: Object,
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      setSendEmailState("");
      if (SendEmailToTeam?.data?.message) {
        setAddUserMessage({ type: "success", message: SendEmailToTeam?.data?.message });
        setTimeout(() => {
          setAddUserMessage({});
        }, 2000);
      }
    } else {
      if (SendEmailToTeam?.data?.message) {
        setAddUserMessage({ type: "error", message: SendEmailToTeam?.data?.message });
        setTimeout(() => {
          setAddUserMessage({});
        }, 2000);
      }
    }
  }, [SendEmailToTeam?.data]);
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  const emailId = authData?.data?.emailId;
  const userId = authData?.data?.userId;
  const handleSendEmailClicked = () => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailRegex.test(sendEmailState)) {
      setAddUserMessage({ message: "Please enter valid email address", type: "error" });
    } else if (!validDomain) {
      setAddUserMessage({ message: "Please enter email which matches your business domain", type: "error" });
    } else if (validDomain) {
      const rawData = {
        Item_Id: proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS.PROPOSAL_ID],
        Item_Type: "proposal",
        Shared_By: userId,
        Shared_With: sendEmailState,
        Access_Type: actionSelected,
        Path: `/shared-proposal/buyer/${ReplaceBlankSpaceByHyphen(
          proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_TITLE],
          proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID]
        )}`,
      };

      SendEmailToTeam.handleTrigger(rawData);
    }
  };
  const handleChangeText = (val) => {
    setSendEmailState(val);
    const getDomain = /@(.+)/;
    const getOriginalDomain = getDomain.exec(emailId);
    if (getOriginalDomain) {
      //   return match[1];
      const domainVal = getOriginalDomain[1];
      const getAddUserDomain = getDomain.exec(val);

      if (getAddUserDomain && getAddUserDomain[1] == domainVal) {
        setIValidDomain(true);
      } else {
        setIValidDomain(false);
      }
      //   const regex = /^[a-zA-Z0-9._%+-]+domainVal\.com$/;
      //   const validate = regex.test(val);
    }
    // Define regular expression for email validation with the domain example.com
  };
  const handleActionSelected = (val) => {
    setActionSelected(val);
  };
  return (
    <div className="p-r">
      <div className="add-question-type-main-container p-y-base">
        <div className="add-user-teams-main-input-and-type-wrapper">
          <div class="field p-r m-x-base flex-question-input-field" id="field-user__email">
            <FeatherIcon icon={"mail"} size="18" color={"#37393f"} className="input-icons" />
            <input
              id={"team-email"}
              type={"email"}
              value={sendEmailState}
              // name={props?.label}
              // disabled={props?.disable ? props?.disable : false}
              placeholder={"Enter business email address"}
              onChange={(e) => handleChangeText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value)}
              className={`add-user-teams-input-field`}
              style={{ paddingLeft: "30px" }}
              maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
            />
          </div>
          <div className="flex-question-dropdown-field">
            <AddUserAccessTypeList handleActionSelected={handleActionSelected} actionSelected={actionSelected} />
          </div>
        </div>

        <div className="flex-question-dropdown-field m-r-sm">
          <div class="field-company-profile p-r" id="field-user__country_id">
            {SendEmailToTeam.isLoading ? (
              <span
                style={{
                  height: "42px",
                  width: "65px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                }}
                className={`valid--email`}
              >
                <LoadingSpinner size={"small"} />
              </span>
            ) : (
              <span
                onClick={handleSendEmailClicked}
                className={`teams-add-user-add-button-display-text ${
                  validDomain || true ? "valid--email cursor--pointer" : "in-valid--email cursor--disable"
                }`}
              >
                Add
              </span>
            )}
          </div>
        </div>
      </div>
      {addUserMessage?.message ? (
        <div>
          <p
            style={{
              fontSize: "12px",
              margin: "0px",
              bottom: "0px",
              left: "20px",
              position: "absolute",
              color: addUserMessage?.type == "error" ? "#cc3300" : "#02ab26",
            }}
          >
            {addUserMessage?.message}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default AddUser;
