import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { ValidateProductCategory, concatIsSelectedAllData } from "../../../utlis/hof/hof";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import AddProductCategoryForProduct from "./addProductCategoryForProduct";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-paste-smart";
import AddProductCategoryWithTopic from "./addProductCategoryWithTopic";
const BasicProductDetails = (props) => {
  const [searchIndustryText, setSearchIndustryText] = useState("a");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [false] }],
          ["bold", "italic", "underline", "strike", "link", "clean"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );
  //   const KEYS = {
  //     ADD_PRODUCT: {
  //       SUCCESS_MESSAGE: "Product details updated successfully",
  //       PRODUCT_PAGE_TITLE: "New Product",
  //       PRODUCT_NAME: "Product Name",
  //       PRODUCT_NAME_LABEL: "Product Name",
  //       VENDOR_NAME: "Vendor Name",
  //       VENDOR_NAME_LABEL: "Vendor Name",
  //       SUPPORTED_LANGUAGES: "Supported Languages",
  //       SUPPORTED_LANGUAGES_LABEL: "Supported Languages",
  //       PRODUCT_SEGMENT: "Product Segment",
  //       PRODUCT_SEGMENT_LABEL: "Product Segment",
  //       INDUSTRY: "Industry",
  //       INDUSTRY_LABEL: "Select Industry",
  //       SOFTWARE_TYPE: "Software Type",
  //       SOFTWARE_TYPE_LABEL: "Software Type",
  //       DESCRIPTION: "Product Description",
  //       DESCRIPTION_LABEL: "Product Description",
  //       PRODUCT_PAGE_LINK: "Product Page Link",
  //       PRODUCT_PAGE_LINK_LABEL: "Product Page Link",
  //       BACK: "Back",
  //       SAVE: "Save",
  //       NEXT: "Next",
  //     },
  //   };
  const [categoryError, setCategoryError] = useState({});

  const error = props?.companyErrors;
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const StaticDropdownData = props?.StaticDropdownData;
  // const productBasicDetails = props?.data;
  const productBasicDetails = props?.productDetails;

  const [description, setDescription] = useState(productBasicDetails?.description);
  //   https://thebox-dev.portqii.com/core/vendor/vendorOnboardingSearch?searchText=auto&type=industry
  const industryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchIndustryText ? searchIndustryText : "a"}&type=industry`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;
  const handleSearchWithApi = (key) => {
    setSearchIndustryText(key);
  };
  const handleApplyCountryClicked = (val) => {};
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...productBasicDetails,
      Compatible_Softwares: val,
    });
  };
  const concatIsSelectedCompatible = concatIsSelectedAllData(
    productBasicDetails?.Compatible_Softwares?.length ? productBasicDetails?.Compatible_Softwares : [],
    StaticDropdownData?.Compatible_Softwares,
    "id"
  );

  function handleDescription(value) {
    setDescription(value);
  }
  useEffect(() => {
    if (description?.length)
      props?.handleDetails({
        ...productBasicDetails,
        Product_Description: description,
      });
    // else setDescription(productBasicDetails?.description);
  }, [description]);

  const handleAddNewCategory = (val) => {
    const DATA = ValidateProductCategory(productBasicDetails?.Selected_Product_Category);
    setCategoryError(DATA);
    setTimeout(() => {
      setCategoryError({});
    }, 2000);
    if (!Object.keys(DATA)?.length) {
      props?.handleDetails({
        ...productBasicDetails,
        Selected_Product_Category: [...productBasicDetails?.Selected_Product_Category, { name: "" }],
      });
    }
  };
  return (
    <div className="company-profile-basic-details-main-container">
      <div className="product-profile-basic-details-left-side-container">
        {/* <div>
          <h5 class="company-header-sub-title">
            {ADD_PRODUCT?.BASIC_INFORMATION_LABEL}
          </h5>
        </div> */}
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.Product_Name ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_PRODUCT?.PRODUCT_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_PRODUCT?.PRODUCT_NAME_LABEL}
            value={productBasicDetails?.Product_Name}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_Name: val,
              })
            }
            error={error?.Product_Name}
          />
        </div>
        {/* <div class="field p-r m-t-mini" id="field-user__phone_number_prefix">
          {productBasicDetails?.Product_Description ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_PRODUCT?.DESCRIPTION_LABEL}
            </label>
          ) : null}
          <TextArea
            value={productBasicDetails?.Product_Description}
            className="m-b-sm"
            label={ADD_PRODUCT?.DESCRIPTION_LABEL}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_Description: val,
              })
            }
            subTitleTop={"Please add at least 50 characters"}
            error={error?.Product_Description}
          />
        </div> */}
        <div className="m-y-sm m-b-lg p-r">
          <label className="company-details-label-input-field-filled-absolute">{ADD_PRODUCT?.DESCRIPTION_LABEL}</label>
          <ReactQuill
            modules={modules}
            theme="snow"
            value={description ?? productBasicDetails?.Product_Description}
            onChange={handleDescription}
            placeholder={ADD_PRODUCT?.DESCRIPTION_LABEL}
          />
          {error?.Product_Description ? (
            <span class="input-field-dropdown-error">{error?.Product_Description}</span>
          ) : null}
        </div>
        {/* <div
          class="field p-r  m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          
          {true ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {ADD_PRODUCT?.VENDOR_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            disable={true}
            label={ADD_PRODUCT?.VENDOR_NAME_LABEL}
            value={StaticDropdownData?.Vendor?.Vendor_Name}
            // changeText={(val) =>
            //   props?.handleDetails({ ...companyDetails, Vendor_Name: val })
            // }
            // error={error?.Vendor_Name}
          />
        </div> */}
        <div class="field-company-profile p-r m-y-base" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_Segment: val,
              })
            }
            data={StaticDropdownData?.Segment}
            placeholder={ADD_PRODUCT?.PRODUCT_SEGMENT}
            // flag={true}
            selectedVal={productBasicDetails?.Product_Segment}
            name={"name"}
            label={ADD_PRODUCT?.PRODUCT_SEGMENT_LABEL}
          />
          {error?.Product_Segment ? <span class="input-field-dropdown-error">{error?.Product_Segment}</span> : null}
        </div>
        {/* <div style={{ margin: "50px 0px 50px 0px" }}>
          <AddProductCategoryForProduct
            error={error}
            productBasicDetails={productBasicDetails}
            handleDetails={props?.handleDetails}
          />
        </div>
      */}
        <div className="m-y-sm m-b-lg">
          {productBasicDetails?.Selected_Product_Category?.length > 0 ? (
            productBasicDetails?.Selected_Product_Category?.map((item, index) => (
              <AddProductCategoryWithTopic
                productBasicDetails={productBasicDetails}
                handleDetails={props?.handleDetails}
                data={item}
                dataIndex={index}
                error={categoryError}
              />
            ))
          ) : (
            <AddProductCategoryWithTopic
              productBasicDetails={productBasicDetails}
              handleDetails={props?.handleDetails}
              // data={item}
            />
          )}
          {productBasicDetails?.Selected_Product_Category?.length > 0 ? (
            <div className="add-more-product-category m-b-md" onClick={() => handleAddNewCategory()}>
              + Add product category
            </div>
          ) : null}
        </div>
        <div style={{ margin: "10px 0px 50px 0px" }}>
          <div class="field-company-profile p-r m-y-base" id="field-user__country_id">
            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleDetails({
                  ...productBasicDetails,
                  Industry: val,
                })
              }
              data={industryDropdownData}
              placeholder={ADD_PRODUCT?.INDUSTRY}
              // flag={true}
              selectedVal={productBasicDetails?.Industry}
              name={"name"}
              label={ADD_PRODUCT?.INDUSTRY_LABEL}
              handleSearchWithApi={handleSearchWithApi}
              searchWithApi={true}
              returnObject={true}
            />
            {error?.Industry ? <span class="input-field-dropdown-error">{error?.Industry}</span> : null}
          </div>
        </div>

        <div style={{ margin: "50px 0px 50px 0px" }}>
          <div class="field-company-profile p-r m-y-md" id="field-user__country_id">
            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleDetails({
                  ...productBasicDetails,
                  Software_Type: val,
                })
              }
              data={StaticDropdownData?.Software_Type}
              placeholder={ADD_PRODUCT?.SOFTWARE_TYPE}
              // flag={true}
              selectedVal={productBasicDetails?.Software_Type}
              name={"name"}
              label={ADD_PRODUCT?.SOFTWARE_TYPE_LABEL}
            />
            {error?.Software_Type ? <span class="input-field-dropdown-error">{error?.Software_Type}</span> : null}
          </div>
        </div>

        {/* <div style={{ margin: "50px 0px 50px 0px" }}>
          <div
            className="field p-r  m-y-company-profile-input-wrapper m-y-md"
            id="field-user__email"
          >
            {productBasicDetails?.Compatible_Softwares?.length > 0 ? (
              <label
                for="user__email"
                className="company-details-label-input-field-filled-absolute"
              >
                Select compatible software
              </label>
            ) : null}
            <Dropdown
              data={concatIsSelectedCompatible}
              handleApplyClicked={handleApplyCountryClicked}
              label={"Select compatible software"}
              handleSelectedDataOnChange={handleSelectedDataOnChange}
              selectedValue={productBasicDetails?.Compatible_Softwares}
              hideSaveAndClear={true}
            />
          </div>
        </div> */}
        <div class="field p-r  m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.Product_Link ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_PRODUCT?.PRODUCT_PAGE_LINK}
            </label>
          ) : null}
          <InputFieldProfile
            // disable={true}
            label={ADD_PRODUCT?.PRODUCT_PAGE_LINK_LABEL}
            value={productBasicDetails?.Product_Link}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_Link: val,
              })
            }
            error={error?.Product_Link}
          />
        </div>
      </div>
      <div className="company-profile-basic-details-right-side-container">
        {/* <h3 className="company-profile-feature-services-content-title">
          Upload Cover Image
        </h3> */}
        {/* <ProductImageAndFile
          handleDetails={props?.handleDetails}
          image={props?.companyDetails}
        /> */}
      </div>
    </div>
  );
};

export default BasicProductDetails;
