import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import EditProposalPortal from "../../../proposals/component/editProposalPortal";
import { useAddProposalApiMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
const VendorListTable = (props) => {
  const VENDOR_ID = props?.VENDOR_ID;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL?.QUESTION_PROPOSAL_VENDOR_LIST_TABLE;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const proposalTableData = props?.proposalTableData;
  const proposalTableHeader = props?.proposalTableHeader;
  const [activeIndex, setActiveIndex] = useState(0);
  //   const [proposalTableHeader, setProposalTableHeader] = useState(
  //     props?.proposalTableHeader ? props?.proposalTableHeader : []
  //   );
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const handleProposalTitleClicked = (val) => {
    if (!VENDOR_ID) {
      setSelectedProposalId({
        showModal: true,
        id: val?.[API_KEYS?.VENDOR_ID],
      });
    }
  };
  const handleRowClicked = (val, index, validate) => {
    if (validate) {
      setActiveIndex(index);
      props?.handleActiveRowData(val, index);
    }
  };
  const handleDeleteClicked = (val) => {
    let rawData = [...proposalTableData];
    rawData.splice(val, 1);
    props?.handleDetails({
      ...props?.proposalDetails,
      [ADD_PRODUCT_CONFIG_KEYS?.VENDORS]: rawData,
    });
  };
  return (
    <>
      {selectedProposalId?.showModal ? (
        <EditProposalPortal
          Id={selectedProposalId?.id}
          editProduct={selectedProposalId?.showModal}
          handleClose={() => setSelectedProposalId({ id: null, showModal: false })}
        />
      ) : null}
      <table className="add-product-pricing-table-main-wrapper">
        <thead className="add-product-pricing-table-header-wrapper">
          {proposalTableHeader?.map((list, index) => {
            return (
              <th className="proposal-table-header-sub-wrapper">
                <div className="dis-f-r">
                  <p className={`proposal-table-header-text`}>{list?.name}</p>
                  {/* <img
                    src={require("../../../../assets/images/sort.svg").default}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                    role={"button"}
                  /> */}
                </div>
              </th>
            );
          })}
          <th className="proposal-table-header-sub-wrapper">
            <div className="dis-f-r">
              <p className={`proposal-table-header-text`}>{""}</p>
            </div>
          </th>
        </thead>
        <tbody style={{ paddingTop: "20px" }}>
          {proposalTableData?.length
            ? proposalTableData?.map((list, index) => {
                {
                  /*     ${
                      activeIndex == index ? "table-row-active" : ""
                    } */
                }
                const DisableClick =
                  list?.[API_KEYS?.STATUS] == "Cancelled" || list?.[API_KEYS?.STATUS] == "Deleted" ? true : false;
                return (
                  <tr className={`proposal-table-content-row-container`}>
                    <td
                      onClick={() => handleRowClicked(list, index, !DisableClick)}
                      //   onClick={() => handleProposalTitleClicked(list)}
                      className={`proposal-table-show-all-data p-r p-y-base ${
                        DisableClick ? "cursor--disable" : "cursor--pointer"
                      }  link-color`}
                    >
                      {list?.[API_KEYS?.VENDOR_NAME]}
                    </td>
                    <td
                      //   onClick={() => handleProposalTitleClicked(list)}
                      className="proposal-table-show-all-data p-r p-y-base cursor--pointer"
                    >
                      {/* {list?.[API_KEYS?.TOTAL_QUESTIONS]} */}
                      {props?.totalQuestion}
                    </td>
                    <td
                      //   onClick={() => handleProposalTitleClicked(list)}
                      className="proposal-table-show-all-data p-r p-y-base cursor--pointer"
                    >
                      {list?.[API_KEYS?.ANSWERED_QUESTIONS] ? list?.[API_KEYS?.ANSWERED_QUESTIONS] : 0}
                    </td>
                    <td
                      style={{
                        width: "100px",
                        // display: "flex",
                        // alignItems: "start",
                      }}
                      className={"proposal-table-show-all-data p-r p-y-base"}
                    >
                      <div>
                        <div
                          className={`${
                            list?.[API_KEYS?.STATUS] == "Pending"
                              ? "proposal-draft"
                              : list?.[API_KEYS?.STATUS] == "In Progress"
                              ? "proposal-in-progress"
                              : list?.[API_KEYS?.STATUS] == "Submitted"
                              ? "proposal-completed"
                              : list?.[API_KEYS?.STATUS] == "Completed"
                              ? "proposal-completed"
                              : list?.[API_KEYS?.STATUS] == "Cancelled" || list?.[API_KEYS?.STATUS] == "Deleted"
                              ? "proposal-canceled"
                              : "proposal-none"
                          }`}
                        >
                          <span>{list?.[API_KEYS?.STATUS] ? list?.[API_KEYS?.STATUS] : "Draft"}</span>
                        </div>
                      </div>
                    </td>
                    {USER_CAN_EDIT ? (
                      <td
                        style={{
                          width: "60px",
                          display: "flex",
                          alignItems: "start",
                        }}
                        className={"proposal-table-show-all-data p-r p-y-base"}
                      >
                        {/* {list?.[API_KEYS?.STATUS] == "In Progress" ||
                      list?.[API_KEYS?.STATUS] ? ( */}
                        <FeatherIcon
                          onClick={() => handleDeleteClicked(index)}
                          className="m-l-base cursor--pointer"
                          icon="trash"
                          size="18"
                        />
                        {/* ) : null} */}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!proposalTableData?.length && (
        <div className="no-results-found-proposal-table">
          <h2 className="no-results-found-proposal-table-text">No results found</h2>
        </div>
      )}
    </>
  );
};

export default VendorListTable;
