import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../config/configKeys";
import * as CONFIG_KEYS from "../../config/config";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { validateRequestVendor } from "../../utlis/hof/hof";
import * as ENV_KEYS from "../../config/configKeys";
const RequestNewVendors = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const showRequestVendorPortal = props?.showRequestVendorPortal;
  const REQUEST_VENDOR_KEYS = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE.REQUEST_VENDOR;
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [CaptchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const sitekey = "6Lc6rOAhAAAAAPBNLnyVjqm0S0Ziic9e6EPXDSKv";

  const RequestVendor = useEnCryptPostApi(
    `/addResourceRequest`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const PostComment = useEnCryptPostApi(
    `/newMessage`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleSubmit = () => {
    if (commentText) {
      setSuccessMessage((prev) => !prev);
      const NewVendorData = showRequestVendorPortal?.data;
      const RAW_DATA = {
        Resource_Name: "Direct_Message",
        Requested_By: authData?.data?.userId,
        Notification_Email: authData?.data?.userExist ? authData?.data?.emailId : formDetails?.Notification_Email,
        Resource_Type: "Direct_Message",
        Additional_Information: {
          Resource_Name: "Direct_Message",
          Resource_Type: "Direct_Message",
          Vendor_Name: NewVendorData?.Vendor_Name,
          Vendor_ID: NewVendorData?.Vendor_ID,
          Domain_URL: NewVendorData?.Domain_URL,
          Description: commentText,
          Requested_By: authData?.data?.userId,
          Notification_Email: authData?.data?.emailId,
        },
      };
      const DM_RAW_DATA = {
        Vendor_ID: NewVendorData?.Vendor_ID,
        User_ID: authData?.data?.userId,
        Message_Text: commentText,
        Conversation_ID: null,
        From_Vendor: false,
        Anonymous_Name: false,
        Verified: false,
        Date_Time: parseInt(new Date().getTime() / 1000),
      };
      PostComment?.handleTrigger(DM_RAW_DATA);
      RequestVendor?.handleTrigger(RAW_DATA);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        SignalCreatedAt: new Date().toString(),
        Action: ENV_KEYS.CONFIG.DM_CLICKED,
        Item_Type: "Request vendor",
        Item_Id: NewVendorData?.Vendor_ID,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: "Direct message",
          context: null,
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      setCommentText("");
    } else {
      setErrorMessage("Please enter a text");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  useEffect(() => {
    if (RequestVendor?.data?.Status || RequestVendor?.data?.isVerified || RequestVendor?.data?.isVerified === false)
      setTimeout(() => {
        HandleCloseModal();
      }, 5000);
  }, [RequestVendor?.data]);

  const onChange = (value) => {
    setCaptchaVerified(true);
  };

  const HandleCloseModal = () => {
    props?.handleClose();
  };

  // let datatatat = {
  //   Resource_Name: "Direct Message",

  //   Resource_Type: "Direct Message",

  //   Requested_By: userId,

  //   Notification_Email: "rahulmadihalli@gmail.com",

  //   Additional_Information: {
  //     Resource_Name: "Direct Message",
  //     Resource_Type: "Direct Message",
  //     Vendor_Name: "The Cat",
  //     Vendor_ID: "The Cat",
  //     Domain_URL: "https://www.The Test.com/",
  //     Description: "",
  //     Requested_By: 105,
  //     Notification_Email: "rahulmadihalli@gmail.com",
  //   },
  // };
  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };
  const handleChangeText = (e) => {
    setCommentText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };

  return (
    <div className="request-vendor-portal--main-wrapper">
      {RequestVendor?.data ? (
        <div
          style={{
            background: RequestVendor?.data?.Status ? "#008000" : "#c00",
          }}
          className="success-message-pop-up"
        >
          <h3 className="success-message-style-board">{RequestVendor?.data?.Message}</h3>
        </div>
      ) : null}
      <div className="add-to-board-container-header-wrapper">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <h1 className="request-new-vendor-header-text">{REQUEST_VENDOR_KEYS.SUBMIT_REQUEST} </h1>
      </div>
      <div className="text-area-container">
        <textarea
          ref={textAreaRef}
          style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
          rows={1}
          id="note"
          // onKeyDown={onKeyDown}
          type={"text"}
          value={commentText}
          onInput={handleInput}
          onChange={(e) => handleChangeText(e)}
          placeholder={`Type your message`}
          className={`input-field-text ${errorMessage ? "background-error" : ""}`}
        />
        {errorMessage ? <p className="dm-request-vendor-input-field-text-error">{errorMessage}</p> : null}
      </div>
      <div className="request-category-container">
        <div className="m-b-sm">
          <h5 className="req-vendor-portal-desc-text">{REQUEST_VENDOR_KEYS.DESCRIPTION}</h5>
        </div>
        <div className="flex justify-flex-end">
          <div
            // title={commentText ? null : "Message field cannot be empty"}
            // data-position="top center"
            // data-tooltip={commentText ? null : "Message field cannot be empty"}
            className={commentText ? "request-category-submit-button" : "request-category-submit-button--disable"}
            onClick={() => handleSubmit()}
          >
            {REQUEST_VENDOR_KEYS.SUBMIT_REQUEST_BUTTON}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestNewVendors;
