import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../utlis/spinner/loadingSpinner";
import RoundSpinner from "../../../utlis/spinner/roundSpinner";
const CompanyDetailsFooter = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const COMPANY_PROFILE = KEYS?.COMPANY_PROFILE;
  const isLoading = props?.vendorPostData?.isLoading;
  const footerList = props?.footerList;
  const handleFooterNumberClicked = (val) => {
    props?.handleFooterNumberClicked(val);
  };
  return (
    <div className="company-details-footer-wrapper">
      <div className="footer-detail-list-wrapper">
        {footerList?.map((list, index) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className={`m-x-base  company-details-footer-numbers ${
                  index + 1 <= props?.footerNumberClicked ? "company-details-footer-active" : ""
                }`}
              >
                {index + 1}
              </div>
              <div
                className={`company-details-footer-text ${
                  index + 1 <= props?.footerNumberClicked ? "company-details-footer-text-active" : ""
                } `}
              >
                {list?.name}
              </div>
              {/* chevron-right */}
              {index >= footerList?.length - 1 ? (
                <p className="p-x-sm"></p>
              ) : (
                <p className="p-x-sm p-t-base">
                  <FeatherIcon
                    icon="chevron-right"
                    size="20"
                    color={index + 1 <= props?.footerNumberClicked ? "#5570f1" : "#6E7079"}
                  />
                </p>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex footer-detail-list-action-button-main-wrapper">
        {props?.footerNumberClicked <= 1 ? null : (
          <div
            onClick={() => handleFooterNumberClicked(props?.footerNumberClicked - 1)}
            className="company-details-footer-back m-l-sm"
          >
            {COMPANY_PROFILE?.BACK}
          </div>
        )}

        {/* {props?.footerNumberClicked >= footerList?.length && isLoading ? (
          <div
            className="company-details-footer-next"
            style={{
              height: "40px",
              width: "60px",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : */}
        {props?.footerNumberClicked >= footerList?.length ? (
          props?.action === "createNew" ? (
            <div className="flex">
              {/* <div
                onClick={() => props?.handleSaveClicked("Draft")}
                className="company-details-footer-next "
              >
                {COMPANY_PROFILE?.SAVE_DRAFT}
              </div> */}
              <div onClick={() => props?.handleSaveClicked("Draft")} className="company-details-footer-next m-l-sm">
                {COMPANY_PROFILE?.SUBMIT}
              </div>
            </div>
          ) : (
            <>
              {isLoading ? (
                <div style={{ height: "38px", width: "55px" }} className="company-details-footer-next">
                  <LoadingSpinner size={"small"} />
                </div>
              ) : (
                <div onClick={() => props?.handleSaveClicked()} className="company-details-footer-next">
                  {COMPANY_PROFILE?.SAVE}
                </div>
              )}
            </>
          )
        ) : (
          <div
            onClick={() => handleFooterNumberClicked(props?.footerNumberClicked + 1)}
            className="company-details-footer-next m-l-sm"
          >
            {COMPANY_PROFILE?.NEXT}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetailsFooter;
