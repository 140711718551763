import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import ViewMoreList from './viewMoreList';
import NewPost from '../../../../components/productExpert/home/components/tabs/posts/newPost';

const BlurbOptions = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [linkedInPost, setLinkedInPost] = useState(false);

  const handleViewOptions = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };

  const handleLinkedInPost = () => {
    setLinkedInPost(!linkedInPost);
  };

  return (
    <div tabIndex={0} onBlur={handleBlur} style={{ position: 'relative' }}>
      <FeatherIcon
        icon='more-horizontal'
        size='20'
        color='#060606'
        className='blurb-detail-social'
        onClick={() => handleViewOptions()}
        role='button'
      />

      {toggleMenu && (
        <div className='blurb-view-more-container modern-card modern-card--elevated'>
          <ViewMoreList
            handleViewOptions={handleViewOptions}
            data={props?.data}
            handleLinkedInPost={() => handleLinkedInPost()}
            // blurbsPostData={blurbsPostData}
          />
        </div>
      )}
      {linkedInPost && <NewPost link={window.location.href} handleToggleModal={() => handleLinkedInPost()} />}
    </div>
  );
};

export default BlurbOptions;
