import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePostRequestMutation, useUpdateUserEmailMutation } from "../../../adapters/xhr/generalApi";
import { selectedVendorFn } from "../../../contexts/reducer/chatReducer";
import { useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import * as CONFIG_KEYS from "../../../config/config";
const MoreOptionsList = (props) => {
  // /updateConversationAnonymous_Name
  const authData = useSelector((state) => state?.authDetails?.details);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const USER_ID = authData?.data?.userId;
  const BUYER_INPUT_CHAT = CONFIG_KEYS.CONFIG.DIRECT_MESSAGE.BUYER_CHAT;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const dispatch = useDispatch();
  const GetApiData = useEnCryptPostApi(
    `/updateConversationAnonymous_Name`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useUpdateUserEmailMutation
  );
  //   /updateConversationDisableStatus
  const updateMuteParticipants = useEnCryptPostApi(
    `/updateConversationDisableStatus`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useUpdateUserEmailMutation
  );
  //   useEffect(() => {
  //     if (GetApiData?.data) {
  //       dispatch(
  //         selectedVendorFn({ ...selectedVendorSelector, Anonymous_Name: !selectedVendorSelector?.Anonymous_Name })
  //       );
  //     }
  //   }, [GetApiData?.data]);
  function handleChatAsAnonymous() {
    GetApiData.handleTrigger({
      Conversation_ID: selectedVendorSelector?.Conversation_ID,
      Anonymous_Name: !selectedVendorSelector?.Anonymous_Name,
    });

    setTimeout(() => {
      dispatch(
        selectedVendorFn({ ...selectedVendorSelector, Anonymous_Name: !selectedVendorSelector?.Anonymous_Name })
      );
      props?.handleClose();
    }, 500);
  }
  function handleMuteChat() {
    updateMuteParticipants.handleTrigger({
      Conversation_ID: selectedVendorSelector?.Conversation_ID,
      Disable_Flag: !selectedVendorSelector?.Disable_Flag,
    });
    setTimeout(() => {
      dispatch(selectedVendorFn({ ...selectedVendorSelector, Disable_Flag: !selectedVendorSelector?.Disable_Flag }));
      props?.handleClose();
    }, 500);
  }

  return (
    <>
      {/* <div className="share-single-item">
        <div
          className="action-button-container"
          onClick={handleChatAsAnonymous}
          style={{ width: "100%", padding: "5px" }}
        >
          <p className="share-desc-title">
            {selectedVendorSelector?.Anonymous_Name
              ? BUYER_INPUT_CHAT.ANONYMOUS_MORE_OPTIONS
              : BUYER_INPUT_CHAT.NON_ANONYMOUS_MORE_OPTIONS}
          </p>
        </div>
      </div> */}
      <div className="share-single-item">
        <div className="action-button-container" onClick={handleMuteChat} style={{ width: "100%", padding: "5px" }}>
          {props?.blurbsPostData?.isLoading ? (
            <p className="share-desc-title">loading...</p>
          ) : (
            <p className="share-desc-title">
              {selectedVendorSelector?.Disable_Flag
                ? BUYER_INPUT_CHAT.OPEN_MORE_OPTIONS
                : BUYER_INPUT_CHAT.CLOSE_MORE_OPTIONS}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreOptionsList;
