import React from "react";
import parse from "html-react-parser";

function FaqDesc(props) {
  const Answer = props?.data?.Answer;
  return (
    <div className="faq-detail-content-wrapper p-r-md">
      <p className="faq-detail-content-desc">{parse(Answer)}</p>
    </div>
  );
}

export default FaqDesc;
