import React, { useEffect, useState } from "react";
import {
  ValidateFeatureMainSectionErrors,
  ValidatePricingPlansErrors,
} from "../../../utlis/hof/hof";
import PricingHeader from "./pricingHeader";
import PricingTable from "./pricingTable";
import _ from "lodash";
import { useSelector } from "react-redux";
import AddFeatureHeader from "./addFeatureHeader";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import FeatureTable from "./featureTable";
import AddFeatureCreateFeature from "./addFeatureCreateFeature";
const AddFeature = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const [featureFormErrors, setFeatureFormErrors] = useState({});
  const productDetails = props?.productDetails;
  const Feature_Details = productDetails?.Product_Feature?.length
    ? productDetails?.Product_Feature
    : [
        {
          Feature_Group_Name: "",
          Feature_List: [
            // {
            //   Feature_Title: "",
            //   Feature_Description: "",
            //   actions: "",
            //   id: new Date().getTime() + authData?.data?.userId,
            // },
          ],
          // id: new Date().getTime() + authData?.data?.userId,
        },
      ];
  useEffect(() => {
    const featureMain = props?.companyErrors ? props?.companyErrors : {};
    if (!_.isEmpty(featureMain)) {
      setFeatureFormErrors(featureMain);
      setTimeout(() => {
        setFeatureFormErrors({});
      }, 2000);
    }
  }, [props?.companyErrors]);
  const handleDetails = (val) => {
    let detailsToEdit = Feature_Details?.length ? [...Feature_Details] : [];
    detailsToEdit[val?.id] = val;
    // const ProductFeatureEditedList = Feature_Details?.map((list) => {
    //   if (list?.id == val?.id) {
    //     return val;
    //   } else {
    //     return list;
    //   }
    // });
    props?.handleDetails({
      ...props?.productDetails,
      Product_Feature: detailsToEdit,
    });
  };
  const handleDeleteFeatureSection = (val) => {
    const List_Splice = [...Feature_Details];
    if (List_Splice?.length >= 1) {
      List_Splice.splice(val, 1);
      props?.handleDetails({
        ...props?.productDetails,
        Product_Feature: List_Splice,
      });
    }
  };
  const addPlansClicked = () => {
    if (Feature_Details?.length) {
      const ValidatePricingPlans = ValidateFeatureMainSectionErrors(
        Feature_Details[0]
      );
      setFeatureFormErrors(ValidatePricingPlans);
      setTimeout(() => {
        setFeatureFormErrors({});
      }, 2000);

      if (_.isEmpty(ValidatePricingPlans)) {
        let pricingProductArray = Feature_Details;
        pricingProductArray.unshift({
          Feature_Group_Name: "",
          Feature_List: [
            {
              Product_Feature: "",
              Feature_Description: "",
              actions: "",
              id: new Date().getTime() + authData?.data?.userId,
            },
          ],
          id: new Date().getTime() + authData?.data?.userId,
        });
        props?.handleDetails({
          ...productDetails,
          Product_Feature: pricingProductArray,
        });
      }
    } else {
      setFeatureFormErrors({
        Feature_Description: "Product description is required",
        Price: "Product pricing is required",
        Plan_Name: "Plan name is required",
      });
    }
  };
  return (
    <div className="add-product-product-pricing-main-wrapper">
      <AddFeatureHeader addPlansClicked={addPlansClicked} />
      {Feature_Details?.map((value, index) => {
        return (
          <AddFeatureCreateFeature
            data={value}
            featureFormErrors={index == 0 ? featureFormErrors : null}
            productDetails={productDetails}
            handleDetails={handleDetails}
            handleDeleteFeatureSection={handleDeleteFeatureSection}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default AddFeature;
