import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import DropdownContent from "../dropdownFilter/component/dropdownContent";
import GraphFilterDropDown from "./graphFilterDropDown";
const data = [
  {
    name: "All pages",
    id: 1,
    type: "page",
  },
  {
    name: "Home page",
    id: 5,
    type: "page",
  },
  {
    name: "Category page",
    id: 2,
    type: "page",
  },
  {
    name: "Topic page",
    id: 2,
    type: "page",
  },
  {
    name: "Search page",
    id: 2,
    type: "page",
  },
];
function GraphFilters(props) {
  const [selectedDropdown, setSelectedDropdown] = useState(false);
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => {
        setSelectedDropdown(false);
      }, 200);
    }
  };
  const openSelectedDropdownClicked = () => {
    setSelectedDropdown(!selectedDropdown);
  };
  const handleFilterSelected = (val) => {
    props?.handleFilterSelected(val);
    setTimeout(() => {
      setSelectedDropdown(false);
    }, 200);
  };
  return (
    <div tabIndex={0} onBlur={handleBlur} className="dashboard-filter-card-wrapper p-r m-l-half-base">
      <div onClick={openSelectedDropdownClicked} className="dashboard-graph-filter-label-wrapper">
        {/* {FILTER_KEYS?.LABEL} */}
        <h5 className="dashboard-graph-filter-label-text">{props?.label}</h5>
        <FeatherIcon
          icon={selectedDropdown ? "chevron-down" : "chevron-right"}
          size="20"
          role="button"
          color={"#ffff"}
        />
      </div>
      {selectedDropdown ? (
        <div className="dashboard-filter-filter-options-wrapper  modern-card modern-card--elevated">
          <GraphFilterDropDown handleFilterSelected={handleFilterSelected} data={data} />
        </div>
      ) : null}
    </div>
  );
}

export default GraphFilters;
