import React from "react";
import ChatRoomContent from "./chatRoomContent";
import ChatRoomHeader from "./chatRoomHeader";

function ChatRoom(props) {
  return (
    <div className="chat-room-main-wrapper">
      <ChatRoomHeader />
      <div className="chat-room-chat-room-content-main-wrapper">
        <ChatRoomContent />
      </div>
    </div>
  );
}

export default ChatRoom;
