import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedVendorFn } from "../../../../contexts/reducer/chatReducer";

const ProfileHelp = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const dispatch = useDispatch();
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const userName = authData?.data?.userExist ? authData?.data?.userName : authData?.data?.anonymousName;
  function handleChatWithOctateClicked() {
    const vendorData = octateVendorSelector?.length ? octateVendorSelector : [];
    dispatch(selectedVendorFn(vendorData[0]));
  }
  return (
    <div>
      <aside class="width-384px tablet-hidden">
        <div class="expert-advice-box my-request__widget my-request__widget--muted">
          <div class="expert-advice-box__header">{KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.HELP_HEADER}</div>

          <div class="expert-advice-box__assistant">
            <div class="circular-avatar">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.8332 14.6667V13.1111C13.8332 12.286 13.5522 11.4947 13.0521 10.9112C12.552 10.3278 11.8737 10 11.1665 10H5.83317C5.12593 10 4.44765 10.3278 3.94755 10.9112C3.44746 11.4947 3.1665 12.286 3.1665 13.1111V14.6667"
                  stroke="#1371C3"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M8.50016 7.33333C9.97292 7.33333 11.1668 6.13943 11.1668 4.66667C11.1668 3.19391 9.97292 2 8.50016 2C7.0274 2 5.8335 3.19391 5.8335 4.66667C5.8335 6.13943 7.0274 7.33333 8.50016 7.33333Z"
                  stroke="#1371C3"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>

            <div class="speech-bubble">
              <span class="speech-bubble__text">
                <strong className="speech-bubble__text_name">{userName},</strong>
                <span
                  onClick={handleChatWithOctateClicked}
                  style={{ textTransform: "capitalize", color: "#1371c3", padding: "0px 5px", cursor: "pointer" }}
                  className="click-link-speech-bubble"
                >
                  chat
                </span>
                {"with us now if you need any help or have any questions for us!"}
              </span>

              <span class="speech-bubble__author">
                {KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.SPEECH_BUBBLE_AUTHOR}
                {/* <a  rel="noreferrer noopener" href="">
                  <img
                    alt="Richard’s LinkedIn profile"
                    class=" ls-is-cached lazyloaded"
                    data-src="/packs/media/images/linkedin-400c2b22cffbfce4adc2752c7120eaf4.svg"
                    src="/packs/media/images/linkedin-400c2b22cffbfce4adc2752c7120eaf4.svg"
                  />
                </a>{" "} */}
              </span>
            </div>
          </div>

          <ul>
            {/* <li class="expert-advice-box__action">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i class="feather icon icon-phone "></i>
                    </td>
                    <td>
                      {KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.CALL_NOW_TEXT}
                      <a href=""></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li> */}

            <li class="expert-advice-box__action">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i class="feather icon icon-mail "></i>
                    </td>
                    <td>
                      {KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.EMAIL_TEXT}
                      <a href="mailto:support@octate.ai">support@octate.ai</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>

            {/* <li class="expert-advice-box__action">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i class="feather icon icon-message-square "></i>
                    </td>
                    <td>
                      {KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.CHAT_WITH_US}
                      <a class="support-link" href="#">
                        {KEYS?.MY_PROFILE_PAGE.PROFILE_HELP.START_CHAT_TEXT}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li> */}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default ProfileHelp;
