import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { DecryptId, GetIdFormLink } from "../../../../utlis/hof/hof";
// import * as KEYS from "../../../../config/config";
import parse from "html-react-parser";

function Questions(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const productId = props?.productId?.Id;
  const GetApiData = useEnCryptGetApi(
    `/getFrequentlyAskedQuestions?type=product&id=${productId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  return GetApiData?.data?.data?.length ? (
    <section class="content-section">
      <h2 class="header">{KEYS?.PRODUCT_PAGE.FAQ_PAGE.FAQ_HEADER}</h2>
      <div>
        <article class="textual-guide modern-card modern-card--elevated">
          <div class="dtrd-faq dtrd-faq--static">
            {GetApiData?.data?.data?.map((item) => {
              return (
                <div class="dtrd-faq__qa-wrapper" key={item?.FAQ_ID}>
                  <p class="dtrd-faq__question" style={{ color: "#060606" }}>
                    {item?.Question}
                  </p>
                  <p class="dtrd-faq__answer">{parse(item?.Answer)}</p>
                </div>
              );
            })}
          </div>
        </article>
      </div>
    </section>
  ) : null;
}

export default Questions;
