import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import * as CONFIG_KEYS from "../../../../../config/config";
import EditAnswerPortal from "../editAnswer/editAnswerPortal";
const Question = (props) => {
  const selectedQuestion = props?.selectedQuestion;
  const [showPortal, setShowPortal] = useState(false);
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const questionText = selectedQuestion?.[ADD_QUESTION_KEYS?.QUESTION_NAME];
  const proposalType = props?.proposalType;
  const CanEdit = props?.CanEdit;
  const handleEditClicked = (val) => {
    if (proposalType == "question") {
      props?.handleCloseSelectedVendor();
    } else {
      setShowPortal(!showPortal);
    }
  };
  const handleEditSaveClicked = (val) => {
    props?.handleSaveClicked(val);
    setShowPortal(!showPortal);
  };
  const handleDeleteClicked = (val) => {};
  return (
    <>
      {showPortal ? (
        <EditAnswerPortal
          handleClose={() => setShowPortal(false)}
          addType={"question"}
          questionListIndex={true}
          handleSaveClicked={handleEditSaveClicked}
          handleDeleteClicked={handleDeleteClicked}
          questionLength={1}
          data={selectedQuestion}
        />
      ) : null}
      <div className="vendor-proposal-dis-flex-space-btw answer-proposal-selected-question-question-container">
        <h6 className="proposal-submit-page-header-title">Question</h6>
        <div
          // onClick={() => props?.handleClose()}
          className="answer-proposal-header-title-icon"
        >
          {CanEdit || proposalType == "question" ? (
            <div onClick={handleEditClicked} className="company-details-footer-back--mini m-x-mini">
              {/* {COMPANY_PROFILE?.NEXT} */}
              {proposalType == "question" ? "Close" : "Edit"}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <h6 className="vendor-proposal-question-list-question--question-text">{questionText}</h6>
      </div>
    </>
  );
};

export default Question;

// <div
// //   onClick={props?.handleClose}
// className="company-details-footer-next--mini"
// >
// {/* {COMPANY_PROFILE?.NEXT} */}
// Close
// </div>
