import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  useBlurbProcessorMutation,
  useBlurbProcessorPutMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import {
  BreakText,
  ReplaceBlankSpaceByHyphen,
  validateBlurbSavePage,
  validateCompanyProfilePage1,
  validateCompanyProfilePage2,
  validateCompanyProfilePage3,
  validateProductProfilePage1,
  validateProductProfilePage2,
} from "../../utlis/hof/hof";
import ProductDetailsList from "../addProduct/component/productDetailsList";
import CompanyDetailsFooter from "../companyProfile/component/companyDetailsFooter";
import CompanyDetailsHeader from "../companyProfile/component/companyDetailsHeader";
import BlurbDetailList from "./component/blurbDetailList";
import { editBlurbDetails } from "../../contexts/reducer/homeReducer";
import * as ENV_KEYS from "../../config/configKeys";
const AddBlurb = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  // const ADD_BLURB = {
  //   SUCCESS_MESSAGE: "Blurb details updated successfully",
  //   BLURB_PAGE_TITLE: "New Blurb",
  //   BLURB_NAME: "Blurb Name",
  //   BLURB_NAME_LABEL: "Blurb Name",
  //   SUPPORTED_LANGUAGES: "Supported Languages",
  //   SUPPORTED_LANGUAGES_LABEL: "Supported Languages",
  //   SELECT_BLURB_SEGMENT: "Select Blurb Segment",
  //   SELECT_BLURB_SEGMENT_LABEL: "Select Blurb Segment",
  //   SELECT_INDUSTRY: "Select Industry",
  //   SELECT_INDUSTRY_LABEL: "Select Industry",
  //   SOFTWARE_TYPE: "Software Type",
  //   SOFTWARE_TYPE_LABEL: "Software Type",
  //   DESCRIPTION: "Blurb Description",
  //   DESCRIPTION_LABEL: "Blurb Description",
  //   BACK: "Back",
  //   SAVE: "Save",
  //   NEXT: "Next",
  // };
  // const KEYS = { ...REF_DATA, ADD_BLURB: ADD_BLURB };
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const navigate = useNavigate();
  const [companyErrors, setCompanyErrors] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [imageFile, setImageFile] = useState({});
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const dispatch = useDispatch();
  const editProduct = props?.editProduct;
  const userId = authData?.data?.userId;
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;
  const EditOrClone = editProduct ? "Edit" : "Clone";
  const ADD_BLURB = KEYS?.ADD_BLURB;
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);

  const GetBlurbDataFromApi = useEnCryptGetApi(
    `/getBlurbById?blurbId=${props?.Id}&userId=${userId}&status=${vendorDetails?.Vendor_Status}&vendorId=${vendorId}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const RequestMethod = editProduct ? useBlurbProcessorPutMutation : useBlurbProcessorMutation;
  const RequestApi = editProduct ? `/approveUpdatedBlurbUpdate` : `/approveUpdatedBlurb`;
  // useBlurbProcessorPutMutation
  const blurbsPostData = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL, RequestMethod);
  useEffect(() => {
    if (GetBlurbDataFromApi?.data?.data) {
      if (editProduct) {
        const PRODUCT_CATEGORY_FROM_EDIT_BLURB = GetBlurbDataFromApi?.data?.data[0]?.object?.tags?.filter(
          (val) => val?.Level === 3
        );
        setProductDetails({
          ...GetBlurbDataFromApi?.data?.data[0]?.object,
          Selected_Product_Category: GetBlurbDataFromApi?.data?.data[0]?.object?.Selected_Product_Category
            ? GetBlurbDataFromApi?.data?.data[0]?.object?.Selected_Product_Category
            : PRODUCT_CATEGORY_FROM_EDIT_BLURB?.length
            ? {
                ...PRODUCT_CATEGORY_FROM_EDIT_BLURB[0],
                name: PRODUCT_CATEGORY_FROM_EDIT_BLURB[0]?.Name,
                id: PRODUCT_CATEGORY_FROM_EDIT_BLURB[0]?.Tag_Id,
              }
            : {},
        });
      }
    }
  }, [GetBlurbDataFromApi?.data]);

  const footerList = [
    {
      name: "Basic Details",
      id: 1,
    },
    // {
    //   name: "Add Features",
    //   id: 2,
    // },
    // {
    //   name: "Plans & Pricing",
    //   id: 3,
    // },
  ];
  const validateEmailId = authData?.data?.emailId
    ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
    : null;
  useEffect(() => {
    if (blurbsPostData?.data?.success) {
      setUpdateStatus(true);
      props?.handleClose(false);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: editProduct ? ENV_KEYS.CONFIG.BLURB_UPDATED : ENV_KEYS.CONFIG.BLURB_CREATED,
        Item_Type: "Blurb",
        Item_Id: productDetails?.blurbID ? productDetails?.blurbID : blurbsPostData?.data?.blurbID,
        Target: {
          page: "Blurb detail page",
          link: `/${ReplaceBlankSpaceByHyphen(productDetails?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
            BreakText(productDetails?.title_level_1?.trim())
          )}-${productDetails?.blurbID ? productDetails?.blurbID : blurbsPostData?.data?.blurbID}/products`,
        },
        Object: {
          section: null,
          context: editProduct ? `Blurb updated` : `Blurb created`,
          page: "/business-profile/blurbs",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [blurbsPostData]);
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    const Validate3 = validateCompanyProfilePage3(productDetails);
    if (val <= 2) {
      const Validate1 = validateProductProfilePage1(productDetails);
      setCompanyErrors(Validate1);
      if (!Object.keys(Validate1)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 3) {
      const Validate2 = validateProductProfilePage2(productDetails);
      setCompanyErrors(Validate2);
      if (!Object.keys(Validate2)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 4) {
      setFooterNumberClicked(val);
    } else {
      return;
    }
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  const handleImageFile = (val) => {
    if (val) {
      setImageFile(val);
    }
  };
  const handleSaveClicked = () => {
    const validateSaveBlurbs = validateBlurbSavePage(productDetails);
    setCompanyErrors(validateSaveBlurbs);
    setTimeout(() => {
      setCompanyErrors({});
    }, 3000);
    // const Validate2 = validateCompanyProfilePage2(productDetails);
    if (!Object.keys(validateSaveBlurbs)?.length) {
      const rawData = {
        data: {
          ...productDetails,
          userId: userId,
          Vendor_ID: vendorId,
          Selected_Product_Category: {
            Tag_Id: productDetails?.Selected_Product_Category?.id
              ? productDetails?.Selected_Product_Category?.id
              : productDetails?.Selected_Product_Category?.Tag_Id,
            Name: productDetails?.Selected_Product_Category?.name
              ? productDetails?.Selected_Product_Category?.name
              : productDetails?.Selected_Product_Category?.Name,
          },
        },
        status: "Publish",
        action: EditOrClone,
      };
      dispatch(
        editBlurbDetails({
          ...rawData?.data,
          Modified_User: authData?.data?.userName,
          Modify_At: Math.floor(Date.now() / 1000),
        })
      );
      setTimeout(() => {
        dispatch(editBlurbDetails({}));
      }, 5000);
      blurbsPostData?.handleTrigger(rawData);
    }
  };
  return (
    <div className="company-profile-main-wrapper">
      <div className="company-details-header-main-wrapper-ref">
        <CompanyDetailsHeader
          productPageLabel={editProduct ? ADD_BLURB?.BLURB_PAGE_TITLE_EDIT : ADD_BLURB?.BLURB_PAGE_TITLE}
          handleClose={() => props?.handleClose()}
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="company-profile-details-main-container-ref">
        <BlurbDetailList
          handleImageFile={handleImageFile}
          productStaticData={ADD_BLURB}
          validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
          footerNumberClicked={footerNumberClicked}
        />
      </div>
      <div className="company-details-footer-main-wrapper">
        <CompanyDetailsFooter
          // productStaticData={ADD_BLURB}
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={blurbsPostData}
        />
      </div>
    </div>
  );
};

export default AddBlurb;

{
  /*
  "ADD_BLURB":{
    "SUCCESS_MESSAGE":'Product details updated successfully',
    "BLURB_PAGE_TITLE":"New Product"
    "BLURB_NAME":'Product Name',
    "BLURB_NAME_LABEL":'Product Name',
    "SUPPORTED_LANGUAGES":"Supported Languages",
    "SUPPORTED_LANGUAGES_LABEL":"Supported Languages",
    "SELECT_BLURB_SEGMENT":"Select Product Segment",
    "SELECT_BLURB_SEGMENT_LABEL":"Select Product Segment",
    "SELECT_INDUSTRY":"Select Industry",
    "SELECT_INDUSTRY_LABEL":"Select Industry",
    "SOFTWARE_TYPE":"Software Type",
    "SOFTWARE_TYPE_LABEL":"Software Type",
    "DESCRIPTION":"Product Description",
    "DESCRIPTION_LABEL":"Product Description",
    "BACK":'Back',
    "SAVE":'Save',
    "NEXT":'Next',
  }
  */
}
