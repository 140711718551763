import React, { useState } from "react";
import VendorSearch from "../../../auth/joinOraganization/components/vendorSearch";
import { useSelector } from "react-redux";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useGetRequestCrudQuery, usePostRequestCrudMutation } from "../../../../adapters/xhr/crudServiceApi";
import CreateNewVendorPortal from "../../../auth/joinOraganization/components/createNewVendorPortal";
import BoxSpinner from "../../../../utlis/spinner/boxSpinner";

const BusinessDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const JOIN_ORGANIZATION = KEYS?.JOIN_ORGANIZATION;

  const GetApiData = useEnCryptGetApi(
    `/vendorJoinedHistory?User_ID=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );
  const AcceptOrRejectInvite = useEnCryptPostApi(
    `/InviteManagement`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestCrudMutation
  );
  const REQUEST_STATUS = GetApiData?.data?.data?.at(-1);
  const INVITED = GetApiData?.data?.data?.some((item) => item?.Is_Invited);

  const handleDomainUrlForModal = (val, type) => {
    window.scrollTo(0, 0);
    setDomainUrl(val);
    setShowModal(true);
  };

  const ShowStatusOfRequest = (val) => {
    switch (1) {
      case val?.Is_Joined_Requested:
        return JOIN_ORGANIZATION?.REQUEST_SENT;
      case val?.Is_Joined_Accepted:
        return JOIN_ORGANIZATION?.ACCEPTED;
      case val?.Is_Joined_Rejected:
        return JOIN_ORGANIZATION.REJECTED;
      case !val?.isAdminExist:
        return JOIN_ORGANIZATION.CLAIMED;
      case val?.Is_Claimed:
        return JOIN_ORGANIZATION?.CLAIMED;
      case val?.Is_Claim_Rejected:
        return "Octate Rejected";
      default:
        return JOIN_ORGANIZATION.REQUEST_SENT;
    }
  };

  const handleAcceptOrReject = (action, val) => {
    const rawData = {
      User_ID: authData?.data?.userId,
      Action: action,
      Vendor_ID: val?.Vendor_ID,
    };
    AcceptOrRejectInvite?.handleTrigger(rawData);
    // const signalTrackingPostData = {
    //   Actor: authData?.data?.userId,
    //   Action: action === "Accept" ? ENV_KEYS.CONFIG.USER_ACCEPTED : ENV_KEYS.CONFIG.USER_REJECTED,
    //   Item_Type: null,
    //   Item_Id: null,
    //   User_Type: "Known",
    //   Persona: "Business user",
    //   Target: {
    //     page: null,
    //     link: null,
    //     id: val?.userId,
    //   },
    //   Object: {
    //     section: null,
    //     context: `User ${action === "Accept" ? "accepted" : "rejected"}`,
    //     page: "/business-profile/teams",
    //     Persona: "Business user",
    //   },
    // };
    // SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return GetApiData?.isLoading ? (
    <BoxSpinner />
  ) : (
    <>
      {showModal ? (
        <CreateNewVendorPortal
          pageToNavigate={"home"}
          handleClose={() => setShowModal(false)}
          action={"createNew"}
          domainUrl={domainUrl}
        />
      ) : null}
      {GetApiData?.data?.data?.length ? (
        <div className="grid-2-1 grid-col-gap-md grid-row-gap-sm m-y-md grid-align-items-center">
          <h2 className="business-details-header">Company</h2>
          <h2 className="business-details-header">Reason</h2>
          {GetApiData?.data?.data?.map((item) => {
            return (
              <>
                <div className={`vendor-list-onboard-container`}>
                  <div className="vendor-list-onboard-header-container">
                    {item?.Vendor_Logo_URL ? (
                      <img
                        class=" ls-is-cached lazyloaded vendor-onboarding-thumbnail"
                        data-src={item?.Vendor_Logo_URL}
                        src={item?.Vendor_Logo_URL}
                        alt={SECRET_KEYS?.CONFIG?.VENDOR_LOGO}
                      />
                    ) : (
                      <img
                        class=" ls-is-cached lazyloaded vendor-onboarding-thumbnail"
                        data-src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
                        src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
                        alt={SECRET_KEYS?.CONFIG?.VENDOR_LOGO}
                      />
                    )}
                    <div className="vendor-list-onboard-header">
                      <h5 className="vendor-list-onboard-name">{item?.Vendor_Name}</h5>
                      <div className="flex">
                        <p className="vendor-onboarding-verified-text">
                          {item?.User_Action === "claim" ? "Unclaimed" : "Claimed"}
                        </p>
                      </div>
                    </div>
                  </div>

                  {item?.Is_Invited ? (
                    <div className="user-management-action-button-container">
                      <div
                        className={`user-manage-action-button-red`}
                        onClick={() => handleAcceptOrReject("Reject", item)}
                      >
                        Reject
                      </div>
                      <div
                        className={`user-manage-action-button-green`}
                        onClick={() => handleAcceptOrReject("Accept", item)}
                      >
                        Accept
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`vendor-onboarding-join-button ${
                        item?.Is_Joined_Requested || item?.Is_Joined_Accepted
                          ? "vendor-onboarding-join-button--success"
                          : "vendor-onboarding-join-button--rejected"
                      }`}
                    >
                      <>
                        {item?.Is_Joined_Requested || item?.Is_Joined_Accepted ? (
                          <i className="feather icon icon-check "></i>
                        ) : null}
                        {ShowStatusOfRequest(item)}
                      </>
                    </div>
                  )}
                </div>
                <h2 className="business-details-content">{item?.User_Status_Reason}</h2>
              </>
            );
          })}
        </div>
      ) : null}
      {
        // REQUEST_STATUS?.Is_Claim_Rejected
        !INVITED &&
        !REQUEST_STATUS?.Is_Joined_Accepted &&
        !REQUEST_STATUS?.Is_Claimed &&
        !REQUEST_STATUS?.Is_Joined_Requested &&
        !REQUEST_STATUS?.Domain_Mismatch &&
        authData?.data?.isValidDomain ? (
          <div style={{ width: "64%", marginTop: "15px" }}>
            <VendorSearch
              organizationData={GetApiData?.data?.data}
              handleSimilarData={(val) => null}
              handleButtonVisible={() => null}
              handleModal={(val) => handleDomainUrlForModal(val)}
              handleDomainUrl={(val) => null}
            />
          </div>
        ) : null
      }
    </>
  );
};

export default BusinessDetails;
