import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../utlis/customHooks/customHooks";
import PasswordField from "../../signUp/onBoard/components/passwordField";
import * as CONFIG from "../../../../config/config";
import * as ENV_KEYS from "../../../../config/configKeys";
import CryptoJS from "react-native-crypto-js";
import PageLoading from "../../../../utlis/spinner/pageLoading";

const InvitePasswordForm = (props) => {
  const [password, setPassword] = useState({});
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const RESET_PASSWORD = KEYS?.RESET_PASSWORD;
  const authData = useSelector((state) => state?.authDetails?.details);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const InvitePassword = useEnCryptPostApi(
    `/saveInviteUserPassword`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    if (token) {
      const data = token?.replace(/ /g, "+");
      const TOKEN = "qube$%$AI*&&*20BC";
      var bytes = CryptoJS.AES.decrypt(data, TOKEN);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserData(decryptedData);
      const signalTrackingPostData = {
        Actor: decryptedData?.userId,
        Action: ENV_KEYS.CONFIG.INVITED_VENDOR_SIGNUP_START,
        Item_Type: null,
        Item_Id: null,
        User_Type: "Known",
        Persona: "Business user",
        Target: {
          page: "Otp",
          link: "/otp-verification",
        },
        Object: {
          section: null,
          context: `Invited vendor signup start`,
          page: "/invite-business-user",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [token]);

  useEffect(() => {
    if (InvitePassword?.data?.status) {
      dispatch(
        tempAuthDetails({
          data: { ...userData },
          verifyOTP: true,
        })
      );
      props?.handleModalVisible();
    } else if (!InvitePassword?.data?.status) {
      setError({ confirmPassword: InvitePassword?.data?.message });
    }
  }, [InvitePassword?.data]);

  const handleSubmit = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@$^*()\[\]{}\\|;:'",<.>/?`\-_=+])(?=.{8,})\S+$/;

    if (!password.password) {
      setError({ password: RESET_PASSWORD?.PASSWORD_REQUIRED });
    } else if (!passwordRegex.test(password.password)) {
      setError({
        password: RESET_PASSWORD?.PASSWORD_PATTERN,
      });
    } else {
      if (password?.password === password?.confirmPassword) {
        const rawData = {
          Password: password?.password,
          deviceId: authData?.data?.deviceId,
          User_ID: userData?.userId,
          Email_Address: userData?.emailId,
        };
        InvitePassword?.handleTrigger(rawData);
      } else {
        setError({
          confirmPassword: RESET_PASSWORD?.PASSWORD_DO_NOT_MATCH,
        });
      }
    }
  };
  const GetApiData = useEnCryptGetApi(
    `/checkForActiveUser?User_ID=${userData?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  return !GetApiData?.data?.data?.Status ? (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        {/* <h4 className="already-account-text m-b-mini ">
          {RESET_PASSWORD?.DONT_HAVE_A_CREATE_ACCOUNT}{" "}
          <a href="/sign-up" style={{ color: "#1371c3" }}>
            {RESET_PASSWORD?.SIGN_UP}
          </a>
        </h4> */}
        <div class="user-registration-container">
          <div className="sign-up-page-header-text m-b-sm m-t-md ">
            Please set your password to complete the activation of your account.
          </div>
          {/* <div className="sign-up-page-text m-b-sm">
            Please provide following details
          </div> */}
          {/* <div className="sign-up-page-text m-b-md">
            {RESET_PASSWORD?.FORGOT_PASSWORD_SUB_TITLE}
          </div> */}
          <div className="m-b-xs">
            <PasswordField
              label={RESET_PASSWORD?.PASSWORD}
              value={password?.password}
              changeText={(val) => setPassword({ ...password, password: val })}
              error={error?.password}
              type={"password"}
              icon={"lock"}
              autoComplete={false}
            />
          </div>

          <PasswordField
            label={RESET_PASSWORD?.CONFIRM_PASSWORD}
            value={password?.confirmPassword}
            changeText={(val) => setPassword({ ...password, confirmPassword: val })}
            error={error?.confirmPassword}
            type={"password"}
            icon={"lock"}
          />

          <div
            className="create-account-button m-t-md"
            role={"button"}
            onClick={() => handleSubmit()}
            style={{ width: "200px" }}
          >
            Set Password
          </div>
        </div>
      </div>
    </div>
  ) : GetApiData?.data?.data?.Status ? (
    <Navigate to={"/"} replace />
  ) : (
    <PageLoading />
  );
};

export default InvitePasswordForm;
