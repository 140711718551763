import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../../../../utlis/customHooks/useLocalStorage";
import { validateForms } from "../../../../../utlis/hof/hof";
import InputFieldNew from "./InputFieldNew";
import _ from "lodash";
import { useNavigate } from "react-router";
import { tempAuthDetails } from "../../../../../contexts/reducer/authReducer";
import SingleSelectNewDropdown from "../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import PasswordField from "./passwordField";
import LoadingSpinner from "../../../../../utlis/spinner/loadingSpinner";
import * as SECRET_KEYS from "../../../../../config/configKeys";
const BuyerForm = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.SIGN_UP_VISITED,
    page: props?.page,
    section: `${props?.consumerAccount ? "Buyer" : "Business Account"} sign up`,
  };

  const OnboardingDetails = useEnCryptPostApi(`/signup/insertSignUpDetails`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const GetCountryCode = useEnCryptGetApi(`/getCountryDialingCode`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, useGetRequestQuery);

  const CountryCode = GetCountryCode?.data?.data;

  useEffect(() => {
    if (OnboardingDetails?.data?.conversion) {
      dispatch(
        tempAuthDetails({
          data: { ...OnboardingDetails?.data },
          source: props?.page,
          target: props?.consumerAccount ? "/" : "/join-organization",
          verifyOTP: true,
        })
      );
      const signalTrackingPostData = {
        Actor: USER_ID,
        Action: SECRET_KEYS.CONFIG.SIGN_UP_START,
        Item_Type: null,
        Item_Id: null,
        Persona: "Business Account",
        Target: {
          page: "OTP page",
          link: "/email-verification",
        },
        Object: {
          section: signalTrackingBreadCrumbObject?.section,
          context: `${props?.consumerAccount ? "Buyer" : "Business Account"} sign up`,
          page: props?.page,
          Persona: props?.consumerAccount ? "Buyer" : "Business Account",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      navigate("/email-verification", { replace: true });
    } else if (OnboardingDetails?.data?.status) {
      dispatch(
        tempAuthDetails({
          data: { ...OnboardingDetails?.data },
          source: props?.page,
          target: props?.consumerAccount ? "/" : "/join-organization",
          verifyOTP: true,
        })
      );
      const signalTrackingPostData = {
        Actor: USER_ID,
        Action: SECRET_KEYS.CONFIG.SIGN_UP_START,
        Item_Type: null,
        Item_Id: null,
        Persona: "Buyer",
        Target: {
          page: "OTP page",
          link: "/email-verification",
        },
        Object: {
          section: signalTrackingBreadCrumbObject?.section,
          context: `${"Buyer"} sign up`,
          page: props?.page,
          Persona: "Buyer",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      navigate("/email-verification", { replace: true });
    } else if (!OnboardingDetails?.data?.status) {
      setError({ emailId: OnboardingDetails?.data?.message });
    }
  }, [OnboardingDetails?.data]);
  useEffect(() => {
    if (USER_ID) {
      const signalTrackingPostData = {
        Actor: USER_ID,
        Action: signalTrackingBreadCrumbObject?.Action,
        Item_Type: null,
        Item_Id: null,
        Persona: props?.consumerAccount ? "Buyer" : "Business Account",
        Target: {
          page: "OTP page",
          link: "/email-verification",
        },
        Object: {
          section: signalTrackingBreadCrumbObject?.section,
          context: `${props?.consumerAccount ? "Buyer" : "Business Account"} sign up`,
          page: props?.page,
          Persona: props?.consumerAccount ? "Buyer" : "Business Account",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [USER_ID]);
  const handleSubmit = () => {
    const rawData = {
      Persona: props?.consumerAccount ? "Consumer" : "Business User",
      firstName: firstName,
      lastName: lastName,
      password: password,
      emailId: emailId,
      userId: authUser?.data?.userId,
      deviceId: authData?.data?.deviceId,
      phoneNumber: phoneNumber,
      phoneCode: phoneCode,
      role: props?.consumerAccount ? "" : props?.vendorRole?.name,
    };

    const validate = validateForms(rawData);
    setError(validate);

    if (_.isEmpty(validate) && privacyPolicyCheckbox) {
      OnboardingDetails?.handleTrigger(rawData);
    }
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleBreadCrumbLinkClicked = (val) => {};
  return (
    <>
      <div class="grid-two-columns">
        <InputFieldNew
          label={ONBOARDING?.FIRST_NAME}
          value={firstName}
          changeText={(val) => setFirstName(val)}
          error={error?.firstName}
          required={true}
        />
        <InputFieldNew
          label={ONBOARDING?.LAST_NAME}
          value={lastName}
          changeText={(val) => setLastName(val)}
          error={error?.lastName}
          required={true}
        />
      </div>
      <InputFieldNew
        label={props?.consumerAccount ? ONBOARDING?.EMAIL_ADDRESS : ONBOARDING?.WORK_EMAIL}
        value={emailId}
        changeText={(val) => setEmailId(val)}
        error={error?.emailId}
        icon={"mail"}
        type={"email"}
        required={true}
        maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
      />
      {props?.consumerAccount ? null : (
        <div class="grid-1-3">
          <SingleSelectNewDropdown handleSelectedData={(val) => setPhoneCode(val)} placeholder="+1" data={CountryCode} type={"number"} />
          <InputFieldNew
            label={ONBOARDING?.PHONE_NUMBER}
            value={phoneNumber}
            changeText={(val) => setPhoneNumber(val?.replace(/[^0-9]/g, ""))}
            error={error?.phoneNumber}
            icon={"smartphone"}
            type={"tel"}
          />
        </div>
      )}
      <PasswordField
        label={ONBOARDING?.CREATE_PASSWORD}
        value={password}
        changeText={(val) => setPassword(val)}
        error={error?.password}
        type={"password"}
        icon={"lock"}
        required={true}
      />{" "}
      <div className="flex align-items-center m-b-md m-t-sm">
        <input
          type="checkbox"
          className="checkbox-terms-of-service"
          checked={privacyPolicyCheckbox}
          onClick={() => setPrivacyPolicyCheckbox(!privacyPolicyCheckbox)}
        />
        <p className="terms-of-service-text">
          {ONBOARDING?.PRIVACY_POLICY_START}{" "}
          <a
            role={"button"}
            onClick={() =>
              window.open(
                props?.consumerAccount
                  ? "https://business.octate.ai/terms-of-service/"
                  : "https://business.octate.ai/vendor-terms-conditions/"
              )
            }
          >
            {ONBOARDING?.TERMS_SERVICE}{" "}
          </a>{" "}
          {ONBOARDING?.PRIVACY_POLICY_BREAK}{" "}
          <a role={"button"} onClick={() => window.open("https://business.octate.ai/privacy-policy/")}>
            {ONBOARDING?.PRIVACY_POLICY}
          </a>
        </p>
      </div>
      {OnboardingDetails?.isLoading ? (
        <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="create-account-button" onClick={() => handleSubmit()} disabled={!privacyPolicyCheckbox} role={"button"}>
          {" "}
          {ONBOARDING?.CREATE_ACC_BUTTON}
        </div>
      )}
    </>
  );
};

export default BuyerForm;
