import React from "react";

const FaqContent = (props) => {
  const data = props?.data?.Question;
  return (
    <div className="faq-detail-content-wrapper m-y-mini">
      <h1 className="faq-detail-title">{data}</h1>
    </div>
  );
};

export default FaqContent;
