import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "./adapters/xhr/generalApi";
import DirectMessage from "./components/directMessage/directMessage";
import { octateVendorFn } from "./contexts/reducer/chatReducer";
import * as SECRET_KEYS from "./config/configKeys";
import "./styles/direct-message.css";
import * as BaseUrl from "./config/configKeys";
import { io } from "socket.io-client";
import { chatSocketFn, socketFn, utmUrlFunction } from "./contexts/reducer/homeReducer";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "./utlis/customHooks/customHooks";
import { ZohoChat } from "zoho-chat";
function DirectMessageWrapper(props) {
  const authData = useSelector((state) => state?.authDetails?.details);
  const USerExist = authData?.data?.userExist;
  const USER_ID = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const dispatch = useDispatch();
  const GetApiData = useEnCryptGetApi(
    `/getOctateVendor?Page=${1}&User_ID=${USER_ID}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetRequestQuery
  );
  const pagePath = window.location.href;
  // const onlyPath = new URL(pagePath)?.pathname;
  const PATH_URL = new URL(pagePath);
  const onlyPath = PATH_URL?.pathname;
  const URL_QUERY = PATH_URL?.searchParams;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (USER_ID) {
      const socket = io(`${BaseUrl?.CONFIG?.BASE_END_POINT}`, {
        path: "/notifications/socket.io/",
      });
      socket.emit("getNotificationCount", USER_ID);
      dispatch(socketFn(socket));
      return () => socket.close();
    }
  }, [USER_ID]);
  useEffect(() => {
    if (USER_ID) {
      const socket2 = io(`${BaseUrl?.CONFIG?.BASE_END_POINT}`, {
        path: "/directmessage_backend/socket.io/",
      });
      if (vendorId) {
        socket2.emit("join", `vendorId_${vendorId}`);
      }
      socket2.emit("join", `userId_${USER_ID}`);
      dispatch(chatSocketFn(socket2));
      return () => socket2.close();
    }
  }, [USER_ID]);
  useEffect(() => {
    if (authData?.data?.userId) {
      const Action = SECRET_KEYS.CONFIG.USER_VISITED;
      let paramsObject = {};
      URL_QUERY.forEach((value, key) => {
        paramsObject[key] = value;
      });
      const Target = {
        Id: null,
        type: null,
        page: null,
        link: onlyPath,
        utm: paramsObject,
      };
      const Object = {
        Id: authData?.data?.userId,
        type: USerExist ? "Non anonymous" : "Anonymous",
        section: null,
        context: "",
        page: null,
        Persona: authData?.data?.Persona == "Business User" ? "Business User" : "Buyer",
      };
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Item_Type: null,
        Item_Id: null,
        SignalCreatedAt: parseInt(new Date().getTime() / 1000),
        Action: Action,
        Target: Target,
        Object: Object,
        Persona: authData?.data?.Persona == "Business User" ? "Business User" : "Buyer",
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [authData?.data?.userId]);
  useEffect(() => {
    if (GetApiData?.data) {
      const checkConversionId = GetApiData?.data?.data?.length
        ? GetApiData?.data?.data[0]?.Conversation_ID
          ? GetApiData?.data?.data
          : [{ ...GetApiData?.data?.data[0], Anonymous_Name: false }]
        : [];
      dispatch(octateVendorFn(checkConversionId));
    }
  }, [GetApiData?.data]);
  return (
    <div className="p-r">
      {USerExist ? (
        <DirectMessage />
      ) : (
        <ZohoChat
          url={"https://salesiq.zoho.in/widget"}
          widgetCode={
            "9c36a74fb41c99c0311fdeb8cba012574054782848167d686d25bf4288f812e27b734f152579e2124df019f5d3c18710"
          }
          position="bottomright"
        />
      )}
      {props?.children}
    </div>
  );
}

export default DirectMessageWrapper;
