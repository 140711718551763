import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link, useParams } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { useSelector } from "react-redux";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
const SmallCardInfo = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const PARAMS = useParams();
  const SEARCH_TEXT = PARAMS?.name?.replaceAll("-", " ");

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.FORMOGRAPICS_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleFirmographicsCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Item_Type: 'Prduct',
      Item_Id: DATA?.Product_ID,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: SEARCH_TEXT,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return DATA?.[props?.keyName] ? (
    <div
      onClick={() =>
        handleFirmographicsCLicked(
          `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            DATA?.Product_Name,
            DATA?.Product_ID
          )}/${props?.navigate}`
        )
      }
    >
      <a
        href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          DATA?.Product_Name,
          DATA?.Product_ID
        )}/${props?.navigate}`}
      >
        <div className="flex-container center-container additional-info-vendor-page " role="button">
          {/* {props?.icon && (
          <FeatherIcon
            icon={props?.icon}
            size="20"
            style={{ marginRight: "10px" }}
            color={"#8B8D97"}

            // className="blurb-detail-social"
          />
        )} */}
          <div data-tooltip={props?.name} data-inverted="true" style={{ width: "20px", height: "20px" }}>
            <img
              src={
                props?.name === "Use cases"
                  ? require("../../../../assets/cardIcons/productCardIcons/2.png")
                  : require("../../../../assets/cardIcons/productCardIcons/1.png")
              }
              style={{ width: "20px", height: "20px", marginRight: "5px", objectFit: "cover" }}
            />
          </div>
          <div className="small-card-info-text">{DATA?.[props?.keyName]}</div>
        </div>
      </a>
    </div>
  ) : null;
};

export default SmallCardInfo;
