import React from 'react';
import EmailVerificationOctate from '../../auth/signUp/emailVerification/emailVerificationOctate';
import ProductExpertForm from './components/productExpertForm';

const ProductExpSignUp = (props) => {
  return (
    <>
      <main>
        <div class='page-content page-content--reset '>
          <div class='splitted-page'>
            {/* <OnboardBanner /> */}
            <EmailVerificationOctate consumerAccount={false} />
            <div class='splitted-page__content'>
              <div class='splitted-page__container'>
                <div class='user-registration-container'>
                  <div className='sign-up-page-header-text m-b-sm m-t-md '>Sign up for Octate AI</div>

                  <div className='sign-up-page-text m-b-sm'>Sign up as Product Expert</div>
                  <ProductExpertForm page={props?.page} consumerAccount={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductExpSignUp;
