import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import FeatherIcon from "feather-icons-react";
import InputFieldFilterProposal from "../inputFieldFilterProposal";
import ProposalHeader from "../proposalHeader/proposalHeader";
import ProposalTableHeader from "./proposalTableHeader";
import ProposalTableContent from "./proposalTableContent";
import { useGetProposalQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import EditProposalPortal from "../editProposalPortal";
import ProposalBusinessTableContent from "../../../businessProfile/components/tabs/components/proposalBusinessTableContent";
import RoundSpinner from "../../../../utlis/spinner/roundSpinner";
const ProposalTable = (props) => {
  const VENDOR_ID = props?.VENDOR_ID;
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const notificationJson = useSelector((state) => state?.notificationDetails?.notificationTarget);
  const [proposalSearchFilters, setProposalSearchFilters] = useState({});
  const PROPOSAL_TABLE_HEADER_KEYS = VENDOR_ID ? KEYS?.PROPOSAL_BUSINESS_TABLE_HEADER : KEYS?.PROPOSAL_TABLE_HEADER;
  const [proposalTableHeader, setProposalTableHeader] = useState(PROPOSAL_TABLE_HEADER_KEYS);
  const permissionsDetails = props?.permissionsDetails;

  const getProposalUrl = VENDOR_ID
    ? `/vendor/proposalsList?vendorId=${VENDOR_ID}&offset=0`
    : `/buyer/proposalsList?userId=${authData?.data?.userId}&offset=0`;
  const getProposalTableData = useEnCryptGetApi(
    getProposalUrl,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetProposalQuery
  );
  const [proposalTableData, setProposalData] = useState(
    getProposalTableData?.data?.data?.length ? getProposalTableData?.data?.data : []
  );
  useEffect(() => {
    if (getProposalTableData?.data?.data) {
      setProposalData(getProposalTableData?.data?.data?.length ? getProposalTableData?.data?.data : []);
      if (!getProposalTableData?.data?.data?.length) {
        setProposalTableHeader(PROPOSAL_TABLE_HEADER_KEYS);
      }
    }
  }, [getProposalTableData?.data]);

  const handleProposalTableHeader = (val) => {
    setProposalTableHeader(val);
  };
  const handleHeadersDetails = (val) => {
    setProposalSearchFilters(val);
  };
  const handleClose = () => {
    setProposalTableHeader({ showModal: false, id: null });
  };
  const handleProposalTableData = (val) => {
    setProposalData(val);
  };
  return (
    <div className="proposal-summary-main-wrapper">
      {getProposalTableData.isLoading || getProposalTableData.isFetching ? (
        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
          <RoundSpinner />
        </div>
      ) : (
        <>
          <ProposalTableHeader
            PROPOSAL_TYPE={VENDOR_ID ? "answer" : "question"}
            proposalSearchFilters={proposalSearchFilters}
            handleDetails={handleHeadersDetails}
            proposalTableData={proposalTableData}
          />
          {VENDOR_ID ? (
            <div className="proposal-table-container-main-wrapper-answer-proposal">
              <ProposalBusinessTableContent
                permissionsDetails={permissionsDetails}
                notificationJson={
                  notificationJson?.Action == "Buyer_Deleted_Proposal" || notificationJson?.Action == "Vendor_deleted"
                    ? {}
                    : notificationJson
                }
                VENDOR_ID={VENDOR_ID}
                proposalSearchFilters={proposalSearchFilters}
                proposalTableData={proposalTableData}
                proposalTableHeader={proposalTableHeader}
                handleProposalTableHeader={handleProposalTableHeader}
                handleProposalTableData={handleProposalTableData}
                handleActiveRowData={props?.handleActiveRowData}
              />
            </div>
          ) : (
            <div className="proposal-table-container-main-wrapper">
              <ProposalTableContent
                notificationJson={notificationJson}
                VENDOR_ID={VENDOR_ID}
                proposalSearchFilters={proposalSearchFilters}
                proposalTableData={proposalTableData}
                proposalTableHeader={proposalTableHeader}
                handleProposalTableHeader={handleProposalTableHeader}
                handleProposalTableData={handleProposalTableData}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProposalTable;
