import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import {
  usePostRequestCrudMutation,
  usePostSuperAdminAcceptRejectMutation,
} from "../../../../../adapters/xhr/crudServiceApi";
import RejectModal from "./rejectModal";
import AcceptModal from "./acceptModal";

const TrackingTable = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const tableHeader = props?.tableHeader ?? [];
  const tableData = props?.data ?? [];
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const EpochDateConvertor = (val) => {
    let date = new Date(val * 1000);
    let options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    const converted = date.toLocaleDateString("en-GB", options);
    return converted;
  };

  const DateFormatting = (val) => {
    const inputDate = new Date(val);
    const day = String(inputDate.getUTCDate()).padStart(2, "0");
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
    const year = inputDate.getUTCFullYear();
    const convertedDate = `${day}/${month}/${year}`;
    return convertedDate;
  };

  const handleAcceptOrReject = (action, val) => {
    setSelectedData({ ...val, action: action });
    if (action === "Reject") {
      setModalVisible((prev) => !prev);
    } else if (action === "Accept") {
      setAcceptModalVisible((prev) => !prev);
    }
  };
  return (
    <div className="m-y-xs user-management-table-container">
      <table className="user-management-table" key={tableData}>
        <thead className="border-table">
          {tableHeader?.map((item) => {
            return (
              <th className="table-header-text">
                <div className="table-header-content">{item?.replaceAll("_", " ")?.toUpperCase()}</div>
              </th>
            );
          })}
        </thead>

        {tableData?.map((item) => {
          return (
            <tr key={item}>
              {tableHeader?.map((table) => {
                if (table === "link") {
                  return (
                    <td className="table-body-text" key={table}>
                      <p role="button" onClick={() => window.open(item[table])}>
                        {item[table]}
                      </p>
                    </td>
                  );
                } else if (
                  [
                    "Feedback_On",
                    "Created_On",
                    "Updated_On",
                    "Comment_Created_Date_Time",
                    "Comment_Updated_Date_Time",
                    "Requested_On",
                    "Date_Time",
                    "Claimed_On",
                  ]?.includes(table)
                ) {
                  return <td className="table-body-text">{EpochDateConvertor(item[table])}</td>;
                } else if (["Start_Date", "Expiry_Date"]?.includes(table)) {
                  return <td className="table-body-text">{DateFormatting(item[table])}</td>;
                } else if (table === "action") {
                  return (
                    <td className="user-management-action-button-container">
                      <>
                        {item[table]?.map((action) => {
                          return (
                            <div
                              className={`user-manage-action-button-${action?.buttonColor}`}
                              onClick={() => handleAcceptOrReject(action?.buttonText, item)}
                            >
                              {action?.buttonText}
                            </div>
                          );
                        })}
                      </>
                    </td>
                  );
                } else
                  return (
                    <td className="table-body-text">
                      <p className="display-three-line">{item[table]}</p>
                    </td>
                  );
              })}
            </tr>
          );
        })}
      </table>
      {modalVisible ? (
        <RejectModal
          handleClose={() => setModalVisible((prev) => !prev)}
          selectedData={selectedData}
          selectedTab={props?.selectedTab}
        />
      ) : null}
      {acceptModalVisible ? (
        <AcceptModal
          handleClose={() => setAcceptModalVisible((prev) => !prev)}
          selectedData={selectedData}
          selectedTab={props?.selectedTab}
        />
      ) : null}
    </div>
  );
};

export default TrackingTable;
