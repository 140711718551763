import React, { useState } from "react";
import Header from "../proposalSubmit/component/header";
import PreviewVendors from "./previewVendors";
const PreviewTimelineAndVendors = (props) => {
  const [showSection, setShowSection] = useState(true);
  const proposalDetails = props?.proposalDetails;
  return (
    <div style={{ width: "100%" }}>
      <Header
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        hideEdit={true}
        headerTitle={"Vendors"}
      />
      {showSection ? (
        <PreviewVendors
          companyErrors={props?.companyErrors}
          proposalDetails={proposalDetails}
          showSection={showSection}
        />
      ) : null}
    </div>
  );
};

export default PreviewTimelineAndVendors;
