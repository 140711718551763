import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import { ToTitleCase } from "../../../../utlis/hof/hof";
import InputFieldFilterProposal from "../../../proposals/component/inputFieldFilterProposal";
import GlobalFilterInputField from "./globalFilterInputField";
// import * as KEYS from "../../../../config/config";

const ShowMoreOptions = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FILTER = KEYS?.SEARCH_RESULT_FILTER;
  const [listData, setListData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterText, setFilterText] = useState("");
  useEffect(() => {
    if (props?.data) {
      setListData(props?.data);
    }
  }, []);
  useEffect(() => {
    handleFilterTextChanged(filterText);
  }, [listData]);

  const handleData = (val) => {
    let newData = [...listData];
    const data = newData.map((item) =>
      item?.name === val?.name ? { ...val, isSelected: !val?.isSelected } : item
    );
    setListData(data);
  };
  const handleFilterTextChanged = (textVal) => {
    setFilterText(textVal);
    if (textVal) {
      const filteredData = listData?.filter((val) => {
        return val?.name
          ?.toLocaleLowerCase()
          .includes(textVal?.toLocaleLowerCase());
      });
      setFilterData(filteredData);
    } else {
      setFilterData(listData);
    }
  };

  const handleApplyChanges = () => {
    const temp = listData?.filter((item) => item?.isSelected);
    props?.handleApplyChanges(temp);
    props?.onClose();
  };
  return ReactDom.createPortal(
    <>
      {/* <div style={{ alignItems: "center", height: "50%" }}> */}
      <div className="ui dimmer modals page visible active portalModal">
        <form
          className="ui longer modal--max modal modal--left-aligned dtrd-form--modern dtrd-form transition visible active front"
          id="filter-options-modal"
          accept-charset="UTF-8"
          method="get"
        >
          <div className="header dis-f-r text-left">
            {/* <div>{props?.showMoreLabel}</div> */}
            {/* {FILTER?.SELECT_OPTIONS} */}
            <p className="filter-global-search-text p-b-base">{`Select ${props?.showMoreLabel}`}</p>
            <div className="dis-f-r add-padding-in-mobile">
              <GlobalFilterInputField
                label={`Select ${props?.showMoreLabel}`}
                // icon={"search"}
                value={filterText}
                changeText={(val) => handleFilterTextChanged(val)}
              />
            </div>
          </div>
          <div className="scrolling content">
            <div class="grid-two-columns grid-col-gap-md">
              {filterData?.map((item, index) => {
                return (
                  <div key={item?.name}>
                    <div>
                      <input
                        type="checkbox"
                        name="filter_data"
                        id={item?.name}
                        value={item?.name}
                        checked={item?.isSelected}
                        onClick={() => handleData(item)}
                      />
                      <label
                        for={item?.name}
                        class="flex space-between align-items-baseline"
                      >
                        <span>{ToTitleCase(item?.name)}</span>
                        <span class="text-alternative-gray text-xs no-line-height">
                          {item?.count}
                        </span>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="actions">
            <div
              className="button outlined deny modern"
              onClick={() => props?.onClose()}
            >
              {FILTER?.CANCEL}
            </div>

            <div
              //   type="submit"
              name="commit"
              text="Apply"
              className="primary modern button"
              data-disable-with="Apply"
              onClick={() => handleApplyChanges()}
              //   onSubmit={() => onSubmit()}
            >
              {FILTER?.APPLY}
            </div>
          </div>
        </form>
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")
  );
};

export default ShowMoreOptions;
