import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import HelpCard from "./helpCard";
import _ from "lodash";
function HelpCategory(props) {
  const { label, data } = props;
  const [viewFAQ, setViewFAQ] = useState(false);
  return data?.length ? (
    <div className="p-b-base">
      <div
        onClick={() => setViewFAQ(!viewFAQ)}
        className="flex space-between align-items-center cursor--pointer  m-y-mini"
      >
        <div style={{ color: "#5570f1", fontSize: "18px" }} className="frequently-asked-questions-product-title">
          {`${label}`}
        </div>
        <div>
          <FeatherIcon
            icon={viewFAQ ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#5570f1"}
            onClick={() => setViewFAQ(!viewFAQ)}
          />
        </div>
      </div>

      {viewFAQ &&
        _.sortBy(data, (val) => val?.Faq_Sequence).map((newfaq, index) => {
          return <HelpCard data={newfaq} index={index} />;
        })}
    </div>
  ) : null;
}

export default HelpCategory;
