import React from "react";
import { Link, useParams } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen, detectMob } from "../../../hof/hof";
import { useSelector } from "react-redux";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
const VendorActions = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const PARAMS = useParams();
  const MOBILE_DEVICE = detectMob();
  const SEARCH_TEXT = PARAMS?.name?.replaceAll("-", " ");

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_CARD_ACTION_BUTTON,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleActionButtonCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Item_Type: "vendor",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: SEARCH_TEXT,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div className="product-card-action-container">
      <div
        onClick={() =>
          handleActionButtonCLicked(`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/products`)
        }
      >
        <a href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/products`}>
          <div className="product-card-action-button-blue">View Products</div>
        </a>
      </div>
      <div
        onClick={() =>
          handleActionButtonCLicked(`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/blurbs`)
        }
      >
        <a href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/blurbs`}>
          <div className="product-card-action-button">View Blurbs</div>
        </a>
      </div>

      {MOBILE_DEVICE ? null : (
        <div
          onClick={() =>
            handleActionButtonCLicked(`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/blurbs`)
          }
        >
          <a href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/blurbs`}>
            <div className="product-card-action-button" style={{ margin: "0px" }}>
              View Details
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default VendorActions;
