import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRequestQuery } from '../../../adapters/xhr/generalApi';
import { userSuperAdminFn } from '../../../contexts/reducer/authReducer';
import { useEnCryptGetApi } from '../../../utlis/customHooks/customHooks';
import OctateHelp from '../../authHome/header/component/octateHelp/octateHelp';
import Notifications from '../../notifications/notifications';
import Banner from './component/banner/banner';
import ExploreCategory from './component/exploreCategories/exploreCategories';
import HeaderSearch from './component/headerSearch/headerSearch';
import ListYourDataDesktop from './component/listYourData/listYourDataDesktop';
import OctateIcon from './component/octateIcon/octateIcon';
import OctateIconDesktop from './component/octateIcon/octateIconDesktop';
import Profile from './component/profile/profile';
import ProfileDesktop from './component/profile/profileDesktop';
import Request from './component/request/request';
import SideDrawerButton from './component/sideDrawerButton/sideDrawerButton';
const Header = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const dispatch = useDispatch();
  const handleSideBarClicked = (val) => {
    setShowDrawer(val);
  };
  const SkipQuery = authData?.data?.userExist ? false : true;
  // /user/checkSuperAdmin?userId=695
  const GetConfigData = useEnCryptGetApi(`/user/checkSuperAdmin?userId=${userId}`, 'core', useGetRequestQuery, SkipQuery);
  useEffect(() => {
    if (GetConfigData?.data?.data) {
      dispatch(userSuperAdminFn(GetConfigData?.data?.data?.Is_Super_Admin ? true : false));
    }
  }, [GetConfigData?.data]);
  return (
    <header className='dtrd-navbar '>
      <Banner />
      <div className='dtrd-navbar__desktop'>
        {/* <div className="dtrd-navbar__top tablet-hidden">
          <div className="container">
            <nav className="navbar__menu">
              <ExploreCategoryDesktop />
              <BrowseUseCasesDesktop />
            </nav>
            <nav className="navbar__menu">
              <PostYourDataDesktop />
              <TalkToDataExpertDesktop />
              <LiveSupportDesktop />
              <ListYourDataDesktop />
            </nav>
          </div>
        </div> */}
        <div className='dtrd-navbar__bottom tablet-hidden'>
          <div className='octate-header'>
            <OctateIconDesktop page={props?.page} />
            {props?.show ? <HeaderSearch page={props?.page} type={props?.type} /> : null}
            <ProfileDesktop page={props?.page} />
          </div>
        </div>
      </div>
      <div className='dtrd-navbar__mobile only-tablet'>
        <div className='container align-items-center m-b-0'>
          <SideDrawerButton page={props?.page} handleSideBarClicked={handleSideBarClicked} />
          <OctateIcon page={props?.page} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '0px', marginLeft: '0px' }}>
              <OctateHelp screen='mobile' page={props?.page} />
            </div>

            <div style={{ marginRight: '20px', marginLeft: '20px' }}>
              <Notifications />
            </div>

            <Profile page={props?.page} />
          </div>
        </div>
      </div>
      {props?.show ? (
        <div className='dtrd-navbar__mobile only-tablet'>
          <div className='container '>
            <HeaderSearch page={props?.page} type={props?.type} />
          </div>
        </div>
      ) : null}

      <nav className={`${showDrawer ? 'navbar__mobile-menu only-tablet slide-in--on' : 'navbar__mobile-menu only-tablet '}`}>
        <ExploreCategory page={props?.page} handleSideBarClicked={handleSideBarClicked} />
        <Request />
        {authData?.data?.userExist ? null : (
          <>
            <ListYourDataDesktop page={props?.page} />
          </>
        )}
      </nav>
      <div className='slide-in-overlay'></div>
    </header>
  );
};
export default Header;
