import { useState } from "react";
import { useDispatch } from "react-redux";
import { authDetails } from "../../contexts/reducer/authReducer";
import { DecryptApi, EncryptPostData } from "../hof/hof";
export const useLocalStorage = (keyName, defaultValue) => {
  const dispatch = useDispatch();
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      const parsedData = JSON.parse(value);
      if (parsedData) {
        const deCryptData = DecryptApi(parsedData);
        dispatch(authDetails({ loading: false, data: deCryptData?.data }));
        return deCryptData;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    if (newValue == "logout") {
      window.localStorage.clear();
      dispatch(authDetails({ loading: false, data: null }));
    } else {
      const enCryptData = EncryptPostData(newValue);
      try {
        window.localStorage.setItem(keyName, JSON.stringify(enCryptData));
        dispatch(authDetails({ loading: false, data: newValue }));
      } catch (err) {}
      setStoredValue(newValue);
    }
  };

  return [storedValue, setValue];
};
