import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import FeatureCard from "../../../../utlis/hoc/featureCard/featureCard";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import FeatureFilter from "./FeatureFilter";
import * as NO_CONTENT from "../../../../config/config";

const Features = (props) => {
  const [filteredData, setFilteredData] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const GetApiData = useEnCryptGetApi(
    `/getFeaturesOnProduct?productId=${PRODUCT?.Id}&userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const FEATURE_KEYS = GetApiData?.data?.data ? Object?.keys(GetApiData?.data?.data) : [];
  const FEATURE_DATA = GetApiData?.data?.data;

  useEffect(() => {
    setFilteredData(GetApiData?.data?.data);
  }, [GetApiData?.data?.data]);

  return (
    <div>
      {FEATURE_DATA?.[FEATURE_KEYS] ? (
        FEATURE_KEYS?.map((key) => (
          <div className="business-aside-container m-b-md" style={{ border: "0px" }}>
            <div className="flex space-between align-items-center m-b-md">
              <h2 className="feature-section-title ">{key}</h2>
              <FeatureFilter data={FEATURE_DATA} keyName={key} handleFilterData={(val) => setFilteredData(val)} />
            </div>
            <div className="grid-two-columns mobile-hidden">
              {filteredData?.[key]?.map((item, index) => (
                <FeatureCard title={item?.Product_Feature} description={item?.Feature_Description} data={item} />
              ))}
            </div>
            <div className="grid-one-columns only-mobile">
              {filteredData?.[key]?.map((item, index) => (
                <FeatureCard title={item?.Product_Feature} description={item?.Feature_Description} data={item} />
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_FEATURE}</div>
        </div>
      )}
    </div>
  ); 
};

export default Features;
