import React from "react";
import AddCustomerLayout from "./components/addCustomerLayout";
import { useSelector } from "react-redux";

const VendorCustomer = (props) => {
  const ADDED_CUSTOMER = useSelector((state) => state?.homeDetails?.customerData);
  const VENDOR_CUSTOMER = props?.data?.Vendor_Customers?.length
    ? props?.data?.Vendor_Customers
    : [{ Customer_Name: "" }];

  const handleChangeDetails = (list, index) => {
    let data = [...VENDOR_CUSTOMER];
    data[index] = list;
    props?.handleDetails({ ...props?.data, Vendor_Customers: data });
  };

  const handleAddNewVendor = () => {
    const LAST_ELE = VENDOR_CUSTOMER?.slice(-1);
    if (LAST_ELE[0]?.Customer_Name?.length) {
      handleChangeDetails({}, VENDOR_CUSTOMER?.length);
    } else {
      props?.setCompanyErrors({ index: VENDOR_CUSTOMER?.length - 1 });
    }
  };
  const handleDeletePressed = (index) => {
    const data = [...VENDOR_CUSTOMER];
    data.splice(index, 1);
    props?.handleDetails({ ...props?.data, Vendor_Customers: data?.length ? data : [{ Customer_Name: "" }] });
  };

  return (
    <div style={{ width: "100%", padding: "0px 20px" }}>
      <div className="flex space-between align-items-center">
        <h5 class="company-header-sub-title" style={{ marginBottom: "10px" }}>
          Add Customers
        </h5>
        <button
          className={`business-profile-create-new-blue-button`}
          onClick={() => handleAddNewVendor()}
          style={{ height: "40px" }}
        >
          Add new customer
        </button>
      </div>
      {VENDOR_CUSTOMER?.map((item, index) => (
        <AddCustomerLayout
          data={item}
          handleChangeDetails={handleChangeDetails}
          index={index}
          validateEmailId={props?.validateEmailId}
          handleDeletePressed={handleDeletePressed}
          companyErrors={props?.companyErrors}
          businessData={props?.data}
        />
      ))}
    </div>
  );
};

export default VendorCustomer;
