import { api } from "../../contexts/redux/slice";
export const crudServiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRequestCrud: builder.query({
      providesTags: ["invalidateGetData"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postRequestCrud: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["invalidateGetData"],
    }),
    putRequestCrud: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["invalidateGetData"],
    }),
    getUserPreferences: builder.query({
      providesTags: ["preferences"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postUserPreferences: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["preferences"],
    }),
    getSuperAdminData: builder.query({
      providesTags: ["superAdminTable"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    postSuperAdminAcceptReject: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["superAdminTable"],
    }),
    postUserPermissions: builder.mutation({
      query: (body) => ({
        url: `api/post`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["permission"],
    }),
    putUserPermissions: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["permission"],
    }),
    getUserPermission: builder.query({
      providesTags: ["permission"],
      query: (getQuery) => ({
        url: "api/get",
        params: getQuery,
      }),
    }),
    deleteFaq: builder.mutation({
      query: (getQuery) => ({
        url: `api/delete`,
        method: "DELETE",
        params: getQuery,
      }),
      invalidatesTags: ["invalidateGetData"],
    }),
    updateFaq: builder.mutation({
      query: (body) => ({
        url: `api/put`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["invalidateGetData"],
    }),
  }),
});
export const {
  useGetRequestCrudQuery,
  usePostRequestCrudMutation,
  useGetUserPreferencesQuery,
  usePostUserPreferencesMutation,
  usePostUserPermissionsMutation,
  useGetUserPermissionQuery,
  usePutRequestCrudMutation,
  usePutUserPermissionsMutation,
  useDeleteFaqMutation,
  useUpdateFaqMutation,
  useGetSuperAdminDataQuery,
  usePostSuperAdminAcceptRejectMutation,
} = crudServiceApi;
