import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDeleteProductExpertDraftMutation } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../../../utlis/customHooks/customHooks";
import NewPost from "./newPost";
import ProductExpViewMore from "./productExpViewMore";
import ConfirmDeleteModal from "../../../../../../utlis/hoc/engagementBar/components/confirmDeleteModal";
const LinkedInDraftCard = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [editModal, setEditModal] = useState(false);
  const [commentListModal, setCommentListModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const { data, ...Post } = props;

  const ProductExpDraftDelete = useEnCryptPostApi(
    `/deleteLinkedInPost?linkedInPostId=${data?.Product_Expert_Post_ID}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useDeleteProductExpertDraftMutation
  );

  const Created_At = data?.Created_At ? moment(data?.Created_At * 1000).format("DD MMM YYYY hh:mm ") : "";

  const handleDeleteDraft = (val) => {
    ProductExpDraftDelete?.handleTrigger();
  };

  const handleActionClicked = (val) => {
    if (val === "edit") {
      setEditModal(true);
    } else if (val === "delete") {
      handleToggleModal();
    }
  };

  const handleToggleModal = (val) => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      {modalVisible ? (
        <ConfirmDeleteModal closeModal={() => handleToggleModal()} handleSubmit={() => handleDeleteDraft()} type={"post"} />
      ) : null}
      <div className="linkedIn-post-card">
        <h2 className="linkedIn-post-name p-x-xs m-b-xs">{data?.Post_Name}</h2>
        <div className="flex align-items-center p-x-xs m-b-xs">
          <img src={require("../../../../../../assets/images/profileIconPlaceholder.png")} className="linked-in-posts-card-user-image" />
          <p className="linkedIn-post-card-header" style={{ marginLeft: "5px" }}>
            {data?.Full_Name}
            <br></br>
            <h3 className="created-by-on-each-card">{Created_At ?? ""}</h3>
          </p>
        </div>
        <p className="linkedIn-post-card-description p-x-xs m-b-xs">{data?.Description}</p>
        <div className="octate-link-container">
          <img
            src={data?.LinkedIn_Image_URL ? data?.LinkedIn_Image_URL : require("../../../../../../assets/images/octateIconSvg.svg").default}
            className="linked-in-posts-card-image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = require("../../../../../../assets/images/octateIconSvg.svg").default;
            }}
          />
          <div onClick={() => window.open(data?.Link)} role="button">
            <p className="linked-in-posts-card-link-title">{data?.Article_Title}</p>
            <p href={data?.Link} className="linked-in-posts-card-link">
              {data?.Link}
            </p>
          </div>
        </div>

        <ProductExpViewMore handleActionClicked={(val) => handleActionClicked(val)} />
        {editModal ? <NewPost data={data} handleToggleModal={() => setEditModal(false)} /> : null}
      </div>
    </>
  );
};

export default LinkedInDraftCard;
