import React from "react";
import SmallCardInfo from "./smallCardInfo";

const SmallCardFirmographics = (props) => {
  const PRODUCT_DATA = props?.data;

  return (
    <div className="grid-auto-min more-information-container">
      <SmallCardInfo
        data={PRODUCT_DATA}
        icon={"feather"}
        navigate={"blurbs"}
        name="Blurbs"
        keyName={"Blurbs_Count"}
        page={props?.page}
        section={props?.section}
      />
      <SmallCardInfo
        data={PRODUCT_DATA}
        icon={"tool"}
        name="Use cases"
        navigate={"use-cases"}
        keyName={"Use_Cases_Count"}
        page={props?.page}
        section={props?.section}
      />
      {/* <SmallCardInfo
        data={PRODUCT_DATA}
        icon={"command"}
        navigate={"features"}
        name="Features"
        keyName={"Features_Count"}
        page={props?.page}
        section={props?.section}
      /> */}
    </div>
  );
};

export default SmallCardFirmographics;
