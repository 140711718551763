import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../config/configKeys";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../config/config";
import ValidateWorkEmail from "./validateWorkEmail";
import VerifyOTP from "./OTPVerification";
import VerifiedEmail from "./emailVerified";
const RequestWorkEmail = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const showRequestVendorPortal = props?.showRequestVendorPortal;
  const REQUEST_WORK_EMAIL_KEYS = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE.REQUEST_WORK_EMAIL;
  const [showValidateWorkEmail, setShowValidateWorkEmail] = useState(1);
  const dispatch = useDispatch();
  const RequestVendor = useEnCryptPostApi(
    `/addResourceRequest`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleSubmit = (val) => {
    // setSuccessMessage((prev) => !prev);
    // const NewVendorData = showRequestVendorPortal?.data;
    // const RAW_DATA = {
    //   Resource_Name: "Direct_Message",
    //   Requested_By: authData?.data?.userId,
    //   Notification_Email: authData?.data?.userExist ? authData?.data?.emailId : formDetails?.Notification_Email,
    //   Resource_Type: "Direct_Message",
    //   Additional_Information: {
    //     Resource_Name: "Direct_Message",
    //     Resource_Type: "Direct_Message",
    //     Vendor_Name: NewVendorData?.Vendor_Name,
    //     Vendor_ID: NewVendorData?.Vendor_ID,
    //     Domain_URL: NewVendorData?.Domain_URL,
    //     Description: "",
    //     Requested_By: authData?.data?.userId,
    //     Notification_Email: authData?.data?.emailId,
    //   },
    // };
    // const VALIDATE = validateRequestVendor(RAW_DATA);
    // setError(VALIDATE);
    // if (_.isEmpty(VALIDATE) && CaptchaVerified) {
    //   const TRIGGER_DATA = {
    //     Resource_Name: formDetails?.Resource_Name ? formDetails?.Resource_Name : searchText,
    //     Resource_Type: "Vendor",
    //     Requested_By: authData?.data?.userId,
    //     Notification_Email: authData?.data?.userExist ? authData?.data?.emailId : formDetails?.Notification_Email,
    //     Additional_Information: { ...RAW_DATA },
    //   };
    //   RequestVendor?.handleTrigger(TRIGGER_DATA);
    // }
    // RequestVendor?.handleTrigger(RAW_DATA);
    setShowValidateWorkEmail(val);
  };

  useEffect(() => {
    if (RequestVendor?.data?.Status || RequestVendor?.data?.isVerified || RequestVendor?.data?.isVerified === false)
      setTimeout(() => {
        HandleCloseModal();
      }, 5000);
  }, [RequestVendor?.data]);

  const HandleCloseModal = () => {
    props?.handleClose();
  };
  function handleChangePage(val) {
    setShowValidateWorkEmail(val);
  }

  return (
    <div className="request-vendor-portal--main-wrapper">
      {showValidateWorkEmail == 2 ? (
        <ValidateWorkEmail handleClose={props?.handleClose} handleChangePage={handleChangePage} />
      ) : showValidateWorkEmail == 3 ? (
        <VerifyOTP handleClose={props?.handleClose} handleChangePage={handleChangePage} />
      ) : showValidateWorkEmail == 4 ? (
        <VerifiedEmail handleClose={props?.handleClose} />
      ) : showValidateWorkEmail == 1 ? (
        <>
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="request-new-vendor-header-text"> {REQUEST_WORK_EMAIL_KEYS.SUBMIT_REQUEST}</h1>
          </div>
          <div className="request-category-container">
            <div className="m-b-sm">
              <h5 className="req-vendor-portal-desc-text">{REQUEST_WORK_EMAIL_KEYS.DESCRIPTION}</h5>
            </div>
            <div className="flex justify-flex-end">
              <div className="request-category-submit-button" onClick={() => handleSubmit(2)}>
                {REQUEST_WORK_EMAIL_KEYS.SUBMIT_REQUEST_BUTTON}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RequestWorkEmail;
