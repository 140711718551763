import { api } from "../../contexts/redux/slice";
export const searchService = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProductCategory: builder.query({
      query: ({ enCryptProductCategory }) => ({
        url: "api/get",
        params: enCryptProductCategory,
      }),
    }),
    getAllPopularDataProviders: builder.query({
      query: ({ enCryptGetPopularData }) => ({
        url: "api/get",
        params: enCryptGetPopularData,
      }),
    }),
    getAllPopularProductCategory: builder.query({
      query: ({ enCryptGetPopularProductCategoryData }) => ({
        url: "api/get",
        params: enCryptGetPopularProductCategoryData,
      }),
    }),
    // delistProductDetails: builder.mutation({
    //   invalidatesTags: ['productList'],
    //   query: body => ({
    //     url: `api/post`,
    //     method: 'POST',
    //     body,
    //   }),
    // }),
  }),
});
export const {
  useGetAllProductCategoryQuery,
  useGetAllPopularDataProvidersQuery,
  useGetAllPopularProductCategoryQuery,
} = searchService;
