import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";

const FeatureCard = (props) => {
  const TITLE = props?.title;
  const DESCRIPTION = props?.description;
  const FEATURE_ASSOCIATED = props?.data?.Feature_Associated;
  return (
    <div className="grid-max-auto feature-card-margin ">
      {FEATURE_ASSOCIATED ? (
        // <img
        //   class=" ls-is-cached lazyloaded"
        //   data-src={
        //     require("../../../assets/onboardingSvg/round-check.svg").default
        //   }
        //   src={
        //     require("../../../assets/onboardingSvg/round-check.svg").default
        //   }
        //   alt={"Round Check"}
        //   style={{ height: "20px", width: "20px" }}
        // />
        <FeatherIcon
          icon={"chevron-right"}
          size={23}
          color={"#1371c3"}
          // fill={"#83898C"}
        />
      ) : (
        <FeatherIcon icon={"x-circle"} size={25} color={"#FFF"} fill={"#83898C"} />
      )}
      <div style={FEATURE_ASSOCIATED ? {} : { marginTop: "3px" }}>
        <h3 className={`feature-card-header ${FEATURE_ASSOCIATED ? "" : "feature-card-header-description-disabled"}`}>
          {TITLE}
        </h3>
        <p
          className={`feature-card-description ${FEATURE_ASSOCIATED ? "" : "feature-card-header-description-disabled"}`}
        >
          {DESCRIPTION}
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
