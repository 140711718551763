import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import SummaryStatsCard from "./summaryStatsCard";
import FeatherIcon from "feather-icons-react";
import BoardRoundSpinner from "../../../../utlis/spinner/boardRoundSpinner";
const SummaryStats = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorId = authData?.data?.vendorId;
  const dashboardFilterDetails = useSelector((state) => state?.dashboardDetails?.dashboardFilter);
  const selectedTab = props?.selectedTab;
  const InfoData =
    selectedTab == 1
      ? "This is a visitor highlight"
      : selectedTab == 2
      ? "This is a engagement highlight"
      : "This is a content highlight";
  const selectedTabType = selectedTab == 1 ? "visitor" : selectedTab == 2 ? "engagement" : "content";
  const getOverView = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/dashboard_backend/getOverviewPageVisitCounts?vendorId=155&filters=[{name: "All Time",
    // id: 1,type: "date", },]
    `/getOverviewPageVisitCounts?vendorId=${vendorId}&filter=${JSON.stringify(
      dashboardFilterDetails
    )}&type=${selectedTabType}`,
    KEYS?.END_POINTS?.DASHBOARD?.END_POINT_URL,
    useGetRequestQuery
  );
  const overViewStats = getOverView?.data?.data?.length ? getOverView?.data?.data : [];
  return (
    <div
      className={`dashboard-summary-stats-list-main-wrapper p-r  modern-card ${
        getOverView?.isLoading || getOverView?.isFetching ? "dashboard-card-is-loading" : ""
      }`}
    >
      {getOverView?.isLoading || getOverView?.isFetching ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            // top: "15px",
          }}
        >
          <BoardRoundSpinner />
        </div>
      ) : null}
      <div
        style={{
          width: "100%",
          textAlign: "start",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        className=" p-x-mini p-y-mini"
      >
        <h2 className="dashboard-summary-overview-title-header p-x-base ">
          {selectedTab == 1 ? "Visitor Highlights" : selectedTab == 2 ? "Engagement Highlights" : "Content Highlight"}
        </h2>
        <div style={{ marginTop: "5px" }} data-inverted data-tooltip={InfoData}>
          <FeatherIcon icon={"info"} size="20" role="button" color={"#5570f1"} />
        </div>
      </div>

      {overViewStats?.map((list) => {
        return <SummaryStatsCard data={list} />;
      })}
    </div>
  );
};

export default SummaryStats;
