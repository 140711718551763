import React from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
const TopicList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  return (
    <>
      <div className="result-group">
        {props?.list?.map((item) => {
          const parts = item?.name?.split(
            new RegExp(`(${props?.inputText})`, "gi")
          );

          return (
            <a
              className="result result--inverted"
              href={`/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(
                item?.name,
                item?.id
              )}`}
            >
              <div className="content">
                <i className="feather icon icon-search "></i>
                {parts.map((part, i) => (
                  <span
                    key={i}
                    style={
                      part.toLowerCase() !== props?.inputText.toLowerCase()
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                  >
                    {part}
                  </span>
                ))}
              </div>
              <i className="feather icon icon-arrow-right "></i>
            </a>
          );
        })}
      </div>
    </>
  );
};
export default TopicList;
