import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
// import * as KEYS from "../../../config/config";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import LoadingSpinner from "../../spinner/loadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { tempAuthDetails } from "../../../contexts/reducer/authReducer";
import InputFieldNew from "../../../components/auth/signUp/onBoard/components/InputFieldNew";
import { useLocalStorage } from "../../customHooks/useLocalStorage";
import PasswordField from "../../../components/auth/signUp/onBoard/components/passwordField";
import _ from "lodash";
import { validateSignInForm } from "../../hof/hof";
import * as SECRET_KEYS from "../../../config/configKeys";
const EmailVerificationModal = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const SIGN_IN = KEYS?.EMAIL_VERIFICATION_SCREEN;
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const EmailId = useRef(null);
  const Password = useRef(null);
  const [error, setError] = useState(null);
  const [workEmail, setWorkEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const signalTrackingSignInObject = {
    Action: SECRET_KEYS.CONFIG.SIGN_IN_COMPETE,
    page: "home page",
    section: "sign in",
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const SignInPostApi = useEnCryptPostApi(`/login/userSignIn`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const onSubmit = async (e) => {
    e.preventDefault();
    const rawData = {
      emailId: workEmail,
      password: password,
      deviceId: authData?.data?.deviceId,
    };
    const validate = validateSignInForm(rawData);
    setError(validate);

    if (_.isEmpty(validate)) SignInPostApi?.handleTrigger(rawData);
  };

  const handleCloseModal = () => {
    props?.handleClose();
  };

  useEffect(() => {
    if (SignInPostApi?.data?.userExist) {
      const signalTrackingPostData = {
        Actor: SignInPostApi?.data?.userId,
        SignalCreatedAt: new Date().toString(),
        Action: signalTrackingSignInObject?.Action,
        Item_Type: null,
        Persona: SignInPostApi?.data?.Persona == "Business user" ? "Business user" : "Buyer",
        User_Type: "Known",
        Target: {
          page: "home",
          link: "/",
        },
        Object: {
          section: signalTrackingSignInObject?.section,
          context: "Sign in",
          page: props?.page,
          Persona: SignInPostApi?.data?.Persona == "Business user" ? "Business user" : "Buyer",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      setUser({ ...authUser?.data, ...SignInPostApi?.data });
      handleCloseModal();
      // navigate(location.state?.path ? location.state?.path : "/", {
      //   replace: true,
      // });
      // props?.handleClose();
    } else if (!SignInPostApi?.data?.status) {
      setError({
        emailId: SignInPostApi?.data?.emailId,
        password: SignInPostApi?.data?.password,
      });
    }
  }, [SignInPostApi?.data]);

  return (
    <>
      <div className="modern-card modern-card--elevated sign-in-modal">
        <div style={{ display: "flex", flexFlow: "row-reverse", padding: 5 }}>
          <a className="slide-in__close-btn closeButton" onClick={() => props?.handleClose()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <div className="modern-card__content modern-card__content--p-lg">
          <h1 className="sign-in-modal-header">{SIGN_IN?.LOG_IN}</h1>
          {/* {SignInPostApi?.data ? (
            !SignInPostApi?.data?.status ? (
              <div class="ui icon message visible warning m-t-xs">
                <i class="feather icon icon-alert-triangle "></i>
                <div class="content">
                  <p>{SignInPostApi?.data?.message}</p>
                </div>
              </div>
            ) : null
          ) : null} */}
          <form className="dtrd-form--modern m-t-md dtrd-form " onSubmit={onSubmit}>
            <div className="sign-up-page-text m-b-lg">
              <InputFieldNew
                label={ONBOARDING?.EMAIL_ADDRESS}
                value={workEmail}
                changeText={(val) => setWorkEmail(val)}
                error={error?.emailId}
                icon={"mail"}
                type={"email"}
                maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
              />
            </div>
            <PasswordField
              label={ONBOARDING?.PASSWORD}
              value={password}
              changeText={(val) => setPassword(val)}
              error={error?.password}
              type={"password"}
              icon={"lock"}
            />

            {SignInPostApi?.isLoading ? (
              <div className="button primary fluid m-t-sm" style={{ backgroundColor: "#5570f1" }}>
                <LoadingSpinner />
              </div>
            ) : (
              <input
                type="submit"
                name="commit"
                value={SIGN_IN?.LOG_IN_TEXT}
                className="button primary fluid m-t-sm"
                data-disable-with="Login"
                style={{ backgroundColor: "#5570f1" }}
              />
            )}
          </form>
        </div>

        <div className="modern-card__footer text-center">
          <div className="modern-card__text-sm">
            <div className="p-t-base">
              Don't have an account?{" "}
              <a role={"button"} onClick={() => navigate("/sign-up", { replace: true })}>
                {SIGN_IN?.SIGN_UP}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationModal;
