import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedVendorFn } from "../../../contexts/reducer/chatReducer";
import { GetNameInitials } from "../../../utlis/hof/hof";
import MoreOptions from "./moreOptions";
import * as CONFIG_KEYS from "../../../config/config";
function ChatRoomHeader(props) {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const dispatch = useDispatch();
  const BUYER_INPUT_CHAT = CONFIG_KEYS.CONFIG.DIRECT_MESSAGE.BUYER_CHAT;
  function formatTime(timeString) {
    const date = new Date(timeString);
    const formattedTime = date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
    return formattedTime.replace(" ", "");
  }
  function handleClose() {
    dispatch(selectedVendorFn({}));
  }
  function handleCommentAsAnonymousUser() {}
  function formatDate(epochTime) {
    var date = new Date(epochTime * 1000);

    var currentDate = new Date();
    var differenceInMilliseconds = currentDate - date;

    if (differenceInMilliseconds < 86400000) {
      return formatTime(date)?.toLocaleUpperCase();
    } else if (date.getFullYear() >= currentDate.getFullYear()) {
      return date.toLocaleDateString("default", { month: "short", day: "numeric" });
    } else {
      return date.toLocaleDateString();
    }
  }
  const LIST_DATA = selectedVendorSelector;
  const initials = GetNameInitials(LIST_DATA?.Vendor_Name);
  return (
    <>
      <div className="direct-message-chat-header-container-selected-vendor">
        <div className="direct-message-chat-header-container-selected-vendor-sub-container">
          <div className="dtrd-navbar__avatar-box">
            <div class="navbar__item" style={{ height: "auto" }}>
              <div class="circular-avatar">
                {LIST_DATA?.Vendor_Logo_URL ? (
                  <img
                    src={LIST_DATA?.Vendor_Logo_URL}
                    alt="vendor-image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                    }}
                    className="chat-room-header-vendor-image"
                  />
                ) : (
                  <div class="user-initials user-initials--primary">{initials}</div>
                )}
              </div>
              <h3 className="direct-message-chat-header">{LIST_DATA?.Vendor_Name}</h3>
            </div>
          </div>

          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }} className="flex space-between">
              {/* <p className="chat-list-date">{formatDate(LIST_DATA?.Date_Time)}</p> */}
              {!selectedVendorSelector?.Verified ? null : <MoreOptions />}
              <FeatherIcon onClick={handleClose} icon={`x`} size="20" color={"#33343a"} role="button" />
            </div>
          </div>
        </div>
      </div>
      {selectedVendorSelector?.Anonymous_Name ? (
        <div className="direct-message-chat-header-container-selected-vendor-sub-container--anonymous-user">
          <p className="filter-list-items-text-anonymous-user m-l-half-base">{BUYER_INPUT_CHAT.ANONYMOUS_CHAT_DESC}</p>
        </div>
      ) : null}
    </>
  );
}

export default ChatRoomHeader;
