import React from "react";
import { useSelector } from "react-redux";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";

const ProductTopics = (props) => {
  const topicsData = useSelector((state) =>
    state?.homeDetails?.topicsData.length ? state?.homeDetails?.topicsData?.filter((val) => val?.Level === 4) : null
  );

  const PRODUCT_DATA = props?.Data;

  const handleTopicPressed = (val) => {
    if (val && props?.handleTopicPressed) {
      props?.handleTopicPressed(val);
    }
  };

  return (
    <div className="product-topic-container">
      {PRODUCT_DATA?.tags?.map((item) => {
        const valueSelected = topicsData?.some((val) => val?.Tag_Id === item?.Tag_Id);
        return valueSelected ? (
          <a
            className="blurb-detail-tags-selected"
            role="button"
            onClick={() => handleTopicPressed(item)}
            key={item?.Tag_Id}
            href={`/topic/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Tag_Id)}/blurbs`}
          >
            {item?.Name}
          </a>
        ) : (
          <a
            className="blurb-detail-tags"
            role="button"
            onClick={() => handleTopicPressed(item)}
            key={item?.Tag_Id}
            href={`/topic/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Tag_Id)}/blurbs`}
          >
            {item?.Name}
          </a>
        );
      })}
    </div>
  );
};

export default ProductTopics;
