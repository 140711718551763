import React, { useCallback, useEffect } from "react";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import CardImage from "../../../../../utlis/hoc/productCard/cardImage";
import CardContent from "../../../../../utlis/hoc/productCard/cardContent";
import CardPricing from "../../../../../utlis/hoc/productCard/cardPricing";
import DataProviderFooter from "../../../../../utlis/hoc/providerCardSmall/dataProviderFooter";

function Recomondedproducts(props) {
  const data = props?.data;
  const section = "product related to category";
  return (
    <div className="item-search-rendered__items">
      {data?.map((item, index) => {
        return (
          <div key={index}>
            <article
              id="data_product_card_a27ce1d3-d8fe-4171-b2c7-41316e9c7fc1"
              className="data-product-card "
            >
              <main>
                <CardImage
                  searchText={item?.Vendor_Name}
                  page={props?.page}
                  section={section}
                  Data={item}
                />
                <CardContent
                  searchText={item?.Vendor_Name}
                  page={props?.page}
                  section={section}
                  Data={item}
                  handleTopicPressed={props?.handleTopicPressed}
                />
                <CardPricing
                  searchText={item?.Vendor_Name}
                  page={props?.page}
                  section={section}
                  Data={item}
                />
              </main>
              {/* <DataProviderFooter data={item} /> */}
            </article>
          </div>
        );
      })}
    </div>
  );
}

export default Recomondedproducts;
