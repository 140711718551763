import React from "react";
import UseCaseContent from "./useCaseContent";
import UseCaseCtaButton from "./useCaseCtaButton";
import UseCaseHeader from "./useCaseHeader";
import UseCaseMedia from "./useCaseMedia";
import EngagementBar from "../../engagementBar/engagementBar";
import { useState } from "react";
import Comments from "../../comments/comments";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";

const UseCaseWrapper = ({ item, props, index }) => {
  const [viewComments, setViewComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const cardType = "usecase";
  const permissionsDetails = props?.permissionsDetails;
  const handleComment = (status) => {
    setCommentCount((prev) => prev + 1);
  };

  const onDragOver = (e, id) => {
    e.preventDefault();
  };
  const onDragStart = (e, id) => {
    if (props?.page == "board detail page") {
      e.dataTransfer.setData("current index", id);
    }
  };
  const onDrop = (e, index, value) => {
    if (props?.page == "board detail page") {
      const currentIndex = e.dataTransfer.getData("current index");
      props?.handleChangePosition(currentIndex, index, value);
    }
  };

  return (
    <div
      draggable={props?.page == "board detail page" ? true : false}
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index, "Use Cases")}
      className="product-card"
      key={item?.Use_Case_Blurb_ID}
    >
      {props?.page === "product page-use-cases" ||
      props?.page === "vendor page" ||
      props?.page === "business profile" ? null : (
        <UseCaseHeader
          keyName={"blurbID"}
          data={item}
          cardType={cardType}
          page={props?.page}
          section={props?.section}
          searchText={props?.searchText}
        />
      )}
      <div
        className={`${
          props?.page === "product page-use-cases" ? "blurb-card-content-without-header" : "blurb-card-content"
        }`}
      >
        <UseCaseContent
          data={item}
          cardType={cardType}
          page={props?.page}
          section={props?.section}
          searchText={props?.searchText}
        />

        <UseCaseMedia
          cardType={cardType}
          page={props?.page}
          section={props?.section}
          data={item}
          searchText={props?.searchText}
        />
      </div>
      <div className="blurb-card-engagement-wrapper">
        <div className="new-card-engagement-bar-container">
          <EngagementBar
            shareLink={`/${ReplaceBlankSpaceByHyphen(item?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
              BreakText(item?.title_level_1?.trim())
            )}-${item?.blurbID}`}
            permissionsDetails={permissionsDetails}
            cardType={cardType}
            page={props?.page}
            section={props?.section}
            data={item}
            keyName={"blurbID"}
            engageSource={"use case engagement from use case card"}
            handleViewComments={() => setViewComments((prev) => !prev)}
            commentCount={commentCount}
          />
        </div>
        <UseCaseCtaButton cardType={cardType} page={props?.page} section={props?.section} data={item} />
      </div>
      {viewComments && (
        <Comments
          cardType={cardType}
          data={item}
          page={props?.page}
          searchText={props?.searchText}
          keyName={"blurbID"}
          handleCommentCount={(val) => handleComment(val)}
        />
      )}
    </div>
  );
};

export default UseCaseWrapper;
