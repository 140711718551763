import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetProposalQuery,
  useGetRequestQuery,
} from "../../../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../../../config/config";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import WeekDropdown from "./weekDropdown";
const ProposalSummary = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL;
  const authData = useSelector((state) => state?.authDetails?.details);
  const PROPOSAL_HEADER_KEYS = KEYS?.BUYER_PROPOSAL_LANDING_PAGE;
  const WEEK_DROPDOWN_DATA = API_KEYS?.WEEK_DROPDOWN_DATA;
  const [timeFilterSelected, setTimeFilterSelected] = useState({
    name: "This Week",
    internalName: "This_Week",
    id: 2,
  });
  const [overallStats, setOverallStats] = useState([]);
  const PROPOSAL_SUMMARY = [
    { name: "All Proposal", id: 1, count: 20 },
    { name: "Ongoing", id: 2, count: 12 },
    { name: "Completed", id: 3, count: 5 },
    { name: "Canceled", id: 4, count: 23 },
  ];
  const [toggleMenu, setToggleMenu] = useState(false);
  // proposalOverallStats
  const getProposalOverallStats = useEnCryptGetApi(
    `/proposalOverallStats?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetProposalQuery
  );
  const handleViewOptionsClicked = () => {
    setToggleMenu(!toggleMenu);
  };
  useEffect(() => {
    if (getProposalOverallStats?.data?.data?.length) {
      setOverallStats(getProposalOverallStats?.data?.data);
    }
  }, [getProposalOverallStats?.data]);
  const handleActionClicked = (val) => {
    setTimeFilterSelected(val);
    setTimeout(() => {
      setToggleMenu(!toggleMenu);
    }, 200);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };

  return (
    <div className="proposal-summary-main-wrapper">
      <div className="proposal-summary-header-main-wrapper p-x-mini p-y-mini">
        {/* <div>
          <img
            className="octate-logo "
            width="25px"
            height="25px"
            src={require("../../../../assets/images/folderSvgIcon.svg").default}
            alt={"profile image"}
          />
        </div> */}
        {/* <div onBlur={handleBlur} tabIndex={0} className="p-r">
          <div
            onClick={handleViewOptionsClicked}
            className="dis-f-r cursor--pointer"
          >
            <p className="proposal-summary-header-content-week-text">
              {timeFilterSelected?.name}
            </p>
            <i
              class={`feather icon ${
                toggleMenu ? "icon-chevron-up" : "icon-chevron-down"
              } c-i-g`}
            ></i>
          </div>
          {toggleMenu && (
            <div className="view-week-dropdown-container  modern-card modern-card--elevated">
              <WeekDropdown
                handleActionClicked={handleActionClicked}
                dropdownData={WEEK_DROPDOWN_DATA}
              />
            </div>
          )}
        </div> */}
      </div>
      <div className="proposal-summary-stats-main-wrapper">
        {overallStats?.map((list) => {
          return (
            <div className="proposal-summary-stats-wrapper">
              <div>
                <p className="proposal-summary-content-stats-text">
                  {list?.[API_KEYS?.PROPOSAL_SUMMARY?.NAME]}
                </p>
              </div>
              <div>
                <p className="proposal-summary-content-stats-count">
                  {list?.[API_KEYS?.PROPOSAL_SUMMARY?.COUNT]}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProposalSummary;
