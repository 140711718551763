import React from "react";
import { useSelector } from "react-redux";
import Dropdown from "../../../../../../utlis/hoc/dropdown/dropdown";
import NewVendorCard from "../../../../../../utlis/hoc/newVendorCard/newVendorCard";
import ProviderCardTabList from "./components/providerCardTabList";
// import * as KEYS from "../../../../../../config/config";

function ProviderTab(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const REF_DROPDOWN = KEYS?.MULTI_SELECT_DROPDOWN;

  const REF_DROPDOWNBYCAT = KEYS?.CATEGORY_PAGE.MULTI_SELECT_DROPDOWNBYCATEGORY;

  const REF_DROPDOWNBYUSECASE =
    KEYS?.CATEGORY_PAGE.MULTI_SELECT_DROPDOWNBYUSECASE;

  const REF_DROPDOWNBYIND = KEYS?.CATEGORY_PAGE.MULTI_SELECT_DROPDOWNBYINDUSTRY;

  const handleApplyCountryClicked = (val) => {
    console.warn(val);
  };

  const handleApplyCategoryClicked = (val) => {
    console.warn(val);
  };

  const handleApplyUseCaseClicked = (val) => {
    console.warn(val);
  };

  const handleApplyIndustryClicked = (val) => {
    console.warn(val);
  };

  return (
    <div>
      <div class="p-b-md">
        <div class="landing-page__hero-header p-t-none">
          <div class="container">
            <div class="landing-page__header">
              <h1 class="landing-page__title">
                {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.TITLE_START}{" "}
                {props?.vendorName} {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.TITLE_END}
              </h1>

              <div class="landing-page__description">
                <div>
                  <p>
                    {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.TITLE_DISC_START}
                    {props?.vendorName}
                    {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.TITLE_DISC_END}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="landing-page__section-header m-b-none">
          <div class="section-header__content">
            <h2 class="landing-page__section-title">
              {/* {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.PAGE_HEADLINE_START} */}
              {props?.vendorName}
              {KEYS?.CATEGORY_PAGE.PROVIDER_TAB.PAGE_HEADLINE_END}
            </h2>
          </div>
        </div>
        {/* <div class="grid-four-columns tablet-landscape-1-col grid-col-gap-xs">
          <Dropdown
            data={REF_DROPDOWN}
            handleApplyClicked={handleApplyCountryClicked}
            label={"Filters by country"}
          />
          <Dropdown
            data={REF_DROPDOWNBYCAT}
            handleApplyClicked={handleApplyCategoryClicked}
            label={"Filter by category"}
          />
          <Dropdown
            data={REF_DROPDOWNBYUSECASE}
            handleApplyClicked={handleApplyUseCaseClicked}
            label={"Filter by use case"}
          />
          <Dropdown
            data={REF_DROPDOWNBYIND}
            handleApplyClicked={handleApplyIndustryClicked}
            label={"Filter by industry"}
          />
        </div> */}
      </div>
      <div class="grid-col-gap-sm grid-row-gap-md tablet-landscape-1-col m-t-sm">
        <NewVendorCard page={props?.page} categoryId={props?.categoryId} />
      </div>
    </div>
  );
}

export default ProviderTab;
