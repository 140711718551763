import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../../adapters/xhr/generalApi";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
} from "../../../utlis/customHooks/customHooks";
import Dropdown from "../../../utlis/hoc/dropdown/dropdown";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import TextArea from "../../../utlis/hoc/textArea/textArea";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import * as CONFIG_KEYS from "../../../config/config";
import ProposalBusinessNeeds from "./proposalBusinessNeeds";
const ProposalBasicDetails = (props) => {
  const [searchIndustryText, setSearchIndustryText] = useState("a");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  //   const KEYS = {
  //     ADD_PROPOSAL: {
  //       SUCCESS_MESSAGE: "Product details updated successfully",
  //       PRODUCT_PAGE_TITLE: "New Product",
  //       PRODUCT_NAME: "Product Name",
  //       PRODUCT_NAME_LABEL: "Product Name",
  //       VENDOR_NAME: "Vendor Name",
  //       VENDOR_NAME_LABEL: "Vendor Name",
  //       SUPPORTED_LANGUAGES: "Supported Languages",
  //       SUPPORTED_LANGUAGES_LABEL: "Supported Languages",
  //       PRODUCT_SEGMENT: "Product Segment",
  //       PRODUCT_SEGMENT_LABEL: "Product Segment",
  //       INDUSTRY: "Industry",
  //       INDUSTRY_LABEL: "Select Industry",
  //       SOFTWARE_TYPE: "Software Type",
  //       SOFTWARE_TYPE_LABEL: "Software Type",
  //       DESCRIPTION: "Product Description",
  //       DESCRIPTION_LABEL: "Product Description",
  //       PRODUCT_PAGE_LINK: "Product Page Link",
  //       PRODUCT_PAGE_LINK_LABEL: "Product Page Link",
  //       BACK: "Back",
  //       SAVE: "Save",
  //       NEXT: "Next",
  //     },
  //   };
  const error = props?.companyErrors;
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const proposalBasicDetails = props?.proposalDetails;
  //   https://thebox-dev.portqii.com/core/vendor/vendorOnboardingSearch?searchText=auto&type=industry
  const industryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchIndustryText}&type=industry`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const businessSizeApi = useEnCryptGetApi(
    `/getEnterpriseList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;

  return (
    <div className="company-profile-basic-details-main-container">
      <div className="product-profile-basic-details-left-side-container">
        <div
          class="field p-r m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          {proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TITLE] ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {ADD_PROPOSAL?.PROPOSAL_TITLE}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_PROPOSAL?.PROPOSAL_TITLE_LABEL}
            value={
              proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TITLE]
            }
            changeText={(val) =>
              props?.handleDetails({
                ...proposalBasicDetails,
                [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TITLE]: val,
              })
            }
            error={error?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TITLE]}
          />
        </div>
        <div class="field p-r m-t-mini" id="field-user__phone_number_prefix">
          {proposalBasicDetails?.[
            ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_DESCRIPTION
          ] ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {ADD_PROPOSAL?.DESCRIPTION}
            </label>
          ) : null}
          <TextArea
            value={
              proposalBasicDetails?.[
                ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_DESCRIPTION
              ]
            }
            className="m-b-sm"
            label={ADD_PROPOSAL?.DESCRIPTION_LABEL}
            changeText={(val) =>
              props?.handleDetails({
                ...proposalBasicDetails,
                [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_DESCRIPTION]: val,
              })
            }
            subTitleTop={
              "Please add at least 50 characters"
            }
            error={error?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_DESCRIPTION]}
          />
        </div>
        {/* <div
          class="field p-r m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          {proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS] ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {ADD_PROPOSAL?.SOLUTION_NEEDS}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_PROPOSAL?.SOLUTION_NEEDS_LABEL}
            value={
              proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]
            }
            changeText={(val) =>
              props?.handleDetails({
                ...proposalBasicDetails,
                [ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]: val,
              })
            }
            error={error?.[ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]}
          />
        </div> */}
        <div style={{ marginTop: "40px" }}>
          <ProposalBusinessNeeds
            proposalBasicDetails={proposalBasicDetails}
            handleDetails={props?.handleDetails}
          />
        </div>
        {/* <div style={{ margin: "50px 0px 50px 0px" }}>
          <div
            class="field p-r m-y-company-profile-input-wrapper"
            id="field-user__email"
          >
            {proposalBasicDetails?.[
              ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN
            ] ? (
              <label
                for="user__email"
                className="company-details-label-input-field-filled-absolute"
              >
                {ADD_PROPOSAL?.BUSINESS_DOMAIN}
              </label>
            ) : null}
            <InputFieldProfile
              label={ADD_PROPOSAL?.BUSINESS_DOMAIN_LABEL}
              value={
                proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]
              }
              changeText={(val) =>
                props?.handleDetails({
                  ...proposalBasicDetails,
                  [ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]: val,
                })
              }
              error={error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]}
            />
          </div>
        </div> */}
        <div style={{ margin: "50px 0px 50px 0px" }}>
          <div
            class="field-company-profile p-r m-y-base"
            id="field-user__country_id"
          >
            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleDetails({
                  ...proposalBasicDetails,
                  [ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]: val,
                })
              }
              data={industryDropdownData}
              placeholder={ADD_PROPOSAL?.BUSINESS_DOMAIN}
              selectedVal={
                proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]
              }
              label={ADD_PROPOSAL?.BUSINESS_DOMAIN_LABEL}
            />
            {error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN] ? (
              <span class="input-field-dropdown-error">
                {error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]}
              </span>
            ) : null}
          </div>
        </div>

        <div style={{ margin: "50px 0px 50px 0px" }}>
          <div
            class="field-company-profile p-r m-y-md"
            id="field-user__country_id"
          >
            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleDetails({
                  ...proposalBasicDetails,
                  [ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_SIZE]: val,
                })
              }
              data={businessSizeApi}
              placeholder={ADD_PROPOSAL?.BUSINESS_SIZE}
              // flag={true}
              selectedVal={
                proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_SIZE]
              }
              name={"name"}
              label={ADD_PROPOSAL?.BUSINESS_SIZE_LABEL}
            />
            {error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_SIZE] ? (
              <span class="input-field-dropdown-error">
                {error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_SIZE]}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="company-profile-basic-details-right-side-container"></div>
    </div>
  );
};

export default ProposalBasicDetails;
