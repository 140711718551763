import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import ProductsCards from "../../../../../utlis/hoc/productCardSmall/productsCards";
import { GetIdFormLink } from "../../../../../utlis/hof/hof";
import ProviderProducts from "../../providerProducts/providerProducts";
import Filters from "../filters/filters";

function AllProductsList(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const vendorName = GetIdFormLink(props?.vendorName)?.Id;
  const GetApiData = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${props?.vendorId}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const allProducts = GetApiData?.data?.data;
  return (
    <div class="container p-b-md">
      <Filters />
      <div class="provider_profile_products-list grid-three-columns laptop-2-col tablet-1-col grid-gap-md">
        {allProducts?.length
          ? allProducts.map((products) => {
              return (
                <ProductsCards
                  allProducts={products}
                  parent={props?.parent}
                  type={"Products"}
                />
              );
            })
          : null}
      </div>
      <div class="text-center m-t-md"></div>
    </div>
  );
}

export default AllProductsList;
