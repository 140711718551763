import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useProductExpGetPostQuery } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import LinkedInDraftCard from "./linkedInDraftCard";

const Drafts = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [newPostVisible, setNewPostVisible] = useState(false);
  const handleToggleModal = () => {
    setNewPostVisible((prev) => !prev);
  };

  const ProductExpertPostsData = useEnCryptGetApi(
    `/getLinkedInDraftPosts?userId=${props?.userId ?? authData?.data?.userId}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useProductExpGetPostQuery
  );

  const handleSortPost = (item, order) => {
    // _.orderBy(users, [item], [order]);
  };

  return (
    <div className="product-expert-post-container">
      {ProductExpertPostsData?.data?.data?.data?.length ? (
        <div className="flex flex-wrap" style={{ gap: "20px", marginTop: "20px" }}>
          {ProductExpertPostsData?.data?.data?.data?.map((post) => (
            <LinkedInDraftCard data={post} />
          ))}
        </div>
      ) : (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">Oops! No post found in drafts.</div>
        </div>
      )}
    </div>
  );
};

export default Drafts;
