import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
import * as ADD_PROPOSAL_KEYS from "../../../../config/config";
import ConfirmClosePortal from "../../../addProposal/component/confirmClosePortal";
const EditProposalHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const footerNumberClicked = props?.footerNumberClicked;
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const proposalDetails = props?.proposalDetails;
  const isLoading = props?.isLoading;
  const companyErrors = props?.companyErrors;
  const handleActionsClicked = (val) => {
    if (val == "close") {
      props?.handleClose();
    } else {
      props?.handleSaveAnswerClicked("Draft");
    }
  };
  const Locked_By = proposalDetails?.Locked_By;
  return (
    <>
      {showCloseModal ? (
        <ConfirmClosePortal
          proposalPublished={props?.proposalPublished}
          vendorPostData={props?.vendorPostData}
          handleClose={() => setShowCloseModal(false)}
          handleActionsClicked={handleActionsClicked}
        />
      ) : null}
      <div className="company-details-header-main-wrapper">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="company-details-header-title-text">
            {props?.productPageLabel}
            {/* {props?.productPageLabel
          ? props?.productPageLabel
          : authData?.data?.Persona === "Business User"
          ? KEYS?.COMPANY_PROFILE?.EDIT_ORAGANIZATION
          : KEYS?.COMPANY_PROFILE?.NEW_ORGANIZATION} */}
          </div>
          {proposalDetails?.Is_Locked ? (
            <div>
              <span className="company-details-locked-by-label m-l-mini m-r-base">
                This proposal is being edited by
              </span>
              <span className="company-details-locked-by-title-text">{Locked_By}</span>
            </div>
          ) : null}
        </div>

        <div
          // onClick={() => props?.handleClose()}
          className="answer-proposal-header-title-icon"
        >
          {companyErrors?.[PROPOSAL_KEYS?.SECTION_INDEX] ? (
            <h3 style={{ color: "red", margin: "0", paddingRight: "40px" }}>
              {companyErrors?.[PROPOSAL_KEYS?.SECTION_INDEX]}
            </h3>
          ) : null}
          {USER_CAN_EDIT ? (
            <>
              {isLoading && !showCloseModal ? (
                <div style={{ height: "38px", width: "55px" }} className="company-details-footer-next m-r-sm">
                  <LoadingSpinner size={"small"} />
                </div>
              ) : (
                <>
                  <div
                    onClick={() => props?.handleSaveAnswerClicked("Published")}
                    className="company-details-footer-back m-x-mini"
                  >
                    {/* {COMPANY_PROFILE?.NEXT} */}
                    Publish
                  </div>
                  <div
                    onClick={() => props?.handleSaveAnswerClicked("Draft")}
                    className="company-details-footer-back m-x-mini"
                  >
                    {/* {COMPANY_PROFILE?.NEXT} */}
                    Save
                  </div>
                </>
              )}
            </>
          ) : null}

          <div onClick={() => setShowCloseModal(true)} className="company-details-footer-next">
            {/* {COMPANY_PROFILE?.NEXT} */}
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProposalHeader;
