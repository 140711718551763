import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";


export function PaginatedItems({
  page,
  itemsPerPage,
  HandlePageNumber,
  totalNumber,
}) {
  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  useEffect(() => {
    // Fetch items from another resources.
    if (totalNumber && itemsPerPage) {
      setPageCount(Math.ceil(totalNumber / itemsPerPage));
    }
  }, [totalNumber]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    HandlePageNumber(event.selected + 1);
  };

  return totalNumber ? (
    <>
      <div class="text-center m-t-mini m-b-mini auto-width mobile-hidden">
        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={9}
          marginPagesDisplayed={2}
          pageCount={pageCount ? pageCount : 0}
          previousLabel="< Previous"
          pageClassName="item paginate-item"
          forcePage={page - 1}
          // pageLinkClassName="page-link"
          //   previousClassName="icon item"
          previousLinkClassName="icon item"
          //   nextClassName="icon item"
          nextLinkClassName="icon item"
          breakLabel="..."
          breakClassName="item"
          // breakLinkClassName="page-link"
          // containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          className="dtrd-menu pagination"
        />
      </div>
    </>
  ) : null;
}
