import React, { useState ,useEffect } from "react";
import Footer from "../../../home/blades/footer";
import Header from "../../../home/header/header";
import HeaderSEO from "../../../SEO/header";
import SearchHeaderBreadCrumb from "../seachHeader/searchHeaderBreadCrumb";
import PopularDataCategories from "./component/allCategoriesData/component/popularDataCategories";
import SearchCategories from "./component/allCategoriesData/component/searchCategories";
import HeroHeader from "./component/heroHeader";
import {updateMetaTags} from "../../../../../src/components/SEO/seo";

const AllCategories = (props) => {
  const [listCount, setListCount] = useState(0);

  const handleListCount = (val) => {
    if (val) {
      setListCount(val);
    }
  };

  useEffect(() => {
    const seoBody = {
      type : 'allCategory',
      id : ""
    }
    updateMetaTags(seoBody);
  }, []);

  return (
    <div id="app" className="landing-page__show">
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      <Header page={props?.page} show={true} />

      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb page={props?.page} parent={"allCategoryPage"} type={"All Categories"} />
          </div>
        </div>
        <HeroHeader page={props?.page} listCount={listCount} />

        <div className="landing-page__content">
          <div className="landing-page__section" style={{ backgroundColor: "#fff" }}>
            <div className="ui container">
              <div className="categories grid-2-1 tablet-1-col grid-col-gap-lg">
                <div>
                  <SearchCategories page={props?.page} handleListCount={handleListCount} />
                </div>
                <>{/* <PopularDataCategories page={props?.page} /> */}</>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer page={props?.page} />
    </div>
  );
};
export default AllCategories;
