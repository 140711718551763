import React from "react";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import Header from "../../../home/header/header";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";

const UserActive = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  const LinkedInAuthenticate = useEnCryptPostApi(`/linked-in-access`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const handleSubmit = () => {
    const USER_DETAILS = {
      userId: authData?.data?.userId,
      emailAddress: authData?.data?.emailId,
    };
    const ENCRYPTED_USER_DETAILS = CryptoJS.AES.encrypt(JSON.stringify(USER_DETAILS), SECRET_KEYS?.CONFIG?.LINKED_IN_SECRET).toString();

    const REDIRECT_URL = encodeURI(SECRET_KEYS?.CONFIG?.BASE_END_POINT + "/core/auth/linkedin/callback");
    const URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${SECRET_KEYS?.CONFIG?.LINKED_IN_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=${SECRET_KEYS?.CONFIG?.LINKED_IN_SCOPE}&state=${ENCRYPTED_USER_DETAILS}`;

    window.location.replace(URL);
  };

  return (
    <>
      <Header show={false} />
      <div className="linked-in-access-container">
        <img src={require("../../../../assets/images/linkedInAccess.svg").default} className="linkedInAccess-image" />
        <div style={{ width: "30%" }}>
          <h2 className="linked-in-access-text">
            <b>Oops! It seems like there's already an account associated with this email address. </b>Please try logging in, or if you've
            forgotten your password, you can reset it. If you're having trouble, feel free to contact our support team for assistance.
          </h2>
          {LinkedInAuthenticate?.isLoading ? (
            <div className="button primary fluid create-account-button" style={{ width: "100%" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div
              className="create-account-button"
              style={{ cursor: "pointer", width: "100%", marginTop: "30px" }}
              onClick={() => handleSubmit()}
            >
              Link different account
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserActive;
