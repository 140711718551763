import React from "react";
import { useSelector } from "react-redux";

const ProductFeature = (props) => {
  const productFeature = props?.data?.productFeature;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  return (
    <>
      <div className="data-product-card__convince-pricing-details">
        <div className="pricing-header">
          {KEYS?.PRODUCT_DATA?.PRODUCT_FEATURE}
        </div>
        {productFeature?.slice(0, 3)?.map((item) => {
          return (
            <div className="pricing-model">
              <i className="feather icon icon-check "></i>
              {item?.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProductFeature;
