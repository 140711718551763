import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddToBoard from "../../../../utlis/hoc/Boards/addToBoard";
import ListCount from "../../../../utlis/hoc/productCard/listCount";
import SignInModal from "../../../../utlis/hoc/signInModal/signInModal";
import StickyTab from "../../../../utlis/hoc/tabNavigator/stickyTab";
import TabNavigator from "../../../../utlis/hoc/tabNavigator/tabNavigator";
import SelectedFilterList from "../filter/selectedFilterList";

const SearchResultHeader = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const NO_RESULT = KEYS?.PRODUCT_NO_RESULT;
  const DATA = props?.searchedData;
  const TAB_DATA = props?.tabData;
  const PAGE_TYPE =
    props?.type == "products"
      ? "products"
      : props?.type == "vendors"
      ? "vendors"
      : props?.type == "blurbs"
      ? "blurbs"
      : props?.type == "use-cases"
      ? "case studies"
      : null;
  const CARD_TYPE =
    props?.type == "products"
      ? "product"
      : props?.type == "vendors"
      ? "vendor"
      : props?.type == "blurbs"
      ? "blurb"
      : props?.type == "use-cases"
      ? "usecase"
      : null;
  const SELECTED_ID_KEYS =
    props?.type == "products"
      ? "Product_ID"
      : props?.type == "vendors"
      ? "Vendor_ID"
      : props?.type == "blurbs"
      ? "blurbID"
      : props?.type == "use-cases"
      ? "blurbID"
      : null;

  const handleAddToBoard = () => {
    authData?.data?.userExist ? setModalVisible((prev) => !prev) : setSignInModalVisible((prev) => !prev);
  };
  return (
    <div className="search-result-container">
      <div className="grid-two-columns tablet-1-col">
        <div className="m-b-xs flex align-items-center space-between">
          {props?.loading ? (
            <div className="search__info">
              <span>loading...</span>
            </div>
          ) : props?.totalNumber ? (
            <ListCount
              page={props?.page}
              pageNo={props?.pageNo}
              type={PAGE_TYPE}
              totalNumber={props?.totalNumber}
              searchText={props?.searchText}
            />
          ) : (
            <div className="search__info">
              <span>No {props?.type?.slice(0, -1)} results</span>
              <span> {NO_RESULT?.FOR} </span>
              <span className="search-term">{props?.searchText}</span>
            </div>
          )}
          {props?.totalNumber ? (
            <div
              className="product-card-follow-button only-tablet-landscape"
              role={"button"}
              onClick={() => props?.handleViewFilter()}
            >
              <p className="product-card-follow-button-text">Filters</p>
            </div>
          ) : null}
          {/* : props?.loading ? (
            <div className="search__info">
              <span>loading...</span>
            </div>
          ) : (
            <div className="search__info">
              <span>No {props?.type?.slice(0, -1)} results</span>
              <span> {NO_RESULT?.FOR} </span>
              <span className="search-term">{props?.searchText}</span>
            </div>
          )} */}
        </div>
        {/* {CARD_TYPE !== "usecase" && DATA?.length > 0 ? (
          <div className="business-profile-button-container">
            <div
              onClick={() => handleAddToBoard()}
              className="business-profile-header-button"
              role={"button"}
              style={{ marginRight: "10px" }}
            >
              {`Add ${DATA?.length >= 10 ? "top" : ""} ${DATA?.length >= 10 ? 10 : DATA?.length} ${PAGE_TYPE} to board`}
            </div>
          </div>
        ) : null} */}
      </div>

      <SelectedFilterList
        page={props?.page}
        section={`search filters-${props?.pageNo}`}
        data={props?.selectedFilter}
        handleRemoveFilterData={props?.handleRemoveFilterData}
        handleClearData={props?.handleClearData}
      />
      <div className="flex tab-navigator-container">
        {TAB_DATA?.map((item, index) => {
          if (item?.display)
            return (
              <TabNavigator
                tabIndex={props?.type}
                tabItem={item}
                index={index + 1}
                navigatePath={"searchResult"}
                page={props?.page}
              />
            );
        })}
      </div>
      <StickyTab
        tabIndex={props?.type}
        tabData={TAB_DATA}
        navigatePath={"searchResult"}
        scrollPosition={props?.scrollPosition}
        page={props?.page}
      />
      {modalVisible ? (
        <AddToBoard
          data={DATA}
          keyName={SELECTED_ID_KEYS}
          cardType={CARD_TYPE}
          dataType={"Array"}
          handleClose={() => setModalVisible((prev) => !prev)}
        />
      ) : null}
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible(false)} /> : null}
    </div>
  );
};

export default SearchResultHeader;
