import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import ConfirmClosePortal from "./confirmClosePortal";
const ProposalHeader = (props) => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const handleActionsClicked = (val) => {
    if (val == "close") {
      props?.handleClose();
    } else {
      props?.handleSaveClicked("Draft");
    }
  };
  return (
    <>
      {showCloseModal ? (
        <ConfirmClosePortal
          proposalPublished={props?.proposalPublished}
          vendorPostData={props?.vendorPostData}
          handleClose={() => setShowCloseModal(false)}
          handleActionsClicked={handleActionsClicked}
          //   checkIfCanDelete={checkIfCanDelete}
          //   vendorPostData={vendorPostData}
        />
      ) : null}
      <div className="company-details-header-main-wrapper">
        <div className="company-details-header-title-text">
          {props?.productPageLabel
            ? props?.productPageLabel
            : authData?.data?.Persona === "Business User"
            ? KEYS?.COMPANY_PROFILE?.EDIT_ORAGANIZATION
            : KEYS?.COMPANY_PROFILE?.NEW_ORGANIZATION}
        </div>
        {/* props?.handleClose() */}
        <div
          onClick={() => setShowCloseModal(true)}
          className="company-details-header-title-icon"
        >
          <FeatherIcon icon="x" size="20" />
        </div>
      </div>
    </>
  );
};

export default ProposalHeader;
