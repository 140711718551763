import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useState } from "react";
import { usePutRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
import * as CONFIG from "../../../../../../config/config";
import RoundSpinner from "../../../../../../utlis/spinner/roundSpinner";
import { useEffect } from "react";

const LicensePlan = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [licenseCount, setLicenseCount] = useState(props?.licenseCount ?? 1);
  const [loading, setLoading] = useState(false);
  const LICENSE = CONFIG?.CONFIG?.LICENSE;

  const LicenseUpdate = useEnCryptPostApi(
    `/updateNumberOfLicense`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePutRequestMutation
  );

  useEffect(() => {
    if (LicenseUpdate?.data?.status) {
      setTimeout(() => {
        props?.handleCloseModal();
      }, 2000);
    }
  }, [LicenseUpdate]);

  const handleSubmit = (val) => {
    setLoading(true);
    const RAW_DATA = {
      Vendor_ID: authData?.data?.vendorId,
      Count: licenseCount,
    };
    LicenseUpdate?.handleTrigger(RAW_DATA);
  };

  return (
    <div>
      <div className="add-to-board-container-header-wrapper">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => props?.handleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <h1 className="choose-license-header-text">{LICENSE?.TITLE}</h1>
        <img
          src={require("../../../../../../assets/images/license_price.svg").default}
          className="license-modal-image"
          alt="license_price"
        />
        <h3 className="choose-license-sub-header-text">{LICENSE?.SUBTITILE}</h3>
        <h3 className="choose-license-sub-header-text m-b-xs">{LICENSE?.SUBTITLE_LINE2}</h3>
        <h3 className="choose-license-sub-header-text"> {LICENSE?.CHOOSE_USERS}</h3>
      </div>

      <div className="flex align-items-center p-mini">
        <FeatherIcon
          icon={"minus"}
          size={25}
          color={"#33343a"}
          className={"m-r-mini"}
          role={"button"}
          onClick={() => setLicenseCount((prev) => (prev > 1 ? prev - 1 : 1))}
        />
        <input
          value={licenseCount}
          onChange={(e) => setLicenseCount(parseInt(e.target.value ? e.target.value : 1))}
          className={`input-field-license`}
          autoComplete="off"
          type={"tel"}
        />
        <FeatherIcon
          icon={"plus"}
          size={25}
          color={"#33343a"}
          className={"m-l-mini"}
          role={"button"}
          onClick={() => setLicenseCount((prev) => prev + 1)}
        />
      </div>
      <p className="license-pricing-text">
        {LICENSE?.PRICING_TEXT} {licenseCount + 1} {LICENSE?.PRICING_TEXT_END}
        <span className="license-pricing-amount">USD $ {(licenseCount + 1) * 99 * 12}</span>
      </p>

      <div className="flex justify-flex-end">
        {/* {loading ? (
          <div style={{ minWidth: "70px" }}>
            <RoundSpinner />
          </div>
        ) : (
          <div
            className="request-category-submit-button"
            onClick={() => handleSubmit()}
            style={{ minWidth: "70px", textAlign: "center" }}
          >
            Submit
          </div>
        )} */}
        <div
          className="request-category-submit-button m-l-mini"
          onClick={() => props?.handleListCount(licenseCount)}
          style={{ minWidth: "70px", textAlign: "center" }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default LicensePlan;
