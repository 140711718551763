import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import * as SECRET_KEYS from "../../config/configKeys";
import SimilarFaq from "./similarFaq";
const FaqAside = (props) => {
  const Data = props?.data;

  return (
    <div className="business-aside-container">
      <aside>
        <div className="m-b-mini" role="button">
          <h3 className="overview-title">More FAQ's</h3>
          {Data?.length ? (
            <div class="dtrd-faq dtrd-faq--static">
              {Data?.map((item) => {
                return <SimilarFaq data={item} />;
              })}
            </div>
          ) : (
            <p class="dtrd-faq__question_empty-faq">{`No new FAQ's`}</p>
          )}
        </div>
      </aside>
    </div>
  );
};

export default FaqAside;
