import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { productDetails } from "../../../../contexts/reducer/homeReducer";
// import * as KEYS from "../../../../config/config";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import ProductProfileSkeleton from "../skeleton/productProfile";
import * as SECRET_KEYS from "../../../../config/configKeys";
function ProductProfile(props) {
  const PRODUCT_ID = props?.productId;
  const signalTrackingVendorObject = {
    action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: "vendor page",
    section: props?.section,
  };
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const dispatch = useDispatch();
  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getVendorAndProductPssDetails?productName=Planning
    // https://thebox-dev.portqii.com/search_backend/getProductDetailsForProductPage?productId=7087
    `/getProductDetailsForProductPage?productId=${PRODUCT_ID?.Id}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const productProfileDetails = GetApiData?.data?.data;
  dispatch(productDetails(productProfileDetails));
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.action,
      Item_Type: 'Vendor',
      Item_Id: val?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}`,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: val?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  return (
    <div className="product-hero">
      {GetApiData?.isLoading || GetApiData?.isFetching ? (
        <ProductProfileSkeleton />
      ) : GetApiData?.isError ? null : productProfileDetails?.Product_Name ? (
        <div className="container">
          <div>
            <div class="product-hero__header">
              <div class="product-hero__header-image">
                <img
                  alt={PRODUCT_ID?.Name ? `Image ${PRODUCT_ID?.Name}` : "Product name"}
                  class="lazyloaded"
                  data-src={
                    productProfileDetails?.Account_Logo ? productProfileDetails?.Account_Logo : KEYS?.CORRUPT_IMAGE_ICON
                  }
                  src={
                    productProfileDetails?.Account_Logo ? productProfileDetails?.Account_Logo : KEYS?.CORRUPT_IMAGE_ICON
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                  }}
                />
              </div>

              <div class="product-hero__header-title">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h1 class="product-hero__header-title-name">
                    {`${productProfileDetails?.Product_Name} - ${productProfileDetails?.Product_Catagories?.slice(
                      0,
                      1
                    )?.map((item) => {
                      return item?.Product_Category_Name;
                    })}`}
                  </h1>{" "}
                  {/* {productProfileDetails?.Product_Catagories?.slice(0, 1)?.map(
                    (item) => (
                      <h1 class="product-hero__header-title-name">
                        {"- "}
                        {item?.Product_Category_Name}
                      </h1>
                    )
                  )} */}
                  <span
                    className="provider-verified-badge bubble-step__title vendor-info"
                    data-tooltip={`${KEYS?.VENDOR_PROFILE?.INFO} ${productProfileDetails?.Vendor_Name}`}
                  >
                    <img
                      class=" ls-is-cached lazyloaded"
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "0px 0px 20px 10px",
                      }}
                      data-src={require("../../../../assets/images/infoIcon.svg").default}
                      src={require("../../../../assets/images/infoIcon.svg").default}
                      alt={"info icon"}
                    />
                  </span>
                </div>

                <div class="product-hero__header-title-provider">
                  {productProfileDetails ? (
                    <div
                      onClick={() => {
                        handleVendorNameLinkClicked(productProfileDetails);
                      }}
                    >
                      <a
                        class="provider"
                        href={`/vendor/${ReplaceBlankSpaceByHyphen(
                          productProfileDetails?.Vendor_Name,
                          productProfileDetails?.Vendor_ID
                        )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
                      >
                        {productProfileDetails?.Vendor_Name}
                      </a>
                    </div>
                  ) : null}
                  <div class="provider__trust-subheader">
                    {/* {productProfileDetails?.Verified ? (
                      <span class="provider-verified-badge" data-tooltip="Verified">
                        <img
                          class="provider-verified-badge__icon lazyloaded"
                          alt="Badge icon"
                          data-src={require("../../../../assets/images/shield-verified.svg").default}
                          src={require("../../../../assets/images/shield-verified.svg").default}
                        />
                        { Verified Data Provider }
                      </span>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProductProfile;
