import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ToTitleCase } from "../../../../../utlis/hof/hof";
import { useState } from "react";
import SignInModal from "../../../../../utlis/hoc/signInModal/signInModal";

const CategoryDetails = () => {
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const navigate = useNavigate();
  const category = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const GetApiData = useEnCryptGetApi(
    `/getProductCategoryDescription?id=${category?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const categoryData = GetApiData?.data?.data;

  const handleManageProposal = () => {
    authData?.data?.userExist ? navigate("/proposals") : setSignInModalVisible((prev) => !prev);
  };
  return (
    <div className="grid-3-1 tablet-1-col">
      {/* <div className="flex"> */}
      {/* <img
          class=" ls-is-cached lazyloaded"
          // data-src={categoryData?.Vendor_Logo_URL}
          // src={categoryData?.Vendor_Logo_URL}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
          alt="Vendor thumbnail "
        /> */}
      <div className="flex-list space-evenly m-l-base">
        <h1 className="landing-page__title">{ToTitleCase(category?.Name)}</h1>
        <p
          className=" landing-page__description m-b-xs"
          // className="vendor-onboarding-verified-text"
        >
          {categoryData?.Product_Category_Description}
        </p>
      </div>
      {/* </div> */}
      {/* <div className="business-profile-button-container mobile-hidden">
        <div className="business-profile-header-button" role={"button"} onClick={() => handleManageProposal()}>
          Manage Proposal
        </div>
      </div> */}
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible(false)} /> : null}
    </div>
  );
};

export default CategoryDetails;
