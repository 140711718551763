module.exports = `<!DOCTYPE html>
  <head>
    <title></title>
    <meta content="summary_large_image" name="twitter:card" />
    <meta content="website" property="og:type" />
    <meta content="" property="og:description" />
    <meta content="https://gsniradtzr.preview-postedstuff.com/V2-X3L5-3z2S-2b1V-sMZD/" property="og:url" />
    <meta content="https://pro-bee-beepro-thumbnail.getbee.io/messages/736995/720126/1603960/8158165_large.jpg"
      property="og:image" />
    <meta content="" property="og:title" />
    <meta content="" name="description" />
    <meta charset="utf-8" />
    <meta content="width=device-width" name="viewport" />
    <link rel="icon" type="image/x-icon/ico" href="./assets/images/OctateIcon.ico" sizes="100x100" />
    <link src="../src/styles/user-defined.css" rel="stylesheet" type="text/css"/>
    <link src="../src/styles/application-octate.css" rel="stylesheet" type="text/css"/>


    <style>
      @import url("../src/styles/application-octate.css");
      @import url("../src/styles/user-defined.css");
      .bee-row,
      .bee-row-content {
        position: relative;
      }

      .bee-html-block {
        text-align: center;
      }

      .bee-row-1,
      .bee-row-1 .bee-row-content,
      .bee-row-2,
      .bee-row-3 {
        background-repeat: no-repeat;
      }

      body {
        background-color: transparent;
        color: #000;
        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
      }

      a {
        color: #8a3c90;
      }

      * {
        box-sizing: border-box;
      }

      body,
      h1,
      h2,
      h3,
      p {
        margin: 0;
      }

      .bee-row-content {
        max-width: 710px;
        margin: 0 auto;
        display: flex;
      }

      .bee-row-content.reverse,
      .bee-row-content.reverse .bee-col {
        -moz-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        transform: scale(1, -1);
      }

      .bee-row-content .bee-col-w12 {
        flex-basis: 100%;
      }

      .bee-image {
        overflow: auto;
      }

      .bee-image .bee-center {
        margin: 0 auto;
      }

      .bee-row-3 .bee-col-1 .bee-block-1 {
        width: 100%;
      }

      .bee-image img {
        display: block;
        width: 100%;
      }

      @media (max-width: 730px) {
        .bee-row-content:not(.no_stack) {
          display: block;
        }
      }

      .bee-row-1 .bee-row-content {
        color: #000;
      }

      .bee-row-1 .bee-col-1,
      .bee-row-2 .bee-col-1,
      .bee-row-3 .bee-col-1 {
        padding-bottom: 5px;
        padding-top: 5px;
      }

      .bee-row-1 .bee-col-1 .bee-block-1 {
        text-align: center;
        width: 100%;
      }

      .bee-row-2 .bee-row-content,
      .bee-row-3 .bee-row-content {
        background-repeat: no-repeat;
        border-radius: 0;
        color: #000;
      }
    </style>
  </head>
  <div class="page-content page-content--reset" >
    <div class="splitted-page">
      <div class="splitted-page__aside" style="position: relative; height: 100vh;">

        <div class="signUp-vertical-line"></div>
        <div class="splitted-page__container signup-split-container">
          <div></div>
          <div class="signUp-image-container">
            <img class="octateImage" src=${"https://octate.ai/images/octate_logo/octateLogo.svg"} alt="Octate logo" style="width: 70px" />
            <p class="subText-signup">World's largest platform of curated content</p>
            <p class="subText-signup">for solution discovery.</p>
          </div>
          <div>
            <div class="signUp-footer">
              <p class="subText-signup">Discover Products</p>
            </div>
            <div class="signUp-footer">
              <p class="subText-signup">Follow Topics</p>
            </div>
            <div class="signUp-footer">
              <p class="subText-signup">Hello Curate Boards</p>
            </div>
          </div>
      </div>
      </div>

      <div class="bee-page-container" style="height: 100vh; overflow: scroll; width: 68%;">
        <div class="bee-row bee-row-1">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-heading">
                <h1 style="
                  color: #8a3c90;
                  direction: ltr;
                  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                  font-size: 38px;
                  font-weight: 700;
                  letter-spacing: normal;
                  line-height: 120%;
                  text-align: center;
                  margin-top: 0;
                  margin-bottom: 0;
                ">
                  <span class="tinyMce-placeholder">Terms of Service</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-2">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-spacer">
                <div class="spacer" style="height: 20px"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-3">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-image">
                <img alt="" class="bee-center bee-autowidth"
                  src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/736995_720126/octateai_v2.0.svg"
                  style="max-width: 250px" />
              </div>
              <div class="bee-block bee-block-2 bee-spacer">
                <div class="spacer" style="height: 25px"></div>
              </div>
              <div class="bee-block bee-block-3 bee-html-block">
                <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
                <meta content="Word.Document" name="ProgId" />
                <meta content="Microsoft Word 15" name="Generator" />
                <meta content="Microsoft Word 15" name="Originator" />
                <link href="Octate_T&amp;C_Ver1.0_files/filelist.xml" rel="File-List" />
                <link href="Octate_T&amp;C_Ver1.0_files/item0001.xml" rel="dataStoreItem"
                  target="Octate_T&amp;C_Ver1.0_files/props002.xml" />
                <link href="Octate_T&amp;C_Ver1.0_files/themedata.thmx" rel="themeData" />
                <link href="Octate_T&amp;C_Ver1.0_files/colorschememapping.xml" rel="colorSchemeMapping" />
                <style>
                  <!--
                  /* Font Definitions */
                  @font-face {
                    font-family: "Cambria Math";
                    panose-1: 2 4 5 3 5 4 6 3 2 4;
                    mso-font-charset: 0;
                    mso-generic-font-family: roman;
                    mso-font-pitch: variable;
                    mso-font-signature: 3 0 0 0 1 0;
                  }

                  @font-face {
                    font-family: Calibri;
                    panose-1: 2 15 5 2 2 2 4 3 2 4;
                    mso-font-charset: 0;
                    mso-generic-font-family: swiss;
                    mso-font-pitch: variable;
                    mso-font-signature: -469750017 -1073732485 9 0 511 0;
                  }

                  @font-face {
                    font-family: Verdana;
                    panose-1: 2 11 6 4 3 5 4 4 2 4;
                    mso-font-charset: 0;
                    mso-generic-font-family: swiss;
                    mso-font-pitch: variable;
                    mso-font-signature: -1610610945 1073750107 16 0 415 0;
                  }

                  /* Style Definitions */
                  p.MsoNormal,
                  li.MsoNormal,
                  div.MsoNormal {
                    mso-style-unhide: no;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    text-align: justify;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    mso-ansi-language: EN-IN;
                  }

                  h1 {
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    mso-style-link: "Heading 1 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    text-align: justify;
                    text-indent: -35.45pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan lines-together;
                    page-break-after: avoid;
                    mso-outline-level: 1;
                    mso-list: l0 level1 lfo1;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 14.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    text-transform: uppercase;
                    mso-font-kerning: 0pt;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    mso-bidi-font-weight: normal;
                  }

                  h2 {
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    mso-style-link: "Heading 2 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    text-align: justify;
                    text-indent: -35.45pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    mso-outline-level: 2;
                    mso-list: l1 level2 lfo4;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 11.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-fareast-language: EN-IN;
                    font-weight: normal;
                  }

                  h3 {
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    mso-style-link: "Heading 3 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: .25in;
                    text-align: justify;
                    text-indent: -10.9pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    mso-outline-level: 3;
                    mso-list: l3 level3 lfo6;
                    font-size: 12.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-fareast-language: EN-IN;
                    font-weight: normal;
                    mso-bidi-font-weight: bold;
                  }

                  h4 {
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    mso-style-link: "Heading 4 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    text-align: justify;
                    text-indent: -35.45pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    mso-outline-level: 4;
                    mso-list: l0 level4 lfo1;
                    font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    font-weight: normal;
                    mso-bidi-font-weight: bold;
                  }

                  h5 {
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-parent: "";
                    mso-style-link: "Heading 5 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 35.45pt;
                    text-align: justify;
                    text-indent: -70.9pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    mso-outline-level: 5;
                    mso-list: l0 level5 lfo1;
                    font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    font-weight: normal;
                  }

                  h6 {
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-link: "Heading 6 Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 35.45pt;
                    text-align: justify;
                    text-indent: -70.9pt;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    mso-outline-level: 6;
                    mso-list: l0 level6 lfo1;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    font-weight: normal;
                  }

                  p.MsoHeading7,
                  li.MsoHeading7,
                  div.MsoHeading7 {
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-link: "Heading 7 Char";
                    mso-style-next: Normal;
                    margin-top: 2.0pt;
                    margin-right: 0in;
                    margin-bottom: 0in;
                    margin-left: .9in;
                    text-align: justify;
                    text-indent: -.9in;
                    line-height: 150%;
                    mso-pagination: widow-orphan lines-together;
                    page-break-after: avoid;
                    mso-outline-level: 7;
                    mso-list: l0 level7 lfo1;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #1F3763;
                    mso-themecolor: accent1;
                    mso-themeshade: 127;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    font-style: italic;
                  }

                  p.MsoHeading8,
                  li.MsoHeading8,
                  div.MsoHeading8 {
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-link: "Heading 8 Char";
                    mso-style-next: Normal;
                    margin-top: 2.0pt;
                    margin-right: 0in;
                    margin-bottom: 0in;
                    margin-left: 1.0in;
                    text-align: justify;
                    text-indent: -1.0in;
                    line-height: 150%;
                    mso-pagination: widow-orphan lines-together;
                    page-break-after: avoid;
                    mso-outline-level: 8;
                    mso-list: l0 level8 lfo1;
                    font-size: 10.5pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #272727;
                    mso-themecolor: text1;
                    mso-themetint: 216;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                  }

                  p.MsoHeading9,
                  li.MsoHeading9,
                  div.MsoHeading9 {
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-qformat: yes;
                    mso-style-link: "Heading 9 Char";
                    mso-style-next: Normal;
                    margin-top: 2.0pt;
                    margin-right: 0in;
                    margin-bottom: 0in;
                    margin-left: 1.1in;
                    text-align: justify;
                    text-indent: -1.1in;
                    line-height: 150%;
                    mso-pagination: widow-orphan lines-together;
                    page-break-after: avoid;
                    mso-outline-level: 9;
                    mso-list: l0 level9 lfo1;
                    font-size: 10.5pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #272727;
                    mso-themecolor: text1;
                    mso-themetint: 216;
                    mso-ansi-language: EN-IN;
                    mso-fareast-language: EN-IN;
                    font-style: italic;
                  }

                  p.MsoToc1,
                  li.MsoToc1,
                  div.MsoToc1 {
                    mso-style-update: auto;
                    mso-style-priority: 39;
                    mso-style-next: Normal;
                    margin-top: 6.0pt;
                    margin-right: 0in;
                    margin-bottom: 0in;
                    margin-left: 0in;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    font-size: 12.0pt;
                    font-family: "Calibri", sans-serif;
                    mso-ascii-font-family: Calibri;
                    mso-ascii-theme-font: minor-latin;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-hansi-font-family: Calibri;
                    mso-hansi-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    font-weight: bold;
                    font-style: italic;
                  }

                  p.MsoHeader,
                  li.MsoHeader,
                  div.MsoHeader {
                    mso-style-priority: 99;
                    mso-style-link: "Header Char";
                    margin: 0in;
                    text-align: justify;
                    mso-pagination: widow-orphan;
                    tab-stops: center 225.65pt right 451.3pt;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    mso-ansi-language: EN-IN;
                  }

                  p.MsoFooter,
                  li.MsoFooter,
                  div.MsoFooter {
                    mso-style-priority: 99;
                    mso-style-link: "Footer Char";
                    margin: 0in;
                    text-align: justify;
                    mso-pagination: widow-orphan;
                    tab-stops: center 225.65pt right 451.3pt;
                    font-size: 12.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    mso-ansi-language: EN-IN;
                  }

                  p.MsoTitle,
                  li.MsoTitle,
                  div.MsoTitle {
                    mso-style-priority: 10;
                    mso-style-unhide: no;
                    mso-style-qformat: yes;
                    mso-style-link: "Title Char";
                    mso-style-next: Normal;
                    margin-top: 0in;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    text-align: center;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    font-size: 16.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    text-transform: uppercase;
                    letter-spacing: 1.0pt;
                    mso-ansi-language: EN-IN;
                    font-weight: bold;
                  }

                  a:link,
                  span.MsoHyperlink {
                    mso-style-priority: 99;
                    color: #0563C1;
                    mso-themecolor: hyperlink;
                    text-decoration: underline;
                    text-underline: single;
                  }

                  a:visited,
                  span.MsoHyperlinkFollowed {
                    mso-style-noshow: yes;
                    mso-style-priority: 99;
                    color: #954F72;
                    mso-themecolor: followedhyperlink;
                    text-decoration: underline;
                    text-underline: single;
                  }

                  span.Heading1Char {
                    mso-style-name: "Heading 1 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 1";
                    mso-ansi-font-size: 12.0pt;
                    mso-bidi-font-size: 14.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    text-transform: uppercase;
                    mso-fareast-language: EN-IN;
                    font-weight: bold;
                    mso-bidi-font-weight: normal;
                  }

                  span.Heading2Char {
                    mso-style-name: "Heading 2 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 2";
                    mso-ansi-font-size: 12.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-ansi-language: EN-US;
                    mso-fareast-language: EN-IN;
                  }

                  span.Heading3Char {
                    mso-style-name: "Heading 3 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 3";
                    mso-ansi-font-size: 12.0pt;
                    mso-bidi-font-size: 12.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-ansi-language: EN-US;
                    mso-fareast-language: EN-IN;
                    mso-bidi-font-weight: bold;
                  }

                  span.Heading4Char {
                    mso-style-name: "Heading 4 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 4";
                    mso-ansi-font-size: 10.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-fareast-language: EN-IN;
                    mso-bidi-font-weight: bold;
                  }

                  span.Heading5Char {
                    mso-style-name: "Heading 5 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 5";
                    mso-ansi-font-size: 10.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    color: black;
                    mso-fareast-language: EN-IN;
                  }

                  span.Heading6Char {
                    mso-style-name: "Heading 6 Char";
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 6";
                    mso-ansi-font-size: 10.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: Verdana;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    mso-fareast-language: EN-IN;
                  }

                  span.Heading7Char {
                    mso-style-name: "Heading 7 Char";
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 7";
                    mso-ansi-font-size: 10.0pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #1F3763;
                    mso-themecolor: accent1;
                    mso-themeshade: 127;
                    mso-fareast-language: EN-IN;
                    font-style: italic;
                  }

                  span.Heading8Char {
                    mso-style-name: "Heading 8 Char";
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 8";
                    mso-ansi-font-size: 10.5pt;
                    mso-bidi-font-size: 10.5pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #272727;
                    mso-themecolor: text1;
                    mso-themetint: 216;
                    mso-fareast-language: EN-IN;
                  }

                  span.Heading9Char {
                    mso-style-name: "Heading 9 Char";
                    mso-style-noshow: yes;
                    mso-style-priority: 9;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: "Heading 9";
                    mso-ansi-font-size: 10.5pt;
                    mso-bidi-font-size: 10.5pt;
                    font-family: "Calibri Light", sans-serif;
                    mso-ascii-font-family: "Calibri Light";
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: "Times New Roman";
                    mso-fareast-theme-font: major-fareast;
                    mso-hansi-font-family: "Calibri Light";
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: major-bidi;
                    color: #272727;
                    mso-themecolor: text1;
                    mso-themetint: 216;
                    mso-fareast-language: EN-IN;
                    font-style: italic;
                  }

                  span.TitleChar {
                    mso-style-name: "Title Char";
                    mso-style-priority: 10;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: Title;
                    mso-ansi-font-size: 16.0pt;
                    mso-bidi-font-size: 16.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-hansi-font-family: Verdana;
                    text-transform: uppercase;
                    letter-spacing: 1.0pt;
                    font-weight: bold;
                  }

                  span.HeaderChar {
                    mso-style-name: "Header Char";
                    mso-style-priority: 99;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: Header;
                    mso-ansi-font-size: 10.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-hansi-font-family: Verdana;
                  }

                  span.FooterChar {
                    mso-style-name: "Footer Char";
                    mso-style-priority: 99;
                    mso-style-unhide: no;
                    mso-style-locked: yes;
                    mso-style-link: Footer;
                    mso-ansi-font-size: 10.0pt;
                    mso-bidi-font-size: 10.0pt;
                    font-family: "Verdana", sans-serif;
                    mso-ascii-font-family: Verdana;
                    mso-hansi-font-family: Verdana;
                  }

                  .MsoChpDefault {
                    mso-style-type: export-only;
                    mso-default-props: yes;
                    font-family: "Calibri", sans-serif;
                    mso-ascii-font-family: Calibri;
                    mso-ascii-theme-font: minor-latin;
                    mso-fareast-font-family: Calibri;
                    mso-fareast-theme-font: minor-latin;
                    mso-hansi-font-family: Calibri;
                    mso-hansi-theme-font: minor-latin;
                    mso-bidi-font-family: "Times New Roman";
                    mso-bidi-theme-font: minor-bidi;
                    mso-ansi-language: EN-IN;
                  }

                  .MsoPapDefault {
                    mso-style-type: export-only;
                    margin-top: 6.0pt;
                    margin-right: 0in;
                    margin-bottom: 6.0pt;
                    margin-left: 0in;
                    line-height: 150%;
                  }

                  /* Page Definitions */
                  @page {
                    mso-footnote-separator: url("Octate_T&C_Ver1.0_files/header.htm") fs;
                    mso-footnote-continuation-separator: url("Octate_T&C_Ver1.0_files/header.htm") fcs;
                    mso-endnote-separator: url("Octate_T&C_Ver1.0_files/header.htm") es;
                    mso-endnote-continuation-separator: url("Octate_T&C_Ver1.0_files/header.htm") ecs;
                  }

                  @page WordSection1 {
                    size: 595.3pt 841.9pt;
                    margin: 1.0in 1.0in 1.0in 1.0in;
                    mso-header-margin: 35.4pt;
                    mso-footer-margin: 35.4pt;
                    border: solid windowtext 1.5pt;
                    padding: 24.0pt 24.0pt 24.0pt 24.0pt;
                    mso-footer: url("Octate_T&C_Ver1.0_files/header.htm") f1;
                    mso-paper-source: 0;
                  }

                  div.WordSection1 {
                    page: WordSection1;
                  }

                  /* List Definitions */
                  @list l0 {
                    mso-list-id: 246500624;
                    mso-list-template-ids: -1867882508;
                  }

                  @list l0:level1 {
                    mso-level-style-link: "Heading 1";
                    mso-level-text: %1;
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .3in;
                    text-indent: -.3in;
                  }

                  @list l0:level2 {
                    mso-level-text: "%1\.%2";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .4in;
                    text-indent: -.4in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: normal;
                  }

                  @list l0:level3 {
                    mso-level-text: "%1\.%2\.%3";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .5in;
                    text-indent: -.5in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: normal;
                  }

                  @list l0:level4 {
                    mso-level-style-link: "Heading 4";
                    mso-level-text: "%1\.%2\.%3\.%4";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .6in;
                    text-indent: -.6in;
                  }

                  @list l0:level5 {
                    mso-level-style-link: "Heading 5";
                    mso-level-text: "%1\.%2\.%3\.%4\.%5";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .7in;
                    text-indent: -.7in;
                  }

                  @list l0:level6 {
                    mso-level-style-link: "Heading 6";
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .8in;
                    text-indent: -.8in;
                  }

                  @list l0:level7 {
                    mso-level-style-link: "Heading 7";
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .9in;
                    text-indent: -.9in;
                  }

                  @list l0:level8 {
                    mso-level-style-link: "Heading 8";
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.0in;
                    text-indent: -1.0in;
                  }

                  @list l0:level9 {
                    mso-level-style-link: "Heading 9";
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.1in;
                    text-indent: -1.1in;
                  }

                  @list l1 {
                    mso-list-id: 1746341531;
                    mso-list-template-ids: -1102405574;
                  }

                  @list l1:level1 {
                    mso-level-text: %1;
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .3in;
                    text-indent: -.3in;
                  }

                  @list l1:level2 {
                    mso-level-number-format: alpha-lower;
                    mso-level-style-link: "Heading 2";
                    mso-level-text: "%2\)";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .25in;
                    text-indent: -.25in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: bold;
                  }

                  @list l1:level3 {
                    mso-level-text: "%1\.%2\.%3";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .5in;
                    text-indent: -.5in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: normal;
                  }

                  @list l1:level4 {
                    mso-level-text: "%1\.%2\.%3\.%4";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .6in;
                    text-indent: -.6in;
                  }

                  @list l1:level5 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .7in;
                    text-indent: -.7in;
                  }

                  @list l1:level6 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .8in;
                    text-indent: -.8in;
                  }

                  @list l1:level7 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .9in;
                    text-indent: -.9in;
                  }

                  @list l1:level8 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.0in;
                    text-indent: -1.0in;
                  }

                  @list l1:level9 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.1in;
                    text-indent: -1.1in;
                  }

                  @list l2 {
                    mso-list-id: 1876698267;
                    mso-list-template-ids: 1242616356;
                  }

                  @list l2:level1 {
                    mso-level-text: %1;
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .3in;
                    text-indent: -.3in;
                  }

                  @list l2:level2 {
                    mso-level-text: "%1\.%2";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .4in;
                    text-indent: -.4in;
                  }

                  @list l2:level3 {
                    mso-level-text: "%1\.%2\.%3";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .5in;
                    text-indent: -.5in;
                  }

                  @list l2:level4 {
                    mso-level-text: "%1\.%2\.%3\.%4";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .6in;
                    text-indent: -.6in;
                  }

                  @list l2:level5 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .7in;
                    text-indent: -.7in;
                  }

                  @list l2:level6 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .8in;
                    text-indent: -.8in;
                  }

                  @list l2:level7 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .9in;
                    text-indent: -.9in;
                  }

                  @list l2:level8 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.0in;
                    text-indent: -1.0in;
                  }

                  @list l2:level9 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.1in;
                    text-indent: -1.1in;
                  }

                  @list l3 {
                    mso-list-id: 1925648284;
                    mso-list-template-ids: -939121300;
                  }

                  @list l3:level1 {
                    mso-level-text: %1;
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .3in;
                    text-indent: -.3in;
                  }

                  @list l3:level2 {
                    mso-level-text: "%1\.%2";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .4in;
                    text-indent: -.4in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: normal;
                  }

                  @list l3:level3 {
                    mso-level-number-format: roman-lower;
                    mso-level-style-link: "Heading 3";
                    mso-level-tab-stop: none;
                    mso-level-number-position: right;
                    margin-left: .25in;
                    text-indent: -.25in;
                    mso-ansi-font-weight: normal;
                    mso-bidi-font-weight: normal;
                  }

                  @list l3:level4 {
                    mso-level-text: "%1\.%2\.%3\.%4";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .6in;
                    text-indent: -.6in;
                  }

                  @list l3:level5 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .7in;
                    text-indent: -.7in;
                  }

                  @list l3:level6 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .8in;
                    text-indent: -.8in;
                  }

                  @list l3:level7 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: .9in;
                    text-indent: -.9in;
                  }

                  @list l3:level8 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.0in;
                    text-indent: -1.0in;
                  }

                  @list l3:level9 {
                    mso-level-text: "%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
                    mso-level-tab-stop: none;
                    mso-level-number-position: left;
                    margin-left: 1.1in;
                    text-indent: -1.1in;
                  }

                  ol {
                    margin-bottom: 0in;
                  }

                  ul {
                    margin-bottom: 0in;
                  }
                  -->
                </style>
                <!--[if gte mso 10]>
                <style>
                  /* Style Definitions */
                  table.MsoNormalTable {
                    mso-style-name: 'Table Normal';
                    mso-tstyle-rowband-size: 0;
                    mso-tstyle-colband-size: 0;
                    mso-style-noshow: yes;
                    mso-style-priority: 99;
                    mso-style-parent: '';
                    mso-padding-alt: 0in 5.4pt 0in 5.4pt;
                    mso-para-margin-top: 6pt;
                    mso-para-margin-right: 0in;
                    mso-para-margin-bottom: 6pt;
                    mso-para-margin-left: 0in;
                    line-height: 150%;
                    mso-pagination: widow-orphan;
                    font-size: 11pt;
                    font-family: 'Calibri', sans-serif;
                    mso-ascii-font-family: Calibri;
                    mso-ascii-theme-font: minor-latin;
                    mso-hansi-font-family: Calibri;
                    mso-hansi-theme-font: minor-latin;
                    mso-bidi-font-family: 'Times New Roman';
                    mso-bidi-theme-font: minor-bidi;
                    mso-ansi-language: EN-IN;
                  }
                </style>
              <![endif]-->
                <!--[if gte mso 9
                ]><xml>
                  <o:shapedefaults v:ext="edit" spidmax="1026" /> </xml
              ><![endif]-->
                <!--[if gte mso 9
                ]><xml>
                  <o:shapelayout v:ext="edit">
                    <o:idmap v:ext="edit" data="1" /> </o:shapelayout></xml
              ><![endif]-->
                <div class="WordSection1">
                  <p class="MsoNormal">
                    <!--[if supportFields
                    ]><b
                      ><span style="mso-ansi-language: EN-US"
                        ><span
                          style="mso-element: field-end"
                        ></span></span></b><!
                  [endif]--><b><span style="mso-ansi-language: EN-US">
                        <o:p> </o:p>
                      </span></b>
                  </p>
                  <b><span style="
                      font-size: 12pt;
                      mso-bidi-font-size: 10pt;
                      line-height: 150%;
                      font-family: 'Verdana', sans-serif;
                      mso-fareast-font-family: Calibri;
                      mso-fareast-theme-font: minor-latin;
                      mso-bidi-font-family: 'Times New Roman';
                      mso-bidi-theme-font: minor-bidi;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-US;
                      mso-bidi-language: AR-SA;
                    "><br clear="all" style="
                        mso-special-character: line-break;
                        page-break-before: always;
                      " /> </span></b>
                  <p align="left" class="MsoNormal" style="margin-top: 6pt; text-align: left">
                    <b><span style="mso-ansi-language: EN-US">
                        <o:p> </o:p>
                      </span></b>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457278">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">1<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="text-transform: none; mso-ansi-language: EN-US">INTRODUCTION</span> </a><span
                      style="mso-bookmark: _Toc113457278"></span><span style="mso-ansi-language: EN-US">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">Welcome to Octate.ai; we are based in the state of
                      Delaware. These Terms of Service regulate the use and access
                      of </span><span lang="EN-IN"><a href="https://www.octate.ai/"><span lang="EN-US" style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                          mso-fareast-language: EN-IN;
                        ">https://www.octate.ai/</span></a></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">
                      media form, media channel, mobile website, and mobile
                      applications related, linked, or otherwise connected thereto
                      (collectively the “Platform”).</span><span style="mso-ansi-language: EN-US"> </span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">For the sake of convenience, the term “Services” shall be
                      included in the term “Platform” wherever used throughout
                      this document unless specified otherwise. Octate.ai is a
                      social media platform comprising various functionalities as
                      specified herein and displayed on the Platform.<o:p> </o:p></span>
                  </p>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">These Terms of Service, our
                      <span style="color: #4472c4; mso-themecolor: accent1">Privacy Policy</span>,
                      <span style="color: #4472c4; mso-themecolor: accent1">Community Guidelines</span>,
                      <span style="color: #4472c4; mso-themecolor: accent1">Cookie Policy,</span>
                      and other additional terms we make available on the Platform
                      from time to time (collectively “Terms”) set out the legal
                      obligation vis-à-vis terms and conditions for your
                      interaction and usage of the Platform. This document
                      includes a mandatory arbitration provision and regulates the
                      resolution of any dispute which may arise between you and us
                      as a result of using our Platform. By clicking “Accept” when
                      prompted or making an Account or using or accessing the
                      Platform, you understand that you will adhere to these Terms
                      and all other operating rules, policies, and procedures that
                      may be issued periodically on the Platform by us, each of
                      which is incorporated by reference periodically by us. If
                      you do not agree to any of these Terms, including the
                      compulsory arbitration provision, you must stop using the
                      Platform.<o:p></o:p></span>
                  </p>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">By accepting these Terms, you agree that the Terms
                      constitute a binding contract, effective as of the date of
                      first acceptance by you, between Octate Corp (hereinafter
                      referred to as “Octate”, “we”, “us”, or “our”) and you the
                      user (hereinafter referred to as (“you”, “your”) (each a
                      “Party” to the contract and collectively, the
                      “Parties”).<o:p></o:p>
                    </span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo1">
                    <a name="_Toc113457279">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">2<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="mso-ansi-language: EN-US">Definitions</span> </a><span
                      style="mso-ansi-language: EN-US">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Terms of Service</b>” refers to this
                    document
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Term</b>” refers to the duration for which
                    these Terms are applicable to you as specified in Section 6 of
                    these Terms of Service.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                        </span></span></span>
                    “<b>Buyer</b>” refers to the users who may
                    access the Platform after registering for Octate.ai services
                    or without registration as visitors with limited
                    functionalities.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Business Users</b>” refers to the paid
                    users of the Platform who wish to, including without limits,
                    create content, display such content for Buyers on the
                    Platform, review their own content, flag content as inactive,
                    make direct edits to content indexed from own website, create
                    new content, engage with Buyers or as representatives of a
                    company, subject to service limits as may be specified in the
                    Terms or the Platform.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Services</b>” means the services as
                    specified herein in this Terms of Service in Section 3, the
                    Terms and on the Platform.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">f)<span style="font: 7pt 'Times New Roman'">           
                        </span></span></span>
                    “<b>Blurbs</b>” means excerpts from content,
                    blogs, articles, etc., available on the Internet. Blurbs are
                    displayed to you on the Platform and may contain links
                    redirecting you to third-party websites.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">g)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Personal Data</b>” means the data of the
                    Buyers and Business Users, which is utilized and regulated in
                    accordance with our Privacy Policy and other documents that we
                    make available from time to time defined in Section 5.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">h)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    “<b>Account</b>” means the online Platform
                    accounts of the Buyers and Business users as defined in
                    Section 7.
                  </h2>
                  <p class="MsoNormal">
                    <span style="
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">BESIDES THE DEFINITIONS CONTAINED IN THIS SECTION, THESE
                      TERMS OF SERVICE CONTAIN DEFINITIONS THROUGHOUT THE
                      DOCUMENT.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457280">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">3<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">THE SERVICE</span> </a><span style="mso-bookmark: _Toc113457280"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">The Platform is an online place where users may browse,
                      create, interact, and otherwise access other functionalities
                      of the Platform in accordance with the Terms. You, as the
                      users of this Platform, whether you are a Buyer or a
                      Business User, shall strictly adhere to the Terms.
                      <o:p></o:p>
                    </span>
                  </p>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">The Platform shows you Blurbs, personalized ads, offers,
                      and other sponsored or commercial content to help you
                      discover content, products, and services that are offered by
                      many businesses and organizations (“Service(s)”).<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457281">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">4<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">ELIGIBILITY</span> </a><span style="mso-bookmark: _Toc113457281"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo5">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    To use our Platform, you must:
                  </h2>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">i.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    Be at least of age the age of majority and not
                    disqualified from entering into contracts under any law;
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">ii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    complete the registration process;
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">iii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    agree to our Terms; and
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">iv.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    provide true, complete, and up-to-date legal
                    and contact information
                    <b>
                      <o:p></o:p>
                    </b>
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <a name="_Hlk71553171">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: normal;
                      "><span style="mso-list: Ignore">v.<span style="font: 7pt 'Times New Roman'">    
                          </span></span></span>
                      You represent and warrant that you have the
                      authority to accept these Terms on behalf of the company you
                      may be affiliated with.<b>
                        <o:p></o:p>
                      </b>
                    </a>
                  </h3>
                  <span style="mso-bookmark: _Hlk71553171"></span>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">vi.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    By using Platform, you represent and warrant
                    that you will use Platform only for non-commercial purposes.<b>
                      <o:p></o:p>
                    </b>
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">vii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    By using Platform, you represent and warrant
                    that you meet all the requirements listed above and that you
                    won’t use Platform in a way that violates any laws or
                    regulations. Octate may refuse Service, close Accounts of any
                    users, and change eligibility requirements at any time.<b>
                      <o:p></o:p>
                    </b>
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">viii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    By accepting these terms, you represent and
                    warrant that you are qualified concerning the conditions
                    stated herein, therefore, are permitted to use the Platform.
                    If you do not meet any of the conditions stated herein, you
                    shall not access/use the Platform and must cease to be a
                    user.<b>
                      <o:p></o:p>
                    </b>
                  </h3>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457282">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">5<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">PERSONAL DATA</span> </a><span style="mso-bookmark: _Toc113457282"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">To provide you with the Platform as mentioned in these
                      Terms, it is required that we collect your basic
                      information, which may include your first name, last name,
                      email address, phone number, country, language, company, job
                      title, job role, profile image (“Personal Data”). You agree
                      that your Personal Data is collected by us through your
                      consent. <o:p> </o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457283">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">6<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">TERM</span> </a><span style="mso-bookmark: _Toc113457283"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                      mso-fareast-language: EN-IN;
                    ">The Term begins as soon as you access the Platform and
                      continues as long as you use the Service. Clicking the
                      button and entering your username means that you’ve
                      officially “signed” these Terms.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457284">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">7<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">ACCOUNT REGISTRATION</span> </a><span style="mso-bookmark: _Toc113457284"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo7">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    To access the Platform, you need to register
                    for a user account on the Platform (“Account”). For continuous
                    access to our Platform, it is suggested that you provide us
                    with accurate, complete, and updated information wherever
                    applicable. Failing to meet the aforesaid condition may result
                    in the suspension of the respective user Account.
                    <b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    You agree not to (1) misrepresent yourself as
                    someone else by selecting or using a username a name, email,
                    or phone number of another person; (2) use, as a username, an
                    offensive, vulgar, or obscene name; (3) use as a username a
                    fictitious name or pseudonym.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                        </span></span></span>
                    You are solely liable and responsible for any
                    activity that occurs on your Account. You agree and understand
                    that you shall not share your user Account password with
                    anybody or do any such act which promotes unauthorized use of
                    your user Account. You shall take all measures to protect your
                    password, including but not limited to restricting the use of
                    your personal device.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    You must notify us immediately on our
                    Platform of any change in your eligibility to use the
                    Platform, breach of security, or unauthorized use of your
                    Account. You shall have the ability to delete your Account,
                    either through the Platform or through a request made on our
                    Platform.
                    <b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <a name="_Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      You understand and agree that by creating a
                      user Account, you agree to receive communication concerning
                      marketing emails and SMS from us. You understand and agree
                      that any communication or notification you receive from us
                      electronically shall qualify as legal notice and meet all
                      the legal notice requirements.
                    </a>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo1">
                    <span style="mso-bookmark: _Hlk71536490"><a name="_Toc24886762"></a><a name="_Toc113457285"><span
                          style="mso-bookmark: _Toc24886762">
                          <span style="
                            mso-fareast-font-family: Verdana;
                            mso-bidi-font-family: Verdana;
                            mso-ansi-language: EN-US;
                          "><span style="mso-list: Ignore">8<span style="font: 7pt 'Times New Roman'">           
                              </span></span></span>
                          <span style="mso-ansi-language: EN-US">Fees, PAYMENTS, and Refunds</span>
                        </span></a></span><span style="mso-bookmark: _Hlk71536490"><span
                        style="mso-ansi-language: EN-US">
                        <o:p></o:p>
                      </span></span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo8">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      Browsing and using the Platform and basic
                      Services is free of cost; however, in order to access
                      Business User features or “Premium Features,” you will have
                      to pay (“Premium Feature Charges”). Premium Features, once
                      purchased, are refundable in accordance with our </span><span
                      style="mso-bookmark: _Hlk71536490"><span style="color: #4472c4; mso-themecolor: accent1">Refund
                        policy</span>,
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      As long as you’re using Premium Features,
                      you’ll provide the Platform with valid credit card
                      information and authorize us to deduct the Premium Features
                      Charges against that credit card. You’ll replace the
                      information for any credit card that expires with
                      information for a different valid credit card. Anyone using
                      a credit card represents and warrants that he or she is
                      authorized to use that credit card and that any and all
                      charges may be billed to that credit card and won’t be
                      rejected. If, for some reason, we’re unable to process your
                      credit card order, we’ll try to charge your payment method three more times
                      in the next few days. Consecutive charge retries do not
                      change and/or extend your Premium Features expiry date.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                          </span></span></span>
                      We may introduce new or cancel existing
                      Premium Features at any time and change pricing from time to
                      time. You will be informed about any of the changes in
                      advance via email and/or on our Platform.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      You are responsible for any taxes imposed on
                      the services provided under these Terms except in cases
                      where legislation requires us to collect the taxes.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      We reserve the right to shut down our
                      Services or Premium Features at any time if we feel that you
                      are abusing our system in any way. If we determine that you
                      have abused the system in any way and we shut down your
                      Account, we do not provide refunds for unused Premium
                      Features.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">f)<span style="font: 7pt 'Times New Roman'">           
                          </span></span></span>
                      Reselling of the Services or Premium
                      Features to third parties is not permitted under any
                      circumstances.
                    </span>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo1">
                    <span style="mso-bookmark: _Hlk71536490"><a name="_Toc24886763"></a><a name="_Toc113457286"><span
                          style="mso-bookmark: _Toc24886763">
                          <span style="
                            mso-fareast-font-family: Verdana;
                            mso-bidi-font-family: Verdana;
                            mso-ansi-language: EN-US;
                          "><span style="mso-list: Ignore">9<span style="font: 7pt 'Times New Roman'">           
                              </span></span></span>
                          <span style="mso-ansi-language: EN-US">Cancellations and Inactivity</span>
                        </span></a></span><span style="mso-bookmark: _Hlk71536490"><span
                        style="mso-ansi-language: EN-US">
                        <o:p></o:p>
                      </span></span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo9">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      We may at any time terminate our agreement
                      with you if (a) you have breached any provision of these
                      Terms (or have acted in a manner that clearly shows you do
                      not intend to or are unable to comply with these Terms); (b)
                      we are required to do so by law (for example, where the
                      provision of the Services to you is, or becomes, unlawful);
                      (c) the provision of the Services to you by us is, in our
                      opinion, no longer commercially viable; or (d) we have
                      elected to discontinue the Services (or any part thereof).
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      Once terminated, we may permanently delete
                      your account and all the data associated with it.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                          </span></span></span>
                      If you do not log in to your account for six
                      or more months, we may treat your account as “inactive” and
                      permanently delete the account and all the data associated
                      with it.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      If the Platform terminates this Agreement
                      because you breached this Agreement or any applicable laws,
                      no refund will be issued even if you have unused, prepaid
                      amounts for Services under this Agreement.
                    </span>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      For all accounts, the Platform may charge an
                      account Reactivation fee should an account need to be
                      Reactivated by a customer after an account has become
                      deactivated due to a breach of this Agreement and/or a long
                      period of inactivity.
                    </span>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo1">
                    <span style="mso-bookmark: _Hlk71536490"><a name="_Toc51951960"></a><a name="_Toc113457287"><span
                          style="mso-bookmark: _Toc51951960">
                          <span style="
                            mso-fareast-font-family: Verdana;
                            mso-bidi-font-family: Verdana;
                            mso-ansi-language: EN-US;
                          "><span style="mso-list: Ignore">10<span style="font: 7pt 'Times New Roman'">       
                              </span></span></span>
                          <span style="mso-ansi-language: EN-US">DMCA Notification.</span>
                        </span></a></span><span style="mso-bookmark: _Hlk71536490"><span
                        style="mso-ansi-language: EN-US">
                        <o:p></o:p>
                      </span></span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="mso-bookmark: _Hlk71536490"><span style="mso-ansi-language: EN-US">If you are a
                        copyright owner or an agent thereof and
                        believe that any content made available via the Platform
                        infringes upon your copyright, you may submit a
                        notification pursuant to the Digital Millennium Copyright
                        Act (“DMCA”) by providing us with the following
                        information in writing: (a) identification of the
                        copyrighted work claimed to have been infringed, or, if
                        multiple copyrighted works are covered by a single
                        notification, a representative list of such works; (b)
                        identification of the claimed infringing material and
                        information reasonably sufficient to permit us to locate
                        the material on the Platform (providing the URL(s) of the
                        claimed infringing material satisfies this requirement);
                        (c) information reasonably sufficient to permit us to
                        contact you, such as an address, telephone number, and, if
                        available, an email address; (d) a statement by you that
                        you have a good faith belief that the disputed use is not
                        authorized by the copyright owner, its agent, or the law;
                        (e) a statement by you, made under penalty of perjury,
                        that the above information in your notification is
                        accurate and that you are the copyright owner or are
                        authorized to act on the copyright owner’s behalf; and (f)
                        your physical or electronic signature. Please send us all
                        notices in connection with copyright infringements to:
                        Octate Corp, 251 Little Falls Drive, Wilmington Delaware.
                        <o:p></o:p>
                      </span></span>
                  </p>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo10">
                    <span style="mso-bookmark: _Hlk71536490">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: bold;
                      "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                          </span></span></span>
                      Counter-Notice.
                    </span>
                  </h2>
                  <p class="MsoNormal">
                    <span style="mso-bookmark: _Hlk71536490"><span style="mso-ansi-language: EN-US">If you feel that any
                        of your Content was improperly
                        removed or made unavailable to other users, please contact
                        us via the contact information set forth above.<o:p></o:p></span></span>
                  </p>
                  <span style="mso-bookmark: _Hlk71536490"></span>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457288">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">11<span style="font: 7pt 'Times New Roman'">       
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">PROHIBITED ACTIVITIES</span> </a><span style="mso-bookmark: _Toc113457288"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo11">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    As a user of the Platform, you agree not to:
                  </h2>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo12;
                  ">
                    <a name="_Hlk71537208">
                      <span style="
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-bidi-font-weight: normal;
                      "><span style="mso-list: Ignore">i.<span style="font: 7pt 'Times New Roman'">    
                          </span></span></span>
                      systematically retrieve data or other
                      Content from the Platform to create or compile, directly or
                      indirectly, a collection, compilation, database, or
                      directory without written permission from us.
                    </a>
                  </h3>
                  <span style="mso-bookmark: _Hlk71537208"></span>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">ii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    make any unauthorized use of the Platform,
                    including collecting usernames and/or email addresses of users
                    by electronic or other means for the purpose of sending
                    unsolicited email or creating user Accounts by automated means
                    or under false pretenses.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">iii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    use a buying agent or purchasing agent to make
                    purchases on the Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">iv.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    use the Platform for advertising or offering
                    to sell goods and services.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">v.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    circumvent, disable, or otherwise interfere
                    with security-related features of the Platform, including
                    features that prevent or restrict the use or copying of any
                    Platform Content or enforce limitations on the use of the
                    Platform and/or the Platform Content or Content contained
                    therein.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">vi.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    engage in unauthorized framing of or linking
                    to the Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">vii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    trick, defraud, or mislead other users of the
                    Platform and us, especially in any attempt to learn sensitive
                    account information such as user passwords;
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">viii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    make improper use of our support services or
                    submit false reports of abuse or misconduct.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">ix.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    engage in any automated use of the system,
                    such as using scripts to send comments or messages or using
                    any data mining, robots, or similar data gathering and
                    extraction tools.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">x.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    interfere with, disrupt, or create an undue
                    burden on the Platform or the networks or services connected
                    to the Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xi.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    attempt to impersonate another user or person
                    or use the username of another user.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    sell or otherwise transfer your profile.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xiii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    use any information obtained from the Platform
                    in order to harass, abuse, or harm another person.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xiv.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    use the Platform as part of any effort to
                    compete with us or otherwise use the Platform and/or the
                    Platform Content for any revenue-generating endeavor or
                    commercial enterprise.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xv.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    attempt to bypass any measures of the Platform
                    designed to prevent or restrict access to the Platform or any
                    portion of the Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xvi.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    harass, annoy, intimidate, or threaten any of
                    our employees or agents engaged in providing any portion of
                    the Platform to you.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xvii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    delete the copyright or other proprietary
                    rights notice from any Platform Content.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xviii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    copy or adapt the Platform’s software,
                    including but not limited to Flash, PHP, HTML, JavaScript, or
                    other code.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xix.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    upload or transmit (or attempt to upload or to
                    transmit) viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any party’s
                    uninterrupted use and enjoyment of the Platform or modifies,
                    impairs, disrupts, alters, or interferes with the use,
                    features, functions, operation, or maintenance of the
                    Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xx.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    upload or transmit (or attempt to upload or to
                    transmit) any material that acts as a passive or active
                    information collection or transmission mechanism, including
                    without limitation, clear graphics interchange formats
                    (“gifs”), 1×1 pixels, web bugs, cookies, or other similar
                    devices (sometimes referred to as “spyware” or “passive
                    collection mechanisms” or “pcms”).
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xxi.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    except as may be the result of a standard
                    search engine or Internet browser usage, use, launch, develop,
                    or distribute any automated system, including without
                    limitation, any spider, robot, cheat utility, scraper, or
                    offline reader that accesses the Platform, or using or
                    launching any unauthorized script or other software.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xxii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    disparage, tarnish, or otherwise harm, in our
                    opinion, us and/or the Platform.
                  </h3>
                  <h3 style="
                    text-indent: -0.25in;
                    mso-text-indent-alt: -10.9pt;
                    mso-list: l3 level3 lfo6;
                  ">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: normal;
                    "><span style="mso-list: Ignore">xxiii.<span style="font: 7pt 'Times New Roman'">    
                        </span></span></span>
                    use the Platform in a manner inconsistent with
                    any applicable laws or regulations.
                  </h3>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457289">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">12<span style="font: 7pt 'Times New Roman'">       
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">LICENSE</span> </a><span style="mso-bookmark: _Toc113457289"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="text-indent: 0in; mso-list: none">
                    Subject to the Terms, Octate gives you a limited, revocable,
                    non-sublicensable, non-exclusive, and non-transferable license
                    to the Platform (and other items displayed on the Platform for
                    download) only for purposes of using the Platform in
                    accordance with these Terms. It is expressly prohibited
                    without prior express permission from Octate to use,
                    reproduce, modify, distribute, or store any Content for
                    purposes other than using the Platform consistent with these
                    Terms.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457290">
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        mso-fareast-font-family: Verdana;
                        mso-bidi-font-family: Verdana;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      "><span style="mso-list: Ignore">13<span style="font: 7pt 'Times New Roman'">       
                          </span></span></span>
                      <span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">INTELLECTUAL PROPERTY RIGHTS</span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      text-transform: none;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo13">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    The Platform contains Intellectual Property of
                    Octate in the form of content, graphics, videos, audio, text,
                    and any other digital content (“Platform Content”). This is an
                    agreement for the use of Platform, and you are not granted a
                    license to any Platform Content under this Terms. Except to
                    the extent that applicable laws prevent us from doing so, you
                    will not, directly or indirectly: (i) reverse engineer,
                    decompile, disassemble, or otherwise attempt to discover the
                    source code, object code, or underlying structure, ideas, or
                    algorithms of, or found at or through the Platform; (ii)
                    remove any proprietary notices or labels from the Platform
                    Content; reproduce or copy the Platform Content or any part
                    thereof; (iii) modify, translate, or create derivative works
                    based on the Platform Content; (iv) copy, distribute, pledge,
                    assign, or otherwise transfer or encumber rights to the
                    Platform Content; (v) create any derivative product from any
                    of the foregoing; (vi) without our express written permission,
                    introduce automated agents or scripts to the Platform so as to
                    produce multiple accounts, generate automated searches,
                    requests and queries, or to strip or mine data from the
                    Platform; or (vii) allow third parties to gain access to the
                    Platform or to Platform Content in any manner other than as
                    expressly permitted in this Terms of Service.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    You acknowledge and agree that the Platform,
                    the names and logos, and all related products and names,
                    design marks and slogans, and all other material comprising
                    the Platform, are the property of the Octate or its affiliates
                    (collectively, the “Marks”). Unless stated otherwise, all
                    Marks are protected as the copyright, trade dress, trademarks,
                    and/or other intellectual properties owned by other parties
                    that have licensed their material to us or by us. You are not
                    authorized to use any of the Marks in any advertising,
                    publicity, or any other commercial manner without the prior
                    written consent of Octate. Your use of the Platform confers no
                    title or ownership in the Platform or the Marks and is not a
                    sale of any rights in the Platform or the Marks. All ownership
                    rights remain in Octate or its third-party suppliers, as the
                    case may be.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                        </span></span></span>
                    You are solely responsible for any content and
                    other material that you submit, publish, transmit, or display
                    on, though, or with our Platform (“Content”). You grant us a
                    non-exclusive, worldwide, royalty-free, and fully paid license
                    to use the Content, as necessary, for purposes of providing
                    the Platform to you and other users of the Platform. All
                    rights in and to the Content not expressly granted to us in
                    these Terms are reserved by you.
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    You acknowledge and agree that any comments,
                    ideas, and/or reports provided to us (“Feedback”) shall be the
                    property of Octate, and you hereby irrevocably transfer and
                    assign to Octate such Feedback and all associated intellectual
                    property rights, provided however that you shall be free to
                    use such Feedback in the ordinary conduct of your business.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    It is our policy to limit access to our
                    Platform of users who infringe the intellectual property
                    rights of others, as a consequence of which we shall terminate
                    your Account. If you find that anything on our Platform
                    infringes any copyright that you own or control, please
                    contact us using the information provided in section 21.
                    <b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248368"></a><a name="_Toc113457291"><span style="mso-bookmark: _Toc36248368">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">14<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">THIRD-PARTY SERVICES</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">The Platform provided by us may permit you to link to other
                      websites, services, or resources on the Internet, and other
                      websites, services, or resources may contain links to the
                      Platform. Also, Content may contain links to other websites,
                      services, or resources on the Internet. When you access
                      third-party resources on the Internet, you shall do so at
                      your own risk. These other resources are not controlled by
                      us, and you agree that we shall not be responsible or liable
                      for, including but not limited to, the content, functions,
                      accuracy, legality, appropriateness, or any other aspect of
                      such websites or resources. The inclusion of any such link
                      shall not imply our endorsement or any association in any
                      way between their operators and us. You also agree that we
                      will not be responsible or liable in any case, either
                      directly or indirectly, for any damage or loss caused or
                      alleged to be caused by or in connection with the use of or
                      reliance on any such content, goods, or services available
                      on or through any such website or resource.<o:p></o:p>
                    </span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248369"></a><a name="_Toc113457292"><span style="mso-bookmark: _Toc36248369">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">15<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">TERMINATION</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">We reserve the right to terminate your access to all or any
                      part of the Platform at any point of time, without providing
                      any cause, with or without notice, effective immediately,
                      which may result in the forfeiture and destruction of all
                      information associated with your membership. You may
                      terminate your
                      <span style="mso-bidi-font-weight: bold">Account</span> if
                      you wish to do so by placing a request on our Platform. Any
                      such termination shall immediately revoke the license
                      granted under Section 9, and you shall effective immediately
                      be prohibited from accessing or using the Platform or
                      Content for any reason. All fees paid hereunder are
                      non-refundable. The provisions of these Terms, which by
                      their nature should survive termination, shall survive
                      termination, including but not limited to Licenses, warranty
                      disclaimers, ownership provisions, limitations of liability,
                      and indemnification.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248370"></a><a name="_Toc113457293"><span style="mso-bookmark: _Toc36248370">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">16<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">RELEASE</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">To the maximum extent permissible by applicable law, you
                      hereby absolutely release Octate and its affiliates as well
                      as all other users of the Platform from responsibilities
                      including but not limited to claims, causes of action,
                      liability, expenses, demands, and/or damages (actual and
                      consequential) of all kinds and nature, known and unknown
                      and claims of negligence that may arise from the use of or
                      inability to use, or in relation to your use of and/or
                      reliance on the Platform, including any disputes which may
                      arise between users and the acts or omissions of third
                      parties.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248371"></a><a name="_Toc113457294"><span style="mso-bookmark: _Toc36248371">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">17<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">WARRANTY DISCLAIMER</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">THE PLATFORM IS PROVIDED “AS IS”, “AS AVAILABLE” BASIS. THE
                      USE OF THE PLATFORM IS AT THE USER’S SOLE RISK. THE PLATFORM
                      IS PROVIDED WITHOUT WARRANTY, REPRESENTATION, OR GUARANTEE
                      OF ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO<span style="color: black; mso-themecolor: text1">,
                      </span></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      font-family: 'Arial', sans-serif;
                      color: black;
                      mso-themecolor: text1;
                      letter-spacing: 0.4pt;
                      background: white;
                      mso-ansi-language: EN-US;
                    "> </span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-bidi-font-family: Arial;
                      color: black;
                      mso-themecolor: text1;
                      letter-spacing: 0.4pt;
                      background: white;
                      mso-ansi-language: EN-US;
                    ">ANY WARRANTIES OF TITLE OR ACCURACY AND ANY IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, OR NON-INFRINGEMENT</span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR
                      USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED</span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-bidi-font-family: Arial;
                      color: #3a4557;
                      letter-spacing: 0.4pt;
                      background: white;
                      mso-ansi-language: EN-US;
                    ">, </span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-bidi-font-family: Arial;
                      color: black;
                      mso-themecolor: text1;
                      letter-spacing: 0.4pt;
                      background: white;
                      mso-ansi-language: EN-US;
                    ">WITH THE SOLE EXCEPTION OF WARRANTIES (IF ANY) WHICH CANNOT
                      BE EXPRESSLY EXCLUDED UNDER APPLICABLE LAW</span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">. Octate, OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS,
                      PARTNERS, AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE
                      PLATFORM IS OR WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR
                      TIME, INSTANCE OR LOCATION; (II) ANY DEFECTS MATERIAL OR
                      NOT, OR ERRORS WILL BE CORRECTED; (III) ANY/ALL CONTENT OR
                      SOFTWARE AVAILABLE AT OR THROUGH THE PLATFORM IS FREE OF
                      VIRUSES OR OTHER HARMFUL COMPONENTS; (IV) ANY/ALL
                      INFORMATION IS COMPLETE, ACCURATE, UP-TO-DATE, OR RELIABLE;
                      (V) ANY PARTICULAR SERVICE, CONTENT, OR PRODUCT REFERRED TO
                      IN THE PLATFORM IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOUR
                      AND/OR YOUR EMPLOYEES; (VI) THAT RESULTS OF USING THE
                      SERVICES PROVIDED BY US WILL MEET YOUR REQUIREMENTS(VII) THE
                      USE OF THE PLATFORM PROVIDED BY US SHALL COMPLY WITH ANY
                      LAWS, RULES, REGULATIONS, REQUIREMENTS, POLICIES,
                      QUALIFICATIONS, OR BEST PRACTICES, INCLUDING BUT NOT LIMITED
                      TO PRIVACY LAWS, PROFESSIONAL LICENSURE, OR REIMBURSEMENT;
                      (VIII) THE USE OF THE PLATFORM SHALL NOT RESULT IN LEGAL
                      DUTIES OR LIABILITY. WE DO NOT GUARANTEE IN ANY INSTANCE
                      THAT ANY PARTICULAR CONTENT OR MATERIAL SHALL BE MADE
                      AVAILABLE THROUGH THE PLATFORM.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248372"></a><a name="_Toc113457295"><span style="mso-bookmark: _Toc36248372">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">18<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">INDEMNIFICATION</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">You acknowledge and agree that you shall at all times
                      defend, indemnify, and hold harmless us, our affiliates, and
                      each of our and our affiliates’ including but not limited to
                      respective officers, directors, contractors, employees,
                      agents, suppliers, and representatives against all
                      liabilities, claims, fees, costs, penalties or sanctions,
                      losses, expenses, and interest of any nature, including
                      reasonable attorneys’ fees, arising out of or which may
                      relate to: (a) your use or misuse of, or access to, the
                      Platform; (b)your violation of any privacy, professional,
                      ethics, licensing, or consumer protection laws, rules, or
                      regulations; (c) your misuse of anyone’s private,
                      proprietary, or Personal data; (d) infringement by you (or
                      any third party using your Account or identity in the
                      Platform) of any intellectual property or other rights of
                      any person or entity; or (e) otherwise in violation of these
                      Terms in any way. It is our right to assume the exclusive
                      defense and control of any matter otherwise subject to
                      indemnification by you, in which event you shall assist and
                      cooperate with us in asserting any available defenses at
                      your expense, including reasonable attorneys’ fees incurred
                      by us.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248373"></a><a name="_Toc113457296"><span style="mso-bookmark: _Toc36248373">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">19<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">LIMITATION OF LIABILITY</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">THE USE OF THE PLATFORM OFFERED BY US IS ENTIRELY AT YOUR
                      OWN RISK. IN NO CASE SHALL WE, NOR OUR OFFICERS, DIRECTORS,
                      EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, SUPPLIERS, CONTENT
                      PROVIDERS, OR ANY USERS BE LIABLE TO YOU UNDER CONTRACT,
                      TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR
                      EQUITABLE THEORY WITH REGARDS TO THE PLATFORM FOR: (I) ANY
                      LOST PROFITS, LOSS IN REVENUE, LOSS OF GOODWILL, ANY DATA
                      LOSS, LOSS OF USE, COST OF PROCURING SUBSTITUTE GOODS OR
                      SERVICES, OTHER INTANGIBLE LOSSES, OR INDIRECT, INCIDENTAL,
                      SPECIAL, PUNITIVE, COMPENSATORY, EXEMPLARY, RELIANCE,
                      PUNITIVE, LIQUIDATED, OR ANY SIMILAR CONSEQUENTIAL DAMAGES
                      OF ANY TYPE WHATSOEVER (HOWEVER ARISING), (II) ANY, VIRUSES,
                      BUGS, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE
                      OF ORIGIN), (III) ANY PERSONAL INJURY OR HARM, INCLUDING
                      DEATH, WHICH IS CAUSED BY YOUR USE OR MISUSE OF THE
                      PLATFORM, (IV) ANY CLAIMS, DEMANDS, OR DAMAGES ARISING OUT
                      OF OR RELATING TO ANY DISPUTE BETWEEN YOU AND ANY OTHER USER
                      OF THE PLATFORM, OR (V) ANY DIRECT DAMAGES IN EXCESS OF (IN
                      THE AGGREGATE) OR GREATER THAN (A) ANY FEES PAID BY YOU FOR
                      USING OF PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS
                      THREE (3) MONTH PERIOD. REMEDIES UNDER THESE TERMS OF
                      SERVICE ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY
                      PROVIDED FOR IN THESE TERMS OF SERVICE. NOTHING IN THESE
                      TERMS OF SERVICE SHALL BE DEEMED TO EXCLUDE OR LIMIT YOUR
                      LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN BY YOU UNDER
                      THESE TERMS.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc113457297"></a><a name="_Toc36248375"><span style="mso-bookmark: _Toc113457297">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">20<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">GOVERNING LAW AND
                        </span>
                      </span> </a><span style="mso-bookmark: _Toc36248375"></span><span
                      style="mso-bookmark: _Toc113457297"><span style="
                        mso-bidi-font-size: 12pt;
                        line-height: 150%;
                        text-transform: none;
                        mso-ansi-language: EN-US;
                      ">DISPUTE RESOLUTION</span></span><span style="mso-bookmark: _Toc113457297"></span><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">The parties agree that the validity, operation, and
                      performance of these Terms shall be governed by and
                      interpreted in accordance with the laws of the state of
                      Delaware applicable therein (notwithstanding conflict of law
                      rules). The Parties do expressly and irrevocably concede to
                      the jurisdiction of courts with respect to any matter or
                      claim, suit, action, or proceeding arising under or related
                      to these Terms. <o:p></o:p></span>
                  </p>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">Any dispute concerning the subject matter of these Terms,
                      or the breach, termination, or validity thereof (a
                      “Dispute”) will be settled exclusively in accordance with
                      the procedures set forth herein. The party seeking
                      resolution of a Dispute will first give notice in writing of
                      the Dispute to the other party, setting forth the nature of
                      the Dispute and a concise statement of the issues to be
                      resolved. If the Dispute has not been resolved through good
                      faith efforts and negotiations of senior officers or
                      representatives of the parties within fifteen (15) days of
                      receipt by the relevant party of the notice of Dispute, such
                      notice will be deemed to be a notice of arbitration and the
                      parties agree to submit the Dispute to a single arbitrator
                      mutually agreeable to both parties. The venue of such
                      arbitration shall be as may be mutually decided by the
                      Parties. In the event that the Parties cannot agree on a
                      sole arbitrator, the arbitrator will be appointed by a judge
                      of the appropriate court on application by either party to
                      the Dispute. All decisions and awards rendered by the
                      arbitrator will be final and binding upon the parties for
                      all questions submitted to such arbitrator, and the costs
                      associated with such submission shall be shared equally by
                      the parties involved in the Dispute unless the arbitrator
                      decides otherwise. The parties waive all rights of appeal,
                      therefore, to any court or tribunal and agree that the only
                      recourse by any party to any court will be for the purpose
                      of enforcing an arbitration award.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248376"></a><a name="_Toc113457298"><span style="mso-bookmark: _Toc36248376">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">21<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">MODIFICATION</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">We shall have the right to make modifications or replace
                      any of the Terms or suspend, change, or discontinue the
                      Platform (including but not limited to the availability of
                      any featured content, or database) at any time or instance
                      by posting a notice through the Platform. We may also do so
                      by sending you a notice via email, via the Platform, or by
                      any other means of communication. We reserve the right to
                      impose limits on certain features and services. We may, if
                      required to do so, restrict your access to parts or all of
                      the Platform without notice or liability. We endeavor to try
                      and provide notice of modifications to these Terms. However,
                      you also agree that it is also your responsibility to make
                      reasonable efforts to be aware of such modifications.
                      <o:p></o:p>
                    </span>
                  </p>
                  <p class="MsoNormal">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">When you continue to use the Platform after notification of
                      any modifications to the Terms shall mean acceptance of
                      those modifications, and those modifications shall apply to
                      your continued use of the Platform going forward. Your use
                      of the Platform is subject to the Terms in effect at the
                      time of such use.<o:p></o:p></span>
                  </p>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248377"></a><a name="_Toc113457299"><span style="mso-bookmark: _Toc36248377">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">22<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">MISCELLANEOUS</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo14">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">a)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    Entire agreement and severability. These Terms
                    are the entire agreement between you and us with regards to
                    the Platform. These Terms supersede all prior, contemporaneous
                    communications and proposals made (whether oral, written, or
                    electronic) between you and us with regard to the Platform. If
                    any provisions mentioned in these Terms are found to be
                    unenforceable or invalid, that particular provision or
                    provisions will be limited or eliminated to the minimum extent
                    necessary so that these Terms will otherwise remain in full
                    force and effect and enforceable. In the event of the failure
                    of either Party to exercise in any respect, any right provided
                    for herein shall not be deemed a waiver of any further rights
                    hereunder.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">b)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    Relationship of the parties. You and Octate
                    are independent contractors. These Terms shall not and do not
                    create a partnership, franchise, joint venture, agency,
                    fiduciary, or employment relationship of any kind between the
                    Parties. You shall not have any authority of any kind to bind
                    us in any respect. Unless expressly stated otherwise in these
                    Terms, there are no third-party beneficiaries to the Terms. We
                    do not have any special relationship with you or any fiduciary
                    duty.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">c)<span style="font: 7pt 'Times New Roman'">          
                        </span></span></span>
                    Force majeure. We will not be liable in any
                    case for any failure or delay in the performance of our
                    obligations for any reason hereunder if such failure results
                    from: (a) any cause beyond our reasonable control, including
                    but not limited to mechanical, electronic, or communications
                    failure or degradation, denial-of-service attacks, (b) any
                    failure by a third-party hosting provider or utility provider,
                    (c) strikes, shortages, riots, fires, acts of God, war,
                    terrorism, and governmental action.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">d)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    Assignment. You agree that these Terms are
                    personal to you and are not assignable, transferable, or
                    sublicensable by you. We reserve the right to assign, transfer
                    or delegate any of our rights and obligations hereunder
                    without obtaining consent.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">e)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    Notices. All notices under these Terms shall
                    be in writing Unless otherwise specified in these Terms of
                    Service. Notices to us shall be sent by email to
                    privacy@octate.ai. You shall ensure written confirmation of
                    receipt for notice to be effective. Notices to you shall be
                    sent to your last known email address (or the email address of
                    your successor, if any) and/or to any email address that would
                    be reasonably likely to provide notice to you, and such notice
                    shall be effective upon transmission.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">f)<span style="font: 7pt 'Times New Roman'">           
                        </span></span></span>
                    No waiver. Our failure to enforce any part of
                    these Terms shall not constitute a waiver of our right to
                    later enforce that or any other part of these Terms. Waiver of
                    compliance in any particular instance does not mean that we
                    will waive compliance in the future.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h2 style="margin-left: 35.45pt; mso-list: l1 level2 lfo4">
                    <span style="
                      mso-fareast-font-family: Verdana;
                      mso-bidi-font-family: Verdana;
                      mso-bidi-font-weight: bold;
                    "><span style="mso-list: Ignore">g)<span style="font: 7pt 'Times New Roman'">         
                        </span></span></span>
                    Interpretation. The headers are provided only
                    to make this agreement easier to read and understand.<b style="mso-bidi-font-weight: normal">
                      <o:p></o:p>
                    </b>
                  </h2>
                  <h1 style="margin-left: 35.45pt; mso-list: l0 level1 lfo2">
                    <a name="_Toc36248378"></a><a name="_Toc113457300"><span style="mso-bookmark: _Toc36248378">
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-fareast-font-family: Verdana;
                          mso-bidi-font-family: Verdana;
                          mso-ansi-language: EN-US;
                        "><span style="mso-list: Ignore">23<span style="font: 7pt 'Times New Roman'">       
                            </span></span></span>
                        <span style="
                          mso-bidi-font-size: 12pt;
                          line-height: 150%;
                          mso-ansi-language: EN-US;
                        ">CONTACT</span>
                      </span> </a><span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">
                      <o:p></o:p>
                    </span>
                  </h1>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="
                      mso-bidi-font-size: 12pt;
                      line-height: 150%;
                      mso-ansi-language: EN-US;
                    ">You may get in touch with us through our Platform or the
                      address given below: Octate Corp, 251 Little Falls Drive,
                      Wilmington Delaware, privacy@octate.ai <o:p></o:p></span>
                  </p>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="mso-ansi-language: EN-US">
                      <o:p> </o:p>
                    </span>
                  </p>
                  <p class="MsoNormal" style="mso-pagination: none">
                    <span style="mso-ansi-language: EN-US">
                      <o:p> </o:p>
                    </span>
                  </p>
                  <h3 style="margin-left: 0in; text-indent: 0in; mso-list: none">
                    <o:p> </o:p>
                  </h3>
                  <p class="MsoNormal">
                    <span style="mso-ansi-language: EN-US">
                      <o:p> </o:p>
                    </span>
                  </p>
                  <h3 style="margin-left: 0in; text-indent: 0in; mso-list: none">
                    <o:p> </o:p>
                  </h3>
                  <h3 style="margin-left: 0in; text-indent: 0in; mso-list: none">
                    <o:p> </o:p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </body>

  </html>`;
