import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../../config/config";
import InputFieldQuestionTypeList from "../../../../addProposal/component/addSectionAndQuestionPortal/inputfFieldQuestionTypeList";
// import InputFieldQuestionTypeList from "./inputfFieldQuestionTypeList";

const AnswersCheckbox = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const proposalQuestionFormError = props?.proposalQuestionFormError;
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const PROPOSAL_ADD_QUESTION = KEYS?.PROPOSAL_ADD_QUESTION;
  const fields = props?.fields;
  return (
    <div style={{ maxHeight: "400px", overflow: "auto", overflowX: "hidden" }}>
      {/* <div className="add-question-type-list-sub-header-wrapper">
        <p className="add-question-type-list-sub-header-text p-x-sm">
          Enter the choices below
        </p>
      </div> */}
      {proposalQuestionFormError?.Answer_Error ? (
        <p className="add-question-type-list-sub-header-text--error p-x-sm">
          {proposalQuestionFormError?.Answer_Error}
        </p>
      ) : null}
      {fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
        ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.map(
            (list, index) => {
              return (
                <div
                  onClick={(val) =>
                    props?.questionTypeList(
                      {
                        ...list,
                        [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: !list?.[
                          ADD_QUESTION_CONFIG_KEYS?.ANSWER
                        ],
                      },
                      index
                    )
                  }
                  className="add-question-type-main-container m-b-md m-x-sm"
                >
                  <input
                    type={props?.inputType ? props?.inputType : "checkbox"}
                    className="checkbox-input-style-proposal-add-question-type-list p-x-base"
                    checked={
                      list?.[ADD_QUESTION_CONFIG_KEYS?.ANSWER] ? true : false
                    }
                  ></input>
                  <div
                    class="field p-r m-x-sm flex-question-input-field"
                    id="field-user__email"
                  >
                    <InputFieldQuestionTypeList
                      disabled={true}
                      label={PROPOSAL_ADD_QUESTION?.TEXT_BOX}
                      value={list?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]}
                      removeBottomPadding={true}
                      //   changeText={(val) =>
                      //     props?.questionTypeList(
                      //       {
                      //         ...list,
                      //         [ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]: val,
                      //         [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: null,
                      //       },
                      //       index
                      //     )
                      //   }
                      error={
                        proposalQuestionFormError?.index == index
                          ? proposalQuestionFormError[
                              ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS
                            ]
                          : null
                      }
                    />
                  </div>
                </div>
              );
            }
          )
        : null}
    </div>
  );
};

export default AnswersCheckbox;
