import React, { useEffect, useState } from "react";
import SummaryStats from "./component/summaryStats.js/summaryStats";
import "../../styles/dashboard.css";
import DropdownFilter from "./component/dropdownFilter/dropdownFilter";
import { useDispatch } from "react-redux";
import { dashboardFilterFn } from "../../contexts/reducer/dashboardReducer";
import ViewsGraph from "./component/viewsGraph/viewsGraph";
import DashboardTabsSection from "./component/dashbaordTabs/dashboardTabsSection";
const VendorDashboard = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  function handleTabClicked(tab) {
    setSelectedTab(tab);
  }
  useEffect(() => {
    dispatch(
      dashboardFilterFn([
        {
          name: "All Time",
          id: 1,
          type: "date",
        },
      ])
    );
  }, []);
  return (
    <div className="dashboard-main-wrapper">
      <div className="m-b-sm">
        <DashboardTabsSection selectedTab={selectedTab} handleTabClicked={handleTabClicked} />
      </div>

      <div className="m-b-sm">
        <DropdownFilter selectedTab={selectedTab} handleTabClicked={handleTabClicked} />
      </div>
      <SummaryStats selectedTab={selectedTab} />
      {selectedTab <= 2 ? <ViewsGraph selectedTab={selectedTab} /> : null}
    </div>
  );
};

export default VendorDashboard;
