import React from "react";
import { useState } from "react";
import EngagementBar from "../../../utlis/hoc/engagementBar/engagementBar";
import BlurbContent from "../../../utlis/hoc/newBlurbCard/components/blurbContent";
import BlurbCtaButton from "../../../utlis/hoc/newBlurbCard/components/blurbCtaButton";
import BlurbHeader from "../../../utlis/hoc/newBlurbCard/components/blurbHeader";
import BlurbMedia from "../../../utlis/hoc/newBlurbCard/components/blurbMedia";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const BlurbCard = (props) => {
  const [dragOpacity, setDragOpacity] = useState(1);
  const [hovered, setHovered] = useState(false);
  const item = props?.item;
  const index = props?.index;
  const cardType = "blurb";
  const onDragOver = (e, id) => {
    e.preventDefault();
    setDragOpacity(0.3);
  };
  const onDragStart = (e, id) => {
    e.dataTransfer.setData("current index", id);
    // setDragOpacity(0.2);
  };
  const onDragEnd = () => {
    setDragOpacity(1);
  };
  const onDrop = (e, index, value) => {
    const currentIndex = e.dataTransfer.getData("current index");
    props?.handleChangePosition(currentIndex, index, value);
    setDragOpacity(1);
  };
  const handleDragLeave = () => {
    // Set the opacity back to 1 when the draggable element leaves the target element
    setDragOpacity(1);
  };
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index, "Blurbs")}
      onDragEnd={(e) => onDragEnd(e, index)}
      onDragLeave={handleDragLeave}
      className="product-card cursor-for-drag p-r"
      style={{ opacity: dragOpacity }}
      key={item?.blurbID}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* {hovered ? (
        <div
          data-inverted="true"
          data-position="top left"
          data-tooltip={"Drag to change position"}
          style={{
            position: "absolute",
            top: "45%",
            left: "40%",
          }}
        >
          <FeatherIcon
            icon="shuffle"
            size="65"
            color={"rgba(85, 112, 241, 0.5)"}
            className="filter-input-field-search-icon"
          />
        </div>
      ) : null} */}

      <BlurbHeader keyName={"blurbID"} data={item} cardType={cardType} page={props?.page} section={props?.section} />
      <div className="blurb-card-content">
        <BlurbContent data={item} cardType={cardType} page={props?.page} section={props?.section} />
        <BlurbMedia cardType={cardType} page={props?.page} section={props?.section} data={item} />
      </div>
      <div className="blurb-card-engagement-wrapper">
        <div className="new-card-engagement-bar-container">
          <EngagementBar
            cardType={cardType}
            page={props?.page}
            section={props?.section}
            data={item}
            keyName={"blurbID"}
            engageSource={"blurb engagement from blurb card"}
          />
        </div>
        <BlurbCtaButton cardType={cardType} page={props?.page} section={props?.section} data={item} />
      </div>
    </div>
  );
};

export default BlurbCard;
