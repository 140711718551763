import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useState } from "react";
import { ToTitleCase } from "../../../../utlis/hof/hof";
import FilterSearch from "./filterSearch";

const FilterList = (props) => {
  const listData = props?.data;
  const [filterOpen, setFilterOpen] = useState(false);

  const handleData = (val) => {
    if (val?.isSelected) {
      props?.handleRemoveFilterData({ ...val, isSelected: !val?.isSelected });
    } else {
      props?.handleFilterData({ ...val, isSelected: !val?.isSelected });
    }
  };

  const handleToggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilterOpen(false);
    }
  };

  return listData?.length > 0 ? (
    <div tabIndex={0} onBlur={handleBlur}>
      <div className="filter-section-header-container">
        <div className="filter-section-header">{props?.label}</div>
        <FeatherIcon icon="search" size="25" color={"#130F26"} role="button" onClick={() => handleToggleFilter()} />
      </div>
      <div className="filter-list-items-container">
        {listData?.map((item, index) => {
          if (item?.isSelected || index < 10)
            return (
              <div className="filter-list-items" key={item?.name}>
                <>
                  <input
                    type="checkbox"
                    name="standard_attribute"
                    id={item?.name}
                    value={item?.name}
                    checked={item?.isSelected}
                    onChange={() => handleData(item, index)}
                    className="filter-checkbox"
                    role={"button"}
                  />
                  <span
                    className={`filter-list-items-text ${item?.isSelected ? `filter-list-items-text-selected` : ""}`}
                  >
                    {item?.name}
                  </span>
                </>
                <div>
                  <span
                    className={`filter-list-items-text ${item?.isSelected ? `filter-list-items-text-selected` : ""}`}
                    style={{ fontSize: "12px" }}
                  >
                    {item?.count}
                  </span>
                </div>
              </div>
            );
        })}
        {listData?.length > 10 ? (
          <div>
            <a
              className="show-more-trigger link-xs fw-600"
              data-controller="modal-trigger"
              data-modal-trigger="#filter-options-modal-standard_attribute"
              role="button"
              onClick={() => handleToggleFilter()}
            >
              + {listData?.length - 10} more
            </a>
          </div>
        ) : null}
        {filterOpen ? (
          <FilterSearch
            data={listData}
            handleToggleFilter={handleToggleFilter}
            handleData={handleData}
            keyName={props?.keyName}
            handleApplyChanges={props?.handleApplyChanges}
          />
        ) : null}
      </div>
    </div>
  ) : null;
};

export default FilterList;
