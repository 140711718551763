import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedUserFn } from "../../../../../contexts/reducer/chatReducer";
import { GetNameInitials } from "../../../../../utlis/hof/hof";
function ChatCard(props) {
  const index = props?.newIndex;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const LIST_DATA = props?.data;
  const dispatch = useDispatch();
  const initials = GetNameInitials(LIST_DATA?.User_Name ? LIST_DATA?.User_Name : "Anono Name");
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedUser);
  const activeConversionId = props?.activeConversionId;
  function handleFeatureList() {
    // props?.handleFeatureList(index + 1);
    // props?.handleActiveConversionId(LIST_DATA?.Conversation_ID);
    if (selectedVendorSelector?.Conversation_ID !== LIST_DATA?.Conversation_ID) {
      dispatch(selectedUserFn(LIST_DATA));
    }
  }
  return (
    <div
      onClick={handleFeatureList}
      className={`chat-list-container p-r ${
        selectedVendorSelector?.Conversation_ID == LIST_DATA?.Conversation_ID ? " chat-list-container--active" : ""
      } `}
      key={LIST_DATA?.Conversation_ID}
    >
      {LIST_DATA?.UnSeenCount && selectedVendorSelector?.Conversation_ID !== LIST_DATA?.Conversation_ID ? (
        <div className="direct-message-count-main-wrapper">
          <p className="direct-message-count-count-text">{LIST_DATA?.UnSeenCount}</p>
        </div>
      ) : null}
      <div class="circular-avatar">
        <div class="user-initials user-initials--primary">{initials}</div>
      </div>
      <div style={{ flexGrow: "1", width: "100%" }}>
        <div className="flex space-between">
          <h3 className="chat-list-username">{LIST_DATA?.User_Name}</h3>
          {/* <p className="chat-list-date">{formatDate(LIST_DATA?.Date_Time)}</p> */}
        </div>
        <p
          className={`chat-list-description ${
            LIST_DATA?.UnSeenCount && selectedVendorSelector?.Conversation_ID !== LIST_DATA?.Conversation_ID
              ? "chat-list-description--bold"
              : ""
          }`}
        >
          {LIST_DATA?.Message_Text}
        </p>
      </div>
    </div>
  );
}

export default ChatCard;
