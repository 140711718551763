import React from "react";
import { useSelector } from "react-redux";
import { ToTitleCase } from "../../../../../utlis/hof/hof";

const ListYourDataDesktop = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  const handleJoinOctate = () => {
    window.open("https://business.octate.ai/");
  };
  return (
    <a rel="noopener noreferrer" className="navbar__item">
      <div className="navbar__item-content">
        <div className="relative flex align-items-center">
          <span className="navbar__item-title m-r-sm" onClick={() => handleJoinOctate()}>
            {KEYS?.JOIN_OCTATE_BUSINESS?.LABEL}
          </span>
        </div>
      </div>
    </a>
  );
};
export default ListYourDataDesktop;
