import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import "../../../../styles/popular-case-studies.css";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import PopularDetail from "./components/popularDetail";
import PopularList from "./components/popularList";

const PopularCaseStudies = () => {
  const [activeCategory, setActiveCategory] = useState("");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  const ProductCategoryData = useEnCryptGetApi(
    `/getProductCategoriesWithUseCases?User_ID=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  useEffect(() => {
    if (ProductCategoryData?.data?.data) setActiveCategory(Object?.keys(ProductCategoryData?.data?.data)[0]);
  }, [ProductCategoryData?.data?.data]);

  const CATEGORY_DATA =
    isMobileOnly && ProductCategoryData?.data?.data
      ? Object?.values(ProductCategoryData?.data?.data)?.flat()
      : ProductCategoryData?.data?.data;
  return (
    <div className="container m-y-sm">
      <h2
        class="landing-page__section-title only-mobile"
        style={{ color: "#181527", borderBottom: "1px solid #ffb527", width: "fit-content" }}
      >
        Popular Success Stories
      </h2>
      {ProductCategoryData?.data?.data ? (
        <div className="grid-1-3 mobile-1-col">
          <PopularList
            data={ProductCategoryData?.data?.data}
            handleActiveCategory={(val) => setActiveCategory(val)}
            activeCategory={activeCategory}
          />
          <PopularDetail data={CATEGORY_DATA} activeCategory={activeCategory} />
        </div>
      ) : null}
    </div>
  );

};

export default PopularCaseStudies;
