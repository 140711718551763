import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import SmallCardFirmographics from "./smallCardFirmographics";
// import * as KEYS from "../../../../config/config";
import * as ENV_KEYS from "../../../../config/configKeys";
function VendorDetails(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorObject = props?.signalTrackingVendorObject;
  const VendorDetails = props?.allProducts;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: signalTrackingVendorObject?.page,
      Item_Id: VendorDetails?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: VendorDetails?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div className="flex space-between align-items-center m-t-xs">
      <div class="vertical-product-card__subtitle flex-container" style={{ margin: "0px" }}>
        <a
          onClick={() =>
            handleVendorLinkClicked(
              `/vendor/${ReplaceBlankSpaceByHyphen(VendorDetails?.Vendor_Name, VendorDetails?.Vendor_ID)}/${
                KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
              }`
            )
          }
          href={`/vendor/${ReplaceBlankSpaceByHyphen(VendorDetails?.Vendor_Name, VendorDetails?.Vendor_ID)}/${
            KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
          }`}
          class="vertical-product-card__logo-wrapper"
        >
          <img
            alt={` ${VendorDetails?.Vendor_Name} ${ENV_KEYS?.CONFIG?.VENDOR_LOGO}`}
            class="lazyloaded"
            data-src={VendorDetails?.Account_Logo ? VendorDetails?.Account_Logo : KEYS?.CORRUPT_IMAGE_ICON}
            src={VendorDetails?.Account_Logo ? VendorDetails?.Account_Logo : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        </a>

        <div>
          <a
            onClick={() =>
              handleVendorLinkClicked(
                `/vendor/${ReplaceBlankSpaceByHyphen(VendorDetails?.Vendor_Name, VendorDetails?.Vendor_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                }`
              )
            }
            href={`/vendor/${ReplaceBlankSpaceByHyphen(VendorDetails?.Vendor_Name, VendorDetails?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`}
          >
            {VendorDetails?.Vendor_Name}
          </a>
        </div>
      </div>
      <SmallCardFirmographics data={props.allProducts} type={props?.type} page={props?.page} />
      <div></div>
    </div>
  );
}

export default VendorDetails;
