import React from "react";
import FeatherIcon from "feather-icons-react";
const Header = (props) => {
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const USER_CAN_EDIT = props?.fUSER_CAN_EDIT;
  const hideEditProposalBasicDetails = PROPOSAL_TYPE == "answer" || !USER_CAN_EDIT ? true : false;
  return (
    <div className="proposal-submit-page-header-collapse-main-wrapper">
      <div className="proposal-submit-page-header-collapse-left-wrapper p-x-sm">
        <FeatherIcon
          onClick={props?.handleToggleSection}
          icon={props?.showSection ? "chevron-down" : "chevron-right"}
          size="20"
          role="button"
          // className="blurb-detail-social"
          color={"#130F26"}
          // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
        />
        <h3 className="proposal-submit-page-header-title p-x-mini">{props?.headerTitle}</h3>
      </div>
      {props?.hideEdit || hideEditProposalBasicDetails ? null : (
        <div
          onClick={() => props?.handleScreenToShow(1)}
          className="proposal-submit-page-header-collapse-right-wrapper p-x-sm"
        >
          <FeatherIcon
            icon="edit-3"
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            //   onClick={() => handleEditFAQ(item)}
            //   style={{ marginLeft: "10px" }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
