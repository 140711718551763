import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetBoardsDataQuery } from "../../../../adapters/xhr/generalApi";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import _ from "lodash";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import * as NO_CONTENT from "../../../../config/config";
import NewUseCaseCard from "../../../../utlis/hoc/newUseCaseCard/newUseCaseCard";

const BoardUsecaseTab = (props) => {
  const [pageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [remountFilter, setRemountFilter] = useState(0);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const ProductData = useEnCryptGetApi(
    `/getBookmarksOrUserLikes?User_ID=${USER_ID}&Item_Type=usecase&type=${PARAMS.type}`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useGetBoardsDataQuery
  );

  const PRODUCT_DATA = ProductData?.data?.data;

  const HandlePageNumber = (val) => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setPageNo(val);
    }, 300);
  };

  const handleFilterData = (val) => {
    let newData = [...SelectedFilter];
    newData.push(val);
    setSelectedFilter(newData);
    HandlePageNumber(1);
    setRemountComponent(Math.random());
    setRemountFilter(Math.random());
  };

  const handleRemoveFilterData = (val) => {
    HandlePageNumber(1);
    const NewData = SelectedFilter?.filter((item) => {
      return val?.name !== item.name;
    });
    setSelectedFilter(NewData);
    setRemountComponent(Math.random());
    setRemountFilter(Math.random());
  };

  const handleApplyChanges = (val) => {
    if (val?.length > 0) {
      const data = _.uniqBy([...SelectedFilter, ...val], "name");
      setSelectedFilter(val);
    } else setSelectedFilter([]);
    setRemountComponent(Math.random());
    setRemountFilter(Math.random());
  };

  const handleClearData = (val) => {
    if (SelectedFilter?.length) {
      setSelectedFilter([]);
      setRemountComponent(Math.random());
    }
  };

  return (
    <div className="one-column-grid" key={remountComponent}>
      {ProductData?.isLoading || ProductData?.isFetching ? (
        <ProductSkeleton />
      ) : PRODUCT_DATA?.length ? (
        <NewUseCaseCard section={`boards-detail-page`} page={props?.page} data={PRODUCT_DATA} />
      ) : (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">
            {PARAMS?.type == "bookmark"
              ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BOOKMARK_BOARD_USECASE
              : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.LIKE_BOARD_USECASE}
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardUsecaseTab;
