import React from "react";
import PreferencesSwitch from "./preferencesSwitch";
import PreferenceItem from "./preferenceItem";
import { ToTitleCase } from "../../../utlis/hof/hof";
import { useSelector } from "react-redux";

const PreferencesSelection = (props) => {
  const FEATURE_STATE = props?.featureActiveList;
  const authData = useSelector((state) => state?.authDetails?.details);

  const handleSwitchChanged = (val, index) => {
    const featuresArray = props?.data?.map((list) => {
      if (list?.name == val?.name) {
        return { ...list, selected: !list.selected };
      } else {
        return list;
      }
    });
    props?.handleDetails({ ...props?.companyDetails, Features: featuresArray });
  };

  const DATA = props?.data?.filter((item) => item?.channel === FEATURE_STATE);

  return (
    <div className="preference-selection-container" key={FEATURE_STATE}>
      <div key={authData?.data?.buyerView}>
        {DATA?.map((channelElements) =>
          channelElements?.channelElements?.map((item) => {
            if (item?.personaDisplayName === "Business user" && authData?.data?.buyerView) {
              return null;
            } else {
              return (
                <div className="preference-section-container" key={FEATURE_STATE}>
                  <h1 className="preference-section-header">
                    {item?.personaDisplayName === "Consumer" ? "Buyer" : item?.personaDisplayName}
                  </h1>
                  <div className="preference-header-container">
                    <div>
                      <h1 className="preference-header">
                        {FEATURE_STATE === "In App" ? "App" : FEATURE_STATE} Notification
                      </h1>
                      <p className="preference-sub-header">
                        Get {FEATURE_STATE} to find out what's going on when you're not on Octate AI. You can turn them
                        off anytime.
                      </p>
                    </div>
                    <PreferencesSwitch
                      checked={item?.personaGlobalChannelOptin}
                      section={"Global"}
                      data={item}
                      channel={DATA[0]?.channel}
                    />
                  </div>
                  {item?.personaGlobalChannelOptin ? (
                    item?.personaElements?.map((element) => (
                      <PreferenceItem data={element} channel={DATA[0]?.channel} />
                    ))
                  ) : (
                    <div className="no-content-container">
                      <h4 className="no-content-preference-header">
                        Turn on {FEATURE_STATE?.toLowerCase()} preferences
                      </h4>
                      <p className="no-content-preference-sub-header">
                        To receive notifications as they happen, turn on {FEATURE_STATE?.toLowerCase()}. You'll also
                        receive them when you're not on Octate AI. Turn them off anytime.
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          })
        )}
      </div>
    </div>
  );
};

export default PreferencesSelection;
