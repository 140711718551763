import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import AddProposal from "../../addProposal/addProposal";
import EditProposal from "../../answerProposal/component/editProposal";
import EditQuestionProposal from "../../editProposal/editQuestionProposal";

const EditProposalPortal = (props) => {
  const tabIndex = props?.tabIndex;
  const notificationJson = props?.notificationJson;
  const Id = props?.Id;
  const editProduct = props?.editProduct;
  return ReactDom.createPortal(
    <>
      <div className=" ui dimmer modals page visible active portalModal">
        <EditQuestionProposal
          notificationJson={notificationJson}
          editProduct={editProduct}
          Id={Id}
          handleClose={() => props?.handleClose()}
        />
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")
  );
};

export default EditProposalPortal;
