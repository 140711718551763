import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
const FaqActionButtons = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const hasProduct = DATA?.Product_ID ? true : false;

  //   href={
  //   !hasProduct
  //     ? `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
  //         KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
  //       }`
  //     : `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
  //         DATA?.Product_Name,
  //         DATA?.Product_ID
  //       )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
  //   }

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.PRODUCT_CARD_ACTION_BUTTON,
    page: props?.page,
    section: "Faq detailed page",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleActionButtonCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Item_Type: "product",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: "data",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div className="product-card-action-container">
      <Link
        to={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
        }`}
      >
        <div
          className="product-card-action-button-blue"
          onClick={() =>
            handleActionButtonCLicked(
              `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
              }`
            )
          }
        >
          View Vendor
        </div>
      </Link>
      <Link
        to={
          !hasProduct
            ? `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/products`
            : `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                DATA?.Product_Name,
                DATA?.Product_ID
              )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
        }
      >
        <div
          className="product-card-action-button"
          onClick={() =>
            handleActionButtonCLicked(
              !hasProduct
                ? `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/products`
                : `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                    DATA?.Product_Name,
                    DATA?.Product_ID
                  )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
            )
          }
        >
          {hasProduct ? "View Product" : "View Products"}
        </div>
      </Link>
    </div>
  );
};

export default FaqActionButtons;
