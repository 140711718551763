import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import {
  concatIsSelectedAllData,
  ConcatSelectedUseCase,
} from "../../../../../utlis/hof/hof";

const SelectUseCase = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const [searchProductCategoryText, setSearchProductCategoryText] = useState("");
  const error = props?.error;
  const KEY_NAME = props?.keyName ? props?.keyName : "Use_Case_Blurb_ID";

  const PRODUCT_ID_LIST = props?.productData?.map((item) => item?.Product_ID);

  const GetProductFormVendorIdApi = useEnCryptGetApi(
    `/getAllUseCaseBlurbs?vendorId=${authData?.data?.vendorId}&productId=${JSON.stringify(
      PRODUCT_ID_LIST
    )}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const selectedTopicValue = props?.productBasicDetails?.length
    ? props?.productBasicDetails
    : [];

  const concatIsSelectedCompatible = ConcatSelectedUseCase(
    selectedTopicValue,
    GetProductFormVendorIdApi,
    "Use_Case_Blurb_ID"
  );

  useEffect(() => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      [KEY_NAME]: selectedTopicValue,
    });
  }, [GetProductFormVendorIdApi]);

  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      [KEY_NAME]: val,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };

  return (
    <div className="m-y-xs p-r" style={{ width: "100%" }}>
      {props?.productBasicDetails?.[KEY_NAME]?.length ? (
        <label
          for="user__email"
          className="company-details-label-input-field-filled-absolute"
        >
          {"Use case"}
        </label>
      ) : null}
      <Dropdown
        data={concatIsSelectedCompatible}
        // handleApplyClicked={handleApplyCountryClicked}
        label={"Select Use case"}
        handleSelectedDataOnChange={handleSelectedDataOnChange}
        selectedValue={selectedTopicValue}
        hideSaveAndClear={true}
        searchWithApi={false}
        handleSearchText={handleSearchText}
        name={"title_level_1"}
        Id={"Use_Case_Blurb_ID"}
        error={error?.[KEY_NAME]}
        filterText={"Search Use case"}
        selectedSmall={true}
      />
    </div>
  );
};

export default SelectUseCase;
