import React from "react";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../utlis/hof/hof";
import MetaSEO from "../SEO/MetaSEO";
import * as SECRET_KEYS from "../../config/configKeys";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const FaqHeader = (props) => {
  const FAQ_DATA = props?.data;

  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const hasProduct = FAQ_DATA?.Product_ID ? true : false;
  const GetSEOTags = (data) => {
    const tags = data?.map((item) => item?.Name)?.filter((val) => val);
    return tags;
  };

  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: props?.page,
    section: "Blurb detail header",
  };
  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: val?.Vendor_Name,
      Item_Id: val?.Vendor_ID,
      Target: {
        page: "vendor page",
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}`,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: val?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div className="blurb-detail-header-container m-b-base">
      {/* <HeaderSEO data={FAQ_DATA?.title_level_1} /> */}
      {FAQ_DATA?.tags ? (
        <MetaSEO
          title={FAQ_DATA?.title_level_1}
          description={FAQ_DATA?.description}
          Keywords={GetSEOTags(FAQ_DATA?.tags)}
        />
      ) : null}

      <div className="flex-container" style={{ width: "70%" }}>
        <div className="product-card-header-details m-r-base">
          <a
            href={`/vendor/${ReplaceBlankSpaceByHyphen(FAQ_DATA?.Vendor_Name, FAQ_DATA?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`}
          >
            <img
              class="blurb-card-logo"
              data-src={
                FAQ_DATA?.Vendor_Logo_URL
                  ? FAQ_DATA?.Vendor_Logo_URL
                  : require("../../assets/images/Vendor_Placeholder_Icon.svg").default
              }
              src={
                FAQ_DATA?.Vendor_Logo_URL
                  ? FAQ_DATA?.Vendor_Logo_URL
                  : require("../../assets/images/Vendor_Placeholder_Icon.svg").default
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
              }}
              alt={`${FAQ_DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
            />
          </a>
        </div>
        <div onClick={() => handleVendorNameClicked(FAQ_DATA)} className="product-card-vendor-details  p-b-base">
          <a
            className="blurb-header-vendor-name"
            role={"button"}
            href={
              !hasProduct
                ? `/vendor/${ReplaceBlankSpaceByHyphen(FAQ_DATA?.Vendor_Name, FAQ_DATA?.Vendor_ID)}/${
                    KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                  }`
                : `/${ReplaceBlankSpaceByHyphen(FAQ_DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                    FAQ_DATA?.Product_Name,
                    FAQ_DATA?.Product_ID
                  )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
            }
          >
            {ToTitleCase(!hasProduct ? FAQ_DATA?.Vendor_Name : FAQ_DATA?.Product_Name)}
          </a>
          <div
            style={{
              border: "1px solid #d3d3d3",
              padding: "1px 3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius:'3px',
              backgroundColor: "rgba(92, 114, 132,0.2)",
            }}
            className="flex additional-info-vendor-page"
          >
            {/* <FeatherIcon
              icon={!hasProduct? "briefcase":"box"}
              size="13"
              style={{ marginRight: "5px" }}
              // className="blurb-detail-social"
            /> */}
            <p
              data-inverted
              data-position="right center"
              data-tooltip={`FAQ related to ${!hasProduct ? "vendor" : "product"} ${
                !hasProduct ? FAQ_DATA?.Vendor_Name : FAQ_DATA?.Product_Name
              }`}
              style={{fontSize:'10px'}}
              className="use-case-card__disclaimer"
            >
              {!hasProduct ? "Vendor" : "Product"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqHeader;
