import React, { useState } from "react";
import { useSelector } from "react-redux";

const OptionsList = ({ props, data, handleDeletePressed }) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;

  return (
    <>
      {props?.data?.User_ID === USER_ID ? (
        <>
          <div className="action-button-container options-list" onClick={() => props?.handleEditPressed()}>
            <p className="share-desc-title">Edit</p>
          </div>
          <div className="action-button-container options-list" onClick={() => handleDeletePressed(data)}>
            <p className="share-desc-title">Delete</p>
          </div>{" "}
        </>
      ) : null}
      <div className="action-button-container options-list" onClick={() => props?.handleActionClicked("delete")}>
        <p className="share-desc-title">Report</p>
      </div>
    </>
  );
};

export default OptionsList;
