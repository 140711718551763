import React from "react";
import FeatherIcon from "feather-icons-react";
const CommentsHeader = (props) => {
  return (
    <div className="vendor-proposal-dis-flex-space-btw answer-proposal-selected-question-question-container">
      <h6 className="proposal-submit-page-header-title">Comments</h6>
      <FeatherIcon
        onClick={props?.handleToggleSection}
        icon={props?.toggleSection ? "chevron-down" : "chevron-right"}
        size="20"
        role="button"
        // className="blurb-detail-social"
        color={"#130F26"}
        // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
      />
    </div>
  );
};

export default CommentsHeader;
