import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostBoardMutation, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../../config/configKeys";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import CreateNewBoard from "../../../utlis/hoc/Boards/createNewBoard";
import ConfirmDeleteModal from "../../../utlis/hoc/engagementBar/components/confirmDeleteModal";
import { ToTitleCase } from "../../../utlis/hof/hof";
function BoardHeader(props) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });

  const Data = props?.Data;
  const [EditModalVisible, setEditModalVisible] = useState(false);
  const [NewEditModalVisible, setNewEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const navigate = useNavigate();
  const selectedTab = props?.selectedTab;
  const boardDetails = props?.boardDetails;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const basicDetails = boardDetails?.Board_Details;
  const time = moment(basicDetails?.Updated_At ? basicDetails?.Updated_At * 1000 : new Date().getTime()).fromNow();
  const ObjectKeys =
    typeof boardDetails?.Data == "object" && Object.keys(boardDetails?.Data)?.length
      ? Object.keys(boardDetails?.Data)
      : [];
  const DeleteBoard = useEnCryptPostApi(`/deleteBoard`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostBoardMutation);
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (DeleteBoard?.data?.status) {
      // "/board-detail/my-boards"
      navigate(-1);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.BOARD_DELETED,
        Item_Type: "Board",
        Item_Id: basicDetails?.Board_ID,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: null,
          context: `Board deleted`,
          page: "/business-profile/blurbs",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [DeleteBoard?.data]);
  const handleDeleteBoard = () => {
    const rawData = {
      ...basicDetails,
      userId: userId,
    };
    DeleteBoard?.handleTrigger(rawData);
  };
  const handleEditBoard = (val) => {
    // setEditBoardData(val);
    setEditModalVisible((prev) => !prev);
  };
  const handleNewEditBoard = (val) => {
    // setEditBoardData(val);
    setNewEditModalVisible((prev) => !prev);
  };
  const handleDeletePressed = () => {
    setDeleteModalVisible((prev) => !prev);
  };
  return (
    <>
      {deleteModalVisible ? (
        <ConfirmDeleteModal
          closeModal={() => handleDeletePressed()}
          handleSubmit={() => handleDeleteBoard()}
          type={"board"}
        />
      ) : null}
      <div className="business-profile-container">
        <div className="flex-list space-evenly ">
          {basicDetails?.Name ? (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <h1 className="business-profile-name">{basicDetails?.Name}</h1>
              <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                {basicDetails?.User_ID == userId ? (
                  <div
                    onClick={handleNewEditBoard}
                    className="filter-search-apply-button"
                    style={{ minWidth: "60px", textAlign: "center" }}
                  >
                    {"Edit"}
                  </div>
                ) : (
                  <div
                    onClick={handleEditBoard}
                    className="filter-search-apply-button"
                    style={{ minWidth: "60px", textAlign: "center" }}
                  >
                    {"Copy"}
                  </div>
                )}
                {basicDetails?.User_ID == userId ? (
                  <div
                    className="filter-search-apply-button"
                    onClick={() => handleDeletePressed()}
                    style={{ minWidth: "60px", textAlign: "center" }}
                  >
                    {DeleteBoard.isLoading ? "Loading..." : "Delete"}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="board-detail-page-board-header-private-container">
            <p className="vendor-onboarding-verified-text m-b-base">
              {basicDetails?.Type == "Private" ? "Private" : "Public"}
            </p>
            {basicDetails?.Updated_At ? <p className="vendor-onboarding-verified-text m-b-base">|</p> : null}
            {basicDetails?.Updated_At ? (
              <p className="vendor-onboarding-verified-text m-b-base">{`${time} ago`}</p>
            ) : null}
          </div>
          <div className="flex align-items-center m-r-base m-y-mini">
            <p className="board-list-board-description-text">{basicDetails?.Description}</p>
          </div>

          {/* <div
            className="engagement-bar-container"
            style={{ width: "100%", alignSelf: "flex-start", flexWrap: "wrap" }}
          >
            {ObjectKeys.map((list) => {
              return (
                <div className="engagement-bar-items">
                  <p className="boards-items-list-total-count">{`Total ${list} : ${boardDetails?.Data?.[list]?.length}`}</p>
                </div>
              );
            })}
          </div> */}
        </div>
        <div className="flex" style={{ overflowX: "auto", borderBottom: "1px solid #d3d3d3", scrollbarWidth: "none" }}>
          {Data.map((label) => {
            return (
              <div className="flex m-t-base" key={label}>
                <h2
                  className={`tab-index-text ${selectedTab == label ? "tab-index-selected" : null}`}
                  onClick={() => props?.handleChangeTabClicked(label)}
                >
                  {`${label} (${boardDetails?.Data?.[label]?.length})`}
                </h2>
              </div>
            );
          })}
        </div>
        {EditModalVisible ? (
          <CreateNewBoard
            data={basicDetails}
            edit={Object.keys(basicDetails)?.length ? true : false}
            clone={true}
            handleClose={() => setEditModalVisible((prev) => !prev)}
          />
        ) : null}

        {NewEditModalVisible ? (
          <CreateNewBoard data={basicDetails} edit={true} handleClose={() => setNewEditModalVisible((prev) => !prev)} />
        ) : null}
      </div>
    </>
  );
}

export default BoardHeader;
