import React from "react";
import { useState } from "react";
import MatchingProducts from "../../../utlis/hoc/newVendorCard/components/matchingProducts";
import VendorActions from "../../../utlis/hoc/newVendorCard/components/vendorActions";
import VendorContent from "../../../utlis/hoc/newVendorCard/components/vendorContent";
import VendorHeader from "../../../utlis/hoc/newVendorCard/components/vendorHeader";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const VendorCard = (props) => {
  const [dragOpacity, setDragOpacity] = useState(1);
  const [hovered, setHovered] = useState(false);
  const item = props?.item;
  const index = props?.index;
  const cardType = "vendor";
  const onDragOver = (e, id) => {
    e.preventDefault();
    setDragOpacity(0.3);
  };
  const onDragStart = (e, id) => {
    e.dataTransfer.setData("current index", id);
    // setDragOpacity(0.2);
  };
  const onDragEnd = () => {
    setDragOpacity(1);
  };
  const onDrop = (e, index, value) => {
    const currentIndex = e.dataTransfer.getData("current index");
    props?.handleChangePosition(currentIndex, index, value);
    setDragOpacity(1);
  };
  const handleDragLeave = () => {
    // Set the opacity back to 1 when the draggable element leaves the target element
    setDragOpacity(1);
  };
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index, "Vendors")}
      onDragEnd={(e) => onDragEnd(e, index)}
      onDragLeave={handleDragLeave}
      className="product-card cursor-for-drag p-r"
      style={{ opacity: dragOpacity }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      key={item?.Vendor_ID}
    >
      {/* {hovered ? (
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: "40%",
          }}
          data-inverted="true"
          data-position="top left"
          data-tooltip={"Drag to change position"}
        >
          <FeatherIcon
            icon="shuffle"
            size="65"
            color={"rgba(85, 112, 241, 0.5)"}
            className="filter-input-field-search-icon"
          />
        </div>
      ) : null} */}
      <VendorHeader boardUserId={props?.boardUserId} keyName={"Vendor_ID"} data={item} cardType={cardType} page={props?.page} section={props?.section} />
      <div className="product-card-content m-b-half-base">
        <VendorContent data={item} cardType={cardType} page={props?.page} section={props?.section} />
        <VendorActions data={item} cardType={cardType} page={props?.page} section={props?.section} />
      </div>
      <MatchingProducts data={item} cardType={cardType} page={props?.page} section={props?.section} />
    </div>
  );
};

export default VendorCard;
