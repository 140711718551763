import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as ENV_KEYS from "../../../../../config/configKeys";
import { GetIdFormLink, detectMob } from "../../../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import HelpDrawer from "./helpDrawer";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useParams } from "react-router";

const OctateHelp = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [hovered, setHovered] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;
  const screen = props?.screen == "mobile" ? true : false;
  const PARAMS = useParams();
  const category = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: category?.Name,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleSideBarClicked = (val) => {
    setShowDrawer(!showDrawer);
    handleSearchFiltersClicked();
  };

  const handleSearchFiltersClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "help",
      Item_Id: null,
      Target: {
        page: signalTrackingProductObject?.page,
        link: null,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: "help drawer opened",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const MOBILE = detectMob();
  return (
    <div className="">
      <div
        onClick={handleSideBarClicked}
        className="navbar__item navbar__item navbar__sub-menu-toggle navbar__mega-menu p-r"
      >
        <div className="navbar__item-content">
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="relative flex align-items-center"
          >
            {screen ? (
              <img
                className="octate-logo"
                width="35px"
                height="35px"
                src={require("../../../../../assets/images/noun-help-1459306-FFFFFF.svg").default}
                alt={"help"}
              ></img>
            ) : hovered ? (
              <img
                className="octate-logo"
                width="35px"
                height="35px"
                src={require("../../../../../assets/images/noun-help-1459306-FFB527.svg").default}
                alt={"help"}
              ></img>
            ) : (
              <img
                className="octate-logo"
                width="35px"
                height="35px"
                src={require("../../../../../assets/images/noun-help-1459306-FFFFFF.svg").default}
                alt={"help"}
              ></img>
            )}
          </div>
        </div>
      </div>
      {showDrawer ? <HelpDrawer handleClose={() => setShowDrawer(false)} visible={showDrawer} /> : null}
      <div className="slide-in-overlay"></div>
    </div>
  );
};

export default OctateHelp;
