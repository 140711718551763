import React from "react";
import FeatherIcon from "feather-icons-react";

const TableContentMoreOptionsList = (props) => {
  return (
    <>
      <div className="share-single-item">
        <div
          className="action-button-container"
          onClick={() => props?.handleActionClicked("preview")}
          style={{ width: "100%", padding: "5px" }}
        >
          <p className="share-desc-title">Preview Proposal</p>
        </div>
      </div>
      <div className="share-single-item">
        <div
          className="action-button-container"
          onClick={() => props?.handleActionClicked("edit")}
          style={{ width: "100%", padding: "5px" }}
        >
          {props?.blurbsPostData?.isLoading ? (
            <p className="share-desc-title">loading...</p>
          ) : (
            <p className="share-desc-title">Edit Proposal</p>
          )}
        </div>
      </div>
    </>
  );
};

export default TableContentMoreOptionsList;
