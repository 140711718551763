import React from "react";
import Switch from "react-switch";
const InputFieldSwitch = (props) => {
  const handleChange = (val) => {
    props?.handleChange(val);
  };
  return (
    <Switch
      offColor="#F1EBFF"
      onColor="#cad3fc"
      offHandleColor="#BBC5CB"
      onHandleColor="#5570F1"
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      height={20}
      width={40}
      className="react-switch"
      onChange={handleChange}
      checked={props?.checked}
    />
  );
};

export default InputFieldSwitch;
