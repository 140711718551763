import React, { useEffect, useState } from "react";
import * as CONFIG_KEYS from "../../../../config/config";
import FeatherIcon from "feather-icons-react";
import ProposalQuestions from "./proposalQuestions";
import _ from "lodash";
const ProposalSection = (props) => {
  const DATA = props?.data;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const questionList = DATA?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length
    ? DATA?.[ADD_QUESTION_KEYS?.QUESTION_LIST]
    : [];
  const proposalDetails = props?.proposalDetails;
  const notificationJson = props?.notificationJson;
  const QuestionAndSectionIndex = props?.QuestionAndSectionIndex;
  // const QuestionAndSectionIndex = {
  //   sectionIndex:
  //     notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID] &&
  //     (notificationJson?.["Object"]?.Action == "Question_Edited" ||
  //       notificationJson?.["Object"]?.Action == "Answer_Edited")
  //       ? notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID]
  //       : notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID] &&
  //         (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
  //           notificationJson?.["Object"]?.Action == "Vendor_Commented")
  //       ? notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID]
  //       : null,
  //   questionIndex:
  //     notificationJson?.["Object"]?.Details?.[ADD_QUESTION_KEYS?.QUESTION_ID] &&
  //     (notificationJson?.["Object"]?.Action == "Question_Edited" ||
  //       notificationJson?.["Object"]?.Action == "Answer_Edited")
  //       ? notificationJson?.["Object"]?.Details?.[
  //           ADD_QUESTION_KEYS?.QUESTION_ID
  //         ]
  //       : notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID] &&
  //         (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
  //           notificationJson?.["Object"]?.Action == "Vendor_Commented")
  //       ? notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID]
  //       : null,
  // };
  const sectionIndex = props?.sectionIndex;
  const selectedQuestion = props?.selectedQuestion;
  const activeIndex = props?.activeIndex;
  const [showSection, setShowSection] = useState(
    sectionIndex == props?.activeIndexNumber ? true : false
  );
  useEffect(() => {
    if (notificationJson?.["Object"]?.Id) {
      handleRunOnlyOnceSec();
    }
  }, []);
  const handleRunOnlyOnceSec = async () => {
    const sectionIndex =
      notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID] &&
      (notificationJson?.["Object"]?.Action == "Question_Edited" ||
        notificationJson?.["Object"]?.Action == "Answer_Edited")
        ? notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID]
        : notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID] &&
          (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
            notificationJson?.["Object"]?.Action == "Vendor_Commented")
        ? notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID]
        : null;
    const proposalQuestions = proposalDetails?.[
      ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION
    ]?.length
      ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
      : [];
    const Find_S_index = _.findIndex(
      proposalQuestions,
      (e) => {
        return e?.[ADD_SECTION_KEYS?.SECTION_ID] == sectionIndex;
      },
      0
    );

    const sect =
      (await sectionIndex) == Find_S_index >= 0
        ? Find_S_index
        : 0
        ? true
        : sectionIndex;
    setShowSection((sec) => sect);
  };
  const handleToggleSection = (val) => {
    setShowSection(!showSection);
  };
  return (
    <div>
      <div className="proposal-submit-page-header-collapse-main-wrapper m-y-base">
        <div className="proposal-submit-page-header-collapse-left-wrapper ">
          <FeatherIcon
            onClick={handleToggleSection}
            icon={showSection ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#130F26"}
            // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
          />
          <h3 className="proposal-submit-page-header-title p-x-mini">
            {DATA?.[ADD_SECTION_KEYS?.SECTION_TITLE]}
          </h3>
        </div>
      </div>
      {showSection ? (
        <>
          {questionList?.map((quest, index) => {
            const ActiveIndex =
              selectedQuestion?.questionIndex == index ? true : false;
            return (
              <ProposalQuestions
                selectedQuestion={selectedQuestion}
                activeIndexNumber={props?.activeIndexNumber}
                proposalDetails={proposalDetails}
                notificationJson={notificationJson}
                data={quest}
                questionIndex={index}
                activeIndex={ActiveIndex}
                activeSectionIndex={activeIndex}
                sectionIndex={sectionIndex}
                handleQuestionClicked={props?.handleQuestionClicked}
              />
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default ProposalSection;
