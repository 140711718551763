import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

const AnswerProposalTab = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const navigate = useNavigate();
  const tabIndex = props?.index;
  const activeIndex = props?.activeTabIndex;
  const tabItem = props?.tabItem;

  return (
    <div className="flex">
      <div
        className={`tab-index-text ${
          tabIndex == activeIndex ? "tab-index-selected" : null
        }`}
        onClick={() => props?.handleActiveTab(tabIndex)}
      >
        {tabItem?.label}
      </div>
    </div>
  );
};

export default AnswerProposalTab;
