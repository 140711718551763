import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedVendorFn } from "../../../contexts/reducer/chatReducer";
import { GetNameInitials } from "../../../utlis/hof/hof";
import { useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../../config/configKeys";
const OctateChatCard = ({ data }) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const LIST_DATA = data;
  const initials = GetNameInitials(LIST_DATA?.Vendor_Name ? LIST_DATA?.Vendor_Name : null);
  const dispatch = useDispatch();
  function formatTime(timeString) {
    const date = new Date(timeString);
    const formattedTime = date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
    return formattedTime.replace(" ", "");
  }
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  function formatDate(epochTime) {
    var date = new Date(epochTime * 1000);

    var currentDate = new Date();
    var differenceInMilliseconds = currentDate - date;

    if (differenceInMilliseconds < 86400000) {
      return formatTime(date)?.toLocaleUpperCase();
    } else if (date.getFullYear() >= currentDate.getFullYear()) {
      return date.toLocaleDateString("default", { month: "short", day: "numeric" });
    } else {
      return date.toLocaleDateString();
    }
  }
  function handleSelectVendorClicked() {
    if (LIST_DATA?.Conversation_ID) {
      dispatch(selectedVendorFn(LIST_DATA));
    } else {
      dispatch(selectedVendorFn({ ...LIST_DATA, Anonymous_Name: false }));
    }
    const signalTrackingPostData = {
      Actor: authData?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: ENV_KEYS.CONFIG.DM_CLICKED,
      Item_Type: "Direct message vendor list",
      Item_Id: LIST_DATA?.Vendor_ID,
      Target: {
        page: null,
        link: null,
      },
      Object: {
        section: "Direct message vendor list",
        context: "Direct message vendor list",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  }
  return (
    <div onClick={handleSelectVendorClicked} className="chat-list-container p-r" key={LIST_DATA?.Conversation_ID}>
      {LIST_DATA?.UnSeenCount && selectedVendorSelector?.Conversation_ID !== LIST_DATA?.Conversation_ID ? (
        <div className="direct-message-count-main-wrapper">
          <p className="direct-message-count-count-text">{LIST_DATA?.UnSeenCount}</p>
        </div>
      ) : null}
      <div class="circular-avatar">
        {LIST_DATA?.Vendor_Logo_URL ? (
          <img src={LIST_DATA?.Vendor_Logo_URL} alt="vendor-image" className="chat-room-header-vendor-image" />
        ) : (
          <div style={{ width: "35px", height: "35px", fontSize: "16px" }} class="user-initials user-initials--primary">
            {initials}
          </div>
        )}
      </div>
      <div
        style={{ flexGrow: "1", width: "100%", alignItems: "center", justifyContent: "flex-start", display: "flex" }}
      >
        <div className="flex space-between">
          <h3 className="chat-list-username">{LIST_DATA?.Vendor_Name}</h3>
          {/* {LIST_DATA?.Date_Time ? <p className="chat-list-date">{formatDate(LIST_DATA?.Date_Time)}</p> : null} */}
        </div>
        {/* <p
          className={`chat-list-description ${LIST_DATA?.Message_Text ? " " : "chat-list-description--no-message"} ${
            LIST_DATA?.UnSeenCount && selectedVendorSelector?.Conversation_ID !== LIST_DATA?.Conversation_ID
              ? "chat-list-description--bold"
              : ""
          }`}
        >
          {LIST_DATA?.Message_Text ? LIST_DATA?.Message_Text : "No messages"}
        </p> */}
      </div>
    </div>
  );
};

export default OctateChatCard;
