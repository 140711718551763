import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";

const AccountType = (props) => {
  // const [ACCOUNT_TYPE, handleToggleAccount] = useState(true);
  const ACCOUNT_TYPE = props?.accountType;

  return (
    <div className="grid-two-columns">
      <div
        className="account-type-box"
        onClick={() => props?.handleToggleAccount(true)}
        style={
          ACCOUNT_TYPE
            ? { color: "#5570F1", borderColor: "#5570F1" }
            : { color: "#060606" }
        }
      >
        {ACCOUNT_TYPE ? (
          <img
            class=" ls-is-cached lazyloaded checkbox-icon"
            data-src={require("../../../../../assets/images/checked-checkbox.png")}
            src={require("../../../../../assets/images/checked-checkbox.png")}
            alt={"Checkbox"}
          />
        ) : null}

        {/* <FeatherIcon
          icon="user"
          size="50"
          color={ACCOUNT_TYPE ? "#ffb527" : "#060606"}
          className="account-type-icons"
        /> */}
        <img
          class=" ls-is-cached lazyloaded"
          data-src={
            ACCOUNT_TYPE
              ? require("../../../../../assets/onboardingSvg/Profile-selected.svg")
                  .default
              : require("../../../../../assets/onboardingSvg/Profile.svg")
                  .default
          }
          src={
            ACCOUNT_TYPE
              ? require("../../../../../assets/onboardingSvg/Profile-selected.svg")
                  .default
              : require("../../../../../assets/onboardingSvg/Profile.svg")
                  .default
          }
          alt={"profile"}
          className="account-type-icons"
        />
        <p className="account-type-text">Buyer Account</p>
      </div>
      <div
        className="account-type-box"
        onClick={() => props?.handleToggleAccount(false)}
        style={
          !ACCOUNT_TYPE
            ? { color: "#5570F1", borderColor: "#5570F1" }
            : { color: "#060606" }
        }
      >
        {!ACCOUNT_TYPE ? (
          <img
            class=" ls-is-cached lazyloaded checkbox-icon"
            data-src={require("../../../../../assets/images/checked-checkbox.png")}
            src={require("../../../../../assets/images/checked-checkbox.png")}
            alt={"Checkbox"}
          />
        ) : null}
        {/* <FeatherIcon
          icon="briefcase"
          size="40"
          color={!ACCOUNT_TYPE ? "#5570F1" : "#060606"}
          className="account-type-icons"
        /> */}
        <img
          class=" ls-is-cached lazyloaded"
          data-src={
            !ACCOUNT_TYPE
              ? require("../../../../../assets/onboardingSvg/Work-selected.svg")
                  .default
              : require("../../../../../assets/onboardingSvg/Work.svg").default
          }
          src={
            !ACCOUNT_TYPE
              ? require("../../../../../assets/onboardingSvg/Work-selected.svg")
                  .default
              : require("../../../../../assets/onboardingSvg/Work.svg").default
          }
          alt={"profile"}
          className="account-type-icons"
        />
        <p className="account-type-text">Vendor Account</p>
      </div>
    </div>
  );
};

export default AccountType;
