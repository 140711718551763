import React, { useState } from "react";
import "../../../styles/new-cards.css";
import NoResult from "../../searchResult/components/noResult";
import * as NO_CONTENT from "../../../config/config";
import VendorCard from "./vendorCard";

const VendorCardList = (props) => {
  const ProductData = props?.data;
  const cardType = "vendor";
  return ProductData?.length > 0 ? (
    ProductData?.map((item, index) => (
      <VendorCard
        page={props?.page}
        handleChangePosition={props?.handleChangePosition}
        cardType={cardType}
        boardUserId={props?.boardUserId}
        item={item}
        index={index}
      />
    ))
  ) : (
    <div className="search__result data-product-card no-content-card">
      <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BOARD_VIEW_VENDOR}</div>
    </div>
  );
};

export default VendorCardList;
