import React from "react";
import * as CONFIG_KEYS from "../../../../../config/config";
import InputFieldProfile from "../../../../profile/editProfile/component/inputFieldProfile";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import "react-datepicker/dist/react-datepicker.css";
const TimelineAndVendorsContent = (props) => {
  const proposalDetails = props?.proposalDetails;
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  return (
    <div className="proposal-submit-timeline-and-vendors-content-main-wrapper">
      <h3 className="proposal-submit-timeline-and-vendors-content-title">
        Provide usage & timeline related information{" "}
      </h3>
      <div
        class="field p-r m-y-company-profile-input-wrapper"
        id="field-user__email"
      >
        {proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE] ? (
          <label
            for="user__email"
            className="company-details-label-input-field-filled-absolute"
          >
            {/* {ADD_PROPOSAL?.PROPOSAL_TITLE} */}
            Select Your Timeline
          </label>
        ) : null}
        <div className="date-field-wrapper-position-relative">
          {/* <div
            id="input-field-wrapper"
            class={`input-field-wrapper ${false ? "background-error" : ""}`}
            style={{ border: "1px solid #EFF1F9" }}
          > */}
          <FeatherIcon
            icon={"calendar"}
            size="17"
            color={"#6E7079"}
            style={{ zIndex: 20, margin: "0px 0px 5px 0px" }}
            className="input-icons"
          />
          <DatePicker
            placeholderText={"Select your Timeline"}
            selected={
              proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                ? proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                : false
            }
            excludeDateIntervals={[
              {
                start: new Date(
                  new Date().setFullYear(new Date().getFullYear() - 50)
                ),
                end: new Date(),
              },
            ]}
            onChange={(val) =>
              props?.handleDetails({
                ...proposalDetails,
                [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]: val.getTime(),
              })
            } //only when value has changed
          />
          {/* {/* </div> */}
        </div>

        {/* <InputFieldProfile
          label={"Select Your Timeline (Optional)"}
          icon={"calendar"}
          value={proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]}
          changeText={(val) =>
            props?.handleDetails({
              ...proposalDetails,
              [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]: val,
            })
          }
          //   error={error?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TITLE]}
        /> */}
      </div>
      <div
        class="field p-r m-y-company-profile-input-wrapper"
        id="field-user__email"
      >
        {proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE] ? (
          <label
            for="user__email"
            className="company-details-label-input-field-filled-absolute"
          >
            {"Number of Licenses"}
          </label>
        ) : null}
        <InputFieldProfile
          removeBottomPadding={true}
          label={"Number of Licenses"}
          type={"number"}
          value={proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]}
          changeText={(val) =>
            props?.handleDetails({
              ...proposalDetails,
              [ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]: val > 0 ? val : "",
            })
          }
          //   error={error?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]}
        />
      </div>
    </div>
  );
};

export default TimelineAndVendorsContent;
