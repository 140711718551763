import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";

const FeatureFilter = (props) => {
  const FEATURE_DATA = props?.data;
  const FEATURE_KEY = props?.keyName;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filterLabel, setFilterLabel] = useState("Show All");
  const DROPDOWN_DATA = [
    {
      id: 1,
      name: "Show All",
      selected: true,
      value: "all",
    },
    {
      id: 2,
      name: "Supported",
      value: true,
    },
    {
      id: 2,
      name: "Not supported",
      value: false,
    },
  ];

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownVisible(false);
    }
  };

  const FilterData = (val) => {
    const FILTER_CONDITION = val?.value;
    setFilterLabel(val?.name);

    let tempData;
    if (FILTER_CONDITION === "all") {
      props?.handleFilterData({ ...FEATURE_DATA });
    } else {
      tempData = FEATURE_DATA?.[FEATURE_KEY]?.filter(
        (item) => item?.Feature_Associated === FILTER_CONDITION
      );
      props?.handleFilterData({ ...FEATURE_DATA, [FEATURE_KEY]: tempData });
    }
    setDropdownVisible(false);
  };
  return (
    <div className="p-r" tabIndex={0} onBlur={handleBlur}>
      {/* <div
        className="feature-filter-button"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <i class="feather icon icon-filter "></i>
      </div> */}

      <FeatherIcon
        icon={"filter"}
        size={20}
        color={"#1371c3"}
        onClick={() => setDropdownVisible(!dropdownVisible)}
        role="button"
      />
      {dropdownVisible && (
        <div className="feature-filter-container">
          {DROPDOWN_DATA?.map((item) => (
            <p
              className="feature-filter-text"
              onClick={() => FilterData(item)}
              key={item?.id}
            >
              {item?.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeatureFilter;
