import React, { useState } from "react";
import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import DropdownFilter from "../../../../../vendorDashboard/component/dropdownFilter/dropdownFilter";
import { useEffect } from "react";
import { dashboardFilterFn } from "../../../../../../contexts/reducer/dashboardReducer";

const LinkedInGraph = ({ selectedPost }) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const dispatch = useDispatch();
  const User_ID = authData?.data?.userId;
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const dashboardFilterDetails = useSelector((state) => state?.dashboardDetails?.dashboardFilter);
  console.log(
    `/getLinkedInPostCountAnalytics?userId=${User_ID}&filter=${dashboardFilterDetails?.[0]?.name}${
      selectedPost?.Product_Expert_Post_ID ? `&postId=${selectedPost?.Product_Expert_Post_ID}` : ""
    }`
  );
  const ProductExpAnalytics = useEnCryptGetApi(
    `/getLinkedInPostCountAnalytics?userId=${User_ID}&filter=${dashboardFilterDetails?.[0]?.name}${
      selectedPost?.Product_Expert_Post_ID ? `&postId=${selectedPost?.Product_Expert_Post_ID}` : ""
    }`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const GraphData = ProductExpAnalytics?.data?.data?.data?.Data;
  const DataSets = GraphData?.length
    ? GraphData.map((set, index) => {
        return {
          label: set?.Label,
          data: set?.Count?.length ? set?.Count : [0, 0, 0, 0, 0, 0, 0],
          borderWidth: 2,
          borderColor:
            index == 1
              ? "rgb(64, 38, 255)"
              : index == 2
              ? "rgb(176, 38, 255)"
              : index == 3
              ? "rgba(255, 136, 38)"
              : index == 4
              ? "rgb(226, 24, 24)"
              : "rgb(254,157,82)",
          backgroundColor:
            index == 1
              ? "rgba(64, 38, 255, 0.5)"
              : index == 2
              ? "rgba(176, 38, 255,0.5)"
              : index == 3
              ? "rgba(255, 136, 38,0.5)"
              : index == 4
              ? "rgba(226, 24, 24, 0.5)"
              : "rgba(254,157,82,0.5)",
        };
      })
    : [
        {
          label: "no Data",
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: "rgb(255, 181, 39)",
          backgroundColor: "rgba(255, 181, 39,0.5)",
        },
      ];

  const GRAPH = {
    labels: ProductExpAnalytics?.data?.data?.data?.Label,
    datasets: DataSets,
  };

  useEffect(() => {
    dispatch(
      dashboardFilterFn([
        {
          name: "All Time",
          id: 1,
          type: "date",
        },
      ])
    );
  }, []);

  return (
    <div className="modern-card p-x-mini p-y-mini">
      <div
        style={{
          width: "100%",
          textAlign: "start",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        className="p-b-sm p-t-mini"
      >
        <h2 className="dashboard-summary-overview-title-header p-x-base ">LinkedIn Metrics</h2>
        <div style={{ marginTop: "5px" }} data-inverted data-tooltip={"Linked In analytics"}>
          <FeatherIcon icon={"info"} size="20" role="button" color={"#5570f1"} />
        </div>
      </div>
      <div className="m-b-sm">
        <DropdownFilter />
      </div>

      <Line options={options} data={GRAPH} />
    </div>
  );
};

export default LinkedInGraph;
