import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../../../../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../../../../utlis/hoc/textArea/textArea";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import SingleSelectNewDropdown from "../../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import _ from "lodash";
import { ReplaceBlankSpaceByHyphen, getDomainFromUrl, validateRequestCustomer } from "../../../../../utlis/hof/hof";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../../../../config/configKeys";
import { addCustomerData, requestCategorySuccess } from "../../../../../contexts/reducer/homeReducer";

const RequestCustomer = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [successMessage, setSuccessMessage] = useState(null);
  const [CaptchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [searchIndustryText, setSearchIndustryText] = useState("a");
  const [popUpVisible, setPopUpVisible] = useState();
  const recaptchaRef = useRef();
  const dispatch = useDispatch();
  const sitekey = "6Lc6rOAhAAAAAPBNLnyVjqm0S0Ziic9e6EPXDSKv";
  const AddCustomer = useEnCryptPostApi(
    `/addNewCustomerIntoCrunchbaseDump`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );

  const StaticData = useEnCryptGetApi(
    `/vendor/vendorOnboardingConfiguration`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data?.config;

  const industryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchIndustryText ? searchIndustryText : "a"}&type=industry`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;

  const handleSubmit = () => {
    const RAW_DATA = {
      ...formDetails,
      Skeleton_Domain: getDomainFromUrl(formDetails?.Domain_URL),
      User_ID: authData?.data?.userId,
      Vendor_Source: "Octate",
    };
    const DISPATCH_DATA = {
      Customer_Domain: formDetails?.Domain_URL,
      Customer_Name: formDetails?.Vendor_Name,
      Skeleton_Domain: getDomainFromUrl(formDetails?.Domain_URL),
    };
    dispatch(addCustomerData(DISPATCH_DATA));
    const VALIDATE = validateRequestCustomer(RAW_DATA);
    setError(VALIDATE);
    if (_.isEmpty(VALIDATE) && CaptchaVerified) {
      AddCustomer?.handleTrigger(RAW_DATA);
    }
  };

  useEffect(() => {
    setPopUpVisible(true);
    setTimeout(() => {
      setPopUpVisible(false);
    }, 3000);
    if (AddCustomer?.data?.status)
      setTimeout(() => {
        HandleCloseModal();
      }, 3000);
  }, [AddCustomer?.data]);

  var handleSearchIndustryWithApi = _.debounce(function (key) {
    setSearchIndustryText(key);
  }, 500);

  const onChange = (value) => {
    setCaptchaVerified(true);
  };

  const HandleCloseModal = () => {
    props?.handleClose();
  };

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper">
          {popUpVisible && AddCustomer?.data ? (
            <div
              style={{
                background: AddCustomer?.data?.status ? "#008000" : "#c00",
              }}
              className="success-message-pop-up"
            >
              <h3 className="success-message-style-board">{AddCustomer?.data?.message}</h3>
            </div>
          ) : null}
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Add Customer</h1>
          </div>
          <div className="request-category-container">
            {/* <div className="m-b-sm p-r">
              <SingleSelectNewDropdown
                handleSelectedData={(val) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    Resource_Name: val,
                  }))
                }
                data={SearchProductCategory?.data?.data}
                placeholder={"Category Name"}
                // flag={true}
                selectedVal={formDetails?.Resource_Name}
                name={"Product_Category_Name"}
                // label={"Category Name"}
                handleSearchWithApi={handleSearchWithApi}
                // handleBlurInput={handleSubmit}
                searchWithApi={true}
                // returnObject={true}
                required={true}
              />
              {AddCustomer?.data?.isVerified ? (
                <span class="input-field-info-text" style={{ color: "#fb574b" }}>
                  Category already available.{" "}
                  <a
                    href={`/category/${ReplaceBlankSpaceByHyphen(
                      AddCustomer?.data?.Product_Category_Name,
                      AddCustomer?.data?.Product_Category_ID
                    )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                    role="button"
                  >
                    {" "}
                    Click here to view
                  </a>
                </span>
              ) : null}
              {error?.Resource_Name ? <span class="input-field-text-error">{error?.Resource_Name}</span> : null}
            </div> */}
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Vendor Name"}
                value={formDetails?.Vendor_Name}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Vendor_Name: val }))}
                error={error?.Vendor_Name}
                removeBottomPadding={true}
                required={true}
              />
            </div>
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Domain URL"}
                value={formDetails?.Domain_URL}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Domain_URL: val }))}
                error={error?.Domain_URL}
                removeBottomPadding={true}
                required={true}
              />
            </div>
            <div className="m-b-sm">
              <TextArea
                value={formDetails?.About}
                className="m-b-xs"
                label={"Description"}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, About: val }))}
                removeMargin={true}
                required={true}
                error={error?.About}
                subTitleTop={"Maximum 500 characters only."}
              />
            </div>
            <div className="m-b-md">
              <InputFieldProfile
                label={"Founded Year"}
                value={formDetails?.Founded}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Founded: val }))}
                error={error?.Founded}
                removeBottomPadding={true}
                required={true}
              />
            </div>
            <div className="m-b-md p-r">
              <SingleSelectNewDropdown
                handleSelectedData={(val) => setFormDetails((prev) => ({ ...prev, Employee_Range: val }))}
                data={StaticDropdownData?.Organization_Size}
                placeholder={"Company Size"}
                required={true}
                // flag={true}
                selectedVal={formDetails?.Employee_Range}
                name={"name"}
                label={"Company Size"}
              />
            </div>
            <div className="m-b-md p-r">
              <SingleSelectNewDropdown
                handleSelectedData={(val) => setFormDetails((prev) => ({ ...prev, Revenue_Range: val }))}
                data={StaticDropdownData?.Revenue_Range}
                placeholder={"Revenue Range"}
                // flag={true}
                selectedVal={formDetails?.Revenue_Range}
                name={"name"}
                label={"Revenue Range"}
              />
            </div>
            <div className="m-b-md p-r">
              <SingleSelectNewDropdown
                handleSelectedData={(val) => setFormDetails((prev) => ({ ...prev, Industry: val }))}
                data={industryDropdownData}
                placeholder={"Industry"}
                // flag={true}
                selectedVal={formDetails?.Industry}
                name={"name"}
                label={"Industry"}
                handleSearchWithApi={handleSearchIndustryWithApi}
                searchWithApi={true}
                returnObject={true}
              />
            </div>

            <div className="flex align-items-center justify-center m-b-xs">
              <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} onChange={onChange} />
            </div>

            <div className="flex justify-flex-end">
              <div className="request-category-submit-button" onClick={() => handleSubmit()}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default RequestCustomer;
