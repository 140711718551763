import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import Dropdown from "../../../utlis/hoc/dropdown/dropdown";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";
import * as CONFIG_KEYS from "../../../config/config";
const ProposalBusinessNeeds = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [searchProductCategoryText, setSearchProductCategoryText] = useState(
    "a"
  );
  const selectedData = props?.proposalBasicDetails[
    ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS
  ]?.length
    ? props?.proposalBasicDetails[ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]
    : [];
  // path: `/getAllTopicForProductCategory?Product_Category_Id=${selectedProductCategory?.Tag_Id}/&searchText=${tagsText?.Name}`,
  // service: KEYS?.CONFIG?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
  // userInfo: {deviceId: DEVICE_INFO, userId: USER_ID},
  //   "https://thebox-dev.portqii.com/proposals/getTopicsOrProductCategory?searchText=Vi"
  const productCategoryDropdownData = useEnCryptGetApi(
    `/getTopicsOrProductCategory?searchText=${searchProductCategoryText}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const selectedTopicValue = props?.productBasicDetails?.tags?.length
    ? props?.productBasicDetails?.tags
    : [];
  const concatIsSelectedCompatible = concatIsSelectedAllData(
    selectedData,
    productCategoryDropdownData,
    "Item_ID"
  );
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.proposalBasicDetails,
      [ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]: val,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };
  const handleSearchWithApi = (key) => {
    setSearchProductCategoryText(key ? key : "a");
  };
  const handleDetailsProductCategory = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: val,
      tags: [],
    });
  };

  return (
    <>
      <div className="m-y-md p-r">
        {props?.proposalBasicDetails?.[ADD_PRODUCT_CONFIG_KEYS?.SOLUTION_NEEDS]
          ?.length > 0 ? (
          <label
            for="user__email"
            className="company-details-label-input-field-filled-absolute"
          >
            {"Select Business Needs"}
          </label>
        ) : null}
        <Dropdown
          data={concatIsSelectedCompatible}
          // handleApplyClicked={handleApplyCountryClicked}
          label={"Select Business Needs"}
          showRightSideItems={true}
          rightSideItemName={"Item_Type"}
          handleSelectedDataOnChange={handleSelectedDataOnChange}
          selectedValue={selectedTopicValue}
          hideSaveAndClear={true}
          searchWithApi={true}
          handleSearchText={handleSearchText}
          name={"Item_Name"}
          Id={"Item_ID"}
        />
      </div>
    </>
  );
};

export default ProposalBusinessNeeds;
