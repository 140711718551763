import React from 'react';
import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';
// import * as KEYS from "../../../../config/config";

const ConfirmDeleteModal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const EDIT_PROFILE = KEYS?.EDIT_PROFILE;

  const handleSubmit = (e) => {
    e.preventDefault();
    props?.handleSubmit();
  };

  return ReactDom.createPortal(
    <>
      <div
        class='ui dimmer modals page visible active portalModal'
        id='deactivate_modal'
        // style={{ visibility: "visible" }}
      >
        <div class='ui modal modal--left-aligned front visible active'>
          <div class='header'>
            <div>Delete</div>
          </div>
          <div class='content m-y-base' style={{ textAlign: 'center' }}>
            <p>Are you sure you want to delete the {props?.type === 'usecase' ? 'case study' : props?.type}?</p>
          </div>
          <form class='dtrd-form--modern dtrd-form' onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
              }}
              class='actions'
            >
              <div class='button outlined deny modern' onClick={() => props?.closeModal()}>
                {EDIT_PROFILE?.CANCEL}
              </div>

              <input type='submit' name='commit' value={'Delete'} class='modern red button' data-disable-with='Close account' />
            </div>
          </form>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  );
};

export default ConfirmDeleteModal;
