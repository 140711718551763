import React from "react";
import FeatherIcon from "feather-icons-react";
const PostYourData = () => {
  return (
    <a className="navbar__item" href="/postings/new">
      <div className="navbar__item-content">
        <div className="relative flex align-items-center">
          <i className="feather icon icon-plus-circle "></i>
          <span className="navbar__item-title">Post your data request</span>
        </div>
      </div>
    </a>
  );
};
export default PostYourData;
