import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../config/config";
import moment from "moment";
import EditProposalPortal from "../editProposalPortal";
import { notificationTargetFn } from "../../../../contexts/reducer/notificationReducer";
import { useNavigate } from "react-router";
import { ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
const ProposalTableContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const navigate = useNavigate();
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL?.PROPOSAL_TABLE;
  const proposalSearchFilters = props?.proposalSearchFilters;
  const [proposalTableData, setProposalData] = useState(props?.proposalTableData ? props?.proposalTableData : []);
  const [proposalTableHeader, setProposalTableHeader] = useState(
    props?.proposalTableHeader ? props?.proposalTableHeader : []
  );
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const notificationJson = props?.notificationJson;
  const dispatch = useDispatch();
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const filterProposalTitle = () => {
    if (proposalSearchFilters?.filterText?.length) {
      const filteredData = props?.proposalTableData?.filter((val) => {
        return val?.[API_KEYS?.PROPOSAL_TITLE]
          ?.toLocaleLowerCase()
          .includes(proposalSearchFilters?.filterText?.toLocaleLowerCase());
      });
      setProposalData(filteredData);
    } else {
      setProposalData(props?.proposalTableData);
    }
  };
  const handleProposalTitleClicked = (val) => {
    // setSelectedProposalId({
    //   showModal: true,
    //   id: val?.[API_KEYS?.PROPOSAL_ID],
    // });
    navigate(
      `/proposal/buyer/${ReplaceBlankSpaceByHyphen(val?.[API_KEYS?.PROPOSAL_TITLE], val?.[API_KEYS?.PROPOSAL_ID])}`
    );
  };
  useEffect(() => {
    filterProposalTitle();
  }, [proposalSearchFilters?.filterText, props?.proposalTableData]);

  useEffect(() => {
    if (notificationJson?.["Object"]?.Id && notificationJson?.["Object"]?.Action == "Vendor_Deleted_Proposal") {
      const filterNotification = proposalTableData
        ?.filter((selectNotification) => {
          return notificationJson?.["Object"]?.Id.includes(selectNotification?.[API_KEYS?.PROPOSAL_ID]);
        })
        .map((mapVal) => {
          return mapVal?.[API_KEYS?.PROPOSAL_ID];
        });
      const proposalToShow = filterNotification?.length > 0 ? filterNotification[0] : null;
      if (proposalToShow) {
        handleProposalTitleClicked({
          [API_KEYS?.PROPOSAL_ID]: proposalToShow,
        });
      } else {
        return;
      }
    } else if (notificationJson?.["Object"]?.Id) {
      const filterNotification = proposalTableData
        ?.filter((selectNotification) => {
          return selectNotification?.[API_KEYS?.PROPOSAL_ID] == notificationJson?.["Object"]?.Id;
        })
        .map((mapVal) => {
          return mapVal?.[API_KEYS?.PROPOSAL_ID];
        });
      const proposalToShow = filterNotification?.length > 0 ? filterNotification[0] : null;
      if (proposalToShow) {
        handleProposalTitleClicked({
          [API_KEYS?.PROPOSAL_ID]: proposalToShow,
        });
      } else {
        return;
      }
    }
  }, [notificationJson, proposalTableData]);
  useEffect(() => {
    if (props?.proposalTableHeader?.length) {
      setProposalTableHeader(props?.proposalTableHeader);
    } else {
      setProposalTableHeader([]);
    }
  }, [props?.proposalTableHeader]);
  const handleInputChange = (val) => {
    const changeIsSelectedData = props?.proposalTableData?.map((list) => {
      if (list?.[API_KEYS?.PROPOSAL_ID] == val?.[API_KEYS?.PROPOSAL_ID]) {
        return val;
      } else {
        return list;
      }
    });
    const checkIsSelectedData = changeIsSelectedData?.filter((value) => {
      return !value?.isSelected;
    });
    if (checkIsSelectedData?.length) {
      let arrayData = [...props?.proposalTableHeader];
      arrayData[0] = { name: "checkbox", id: 7, isSelected: false };
      props?.handleProposalTableHeader(arrayData);
    } else {
      let arrayData = [...props?.proposalTableHeader];
      arrayData[0] = { name: "checkbox", id: 7, isSelected: true };
      props?.handleProposalTableHeader(arrayData);
    }

    props?.handleProposalTableData(changeIsSelectedData);
  };
  const handleInputChangeHeader = () => {
    const headerIsSelected = props?.proposalTableHeader.map((val) => {
      if (val?.name == "checkbox") {
        return { ...val, isSelected: !val?.isSelected };
      } else {
        return val;
      }
    });
    props?.handleProposalTableHeader(headerIsSelected);
    const changeIsSelectedData = props?.proposalTableData?.map((list) => {
      const value = {
        ...list,
        isSelected: headerIsSelected[0]?.isSelected ? true : false,
      };
      return value;
    });
    props?.handleProposalTableData(changeIsSelectedData);
  };
  const handleClose = () => {
    setSelectedProposalId({ id: null, showModal: false });
    dispatch(notificationTargetFn({}));
  };
  // dispatch(notificationTargetFn({}));
  return (
    <>
      {selectedProposalId?.showModal ? (
        <EditProposalPortal
          notificationJson={notificationJson}
          Id={selectedProposalId?.id}
          editProduct={selectedProposalId?.showModal}
          handleClose={handleClose}
        />
      ) : null}
      <table className="add-product-pricing-table-main-wrapper">
        <thead className="add-product-pricing-table-header-wrapper">
          {proposalTableHeader?.map((list) => {
            return (
              <th className="proposal-table-header-sub-wrapper">
                {list?.name == "checkbox" ? (
                  <input
                    type="checkbox"
                    className="checkbox-input-style-proposal"
                    checked={list?.isSelected}
                    onChange={handleInputChangeHeader}
                  ></input>
                ) : (
                  <div className="dis-f-r">
                    <p className="proposal-table-header-text">{list?.name}</p>
                    {/* <img
                      src={
                        require("../../../../assets/images/sort.svg").default
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                      role={"button"}
                      // onClick={() => handleSortTableByColumn(item)}
                    /> */}
                  </div>
                )}
              </th>
            );
          })}
        </thead>
        <tbody style={{ paddingTop: "20px" }}>
          {proposalTableData?.length
            ? proposalTableData?.map((list) => {
                return (
                  <tr
                    className={`${
                      list?.Is_Locked
                        ? "proposal-table-content-row-container--disable table-row-active--error"
                        : "proposal-table-content-row-container"
                    }`}
                  >
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="right center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      className="p-y-base"
                    >
                      <input
                        type="checkbox"
                        className="checkbox-input-style-proposal m-x-base"
                        checked={list?.isSelected}
                        onChange={() =>
                          handleInputChange({
                            ...list,
                            isSelected: !list?.isSelected,
                          })
                        }
                      ></input>
                    </td>
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="top center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      // style={{ cursor: "pointer" }}
                      onClick={() => handleProposalTitleClicked(list)}
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                    >
                      {list?.[API_KEYS?.PROPOSAL_TITLE]}
                    </td>
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="top center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                    >
                      {list?.[API_KEYS?.PROPOSAL_OWNER]}
                    </td>
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="top center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                    >
                      {list?.[API_KEYS?.CREATED_ON]
                        ? moment(list?.[API_KEYS?.CREATED_ON] * 1000).format("DD MMM yyyy")
                        : 0}
                    </td>
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="top center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                    >
                      {list?.[API_KEYS?.PROPOSAL_TIMELINE]
                        ? moment(list?.[API_KEYS?.PROPOSAL_TIMELINE] * 1000).format("DD MMM yyyy")
                        : "Not Selected"}
                      {/* - hh:mm a */}
                    </td>
                    <td
                      data-inverted="true"
                      role="button"
                      data-position="top center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                    >
                      {list?.[API_KEYS?.PARTICIPANTS]}
                    </td>
                    <td
                      className={`${
                        list?.Is_Locked
                          ? "proposal-table-show-all-data--disable  p-r p-y-base"
                          : "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      } `}
                      data-inverted="true"
                      role="button"
                      data-position="left center"
                      data-tooltip={list?.Is_Locked ? `This proposal is being  edited by ${list?.Locked_By}` : null}
                    >
                      <div
                        className={`${
                          list?.[API_KEYS?.STATUS] == "Draft"
                            ? "proposal-draft"
                            : list?.[API_KEYS?.STATUS] == "In Progress"
                            ? "proposal-in-progress"
                            : list?.[API_KEYS?.STATUS] == "Published"
                            ? "proposal-completed"
                            : list?.[API_KEYS?.STATUS] == "Completed"
                            ? "proposal-completed"
                            : list?.[API_KEYS?.STATUS] == "Canceled"
                            ? "proposal-canceled"
                            : "proposal-none"
                        }`}
                      >
                        {list?.[API_KEYS?.STATUS]}
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!proposalTableData?.length ? (
        <div className="no-results-found-proposal-table">
          <h2 className="no-results-found-proposal-table-text">No results found</h2>{" "}
        </div>
      ) : null}
    </>
  );
};

export default ProposalTableContent;
