import React, { useState, useEffect } from "react";
import NewPost from "./newPost";
import { useEnCryptGetApi } from "../../../../../../utlis/customHooks/customHooks";
import { useProductExpGetPostQuery } from "../../../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import LinkedInPostcard from "./linkedInPostcard";
import _ from "lodash";
import PostFilter from "./postFilter";

const ExpertPost = (props) => {
  const [productExpertData, setProductExpertData] = useState([]);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [newPostVisible, setNewPostVisible] = useState(false);
  const handleToggleModal = () => {
    setNewPostVisible((prev) => !prev);
  };

  const ProductExpertPostsData = useEnCryptGetApi(
    `/getLinkedInPosts?userId=${props?.userId ?? authData?.data?.userId}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useProductExpGetPostQuery
  );

  useEffect(() => {
    if (ProductExpertPostsData?.data?.data?.data) setProductExpertData(ProductExpertPostsData?.data?.data?.data);
  }, [ProductExpertPostsData?.data]);

  const handleSortPost = (item, order) => {
    const SORTED_DATA = _.orderBy(ProductExpertPostsData?.data?.data?.data, [item], [order]);
    setProductExpertData(SORTED_DATA);
  };

  return (
    <div className="product-expert-post-container">
      {props?.disableHeader ? null : (
        <div className="flex space-between align-items-center">
          <h3 style={{ margin: "0px" }}>Posts</h3>
          <div className="flex items-center justify-flex-end" style={{ width: "50%" }}>
            <PostFilter handleSortPost={(item, order) => handleSortPost(item, order)} />
            <button className="business-profile-create-new-blue-button" onClick={() => handleToggleModal()}>
              New Post
            </button>
          </div>
        </div>
      )}
      {newPostVisible ? <NewPost handleToggleModal={() => handleToggleModal()} /> : null}
      {productExpertData?.length ? (
        <div className="flex flex-wrap" style={{ gap: "20px", marginTop: "20px" }}>
          {productExpertData?.map((post) => (
            <LinkedInPostcard data={post} />
          ))}
        </div>
      ) : (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">Oops! No post found.</div>
        </div>
      )}
    </div>
  );
};

export default ExpertPost;
