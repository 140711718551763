import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import CategoryDescription from "./categoryDescription";
import Recomondedproducts from "./recomondedproducts";
// import * as KEYS from "../../../../../config/config";
import { PaginatedItems } from "../../../../searchResult/components/pagination/paginationReact";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import ListCount from "../../../../../utlis/hoc/productCard/listCount";
import { useDispatch, useSelector } from "react-redux";
import { type } from "@testing-library/user-event/dist/type";
import SelectedFilterList from "../../../../searchResult/components/filter/selectedFilterList";
import {
  clearTopic,
  removeTopic,
} from "../../../../../contexts/reducer/homeReducer";
import ProductSkeleton from "../../../../searchResult/components/skeleton/productSkeleton";

function SubCategoryPage(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [listCount, setListCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const REF_DROPDOWN = KEYS?.MULTI_SELECT_DROPDOWN;
  const searchText = props?.vendorName;
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const dispatch = useDispatch();
  const topicsData = useSelector((state) => state?.homeDetails?.topicsData);

  const RecommendedProducts = useEnCryptPostApi(
    `/searchByFilter`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    const rawData = {
      filterBy: "Product_Catagories",
      searchText: searchText,
      page: pageNo,
    };
    RecommendedProducts?.handleTrigger(rawData);
  }, [searchText, pageNo]);

  const handleApplyClicked = (val) => {};

  const HandlePageNumber = (val) => {
    window.scrollTo(0, 100);
    setTimeout(() => {
      setPageNo(val);
    }, 100);
    // if (props?.type == "categoryPage") {
    //   props?.handleScrollOnClick("products");
    // }
  };

  const handleRemoveFilterData = (val) => {
    dispatch(removeTopic({ ...val }));
  };

  const handleClearData = (val) => {
    dispatch(clearTopic([]));
  };

  return (
    <div>
      <CategoryDescription page={props?.page} vendorName={props?.vendorName} />
      <div className="landing-page__section-header m-b-none">
        <div className="section-header__content">
          <a>
            <h2 className="landing-page__section-title">
              Recommended {props?.vendorName} Products
            </h2>
          </a>
        </div>
      </div>
      {/* <div class="grid-two-columns">
        <Dropdown
          // send data to multi select dropdown
          data={REF_DROPDOWN}
          //grid-two-columns class="grid-four-columns tablet-landscape-1-col grid-col-gap-xs"
          // style container of multi select drop dropdown
          containerStyle={
            "grid-four-columns tablet-landscape-1-col grid-col-gap-xs"
          }
          // get the selected data from multiselect dropdown
          handleApplyClicked={handleApplyClicked}
          // label for multiselect dropdown
          label={"Filters"}
        />
      </div> */}

      <div className="m-t-xs">
        <div className="item-search-rendered__filter-controls">
          {/* <div className="item-search-rendered__count">
            {listCount}{" "}
            {KEYS?.CATEGORY_PAGE.SUB_CAT_PAGE.LIST_COUNT_RLT_LBL}
          </div> */}
          <ListCount
            page={`${props?.page}-${pageNo}`}
            pageNo={pageNo}
            type={PRODUCTS}
            totalNumber={RecommendedProducts?.data?.total}
          />
        </div>
        <SelectedFilterList
          page={`${props?.page}-${pageNo}`}
          data={topicsData}
          handleRemoveFilterData={handleRemoveFilterData}
          handleClearData={handleClearData}
        />

        {RecommendedProducts?.isLoading || RecommendedProducts?.isFetching ? (
          <ProductSkeleton page={props?.page} />
        ) : (
          <Recomondedproducts
            page={`${props?.page}-${pageNo}`}
            data={RecommendedProducts?.data?.data}
            handleTopicPressed={props?.handleTopicPressed}
          />
        )}
      </div>

      {/* <div className="text-center">
        <a className="button primary outlined small transparent m-t-md" href="">
          {KEYS?.CATEGORY_PAGE.SHOW_ALL_BTN_LBL}
        </a>
      </div> */}
      <PaginatedItems
        page={pageNo}
        totalNumber={RecommendedProducts?.data?.total}
        itemsPerPage={10}
        HandlePageNumber={HandlePageNumber}
      />
    </div>
  );
}

export default SubCategoryPage;
