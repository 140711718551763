import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import _ from "lodash";
import { ReplaceBlankSpaceByHyphen, validateRequestVendor } from "../../../../utlis/hof/hof";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../../../config/configKeys";
import { requestCategorySuccess } from "../../../../contexts/reducer/homeReducer";

const RequestVendor = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [successMessage, setSuccessMessage] = useState(null);
  const [CaptchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const sitekey = "6Lc6rOAhAAAAAPBNLnyVjqm0S0Ziic9e6EPXDSKv";
  const RequestVendor = useEnCryptPostApi(
    `/addVendorRequest`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleSubmit = () => {
    setSuccessMessage((prev) => !prev);
    const RAW_DATA = {
      ...formDetails,
      Resource_Name: formDetails?.Resource_Name ? formDetails?.Resource_Name : searchText,
      Requested_By: authData?.data?.userId,
      Notification_Email: authData?.data?.userExist ? authData?.data?.emailId : formDetails?.Notification_Email,
      Resource_Type: "Vendor",
    };
    const VALIDATE = validateRequestVendor(RAW_DATA);
    setError(VALIDATE);
    if (_.isEmpty(VALIDATE) && CaptchaVerified) {
      const TRIGGER_DATA = {
        Resource_Name: formDetails?.Resource_Name ? formDetails?.Resource_Name : searchText,
        Resource_Type: "Vendor",
        Requested_By: authData?.data?.userId,
        Notification_Email: authData?.data?.userExist ? authData?.data?.emailId : formDetails?.Notification_Email,
        Additional_Information: { ...RAW_DATA },
      };
      RequestVendor?.handleTrigger(TRIGGER_DATA);
    }
  };

  useEffect(() => {
    if (RequestVendor?.data?.Status || RequestVendor?.data?.isVerified || RequestVendor?.data?.isVerified === false)
      setTimeout(() => {
        HandleCloseModal();
      }, 5000);
  }, [RequestVendor?.data]);

  var handleSearchWithApi = _.debounce(function (key) {
    setSearchText(key);
  }, 500);

  const onChange = (value) => {
    setCaptchaVerified(true);
  };

  const HandleCloseModal = () => {
    props?.handleClose();
  };

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper">
          {RequestVendor?.data ? (
            <div
              style={{
                background: RequestVendor?.data?.Status ? "#008000" : "#c00",
              }}
              className="success-message-pop-up"
            >
              <h3 className="success-message-style-board">{RequestVendor?.data?.Message}</h3>
            </div>
          ) : null}
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Request Vendor</h1>
          </div>
          <div className="request-category-container">
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Vendor name"}
                value={formDetails?.Resource_Name}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Resource_Name: val }))}
                error={error?.Resource_Name}
                removeBottomPadding={true}
                // infoText={true}
                required={true}
              />
            </div>
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Domain url"}
                value={formDetails?.Domain_URL}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Domain_URL: val }))}
                error={error?.Domain_URL}
                removeBottomPadding={true}
                // infoText={true}
                required={true}
              />
            </div>
            <div className="m-b-sm">
              <InputFieldProfile
                label={"Vendor email"}
                value={formDetails?.Vendor_Email}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Vendor_Email: val }))}
                error={error?.Vendor_Email}
                removeBottomPadding={true}
                // infoText={true}
              />
            </div>
            {authData?.data?.userExist ? null : (
              <div className="m-b-sm">
                <InputFieldProfile
                  label={"Notification email"}
                  value={formDetails?.Notification_Email}
                  changeText={(val) => setFormDetails((prev) => ({ ...prev, Notification_Email: val }))}
                  error={error?.Notification_Email}
                  removeBottomPadding={true}
                  // infoText={true}
                />
              </div>
            )}
            <div className="m-b-md">
              <TextArea
                value={formDetails?.Description}
                className="m-b-xs"
                label={"Additional information"}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Description: val }))}
                removeMargin={true}
                error={error?.Description}
                subTitleTop={"Maximum 500 characters only."}
              />
            </div>

            <div className="flex align-items-center justify-center m-b-xs">
              <ReCAPTCHA sitekey={sitekey} onChange={onChange} />
            </div>

            <div className="flex justify-flex-end">
              <div className="request-category-submit-button" onClick={() => handleSubmit()}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default RequestVendor;
