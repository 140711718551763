import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import parse from "html-react-parser";
const VendorFaq = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleVendor, setToggleVendor] = useState(false);
  const DATA = props?.data;
  function handleToggleAnswer() {
    setToggleVendor(!toggleVendor);
  }
  return (
    <div style={{marginBottom: "15px" }} class="dtrd-faq__qa-wrapper" key={DATA?.Octate_FAQ_ID}>
      <div onClick={handleToggleAnswer} className="flex space-between  cursor--pointer  m-y-mini">
        <div
          style={{ fontSize: "15px", textDecoration: "none", cursor: "pointer" }}
          class="dtrd-faq__question-octae-faq p-b-base"
        >
          {DATA?.Question}
        </div>
        <div>
          <FeatherIcon
            icon={toggleVendor ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#060606"}
          />
        </div>
      </div>
      {toggleVendor ? (
        <div class="dtrd-faq__question-octae-faq" style={{ textDecoration: "none",fontSize: "13px",fontWeight:'400' }}>
          {parse(DATA?.Answer)}
        </div>
      ) : null}
    </div>
  );
};

export default VendorFaq;
