import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import { concatIsSelectedAllData } from "../../../../../utlis/hof/hof";

const SelectProduct = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const [searchProductCategoryText, setSearchProductCategoryText] =
    useState("");
  const error = props?.error;
  const KEY_NAME = props?.keyName ? props?.keyName : "Product_ID";

  const GetProductFormVendorIdApi = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${authData?.data?.vendorId}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;

  const selectedTopicValue = props?.productBasicDetails?.length
    ? props?.productBasicDetails
    : [];

  const concatIsSelectedCompatible = concatIsSelectedAllData(
    selectedTopicValue,
    GetProductFormVendorIdApi,
    "Product_ID"
  );

  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      [KEY_NAME]: val,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };

  return (
    <div className="m-y-xs p-r" style={{ width: "100%" }}>
      {props?.productBasicDetails?.[KEY_NAME]?.length ? (
        <label
          for="user__email"
          className="company-details-label-input-field-filled-absolute"
        >
          {"Products"}
        </label>
      ) : null}
      <Dropdown
        data={concatIsSelectedCompatible}
        // handleApplyClicked={handleApplyCountryClicked}
        label={"Select Products"}
        handleSelectedDataOnChange={handleSelectedDataOnChange}
        selectedValue={selectedTopicValue}
        hideSaveAndClear={true}
        searchWithApi={false}
        handleSearchText={handleSearchText}
        name={"Product_Name"}
        Id={"Product_ID"}
        error={error?.[KEY_NAME]}
        filterText={"Search Products"}
        selectedSmall={true}
      />
    </div>
  );
};

export default SelectProduct;
