import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GetNameInitials } from "../../../../../utlis/hof/hof";
import NavItems from "../navItems/navItems";
const ProfileDesktop = (props) => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state?.authDetails?.details);

  const initials = GetNameInitials(
    authData?.data?.userName
      ? authData?.data?.userName
      : authData?.data?.firstName + " " + authData?.data?.lastName
  );

  return (
    <>
      <a className="navbar__item" role="button">
        <div className="navbar__item-content">
          <NavItems />
          <div
            className="dtrd-navbar__avatar-box"
            onClick={() => navigate("/profile")}
          >
            {authData?.data?.userName || authData?.data?.firstName ? (
              <div class="dtrd-navbar__avatar-box navbar__user">
                <div class="circular-avatar">
                  <div class="user-initials user-initials--primary">
                    {initials}
                  </div>
                </div>
              </div>
            ) : (
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8332 14.6667V13.1111C13.8332 12.286 13.5522 11.4947 13.0521 10.9112C12.552 10.3278 11.8737 10 11.1665 10H5.83317C5.12593 10 4.44765 10.3278 3.94755 10.9112C3.44746 11.4947 3.1665 12.286 3.1665 13.1111V14.6667"
                  stroke="#1371C3"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M8.50016 7.33333C9.97292 7.33333 11.1668 6.13943 11.1668 4.66667C11.1668 3.19391 9.97292 2 8.50016 2C7.0274 2 5.8335 3.19391 5.8335 4.66667C5.8335 6.13943 7.0274 7.33333 8.50016 7.33333Z"
                  stroke="#1371C3"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            )}
          </div>
          <div className="tablet-hidden">
            <div
              className="fw-400"
              onClick={() => navigate("/profile")}
              style={{ color: "#fff" }}
            >
              {authData?.data?.userName
                ? authData?.data?.userName
                : authData?.data?.firstName
                ? authData?.data?.firstName
                : "Sign In"}
            </div>
            {/* <div className="fw-600">My account</div> */}
          </div>
        </div>
      </a>
    </>
  );
};
export default ProfileDesktop;
