import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import ChangeRoles from "../changeRoles";
import { useSelector } from "react-redux";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import { useEffect } from "react";
import * as CONFIG from "../../../../../../config/config";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { usePostRequestCrudMutation } from "../../../../../../adapters/xhr/crudServiceApi";
import * as ENV_KEYS from "../../../../../../config/configKeys";
const AddUserTable = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const [error, setError] = useState({});
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [emailAddress, setEmailAddress] = useState(props?.enteredEmail ?? []);
  const [userPermissions, setUserPermissions] = useState([]);
  const [inputMail, setInputEmail] = useState("");
  const [maximumLimit, setMaximumLimit] = useState(props?.licenseCount);
  const ADD_USER = CONFIG?.CONFIG?.LICENSE;

  function handleApplyClicked(val) {
    setUserPermissions(val);
  }

  const LicenseUpdate = useEnCryptPostApi(
    `/groupSendInvite`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestCrudMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (LicenseUpdate?.data?.status) {
      props?.handleCloseModal();
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.LICENSE_ADDED,
        Item_Type: null,
        Item_Id: null,
        User_Type: "Known",
        Persona: "Business user",
        Target: {
          page: null,
          link: null,
          id: LicenseUpdate?.data?.userIds,
          vendorId: authData?.data?.vendorId,
        },
        Object: {
          section: null,
          context: `License updated`,
          page: "/business-profile/teams",
          Persona: "Business user",
          licenseCount: maximumLimit,
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [LicenseUpdate]);

  const handleAddEmailAddress = (val) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const ALREADY_EXIST = emailAddress?.some((item) => item?.Email_Address === inputMail);
    if (!emailRegex.test(inputMail)) {
      setError({ inputMail: "Please enter valid email address" });
      setTimeout(() => {
        setError({});
      }, 2000);
    } else if (!userPermissions?.Permission_Set_ID) {
      setError({ inputMail: "Please select a role" });
    } else if (maximumLimit === emailAddress?.length) {
      setError({ inputMail: "Maximum limit reached" });
      setTimeout(() => {
        setError({});
      }, 2000);
    } else if (ALREADY_EXIST) {
      setError({ inputMail: "Email already added" });
      setTimeout(() => {
        setError({});
      }, 2000);
    } else {
      const tempData = {
        id: emailAddress?.length + 1,
        Invited_By: authData?.data?.userId,
        Email_Address: inputMail,
        Vendor_Name: authData?.data?.vendorName,
        Vendor_ID: authData?.data?.vendorId,
        senderEmailAddress: authData?.data?.emailId,
        Permission_Set_ID: userPermissions?.Permission_Set_ID,
        Permission_Set_Name: userPermissions?.Permission_Set_Name,
      };
      setEmailAddress((prev) => [...prev, tempData]);
      setInputEmail("");
    }
  };

  const handleGroupSubmitUser = () => {
    if (privacyPolicyCheckbox) {
      const RAW_DATA = {
        Vendor_ID: authData?.data?.vendorId,
        Count: maximumLimit,
        groupUsers: emailAddress,
      };
      LicenseUpdate?.handleTrigger(RAW_DATA);
    }
  };

  const handleGoBack = () => {
    props?.handleViewPreviousStep(emailAddress);
  };

  const handleRemoveUser = (val) => {
    setEmailAddress((prev) => prev.filter((item) => item?.Email_Address !== val?.Email_Address));
  };

  return (
    <div style={{ paddingBottom: "35px", minHeight: "430px" }}>
      <div className="add-to-board-container-header-wrapper m-b-mini">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => props?.handleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <div className="flex-container m-b-base">
          <FeatherIcon size={20} icon={"arrow-left"} color={"37393f"} onClick={() => handleGoBack()} role={"button"} />
          <h1 className="choose-license-header-text">{ADD_USER?.INVITE_USER}</h1>
        </div>
        <div
          className="p-r"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            gap: "10px",
            flexWrap: isMobile ? "wrap" : "nowrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <input
              placeholder="name@example.com"
              className="user-invite-input-field"
              value={inputMail}
              onChange={(val) => setInputEmail(val.target.value)}
            />
            {error?.inputMail ? (
              <p className="input-field-info-text" style={{ color: "#c00" }}>
                {" "}
                {error?.inputMail}
              </p>
            ) : (
              <p className="input-field-info-text">{ADD_USER?.INFO_TEXT}</p>
            )}
          </div>
          <ChangeRoles autoWidth={true} handleApplyClicked={handleApplyClicked} invite={true} />
          <button className="user-invite-button" onClick={() => handleAddEmailAddress()}>
            Add user
          </button>
        </div>
      </div>
      <table className="user-management-table">
        <thead className="border-table">
          <th className="table-header-text" style={{ height: "auto" }}>
            Sl.No
          </th>
          <th className="table-header-text">Role</th>
          <th className="table-header-text">Email Address</th>
          <th className="table-header-text"></th>
        </thead>

        {emailAddress?.map((item,index) => {
          return (
            <tr>
              <td className="table-body-text">{index+1}</td>
              <td className="table-body-text">{item?.Permission_Set_Name}</td>
              <td className="table-body-text">{item?.Email_Address}</td>
              <td role="button" onClick={() => handleRemoveUser(item)}>
                <FeatherIcon icon={"x"} size={"20"} />
              </td>
            </tr>
          );
        })}
      </table>
      <div className="business-profile-description m-t-md">
        {" "}
        Of total {maximumLimit}, you have {maximumLimit - emailAddress?.length} user-licenses left.
      </div>

      <div className="invite-terms-submit-footer-container">
        <div>
          <div className="flex align-items-center m-t-mini">
            <input
              type="checkbox"
              className="checkbox-terms-of-service"
              checked={privacyPolicyCheckbox}
              onClick={() => setPrivacyPolicyCheckbox(!privacyPolicyCheckbox)}
            />
            <p className="terms-of-service-text">
              {ADD_USER?.TERMS_START}{" "}
              <a
                role={"button"}
                onClick={() => window.open("https://business.octate.ai/2023/06/05/vendor-terms-conditions/")}
              >
                {ADD_USER?.TERMS_SERVICE}{" "}
              </a>
              {" & "}
              <a
                role="button"
                onClick={() => window.open("https://business.octate.ai/2023/06/05/cancellations-refunds-policy/")}
              >
                {ADD_USER?.REFUND_POLICY}
              </a>
            </p>
          </div>
        </div>

        <div>
          <div
            className="request-category-submit-button m-l-mini"
            onClick={() => handleGroupSubmitUser()}
            style={{ minWidth: "70px", textAlign: "center" }}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserTable;
