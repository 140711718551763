import React, { memo, useEffect, useState } from "react";
import CategoryList from "./component/categoryList";
import FeatherIcon from "feather-icons-react";
// import * as KEYS from "../../../../../config/config";
import { EncryptApiGetData } from "../../../../../utlis/hof/hof";
import { useGetAllProductCategoryQuery } from "../../../../../adapters/xhr/searchService";
import { useSelector } from "react-redux";
const ExploreCategoryDesktop = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [enCryptProductCategory, setEnCryptProductCategory] = useState("");
  useEffect(() => {
    const enCryptData = {
      // https://thebox-dev.portqii.com/search_backend/getAllProductCategory
      path: `/getAllProductCategory`,
      service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
      userInfo: { deviceId: "ffbe445", userId: 234 },
    };
    const enCryptProductCategory = EncryptApiGetData(enCryptData);
    setEnCryptProductCategory(enCryptProductCategory);
  }, []);

  const {
    data: allProductCategoryData,
    isError: allProductCategoryIsError,
    isLoading: allProductCategoryIsLoading,
  } = useGetAllProductCategoryQuery(
    { enCryptProductCategory },
    { skip: enCryptProductCategory?.data ? false : true }
  );
  return (
    <div
      className="navbar__item navbar__sub-menu-toggle navbar__mega-menu"
      data-controller="sub-menu-toggle"
      // style={{ marginLeft: "0px" }}
    >
      <div className="navbar__item-content">
        <div className="relative flex align-items-center">
          {/* <i className="feather icon icon-align-left "></i> */}
          {/* <FeatherIcon icon="align-left" className="feather icon" /> */}
          <span className="navbar__item-title">{KEYS?.EXPLORE_CATEGORIES.LABEL}</span>
        </div>
      </div>
      {allProductCategoryIsError ||
      !enCryptProductCategory ? null : allProductCategoryIsLoading ? null : allProductCategoryData?.data ? (
        <CategoryList page={props?.page} productCategoryList={allProductCategoryData} />
      ) : null}
    </div>
  );
};
export default ExploreCategoryDesktop;
