import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useScrollPosition from '../../utlis/customHooks/useScrollPosition';
import StickyTab from '../../utlis/hoc/tabNavigator/stickyTab';
import { GetIdFormLink } from '../../utlis/hof/hof';
import Header from '../home/header/header';
import SearchHeaderBreadCrumb from '../searchResult/components/seachHeader/searchHeaderBreadCrumb';
import BoardHeader from './components/boardHeader/BoardHeader';
import BoardBlurbTab from './components/tabs/boardBlurbTab';
import BoardProductTab from './components/tabs/boardProductTab';
import BoardVendorTab from './components/tabs/boardVendorTab';
import BoardUsecaseTab from './components/tabs/boardUsecaseTab';

const BoardDetail = (props) => {
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const [productData, setProductData] = useState('');
  const scrollPosition = useScrollPosition();
  const TYPE = PARAMS?.type;
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll('-', ' '));
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  //   const GetApiData = useEnCryptGetApi(
  //     `/getNavigationPath?product_ID=${PRODUCT?.Id}`,
  //     KEYS?.END_POINTS?.CORE?.END_POINT_URL,
  //     useGetRequestQuery
  //   );

  const VENDOR_TAB = {
    label: 'Vendors',
    value: 'vendors',
    display: true,
  };

  const BOOKMARK_PAGE_TAB = [
    {
      label: 'Blurbs',
      value: 'blurbs',
      display: true,
    },
    {
      label: 'Products',
      value: 'products',
      display: true,
    },
    {
      label: 'Case Studies',
      value: 'usecases',
      display: true,
    },
    TYPE === 'bookmark' ? { ...VENDOR_TAB } : {},
  ];

  const BREAD_CRUMB_BOARDS = [
    { name: 'Home', active: true, link: '/' },
    { name: 'My Account', active: true, link: '/profile' },
    { name: 'My Boards', active: true, link: '/user-boards' },
    PARAMS.type == 'bookmark' ? { name: 'My Bookmarks', active: false } : { name: 'Likes', active: false },
  ];

  return (
    <>
      <Header show={true} />
      <div className='attached-navbar m-b-md'>
        <div className='fixed-nav-container flex space-between align-items-bottom'>
          <SearchHeaderBreadCrumb parent={'boards'} data={BREAD_CRUMB_BOARDS} />
        </div>{' '}
      </div>
      <div className='business-profile-landing-page-container'>
        <BoardHeader
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          parent={'productPage'}
          section={'productPage'}
          handleProductData={(val) => setProductData(val)}
          boardTab={BOOKMARK_PAGE_TAB}
          page={props?.page}
        />
        <StickyTab
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          tabData={BOOKMARK_PAGE_TAB}
          scrollPosition={scrollPosition}
          navigatePath={'board-detail'}
          page={props?.page}
        />
        <div className='grid-business-profile m-t-sm mobile-1-col'>
          {tabIndex == 'blurbs' && <BoardBlurbTab page={props?.page} />}
          {tabIndex == 'products' && <BoardProductTab page={props?.page} />}
          {tabIndex == 'vendors' && <BoardVendorTab page={props?.page} />}
          {tabIndex == 'usecases' && <BoardUsecaseTab page={props?.page} />}

          <div className='mobile-hidden'></div>
        </div>
      </div>
    </>
  );
};

export default BoardDetail;
