import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../../../adapters/xhr/generalApi";
// import * as KEYS from "../../../../config/config";
import {
  useEnCryptGetApi,
  useEnCryptSignalTrackingApi,
} from "../../../../utlis/customHooks/customHooks";
// import BlurbCard from "../../../../utlis/hoc/blurbCardSmall/blurbCard";
import BlurbCardLarge from "../../../../utlis/hoc/blurbCard/blurbCard";
import NewBlurbCard from "../../../../utlis/hoc/newBlurbCard/newBlurbCard";
import {
  EncryptId,
  GetIdFormLink,
  ReplaceBlankSpaceByHyphen,
} from "../../../../utlis/hof/hof";
function Blurbs(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const vendorName = PARAMS?.vendor;
  const USER_ID = authData?.data?.userId;
  const productId = props?.productId;
  const signalTrackingBlurbObject = {
    Action: `${KEYS?.PROVIDER_BLURBS.MORE} clicked`,
    page: "blurb page-product",
    section: "related blurbs",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewAllBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      
      Target: {
        page: signalTrackingBlurbObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: productId?.Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getVendorBlurbsForVendorPage?vendorName=Cashbook
    // https://thebox-dev.portqii.com/search_backend/getProductRelatedBlurbsOfVendors?productId=125
    `/getProductRelatedBlurbsOfVendors?productId=${productId?.Id}&limit=10&offset=1&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const providerBlurbs = GetApiData?.data?.data;
  return providerBlurbs?.length ? (
    <section className="content-section">
      <h2 className="header">
        {KEYS?.PRODUCT_PAGE.BLURB_PAGE.BLURB_PAGE_HEADER}{" "}
      </h2>
      <div className="tablet-1-col">
        {providerBlurbs?.length ? (
          // providerBlurbs.map((products) => {
          //     return (
          //       <BlurbCardLarge
          //         page={props?.page}
          //         section={"blurbs related to product"}
          //         data={products}
          //         parent={props?.parent}
          //       />
          //     );
          //   })
          <NewBlurbCard
            page={props?.page}
            section={"blurbs related to product"}
            data={providerBlurbs}
            parent={props?.parent}
          />
        ) : null}
      </div>
      {/* {providerBlurbs?.length >= 6 ? (
        <a
          class="display-block m-t-sm text-center"
          onClick={() =>
            handleViewAllBlurbLinkClicked(
              `/${ReplaceBlankSpaceByHyphen(
                vendorName
              )}/${ReplaceBlankSpaceByHyphen(
                props?.productName
              )}/blurbs/${EncryptId(productId?.Id)}`
            )
          }
          href={`/${ReplaceBlankSpaceByHyphen(
            vendorName
          )}/${ReplaceBlankSpaceByHyphen(
            props?.productName
          )}/blurbs/${EncryptId(productId?.Id)}`}
        >
          {KEYS?.PROVIDER_BLURBS.MORE}
        </a>
      ) : null} */}
    </section>
  ) : null;
}

export default Blurbs;
