import React from "react";
import { useSelector } from "react-redux";
import { useDeleteRequestImageMutation, usePostRequestImageMutation } from "../../../adapters/xhr/generalApi";
import { useImageUploadApi } from "../../../utlis/customHooks/customHooks";
const CompanyProfileImage = (props) => {
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const permissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  // const [previewUrl, setPreviewUrl] = useState("");
  const AVATAR = KEYS?.EDIT_PROFILE;
  const DELETE_SVG = "../../../assets/images/deleteImage.svg";
  const UPLOAD_SVG = "../../../assets/images/upLoadImage.svg";

  const profileImageUpload = useImageUploadApi(usePostRequestImageMutation);
  const profileImageDelete = useImageUploadApi(useDeleteRequestImageMutation);
  const previewUrl = props?.image?.Vendor_Logo_URL;
  // updateUserProfile
  const handleChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      props?.handleDetails({
        ...props?.image,
        Vendor_Logo_URL: URL.createObjectURL(selectedFile),
      });
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("userId", authData?.data?.userId);
      formData.append("vendorId", superAdminStatus ? permissionsDetails?.vendorId : authData?.data?.vendorId);
      formData.append("type", "companyIcon");
      if (authData?.data?.vendorId) profileImageUpload?.handleTrigger(formData);
      else props?.handleSelectedImage(selectedFile);
    } else {
      // setIsValid(false);
    }
  };
  const handleDelete = () => {
    props?.handleDetails({
      ...props?.image,
      Vendor_Logo_URL: null,
    });
    if (authData?.data?.vendorId?.length)
      profileImageDelete?.handleTrigger({
        type: "companyIcon",
        userId: authData?.data?.userId,
        vendorId: authData?.data?.vendorId,
      });
  };
  return (
    <div class="dtrd-form--modern dtrd-form m-t-md">
      <div class="field horizontal-center m-b-none">
        <div class="inline-block no-line-height m-b-none">
          <div className="user-profile-picture-wrapper">
            <div className="user-profile-picture-options-icons">
              <label htmlFor="file-upload">
                <div className="user-profile-picture-options-icons-update">
                  <img
                    className="octate-logo "
                    width="25px"
                    height="25px"
                    src={require("../../../assets/images/upLoadImage.svg").default}
                    alt={"profile image"}
                  ></img>
                </div>
              </label>

              <input
                id="file-upload"
                type={"file"}
                onChange={handleChange}
                accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                style={{ display: "none" }}
              />
            </div>
            <div onClick={handleDelete} className="user-profile-picture-options-icons-delete">
              <img
                className="octate-logo "
                width="25px"
                height="25px"
                src={require("../../../assets/images/deleteImage.svg").default}
                alt={"profile image"}
              />
            </div>
            {previewUrl ? (
              <img
                className="octate-logo "
                width="170px"
                height="170px"
                src={previewUrl}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = require("../../../assets/images/deleteImage.svg");
                }}
                alt={"profile image"}
                style={{ objectFit: "contain" }}
              />
            ) : (
              <img
                className="octate-logo"
                width="170px"
                height="170px"
                src={require("../../../assets/images/Vendor_Placeholder_Icon.svg").default}
                alt={"profile image"}
                style={{ objectFit: "contain" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileImage;
