import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useGetNotificationQuery, useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import NotificationList from "../../../../notifications/component/notificationList";

const NotificationDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const socket = useSelector((state) => state?.homeDetails?.socket);
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  const userId = authData?.data?.userId;
  const [count, setCount] = useState(0);

  useEffect(() => {
    setShowDrawer(props?.visible);
  }, [props?.visible]);

  const handleNotificationRed = () => {
    setTimeout(() => {
      props?.handleClose();
    }, 200);
  };
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getUnreadNotification?user_Id=${userId}`,
    "notifications",
    useGetNotificationQuery,
    userId ? false : true
  );
  // ("http://thebox-dev.portqii.com/",{path:"/notifications/socket.io"});
  function handler(data) {
    setCount(JSON.parse(data)?.count ? JSON.parse(data)?.count : 0);
  }
  useEffect(() => {
    if (socket?.connected) {
      socket.on(userId, handler);
      return () => socket.off(userId, handler);
    }
  }, [socket]);

  const handleCloseModal = () => {
    setTimeout(() => {
      setVisible(false);
      props?.handleClose();
    }, 200);
  };
  return (
    <div
      className={`header-drawer-container ${showDrawer ? "show-drawer" : "hide-drawer"}`}
      onClick={() => handleCloseModal()}
    >
      <div className={`header-drawer-view-container ${showDrawer ? "slideIn-drawer" : "slideOut-drawer"}`}>
        <div className="header-drawer-view-sub-container">
          <div className="flex-container space-between m-b-mini">
            <h1 className="header-drawer-title">Notification</h1>
            <FeatherIcon icon={"x"} size={"30"} color={"#37393f"} role="button" onClick={() => props?.handleClose()} />
          </div>
          <NotificationList
            count={count}
            handleNotificationRed={() => handleNotificationRed()}
            Data={GetProductDataFromApi?.data?.data}
            isLoading={GetProductDataFromApi?.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationDrawer;
