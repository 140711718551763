import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import CreateNewVendorPortal from "../../../auth/joinOraganization/components/createNewVendorPortal";
import * as CONFIG_KEYS from "../../../../config/config";
import { Navigate, useNavigate } from "react-router";
import ShowMoreText from "react-show-more-text";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import ReadMore from "../businessOverview/ReadMore";
import { selectedVendorFn } from "../../../../contexts/reducer/chatReducer";
const ProposalOverview = (props) => {
  const [showModal, setShowModal] = useState(false);
  const connectWithOctate = props?.connectWithOctate;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const authData = useSelector((state) => state?.authDetails?.details);
  const dispatch = useDispatch();
  const vendorId = authData?.data?.vendorId;
  const BUSINESS_DETAILS = KEYS?.BUSINESS_LANDING_PAGE_OVERVIEW;
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const OVERVIEW_FOR_BUYER = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE?.OVERVIEW;
  const activeRowProposal = props?.activeRowProposal?.[PROPOSAL_KEYS?.PROPOSAL_ID];
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getVendorProposal?Proposal_ID=${activeRowProposal}&Vendor_ID=${vendorId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );
  const PROPOSAL_OVERVIEW = GetProductDataFromApi?.data?.data;
  // const PROPOSAL_OVERVIEW = {
  //   [PROPOSAL_KEYS?.PROPOSAL_TITLE]: "New Proposal 1",
  //   [PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION]: "Quis necessitatibus placeat nobis aut illum qui. Nulla tenetur error. Et autem mollitia non nobis nemo beatae minus atque. Id reiciendis ut ea aut rerum. Itaque deserunt quasi consectetur delectus qui. Accusantium veritatis quisquam cum.",
  //   [PROPOSAL_KEYS?.BUSINESS_DOMAIN]: "Automation marketing",
  //   [PROPOSAL_KEYS?.NUMBER_OF_LICENSE]: "24",
  //   [PROPOSAL_KEYS?.BUSINESS_SIZE]: "Large Scale",
  //   [PROPOSAL_KEYS?.SOLUTION_NEEDS]: "Data Security",
  // };
  const navigate = useNavigate();
  function handleChatWithOctateClicked() {
    const vendorData = octateVendorSelector?.length ? octateVendorSelector : [];
    dispatch(selectedVendorFn(vendorData[0]));
  }
  return (
    <>
      {connectWithOctate ? (
        <aside className="business-aside-container">
          <div className="flex space-between m-b-mini">
            <h3 className="overview-title">{OVERVIEW_FOR_BUYER?.TITLE}</h3>
            {/* {props?.type !== "buyer" ? (
            <FeatherIcon
              icon="edit-2"
              size="20"
              role="button"
              // className="blurb-detail-social"
              color={"#ABAFB1"}
              onClick={() => setShowModal(true)}
            />
          ) : null} */}
          </div>
          <div className="m-b-xs">
            <div className="m-y-xs">
              <h5 className="business-profile-list-item business-profile-description">
                {OVERVIEW_FOR_BUYER?.DESCRIPTION}
              </h5>
            </div>
            <div className="m-y-xs">
              <p className="business-profile-list-item business-profile-description">
                <span onClick={handleChatWithOctateClicked} className="business-profile-description--link">
                  {OVERVIEW_FOR_BUYER?.CTA_LINK}
                </span>
                {OVERVIEW_FOR_BUYER.SUB_DESCRIPTION}
              </p>
            </div>
          </div>
        </aside>
      ) : (
        <aside className="business-aside-container">
          <div className="flex space-between m-b-mini">
            <h3 className="overview-title">{BUSINESS_DETAILS?.OVERVIEW_TITLE}</h3>
            {/* {props?.type !== "buyer" ? (
            <FeatherIcon
              icon="edit-2"
              size="20"
              role="button"
              // className="blurb-detail-social"
              color={"#ABAFB1"}
              onClick={() => setShowModal(true)}
            />
          ) : null} */}
          </div>
          <div className="m-b-xs">
            {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION] ? (
              <div className="m-y-xs">
                <h5 className="business-profile-headers">{BUSINESS_DETAILS?.DESCRIPTION}</h5>
                {/* <ShowMoreText
              lines={5}
              more="Show more"
              less="Show less"
              anchorClass="show-more-less-clickable"
            >
              <p className="business-profile-description">
                {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION]}
              </p>
            </ShowMoreText> */}
                <ReadMore>{PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.PROPOSAL_DESCRIPTION]}</ReadMore>
              </div>
            ) : null}
          </div>
          {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.BUSINESS_DOMAIN] ? (
            <div className="m-y-xs">
              <h5 className="business-profile-headers">
                {/* {BUSINESS_DETAILS?.PRODUCT_CATEGORY} */}
                Business Domain
              </h5>
              <ul className="business-profile-list-item business-profile-description">
                {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.BUSINESS_DOMAIN]}
              </ul>
            </div>
          ) : null}
          {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.NUMBER_OF_LICENSE] ? (
            <div className="m-b-xs">
              <h5 className="business-profile-headers">
                {/* {BUSINESS_DETAILS?.WEBSITE} */}
                Number of licenses
              </h5>
              <a>{PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.NUMBER_OF_LICENSE]}</a>
            </div>
          ) : null}
          {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.BUSINESS_SIZE] ? (
            <div className="m-b-xs">
              <h5 className="business-profile-headers">
                {/* {BUSINESS_DETAILS?.INDUSTRY} */}
                Business Size
              </h5>
              <p className="business-profile-description "> {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.BUSINESS_SIZE]}</p>
            </div>
          ) : null}
        </aside>
      )}
    </>
  );
};

export default ProposalOverview;

// {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.SOLUTION_NEEDS] ? (
//   <div className="m-b-xs">
//     <h5 className="business-profile-headers">
//       {/* {BUSINESS_DETAILS?.ESTABLISHED} */}
//       Business Needs
//     </h5>
//     <p className="business-profile-description ">
//       {PROPOSAL_OVERVIEW?.[PROPOSAL_KEYS?.SOLUTION_NEEDS]}
//     </p>
//   </div>
// ) : null}
