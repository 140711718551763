import React from "react";
import Questions from "../../../../../../productPage/component/questions/questions";

function FAQ() {
  return (
    <div class="ui container">
      <div class="grid-11-5 grid-gap-md tablet-landscape-1-col">
        <Questions />
      </div>
    </div>
  );
}

export default FAQ;
