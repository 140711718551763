import React from "react";
import PreferenceItem from "../../../managePreference/components/preferenceItem";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { usePostUserPermissionsMutation } from "../../../../adapters/xhr/crudServiceApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import PermissionResource from "./permissionResource";
const PermissionContent = (props) => {
  const FEATURE_STATE = props?.featureActiveList;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const DATA = props?.data[FEATURE_STATE - 1];
  const HEADER = DATA?.Resource;
  const BODY = DATA?.Permissions?.Controls;
  const [tableBody, setTableBody] = useState(BODY);
  // /permission/updatePermissions
  const InviteUserPostRequest = useEnCryptPostApi(
    `/permission/updatePermissions`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPermissionsMutation
  );
  function handleTogglePermission(val, index, keyName) {
    let tempBody = [...tableBody];
    tempBody[index] = { ...tempBody[index], [keyName]: !val?.[keyName] };
    setTableBody(tempBody);
  }
  function handleSavePermission() {
    const rawData = {
      Permission_Set_ID: DATA?.Permission_Set_ID,
      Permissions: tableBody,
      User_ID: userId,
    };
    InviteUserPostRequest.handleTrigger(rawData);
  }
  useEffect(() => {
    setTableBody(BODY);
  }, [BODY]);
  return (
    <div className="permission-set-table-container">
      <PermissionResource permissionSetId={DATA?.Permission_Set_ID} data={HEADER} />
    </div>
  );
};

export default PermissionContent;
