import React from "react";
import { useState } from "react";
import ProductHeader from "./productHeader";
import ProductContent from "./productContent";
import ProductActions from "./productActions";
import EngagementBar from "../../engagementBar/engagementBar";
import Comments from "../../comments/comments";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { useSelector } from "react-redux";

const ProductCard = ({ props, item }) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [viewComments, setViewComments] = useState(false);
  const cardType = "product";
  const [commentCount, setCommentCount] = useState(0);
  const permissionsDetails = props?.permissionsDetails;
  const canDelete = props?.canDeleteProduct;
  const handleComment = (status) => {
    setCommentCount((prev) => prev + 1);
  };
  return (
    <div className="product-card" key={item?.Product_ID}>
      <ProductHeader
        data={item}
        cardType={cardType}
        page={props?.page}
        section={props?.section}
        keyName={"Product_ID"}
      />
      <div className="product-card-content">
        <ProductContent data={item} cardType={cardType} page={props?.page} section={props?.section} />
        <ProductActions data={item} cardType={cardType} page={props?.page} section={props?.section} />
      </div>
      <div className="new-card-engagement-bar-container m-b-xs blurb-card-engagement-wrapper">
        <EngagementBar
          shareLink={`/${ReplaceBlankSpaceByHyphen(item?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            item?.Product_Name,
            item?.Product_ID
          )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
          permissionsDetails={permissionsDetails}
          cardType={cardType}
          page={props?.page}
          canDelete={canDelete}
          data={item}
          section={props?.section}
          searchText={props?.searchText}
          keyName={"Product_ID"}
          engageSource={"Product engagement from product card"}
          handleViewComments={() => setViewComments((prev) => !prev)}
          commentCount={commentCount}
        />
      </div>
      {viewComments && (
        <Comments
          cardType={cardType}
          data={item}
          page={props?.page}
          searchText={props?.searchText}
          keyName={"Product_ID"}
          handleCommentCount={(val) => handleComment(val)}
        />
      )}
    </div>
  );
};

export default ProductCard;
