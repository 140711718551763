import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import RequestCategory from "./requestCategory/requestCategory";

const HeroHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div style={{ zIndex: 0 }} className="landing-page__hero-header">
      <div className="container">
        <div
          className="landing-page__header landing-page__header--divided flex space-between"
          style={{ flexWrap: "wrap" }}
        >
          <div>
            <h1 className="landing-page__title">{KEYS?.ALL_CATEGORIES.PAGE_TITLE}</h1>
            <div className="landing-page__description">
              {KEYS?.ALL_CATEGORIES.PAGE_DISCRIPTION_START}
              {props?.listCount}
              {KEYS?.ALL_CATEGORIES.PAGE_DISCRIPTION_END}
            </div>
          </div>
          {authData?.data?.userExist || true ? (
            <div className="flex align-items-center">
              <h4 className="request-category-text">Don't see your category? </h4>{" "}
              <button className="request-now-button" onClick={() => setModalVisible((prev) => !prev)}>
                Request now
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {modalVisible ? <RequestCategory handleClose={() => setModalVisible((prev) => !prev)} /> : null}
    </div>
  );
};
export default HeroHeader;
