import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetRequestCrudQuery, usePostRequestCrudMutation } from "../../../../../adapters/xhr/crudServiceApi";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { vendorDetails } from "../../../../../contexts/reducer/homeReducer";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import FilterModal from "../../../../searchResult/components/filter/mobile/filterModal";
import AddBlurbOrProductPortal from "./addBlurbOrProductPortal";
import ShareVendorPage from "./shareVendorPage";
import VendorEngagement from "./vendorEngagement";
import SignInModal from "../../../../../utlis/hoc/signInModal/signInModal";
import * as ENV_KEYS from "../../../../../config/configKeys";
import ConfirmPublishPortal from "./confirmPublishPortal";
import * as CONFIG_KEYS from "../../../../../config/config";
const BusinessProfileDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;
  const BUSINESS_PAGE = KEYS?.BUSINESS_LANDING_PAGE_OVERVIEW;
  const BUSINESS_PUBLISH_KEYS = CONFIG_KEYS?.CONFIG?.BUSINESS_PROFILE_PUBLISH;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabIndex = props?.tabIndex;
  const VENDOR_ID = props?.VENDOR_ID;
  const permissionsDetails = props?.permissionsDetails;
  const GetApiData = useEnCryptGetApi(
    `/getVendorDetailsOnVendorId?vendorId=${VENDOR_ID}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );

  const PostPublishVendor = useEnCryptPostApi(
    `/vendor/publishOnOctate`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestCrudMutation
  );

  const AddingProductOrBlurbLabel =
    tabIndex == "feed" ? "Add Blurb" : tabIndex == "products" ? "Add Product" : "Add FAQ";
  const vendorData = GetApiData?.data?.data;
  dispatch(vendorDetails(GetApiData?.data?.data));
  const handleNewPostClicked = () => {
    if (tabIndex == "feed" || tabIndex == "products") {
      window.scrollTo(0, 0);
      setShowModal(true);
      // document.body.style.overflow = "hidden";
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    // document.body.style.overflow = "auto";
  };

  const handlePublishVendor = (val) => {
    setShowConfirmPublishModal(true);
  };

  const handleManageProposal = () => {
    if (authData?.data?.userExist) {
      navigate("/proposals");
    } else {
      setSignInModalVisible((prev) => !prev);
    }
  };
  function handleActionsClicked(val) {
    if (val == "Confirm") {
      const rawData = {
        userId: authData?.data?.userId,
        vendorId: authData?.data?.vendorId,
        status: "Publish",
      };
      PostPublishVendor?.handleTrigger(rawData);
      setTimeout(() => {
        setShowConfirmPublishModal(false);
      }, 1000);
    }
  }

  return (
    <>
      {showConfirmPublishModal ? (
        <ConfirmPublishPortal
          handleClose={() => setShowConfirmPublishModal(false)}
          handleActionsClicked={handleActionsClicked}
          vendorPostData={PostPublishVendor}
        />
      ) : null}
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible((prev) => !prev)} /> : null}
      {showModal ? <AddBlurbOrProductPortal tabIndex={tabIndex} handleClose={handleCloseModal} /> : null}
      <div className="grid-two-columns tablet-1-col">
        <div className="flex m-y-xs">
          {vendorData?.Vendor_Logo_URL ? (
            <img
              class=" ls-is-cached lazyloaded vendor-image"
              data-src={vendorData?.Vendor_Logo_URL}
              src={vendorData?.Vendor_Logo_URL}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                backgroundColor: "#f5f5f5",
                borderRadius: "5px",
                border: "1px solid #f5f5f5",
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = require("../../../../../assets/images/Vendor_Placeholder_Icon.svg").default;
              }}
              alt={`${vendorData?.Vendor_Name} ${ENV_KEYS?.CONFIG?.VENDOR_LOGO}`}
            />
          ) : (
            <img
              class=" ls-is-cached lazyloaded vendor-image"
              data-src={require("../../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
              src={require("../../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                backgroundColor: "#f5f5f5",
                borderRadius: "5px",
                border: "1px solid #f5f5f5",
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = require("../../../../../assets/images/Vendor_Placeholder_Icon.svg").default;
              }}
              alt={`${vendorData?.Vendor_Name} ${ENV_KEYS?.CONFIG?.VENDOR_LOGO}`}
            />
          )}
          <div className="flex-list space-evenly m-l-base">
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
              <h1 className="business-profile-name">{vendorData?.Vendor_Name}</h1>
              {/* {vendorData?.Vendor_Status === "Verified" || props?.type === "buyer" ? (
                <div data-tooltip="Verified" className="flex align-items-center m-r-base">
                  <img
                    class=" ls-is-cached lazyloaded"
                    // data-src={require("../../../../../assets/onboardingSvg/Shield-Done.svg").default}
                    src={require("../../../../../assets/images/shield-verified.svg").default}
                    alt={"shield"}
                  />
                </div>
              ) : authData?.data?.User_Status ? (
                <p className="vendor-onboarding-unverified-text">{authData?.data?.User_Status}</p>
              ) : null} */}
            </div>

            {props?.type === "buyer" && vendorData ? <VendorEngagement data={vendorData} /> : null}
          </div>
        </div>
        <div className="mobile-hidden">
          <div className="business-profile-button-container ">
            {props?.type === "buyer" && (
              <div
                className="business-profile-header-button"
                role={"button"}
                style={{ marginRight: "10px" }}
                onClick={() => handleManageProposal()}
              >
                Manage Proposal
              </div>
            )}
            {GetApiData.isLoading ? null : permissionsDetails?.Publish ? (
              vendorData?.Vendor_Status === "Verified" ? (
                <div
                  className="business-profile-header-button-success"
                  data-position="left center"
                  data-tooltip={BUSINESS_PUBLISH_KEYS?.PUBLISHED_TOOLTIP}
                  data-inverted="true"
                >
                  {BUSINESS_PUBLISH_KEYS?.PUBLISHED}
                </div>
              ) : vendorData?.Vendor_Status !== "Has_Product" ? (
                <div
                  className="business-profile-header-button cursor--disabled"
                  role={"button"}
                  data-position="left center"
                  data-tooltip={BUSINESS_PUBLISH_KEYS?.NO_PRODUCTS}
                  data-inverted="true"
                >
                  {BUSINESS_PAGE?.PUBLISH}
                </div>
              ) : permissionsDetails?.Publish ? (
                <div
                  className="business-profile-header-button"
                  role={"button"}
                  onClick={() => handlePublishVendor(vendorData)}
                  // data-tooltip={"Verification is yet under process"}
                  // data-inverted="true"
                >
                  {BUSINESS_PAGE?.PUBLISH}
                </div>
              ) : null
            ) : null}
            {/* <ShareVendorPage data={vendorData} /> */}
          </div>

          {/* <div
            className="business-profile-header-button"
            role={"button"}
            //   onClick={() => handleJoinOrClaim(vendorData)}
          >
            More
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BusinessProfileDetails;
