import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import SmallCardSkeleton from "../../../../utlis/hoc/skeleton/smallCardSkeleton";
import { MobilePaginatedItems } from "../../../searchResult/components/pagination/paginationMobileView";
import { PaginatedItems } from "../../../searchResult/components/pagination/paginationReact";
import TemplateCard from "./templateCard";

function TemplateList(props) {
  // /getProposalTemplates
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const allCategoriesId = props?.allCategoriesId.length ? JSON.stringify(props?.allCategoriesId) : [];
  const page = props?.page;
  const getProposalTemplates = useEnCryptGetApi(
    `/getProposalTemplates?limit=10&page=${page}&categoryId=${allCategoriesId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );
  function HandlePageNumber(val) {
    props?.handlePage(val);
  }
  const AllProposalTemplate = getProposalTemplates?.data?.data?.Data?.length
    ? getProposalTemplates?.data?.data?.Data
    : [];
  const TotalCount = getProposalTemplates?.data?.data?.Total_Count;
  const DUMMY_DATA = [
    {
      Proposal_ID: 2245,
      Proposal_Title: "Business Consulting Proposal_Test",
      Proposal_Description:
        "Introduction\nThe introduction component of the business proposal structure ideally covers:\nThe business entity in terms of company, partnership, proprietorship.\nThe major activities of the business entity.\nA brief history of past relevant accomplishments.\nThe values and the ethical standards adopted in dealings with stakeholders. \nThe purpose of the proposal. \nCredits, including the author and external sources who helped prepare the proposal.\nConcise personal profiles (not resumes) of key people who would drive the project.\nThe contact details of the key person to contact for follow-up on the proposal.\nThe intended recipients of the proposal and any access restrictions.\nThe date of the propo",
      Type: "Template",
      Product_Category_ID: 2,
      Product_Category_Name: "Financial Analysis Software",
    },
    {
      Proposal_ID: 2245,
      Proposal_Title: "Business Consulting Proposal_Test",
      Proposal_Description:
        "Introduction\nThe introduction component of the business proposal structure ideally covers:\nThe business entity in terms of company, partnership, proprietorship.\nThe major activities of the business entity.\nA brief history of past relevant accomplishments.\nThe values and the ethical standards adopted in dealings with stakeholders. \nThe purpose of the proposal. \nCredits, including the author and external sources who helped prepare the proposal.\nConcise personal profiles (not resumes) of key people who would drive the project.\nThe contact details of the key person to contact for follow-up on the proposal.\nThe intended recipients of the proposal and any access restrictions.\nThe date of the propo",
      Type: "Template",
      Product_Category_ID: 2,
      Product_Category_Name: "Financial Analysis Software",
    },
    {
      Proposal_ID: 2245,
      Proposal_Title: "Business Consulting Proposal_Test",
      Proposal_Description: "Introduction\nThe introduction",
      Type: "Template",
      Product_Category_ID: 2,
      Product_Category_Name: "Financial Analysis Software",
    },
  ];
  return (
    <div className="proposal-template-template-list-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2 className="proposal-template-template-list-header">{`All templates (${TotalCount})`}</h2>
        <div
          onClick={() => props?.handleFooterNumberFromTemplateClicked(2)}
          className="company-details-footer-next m-r-sm"
        >
          Create new
        </div>
      </div>

      <div>
        {getProposalTemplates.isLoading || getProposalTemplates.isFetching ? (
          <div className="grid-two-columns col-gap-md tablet-1-col tablet-landscape-1-col grid-col-gap-md">
            <SmallCardSkeleton skeletonLength={4} />
          </div>
        ) : (
          <div className="grid-two-columns col-gap-md tablet-1-col tablet-landscape-1-col grid-col-gap-md">
            {AllProposalTemplate?.map((list) => {
              return (
                <TemplateCard
                  handleFooterNumberFromTemplateClicked={props?.handleFooterNumberFromTemplateClicked}
                  data={list}
                />
              );
            })}
          </div>
        )}
      </div>

      <PaginatedItems
        page={page}
        totalNumber={TotalCount}
        itemsPerPage={10}
        HandlePageNumber={HandlePageNumber}
        // forcePage={0}
      />
      <MobilePaginatedItems
        page={page}
        totalNumber={TotalCount}
        itemsPerPage={10}
        HandlePageNumber={HandlePageNumber}
        forcePage={0}
      />
    </div>
  );
}

export default TemplateList;
