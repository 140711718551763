import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation, useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useGetAllPopularDataProvidersQuery } from "../../../adapters/xhr/searchService";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../utlis/customHooks/customHooks";
//  import * as KEYS from "../../../config/config";
import DataProviderCard from "../../../utlis/hoc/providerCardSmall/dataProviderCard";
import VendorCard from "../../../utlis/hoc/vendorCard/vendorCard";
import { DecryptApi, EncryptApiGetData } from "../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../config/configKeys";
const DataProvider = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const MATRIX = useSelector((state) => state?.authDetails?.octateMatrix);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const VENDORS_COUNT = MATRIX?.length ? MATRIX.find((o) => o.Label === KEYS?.MATRIX?.VENDORS) : null;
  const PROVIDER_DATA = KEYS?.PROVIDER_DATA;
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const PROVIDERS = KEYS?.HEADER_SEARCH_TYPE.PROVIDER;
  const PRODUCTS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PRODUCT_LABEL;
  const PROVIDERS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PROVIDER_LABEL;
  const [enCryptGetPopularData, setEnCryptGetPopularData] = useState("");
  const signalTrackingVendorObject = {
    action: SECRET_KEYS.CONFIG.VIEW_ALL_VENDORS_CLICKED,
    page: "search page",
    section: props?.section,
    context: "all vendors",
  };
  // const {
  //   data: PopularDataProviders,
  //   isError: PopularDataProvidersIsError,
  //   isLoading: PopularDataProvidersIsLoading,
  // } = useGetAllPopularDataProvidersQuery(
  //   { enCryptGetPopularData },
  //   { skip: enCryptGetPopularData?.data ? false : true }
  // );
  const SKIP_QUERY = USER_ID ? false : true;
  const getPopularVendorsApi = useEnCryptGetApi(
    `/getAllPopularDataProvider?userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery,
    SKIP_QUERY
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleAllVendorsLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.action,
      Item_Type: null,
      Item_Id: null,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: signalTrackingVendorObject?.context,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  // useEffect(() => {
  //   const enCryptData = {
  //     //* https://thebox-dev.portqii.com/search_backend/getAllPopularDataProvider
  //     path: `/getAllPopularDataProvider?userId=${USER_ID}`,
  //     service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
  //     userInfo: { deviceId: "ffbe445", userId: USER_ID },
  //   };
  //   const enCryptGetPopularData = EncryptApiGetData(enCryptData);
  //   setEnCryptGetPopularData(enCryptGetPopularData);
  // }, []);

  const DeCryptPopularData = getPopularVendorsApi?.data;
  return (
    <>
      <div className="dtrd-landing-page ">
        <div className="landing-page__section landing-page__benefits">
          <div className="ui container" style={{ maxWidth: "1120px" }}>
            <div className="landing-page__section-header">
              <div className="section-header__content">
                <h2 className="landing-page__section-title">{PROVIDER_DATA?.HEADER}</h2>
                {VENDORS_COUNT ? (
                  <div className="landing-page__section-subtitle">
                    {PROVIDER_DATA?.SUB_HEADER1} {VENDORS_COUNT?.Count} {PROVIDER_DATA?.SUB_HEADER2}
                  </div>
                ) : null}
              </div>
              {/* <div className="section-header__actions">
                 <a
                   className="landing-page__section-link"
                   href={`/search/${PROVIDERS}`}
                 >
                   {PROVIDER_DATA?.SEARCH_ALL}
                 </a>
               </div> */}
            </div>

            {/* <DataProviderCard data={DeCryptPopularData?.data} /> */}
            <VendorCard section={props?.section} data={DeCryptPopularData?.data} page={props?.page} />
            {DeCryptPopularData?.data?.length >= 6 ? (
              <div onClick={() => handleAllVendorsLinkClicked(`/search/${PROVIDERS}`)} className="m-t-sm">
                <a className="button primary outlined" href={`/search/${PROVIDERS}`}>
                  {PROVIDER_DATA?.SEARCH_ALL}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataProvider;
