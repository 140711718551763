import React, { useState,useEffect } from "react";
import EmailVerificationOctate from "../signUp/emailVerification/emailVerificationOctate";
import SignInForm from "./component/signInForm";
import {updateMetaTags} from "../../../../src/components/SEO/seo";
import SignInLeftContainer from "./component/signInLeftContainer";

const SignIn = (props) => {
  useEffect(() => {
    const seoBody = {
      type: "sign-in",
    };
    updateMetaTags(seoBody);
  }, []);

  return (
    <main>
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      <div class="page-content page-content--reset p-r">
        <div class="splitted-page">
          <SignInLeftContainer />
          <SignInForm />
        </div>
      </div>
    </main>
  );
};

export default SignIn;
