import React from "react";
import { useSelector } from "react-redux";
import * as NOTIFICATION_KEYS from "../../../../config/config";
import TeamsCard from "./teamsCard";
const TeamsList = (props) => {
  const list = props?.Data?.length ? props?.Data : [];
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const isLoading = props?.isLoading;
  const proposalDetails = props?.proposalDetails;
  const NOTIFICATION_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.NOTIFICATION_API_KEYS;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  return (
    <div className="proposal-teams-list-main-wrapper p-x-md">
      {list?.length > 0 ? (
        list?.map((val, index) => {
          return <TeamsCard USER_CAN_EDIT={USER_CAN_EDIT} proposalDetails={proposalDetails} data={val} index={index} />;
        })
      ) : (
        <div className="notification-card-main-wrapper">
          <h3 style={{ color: "#d3d3d3" }} className="notifications-header-main-title-text">
            User not added
          </h3>
        </div>
      )}
    </div>
  );
};

export default TeamsList;
