import React from "react";
import FeatherIcon from "feather-icons-react";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import { useSelector } from "react-redux";

const ShareDetail = (props) => {
  const DATA = props?.data;
  const HOST_NAME = new URL(window.location.href);
  const NEW_URL = window.location.origin;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PAGE_URL = `${props?.cardType == "vendor-page" ? window.location.origin : window.location.origin}${
    props?.shareLink
  }`;
  const iconSize = 25;
  const title =
    props?.cardType == "product"
      ? props?.data?.Product_Name
      : props?.cardType == "vendor-page"
      ? props?.data?.Vendor_Name
      : props?.cardType
      ? props?.data?.title_level_1
      : " share";

  const handleCopyToClipboard = () => {
    props?.cardType == "product"
      ? navigator.clipboard.writeText(
          `${NEW_URL}/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            DATA?.Product_Name,
            DATA?.Product_ID
          )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}?utm_source=octate_link`
        )
      : props?.cardType == "vendor"
      ? navigator.clipboard.writeText(
          `${NEW_URL}/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
            KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
          }?utm_source=octate_link`
        )
      : props?.cardType == "blurb"
      ? navigator.clipboard.writeText(
          `${NEW_URL}/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
            BreakText(DATA?.title_level_1?.trim())
          )}-${DATA?.blurbID}?utm_source=octate_link`
        )
      : props?.cardType == "usecase"
      ? navigator.clipboard.writeText(
          `${NEW_URL}/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
            BreakText(DATA?.title_level_1?.trim())
          )}-${DATA?.blurbID}?utm_source=octate_link`
        )
      : navigator.clipboard.writeText(window.location.href);
    props?.shareSignalTracking("copied to clipboard");
    props?.handleToggleMenu();
  };
  return (
    <>
      <div className="share-single-item ">
        <div
          onClick={() => handleCopyToClipboard("twitter share")}
          style={{ width: "100%", padding: "5px", borderBottom: "1px solid #e1e2e9" }}
          className="flex align-items-center p-y-mini"
        >
          {/* <FeatherIcon icon="link" color={"rgb(168, 168, 168)"} size="18" role="button" style={{ margin: "0% 10px" }} /> */}
          <img
            src={require("../../../../assets/images/Share icons/3.svg").default}
            height={25}
            width={25}
            className="m-x-base"
          />
          <p className="share-desc-title">Copy link</p>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("twitter share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <TwitterShareButton url={`${PAGE_URL}?utm_source=twitter`} title={title} className="flex align-items-center">
            {/* <FeatherIcon
              icon="twitter"
              // fill={"#181527"}
              size="18"
              className="blurb-detail-social"
              role="button"
            /> */}
            <img
              src={require("../../../../assets/images/Share icons/4.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Twitter</p>
          </TwitterShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("linkedin share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <LinkedinShareButton
            url={`${PAGE_URL}?utm_source=linkedin`}
            title={title}
            className="flex align-items-center"
          >
            {/* <FeatherIcon fill={true} icon="linkedin" size="18" role="button" className="blurb-detail-social" /> */}
            <img
              src={require("../../../../assets/images/Share icons/1.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Linkedin</p>
          </LinkedinShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("facebook share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <FacebookShareButton
            url={`${PAGE_URL}?utm_source=facebook`}
            title={title}
            className="flex align-items-center"
          >
            {/* <FeatherIcon fill={true} icon="facebook" size="18" role="button" className="blurb-detail-social" /> */}
            <img
              src={require("../../../../assets/images/Share icons/2.svg").default}
              height={25}
              width={25}
              className="m-x-base"
            />
            <p className="share-desc-title"> Share on Facebook</p>
          </FacebookShareButton>
        </div>
      </div>
    </>
  );
};

export default ShareDetail;
