import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import BlurbTags from "../../../../components/blurbDetail/components/blurb/blurbTags";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { BreakText, CamelCaseName, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import * as SECRET_KEYS from "../../../../config/configKeys";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import parse from "html-react-parser";
const BlurbContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;

  const signalTrackingBlurbObject = {
    Action: SECRET_KEYS.CONFIG.BLURB_TITLE_CONTENT_CLICKED,
    page: props?.page,
    section: props?.section,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Item_Type: signalTrackingBlurbObject?.page,
      Item_Id: DATA?.blurbID,
      Target: {
        page: signalTrackingBlurbObject?.page,
        link: `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
          BreakText(DATA?.title_level_1?.trim())
        )}-${DATA?.blurbID}/products`,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: val?.title_level_1,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div className="blurb-card-content-container">
      <div onClick={() => handleBlurbLinkClicked(DATA)}>
        <a
          href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
            BreakText(DATA?.title_level_1?.trim())
          )}-${DATA?.blurbID?.trim()}/product`}
        >
          <h2 className="new-blurb-card-title">{DATA?.title_level_1}</h2>
          {(props?.page === "vendor page" || props?.page === "business profile") &&
          DATA?.Product_Name &&
          DATA?.Product_ID ? (
            <div className="flex additional-info-vendor-page use-case-type-margin">
              <FeatherIcon
                icon={"box"}
                size="15"
                style={{ marginRight: "5px" }}
                // className="blurb-detail-social"
              />
              <a
                className="blurb-card__disclaimer"
                href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                  DATA?.Product_Name,
                  DATA?.Product_ID
                )}/blurbs`}
              >
                {CamelCaseName(DATA?.Product_Name ? DATA?.Product_Name : "")}
              </a>
            </div>
          ) : null}
          {DATA?.description ? <p className="new-blurb-card-description">{parse(DATA?.description)}</p> : null}
        </a>
      </div>
      <BlurbTags data={DATA} page={props?.page} />
    </div>
  );
};

export default BlurbContent;
