import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import InputFieldProfile from "../../../components/profile/editProfile/component/inputFieldProfile";
import TextArea from "../textArea/textArea";

const FeedbackForm = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  const UserFeedback = useEnCryptPostApi(`/addFeedback`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  const handleSubmit = () => {
    setSuccessMessage((prev) => !prev);
    if (!formDetails?.Feedback) {
      setError({ Feedback: "Feedback is required" });
    } else {
      const RAW_DATA = {
        ...formDetails,
        Item_ID: props?.data?.[props?.keyName],
        Item_Type: props?.cardType,
        Feedback_By: authData?.data?.userId,
      };
      UserFeedback?.handleTrigger(RAW_DATA);
    }
  };

  useEffect(() => {
    if (UserFeedback?.data?.status)
      setTimeout(() => {
        props?.handleClose();
      }, 1000);
  }, [UserFeedback?.data]);

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="feedback-container--main-wrapper" style={{ height: "min-content" }}>
          {UserFeedback?.data?.status ? (
            <div
              style={{
                background: UserFeedback?.data?.status ? "#008000" : "#c00",
              }}
              className="success-message-pop-up"
            >
              <h3 className="success-message-style-board">Request Submitted successfully</h3>
            </div>
          ) : null}
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => props?.handleClose()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Feedback</h1>
          </div>
          <div className="request-category-container">
            <div className="m-b-md">
              <TextArea
                value={formDetails?.Feedback}
                className="m-b-xs"
                label={"Please leave your feedback over here..."}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Feedback: val }))}
                removeMargin={true}
                error={error?.Feedback}
                subTitleTop={"Maximum 500 characters only."}
              />
            </div>

            <div className="flex justify-flex-end">
              <div className="request-category-submit-button" onClick={() => handleSubmit()}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default FeedbackForm;
