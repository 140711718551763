import React, { forwardRef, useRef, useState } from "react";
import * as CONFIG_KEYS from "../../../../../../config/config";
import moment from "moment";
import { useSelector } from "react-redux";
import CommentMoreOptions from "./commentMoreOptions";
import FeatherIcon from "feather-icons-react";
import { usePostRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../../../utlis/customHooks/customHooks";
import { useEffect } from "react";
const SingleCommentCard = forwardRef((props, ref) => {
  const [commentText, setCommentText] = useState("");
  const [editComment, setEditComment] = useState(false);
  const textAreaRef = useRef(null);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const index = props?.index;
  const proposalType = props?.proposalType;
  const vendorId = props?.Vendor_Id;
  //  authData?.data?.vendorId;
  const list = props?.list;
  const PROPOSAL_COMMENT_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL_COMMENTS_KEYS;
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const vendorPostData = useEnCryptPostApi(
    `/proposal/comment`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleActionClicked = (val) => {
    if (val == "edit") {
      setEditComment(true);
      props?.showEditOption(false);
    } else if (val == "reply") {
      props?.handleReplyClicked(list);
    } else {
      const commentRawObj = {
        ...list,
        Delete_Status: 1,
        Comment_Date_Time: new Date().getTime() / 1000,
      };
      vendorPostData?.handleTrigger(commentRawObj);
      props?.handleDeleteClicked(index);
    }
  };
  const handleEditClicked = (val) => {
    const commentRawObj = {
      ...list,
      Comment: commentText,
      Comment_Date_Time: new Date().getTime() / 1000,
    };
    if (commentText) {
      vendorPostData?.handleTrigger(commentRawObj);
      props?.handleEditClicked(commentRawObj, index);
      setEditComment(false);
      setCommentText("");
      props?.showEditOption(true);
      handleChangeText();
    }
  };
  const handleCloseClicked = (val) => {
    props?.showEditOption(true);
    setEditComment(false);
    setCommentText("");
  };
  const handleChangeText = (e) => {
    setCommentText(e?.target?.value?.length == 1 ? e.target.value?.trim() : e?.target?.value);
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
    }
    //Here you can even write the logic to select the value from the drop down or something.
  };
  const handleFocus = (e) => {
    const target = e.target;
    setTimeout(() => (target.rows = "auto"));
  };
  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 10)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };
  useEffect(() => {
    if (list?.[PROPOSAL_COMMENT_KEYS?.COMMENT]) {
      setCommentText(list?.[PROPOSAL_COMMENT_KEYS?.COMMENT]);
    }
  }, [list?.[PROPOSAL_COMMENT_KEYS?.COMMENT]]);
  return (
    <div
      className={`${
        list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId
          ? proposalType == "answer"
            ? "proposal-comments-comment-list-single-comment-wrapper---vendor"
            : "proposal-comments-comment-list-single-comment-wrapper---user"
          : proposalType == "answer"
          ? "proposal-comments-comment-list-single-comment-wrapper---user"
          : "proposal-comments-comment-list-single-comment-wrapper---vendor"
      }`}
    >
      <div
        className={`proposal-comments-comment-list-comment-content-main-container ${
          list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId ? "comment-text--vendor" : "comment-text--user"
        } ${
          list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId ? "comment-container--vendor" : "comment-container--user"
        }`}
      >
        {editComment ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <textarea
              ref={textAreaRef}
              onKeyDown={onKeyDown}
              onFocus={handleFocus}
              autoFocus={true}
              rows={1}
              className={`${
                list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId ? "comment-text--vendor" : "comment-text--user"
              }`}
              id="note"
              type={"text"}
              value={commentText}
              onInput={handleInput}
              onChange={(e) => handleChangeText(e)}
              placeholder="Your message"
              // className="comment-input-style"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FeatherIcon
                onClick={handleCloseClicked}
                icon={"x"}
                style={{ cursor: "pointer", marginRight: "10px" }}
                size="22"
              />
              <FeatherIcon
                data-inverted="true"
                data-position="left center"
                // role="button"
                data-tooltip={!commentText ? "Comments cannot be empty" : null}
                style={{ cursor: "pointer" }}
                onClick={handleEditClicked}
                icon={"check"}
                size="22"
              />
            </div>
          </div>
        ) : (
          <>
            {props?.showEditOptionState && (
              <div className="proposal-comments-comment-list-comment-more-options-container">
                <CommentMoreOptions handleActionClicked={handleActionClicked} list={list} />
              </div>
            )}
            <div className="proposal-comments-comment-list-comment-date">
              <p
                className={`${
                  list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId ? "comment-date--user" : "comment-date--vendor"
                }`}
              >
                {list?.[PROPOSAL_COMMENT_KEYS?.CREATED_ON]
                  ? moment(list?.[PROPOSAL_COMMENT_KEYS?.CREATED_ON] * 1000).fromNow()
                  : "Not defined"}
              </p>
              <p
                className={` ${
                  list?.[PROPOSAL_COMMENT_KEYS?.VENDOR_ID] == vendorId ? "comment-date--user" : "comment-date--vendor"
                }`}
              >
                {list?.[PROPOSAL_COMMENT_KEYS?.USER_NAME]}
              </p>
            </div>

            <div ref={props?.replyId == list?.[PROPOSAL_COMMENT_KEYS?.COMMENT_ID] ? ref : null}>
              {list?.[PROPOSAL_COMMENT_KEYS?.REPLY] ? (
                <div
                  onClick={() =>
                    props?.handleScrollToReplyComment(
                      list?.[PROPOSAL_COMMENT_KEYS?.REPLY]?.[PROPOSAL_COMMENT_KEYS?.COMMENT_ID]
                    )
                  }
                  style={{ padding: "5px 5px", cursor: "pointer" }}
                  className="proposal-comments-comment-reply-wrapper m-t-sm p-r"
                >
                  <p style={{ padding: "0px 5px" }} className={`proposal-comment-reply-text`}>
                    {list?.[PROPOSAL_COMMENT_KEYS?.REPLY]?.[PROPOSAL_COMMENT_KEYS?.COMMENT]}
                  </p>
                </div>
              ) : null}
              <p
                className={`proposal-comments-comment-list-comment-text m-r-mini ${
                  list?.[PROPOSAL_COMMENT_KEYS?.REPLY] ? "" : "m-t-mini"
                }`}
              >
                {list?.[PROPOSAL_COMMENT_KEYS?.COMMENT]}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default SingleCommentCard;
