import React from "react";
import * as CONFIG_KEYS from "../../../../config/config";
import ProposalSection from "./proposalSection";
import _ from "lodash";
const QuestionList = (props) => {
  const proposalDetails = props?.proposalDetails;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const allQuestions = proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
    ?.length
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]
    : [];
  const notificationJson = props?.notificationJson;
  const QuestionAndSectionIndex = {
    sectionIndex:
      notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID] &&
      (notificationJson?.["Object"]?.Action == "Question_Edited" ||
        notificationJson?.["Object"]?.Action == "Answer_Edited")
        ? notificationJson?.["Object"]?.Details?.[ADD_SECTION_KEYS?.SECTION_ID]
        : notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID] &&
          (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
            notificationJson?.["Object"]?.Action == "Vendor_Commented")
        ? notificationJson?.["Object"]?.[ADD_SECTION_KEYS?.SECTION_ID]
        : null,
    questionIndex:
      notificationJson?.["Object"]?.Details?.[ADD_QUESTION_KEYS?.QUESTION_ID] &&
      (notificationJson?.["Object"]?.Action == "Question_Edited" ||
        notificationJson?.["Object"]?.Action == "Answer_Edited")
        ? notificationJson?.["Object"]?.Details?.[
            ADD_QUESTION_KEYS?.QUESTION_ID
          ]
        : notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID] &&
          (notificationJson?.["Object"]?.Action == "Buyer_Commented" ||
            notificationJson?.["Object"]?.Action == "Vendor_Commented")
        ? notificationJson?.["Object"]?.[ADD_QUESTION_KEYS?.QUESTION_ID]
        : null,
  };
  // "Vendor_Commented" : "Buyer_Commented"
  const selectedQuestion = props?.selectedQuestion;
  return (
    <aside className="business-aside-container">
      <div className="vendor-proposal-dis-flex-space-btw">
        <h3 className="vendor-proposal-question-list-main-title">Questions</h3>
        {/* <p className="vendor-proposal-question-list-total-question-count">20</p> */}
      </div>
      {allQuestions?.map((list, index) => {
        {
          /* QuestionAndSectionIndex?.sectionIndex
          ? QuestionAndSectionIndex?.sectionIndex ==
            list?.[ADD_SECTION_KEYS?.SECTION_ID]
            ? true
            : false
          : */
        }
        const ActiveIndex =
          QuestionAndSectionIndex?.sectionIndex == null &&
          selectedQuestion?.sectionIndex == null
            ? 0 == index
              ? true
              : false
            : selectedQuestion?.sectionIndex == index
            ? true
            : false;
        return (
          <ProposalSection
            notificationJson={notificationJson}
            data={list}
            proposalDetails={proposalDetails}
            activeIndex={ActiveIndex}
            selectedQuestion={selectedQuestion}
            // QuestionAndSectionIndex={QuestionAndSectionIndex}
            sectionIndex={index}
            activeIndexNumber={ActiveIndex ? index : null}
            handleQuestionClicked={props?.handleQuestionClicked}
          />
        );
      })}
    </aside>
  );
};

export default QuestionList;
