import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetBoardsDataQuery, useGetMyBoardsDataQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import BoardCardSmall from "../../../../utlis/hoc/boardCardSmall/boardCardSmall";
import TabHeader from "../../../businessProfile/components/tabs/components/tabHeader";
import BoardTabHeader from "./boardTabHeader";
import * as NO_CONTENT from "../../../../config/config";

function BoardsTab(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [showOnlyVendor, setShowOnlyVendor] = useState(false);
  const vendorId = authData.data?.vendorId;
  const userId = authData.data?.userId;
  const type = props?.type;
  const permissionsDetails = props?.permissionsDetails;

  const Id = props?.vendorId;
  const requestApi = `/boards/getPublicBoards?itemId=${Id}&itemType=${props?.cardType}&type=${
    showOnlyVendor ? "vendor" : type
  }`;

  // `/getUserBoards?userId=${USER_ID}&Vendor_ID=${vendorId}
  const GetApiData = useEnCryptGetApi(requestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, useGetMyBoardsDataQuery);
  const getAllBoardsList = GetApiData?.data?.data?.vendorCreatedBoards?.length
    ? GetApiData?.data?.data?.vendorCreatedBoards
    : [];
  const getAllBoardData = GetApiData?.data?.data?.length ? GetApiData?.data?.data : [];
  const checkBoardsLength = getAllBoardData?.length ? true : false;
  function handleShowVendorBoardClicked() {
    setShowOnlyVendor(!showOnlyVendor);
  }
  return (
    <div class="provider-profile__main-content-section" id="provider-profile-alternatives">
      <BoardTabHeader
        permissionsDetails={permissionsDetails}
        tabIndex={props?.tabIndex}
        type={props?.type}
        page={props?.page}
        handleShowVendorBoardClicked={handleShowVendorBoardClicked}
        showOnlyVendor={showOnlyVendor}
      />
      {GetApiData?.isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h5 className="boards-list-no-results-text--no-boards">{"Loading..."}</h5>
        </div>
      ) : (
        <>
          {checkBoardsLength ? (
            <BoardCardSmall permissionsDetails={permissionsDetails} page={props?.page} data={getAllBoardData} />
          ) : typeof GetApiData?.data?.data === "object" && GetApiData?.data?.data?.length === 0 ? (
            <div className="search__result data-product-card no-content-card">
              <div className="no-content-text">
                {props?.cardType === "vendor"
                  ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_BOARD
                  : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_BOARD}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default BoardsTab;
