import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
const UseCaseInfoContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const PARAMS = useParams();
  const SEARCH_TEXT = PARAMS?.name?.replaceAll("-", " ");

  const DISPLAY_NAME = props?.array
    ? `${
        DATA?.[props?.keyName]?.length
          ? `${DATA?.[props?.keyName][0]?.Customer_Name?.slice(0, 100)}${
              DATA?.[props?.keyName][0]?.Customer_Name?.length > 100 ? "..." : ""
            }`
          : ""
      }`
    : props?.object
    ? `${DATA?.[props?.keyName]?.name?.slice(0, 100)}${DATA?.[props?.keyName]?.name?.length > 100 ? "..." : ""}`
    : DATA?.[props?.keyName]
    ? `${DATA?.[props?.keyName]?.slice(0, 100)}${DATA?.[props?.keyName]?.length > 100 ? "..." : ""}`
    : "";
  const MORE_LENGTH = props?.array
    ? `${DATA?.[props?.keyName]?.length > 1 ? `+${DATA?.[props?.keyName]?.length - 1} more` : ""}`
    : "";

  const DisplayList = (data) => {
    const itemList = data?.map((item, index) => {
      if (index === data?.length - 1) {
        return item?.Customer_Name;
      } else if (index === data?.length - 2) {
        return item?.Customer_Name + " and ";
      } else {
        return item?.Customer_Name + ", ";
      }
    });
    return itemList;
  };

  const HOVER_TEXT = props?.object ? `${DATA?.[props?.keyName]?.name}` : `${DATA?.[props?.keyName]}`;

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.FORMOGRAPICS_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleFirmographicsCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: SEARCH_TEXT,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return DATA?.[props?.keyName] && DISPLAY_NAME ? (
    <div className="flex-container center-container additional-info-vendor-page">
      {props?.keyName === "Use_Case_Customers" ? (
        <>
          <div
            data-tooltip={props?.name}
            data-inverted="true"
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          >
            <img
              src={
                props?.keyName === "Use_Case_Customers"
                  ? require("../../../../assets/cardIcons/useCaseIcons/1.png")
                  : props?.keyName === "Use_Case_Industry"
                  ? require("../../../../assets/cardIcons/useCaseIcons/2.png")
                  : require("../../../../assets/cardIcons/useCaseIcons/1.png")
              }
              style={{ width: "20px", height: "20px", marginRight: "5px" }}
            />
          </div>
          <div className="customer-dropdown">
            <div
              className="small-card-info-text--detail-page"
              // data-tooltip={props?.array ? DisplayList(DATA?.[props?.keyName]).join("") : HOVER_TEXT}
              // data-inverted="true"
            >
              {/* {DISPLAY_NAME ? DISPLAY_NAME : ""} */}
              {DisplayList(DATA?.[props?.keyName]).join("")}
              {/* <span className="use-case-firmo-more-text">{MORE_LENGTH}</span> */}
            </div>
            <div className="customer-dropdown-content">
              {props?.array &&
                DATA?.[props?.keyName]?.map((item) => {
                  return <a>{item?.Customer_Name}</a>;
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
            data-tooltip={props?.name}
            data-inverted="true"
          >
            <img
              src={
                props?.keyName === "Use_Case_Customers"
                  ? require("../../../../assets/cardIcons/useCaseIcons/1.png")
                  : props?.keyName === "Use_Case_Industry"
                  ? require("../../../../assets/cardIcons/useCaseIcons/2.png")
                  : require("../../../../assets/cardIcons/useCaseIcons/1.png")
              }
              style={
                props?.keyName === "Use_Case_Industry"
                  ? { width: "20px", height: "20px", marginRight: "5px", marginTop: "-3px" }
                  : { width: "20px", height: "20px", marginRight: "5px" }
              }
            />
          </div>

          <div
            className="small-card-info-text--detail-page "
            data-tooltip={props?.array ? DisplayList(DATA?.[props?.keyName]).join("") : HOVER_TEXT}
            data-inverted="true"
          >
            {DISPLAY_NAME ? DISPLAY_NAME : ""}
            <span className="use-case-firmo-more-text">{MORE_LENGTH}</span>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default UseCaseInfoContent;
