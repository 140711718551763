import React from "react";
import FeatherIcon from "feather-icons-react";
import OptionsList from "./optionsList";
import { useState } from "react";
import { useEnCryptPostApi } from "../../../customHooks/customHooks";
import { usePostCommentForCardMutation } from "../../../../adapters/xhr/generalApi";
import ConfirmDeleteModal from "../../engagementBar/components/confirmDeleteModal";
import { useSelector } from "react-redux";
const CommentOptions = (props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [modalVisible, setModalVisible] = useState(false);
  const USER_ID = authData?.data?.userId;

  const DeleteComment = useEnCryptPostApi(
    `/comment/deleteComment`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  const handleDeletePressed = () => {
    setDeleteModalVisible((prev) => !prev);
  };

  const handleDeleteItem = (val) => {
    const rawData = {
      User_ID: USER_ID,
      Comment_GUID: props?.data?.commentGUID,
    };
    DeleteComment?.handleTrigger(rawData);
    handleDeletePressed();
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setModalVisible(false);
    }
  };
  return (
    <>
      {deleteModalVisible ? (
        <ConfirmDeleteModal
          closeModal={() => handleDeletePressed()}
          handleSubmit={() => handleDeleteItem()}
          type={"comment"}
        />
      ) : null}
      <div tabIndex={0} onBlur={handleBlur} className="flex">
        <FeatherIcon
          icon="more-horizontal"
          size="18"
          color={"#45464e"}
          role="button"
          onClick={() => setModalVisible((prev) => !prev)}
        />
        {modalVisible ? (
          <div className="comment-options-container  modern-card modern-card--elevated">
            <OptionsList
              props={props}
              data={props?.data}
              // handleDisableModal={() => setModalVisible(false)}
              handleDeletePressed={(val) => handleDeletePressed(val)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CommentOptions;
