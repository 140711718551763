import React from "react";

const ViewMoreList = (props) => {
  const permissionsDetails = props?.permissionsDetails;
  const cardType = props?.cardType;
  const canDelete = cardType == "product" && props?.canDelete ? true : cardType !== "product" ? true : false;
  return (
    <>
      {permissionsDetails?.Update ? (
        <div className="share-single-item">
          <div
            className="action-button-container"
            onClick={() => props?.handleActionClicked("edit")}
            style={{ width: "100%", padding: "5px" }}
          >
            <p className="share-desc-title">Edit</p>
          </div>
        </div>
      ) : null}
      {permissionsDetails?.Delete && canDelete ? (
        <div className="share-single-item">
          <div
            className="action-button-container"
            onClick={() => props?.handleActionClicked("delete")}
            style={{ width: "100%", padding: "5px" }}
          >
            {props?.blurbsPostData?.isLoading ? (
              <p className="share-desc-title">loading...</p>
            ) : (
              <p className="share-desc-title">Delete</p>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ViewMoreList;
