import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import InputFieldProfile from "../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../utlis/hoc/textArea/textArea";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import * as SITE_KEY from "../../config/configKeys";
import { usePostRequestMutation, useUpdateUserEmailMutation } from "../../adapters/xhr/generalApi";
import { validateForgotPassword, validateRequestVendor } from "../../utlis/hof/hof";
import InputFieldNew from "../auth/signUp/onBoard/components/InputFieldNew";
import * as CONFIG_KEYS from "../../config/config";
import { tempAuthDetails } from "../../contexts/reducer/authReducer";
import * as SECRET_KEYS from "../../config/configKeys";

const ValidateWorkEmail = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const VALIDATE_WORK_EMAIL = CONFIG_KEYS?.CONFIG?.DIRECT_MESSAGE.VALIDATE_WORK_EMAIL;
  const dispatch = useDispatch();
  const UpdateUserEmail = useEnCryptPostApi(`/user/updateUserEmail`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, useUpdateUserEmailMutation);

  const handleSubmit = (val) => {
    const rawData = {
      emailId: userEmail,
      userId: authData?.data?.userId,
    };
    const validate = validateForgotPassword({ emailId: userEmail });
    setError(validate);
    if (_.isEmpty(validate)) UpdateUserEmail?.handleTrigger(rawData);
  };

  useEffect(() => {
    if (UpdateUserEmail?.data?.status) {
      dispatch(
        tempAuthDetails({
          data: {
            ...UpdateUserEmail?.data,
          },
        })
      );
      props?.handleChangePage(3);
    } else {
      setError({ emailId: UpdateUserEmail?.data?.message });
    }
  }, [UpdateUserEmail?.data]);

  const HandleCloseModal = () => {
    props?.handleClose();
  };

  return (
    <div className="request-vendor-portal--main-wrapper">
      {/* {UpdateUserEmail?.data ? (
        <div
          style={{
            background: UpdateUserEmail?.data?.Status ? "#008000" : "#c00",
          }}
          className="success-message-pop-up"
        >`
          <h3 className="success-message-style-board">{RequestVendor?.data?.Message}</h3>
        </div>
      ) : null} */}
      <div className="add-to-board-container-header-wrapper">
        <div className="close-icon">
          <a className="slide-in__close-btn closeButton" onClick={() => HandleCloseModal()}>
            <i className="feather icon icon-x m-none"></i>
          </a>
        </div>
        <h1 className="request-new-vendor-header-text">{VALIDATE_WORK_EMAIL?.SUBMIT_REQUEST}</h1>
      </div>
      <div className="request-category-container">
        <div className="m-b-sm">
          <InputFieldNew
            label={"Business email"}
            value={userEmail}
            changeText={(val) => setUserEmail(val)}
            error={error?.emailId}
            icon={"mail"}
            type={"email"}
            maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
          />
        </div>
        <div className="flex justify-flex-end">
          <div className="request-category-submit-button" onClick={() => handleSubmit(3)}>
            {VALIDATE_WORK_EMAIL?.SUBMIT_REQUEST_BUTTON}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateWorkEmail;
