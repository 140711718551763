import React from "react";
import EngagementBar from "../../../../utlis/hoc/engagementBar/engagementBar";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";
import VENDOR_PLACEHOLDER from "../../../../assets/images/Vendor_Placeholder_Icon.svg";

const RelatedUseCaseCard = ({ data, props }) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const navigate = useNavigate();
  const USE_CASE_IMAGE = data?.Media?.valid
    ? data?.Media?.SRC
    : data?.image_src
    ? data?.image_src
    : data?.account_logo
    ? data?.account_logo
    : KEYS?.CORRUPT_IMAGE_ICON;
  return (
    <div className="related-case-studies-container">
      <img
        src={USE_CASE_IMAGE}
        className="related-case-studies-image"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
        }}
        alt="case study image"
      />

      <div
        className="flex"
        role="button"
        onClick={() =>
          navigate(
            `/vendor/${ReplaceBlankSpaceByHyphen(data?.Vendor_Name, data?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`
          )
        }
      >
        <img
          src={data?.account_logo ? data?.account_logo : VENDOR_PLACEHOLDER}
          className="related-case-studies-account-logo"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
          }}
          alt="vendor logo"
        />
        <p className="related-case-studies-vendor-name">{data?.Vendor_Name}</p>
      </div>
      <a
        title={data?.title_level_1}
        className="related-case-studies-card-title"
        href={`/${ReplaceBlankSpaceByHyphen(data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
          BreakText(data?.title_level_1?.trim())
        )}-${data?.blurbID?.trim()}`}
      >
        {data?.title_level_1}
      </a>
      <a
        className="related-case-studies-card-description"
        href={`/${ReplaceBlankSpaceByHyphen(data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
          BreakText(data?.title_level_1?.trim())
        )}-${data?.blurbID?.trim()}`}
      >
        {parse(data?.description)}
      </a>
      <div style={{ width: isMobileOnly ? "100%" : "50%" }}>
        <EngagementBar
          shareLink={`/${ReplaceBlankSpaceByHyphen(data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
            BreakText(data?.title_level_1?.trim())
          )}-${data?.blurbID}`}
          page={props?.page}
          data={data}
          cardType={"usecase"}
          engageSource={"usecase engagement from detail page"}
          keyName={"blurbID"}
          commentDisable={true}
          // handleViewComments={() => setViewComments((prev) => !prev)}
          // commentCount={commentCount}
        />
      </div>
    </div>
  );
};

export default RelatedUseCaseCard;
