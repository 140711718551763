import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import EngagementBar from "../../../../utlis/hoc/engagementBar/engagementBar";
import { BreakText, GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import BlurbCard from "./blurbCard";
import BlurbCTA from "./blurbCTA";
import BlurbDescription from "./blurbDescription";
import BlurbHeader from "./blurbHeader";
import BlurbImage from "./blurbImage";
import BlurbSocial from "./blurbSocial";
import BlurbTags from "./blurbTags";
import BlurbTitle from "./blurbTitle";
import Comments from "../../../../utlis/hoc/comments/comments";
import { useState } from "react";
import { isMobileOnly } from "react-device-detect";

const Blurb = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const [viewComments, setViewComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);

  const USER_ID = authData?.data?.userId;

  //to get the id from the url of blurb id
  const BLURB_DETAILS = PARAMS?.name;
  let BLURB_ID = BLURB_DETAILS?.split("-").at(-1);

  const GetApiData = useEnCryptGetApi(
    `/getBlurbById?blurbId=${BLURB_ID}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const Data = GetApiData?.data?.data;

  const handleComment = () => {
    setCommentCount((prev) => prev + 1);
  };

  return Data?.map((item) => {
    props?.handleVendorId(item?.object?.Vendor_ID);
    props?.handleProductId(item?.object?.Product_ID);
    props?.handleBlurbData(item?.object);
    return (
      <>
        <BlurbCard>
          <BlurbSocial data={item?.object} />
          <BlurbTitle data={item?.object} />
          <BlurbHeader data={item?.object} page={props?.page} />
          {/* <div className="blurb-detail-content mobile-hidden">
            <div style={{ width: "70%" }}>
              <BlurbDescription data={item?.object} />
            </div>
            <div
              style={{
                display: "flex",
                width: "30%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BlurbImage data={item?.object} />
            </div>
          </div> */}
          {item?.object?.description?.length > 980 && !isMobileOnly ? (
            <BlurbDescription data={item?.object} />
          ) : (
            <BlurbImage data={item?.object} page={props?.page} />
          )}

          <div className="blurb-detail-footer-container">
            <BlurbCTA data={item?.object} page={props?.page} />

            <div style={{ width: "300px", marginBottom: "30px" }} className="flex-container">
              <EngagementBar
                shareLink={`/${ReplaceBlankSpaceByHyphen(item?.object?.Vendor_Name)}/blurb/${ReplaceBlankSpaceByHyphen(
                  BreakText(item?.object?.title_level_1?.trim())
                )}-${item?.object?.blurbID}`}
                page={props?.page}
                data={item?.object}
                cardType={"blurb"}
                engageSource={"blurb engagement from detail page"}
                keyName={"blurbID"}
                handleViewComments={() => setViewComments((prev) => !prev)}
                commentCount={commentCount}
              />
            </div>
          </div>
          {viewComments && (
            <Comments
              cardType={"blurb"}
              data={item?.object}
              page={props?.page}
              searchText={props?.searchText}
              keyName={"blurbID"}
              handleCommentCount={(val) => handleComment(val)}
              fullWidth={true}
            />
          )}
        </BlurbCard>
      </>
    );
  });
};

export default Blurb;
