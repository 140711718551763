import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import AccountType from "./components/accountType";
import BuyerForm from "./components/buyerForm";
import { useNavigate } from "react-router";
import RoleSelection from "./components/roleSelection";

const UserDetailsForm = (props) => {
  const [consumerAccount, setConsumerAccount] = useState(true);
  const [vendorRole, setVendorRole] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const navigate = useNavigate();
  props?.handleStatus(consumerAccount);
 
  return (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        <div className="only-mobile">
          <img
            className=".octate-mobile"
            width="150px"
            height="50px"
            style={{ marginRight: "8px" }}
            src={require("../../../../assets/images/octateLogo181527.svg").default}
            alt={"Octate AI logo"}
            onClick={() => navigate("/")}
          />
        </div>
        <h4 className="already-account-text m-b-mini ">
          {ONBOARDING?.ALREADY_ACCOUNT}{" "}
          <a onClick={() => navigate("/sign-in")} role="button" style={{ color: "#1371c3" }}>
            {ONBOARDING?.SIGN_IN}
          </a>
        </h4>
        <div class="user-registration-container">
          <div className="sign-up-page-header-text m-b-sm m-t-md ">{ONBOARDING?.SIGN_UP_TEXT}</div>
          {/* <div className="sign-up-page-text m-b-sm">
            Please provide following details
          </div> */}
          <div className="sign-up-page-text m-b-sm">{ONBOARDING?.ONBOARDING_SUBTEXT} </div>
          <div className="m-b-sm">
            <AccountType handleToggleAccount={(val) => setConsumerAccount(val)} accountType={consumerAccount} />
          </div>
          {consumerAccount ? null : <RoleSelection handleRole={(val) => setVendorRole(val)} />}
          <BuyerForm consumerAccount={consumerAccount} page={props?.page} vendorRole={vendorRole} />
        </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
