import React, { useEffect, useState } from "react";
// import * as KEYS from "../../../config/config";
import { EncryptApiGetData, ReplaceBlankSpaceByHyphen } from "../../../utlis/hof/hof";
import { useGetAllProductCategoryQuery } from "../../../adapters/xhr/searchService";
import { DecryptApi } from "../../../utlis/hof/hof";
import { useDispatch } from "react-redux";
// import { productData } from "../homeReducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import * as SECRET_KEYS from "../../../config/configKeys";
import { Link } from "react-router-dom";
const ExploreData = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const MATRIX = useSelector((state) => state?.authDetails?.octateMatrix);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const CATEGORIES_COUNT = MATRIX?.length ? MATRIX.find((o) => o.Label === KEYS?.MATRIX?.CATEGORIES) : null;
  const navigation = useNavigate();
  const EXPLORE_DATA = KEYS?.EXPLORE_DATA;
  const productData = useSelector((state) => state?.homeDetails?.productCategoryData);
  const signalTrackingCategoryObject = {
    Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
    page: "category page",
    section: "explore categories",
  };
  const signalTrackingAllCategoriesObject = {
    Action: SECRET_KEYS.CONFIG.ALL_CATEGORIES_CLICKED,
    page: "all categories page",
    section: "explore categories",
    context: "all categories",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleExploreCategoryLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingCategoryObject?.Action,
      Item_Type: "Category",
      Item_Id: val?.Product_Category_ID,
      Target: {
        page: signalTrackingCategoryObject?.page,
        link: `/category/${ReplaceBlankSpaceByHyphen(val?.Product_Category_Name, val?.Product_Category_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE
        }`,
      },
      Object: {
        section: signalTrackingCategoryObject?.section,
        context: val?.Product_Category_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleExploreAllCategoriesLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingAllCategoriesObject?.Action,
      Item_Type: signalTrackingAllCategoriesObject?.context,
      Item_Id: null,
      Target: {
        page: signalTrackingAllCategoriesObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingAllCategoriesObject?.section,
        context: signalTrackingAllCategoriesObject?.context,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  // const {
  //   data: allProductCategoryData,
  //   isError: allProductCategoryIsError,
  //   isLoading: allProductCategoryIsLoading,
  // } = useGetAllProductCategoryQuery(
  //   { enCryptProductCategory },
  //   { skip: enCryptProductCategory?.data ? false : true }
  // );

  // useEffect(() => {
  //   const enCryptData = {
  //     // https://thebox-dev.portqii.com/search_backend/getAllProductCategory
  //     path: `/getAllProductCategory`,
  //     service: KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
  //     userInfo: { deviceId: "ffbe445", userId: 234 },
  //   };
  //   const enCryptProductCategory = EncryptApiGetData(enCryptData);
  //   setEnCryptProductCategory(enCryptProductCategory);
  // }, []);

  // useEffect(() => {
  //   const DeCryptData = DecryptApi(allProductCategoryData);
  //   if (DeCryptData?.data) {
  //     setDeCryptProductCategoryList(DeCryptData);
  //     dispatch(productData(DeCryptData));
  //   }
  // }, [allProductCategoryData]);

  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
    });
  }

  return !productData ? null : (
    <div className="dtrd-landing-page">
      <div className="landing-page__section light_blue_background">
        <div className="ui container" style={{ maxWidth: "1120px" }}>
          <div className="landing-page__section-header">
            <div className="section-header__content">
              <h2 className="landing-page__section-title">{EXPLORE_DATA?.HEADER}</h2>
              {CATEGORIES_COUNT ? (
                <div className="landing-page__section-subtitle">
                  {EXPLORE_DATA?.SUB_HEADER1} {CATEGORIES_COUNT?.Count} {EXPLORE_DATA?.SUB_HEADER2}
                </div>
              ) : null}
            </div>
          </div>

          <div className="explore-data-grid">
            {productData?.data?.slice(0, 9)?.map((item) => {
              return (
                <div onClick={() => handleExploreCategoryLinkClicked(item)}>
                  <Link
                    to={`/category/${ReplaceBlankSpaceByHyphen(
                      item?.Product_Category_Name,
                      item?.Product_Category_ID
                    )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                  >
                    <a
                      className="tag-label tag-label--yellow"
                      role="button"
                      // href={`/category/${ReplaceBlankSpaceByHyphen(
                      //   item?.Product_Category_Name,
                      //   item?.Product_Category_ID
                      // )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
                      key={item?.Product_Category_ID}
                    >
                      <span>{toTitleCase(item?.Product_Category_Name)}</span>
                    </a>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* <div className="m-t-mini">
            <a className="landing-page__section-link" href="/categories">
              {EXPLORE_DATA?.VIEW_ALL}
            </a>
          </div> */}
          {productData?.data?.length == 9 || productData?.data?.length > 9 ? (
            <div className="m-t-sm">
              <a
                className="button primary outlined"
                onClick={() => handleExploreAllCategoriesLinkClicked(`/categories`)}
                href="/categories"
              >
                {EXPLORE_DATA?.VIEW_ALL}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExploreData;
