import React from "react";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import VendorFaq from "./vendorFaq";

const ProductFaq = (props) => {
  const [viewFAQ, setViewFAQ] = useState(true);
  const data = props?.data;
  const ObjectKey = props?.ObjectKey;
  return data?.[ObjectKey] ? (
    <>
      <div
        style={{ background: "#CED0E0", padding: "8px 15px 8px 15px", borderRadius: "4px" }}
        className="flex space-between align-items-center m-l-mini m-t-sm"
      >
        <div className="frequently-asked-questions-product-title">{ObjectKey}</div>
        <FeatherIcon
          icon={viewFAQ ? "chevron-down" : "chevron-right"}
          size="20"
          role="button"
          // className="blurb-detail-social"
          color={"#ABAFB1"}
          onClick={() => setViewFAQ(!viewFAQ)}
        />
      </div>
      <div class="dtrd-faq dtrd-faq--static m-l-mini">
        {viewFAQ &&
          data?.[ObjectKey]?.map((item) => {
            return (
              // <div class="dtrd-faq__qa-wrapper" key={item?.FAQ_ID}>
              //   <p class="dtrd-faq__question">
              //     {item?.Question}
              //     <span style={{ position: "absolute" }}>
              //       {props?.type !== "buyer" ? (
              //         <FeatherIcon
              //           icon="edit-3"
              //           size="20"
              //           role="button"
              //           // className="blurb-detail-social"
              //           color={"#ABAFB1"}
              //           onClick={() => props?.handleEditFAQ(item)}
              //           style={{ marginLeft: "10px" }}
              //         />
              //       ) : null}
              //     </span>
              //   </p>
              //   <p class="dtrd-faq__answer">{item.Answer}</p>
              // </div>
              <VendorFaq
                getOctateVendorID={props?.getOctateVendorID}
                data={item}
                permissionsDetails={props?.permissionsDetails}
                handleEditFAQ={() => props?.handleEditFAQ(item)}
              />
            );
          })}
      </div>
    </>
  ) : null;
};

export default ProductFaq;
