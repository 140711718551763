import React from "react";

const BoardRoundSpinner = () => {
  return (
    <div class="lds-spinner board-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BoardRoundSpinner;
