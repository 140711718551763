import { set } from "lodash";
import React, { useEffect, useState } from "react";
import RequestCategory from "../../../../components/searchResult/components/allCategories/component/requestCategory/requestCategory";

const DropdownNewOptions = (props) => {
  const [filteredData, setFilteredData] = useState(props?.data);
  const INPUT_TEXT = props?.removeFiltering ? "" : props?.inputText || "";
  const name = props?.name ? props?.name : "name";
  useEffect(() => {
    handleFilteredData();
  }, [props?.data, INPUT_TEXT]);
  const handleFilteredData = () => {
    if (INPUT_TEXT?.length) {
      const rawData = props?.data?.length
        ? props?.data?.filter((item) => {
            return item?.[name]?.toLowerCase()?.includes(INPUT_TEXT?.toLowerCase());
          })
        : [];
      setFilteredData(rawData);
    } else {
      setFilteredData(props?.data);
    }
  };

  return (
    <>
      {filteredData?.length > 0 ? (
        <ul
          // class="menu transition visible"
          tabindex="10"
          // style={{ display: "block !important" }}
          className="single-dropdown-container"
        >
          {filteredData?.map((item) => {
            return (
              <div
                className="single-dropdown-item"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => props?.handleSelectItem(props?.returnObject ? item : item?.[name])}
                role={"button"}
              >
                {props?.flag ? <i title="Eritrea" class={`flag ${item?.code?.toLowerCase()}`}></i> : null}

                <li class="item">
                  {item?.[name]}{" "}
                  <span>
                    {props?.enableViewDomain && (
                      <span className="dropdown-domain">{item?.Customer_Skeleton_Domain}</span>
                    )}
                  </span>
                </li>
                {props?.rightSideContent ? (
                  <div className="add-blurb-content-type-level">{item?.[props?.rightName]}</div>
                ) : null}
              </div>
            );
          })}
        </ul>
      ) : props?.type == "ProductCategory" ? (
        <ul tabindex="10" className="single-dropdown-container">
          <span class="next-gen-select__selected">
            Didn't find right category?{" "}
            <span style={{ cursor: "pointer", color: "#5570f1" }} onClick={() => props?.requestCategory()}>
              Create new
            </span>
          </span>
        </ul>
      ) : props?.name === "Customer_Name" && INPUT_TEXT?.length >= 3 ? (
        <ul tabindex="10" className="single-dropdown-container">
          <span class="next-gen-select__selected">
            Didn't find customer you are looking?{" "}
            <span style={{ cursor: "pointer", color: "#5570f1" }} onClick={() => props?.requestCustomer()}>
              add new
            </span>
          </span>
        </ul>
      ) : INPUT_TEXT?.length ? (
        <ul tabindex="10" className="single-dropdown-container">
          <li class="item">{`No results found ${INPUT_TEXT && "for"} "${INPUT_TEXT && INPUT_TEXT}"`}</li>
        </ul>
      ) : null}
    </>
  );
};

export default DropdownNewOptions;
