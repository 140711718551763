import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import FeatherIcon from "feather-icons-react";
import { validateCompanyProfileLocationForm } from "../../../utlis/hof/hof";
import InputFieldSwitch from "./inputFieldSwitch";
const CompanyAddLocations = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const COMPANY_PROFILE = KEYS?.COMPANY_PROFILE;
  const [error, setError] = useState(null);
  const [toggleSwitchClicked, setToggleSwitchClicked] = useState(false);
  const [companyProfileLocationForm, setCompanyProfileLocationForm] = useState(
    {}
  );
  const [locationDetails, setLocationDetails] = useState({});
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  //   const props?.locationDetailsList = props?.data
  const GetCountryData = useEnCryptGetApi(
    `/s3Processor/getCountryList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const DropDownData = GetCountryData?.data?.data?.allCountries;
  const handleAddLocationClicked = () => {
    const details = { ...locationDetails };
    const Validate = validateCompanyProfileLocationForm(details);
    setCompanyProfileLocationForm(Validate);
    let arrayOfLocation = props?.locationDetailsList?.length
      ? props?.locationDetailsList
      : [];
    if (!Object.keys(Validate)?.length) {
      arrayOfLocation.push(details);
      props?.handleLocationDetails(arrayOfLocation);
      setLocationDetails({
        Building_Name: "",
        Address: "",
        Country: "",
        Pincode: "",
      });
    }
  };
  const handleRemoveLocation = (index) => {
    const data = [...props?.locationDetailsList];
    data.splice(index, 1);
    props?.handleLocationDetails(data);
  };
  const handleEditLocationDetails = (list, index) => {
    let data = [...props?.locationDetailsList];
    // const a3 = userRecommendationData.map((t1) => ({
    //   ...t1,
    //   ...result.find((t2) => t1.id !== t2.Vendor_ID),
    // }));
    // const updatedData = data?.map((val) => {
    //   if (val?.id == list?.id) {
    //     return list;
    //   } else {
    //     return val;
    //   }
    // });
    data[index] = list;
    props?.handleLocationDetails(data);
  };
  const handleSwitchChanged = (val) => {
    setToggleSwitchClicked(val);
  };
  return (
    <section>
      <div className="communication-setup-section-2-header-wrapper">
        <h3 className="communication-setup-section-2-header-location-text">
          {`Locations (${
            props?.locationDetailsList?.length
              ? props?.locationDetailsList?.length
              : 0
          })`}
        </h3>
        <div
          onClick={handleAddLocationClicked}
          className="communication-setup-section-2-header-location-button"
        >
          {/* <InputFieldSwitch
            handleChange={handleSwitchChanged}
            checked={toggleSwitchClicked}
          /> */}
          {COMPANY_PROFILE?.ADD_LOCATION}
        </div>
      </div>
      <div
        key={{ ...locationDetails }}
        className={`${
          props?.locationDetailsList?.length
            ? "communication-setup-location-list-main-wrapper m-b-md"
            : "communication-setup-location-main-wrapper"
        }`}
      >
        <div className="p-relative-company">
          {locationDetails?.Building_Name ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.BUILDING_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.BUILDING_NAME_LABEL}
            value={locationDetails?.Building_Name}
            changeText={(val) =>
              setLocationDetails({ ...locationDetails, Building_Name: val })
            }
            error={companyProfileLocationForm?.Building_Name}
            removeBottomPadding={true}
          />
        </div>
        <div className="p-relative-company">
          {locationDetails?.Address ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.ADDRESS}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.ADDRESS_LABEL}
            value={locationDetails?.Address}
            changeText={(val) =>
              setLocationDetails({ ...locationDetails, Address: val })
            }
            error={companyProfileLocationForm?.Address}
            removeBottomPadding={true}
          />
        </div>

        <div class="p-relative-company" id="field-user__country_id">
          {locationDetails?.Country ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.COUNTRY}
            </label>
          ) : null}

          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              setLocationDetails({ ...locationDetails, Country: val })
            }
            data={DropDownData}
            placeholder={COMPANY_PROFILE?.COUNTRY}
            flag={true}
            selectedVal={locationDetails?.Country}
            name={"name"}
          />
          {companyProfileLocationForm?.Country ? (
            <span class="input-field-text-error">
              {companyProfileLocationForm?.Country}
            </span>
          ) : null}
        </div>
        <div className="p-relative-company">
          {locationDetails?.Pincode ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.PINCODE}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.PINCODE_LABEL}
            value={locationDetails?.Pincode}
            changeText={(val) =>
              setLocationDetails({ ...locationDetails, Pincode: val })
            }
            error={companyProfileLocationForm?.Pincode}
            removeBottomPadding={true}
          />
        </div>
        {props?.locationDetailsList?.length ? <div></div> : null}
      </div>
      {props?.locationDetailsList?.length
        ? props?.locationDetailsList.map((list, index) => {
            return (
              <div className="communication-setup-location-list-main-wrapper">
                <div className="flex flex-column">
                  <label
                    className="company-details-label-input-field-filled"
                    for="user__email"
                  >
                    {COMPANY_PROFILE?.BUILDING_NAME}
                  </label>
                  <InputFieldProfile
                    label={COMPANY_PROFILE?.BUILDING_NAME_LABEL}
                    value={list?.Building_Name}
                    changeText={(val) =>
                      handleEditLocationDetails(
                        {
                          ...list,
                          Building_Name: val,
                        },
                        index
                      )
                    }
                    error={error?.firstName}
                    removeBottomPadding={true}
                  />
                </div>

                <div className="flex flex-column">
                  <label
                    className="company-details-label-input-field-filled"
                    for="user__email"
                  >
                    {COMPANY_PROFILE?.ADDRESS}
                  </label>
                  <InputFieldProfile
                    label={COMPANY_PROFILE?.ADDRESS_LABEL}
                    value={list?.Address}
                    changeText={(val) =>
                      handleEditLocationDetails(
                        {
                          ...list,
                          Address: val,
                        },
                        index
                      )
                    }
                    error={error?.Address}
                    removeBottomPadding={true}
                  />
                </div>

                <div
                  class="field-company-profile"
                  id="field-user__country_id"
                  className="flex flex-column"
                >
                  <label
                    for="user__email"
                    className="company-details-label-input-field-filled"
                  >
                    {COMPANY_PROFILE?.COUNTRY}
                  </label>
                  <SingleSelectNewDropdown
                    handleSelectedData={(val) =>
                      handleEditLocationDetails(
                        {
                          ...list,
                          Country: val,
                        },
                        index
                      )
                    }
                    data={DropDownData}
                    placeholder={COMPANY_PROFILE?.COUNTRY_LABEL}
                    flag={true}
                    selectedVal={list?.Country}
                    name={"name"}
                  />
                </div>

                <div className="flex flex-column">
                  <label
                    for="user__email"
                    className="company-details-label-input-field-filled"
                  >
                    {COMPANY_PROFILE?.PINCODE}
                  </label>
                  <InputFieldProfile
                    label={COMPANY_PROFILE?.PINCODE_LABEL}
                    value={list?.Pincode}
                    changeText={(val) =>
                      handleEditLocationDetails(
                        {
                          ...list,
                          Pincode: val,
                        },
                        index
                      )
                    }
                    error={error?.firstName}
                    removeBottomPadding={true}
                  />
                </div>
                <div
                  onClick={() => handleRemoveLocation(index)}
                  className="company-details-remove-company-location-icon m-t-xs"
                >
                  <FeatherIcon icon="x" size="20" />
                </div>
              </div>
            );
          })
        : null}
    </section>
  );
};

export default CompanyAddLocations;
