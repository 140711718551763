import React from "react";
import TabNavigator from "../../../utlis/hoc/tabNavigator/tabNavigator";
import { detectMob } from "../../../utlis/hof/hof";

const RelatedBlurbsTab = (props) => {
  const BLURB_DETAIL_PAGE_TAB = [
    {
      label: "Product",
      value: "product",
      display: true,
    },
    {
      label: "Vendor",
      value: "vendor",
      display: true,
    },
    {
      label: "Related Category",
      value: "categories",
      display: true,
    },
    {
      label: "Related Topics",
      value: "topics",
      display: true,
    },
  ];
  const MOBILE_DEVICE = detectMob();

  return (
    <>
      <h2 className="p-l-xs">Blurbs from</h2>
      <div className="flex tab-navigator-container">
        {BLURB_DETAIL_PAGE_TAB?.map((item, index) => {
          if (item?.display || MOBILE_DEVICE)
            return (
              <TabNavigator
                tabIndex={"product"}
                tabItem={item}
                index={index + 1}
                navigatePath={"blurbDetail"}
                page={props?.page}
              />
            );
        })}
      </div>
    </>
  );
};

export default RelatedBlurbsTab;
