import React from "react";
import { useSelector } from "react-redux";
import TabNavigator from "../../../../utlis/hoc/tabNavigator/tabNavigator";
import { detectMob } from "../../../../utlis/hof/hof";
import ProductDetails from "./components/productDetails";
import ProductEngagement from "./components/productEngagement";

const ProductHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PRODUCT_TABS = KEYS?.PRODUCT_PAGE_TAB;

  const MOBILE_DEVICE = detectMob();

  return (
    <div className="business-profile-container">
      <ProductDetails page={props?.page} handleProductData={props?.handleProductData} />
      <div className="flex tab-navigator-container">
        {PRODUCT_TABS?.map((item, index) => {
          if (item?.display || MOBILE_DEVICE)
            return (
              <TabNavigator
                page={props?.page}
                tabIndex={props?.tabIndex}
                tabItem={item}
                index={index + 1}
                navigatePath={"product"}
              />
            );
        })}
      </div>
    </div>
  );
};

export default ProductHeader;
