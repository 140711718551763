import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import ReactDom from "react-dom";
import BoardList from "./boardList/boardList";
import CreateNewBoard from "./createNewBoard";
import "../../../styles/boards.css";
import { useEnCryptGetApi } from "../../customHooks/customHooks";
import { useGetMyBoardsDataQuery } from "../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import { includes } from "lodash";
import * as CONFIG_KEYS from "../../../config/config";
const AddToBoard = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const BoardPermissions = allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS];
  const SuperAdminPermissions = allPermissionsDetails?.vendorId;
  const page = props?.page;
  const USER_ID = authData?.data?.userId;
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData.data?.vendorId;
  const [CreateNewModalVisible, setCreateNewModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const cardType = props?.cardType;
  const keyName = props?.keyName;
  const data = props?.data;
  const CanCreateBoard =
    props?.page == "business profile" || (props?.page == "vendor page" && SuperAdminPermissions) ? true : false;
  const canCreateBoardButton = CanCreateBoard && BoardPermissions ? BoardPermissions?.Create : true;
  const handleItemsSuccessfullyAdded = (val) => {
    if (val?.message) {
      setSuccessMessage(val);
      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
    }
    if (val?.status) {
      setTimeout(() => {
        props?.handleClose("item-added");
      }, 1000);
    }
  };
  const RequestApi = CanCreateBoard
    ? `/getUserBoards?userId=${USER_ID}&Vendor_ID=${vendorId}`
    : `/getUserBoards?userId=${USER_ID}`;
  const GetApiData = useEnCryptGetApi(RequestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, useGetMyBoardsDataQuery);
  const getAllBoardsList = CanCreateBoard
    ? GetApiData?.data?.data?.vendorCreatedBoards?.length
      ? GetApiData?.data?.data?.vendorCreatedBoards
      : []
    : GetApiData?.data?.data?.userCreatedBoards?.length
    ? GetApiData?.data?.data?.userCreatedBoards
    : [];
  const handleFilteredData = (e) => {
    setFilterText(e.target.value);

    if (e.target.value) {
      const data1 = getAllBoardsList?.filter((list) => {
        return list.Name?.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setFilteredData(data1?.length ? data1 : []);
    } else {
      setFilteredData(getAllBoardsList);
    }
  };

  return ReactDom.createPortal(
    <>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper">
          {successMessage?.message ? (
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                background: successMessage?.status ? "#008000" : "#c00",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
            >
              <h3 className="success-message-style-board">{successMessage?.message}</h3>
            </div>
          ) : null}
          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => props?.handleClose()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Pick your board</h1>
            <div className="filter-search-input-container m-x-base">
              <FeatherIcon icon="search" size="20" color={"#394856"} className="filter-input-field-search-icon" />
              <input
                className="filter-search-input"
                placeholder={`Search boards`}
                onChange={(e) => handleFilteredData(e)}
                autoFocus={true}
              />
            </div>
            {canCreateBoardButton ? (
              <div className="flex space-between m-y-mini">
                <h3 className="my-boards-text">My Boards</h3>
                <div className="filter-search-apply-button" onClick={() => setCreateNewModalVisible((prev) => !prev)}>
                  Create New
                </div>
              </div>
            ) : null}
          </div>
          <div className="boards-list-main-wrapper">
            <BoardList
              page={page}
              filterText={filterText}
              dataType={props?.dataType}
              handleItemsSuccessfullyAdded={handleItemsSuccessfullyAdded}
              data={data}
              boardListData={filterText ? filteredData : getAllBoardsList}
              keyName={keyName}
              cardType={cardType}
              isLoading={GetApiData?.isLoading}
            />
          </div>
        </div>
      </div>
      {CreateNewModalVisible ? (
        <CreateNewBoard page={page} handleClose={() => setCreateNewModalVisible(false)} />
      ) : null}
    </>,
    document.getElementById("portal")
  );
};

export default AddToBoard;
