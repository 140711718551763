import { useRouteError } from "react-router-dom";
import React from "react";
export default function PageNotFound() {
  const error = useRouteError();
  console.log('error in 404 page is');
  console.log(error);
  console.log('error in 404 page is');
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffff",
        textAlign: "center",
        fontFamily:'helvetica'
      }}
      id="error-page"
    >
      <div>
        <img
          style={{ height: "40vh", width: "30vw" }}
          alt="Image of page not found"
          class=" ls-is-cached lazyloaded"
          data-src={require("../../assets/images/pageNotFound.png")}
          src={require("../../assets/images/pageNotFound.png")}
        />
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p style={{ color: "#827B76" }}>
          <i>Page {error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}
