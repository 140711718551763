import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestCrudMutation, usePutRequestCrudMutation } from "../../adapters/xhr/crudServiceApi";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { validateFAQ } from "../../utlis/hof/hof";
import _ from "lodash";
import CompanyDetailsFooter from "../companyProfile/component/companyDetailsFooter";
import CompanyDetailsHeader from "../companyProfile/component/companyDetailsHeader";
import FaqForm from "./components/faqForm";
import * as ENV_KEYS from "../../config/configKeys";
const AddFaq = (props) => {
  const [faqDetails, setFaqDetails] = useState({});
  const [SelectedProductId, setSelectedProductId] = useState([]);
  const [error, setError] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const navigate = useNavigate();
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;

  useEffect(() => {
    setFaqDetails(props?.data);
  }, [props?.data]);

  const ADD_FAQ = KEYS?.ADD_FAQ;
  const footerList = [
    {
      name: "Faq Details",
      id: 1,
    },
  ];
  const vendorPostData = useEnCryptPostApi(
    `/vendor/productOnboarding`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (vendorPostData?.data?.status) {
      setUpdateStatus(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [vendorPostData?.data]);
  const editFaq = props?.data?.FAQ_ID;
  const RequestMethod = editFaq ? usePutRequestCrudMutation : usePostRequestCrudMutation;
  const RequestApi = editFaq ? `/addFrequentlyAskedQuestionsUpdate` : `/addFrequentlyAskedQuestions`;
  const PostProductFAQ = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, RequestMethod);

  const handleFooterNumberClicked = (val) => {};
  const handleDetails = (val) => {
    setFaqDetails(val);
  };

  const handleSaveClicked = () => {
    const rawData = {
      ...faqDetails,
      Vendor_Id: vendorId,
    };
    const validate = validateFAQ(rawData);
    setError(validate);
    if (_.isEmpty(validate)) PostProductFAQ?.handleTrigger(rawData);
  };

  useEffect(() => {
    if (PostProductFAQ?.data?.Status) {
      props?.handleClose();
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: editFaq ? ENV_KEYS.CONFIG.FAQ_UPDATED : ENV_KEYS.CONFIG.FAQ_CREATED,
        Item_Type: "FAQ",
        Item_Id: props?.data?.FAQ_ID ? props?.data?.FAQ_ID : PostProductFAQ?.data?.FAQ_ID,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: null,
          context: editFaq ? `FAQ updated` : `FAQ created`,
          page: "/business-profile/faq",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [PostProductFAQ?.data]);

  return (
    <div className="company-profile-main-wrapper">
      <div className="company-details-header-main-wrapper-ref">
        <CompanyDetailsHeader
          productPageLabel={props?.data ? ADD_FAQ?.FAQ_PAGE_TITLE_EDIT : ADD_FAQ?.FAQ_PAGE_TITLE}
          handleClose={props?.handleClose}
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="company-profile-details-main-container-ref">
        <FaqForm vendorId={vendorId} faqDetails={faqDetails} handleDetails={handleDetails} error={error} />
      </div>
      <div className="company-details-footer-main-wrapper">
        <CompanyDetailsFooter
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={vendorPostData}
        />
      </div>
    </div>
  );
};

export default AddFaq;
