import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../../config/config";
// import AddQuestionType from "./addQuestionType";
// import AddQuestionTypeList from "./addQuestionTypeList";
import {
  TrackAnswerChangedAdded,
  validateProposalAnswerFormError,
  validateProposalQuestionFormError,
} from "../../../../../utlis/hof/hof";
import AddQuestionTypeList from "../../../../addProposal/component/addSectionAndQuestionPortal/addQuestionTypeList";
import AddQuestionType from "../../../../addProposal/component/addSectionAndQuestionPortal/addQuestionType";
import TextArea from "../../../../../utlis/hoc/textArea/textArea";
import DatePicker from "react-datepicker";
import AnswersCheckbox from "./answersCheckbox";
const EditAnswer = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const addType = props?.addType;
  //   const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [fields, setFields] = useState(addType == "section" ? {} : []);
  const [proposalQuestionFormError, setProposalQuestionFormError] = useState(
    {}
  );
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const questionListIndex = props?.questionListIndex;
  const proposalBasicDetails = fields;
  const data = addType == "section" ? props?.data : props?.data;
  useEffect(() => {
    setFields(
      addType == "section" ? props?.data : questionListIndex ? props?.data : {}
    );
  }, [data]);

  const questionTypeList = (val, questionIndex) => {
    let metaData = fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]
      ?.length
      ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]
      : [];
    if (fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button") {
      const radioButtonData = metaData?.map((value, index) => {
        if (index == questionIndex) {
          return {
            ...value,
            [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: true,
          };
        } else {
          return {
            ...value,
            [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: false,
          };
        }
      });
      metaData = radioButtonData;
    } else {
      metaData[questionIndex] = val;
    }
    setFields({
      ...fields,
      [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: metaData,
    });
  };
  const handleSaveClicked = () => {
    let validateErrors = {};
    if (
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button"
    ) {
      validateErrors = validateProposalAnswerFormError(fields, "checkbox");
    } else {
      validateErrors = validateProposalAnswerFormError(fields, "text");
    }
    setProposalQuestionFormError(validateErrors);

    setTimeout(() => {
      setProposalQuestionFormError({});
    }, 3000);
    if (!Object.keys(validateErrors)?.length) {
      const TrackSingleAnswerChanged = TrackAnswerChangedAdded(
        props?.data,
        fields
      );
      if (TrackSingleAnswerChanged) {
        props?.handleSaveClicked({ ...fields, Answered: true, edited: false });
      } else {
        props?.handleSaveClicked({ ...fields, Answered: true, edited: true });
      }
    }
  };
  const handleChangeText = (val) => {
    let metaData = {};
    if (fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Date Picker") {
      metaData[ADD_QUESTION_CONFIG_KEYS?.ANSWER] = val
        ? val.getTime() / 1000
        : false;
    } else {
      metaData[ADD_QUESTION_CONFIG_KEYS?.ANSWER] = val;
    }

    setFields({
      ...fields,
      [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: metaData,
    });
  };
  return (
    <div
      className={`confirm-delete-portal-main-wrapper ${
        addType == "section" ? "" : "confirm-delete-portal-main-wrapper--width"
      } p-r`}
    >
      <div className="add-section-and-question-header-wrapper">
        <div>
          <h3 className="add-section-and-question-content-header-text">
            {addType == "section"
              ? PROPOSAL_ADD_SECTION?.SECTION_HEADER
              : `${questionListIndex ? "Edit" : "Add"} Question (${props?.questionLength ? props?.questionLength : 1})`}
          </h3>
        </div>
        <div
          onClick={props?.handleClose}
          className="dis-f-r cursor--pointer"
          style={{
            borderRadius: "5px",
            border: "1px solid #ffff",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "5px 10px",
          }}
        >
          <FeatherIcon icon="x" size="12" color="#ffffff" />
        </div>
      </div>
      <div>
        <h6 className="vendor-proposal-edit-answer-question-text">
          {fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_NAME]}
        </h6>
      </div>

      {fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button" ? (
        <AnswersCheckbox
          inputType={fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ? "checkbox" : "radio"}
          questionTypeList={questionTypeList}
          fields={fields}
          proposalQuestionFormError={proposalQuestionFormError}
        />
      ) : fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Date Picker" ? (
        <div className="date-field-wrapper-position-relative m-x-mini">
          <FeatherIcon
            icon={"calendar"}
            size="17"
            color={"#6E7079"}
            style={{ zIndex: 20, margin: "0px 0px 5px 0px" }}
            className="input-icons"
          />
          <DatePicker
            placeholderText={"Select your Timeline"}
            selected={
              proposalBasicDetails?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.[ADD_QUESTION_CONFIG_KEYS?.ANSWER]
                ? proposalBasicDetails?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.[
                    ADD_QUESTION_CONFIG_KEYS?.ANSWER
                  ] * 1000
                : false
            }
            onChange={(val) => handleChangeText(val)} //only when value has changed
          />
          {proposalQuestionFormError?.Answer_Error ? (
            <span class="input-field-text-error-text-area">{proposalQuestionFormError?.Answer_Error}</span>
          ) : null}
        </div>
      ) : (
        <div class="field p-r p-x-mini" id="field-user__phone_number_prefix" style={{ width: "100%" }}>
          <TextArea
            removeMargin={true}
            value={
              proposalBasicDetails?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.[ADD_QUESTION_CONFIG_KEYS?.ANSWER]
            }
            className="m-b-sm"
            label={"Enter your text here"}
            changeText={(val) => handleChangeText(val)}
            subTitleTop={"Please add at least 50 characters"}
            error={proposalQuestionFormError?.Answer_Error}
          />
        </div>
      )}
      <div className="confirm-delete-portal-header-actions-wrapper ">
        <div onClick={handleSaveClicked}>
          <p className="m-x-sm add-section-and-question-action-save-text cursor--pointer">
            {PROPOSAL_ADD_SECTION?.SAVE}
          </p>
        </div>
        <div onClick={props?.handleClose}>
          <p className="add-section-and-question-action-cancel-text cursor--pointer">{PROPOSAL_ADD_SECTION?.CANCEL}</p>
        </div>
      </div>
    </div>
  );
};

export default EditAnswer;
