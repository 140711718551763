import React, { useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import moment from "moment";
import { useSelector } from "react-redux";
import PostLikeList from "./components/postLikeList";
import PostCommentList from "./components/postCommentList";
const LinkedInPostcard = (props) => {
  const [likeListModal, setLikeListModal] = useState(false);
  const [commentListModal, setCommentListModal] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const { data, ...Post } = props;

  const OCTATE_LINK = new URL(data?.Link);

  const Created_At = data?.Created_At ? moment(data?.Created_At * 1000).format("DD MMM YYYY hh:mm ") : "";

  const handleViewPost = (val) => {
    window.open("https://www.linkedin.com/feed/update/" + val?.LinkedIn_Post_URN);
  };
  return (
    <div className="linkedIn-post-card" key={data?.LinkedIn_Post_URN}>
      <h2 className="linkedIn-post-name p-x-xs m-b-xs">{data?.Post_Name}</h2>
      <div className="flex align-items-center p-x-xs m-b-xs">
        <img src={require("../../../../../../assets/images/profileIconPlaceholder.png")} className="linked-in-posts-card-user-image" />
        <p className="linkedIn-post-card-header" style={{ marginLeft: "5px" }}>
          {data?.Full_Name}
          <br></br>
          <h3 className="created-by-on-each-card">{Created_At ?? ""}</h3>
        </p>
      </div>
      <p className="linkedIn-post-card-description p-x-xs m-b-xs">{data?.Description}</p>
      <div className="octate-link-container ">
        <img
          src={data?.LinkedIn_Image_URL ? data?.LinkedIn_Image_URL : require("../../../../../../assets/images/octateIconSvg.svg").default}
          className="linked-in-posts-card-image"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = require("../../../../../../assets/images/octateIconSvg.svg").default;
          }}
        />
        <div onClick={() => window.open(data?.Link)} role="button">
          <p className="linked-in-posts-card-link-title">{data?.Article_Title}</p>
          <p href={data?.Link} className="linked-in-posts-card-link">
            {data?.Link}
          </p>
        </div>
      </div>

      <div className="flex items-center space-between p-xs">
        <div className="flex items-center">
          <div className="flex items-center" role="button" onClick={() => setLikeListModal(true)}>
            <FeatherIcon icon={"thumbs-up"} size={20} color={"#5e6366"} style={{ marginLeft: "0px" }} />
            <p className="linked-in-engagement-count" onClick={() => setLikeListModal(true)}>
              {data?.Total_Likes ? data?.Total_Likes : null}{" "}
            </p>
          </div>
          <div className="flex items-center" role="button" onClick={() => setCommentListModal(true)}>
            <FeatherIcon icon={"message-square"} size={20} color={"#5e6366"} style={{ marginLeft: "20px" }} />
            <p className="linked-in-engagement-count">{data?.Total_Comments ? data?.Total_Comments : null}</p>
          </div>
        </div>

        <div className="flex items-center" role="button" onClick={() => handleViewPost(data)}>
          <span className="engagement-view-more-text">View LinkedIn Post</span>
        </div>
      </div>
      {likeListModal ? <PostLikeList data={data} handleCloseModal={() => setLikeListModal(false)} /> : null}
      {commentListModal ? <PostCommentList data={data} handleCloseModal={() => setCommentListModal(false)} /> : null}
    </div>
  );
};

export default LinkedInPostcard;
