import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { tempAuthDetails } from "../../contexts/reducer/authReducer";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import LoadingSpinner from "../../utlis/spinner/loadingSpinner";
import CountdownTimer from "../auth/signUp/otpVerification/countdownTimer";

const BusinessOtp = () => {
  const [authUser, setUser] = useLocalStorage("user");
  const [remountComponent, setRemountComponent] = useState(0);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [error, setError] = useState(null);
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const OTP = KEYS?.SIGN_UP_EMAIL_VERIFICATION;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailAddress = tempAuthData?.data?.emailId;
  const domain = emailAddress?.slice(emailAddress?.indexOf("@") + 1);
  const emailStart = emailAddress?.slice(0, 3);
  const asterisksLength = emailAddress?.length - (domain?.length + 4);

  const handleInput = (val, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (regex.test(val)) {
      switch (inputNum) {
        case 1:
          setOtp1(val);
          break;
        case 2:
          setOtp2(val);
          break;
        case 3:
          setOtp3(val);
          break;
        case 4:
          setOtp4(val);
          break;
        case 5:
          setOtp5(val);
          break;
        case 6:
          setOtp6(val);
          break;
        default:
          break;
      }
    }
  };

  const handleFocus = (inputNum) => {
    const input = document.getElementById(`otp${inputNum - 1}`);
    input.focus();
  };

  const clearState = (inputNum) => {
    switch (inputNum) {
      case 1:
        if (otp1) {
          setOtp1("");
          break;
        } else {
          setOtp1("");
        }
        break;
      case 2:
        if (otp2) {
          setOtp2("");
          break;
        } else {
          setOtp1("");
          handleFocus(inputNum);
        }
        break;
      case 3:
        if (otp3) {
          setOtp3("");
          break;
        } else {
          setOtp2("");
          handleFocus(inputNum);
        }
        break;

      case 4:
        if (otp4) {
          setOtp4("");
          break;
        } else {
          setOtp3("");
          handleFocus(inputNum);
        }
        break;
      case 5:
        if (otp5) {
          setOtp5("");
          break;
        } else {
          setOtp4("");
          handleFocus(inputNum);
        }
        break;
      case 6:
        if (otp6) {
          setOtp6("");
          break;
        } else {
          setOtp5("");
          handleFocus(inputNum);
        }
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (event, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (event.key === "Backspace" || event.key === "Delete") {
      setOtpError(false);
      clearState(inputNum);

    } else if (regex.test(event.key)) {
      setTimeout(() => {
        const input = document.getElementById(`otp${inputNum + 1}`);
        input.focus();
      }, 5);
    }
  };

  const VerifyOtpPostApi = useEnCryptPostApi(
    tempAuthData?.resetPassword ? `/user/OTPVerification` : `/user/signInOTPVerification`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  const ResendOtpPostApi = useEnCryptPostApi(
    `/user/resendOTP`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    if (VerifyOtpPostApi?.data?.userExist) {
      dispatch(
        tempAuthDetails({
          data: { ...tempAuthData?.data },
          loading: true,
          verifyOTP: false,
          joinOrg: true,
          resetPassword: true,
        })
      );
      // let navigationPath = VerifyOtpPostApi?.data?.navigatePath ? "/user-invite" : tempAuthData?.target;
      let tempObj = VerifyOtpPostApi?.data;
      if (VerifyOtpPostApi?.data?.navigatePath) {
        delete tempObj.userExist;
      }
      let SetUserExist = VerifyOtpPostApi?.data?.navigatePath ? tempObj : VerifyOtpPostApi?.data;
      setUser({ ...authUser?.data, ...SetUserExist });
      window.open("https://octate.ai/join-organization", "_parent");
    } else if (!VerifyOtpPostApi?.data?.userExist && VerifyOtpPostApi?.data?.message) {
      setOtpError(true);
      setError({ otpError: VerifyOtpPostApi?.data?.message });
    }
  }, [VerifyOtpPostApi?.data]);

  const handleSubmitPressed = () => {
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    const rawData = {
      emailId: tempAuthData?.data?.emailId,
      userId: tempAuthData?.data?.userId,
      OTP: parseInt(otpValue),
      Persona: tempAuthData?.data?.Persona,
      data: { ...tempAuthData?.data },
    };

    VerifyOtpPostApi?.handleTrigger(rawData);
  };

  const handleResend = () => {
    const rawData = {
      emailId: tempAuthData?.data?.emailId,
      userId: tempAuthData?.data?.userId,
      type: tempAuthData?.resetPassword ? "forgotPassword" : "signUp",
    };

    ResendOtpPostApi?.handleTrigger(rawData);
    setRemountComponent(Math.random());
  };

  const handlePasteOtp = (event) => {
    event.preventDefault();
    const pasteText = event.clipboardData.getData("text/plain").trim().slice(0, 6);
    for (let i = 0; i < 6 && i < pasteText.length; i++) {
      handleInput(pasteText[i], i + 1);
    }
  };

  return (
    <div class="splitted-page__content" style={{ marginTop: "5%" }}>
      <div class="splitted-page__container">
        <div class="m-y-auto">
          <div className="grid-max-auto">
            <div type="checkbox" className="otp-verification-checkbox" />
            <div className="otp-verification-container">
              <h1 className="otp-verification-title">{OTP?.EMAIL_VERIFICATION}</h1>
              <p className="otp-verification-text m-b-md">
                {OTP?.EMAIL_VERIFICATION_SUBTEXT_START}
                {"  "}
                {/* {tempAuthData?.data?.emailId} */}
                <strong>{`${emailStart}${"*".repeat(asterisksLength)}@${domain}`}</strong>
                {"  "}
                {OTP?.EMAIL_VERIFICATION_SUBTEXT_END}
              </p>
              <div className="input-field-wrapper-position-relative">
                <div className="flex space-between m-b-md " onPaste={handlePasteOtp}>
                  <input
                    id="otp1"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp1}
                    onChange={(e) => handleInput(e.target.value, 1)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                    autoFocus
                    pattern="\d*"
                  />
                  <input
                    id="otp2"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp2}
                    onChange={(e) => handleInput(e.target.value, 2)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 2)}
                    pattern="\d*"
                  />
                  <input
                    id="otp3"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp3}
                    onChange={(e) => handleInput(e.target.value, 3)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                    pattern="\d*"
                  />
                  <input
                    id="otp4"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp4}
                    onChange={(e) => handleInput(e.target.value, 4)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 4)}
                    pattern="\d*"
                  />
                  <input
                    id="otp5"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp5}
                    onChange={(e) => handleInput(e.target.value, 5)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 5)}
                    pattern="\d*"
                  />
                  <input
                    id="otp6"
                    type="tel"
                    className={`Otp-verification-number ${otpError ? "background-error" : ""}`}
                    autoComplete="off"
                    value={otp6}
                    onChange={(e) => handleInput(e.target.value, 6)}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, 6)}
                    pattern="\d*"
                  />
                </div>
                {otpError ? (
                  <p className="input-field-text-error" style={{ top: "80px" }}>
                    {error?.otpError}
                  </p>
                ) : null}
              </div>
              <div key={remountComponent}>
                <p className="otp-verification-resend-text m-b-md">
                  {OTP?.RESEND_QUESTION_START}{" "}
                  {/* <p className="resend-button" role={"button"}>
                  {OTP?.RESEND_CODE}{" "}
                </p> */}
                  <CountdownTimer seconds={40} onResend={() => handleResend()} />
                </p>
              </div>
              {VerifyOtpPostApi?.isLoading ? (
                <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="create-account-button" role={"button"} onClick={() => handleSubmitPressed()}>
                  {OTP?.VERIFY_BUTTON}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOtp;
