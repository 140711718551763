import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReadMore from "../../../../components/businessProfile/components/businessOverview/ReadMore";
import CommentOptions from "./commentOptions";
import CommentInput from "./commentInput";
import { useEnCryptGetApi } from "../../../customHooks/customHooks";
import { useGetCommentsForCardQuery } from "../../../../adapters/xhr/generalApi";
import BoxSpinner from "../../../spinner/boxSpinner";
import InitialCommentCard from "./InitialCommentCard";

const CommentReplyCard = (props) => {
  const [commentData, setCommentData] = useState("");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PROPS_DATA = props?.data;
  const REPLY_COMMENT = props?.parentData;

  const GetAllComments = useEnCryptGetApi(
    `/comment/getAllComments?Item_ID=${
      PROPS_DATA?.data?.[PROPS_DATA?.keyName]
    }&User_ID=${USER_ID}&offset=0&Parent_Comment_ID=${REPLY_COMMENT?.commentGUID}&Display_Parent_Comment_ID=${
      REPLY_COMMENT?.commentGUID
    }`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useGetCommentsForCardQuery
  );
  const COMMENT_DATA = GetAllComments?.data?.data;

  return GetAllComments?.isLoading ? (
    <BoxSpinner />
  ) : (
    COMMENT_DATA?.map((item) => <InitialCommentCard item={item} data={PROPS_DATA} replyCard={true} />)
  );
};

export default CommentReplyCard;
