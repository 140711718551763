import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../config/config";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import StickyTab from "../../utlis/hoc/tabNavigator/stickyTab";
import { GetIdFormLink } from "../../utlis/hof/hof";
import BusinessDetails from "../businessProfile/components/businessOverview/businessDetails";
import BusinessProfileHeader from "../businessProfile/components/businessProfileHeader/businessProfileHeader";
import ProposalOverview from "../businessProfile/components/proposalOverView/proposalOverview";
import ProposalsTab from "../businessProfile/components/tabs/proposalsTab";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import VendorDashboard from "../vendorDashboard/vendorDashboard";
const SalesHub = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const authData = useSelector((state) => state?.authDetails?.details);
  const emailId = authData?.data?.emailId;
  const [activeRowProposal, setActiveRowProposal] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const scrollPosition = useScrollPosition();
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const VENDOR_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : authData?.data?.vendorId;
  const VENDOR_NAME = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Name : null;
  const navigatePath = props?.type === "buyer" ? "vendor" : "sales-hub";

  const GetVendorStatus = useEnCryptGetApi(
    `/getVendorStatusForUser?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const domain = emailId ? emailId.split("@").pop() : null;
  const SkipGetPermissions = props?.type !== "buyer" || domain == "portqii.com" || domain == "octate.ai" ? false : true;
  const GetUserPermissionStatus = useEnCryptGetApi(
    `/permission/getPermissionsForUser?User_ID=${authData?.data?.userId}&Vendor_ID=${authData.data?.vendorId} `,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    SkipGetPermissions
  );
  const permissionsDetails =
    props?.type === "buyer" ? {} : GetUserPermissionStatus?.data?.data ? GetUserPermissionStatus?.data?.data : {};
  useEffect(() => {
    if (GetVendorStatus?.data?.data) {
      setUser({ ...authData?.data, ...GetVendorStatus?.data?.data });
    }
  }, [GetVendorStatus?.data?.data]);

  let BUSINESS_PROFILE_TAB = KEYS?.SALES_HUB_TAB;
  const handleActiveRowData = (val, index) => {
    setActiveRowProposal(val);
  };
  BUSINESS_PROFILE_TAB =
    authData?.data?.adminStatus && props?.type !== "buyer"
      ? [...BUSINESS_PROFILE_TAB]
      : props?.type === "buyer"
      ? KEYS?.VENDOR_PAGE_TAB
      : [...BUSINESS_PROFILE_TAB];

  return (authData?.data?.requestAccepted || authData?.data?.adminStatus || props?.type === "buyer") &&
    !authData?.data?.buyerView ? (
    <>
      <Header show={true} />
      {props?.type === "buyer" && (
        <div className="attached-navbar m-b-md">
          <div className="fixed-nav-container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb page={props?.page} parent={"providerPage"} type={"Provider"} params={VENDOR_NAME} />
          </div>
        </div>
      )}
      <div className="business-profile-landing-page-container ui container">
        <BusinessProfileHeader
          permissionsDetails={permissionsDetails}
          tabIndex={tabIndex}
          tabData={BUSINESS_PROFILE_TAB}
          type={props?.type}
          navigatePath={navigatePath}
          VENDOR_ID={VENDOR_ID}
        />

        <StickyTab
          permissionsDetails={permissionsDetails}
          tabIndex={tabIndex}
          tabData={BUSINESS_PROFILE_TAB}
          scrollPosition={scrollPosition}
          navigatePath={navigatePath}
          page={props?.page}
        />

        <div className="grid-business-profile m-t-sm mobile-1-col">
          {tabIndex == "proposals" ? (
            <ProposalsTab
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.PROPOSAL]}
              tabIndex={tabIndex}
              handleActiveRowData={handleActiveRowData}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == "dashboard" ? (
            <VendorDashboard />
          ) : null}
          <div className="mobile-hidden">
            {tabIndex == "proposals" ? (
              <ProposalOverview
                permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BUSINESS_PROFILE]}
                type={props?.type}
                activeRowProposal={activeRowProposal}
              />
            ) : (
              <BusinessDetails
                type={props?.type}
                permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BUSINESS_PROFILE]}
              />
            )}
          </div>
        </div>
      </div>
      <Footer page={props?.page} />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default SalesHub;
