export const CONFIG = {
  BASE_END_POINT: "https://octate.ai",
  TOKEN: process.env.REACT_APP_KEY,
  SITE_KEY_GOOGLE: process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY,
  REACT_APP_URL: 'https://octate.ai',
  DIRECT_MESSAGE: {
    REQUEST_VENDOR: {
      DESCRIPTION:
        "This vendor is currently not onboarded on Octate AI. We are continuously working with the vendors to have them onboarded. You can still submit a request for this vendor and we will ensure the vendor receives it.",
      SUBMIT_REQUEST: "Submit request",
      SUBMIT_REQUEST_BUTTON: "Submit request",
    },
    REQUEST_WORK_EMAIL: {
      DESCRIPTION:
        "We notice that you are using a personal email address. To message vendors, you need to provide your business email address.",
      SUBMIT_REQUEST: "Business email",
      SUBMIT_REQUEST_BUTTON: "Next",
    },
    VALIDATE_WORK_EMAIL: {
      DESCRIPTION: "Enter business email",
      SUBMIT_REQUEST: "Verify Email",
      SUBMIT_REQUEST_BUTTON: "Verify",
    },
    VERIFY_OTP: {
      DESCRIPTION: "You have to sign up using business email address, to communicate with the vendor.",
      SUBMIT_REQUEST: " Verify OTP",
      SUBMIT_REQUEST_BUTTON: "Verify",
    },
    VERIFIED_EMAIL: {
      DESCRIPTION:
        "Your email address has been successfully verified. Henceforth please use this email address for signing in to Octate AI.",
      SUBMIT_REQUEST: "Email verified",
      SUBMIT_REQUEST_BUTTON: "Ok",
    },
    VENDOR_CHAT: {
      DISABLE_DESC: "The buyer has closed this chat",
    },
    BUYER_CHAT: {
      DISABLE_DESC: "You have closed this chat",
      DISABLE_BUTTON: "Reopen",
      ANONYMOUS_MORE_OPTIONS: "Chat as anonymous",
      NON_ANONYMOUS_MORE_OPTIONS: "Chat as known user",
      CLOSE_MORE_OPTIONS: "Close this chat",
      OPEN_MORE_OPTIONS: "Reopen",
      UN_VERIFIED_VENDOR:
        "This vendor is currently not onboarded on Octate AI. We will notify you once we receive a response from this vendor.",
      ANONYMOUS_CHAT_DESC: "You are chatting as an anonymous user",
    },
    OVERVIEW: {
      DESCRIPTION:
        "Octate AI BuyerAssist team will work with you and vendors throughout this proposal. The team will work with the vendor team to expedite the responses. Our team will help you engage with the vendors. Our goal is to accelerate your buying journey and help you with the due diligence.",
      TITLE: "Octate AI BuyerAssist",
      CTA_LINK: "Chat",
      SUB_DESCRIPTION: "with us if you have any questions about the Octate AI BuyerAssist!",
    },
  },
  SIGN_UP_ERRORS: {
    EMAIL_REQUIRED: "Email Address is required",
    VALID_EMAIL: "Please enter a valid Email Address",
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    PASSWORD_REQUIRED: "Password is required",
    PASSWORD_PATTERN:
      "Passwords are at least 8 Characters in length with a minimum of one Capital Letter, a Number and a Special Character.",
  },
  EDIT_PROFILE_ERRORS: {
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
  },
  GET_EARLY_ACCESS_ERRORS: {
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    COMPANY_NAME_REQUIRED: "Company Name is required",
    EMAIL_REQUIRED: "Work Email is required",
  },
  COMPANY_PROFILE_LOCATION_ERRORS: {
    BUILDING_NAME_REQUIRED: "Building Name is required",
    ADDRESS_REQUIRED: "Address is required",
    COUNTRY_REQUIRED: "Country is required",
    PINCODE_REQUIRED: "Pincode is required",
  },
  SIGN_IN_ERRORS: {
    EMAIL_REQUIRED: "Email Address is required",
    VALID_EMAIL: "Please enter a valid Email address",
  },
  FAQ_ERRORS: {
    QUESTION_REQUIRED: "The Question field cannot be Empty",
    ANSWER_REQUIRED: "The Answer section cannot be Empty",
    ANSWER_MIN_LENGTH: "Answers are at least 50 characters in length.",
  },
  COMPANY_PROFILE_PAGE1_ERRORS: {
    ORGANIZATION_REQUIRED: "Organization Name is required",
    ABOUT_REQUIRED: "About is required",
    BUSINESS_FOCUS_REQUIRED: "Business Focus is required",
    ESTABLISHED_ON_REQUIRED: "Established On is required",
    ORGANIZATION_SIZE_REQUIRED: "Organization Size is required",
    ORGANIZATION_TYPE_REQUIRED: "Organization Type is required",
  },
  COMPANY_PROFILE_PAGE2_ERRORS: {
    COMPANY_PHONE_NUMBER_REQUIRED: "Company Phone Number is required",
    HEADQUARTERS_REQUIRED: "Headquarters is required",
  },
  COMPANY_PROFILE_PAGE3_ERRORS: {
    FIRST_NAME_REQUIRED: "First Name is required",
    LAST_NAME_REQUIRED: "Last Name is required",
    COMPANY_NAME_REQUIRED: "Company Name is required",
    EMAIL_REQUIRED: "Work Email is required",
  },
  COMPANY_PRODUCT_PAGE1_ERRORS: {
    PRODUCT_NAME_REQUIRED: "Product Name is required",
    PRODUCT_SEGMENT_REQUIRED: "Product Segment is required",
    PRODUCT_LINK_REQUIRED: "Product Link is required",
    PRODUCT_PRODUCT_CATEGORIES: "Please select at least one product category",
  },
  COMPANY_PRODUCT_PAGE2_ERRORS: {
    FEATURE_TITLE_REQUIRED: "Feature ritle is required",
    FEATURE_REQUIRED: "At least one feature is required",
  },
  PRICING_PLAN_PAGE2_ERRORS: {
    PLAN_NAME_REQUIRED: "Plan name is required",
    PRODUCT_DESCRIPTION_REQUIRED: "Product description is required",
    PRODUCT_PRICING_REQUIRED: "Product pricing is required",
  },
  FEATURE_LIST_ERRORS: {
    FEATURE_TITLE_REQUIRED: "Feature title is required",
    FEATURE_DESCRIPTION_REQUIRED: "Feature description is required",
  },
  FEATURE_SECTION_ERRORS: {
    FEATURE_TITLE_REQUIRED: "Feature title is required",
    SECTION_TITLE_REQUIRED: "Section title is required",
    FEATURE_DESCRIPTION_REQUIRED: "Feature description is required",
    FEATURE_REQUIRED: "At least one feature is required",
  },
  BLURB_SAVE_ERRORS: {
    BLURB_TITLE_REQUIRED: "Blurb title is required",
    BLURB_DESCRIPTION_REQUIRED: "Blurb description is required",
    BLURB_LENGTH: "Blurb descriptions are at least 50 characters in length.",
    BLURB_JOURNEY: "Blurb journey is required.",
    PAGE_URL: "Page URL is required",
    PAGE_URL_SYNTAX: "Please type a valid URL",
  },
  USE_CASE_SAVE_ERRORS: {
    USE_CASE_TITLE_REQUIRED: "Resource title is required",
    USE_CASE_DESCRIPTION_REQUIRED: "Resource description is required",
    USE_CASE_LENGTH: "Resource descriptions are at least 50 characters in length.",
    USE_CASE_PAGE_URL: "Page URL is required",
    USE_CASE_PAGE_URL_SYNTAX: "Please type a valid URL",
    USE_CASE_PRODUCT: "Resource product is required",
    USE_CASE_TYPE: "Resource type is required",
    USE_CASE_ANON_CUSTOMER: "Resource anonymous customer name is required",
    USE_CASE_ANON_COUNTRY: "Resource anonymous customer country is required",
  },
  REQUEST_CUSTOMER_ERRORS: {
    REQUEST_CUSTOMER_VENDOR_REQUIRED: "Vendor name is required",
    REQUEST_CUSTOMER_ABOUT_REQUIRED: "Description is required",
    REQUEST_CUSTOMER_ABOUT_LENGTH: "Descriptions are at least 50 characters in length.",
    REQUEST_CUSTOMER_URL_REQUIRED: "Domain URL is required",
    REQUEST_CUSTOMER_VALID_URL: "Please enter valid URL",
    REQUEST_CUSTOMER_YEAR_REQUIRED: "Founded year is required",
    REQUEST_CUSTOMER_COMPANY_REQUIRED: "Company size is required",
  },
  PROPOSAL: {
    PROPOSAL_SUMMARY: {
      BASE_END_POINT: "/proposalSummary?filterBy=week",
      NAME: "name",
      COUNT: "count",
      ID: "id",
    },
    PROPOSAL_TABLE: {
      BASE_END_POINT: "/proposalTable",
      PROPOSAL_TITLE: "Proposal_Title",
      PROPOSAL_ID: "Proposal_ID",
      PROPOSAL_OWNER: "Proposal_Owner",
      CREATED_ON: "Created_On",
      TARGET_DATE: "Target_Date",
      PARTICIPANTS: "Participants",
      STATUS: "Status",
      PROPOSAL_TIMELINE: "Proposal_Timeline",
      NUMBER_OF_LICENSE: "Number_Of_Licenses",
    },
    QUESTION_PROPOSAL_VENDOR_LIST_TABLE: {
      VENDOR_NAME: "Vendor_Name",
      STATUS: "Status",
      TOTAL_QUESTIONS: "Total_Questions",
      ANSWERED_QUESTIONS: "Answered_Questions",
      VENDOR_ID: "Vendor_ID",
    },
    WEEK_DROPDOWN_DATA: [
      {
        name: "Today",
        internalName: "Today",
        id: 1,
      },
      {
        name: "This Week",
        internalName: "This_Week",
        id: 2,
      },
      {
        name: "This Month",
        internalName: "This_Month",
        id: 3,
      },
    ],
    PROPOSAL_ID: "Proposal_ID",
  },
  ADD_PROPOSAL: {
    PROPOSAL_TITLE: "Proposal_Title",
    PROPOSAL_ID: "Proposal_ID",
    PROPOSAL_DESCRIPTION: "Proposal_Description",
    SOLUTION_NEEDS: "Business_Needs",
    BUSINESS_DOMAIN: "Business_Domain",
    BUSINESS_SIZE: "Business_Size",
    PROPOSAL_QUESTION: "Proposal_Question",
    SECTION_INDEX: "Section_Index",
    PROPOSAL_TIMELINE: "Proposal_Timeline",
    NUMBER_OF_LICENSE: "Number_Of_Licenses",
    VENDORS: "Vendors",
    VENDOR_NAME: "Vendor_Name",
    VENDOR_ID: "Vendor_ID",
    VENDOR_SELECTION_WAY: "Vendor_Selection_Way",
    CREATED_BY: "Created_By",
    UPDATE_BY: "Updated_By",
    PARTICIPANTS: "Participants",
    STATUS: "Status",
    USER_NAME: "User_Name",
    TARGET_DATE: "Target_Date",
    CREATED_DATE: "Created_On",
    UPDATED_DATE: "Updated_On",
    EMAIL: "Email_Address",
    FULL_NAME: "Full_Name",
    USER_ID: "User_ID",
    SHARED_ITEM_ID: "Share_Item_ID",
    SHARED_WITH: "Shared_With",
    ACCESS: "Access_Type",
    ID: "Id",
    CAN_EDIT: "Can_Edit",
    IS_SHARED: "Is_Shared",
  },

  ADD_SECTION: {
    SECTION_TITLE: "Section_Name",
    SECTION_ORDER: "Section_Order",
    SECTION_ID: "Proposal_Section_ID",
  },
  ADD_QUESTION: {
    QUESTION_LIST: "Question_List",
    QUESTION_NAME: "Question_Text",
    QUESTION_TYPE: "Answer_Type",
    PROPOSAL_QUESTION_ID: "Proposal_Question_ID",
    PROPOSAL_META_DATA: "Answer_Type_Meta",
    PROPOSAL_META_DATA_LENGTH: "Answer_Type_Meta_Length",
    QUESTION_OPTIONS: "Question_Options",
    ANSWER: "Answer",
    QUESTION_ID: "Proposal_Question_ID",
    USER_NAME: "Username",
  },
  ADD_PROPOSAL_ERROR_MESSAGES: {
    PROPOSAL_TITLE: "Proposal title is required",
    PROPOSAL_DESCRIPTION: "Proposal description is required",
    SOLUTION_NEEDS: "Solution_Needs",
    BUSINESS_DOMAIN: "Business_Domain",
    BUSINESS_SIZE: "Business_Size",
    PROPOSAL_DESCRIPTION_LENGTH: "Answers are at least 50 characters in length.",
    SECTION_TITLE: "Section title is required",
    QUESTION_LIST: "Add at least one section and question",
    SECTION_WITHOUT_QUESTION: "Section should have at least one question",
    SECTION_WITHOUT_QUESTION_WITH_SECTION_NAME: "should have at least one question",
    VENDOR: "Please add at least one vendor",
    ADD_ONE_QUESTION_SECTION: "Please add at least one question for section",
  },
  ADD_PROPOSAL_QUESTION_ERROR_MESSAGES: {
    QUESTION_TITLE: "Question name is required",
    QUESTION_TYPE: "Question type is required",
    QUESTION_META_DATA: "Add at least two options",
    QUESTION_META_DATA_OPTIONS: "Option name is required",
    SECTION_TITLE: "Section title is required",
    SECTION_WITH_SAME_NAME: "Two sections cannot have the same name",
    ANSWER_ERROR: "Answer cannot be empty",
  },
  PROPOSAL_COMMENTS_KEYS: {
    COMMENT_ID: "Comments_ID",
    USER_ID: "User_ID",
    VENDOR_ID: "Vendor_ID",
    ID: "ID",
    COMMENT: "Comment",
    COMMENT_TYPE: "Comment_Type",
    DELETE_STATUS: "Delete_Status",
    PROPOSAL_ANSWER: "Proposal_Answer",
    PROPOSAL_QUESTION: "Proposal_Question",
    CREATED_ON: "Comment_Date_Time",
    PARENT_COMMENT_ID: "Parent_Comment_ID",
    REPLY: "reply",
    USER_NAME: "Username",
  },
  NOTIFICATION_API_KEYS: {
    DESCRIPTION: "Description",
    CREATED_ON: "Created_On",
    PATH: "Path",
    READ: "Read",
    NOTIFICATION_ID: "Notification_ID",
  },
  VENDOR_DASHBOARD: {
    SUMMARY_STATS: {
      TITLE: "Type",
      COUNT: "Count",
    },
    FILTERS: {
      LABEL: "name",
      NAME: "name",
      TYPE: "type",
    },
  },
  BOARDS: {
    BOARD_LIST: {
      VENDOR: "Vendors",
      PRODUCTS: "Products",
      BLURBS: "Blurbs",
      USE_CASES: "Case Studies",
    },
  },
  PERMISSION_RESOURCE_KEYS: {
    VENDOR_TAB: "Vendors",
    PRODUCT_TAB: "Products",
    PROPOSAL_TAB: "Proposals",
    TEAMS_TAB: "Teams",
    CUSTOMERS_TAB: "Customers",
    BUSINESS_PROFILE_TAB: "Business_Profile",
    PERMISSIONS_TAB: "Permission",
    DASHBOARD_TAB: "dashboard",
    FAQ_TAB: "FAQ",
    USE_CASE_TAB: "Case Studies",
    BOARDS_TAB: "Boards",
    BLURBS_TAB: "Blurbs",
    VENDOR_CHAT_TAB: "Direct message",
    VENDOR: "Vendor",
    PRODUCT: "Product",
    PROPOSAL: "Proposals",
    DASHBOARD: "Dashboard",
    TEAMS: "Teams",
    VENDOR_CHAT: "Direct_Message",
    BOARDS: "Boards",
    CUSTOMERS: "Customers",
    FAQ: "FAQ",
    BUSINESS_PROFILE: "Business_Profile",
    PERMISSIONS: "Permissions",
    BLURBS: "Blurb",
    USE_CASE: "Usecase",
  },
  REQUEST_VENDOR_ERROR: {
    REQUEST_NAME: "Vendor name is required",
    DOMAIN_URL: "Domain url is required",
    VALID_DOMAIN_URL: "Please enter valid url",
  },

  NO_CONTENT_MESSAGE: {
    SEARCH_RESULT_BLURB: "No blurbs found for your search.",
    SEARCH_RESULT_PRODUCT: "No products found for your search.",
    SEARCH_RESULT_USE_CASE: "No case studies found for your search.",
    SEARCH_RESULT_VENDOR: "No vendors found for your search.",

    BUSINESS_PROFILE_BLURB: "You have not added any blurbs so far.",
    BUSINESS_PROFILE_PRODUCT: "You have not added any product so far.",
    BUSINESS_PROFILE_USE_CASE: "You have not added any case studies so far.",
    BUSINESS_PROFILE_FAQ_VENDOR: "You have not added any FAQ so far.",
    BUSINESS_PROFILE_FAQ_PRODUCT: "You have not added any FAQ so far.",
    BUSINESS_PROFILE_CUSTOMER: "You have not added any customers so far.",
    BUSINESS_PROFILE_BOARD: "You have not added any boards so far.",

    VENDOR_PAGE_BLURB: "We don't have the blurb data for this vendor yet.",
    VENDOR_PAGE_PRODUCT: "We don't have the product data for this vendor yet.",
    VENDOR_PAGE_USE_CASE: "We don't have the case study data for this vendor yet.",
    VENDOR_PAGE_FAQ_VENDOR: "We don't have the FAQ data for this vendor yet.",
    VENDOR_PAGE_FAQ_PRODUCT: "We don't have the FAQ data for this product yet.",
    VENDOR_PAGE_CUSTOMER: "We don't have the customer data for this vendor yet.",
    VENDOR_PAGE_ALTERNATE_VENDOR: "We don't have the alternate vendors data for this vendor yet.",
    VENDOR_PAGE_BOARD: "No boards found for this vendor.",

    PRODUCT_PAGE_BLURB: "We don't have the blurb data for this product yet.",
    PRODUCT_PAGE_FEATURE: "We don't have the features data for this product yet.",
    PRODUCT_PAGE_USE_CASE: "We don't have the case study data for this product yet.",
    PRODUCT_PAGE_CUSTOMER: "We don't have the customer data for this product yet.",
    PRODUCT_PAGE_FAQ_VENDOR: "We don't have the FAQ data for this vendor yet.",
    PRODUCT_PAGE_FAQ_PRODUCT: "We don't have the FAQ data for this product yet.",
    PRODUCT_PAGE_SIMILAR_PRODUCT: "We don't have the similar product data for this product yet.",
    PRODUCT_PAGE_BOARD: "We don't have the board data for this product yet.",

    CATEGORY_PAGE_BLURB: "We don't have the blurb data for this category yet.",
    CATEGORY_PAGE_PRODUCT: "We don't have the product data for this category yet.",
    CATEGORY_PAGE_USE_CASE: "We don't have the case study data for this category yet.",
    CATEGORY_PAGE_VENDOR: "We don't have the vendor data for this category yet.",

    TOPIC_PAGE_BLURB: "We don't have the blurb data for this topic yet.",
    TOPIC_PAGE_USE_CASE: "We don't have the case study data for this topic yet.",

    LIKE_BOARD_BLURB: "You have not liked any blurbs so far.",
    LIKE_BOARD_PRODUCT: "You have not liked any products so far.",
    LIKE_BOARD_VENDOR: "You have not liked any vendor so far.",
    LIKE_BOARD_USECASE: "You have not liked any case study so far.",
    BOOKMARK_BOARD_BLURB: "You have not bookmarked any blurb so far",
    BOOKMARK_BOARD_PRODUCT: "You have not bookmarked any product so far.",
    BOOKMARK_BOARD_VENDOR: "You have not bookmarked any vendor so far.",
    BOOKMARK_BOARD_USECASE: "You have not bookmarked any case study so far.",

    BOARD_VIEW_BLURB: "You have not added any blurb to this board yet.",
    BOARD_VIEW_PRODUCT: "You have not added any product to this board yet.",
    BOARD_VIEW_USE_CASE: "You have not added any case study to this board yet.",
    BOARD_VIEW_VENDOR: "You have not added any vendor to this board yet.",

    BLURB_DETAIL_CATEGORY_RELATED: "We don't have the blurb data for this category yet.",
    BLURB_DETAIL_PRODUCT_RELATED: "We don't have the blurb data for this product yet.",
    BLURB_DETAIL_TOPIC_RELATED: "We don't have the blurb data for this topic yet.",
    BLURB_DETAIL_VENDOR_RELATED: "We don't have the blurb data for this vendor yet.",

    USECASE_DETAIL_CATEGORY_RELATED: "We don't have the case study data for this category yet.",
    USECASE_DETAIL_PRODUCT_RELATED: "We don't have the case studydy data for this product yet.",
    USECASE_DETAIL_TOPIC_RELATED: "We don't have the case study data for this topic yet.",
    USECASE_DETAIL_VENDOR_RELATED: "We don't have the case study data for this vendor yet.",
  },
  SIGN_UP_ROLES: [
    {
      id: 1,
      name: "Marketing",
      selected: false,
      path: "/business-profile/blurbs",
    },
    {
      id: 2,
      name: "Sales",
      selected: false,
      path: "/sales-hub/proposals",
    },
    {
      id: 3,
      name: "Others",
      selected: false,
      path: "/business-profile/blurbs",
    },
  ],
  LICENSE: {
    TITLE: "Start collaborating with SalesHub",
    SUBTITILE: "Get more done with your team. Upgrade to start collaborating",
    SUBTITLE_LINE2: "Together is Better with as little as $99 per month per user",
    // CHOOSE_USERS: "How many members do you want to add ?",
    PRICING_TEXT: "Total cost for",
    PRICING_TEXT_END: "users per year: ",

    INVITE_USER: "Invite Users",
    INFO_TEXT: "You can add user here now or later",

    TERMS_START: "Agree to",
    TERMS_SERVICE: "Terms and Services",
    REFUND_POLICY: "Cancellations and Refunds Policy",
  },
  HEADER_BANNER: {
    VISIBLE: false,
    TITLE: "Exclusive offer for IFX Expo attendees. Offer ends soon. ",
    BUTTON_TEXT: "Claim it today!",
    VISIT_LINK: "https://bangkok2023.ifxexpo.com/",
    OCTATE_LINK: "https://business.octate.ai/",
  },
  VENDOR_PUBLISH_PORTAL: {
    TITLE: "Publish profile",
    DESCRIPTION: "This will make your Vendor profile public and all the content will be accessible to users. Proceed to publish?",
    CONFIRM_BUTTON: "Publish",
    CLOSE_BUTTON: "Cancel",
  },
  BUSINESS_PROFILE_PUBLISH: {
    PUBLISH: "Publish",
    PUBLISHED: "Published",
    PUBLISHED_TOOLTIP: "Your Vendor profile is public, all the content will be accessible to users",
    NO_PRODUCTS: "Please add at least one product to publish",
  },
  MANAGE_BUSINESS: {
    SEARCH_REJECTED_VENDORS: "You have already been rejected by the above vendor",
  },
  PRODUCT_EXPERT_ERRORS: {
    DESCRIPTION: "Description is required",
    POST_NAME: "Post name is required",
    LINK: "Link is required",
    OCTATE_LINK: "Please enter valid octate link",
  },
};
