import React from "react";
import CategoryFilter from "./categoryFilter";

function FiltersList(props) {
  return (
    <div className="business-aside-container modern-card modern-card--elevated">
      <aside>
        <div className="filter-title-proposal">Filters</div>
        <CategoryFilter getAllCategoriesId={props?.getAllCategoriesId} />
      </aside>
    </div>
  );
}

export default FiltersList;
