import React from "react";
import { useSelector } from "react-redux";
import { DecryptApi, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import ProvidersList from "./companyList";
import TopicList from "./topicList";
// import * as KEYS from "../../../../../config/config";
const SearchInputDropDown = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const searchedList = DecryptApi(props?.searchedList);
  const KeysInObject = Object.keys(searchedList?.data);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE?.PRODUCT;
  return (
    <>
      {KeysInObject?.length
        ? KeysInObject?.map((list) => {
            if (list == "Topics") {
              return (
                <TopicList
                  list={searchedList?.data[list]}
                  inputText={props?.inputText}
                  name={""}
                />
              );
            } else {
              return (
                <ProvidersList
                  list={searchedList?.data[list]}
                  inputText={props?.inputText}
                  name={list}
                />
              );
            }
          })
        : null}
      <a
        className="result"
        href={`/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(
          props?.inputText
        )}`}
      >
        <div className="content">
          <i className="feather icon icon-search "></i>
          <div>
            {KEYS?.SEARCH_INPUT_DROPDOWN?.LABEL}
            <em>{`${" "}"${props?.inputText}"`}</em>
          </div>
        </div>
      </a>
    </>
  );
};
export default SearchInputDropDown;
