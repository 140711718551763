import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAddItemsToBoardMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import "../../../../styles/boards.css";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { ToTitleCase } from "../../../hof/hof";
import BoardRoundSpinner from "../../../spinner/boardRoundSpinner";
import RoundSpinner from "../../../spinner/roundSpinner";
import * as SECRET_KEYS from "../../../../config/configKeys";
function BoardCard(props) {
  const data = props?.data;
  const boardData = props?.boardData;
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;
  const page = props?.page;
  const isBusinessProfile = page == "business profile" ? true : false;
  // Board_Image
  const cardType =
    props?.cardType == "blurb"
      ? "blurb"
      : props?.cardType == "product"
      ? "product"
      : props?.cardType == "usecase"
      ? "usecase"
      : props?.cardType == "vendor"
      ? "vendor"
      : "";
  const boardImageKey =
    props?.cardType == "blurb"
      ? "image_src"
      : props?.cardType == "product"
      ? "Account_Logo"
      : props?.cardType == "Use Case"
      ? "image_src"
      : props?.cardType == "vendor"
      ? "Vendor_Logo_URL"
      : "";
  const keyName = props?.keyName;
  const dataType = props?.dataType;

  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: cardType,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const CreateNewBoard = useEnCryptPostApi(
    `/board/addItemToBoard`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useAddItemsToBoardMutation
  );
  const handleAddContentToBoardClicked = () => {
    if (dataType == "Array") {
      const arrayOfIds = data
        .map((list) => {
          return list?.[keyName];
        })
        .splice(0, 10);
      CreateNewBoard.handleTrigger({
        Board_ID: boardData?.Board_ID,
        Item_ID: arrayOfIds,
        Item_Type: cardType,
        Is_Vendor_Added: isBusinessProfile ? true : false,
        Updated_At: parseInt(new Date().getTime() / 1000),
      });
      handleSignalTracking({
        Board_ID: boardData?.Board_ID,
        Item_ID: arrayOfIds,
        Item_Type: cardType,
        Is_Vendor_Added: isBusinessProfile ? true : false,
        Updated_At: parseInt(new Date().getTime() / 1000),
      });
    } else {
      CreateNewBoard.handleTrigger({
        Board_ID: boardData?.Board_ID,
        Item_ID: data?.[keyName],
        Item_Type: cardType,
        Board_Image: data?.[boardImageKey],
        Is_Vendor_Added: isBusinessProfile ? true : false,
        Updated_At: parseInt(new Date().getTime() / 1000),
      });
      handleSignalTracking({
        Board_ID: boardData?.Board_ID,
        Item_ID: data?.[keyName],
        Item_Type: cardType,
        Board_Image: data?.[boardImageKey],
        Is_Vendor_Added: isBusinessProfile ? true : false,
        Updated_At: parseInt(new Date().getTime() / 1000),
      });
    }
  };
  useEffect(() => {
    if (CreateNewBoard?.data && cardType) {
      props?.handleItemsSuccessfullyAdded({
        status: CreateNewBoard?.data?.status,
        message: CreateNewBoard?.data?.message ? CreateNewBoard?.data?.message : "hello world",
      });
    }
  }, [CreateNewBoard?.data]);

  const handleSignalTracking = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: val?.Item_Type,
      Item_Id: val?.Item_ID,
      Target: {
        page: "",
        link: "",
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: "add to board",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div
      onClick={handleAddContentToBoardClicked}
      className={`boards-card-main-wrapper--hover p-r ${
        CreateNewBoard?.isLoading ? "board-card-is-loading cursor--disable" : "cursor--pointer"
      }`}
    >
      {CreateNewBoard?.isLoading ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            top: "15px",
          }}
        >
          <BoardRoundSpinner />
        </div>
      ) : null}
      <div className="boards-card-main-wrapper m-y-base">
        <div className="boards-card-left-side-main-wrapper">
          <img
            className="octate-mobile--boards-list-image"
            width="auto"
            height="auto"
            style={{ marginRight: "8px" }}
            src={require("../../../../assets/images/OctateIcon.ico")}
            alt={"octate.ai logo"}
          ></img>
        </div>
        <div className="boards-card-right-side-main-wrapper">
          <h3 className="boards-card-title-text">{boardData?.Name}</h3>
          <p className="boards-card-description-text">{boardData?.Description}</p>
          {/* <p className="boards-card-date-text">{time}</p> */}
        </div>
      </div>
    </div>
  );
}

export default BoardCard;
