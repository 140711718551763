import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../../../config/config";
import AddSection from "../../../proposalQuestions/addSection";
import AddQuestion from "../../../proposalQuestions/addQuestion";
const QuestionsList = (props) => {
  const [sectionIndex, setSectionIndex] = useState(0);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const authData = useSelector((state) => state?.authDetails?.details);
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const proposalDetails = props?.proposalDetails;
  const handleSectionTitleClicked = (val) => {
    setSectionIndex(val ? val : 0);
  };
  const handleDeleteQuestionClicked = (val) => {
    let questionToDelete = [
      ...proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION],
    ];
    questionToDelete.splice(val, 1);
    props?.handleDetails({
      ...proposalDetails,
      [ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]: questionToDelete,
    });
    if (val == 0) {
      setSectionIndex(0);
    } else if (
      sectionIndex + 1 >=
      proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
    ) {
      setSectionIndex(questionToDelete?.length - 1);
    }
  };
  return (
    <div className="proposal-add-questions-wrapper">
      <div className="proposal-add-questions-add-section-container">
        <AddSection
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
          sectionIndex={sectionIndex}
          handleSectionTitleClicked={handleSectionTitleClicked}
          handleDeleteQuestionClicked={handleDeleteQuestionClicked}
        />
      </div>
      <AddQuestion
        sectionIndex={sectionIndex}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
      />
    </div>
  );
};

export default QuestionsList;
