import React from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { Link } from "react-router-dom";
import { CamelCaseName, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import ViewMoreList from "../../engagementBar/components/viewMoreList";
import MoreOptionsList from "./moreOptionsList";
import { useState } from "react";
import * as SECRET_KEYS from "../../../../config/configKeys";
import SmallCardFirmographics from "../../productCardSmall/component/smallCardFirmographics";
import FeedbackForm from "../../globalModals/feedbackForm";
const ProductHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const USER_ID = authData?.data?.userId;
  const boardUserId = props?.boardUserId;
  const DATA = props?.data;
  const cardType = props?.cardType;
  const keyName = props?.keyName;
  const page = props?.page;
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: "vendor page",
    section: props?.section,
  };
  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.PRODUCT_NAME_CLICKED,
    page: "product page",
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "vendor",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleProductNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: "product",
      Item_Id: DATA?.Product_ID,
      Target: {
        page: signalTrackingProductObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const handleMenuOptions = () => {
    setFeedbackModalVisible((prev) => !prev);
  };

  return (
    <div className="product-card-header">
      <div className="flex-container">
        <div className="product-card-header-details m-r-xs">
          <img
            class="product-card-logo"
            alt={` ${DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
            data-src={
              DATA?.Account_Logo
                ? DATA?.Account_Logo
                : require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default
            }
            src={
              DATA?.Account_Logo
                ? DATA?.Account_Logo
                : require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
          <div className="product-card-vendor-details">
            <a
              href={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                DATA?.Product_Name,
                DATA?.Product_ID
              )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
            >
              <p
                className="product-card-product-name"
                onClick={() =>
                  handleProductNameLinkClicked(
                    `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                      DATA?.Product_Name,
                      DATA?.Product_ID
                    )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
                  )
                }
              >
                {CamelCaseName(DATA?.Product_Name ? DATA?.Product_Name : " ")}
              </p>
            </a>

            <a
              href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
              }`}
            >
              <p
                className="product-card-vendor-name"
                onClick={() =>
                  handleVendorNameLinkClicked(
                    `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                      KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                    }`
                  )
                }
              >
                {DATA?.Vendor_Name}{" "}
                {/* <img
                  class="product-card-verified-logo"
                  alt={` ${DATA?.Vendor_Name} thumbnail`}
                  data-src={require("../../../../assets/onboardingSvg/verified-red.svg").default}
                  src={require("../../../../assets/onboardingSvg/verified-red.svg").default}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                  }}
                /> */}
              </p>
            </a>

            {/* <p className="product-card-vendor-name">5K Followers</p> */}
          </div>
        </div>
        <SmallCardFirmographics data={DATA} type={props?.type} page={props?.page} />
      </div>
      <div tabIndex={0} onBlur={handleBlur} className="product-card-header-options p-r">
        {/* <div className="product-card-follow-button">
          <i class="feather icon icon-plus"></i>
          <p className="product-card-follow-button-text">Follow</p>
       
        </div> */}
        <FeatherIcon
          onClick={() => setToggleMenu(!toggleMenu)}
          icon="chevron-down"
          size="20"
          className="product-card-more-options cursor--pointer"
        />

        {toggleMenu && (
          <div className="view-more-container-all-cards-header  modern-card modern-card--elevated">
            <MoreOptionsList
              keyName={keyName}
              data={DATA}
              cardType={cardType}
              page={page}
              handleToggleMenu={handleToggleMenu}
              boardUserId={boardUserId}
              handleMenuOptions={handleMenuOptions}
            />
          </div>
        )}
      </div>
      {feedbackModalVisible ? (
        <FeedbackForm
          handleClose={() => setFeedbackModalVisible((prev) => !prev)}
          cardType={cardType}
          keyName={keyName}
          data={DATA}
        />
      ) : null}
    </div>
  );
};

export default ProductHeader;
