import React, { useEffect, useState } from "react";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import ChangeAccessList from "./changeAccessList";
import * as NOTIFICATION_KEYS from "../../../../config/config";
import FeatherIcon from "feather-icons-react";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { usePostProposalTeamsApiMutation } from "../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
const staticData = [
  {
    Name: "Can View",
    Internal_Name: "can_view",
    id: 1,
  },
  {
    Name: "Can Edit",
    Internal_Name: "can_edit",
    id: 2,
  },
];

// /updateUserAccessOnSharedProposal
function ChangeAccess(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const TEAMS_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [actionSelected, setActionSelected] = useState("");
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const updateUserAccess = useEnCryptPostApi(
    `/updateUserAccessOnSharedProposal`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostProposalTeamsApiMutation
  );
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  useEffect(() => {
    setActionSelected(props?.data?.[TEAMS_KEYS_API?.ACCESS]);
  }, [props?.data]);
  const handleActionClicked = (val) => {
    // props.handleActionClicked(val);
    // {

    //     "Share_Item_ID": 56,

    //     "Access_Type": "can_edit",

    //     "Shared_With": 65,

    //     "Item_Id":2431

    // }
    //   {
    //     "Email_Address": "vishal.jagamani@portqii.com",
    //     "Full_Name": "Vishal Jagamani",
    //     "Status": "Active",
    //     "Share_Item_ID": 29,
    //     "Item_Id": "2399",
    //     "Item_Type": "proposal",
    //     "Shared_By": 35,
    //     "Shared_With": 17,
    //     "Access_Type": "can_view"
    // }
    const rawData = {
      Share_Item_ID: props?.data?.Share_Item_ID,
      Access_Type: val?.Internal_Name,
      Shared_With: props?.data?.Shared_With,
      Item_Id: props?.data?.Item_Id,
    };
    updateUserAccess.handleTrigger(rawData);
    setActionSelected(val?.Internal_Name);
    setTimeout(() => {
      setToggleMenu(false);
    }, 100);
  };
  const handleShareBlurbClicked = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <div tabIndex={0} onBlur={handleBlur} className="engagement-bar-items p-r">
      <div className="teams-access-display-text-wrapper" onClick={() => handleShareBlurbClicked()}>
        <p className="teams-access-display-text p-r-half-base">
          {actionSelected == "can_edit" ? "Can Edit" : actionSelected == "can_view" ? "Can View" : "Owner"}
        </p>
        {toggleMenu ? (
          <FeatherIcon icon={"chevron-up"} size="17" color={"#37393f"} />
        ) : (
          <FeatherIcon icon={"chevron-down"} size="17" color={"#37393f"} />
        )}
      </div>
      {toggleMenu && (
        <div className="teams-change-access-container  modern-card modern-card--elevated">
          <ChangeAccessList
            handleActionClicked={handleActionClicked}
            handleToggleMenu={handleToggleMenu}
            data={staticData}
          />
        </div>
      )}
    </div>
  );
}

export default ChangeAccess;
