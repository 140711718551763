import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import Header from "./header";
import TimelineAndVendorsContent from "./timelineAndVendorsContent";
import AddVendors from "./addVendors";
const TimelineAndVendors = (props) => {
  const [showSection, setShowSection] = useState(true);
  const proposalDetails = props?.proposalDetails;
  return (
    <div style={{ width: "100%" }}>
      <Header
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        hideEdit={true}
        headerTitle={"Timeline & Vendors"}
      />
      {showSection ? (
        <TimelineAndVendorsContent
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
        />
      ) : null}
      {showSection ? (
        <AddVendors
          companyErrors={props?.companyErrors}
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
          showSection={showSection}
        />
      ) : null}
    </div>
  );
};

export default TimelineAndVendors;
