import React from "react";
import UseCaseInfoContent from "./useCaseInfoContent";

const UseCaseDetailPageInfo = (props) => {
  const USE_CASE_DATA = props?.data;

  return (
    <>
      <div className={`${"use-case-info-detail-page"}`}>
        <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"users"}
          name="Customers"
          navigate={"products"}
          keyName="Use_Case_Customers"
          page={props?.page}
          section={props?.section}
          array={true}
        />
        <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"zap"}
          navigate={"blurbs"}
          name="Industry"
          keyName={"Use_Case_Industry"}
          page={props?.page}
          section={props?.section}
          object={true}
        />
        {/* <UseCaseInfoContent
          data={USE_CASE_DATA}
          icon={"tag"}
          navigate={"blurbs"}
          name="Product Name"
          keyName={"Product_Name"}
          page={props?.page}
          section={props?.section}
        /> */}
      </div>
    </>
  );
};

export default UseCaseDetailPageInfo;
