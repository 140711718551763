import React from "react";
import { useParams } from "react-router";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";

const ManageProposals = () => {
  const PARAMS = useParams();
  const BREAD_CRUMB_BOARDS = [
    { name: "Home", active: true, link: "/" },
    { name: "My Account", active: true, link: "/profile" },
    { name: "Manage Proposals", active: false },
  ];

  return (
    <>
      <Header show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb parent={"boards"} data={BREAD_CRUMB_BOARDS} />
          </div>
        </div>
      </div>
      <div class="page-content page-content--reset page-content--top-reset">
        <div class="attached-navbar">
          <div class="container flex space-between align-items-bottom"></div>
        </div>
        <div class="container p-y-md">
          <div class="grid-auto-max tablet-1-col grid-col-gap-lg">
            <div class="my-profile__menu-items grid-four-columns tablet-2-col grid-col-gap-md grid-row-gap-md grid-row-gap-md">
              <div style={{maxWidth:'250px'}}>
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href="/proposals"
                  // onClick={handleViewBusinessProfileClicked}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-inbox big"></i>
                    <h4 class="m-t-xs"> My Proposals </h4>
                    <p class="text-sm tablet-hidden fw-400 text-grey-3">View my Proposals</p>
                  </div>
                </a>
              </div>
              <div style={{maxWidth:'250px'}}>
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href="/shared-proposals"
                  // onClick={handleViewBusinessProfileClicked}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-share-2 big"></i>
                    <h4 class="m-t-xs"> Shared Proposals </h4>
                    <p class="text-sm tablet-hidden fw-400 text-grey-3">View shared proposals</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProposals;
