import React from "react";
import BasicInformation from "./component/basicInformation/basicInformation";
import Questions from "./component/questions/questions";
import TimelineAndVendors from "./component/timelineAndVendors";

const ProposalSubmit = (props) => {
  const proposalDetails = props?.proposalDetails;
  return (
    <div style={{ width: "100%" }}>
      <BasicInformation
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
        handleScreenToShow={props?.handleScreenToShow}
      />
      <Questions
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
        companyErrors={props?.companyErrors}
      />
      <TimelineAndVendors
        companyErrors={props?.companyErrors}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
      />
    </div>
  );
};

export default ProposalSubmit;
