import React from "react";
import ReactDom from "react-dom";
import CompanyProfile from "../../../companyProfile/companyProfile";

const EditBusinessProfile = (props) => {
  return ReactDom.createPortal(
    <>
      <div
        className="ui dimmer modals page visible active portalModal"
        // style={{
        //   width: "100%",
        //   height: "100%",
        //   position: "absolute",
        //   top: "0px",
        // }}
      >
        <CompanyProfile handleClose={props?.handleClose} />
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default EditBusinessProfile;
