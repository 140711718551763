import React from "react";

const SideHeaderMobile = (props) => {
  const handleSideBarClicked = () => {
    props?.handleSideBarClicked(false);
  };
  return (
    <div className="slide-in__header">
      <a href="/">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className=".octate-mobile"
            width="150px"
            height="50px"
            style={{ marginRight: "8px" }}
            src={require("../../../../../assets/images/octateLogo181527.svg").default}
            alt={"octate.ai logo"}
          ></img>
          {/* <img
            className="octate-logo "
            width="120px"
            height="54px"
            src={require("../../../../../assets/images/OctateLogo.png")}
            alt={"octate.ai logo"}
          ></img> */}
        </div>
      </a>
      <div className="slide-in__close-btn" onClick={handleSideBarClicked}>
        <i className="feather icon icon-x m-none"></i>
      </div>
    </div>
  );
};
export default SideHeaderMobile;
