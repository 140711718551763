import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../../../utlis/customHooks/useLocalStorage";
import CompleteStepModal from "./completeStepModal";
import CreateNewVendorPortal from "./createNewVendorPortal";
import * as SECRET_KEYS from "../../../../config/configKeys";
import { usePostRequestCrudMutation, usePutRequestCrudMutation } from "../../../../adapters/xhr/crudServiceApi";
const VendorList = (props) => {
  const [RequestSent, setRequestSent] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const signUpComplete = SECRET_KEYS.CONFIG.SIGN_UP_COMPLETE;
  const SELECTED_ROLE = useSelector((state) => state?.authDetails?.selectedRole);
  const JOIN_ORGANIZATION = KEYS?.JOIN_ORGANIZATION;
  const vendorData = props?.data;

  const JoinOrClaimOrg = useEnCryptPostApi(
    `/InsertOrganizationForSelection`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (JoinOrClaimOrg?.data?.Status) {
      setRequestSent(true);
      setDisplayText(
        vendorData?.isAdminExist
          ? `Thank you for signing up on Octate AI. ${vendorData?.Vendor_Name} has been notified about your interest to join the team.`
          : `Thank you for signing up on Octate AI.`
      );
      // setUser({ ...authUser?.data, Persona: "Business User" });
      // props?.handleButtonVisible();
    }
  }, [JoinOrClaimOrg?.data]);

  const handleJoinOrClaim = (val) => {
    const signalTrackingPostData = {
      Actor: authUser?.data?.userId,
      Action: signUpComplete,
      Item_Type: null,
      Item_Id: null,
      Persona: "Business user",
      User_Type: "Known",
      Target: {
        page: "business page",
        link: "/business-profile/blurbs",
      },
      Object: {
        section: null,
        context: `signup complete`,
        page: null,
        Persona: "Business user",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    if (!RequestSent) {
      const rawData = {
        vendorId: val?.Vendor_ID,
        vendorName: val?.Vendor_Name,
        userId: authData?.data?.userId,
        createdAt: new Date().toString(),
        action: val?.isAdminExist ? "join" : "claim",
        source: props?.source ? props?.source : "default",
        domainURL: val?.Domain_URL,
        emailAddress: authData?.data?.emailId,
      };
      JoinOrClaimOrg?.handleTrigger(rawData);
      setModalVisible(true);
    }
  };

  const ShowStatusOfRequest = (val) => {
    switch (1) {
      case val?.Is_Joined_Requested:
        return JOIN_ORGANIZATION?.REQUEST_SENT;
      case val?.Is_Joined_Accepted:
        return JOIN_ORGANIZATION?.ACCEPTED;
      case val?.Is_Joined_Rejected:
        return JOIN_ORGANIZATION.REJECTED;
      case !val?.isAdminExist:
        return JOIN_ORGANIZATION.CLAIMED;
      case val?.Is_Claimed:
        return JOIN_ORGANIZATION?.CLAIMED;
      default:
        return JOIN_ORGANIZATION.REQUEST_SENT;
    }
  };

  const VENDOR_PROCESS =
    vendorData?.Is_Joined_Requested ||
    vendorData?.Is_Joined_Accepted ||
    vendorData?.Is_Joined_Rejected ||
    vendorData?.Is_Claimed;

  return (
    <>
      {/* <CreateNewVendorPortal /> */}
      {modalVisible ? (
        <CompleteStepModal
          displayText={displayText}
          navigatePath={
            vendorData?.isAdminExist ? "/" : SELECTED_ROLE?.path ? SELECTED_ROLE?.path : "/business-profile/blurbs"
          }
        />
      ) : null}
      <div className={`vendor-list-onboard-container`}>
        <div className="vendor-list-onboard-header-container">
          {vendorData?.Vendor_Logo_URL ? (
            <img
              class=" ls-is-cached lazyloaded vendor-onboarding-thumbnail"
              data-src={vendorData?.Vendor_Logo_URL}
              src={vendorData?.Vendor_Logo_URL}
              alt={SECRET_KEYS?.CONFIG?.VENDOR_LOGO}
            />
          ) : (
            <img
              class=" ls-is-cached lazyloaded vendor-onboarding-thumbnail"
              data-src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
              src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
              alt={SECRET_KEYS?.CONFIG?.VENDOR_LOGO}
            />
          )}
          <div className="vendor-list-onboard-header">
            <h5 className="vendor-list-onboard-name">{vendorData?.Vendor_Name}</h5>
            <div className="flex">
              {vendorData?.Vendor_Status === "Verified" ? (
                <img
                  class=" ls-is-cached lazyloaded"
                  data-src={require("../../../../assets/onboardingSvg/Shield-Done.svg").default}
                  src={require("../../../../assets/onboardingSvg/Shield-Done.svg").default}
                  alt={"shield"}
                />
              ) : null}
              <p className="vendor-onboarding-verified-text">
                {vendorData?.Vendor_Status === "Verified" ? JOIN_ORGANIZATION?.VERIFIED : JOIN_ORGANIZATION?.UNVERIFIED}
              </p>
            </div>
          </div>
        </div>
        {VENDOR_PROCESS ? (
          <div className="vendor-onboarding-join-button" role={"button"}>
            <>
              {vendorData?.Is_Joined_Requested || vendorData?.Is_Joined_Accepted ? (
                <i className="feather icon icon-check "></i>
              ) : null}{" "}
              {ShowStatusOfRequest(vendorData)}
            </>
          </div>
        ) : (
          <div className="vendor-onboarding-join-button" role={"button"} onClick={() => handleJoinOrClaim(vendorData)}>
            {RequestSent ? (
              <>
                <i className="feather icon icon-check "></i>

                {ShowStatusOfRequest(vendorData)}
              </>
            ) : null}

            {!RequestSent ? (vendorData?.isAdminExist ? JOIN_ORGANIZATION?.JOIN : JOIN_ORGANIZATION?.CLAIM) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(VendorList);
