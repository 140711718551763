import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useAddBookmarkMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import * as SECRET_KEYS from "../../../../config/configKeys";
const LikeIcon = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [EngagementBarData, setEngagementBarData] = useState({ ...props?.data });
  useEffect(() => {
    setEngagementBarData(props?.data);
  }, [props?.data]);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  const TriggerLikeBlurb = useEnCryptPostApi(
    `/likeManagement`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useAddBookmarkMutation
  );

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const ACTIONS =
    props?.cardType == "blurb"
      ? SECRET_KEYS.CONFIG.LIKE_BLURB
      : props?.cardType == "product"
      ? SECRET_KEYS.CONFIG.LIKE_PRODUCT
      : props?.cardType?.includes("usecase")
      ? SECRET_KEYS.CONFIG.LIKE_USE_CASE
      : "";

  const signalTrackingBreadCrumbObject = {
    Action: ACTIONS,
    page: props?.page,
    section: props?.page,
  };

  const handleLikePressed = (val) => {
    // if (authData?.data?.Permissions?.Like) {
    setEngagementBarData((prev) => ({
      ...prev,

      [KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]
        ? false
        : true,
      [KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]
        ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
          ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT] - 1
          : 0
        : EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
        ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT] + 1
        : 1,
    }));

    const rawData = {
      Action_Type: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED] ? "dislike" : "Liked",
      Item_Type: props?.cardType,
      Item_Id: EngagementBarData?.[props?.keyName],
      User_Id: USER_ID,
    };
    TriggerLikeBlurb?.handleTrigger(rawData);

    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: ACTIONS,
      Item_Type: props?.cardType,
      Item_Id: EngagementBarData?.[props?.keyName],
      Target: {
        page: "",
        link: "",
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED] ? "Unlike" : "Liked",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    // } else {
    //   props?.handleSignInModalVisible();
    // }
  };

  return (
    <>
      <div className="engagement-bar-items" role="button" data-tooltip={"Like"} data-inverted="true">
        <FeatherIcon
          icon="heart"
          size={15}
          color={EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED] ? "#C21807" : "#323232"}
          fill={EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED] ? "#C21807" : "none"}
          onClick={() => handleLikePressed(props?.data)}
        />
        <p className="engagement-bar-items-count">
          {EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
            ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
            : null}
        </p>
      </div>
    </>
  );
};

export default LikeIcon;
