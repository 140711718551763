import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import * as SECRET_KEYS from "../../../../config/configKeys";
function BlurbCtaButton(props) {
  const UrlUtmData = useSelector((state) => state?.homeDetails?.utmUrl);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingBlurbObject = {
    Action: SECRET_KEYS.CONFIG.CTA_LINK_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const utmQuery = props?.data?.call_to_action_link?.includes("?");
  // const domain =
  //   props?.data?.call_to_action_link?.split("https://")[1] ||
  //   props?.data?.call_to_action_link?.split("http://")[1]
  //     ? new URL(props?.data?.call_to_action_link)
  //     : props?.data?.call_to_action_link;
  // // const validateHostName = domain?.hostname;
  const CTA_BUTTON = props?.data?.call_to_action_name;

  const CTA_LINK = `${props?.data?.call_to_action_link?.replace(/\/$/, "")}${utmQuery ? "&" : "?"}${UrlUtmData &&
    UrlUtmData}&utm_id=${props?.data?.Vendor_ID}`;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Item_Type: null,
      Item_Id: null,
      Target: {
        page: CTA_LINK,
        link: CTA_LINK,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: val?.call_to_action_link,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    window.open(CTA_LINK, "_blank");
  };
  return CTA_LINK ? (
    <a
      className="blurb-card-action-button"
      target="_blank"
      onClick={() => handleBlurbLinkClicked(props?.data)}
      // href={CTA_LINK}
      style={{
        whiteSpace: "normal",
        textAlign: "center",
        marginRight: "5px",
        maxWidth: "200px",
      }}
    >
      {CTA_BUTTON && CTA_BUTTON?.length < 25 ? CTA_BUTTON : "Learn more"}
    </a>
  ) : null;
}

export default BlurbCtaButton;
