import React, { useState } from 'react';
import * as DASHBOARD_KEYS from '../../../../config/config';
import FeatherIcon from 'feather-icons-react';
import GraphFilters from './graphFilters';
import ShowGraphsWithFilter from './showGraphsWithFilter';
function ViewsGraph(props) {
  const [graphFilterDetails, setGraphFilterDetails] = useState([
    {
      name: 'All pages',
      type: 'page',
      id: 1,
    },
  ]);
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const FILTER_KEYS = DASHBOARD_CONFIG_KEYS?.FILTERS;
  const selectedTab = props?.selectedTab;

  const InfoData =
    selectedTab == 1 ? 'This is a visitor metric' : selectedTab == 2 ? 'This is a engagement metric' : 'This is a content metric';
  function handleFilterSelected(val) {
    const selectedFilter = graphFilterDetails?.map((list) => {
      if (list?.[FILTER_KEYS?.TYPE] == val?.[FILTER_KEYS.TYPE]) {
        return val;
      } else {
        return list;
      }
    });
    setGraphFilterDetails(selectedFilter);
  }
  return (
    <div
      className='modern-card p-x-mini p-y-mini'
      // style={{ width: "100%", height: "100%", marginTop: "30px" }}
    >
      <div
        style={{
          width: '100%',
          textAlign: 'start',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        className='p-b-sm p-t-mini'
      >
        <h2 className='dashboard-summary-overview-title-header p-x-base '>
          {selectedTab == 1 ? 'Visitor Metrics' : selectedTab == 2 ? 'Engagement Metrics' : 'Content Metrics'}
        </h2>
        <div style={{ marginTop: '5px' }} data-inverted data-tooltip={InfoData}>
          <FeatherIcon icon={'info'} size='20' role='button' color={'#5570f1'} />
        </div>
      </div>
      {selectedTab == 1 &&
        graphFilterDetails?.map((value) => {
          return (
            <GraphFilters
              handleFilterSelected={handleFilterSelected}
              label={value?.[FILTER_KEYS?.LABEL]}
              type={value?.[FILTER_KEYS?.TYPE]}
            />
          );
        })}

      <ShowGraphsWithFilter graphFilterDetails={graphFilterDetails} selectedTab={selectedTab} />
    </div>
  );
}

export default ViewsGraph;
