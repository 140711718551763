import React from "react";
import { ToTitleCase } from "../../hof/hof";

const ListCount = (props) => {
  const PAGE = props?.page;
  const PageNo = props?.pageNo;
  const total = props?.totalNumber;
  const searchText = props?.searchText;
  // ${PageNo * 10 - 9} - ${PageNo * 10} of
  return PAGE?.includes("search results") ? (
    searchText ? (
      <div className="search__info">
        <span>Showing results </span>
        {searchText ? (
          <>
            <span> for </span>
            <span className="search-term">{searchText}</span>
          </>
        ) : (
          <>
            <span> for </span>
            <span className="search-term">{props?.type}</span>
          </>
        )}
      </div>
    ) : (
      <div className="search__info">
        <span>{`${total} 
  ${props?.type?.slice(0, -1)}
   results`}</span>
      </div>
    )
  ) : total ? (
    <>
      <div className="search__info">
        {total < 10 ? (
          <span>{`${total} ${props?.type?.slice(0, -1)} result${total === 1 ? "" : "s"} `}</span>
        ) : (
          <>
            <span>{`${total} 
            ${props?.type?.slice(0, -1)}s
             `}</span>
          </>
        )}
        {searchText ? (
          <>
            <span> for </span>
            <span className="search-term">{searchText}</span>
          </>
        ) : null}
      </div>
    </>
  ) : null;
};

export default ListCount;
