import React from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";

const PasswordField = (props) => {
  const error = props?.error;
  const REQUIRED = props?.required;
  const [hiddenText, setHiddenText] = useState(true);

  return (
    <div className="m-b-sm input-field-wrapper-position-relative">
      {/* <label
        className={`input-field-label ${error ? "error" : ""} `}
        for={props?.label}
      >
        {props?.label}
      </label> */}

      <div class={`input-field-wrapper ${error ? "background-error" : ""}`}>
        <FeatherIcon icon={props?.icon} size="17" color={"#6E7079"} className="input-icons" />
        <input
          id={props?.label}
          type={hiddenText ? "password" : "text"}
          value={props?.value}
          name={props?.label}
          placeholder={props?.label}
          onChange={(e) => props?.changeText(e.target.value)}
          // className="input-field-text"
          className={`input-field-text ${error ? "background-error" : ""} ${REQUIRED ? "required-border-right" : ""}`}
          autocomplete={"new-password"}
          style={props?.icon ? { paddingLeft: "30px" } : { paddingLeft: "10px" }}
        />
        <FeatherIcon
          icon={hiddenText ? "eye" : "eye-off"}
          size="17"
          color={"#6E7079"}
          className="dropdown-icons"
          onClick={() => setHiddenText(!hiddenText)}
          role={"button"}
        />
      </div>
      {error ? <span class="input-field-text-error">{error}</span> : null}
    </div>
  );
};

export default PasswordField;
