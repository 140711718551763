import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../../../../config/configKeys";
import { useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { BreakText, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
function PopularUseCaseMedia(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const Data = props?.data;
  const blurbImage = Data?.Media?.SRC ?? Data?.image_src;

  const signalTrackingBlurbObject = {
    Action: "Use Case image clicked",
    page: props?.page,
    section: props?.section,
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleBlurbImageClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Item_Type: "Use case",
      Item_Id: Data?.blurbID,
      Target: {
        page: "Use case detail page",
        link: `/${ReplaceBlankSpaceByHyphen(Data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
          BreakText(Data?.title_level_1)
        )}-${Data?.blurbID}`,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: signalTrackingBlurbObject?.Action,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <>
      {/* // <div onClick={() => handleBlurbImageClicked()} className="blurb-card-image-container">
    //   <a
    //     href={`/${ReplaceBlankSpaceByHyphen(Data?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
    //       BreakText(Data?.title_level_1?.trim())
    //     )}-${Data?.blurbID?.trim()}/products`}
    //     className="blurb-card-image-container"
    //   >
    //     <img
    //       class="provider-card__logo ls-is-cached lazyloaded"
    //       className="blurb-card-image"
    //       alt="Blurb image"
    //       src={blurbImage ? blurbImage : Data?.account_logo ? Data?.account_log : KEYS?.CORRUPT_IMAGE_ICON}
    //       onError={({ currentTarget }) => {
    //         currentTarget.onerror = null; // prevents looping
    //         currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
    //       }}
    //     /> */}
      {Data?.Media?.valid || true ? (
        <img
          class="ls-is-cached lazyloaded"
          className="popular-case-study-image"
          alt={`${ENV_KEYS?.CONFIG?.IMAGE_FOR} ${Data?.title_level_1}`}
          // src={require("../../../../../assets/images/Case Study Image 1.png")}
          src={Data?.Customer_Additional_Details?.Usecase_Image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
          }}
          onClick={() => handleBlurbImageClicked(Data)}
        />
      ) : Data?.image_src ? (
        <img
          class="ls-is-cached lazyloaded"
          className="blurb-card-image"
          alt={`${ENV_KEYS?.CONFIG?.IMAGE_FOR} ${Data?.title_level_1}`}
          src={Data?.image_src}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
          }}
          onClick={() => handleBlurbImageClicked(Data)}
        />
      ) : Data?.account_logo ? (
        <img
          class="ls-is-cached lazyloaded"
          className="blurb-card-image"
          alt={`${ENV_KEYS?.CONFIG?.IMAGE_FOR} ${Data?.title_level_1}`}
          src={Data?.account_logo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
          }}
          onClick={() => handleBlurbImageClicked(Data)}
        />
      ) : (
        <img
          class="ls-is-cached lazyloaded"
          className="blurb-card-image"
          alt={`${ENV_KEYS?.CONFIG?.IMAGE_FOR} ${Data?.title_level_1}`}
          src={KEYS?.CORRUPT_IMAGE_ICON}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
          }}
          onClick={() => handleBlurbImageClicked(Data)}
        />
      )}
      {/* //   </a>
    // </div> */}
    </>
  );
}

export default PopularUseCaseMedia;
