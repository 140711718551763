import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { detectMob } from '../../hof/hof';
import { GetIdFormLink } from '../../hof/hof';
import { updateMetaTags } from '../../../../src/components/SEO/seo';
import * as CONFIG_KEYS from '../../../config/config';
import { useEnCryptSignalTrackingApi } from '../../customHooks/customHooks';
import { usePostRequestMutation } from '../../../adapters/xhr/generalApi';
import * as SECRET_KEYS from '../../../config/configKeys';

const StickyTab = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const BUSINESS_PAGE = KEYS?.BUSINESS_LANDING_PAGE_OVERVIEW;
  const PARAMS = useParams();
  const navigate = useNavigate();
  const tabIndex = PARAMS?.tab ?? props?.tabIndex;
  const tabData = props?.tabData;
  const scrollPosition = props?.scrollPosition;
  const NAVIGATE_PATH = props?.navigatePath;
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const permissionsDetails = props?.permissionsDetails;

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const signalTrackingProductObject = {
    Action: SECRET_KEYS.CONFIG.CLICKED,
    page: props?.page,
    section: `${props?.page}`,
  };

  const CanViewPermissions = permissionsDetails?.[PERMISSIONS_KEYS.PERMISSIONS]?.Read
    ? { display: PERMISSIONS_KEYS.PERMISSIONS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.PERMISSIONS_TAB, Read: false };
  const CanViewTeams = permissionsDetails?.[PERMISSIONS_KEYS.TEAMS]?.Read
    ? { display: PERMISSIONS_KEYS.TEAMS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.TEAMS_TAB, Read: false };
  const CanViewProduct = permissionsDetails?.[PERMISSIONS_KEYS.PRODUCT]?.Read
    ? { display: PERMISSIONS_KEYS.PRODUCT_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.PRODUCT_TAB, Read: false };
  const CanViewVendors = permissionsDetails?.[PERMISSIONS_KEYS.VENDOR]?.Read
    ? { display: PERMISSIONS_KEYS.VENDOR_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.VENDOR_TAB, Read: false };
  const CanViewBlurbs = permissionsDetails?.[PERMISSIONS_KEYS.BLURBS]?.Read
    ? { display: PERMISSIONS_KEYS.BLURBS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.BLURBS_TAB, Read: false };
  const CanViewFaq = permissionsDetails?.[PERMISSIONS_KEYS.FAQ]?.Read
    ? { display: PERMISSIONS_KEYS.FAQ_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.FAQ_TAB, Read: false };
  const CanViewUseCases = permissionsDetails?.[PERMISSIONS_KEYS.USE_CASE]?.Read
    ? { display: PERMISSIONS_KEYS.USE_CASE_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.USE_CASE_TAB, Read: false };
  const CanViewBoards = permissionsDetails?.[PERMISSIONS_KEYS.BOARDS]?.Read
    ? { display: PERMISSIONS_KEYS.BOARDS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.BOARDS_TAB, Read: false };
  const CanViewCustomers = permissionsDetails?.[PERMISSIONS_KEYS.CUSTOMERS]?.Read
    ? { display: PERMISSIONS_KEYS.CUSTOMERS_TAB, Read: true }
    : { display: PERMISSIONS_KEYS.CUSTOMERS_TAB, Read: false };
  const handleUrlForPage = (val, itemType) => {
    switch (itemType) {
      case 'product':
        return `/${PARAMS?.vendor}/product/${PARAMS?.name}/${val}`;
      case 'searchResult':
        return `/search/${val}${PARAMS?.name ? `/${PARAMS?.name}` : ''}`;
      case 'productExpert':
        return `/product-expert/home/${val}`;
      case 'board-detail':
        return `/board-detail${PARAMS?.type ? `/${PARAMS?.type}` : ''}/${val}`;
      case 'blurbDetail':
        return `${PARAMS?.vendor ? `/${PARAMS?.vendor}` : ''}/blurb${PARAMS?.name ? `/${PARAMS?.name}` : ''}/${val}`;
      default:
        return `/${itemType}${PARAMS?.name ? `/${PARAMS?.name}` : ''}/${val}`;
    }
  };

  const handleSignalTracking = (item, path) => {
    const TARGET_PATH = handleUrlForPage(item, path);
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductObject?.Action,
      Item_Type: 'tab navigator',
      Item_Id: null,
      Target: {
        page: `${path}-${item}`,
        link: TARGET_PATH,
      },
      Object: {
        section: signalTrackingProductObject?.section,
        context: 'tab switched',
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const handleNavigatePage = (val) => {
    handleSignalTracking(val, NAVIGATE_PATH);

    const idObject = GetIdFormLink(PARAMS?.name?.replaceAll('-', ' '));
    const seoBody = {
      type: NAVIGATE_PATH,
      id: idObject?.Id,
      tab: val,
      SearchText: idObject?.Name,
    };
    updateMetaTags(seoBody);
  };
  const MOBILE_DEVICE = detectMob();

  return scrollPosition > 600 && MOBILE_DEVICE ? (
    <div className='sticky-header-tab'>
      <div className='flex m-t-base' style={{ overflow: 'auto' }}>
        {tabData?.map((item, index) => {
          if (item?.display)
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
        })}
      </div>
    </div>
  ) : scrollPosition > 450 && props?.page === 'business profile' ? (
    <div className='sticky-header-tab mobile-hidden'>
      <div className='flex m-t-base'>
        {tabData?.map((item, index) => {
          if (item?.label == CanViewBlurbs?.display && CanViewBlurbs?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewProduct?.display && CanViewProduct?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewFaq?.display && CanViewFaq?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewPermissions?.display && CanViewPermissions?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewTeams?.display && CanViewTeams?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewBlurbs?.display && CanViewBlurbs?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewUseCases?.display && CanViewUseCases?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewBoards?.display && CanViewBoards?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (item?.label == CanViewCustomers?.display && CanViewCustomers?.Read) {
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
          } else if (props?.type === 'buyer' && (item?.display || MOBILE_DEVICE))
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
        })}
      </div>
    </div>
  ) : scrollPosition > 450 ? (
    <div className='sticky-header-tab'>
      <div className='flex m-t-base' style={{ overflow: 'auto' }}>
        {tabData?.map((item, index) => {
          if (item?.display)
            return (
              <Link to={handleUrlForPage(item?.value, NAVIGATE_PATH)}>
                <h2
                  className={`tab-index-text ${tabIndex == item?.value ? 'tab-index-selected' : null}`}
                  onClick={() => handleNavigatePage(item?.value)}
                >
                  {item?.label}
                </h2>
              </Link>
            );
        })}
      </div>
    </div>
  ) : null;
};

export default StickyTab;
