import React, { useState } from "react";
import Header from "../header";
import QuestionsList from "./questionsList";
import * as ADD_SECTION__KEYS from "../../../../../../config/config";
const Questions = (props) => {
  const [showSection, setShowSection] = useState(false);
  const proposalDetails = props?.proposalDetails;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  return (
    <div style={{ width: "100%" }}>
      <Header
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        hideEdit={true}
        headerTitle={"Questions"}
      />
      {showSection &&
      proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length ? (
        <QuestionsList
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
        />
      ) : null}
    </div>
  );
};

export default Questions;
