import React, {useEffect} from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import StickyTab from "../../utlis/hoc/tabNavigator/stickyTab";
import { GetIdFormLink } from "../../utlis/hof/hof";
import BusinessDetails from "../blurbDetail/components/businessDeatils/businessDetails";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import ProductHeader from "./components/productHeader/productHeader";
import BlurbsTab from "./components/tabs/blurbsTab";
import BoardsTab from "./components/tabs/boardsTab";
import FaqTab from "./components/tabs/faqTab";
import Features from "./components/tabs/features";
import ProductCustomers from "./components/tabs/productCustomers";
import ProductTab from "./components/tabs/productTab";
import UseCase from "./components/tabs/useCase";
import {updateMetaTags } from "../../../src/components/SEO/seo";

const ProductPage = (props) => {
  // const [tabIndex, setTabIndex] = useState(1);
  const [productData, setProductData] = useState("");
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const scrollPosition = useScrollPosition();
  const TYPE = props?.TYPE;
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  useEffect(() => {
    const seoBody = {
      type : 'product',
      id : PRODUCT?.Id,
      tab : tabIndex
    }
    updateMetaTags(seoBody);
  }, []);
  const GetApiData = useEnCryptGetApi(
    `/getNavigationPath?product_ID=${PRODUCT?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const page = `${props?.page}-${tabIndex}`;

  return (
    <>
      <Header page={page} show={true} />
      <div className="attached-navbar">
        <div className="fixed-nav-container flex space-between align-items-bottom  ui container">
          <SearchHeaderBreadCrumb
            page={page}
            parent={"productPage"}
            type={"Product"}
            params={PRODUCT?.Name}
            data={GetApiData?.data?.data}
          />
        </div>{" "}
      </div>
      <div className="business-profile-landing-page-container ui container">
        <ProductHeader
          page={page}
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          parent={"productPage"}
          section={"productPage"}
          handleProductData={(val) => setProductData(val)}
        />
        <StickyTab
          // changeTabIndex={(val) => setTabIndex(val)}
          tabIndex={tabIndex}
          tabData={KEYS?.PRODUCT_PAGE_TAB}
          scrollPosition={scrollPosition}
          navigatePath={"product"}
          page={page}
        />
        <div className="grid-business-profile mobile-1-col">
          {tabIndex == "blurbs" && <BlurbsTab page={page} />}
          {tabIndex == "similar-products" && <ProductTab page={page} />}
          {tabIndex == "faq" && <FaqTab page={page} />}
          {tabIndex == "features" && <Features page={page} />}
          {tabIndex == "customers" && <ProductCustomers page={page} tabIndex={tabIndex} />}
          {tabIndex == "use-cases" && <UseCase page={page} vendorId={productData?.Vendor_ID} />}
          {tabIndex == "overview" && (
            <BusinessDetails type={"buyer"} productData={productData} page={page} vendorId={productData?.Vendor_ID} />
          )}
          {tabIndex == "boards" && (
            <BoardsTab
              cardType={"product"}
              type={"buyer"}
              productData={productData}
              page={page}
              vendorId={PRODUCT?.Id}
            />
          )}
          {tabIndex !== "faq" &&
            tabIndex !== "similar-products" &&
            tabIndex !== "blurbs" &&
            tabIndex !== "features" &&
            tabIndex !== "use-cases" &&
            tabIndex !== "customers" &&
            tabIndex !== "boards" &&
            tabIndex !== "overview" && <BlurbsTab page={page} />}
          <div className="mobile-hidden">
            <BusinessDetails type={"buyer"} productData={productData} page={page} vendorId={productData?.Vendor_ID} />
          </div>
        </div>
      </div>
      <Footer page={page} />
    </>
  );
};

export default ProductPage;
