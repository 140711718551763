import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetProductsApiQuery } from "../../../../adapters/xhr/generalApi";
import * as NO_CONTENT from "../../../../config/config";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import NewProductCard from "../../../../utlis/hoc/newProductCard/newProductCard";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import TabHeader from "./components/tabHeader";

const VendorProducts = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const EDITED_PRODUCT = useSelector((state) => state?.homeDetails?.editProductData);
  const [pageNo, setPageNo] = useState(0);
  const [noResultsData, setNoResultsData] = useState(false);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const VENDOR_ID = props?.VENDOR_ID;
  const permissionsDetails = props?.permissionsDetails;
  const GetApiData = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${VENDOR_ID}&status=${vendorDetails?.Vendor_Status}&limit=30&offset=${pageNo}&User_ID=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetProductsApiQuery
  );
  const tabIndex = props?.tabIndex;
  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };
  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(GetApiData?.data?.data));
      } else {
        setLazyLoadData(GetApiData?.data?.data);
      }
    } else if (typeof GetApiData?.data?.data === "object" && GetApiData?.data?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [GetApiData?.data]);

  useEffect(() => {
    if (typeof EDITED_PRODUCT === "object" && Object.keys(EDITED_PRODUCT)?.length > 1) {
      const BLURB_INDEX = lazyLoadData?.findIndex((item) => item?.Product_ID === EDITED_PRODUCT?.Product_ID);
      let tempData = [...lazyLoadData];
      if (EDITED_PRODUCT?.status == "Delete") {
        tempData.splice(1, tempData[BLURB_INDEX]);
      } else {
        tempData[BLURB_INDEX] = EDITED_PRODUCT;
      }
      setLazyLoadData(tempData);
    }
  }, [EDITED_PRODUCT]);
  return (
    <div className="provider_profile_products-list grid-gap-md one-column-grid">
      <TabHeader
        permissionsDetails={permissionsDetails}
        dataLength={lazyLoadData?.length}
        tabIndex={props?.tabIndex}
        type={props?.type}
      />
      <div>
        {lazyLoadData?.length < 1 && (GetApiData?.isLoading || GetApiData?.isFetching) ? (
          <ProductSkeleton page={props?.page} />
        ) : lazyLoadData?.length > 0 ? (
          <InfiniteScrollComp
            Data={lazyLoadData}
            hasMoreData={GetApiData?.data?.data?.length >= 30}
            fetchData={fetchData}
          >
            <NewProductCard
              permissionsDetails={permissionsDetails}
              section={`business-landing-page`}
              page={props?.page}
              data={lazyLoadData}
              canDeleteProduct={lazyLoadData?.length > 1 ? true : false}
            />
          </InfiniteScrollComp>
        ) : noResultsData ? (
          <div className="search__result data-product-card no-content-card">
            <div className="no-content-text">
              {props?.type === "buyer"
                ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_PRODUCT
                : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_PRODUCT}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VendorProducts;
