import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { usePostRequestMutation } from '../../../../adapters/xhr/generalApi';
import { tempAuthDetails } from '../../../../contexts/reducer/authReducer';
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from '../../../../utlis/customHooks/customHooks';
import { useLocalStorage } from '../../../../utlis/customHooks/useLocalStorage';
import LoadingSpinner from '../../../../utlis/spinner/loadingSpinner';
import CountdownTimer from './countdownTimer';
import * as SECRET_KEYS from '../../../../config/configKeys';
import OtpInput from 'react-otp-input';
import { isMobile } from 'react-device-detect';
const OtpInputNew = () => {
  const [authUser, setUser] = useLocalStorage('user');
  const [remountComponent, setRemountComponent] = useState(0);
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [otp5, setOtp5] = useState('');
  const [otp6, setOtp6] = useState('');
  const [OtpValue, setOtpValue] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [error, setError] = useState(null);
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  const signUpComplete = SECRET_KEYS.CONFIG.SIGN_UP_COMPLETE;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const OTP = KEYS?.SIGN_UP_EMAIL_VERIFICATION;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailAddress = tempAuthData?.data?.emailId;
  const domain = emailAddress?.slice(emailAddress?.indexOf('@') + 1);
  const emailStart = emailAddress?.slice(0, 3);
  const asterisksLength = emailAddress?.length - (domain?.length + 4);

  const handleInput = (val, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (regex.test(val)) {
      switch (inputNum) {
        case 1:
          setOtp1(val);
          break;
        case 2:
          setOtp2(val);
          break;
        case 3:
          setOtp3(val);
          break;
        case 4:
          setOtp4(val);
          break;
        case 5:
          setOtp5(val);
          break;
        case 6:
          setOtp6(val);
          break;
        default:
          break;
      }
    }
  };

  const handleFocus = (inputNum) => {
    const input = document.getElementById(`otp${inputNum - 1}`);
    input.focus();
  };

  const clearState = (inputNum) => {
    switch (inputNum) {
      case 1:
        if (otp1) {
          setOtp1('');
          break;
        } else {
          setOtp1('');
        }
        break;
      case 2:
        if (otp2) {
          setOtp2('');
          break;
        } else {
          setOtp1('');
          handleFocus(inputNum);
        }
        break;
      case 3:
        if (otp3) {
          setOtp3('');
          break;
        } else {
          setOtp2('');
          handleFocus(inputNum);
        }
        break;

      case 4:
        if (otp4) {
          setOtp4('');
          break;
        } else {
          setOtp3('');
          handleFocus(inputNum);
        }
        break;
      case 5:
        if (otp5) {
          setOtp5('');
          break;
        } else {
          setOtp4('');
          handleFocus(inputNum);
        }
        break;
      case 6:
        if (otp6) {
          setOtp6('');
          break;
        } else {
          setOtp5('');
          handleFocus(inputNum);
        }
        break;
      default:
        break;
    }
  };

  function handleOtp(val) {
    setOtpValue(val);
  }

  const handleKeyDown = (event, inputNum) => {
    var regex = /^-?\d*\.?\d*$/;
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setOtpError(false);
      clearState(inputNum);
      // setTimeout(() => {
      //   if (inputNum === 1) return;
      //   else if (inputNum === 6) {
      //     if (maxInputReached) {
      //       const input = document.getElementById(`otp${inputNum}`);
      //       clearState(inputNum);
      //       setMaxInputReached(false);
      //     } else {
      //       const input = document.getElementById(`otp${inputNum - 1}`);
      //       clearState(inputNum - 1);
      //       input.focus();
      //     }
      //   } else {
      //     clearState(inputNum - 1);
      //     const input = document.getElementById(`otp${inputNum - 1}`);
      //     input.focus();
      //   }
      // }, 100);
    } else if (regex.test(event.key)) {
      setOtpError(false);
      setTimeout(() => {
        const input = document.getElementById(`otp${inputNum + 1}`);
        input.focus();
      }, 5);
    }
  };

  const VerifyOtpPostApi = useEnCryptPostApi(
    tempAuthData?.resetPassword ? `/user/OTPVerification` : `/user/signInOTPVerification`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const ResendOtpPostApi = useEnCryptPostApi(`/user/resendOTP`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  useEffect(() => {
    if (VerifyOtpPostApi?.data?.userExist) {
      dispatch(
        tempAuthDetails({
          data: { ...tempAuthData?.data },
          loading: true,
          verifyOTP: true,
          joinOrg: true,
          resetPassword: true,
        })
      );
      let tempObj = VerifyOtpPostApi?.data;
      if (VerifyOtpPostApi?.data?.navigatePath) {
        delete tempObj.userExist;
      }
      let navigationPath = VerifyOtpPostApi?.data?.navigatePath ? '/user-invite' : tempAuthData?.target;
      if (navigationPath == '/') {
        const signalTrackingPostData = {
          Actor: authUser?.data?.userId,
          Action: signUpComplete,
          Item_Type: null,
          Item_Id: null,
          User_Type: 'Known',
          Persona: 'Buyer',
          Target: {
            page: 'home page',
            link: '/',
          },
          Object: {
            section: null,
            context: `signup complete`,
            page: null,
            Persona: 'Buyer',
          },
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
      let SetUserExist = VerifyOtpPostApi?.data?.navigatePath ? tempObj : VerifyOtpPostApi?.data;
      setUser({ ...authUser?.data, ...SetUserExist });
      navigate(navigationPath, {
        replace: true,
      });
    } else if (!VerifyOtpPostApi?.data?.userExist && VerifyOtpPostApi?.data?.message) {
      setOtpError(true);
      setError({ otpError: VerifyOtpPostApi?.data?.message });
    }
  }, [VerifyOtpPostApi?.data]);

  const handleSubmitPressed = () => {
    // const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    if (OtpValue?.length < 6) {
      setOtpError(true);
      // setError({ otpError: "Please enter 6 digit otp" });
    } else {
      const rawData = {
        emailId: tempAuthData?.data?.emailId,
        userId: tempAuthData?.data?.userId,
        OTP: parseInt(OtpValue),
        Persona: tempAuthData?.data?.Persona,
        data: { ...tempAuthData?.data },
      };
      VerifyOtpPostApi?.handleTrigger(rawData);
    }
  };

  const handleResend = () => {
    const rawData = {
      emailId: tempAuthData?.data?.emailId,
      userId: tempAuthData?.data?.userId,
      type: tempAuthData?.resetPassword ? 'forgotPassword' : 'signUp',
    };

    ResendOtpPostApi?.handleTrigger(rawData);
    setRemountComponent(Math.random());
  };

  const handlePasteOtp = (event) => {
    event.preventDefault();
    const pasteText = event.clipboardData.getData('text/plain').trim().slice(0, 6);
    for (let i = 0; i < 6 && i < pasteText.length; i++) {
      handleInput(pasteText[i], i + 1);
    }
  };

  return (
    <div class='splitted-page__content'>
      <div class='splitted-page__container' style={isMobile ? { padding: '5px' } : {}}>
        <div class='m-y-auto'>
          <div className='grid-max-auto'>
            <div type='checkbox' className='otp-verification-checkbox' />
            <div className='otp-verification-container'>
              <h1 className='otp-verification-title'>{OTP?.EMAIL_VERIFICATION}</h1>
              <p className='otp-verification-text m-b-md'>
                {OTP?.EMAIL_VERIFICATION_SUBTEXT_START}
                {'  '}
                {/* {tempAuthData?.data?.emailId} */}
                <strong>{`${emailStart}${'*'.repeat(asterisksLength)}@${domain}`}</strong>
                {'  '}
                {OTP?.EMAIL_VERIFICATION_SUBTEXT_END}
              </p>
              <div className='input-field-wrapper-position-relative'>
                <div className='flex space-between m-b-md'>
                  <OtpInput
                    value={OtpValue}
                    inputStyle={`Otp-verification-number`}
                    onChange={handleOtp}
                    inputType={'tel'}
                    shouldAutoFocus={true}
                    numInputs={6}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                {otpError ? (
                  <p className='input-field-text-error' style={{ top: '80px' }}>
                    {error?.otpError}
                  </p>
                ) : null}
              </div>
              <div key={remountComponent}>
                <p className='otp-verification-resend-text m-b-md'>
                  {OTP?.RESEND_QUESTION_START}{' '}
                  {/* <p className="resend-button" role={"button"}>
                  {OTP?.RESEND_CODE}{" "}
                </p> */}
                  <CountdownTimer seconds={40} onResend={() => handleResend()} />
                </p>
              </div>
              {VerifyOtpPostApi?.isLoading ? (
                <div className='button primary fluid create-account-button' style={{ width: '170px' }}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className='create-account-button' role={'button'} onClick={() => handleSubmitPressed()}>
                  {OTP?.VERIFY_BUTTON}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpInputNew;
