import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../../../config/config";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import EditProposalPortal from "../../../../proposals/component/editProposalPortal";
import TableContentMoreOptions from "./tableContentMoreOptions";
import { notificationTargetFn } from "../../../../../contexts/reducer/notificationReducer";
const ProposalBusinessTableContent = (props) => {
  const VENDOR_ID = props?.VENDOR_ID;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL?.PROPOSAL_TABLE;
  const proposalSearchFilters = props?.proposalSearchFilters;
  const [proposalTableData, setProposalData] = useState(props?.proposalTableData ? props?.proposalTableData : []);
  const permissionsDetails = props?.permissionsDetails;
  const notificationJson = props?.notificationJson;
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [proposalTableHeader, setProposalTableHeader] = useState(
    props?.proposalTableHeader ? props?.proposalTableHeader : []
  );
  const [selectedProposalId, setSelectedProposalId] = useState(null);

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  // useEffect(() => {
  //   if (notificationJson?.object?.Id) {
  //     handleProposalTitleClicked({
  //       [API_KEYS?.PROPOSAL_ID]: notificationJson?.object?.Id,
  //     });
  //   }
  // }, [notificationJson]);
  const filterProposalTitle = () => {
    if (proposalSearchFilters?.filterText?.length) {
      const filteredData = props?.proposalTableData?.filter((val) => {
        return val?.[API_KEYS?.PROPOSAL_TITLE]
          ?.toLocaleLowerCase()
          .includes(proposalSearchFilters?.filterText?.toLocaleLowerCase());
      });
      setProposalData(filteredData);
    } else {
      setProposalData(props?.proposalTableData);
    }
  };
  useEffect(() => {
    filterProposalTitle();
  }, [proposalSearchFilters?.filterText, props?.proposalTableData]);
  useEffect(() => {
    if (props?.proposalTableHeader?.length) {
      setProposalTableHeader(props?.proposalTableHeader);
    } else {
      setProposalTableHeader([]);
    }
  }, [props?.proposalTableHeader]);
  const handleInputChange = (val) => {
    const changeIsSelectedData = props?.proposalTableData?.map((list) => {
      if (list?.[API_KEYS?.PROPOSAL_ID] == val?.[API_KEYS?.PROPOSAL_ID]) {
        return val;
      } else {
        return list;
      }
    });
    const checkIsSelectedData = changeIsSelectedData?.filter((value) => {
      return !value?.isSelected;
    });
    if (checkIsSelectedData?.length) {
      let arrayData = [...props?.proposalTableHeader];
      arrayData[0] = { name: "checkbox", id: 7, isSelected: false };
      props?.handleProposalTableHeader(arrayData);
    } else {
      let arrayData = [...props?.proposalTableHeader];
      arrayData[0] = { name: "checkbox", id: 7, isSelected: true };
      props?.handleProposalTableHeader(arrayData);
    }

    props?.handleProposalTableData(changeIsSelectedData);
  };
  const handleInputChangeHeader = () => {
    const headerIsSelected = props?.proposalTableHeader.map((val) => {
      if (val?.name == "checkbox") {
        return { ...val, isSelected: !val?.isSelected };
      } else {
        return val;
      }
    });
    props?.handleProposalTableHeader(headerIsSelected);
    const changeIsSelectedData = props?.proposalTableData?.map((list) => {
      const value = {
        ...list,
        isSelected: headerIsSelected[0]?.isSelected ? true : false,
      };
      return value;
    });
    props?.handleProposalTableData(changeIsSelectedData);
  };
  const handleRowClicked = (val, index) => {
    setActiveIndex(index);
    props?.handleActiveRowData(val, index);
  };
  useEffect(() => {
    if (activeIndex == 0 && proposalTableData?.length) {
      const listValue = proposalTableData[0];
      props?.handleActiveRowData(listValue, 0);
    }
  }, [proposalTableData]);
  return (
    <>
      <table style={{ marginBottom: "95px" }} className="add-product-pricing-table-main-wrapper">
        <thead className="add-product-pricing-table-header-wrapper">
          {proposalTableHeader?.map((list) => {
            return (
              <th className="proposal-table-header-sub-wrapper">
                {list?.name == "checkbox" ? (
                  <input
                    disabled={permissionsDetails?.Delete ? false : true}
                    type="checkbox"
                    className="checkbox-input-style-proposal"
                    checked={list?.isSelected}
                    onChange={handleInputChangeHeader}
                  ></input>
                ) : (
                  <div className="dis-f-r">
                    <p className="proposal-table-header-text">{list?.name}</p>
                    {/* <img
                      src={
                        require("../../../../../assets/images/sort.svg").default
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                      role={"button"}
                      // onClick={() => handleSortTableByColumn(item)}
                    /> */}
                  </div>
                )}
              </th>
            );
          })}
        </thead>
        <tbody style={{ paddingTop: "20px" }}>
          {proposalTableData?.length
            ? proposalTableData?.map((list, index) => {
                return list?.Deleted || list?.[API_KEYS?.STATUS] == "Deleted" ? (
                  <tr className={`proposal-table-content-row-container--error table-row-active--error`}>
                    <td className="p-y-base">
                      <input
                        type="checkbox"
                        disabled={permissionsDetails?.Delete ? false : true}
                        className="checkbox-input-style-proposal m-x-base cursor--pointer"
                        checked={list?.isSelected}
                      ></input>
                    </td>
                    <td className="proposal-table-show-all-data--disable p-r p-y-base cursor--pointer">
                      {list?.[API_KEYS?.PROPOSAL_TITLE]}
                    </td>
                    <td className="proposal-table-show-all-data--disable p-r p-y-base">
                      {list?.[API_KEYS?.CREATED_ON]
                        ? moment(list?.[API_KEYS?.CREATED_ON] * 1000).format("DD MMM yyyy")
                        : 0}
                    </td>
                    <td className="proposal-table-show-all-data--disable p-r p-y-base">
                      {list?.[API_KEYS?.TARGET_DATE]
                        ? moment(list?.[API_KEYS?.TARGET_DATE] * 1000).format("DD MMM yyyy")
                        : "Not Selected"}
                      {/* - hh:mm a */}
                    </td>
                    <td className="proposal-table-show-all-data--disable p-r p-y-base">
                      {list?.[API_KEYS?.PARTICIPANTS]}
                    </td>
                    <td className={"proposal-table-show-all-data--disable p-r p-y-base"}>
                      <div className={`${"proposal-canceled"}`}>{"Withdrawn"}</div>
                    </td>
                    {/* <div
                      className={`${
                        proposalTableData?.length - 1 == index
                          ? "padding-bottom-show-more"
                          : ""
                      }`}
                    > */}
                    {/* <td
                      className={
                        "proposal-table-show-all-data p-r p-y-base cursor--pointer"
                      }
                    >
                      <TableContentMoreOptions list={list} index={index} />
                    </td> */}
                    {/* </div> */}
                  </tr>
                ) : (
                  <tr
                    onClick={() => handleRowClicked(list, index)}
                    className={`proposal-table-content-row-container ${activeIndex == index ? "table-row-active" : ""}`}
                  >
                    <td className="p-y-base">
                      <input
                        disabled={permissionsDetails?.Delete ? false : true}
                        type="checkbox"
                        className="checkbox-input-style-proposal m-x-base cursor--pointer"
                        checked={list?.isSelected}
                        onChange={() =>
                          handleInputChange({
                            ...list,
                            isSelected: !list?.isSelected,
                          })
                        }
                      ></input>
                    </td>
                    <td className="proposal-table-show-all-data p-r p-y-base cursor--pointer">
                      {list?.[API_KEYS?.PROPOSAL_TITLE]}
                    </td>
                    <td className="proposal-table-show-all-data p-r p-y-base">
                      {list?.[API_KEYS?.CREATED_ON]
                        ? moment(list?.[API_KEYS?.CREATED_ON] * 1000).format("DD MMM yyyy")
                        : 0}
                    </td>
                    <td className="proposal-table-show-all-data p-r p-y-base">
                      {list?.[API_KEYS?.PROPOSAL_TIMELINE]
                        ? moment(list?.[API_KEYS?.PROPOSAL_TIMELINE] * 1000).format("DD MMM yyyy")
                        : "Not Selected"}
                      {/* - hh:mm a */}
                    </td>
                    <td className="proposal-table-show-all-data p-r p-y-base">{list?.[API_KEYS?.PARTICIPANTS]}</td>
                    <td className={"proposal-table-show-all-data p-r p-y-base"}>
                      <div
                        className={`${
                          list?.[API_KEYS?.STATUS] == "Pending"
                            ? "proposal-draft"
                            : list?.[API_KEYS?.STATUS] == "In Progress"
                            ? "proposal-in-progress"
                            : list?.[API_KEYS?.STATUS] == "Submitted"
                            ? "proposal-completed"
                            : list?.[API_KEYS?.STATUS] == "Completed"
                            ? "proposal-completed"
                            : list?.[API_KEYS?.STATUS] == "Canceled"
                            ? "proposal-canceled"
                            : "proposal-none"
                        }`}
                      >
                        {list?.[API_KEYS?.STATUS]}
                      </div>
                    </td>
                    {/* <div
                      className={`${
                        proposalTableData?.length - 1 == index
                          ? "padding-bottom-show-more"
                          : ""
                      }`}
                    > */}
                    <td className={"proposal-table-show-all-data p-r p-y-base cursor--pointer"}>
                      <TableContentMoreOptions
                        permissionsDetails={permissionsDetails}
                        notificationJson={notificationJson}
                        list={list}
                        index={index}
                      />
                    </td>
                    {/* </div> */}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!proposalTableData?.length && (
        <div className="no-results-found-proposal-table">
          <h2 className="no-results-found-proposal-table-text">No results found</h2>
        </div>
      )}
    </>
  );
};

export default ProposalBusinessTableContent;
