import React from "react";
import AddVendorMultiSelect from "./addVendorMutiSelect";
import RecommendedVendors from "./recommendedVendors";
import SelectedVendors from "./selectedVendors";
import * as SECRET_KEY from "../../../../../config/config";
const AddVendors = (props) => {
  const proposalDetails = props?.proposalDetails;
  const companyErrors = props?.companyErrors;
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const ADD_PROPOSAL_ERROR_KEYS =
    SECRET_KEY?.CONFIG?.ADD_PROPOSAL_ERROR_MESSAGES;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  return (
    <div className="proposal-submit-timeline-and-vendors-content-add-vendor-main-wrapper">
      <h3 className="proposal-submit-timeline-and-vendors-content-title-submit">
        Select Vendors to Submit
      </h3>
      <div className="proposal-submit-selected-v-recommended-v-main-wrapper">
        <RecommendedVendors
          proposalDetails={proposalDetails}
          handleDetails={handleDetails}
        />
        <SelectedVendors
          companyErrors={companyErrors}
          proposalDetails={proposalDetails}
          handleDetails={handleDetails}
        />
      </div>
    </div>
  );
};

export default AddVendors;
