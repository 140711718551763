import { apiNew } from '../../contexts/redux/slice';
export const coreService = apiNew.injectEndpoints({
  endpoints: (builder) => ({
    getRequestNew: builder.query({
      query: (getQuery) => ({
        url: 'api/get',
        params: getQuery,
      }),
    }),
    // delistProductDetails: builder.mutation({
    //   invalidatesTags: ["productList"],
    //   query: (body) => ({
    //     url: `api/post`,
    //     method: "POST",
    //     body,
    //   }),
    // }),
  }),
});
export const { useGetRequestNewQuery } = coreService;
