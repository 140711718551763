import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import VendorFirmographics from "../newVendorCard/components/vendorFirmographics";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CreateNewBoard from "../Boards/createNewBoard";
import EditBoardCard from "./editBoardCard";
import moment from "moment";
function BoardCardSmall(props) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const permissionsDetails = props?.permissionsDetails;
  const data = props?.data;

  const USER_ID = authData?.data?.userId;

  return (
    <div class="grid-two-columns col-gap-md tablet-1-col tablet-landscape-1-col grid-col-gap-md">
      {data?.length
        ? data.map((val) => {
            const boardCardDetails = val;
            const BoardImage = boardCardDetails?.Board_Image;
            const vendorCount = val?.Vendor_Count;
            const useCaseCount = val?.Use_Case_Count;
            const blurbCount = val?.Blurb_Count;
            const productCount = val?.Product_Count;
            const time = moment(val?.Updated_At ? val?.Updated_At * 1000 : new Date().getTime()).fromNow();
            return (
              <div key={boardCardDetails?.Board_ID} class="provider-profile__alternative p-r">
                <div class="provider-profile__alternative__header">
                  {BoardImage ? (
                    <img
                      class="provider-profile__alternative__header-logo ls-is-cached lazyloaded"
                      src={BoardImage ? BoardImage : KEYS?.CORRUPT_IMAGE_ICON}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require("../../../assets/images/boardSmallIcon.jpg");
                      }}
                      alt={` ${boardCardDetails?.Name} thumbnail`}
                    />
                  ) : (
                    <img
                      class="provider-profile__alternative__header-logo ls-is-cached lazyloaded"
                      src={require("../../../assets/images/boardSmallIcon.jpg")}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require("../../../assets/images/boardSmallIcon.jpg");
                        // "../../../src/assets/images/boardBlankImage.jpg"
                      }}
                      alt={` ${boardCardDetails?.Name} thumbnail`}
                    />
                  )}

                  <div>
                    <div class="provider-profile__alternative__header-name">{boardCardDetails?.Name}</div>

                    <p className="provider-profile__alternative__header-time">{`${
                      val?.Vendor_ID ? "Vendor" : val?.Type
                    } board`}</p>

                    {val?.Updated_At ? (
                      <p className="provider-profile__alternative__header-time">{`${time} ago`}</p>
                    ) : null}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                      className="m-t-half-base"
                    >
                      {vendorCount ? (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="vertical-provider-card__subtitle"
                        >
                          <p style={{ margin: "0px", color: "#080808", fontSize: "14px" }}>{vendorCount}</p>
                          <p className="p-l-half-base">{"Vendors"}</p>
                        </div>
                      ) : null}
                      {productCount ? (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="vertical-provider-card__subtitle"
                        >
                          <p style={{ margin: "0px", color: "#080808", fontSize: "14px" }}>{productCount}</p>
                          <p className="p-l-half-base">{"Products"}</p>
                        </div>
                      ) : null}
                      {blurbCount ? (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="vertical-provider-card__subtitle"
                        >
                          <p style={{ margin: "0px", color: "#080808", fontSize: "14px" }}>{blurbCount}</p>
                          <p className="p-l-half-base">{"Blurbs"}</p>
                        </div>
                      ) : null}

                      {useCaseCount ? (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="vertical-provider-card__subtitle"
                        >
                          <p style={{ margin: "0px", color: "#080808", fontSize: "14px" }}>{useCaseCount}</p>
                          <p className="p-l-half-base">{"Use Case"}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div class="provider-profile__alternative__content">
                  <div class="provider-profile__alternative__header-description vendor-card-description-wrap-text">
                    {boardCardDetails?.Description}
                  </div>
                </div>
                <div class="vertical-product-card-for-provider__footer grid-two-columns tablet-1-col grid-col-gap-base">
                  <a
                    class="vertical-product-card-for-provider__cta small primary modern button"
                    href={`/board/${ReplaceBlankSpaceByHyphen(boardCardDetails?.Name, boardCardDetails?.Board_ID)}`}
                  >
                    {"View Board"}
                    <i className="feather icon icon-arrow-right "></i>
                  </a>
                </div>
                {boardCardDetails.User_ID == authData?.data?.userId &&
                permissionsDetails &&
                permissionsDetails?.Update ? (
                  <EditBoardCard page={props?.page} boardCardDetails={boardCardDetails} />
                ) : null}
              </div>
            );
          })
        : null}
    </div>
  );
}

export default BoardCardSmall;
