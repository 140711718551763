import React from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
function BoardOverView(props) {
  const handleFilteredData = (e) => {
    props?.handleFilterItems(e.target.value);
  };
  return (
    <aside  className="boards-aside-container">
      {/* <div className="flex space-between m-b-mini">
        <h3 className="overview-title">Filters</h3>
      </div> */}
      <div className="filter-search-input-container ">
        <FeatherIcon icon="search" size="20" color={"#394856"} className="filter-input-field-search-icon" />
        <input
          className="filter-search-input"
          placeholder={`Search board items`}
          value={props?.filteredText}
          onChange={(e) => handleFilteredData(e)}
          autoFocus={true}
        />
      </div>
    </aside>
  );
}

export default BoardOverView;
