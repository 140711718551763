import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
const AddQuestionType = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const addType = props?.addType;
  const error = props?.proposalQuestionFormError;
  //   const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  //   const [fields, setFields] = useState(addType == "section" ? {} : []);

  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const PROPOSAL_ADD_QUESTION = KEYS?.PROPOSAL_ADD_QUESTION;
  const fields = props?.fields;
  const proposalBasicDetails = fields;
  const data = addType == "section" ? props?.data : props?.data;
  //   useEffect(() => {
  //     setFields(props?.data);
  //   }, [data]);
  // https://thebox-dev.portqii.com/proposals/getAnswerTypes
  const getAnswerTypes = useEnCryptGetApi(
    `/getAnswerTypes`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  // [
  //   { name: "Text Box", id: 1 },
  //   { name: "Radio Button", id: 2 },
  //   { name: "Check Box", id: 3 },
  //   { name: "Date Picker", id: 4 },
  // ]
  return (
    <div className="add-question-type-main-container p-y-sm">
      {/* m-y-company-profile-input-wrapper  m-x-sm*/}
      <div
        class="field p-r m-x-sm flex-question-input-field"
        id="field-user__email"
      >
        <InputFieldProfile
          label={PROPOSAL_ADD_QUESTION?.TEXT_BOX}
          value={fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_NAME]}
          removeBottomPadding={true}
          changeText={(val) =>
            props?.questionFields({
              ...fields,
              [ADD_QUESTION_CONFIG_KEYS?.QUESTION_NAME]: val,
            })
          }
          error={error?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_NAME]}
        />
      </div>
      <div className="flex-question-dropdown-field m-r-sm">
        {/* m-y-md */}
        <div class="field-company-profile p-r " id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.questionFields({
                ...fields,
                [ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]: val,
              })
            }
            removeFiltering={true}
            data={getAnswerTypes}
            placeholder={"Select Question Type"}
            // flag={true}
            selectedVal={fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]}
            name={"Proposal_Answer_Type"}
            // label={ADD_PROPOSAL?.BUSINESS_SIZE_LABEL}
          />
          {error?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] ? (
            <span class="input-field-dropdown-error">
              {error?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddQuestionType;
