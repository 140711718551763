import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import AddSection from "./addSection";
import AddQuestion from "./addQuestion";
import * as ADD_SECTION__KEYS from "../../../../config/config";
const ProposalQuestions = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const USER_CAN_EDIT = true;
  const authData = useSelector((state) => state?.authDetails?.details);
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const erroredSectionIndex = props?.companyErrors?.Section_Index;
  const [sectionIndex, setSectionIndex] = useState(erroredSectionIndex ? erroredSectionIndex : 0);
  const proposalDetails = props?.proposalDetails;
  const handleSectionTitleClicked = (val) => {
    setSectionIndex(val ? val : 0);
  };
  const handleDeleteQuestionClicked = (val) => {
    let questionToDelete = [...proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]];
    questionToDelete.splice(val, 1);
    props?.handleDetails({
      ...proposalDetails,
      [ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]: questionToDelete,
    });
    if (val == 0) {
      setSectionIndex(0);
    } else if (sectionIndex + 1 >= proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length) {
      setSectionIndex(questionToDelete?.length - 1);
    }
  };
  useEffect(() => {
    if (erroredSectionIndex) {
      setSectionIndex(erroredSectionIndex);
    }
  }, [erroredSectionIndex]);

  return (
    <div className="proposal-add-questions-wrapper">
      <div className="proposal-add-questions-header-main-wrapper">
        <div className="proposal-add-questions-header-title-text">{ADD_PROPOSAL?.ADD_Q_SUB_HEADER}</div>
        <div className="company-details-header-title-icon">{/* <FeatherIcon icon="x" size="20" /> */}</div>
      </div>
      <div className="proposal-add-questions-add-section-container">
        <AddSection
          USER_CAN_EDIT={USER_CAN_EDIT}
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
          sectionIndex={sectionIndex}
          handleSectionTitleClicked={handleSectionTitleClicked}
          handleDeleteQuestionClicked={handleDeleteQuestionClicked}
        />
      </div>
      {props?.companyErrors?.Proposal_Question ? (
        <span class="input-field-proposal-no-question-error">
          <p>{props?.companyErrors?.Proposal_Question}</p>
        </span>
      ) : null}
      <AddQuestion
        USER_CAN_EDIT={USER_CAN_EDIT}
        sectionIndex={sectionIndex}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
      />
    </div>
  );
};

export default ProposalQuestions;
