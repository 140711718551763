import React, { useState } from "react";
import DropdownFilter from "../dropdownFilter";
import DropdownContent from "./dropdownContent";
import * as DASHBOARD_KEYS from "../../../../../config/config";
import FeatherIcon from "feather-icons-react";
const ShowFilter = (props) => {
  const data = props?.data;
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const FILTER_KEYS = DASHBOARD_CONFIG_KEYS?.FILTERS;
  const label = props?.label;
  const [selectedDropdown, setSelectedDropdown] = useState(false);
  const openSelectedDropdownClicked = () => {
    setSelectedDropdown(!selectedDropdown);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => {
        setSelectedDropdown(false);
      }, 200);
    }
  };
  const handleFilterSelected = (value) => {
    props?.handleFilterSelected(value);
    setTimeout(() => {
      setSelectedDropdown(false);
    }, 200);
  };
  return (
    <div
      tabIndex={0}
      onBlur={handleBlur}
      className="dashboard-filter-card-wrapper p-r"
    >
      <div
        onClick={openSelectedDropdownClicked}
        className="dashboard-filter-label-wrapper"
      >
        {/* {FILTER_KEYS?.LABEL} */}
        <h5 className="dashboard-filter-label-text">{label}</h5>
        <FeatherIcon
          icon={selectedDropdown ? "chevron-down" : "chevron-right"}
          size="20"
          role="button"
          // className="blurb-detail-social"
          color={"#39383a"}
          // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
        />
      </div>
      {selectedDropdown ? (
        <div className="dashboard-filter-filter-options-wrapper  modern-card modern-card--elevated">
          <DropdownContent
            handleFilterSelected={handleFilterSelected}
            data={data}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ShowFilter;
