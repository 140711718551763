import React from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../../../../config/config";
const SearchHeader = () => {

  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  return (
    <>
      <h1>
        {KEYS?.SEARCH_HEADER.TAG_LINE_START}{" "}
        <span style={{ color: "#FFB527" }}>
          {KEYS?.SEARCH_HEADER.ZERO_MARKETING}
        </span>{" "}
        {KEYS?.SEARCH_HEADER.TAG_LINE_END}
      </h1>

      <div className="subheader">{KEYS?.SEARCH_HEADER.SUB_HEADER}</div>
    </>
  );
};
export default SearchHeader;
