import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import InputFieldQuestionTypeList from "./inputfFieldQuestionTypeList";

const AddQuestionTypeList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const addType = props?.addType;
  const proposalQuestionFormError = props?.proposalQuestionFormError;
  //   const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  //   const [fields, setFields] = useState(addType == "section" ? {} : []);

  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const PROPOSAL_ADD_QUESTION = KEYS?.PROPOSAL_ADD_QUESTION;
  const fields = props?.fields;
  const data = addType == "section" ? props?.data : props?.data;
  //   useEffect(() => {
  //     setFields(props?.data);
  //   }, [data]);
  return (
    <div style={{ maxHeight: "400px", overflow: "auto", overflowX: "hidden" }}>
      <div className="add-question-type-list-sub-header-wrapper">
        <p className="add-question-type-list-sub-header-text p-x-sm">
          Enter the choices below
        </p>
      </div>
      {fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
        ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.map(
            (list, index) => {
              return (
                <div className="add-question-type-main-container m-b-md m-x-sm">
                  <input
                    type={props?.inputType ? props?.inputType : "checkbox"}
                    disabled
                    className="checkbox-input-style-proposal-add-question-type-list p-x-base"
                    checked={false}
                  ></input>
                  <div
                    class="field p-r m-x-sm flex-question-input-field"
                    id="field-user__email"
                  >
                    <InputFieldQuestionTypeList
                      label={PROPOSAL_ADD_QUESTION?.TEXT_BOX}
                      value={list?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]}
                      removeBottomPadding={true}
                      changeText={(val) =>
                        props?.questionTypeList(
                          {
                            ...list,
                            [ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]:
                              val?.length == 1 ? val?.trim() : val,
                            [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: null,
                          },
                          index
                        )
                      }
                      error={
                        proposalQuestionFormError?.index == index
                          ? proposalQuestionFormError[
                              ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS
                            ]
                          : null
                      }
                    />
                  </div>
                  <div
                    onClick={() => props?.handleDeleteQuestionOptions(index)}
                    className="dis-f-r cursor--pointer"
                    style={{
                      borderRadius: "5px",
                      border: "1px solid #130F26",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "5px 5px",
                    }}
                  >
                    <FeatherIcon icon="trash" size="12" color="#130F26" />
                  </div>
                </div>
              );
            }
          )
        : null}

      <div
        onClick={props?.handleAddOptionsClicked}
        className="add-question-type-list-add-options-wrapper cursor--pointer"
      >
        <p className="add-question-type-list-add-question-text p-x-sm">
          + Add Options
        </p>
        {proposalQuestionFormError[
          ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA
        ] ? (
          <span class="input-field-question-options-matadata-length-error">
            {
              proposalQuestionFormError[
                ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA
              ]
            }
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default AddQuestionTypeList;
