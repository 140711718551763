import React from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../../../../config/config";
const ReferenceSection = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const MATRIX = useSelector((state) => state?.authDetails?.octateMatrix);
  return MATRIX?.length ? (
    <div className="references-section">
      <p>{KEYS?.REFERENCE_SECTION.TITLE}</p>
      <div className="flex space-between">
        {/* <img
        alt="Logo of Google"
        className=" ls-is-cached lazyloaded"
        data-src={
          require("../../../../../assets/images/google-logo.svg").default
        }
        src={require("../../../../../assets/images/google-logo.svg").default}
      />
      <img
        alt="Logo of Amazon"
        className=" lazyloaded"
        data-src={
          require("../../../../../assets/images/amazon-logo.svg").default
        }
        src={require("../../../../../assets/images/amazon-logo.svg").default}
      />
      <img
        alt="Logo of SAP"
        className=" lazyloaded"
        data-src={
          require("../../../../../assets/images/sap-logo.svg").default
        }
        src={require("../../../../../assets/images/sap-logo.svg").default}
      />
      <img
        alt="Logo of Boston Consulting Group"
        className="tablet-hidden lazyloaded"
        data-src={
          require("../../../../../assets/images/bcg-logo.svg").default
        }
        src={require("../../../../../assets/images/bcg-logo.svg").default}
      />
      <img
        alt="Logo of Paypal"
        className="tablet-hidden lazyloaded"
        data-src={
          require("../../../../../assets/images/paypal-logo.svg").default
        }
        src={require("../../../../../assets/images/paypal-logo.svg").default}
      />
      <img
        alt="Logo of Pepsico"
        className="tablet-hidden lazyloaded"
        data-src={
          require("../../../../../assets/images/pepsico-logo.svg").default
        }
        src={require("../../../../../assets/images/pepsico-logo.svg").default}
      /> */}
        {MATRIX?.map((list, index) => {
          return (
            <div
              style={{ textAlign: "center" }}
              className={`${
                index > 2 ? "tablet-hidden lazyloaded" : "lazyloaded"
              }`}
            >
              <div className="reference-section-content-number">
                {list?.Count}
              </div>
              <div className="reference-section-content">{list?.Label}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
export default ReferenceSection;
