import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import Dropdown from "../../../utlis/hoc/dropdown/dropdown";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";

const SearchProductCategory = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [searchProductCategoryText, setSearchProductCategoryText] = useState("a");
  const productCategoryId = props?.productBasicDetails?.Selected_Product_Category?.id;
  // path: `/getAllTopicForProductCategory?Product_Category_Id=${selectedProductCategory?.Tag_Id}/&searchText=${tagsText?.Name}`,
  // service: KEYS?.CONFIG?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
  // userInfo: {deviceId: DEVICE_INFO, userId: USER_ID},
  const productCategoryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchProductCategoryText}&type=productCategory`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const productTopicsDropdownData = useEnCryptGetApi(
    `/getAllTopicForProductCategory?Product_Category_Id=${productCategoryId}/&searchText=${""}`,
    KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const AssociatedTopic = useEnCryptPostApi(
    "/getAssociatedTagsForBlurb",
    KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
    usePostRequestMutation
  );
  const selectedTopicValue = props?.productBasicDetails?.tags?.length
    ? props?.productBasicDetails?.tags?.filter((val) => val?.Level === 4)
    : [];
  // const selectedVerticalAndProductCategory = props?.productBasicDetails?.tags
  //   ?.length
  //   ? props?.productBasicDetails?.tags?.filter((val) => val?.Level !== 4)
  //   : [];
  const concatIsSelectedCompatible = concatIsSelectedAllData(selectedTopicValue, productTopicsDropdownData, "Tag_Id");
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      tags: val,
    });
  };
  const handleSearchText = (val) => {
    setSearchProductCategoryText(val ? val : "a");
  };
  const handleSearchWithApi = (key) => {
    setSearchProductCategoryText(key ? key : "a");
  };
  const handleDetailsProductCategory = (val) => {
    props?.handleDetails({
      ...props?.productBasicDetails,
      Selected_Product_Category: val,
      tags: [],
    });
  };

  useEffect(() => {
    if (productCategoryId) {
      var RAW_DATA = {
        title_level_1: props?.productBasicDetails?.title_level_1,
        description: props?.productBasicDetails?.description,
        vendorID: props?.productBasicDetails?.vendorID,
        Selected_Product_Category: props?.productBasicDetails?.Selected_Product_Category,
        totalTagLimit: 3,
      };
      AssociatedTopic?.handleTrigger(RAW_DATA);
    }
  }, [props?.productBasicDetails?.Selected_Product_Category]);

  return (
    <>
      <div class="field-company-profile p-r m-y-md" id="field-user__country_id">
        <SingleSelectNewDropdown
          handleSelectedData={(val) => handleDetailsProductCategory(val)}
          data={productCategoryDropdownData}
          placeholder={"Select product category"}
          // flag={true}
          selectedVal={props?.productBasicDetails?.Selected_Product_Category}
          name={"name"}
          // rightSideContent={true}
          // rightName={"Journey_Stage"}
          returnObject={true}
          label={"Product Category"}
          handleSearchWithApi={handleSearchWithApi}
          searchWithApi={true}
          type={"ProductCategory"}
        />
        {/* {error?.Content_Type ? (
          <span class="input-field-dropdown-error">{error?.Content_Type}</span>
        ) : null} */}
      </div>
      {productCategoryId && props?.cardType == "add-blurbs" ? (
        <div className="m-y-md p-r">
          {props?.productBasicDetails?.tags?.length ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {"Topics"}
            </label>
          ) : null}
          <Dropdown
            data={concatIsSelectedCompatible}
            // handleApplyClicked={handleApplyCountryClicked}
            label={"Select topics "}
            handleSelectedDataOnChange={handleSelectedDataOnChange}
            selectedValue={selectedTopicValue}
            hideSaveAndClear={true}
            // searchWithApi={true}
            // handleSearchText={handleSearchText}
            name={"Name"}
            Id={"Tag_Id"}
          />
        </div>
      ) : null}
    </>
  );
};

export default SearchProductCategory;
