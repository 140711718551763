import React from "react";
import ProductInfo from "../../../../../utlis/hoc/newProductCard/components/productInfo";
import { useSelector } from "react-redux";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import {
  useAddBookmarkMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../../../../adapters/xhr/generalApi";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useParams } from "react-router";
import { GetIdFormLink } from "../../../../../utlis/hof/hof";
import SignInModal from "../../../../../utlis/hoc/signInModal/signInModal";
import ShareIcon from "../../../../../utlis/hoc/engagementBar/components/shareIcon";
import VendorShareIcon from "./vendorShareIcon";

const VendorEngagement = ({ data }) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const USER_ID = authData?.data?.userId;
  const [EngagementBarData, setEngagementBarData] = useState({ ...data });
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const TriggerLikeBlurb = useEnCryptPostApi(
    `/likeManagement`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostRequestMutation
  );

  const TriggerBookmark = useEnCryptPostApi(
    `/addOrRemoveBookmark`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostRequestMutation
  );

  const handleLikePressed = (val) => {
    if (authData?.data?.Permissions?.Like) {
      setEngagementBarData((prev) => ({
        ...prev,

        [KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]
          ? false
          : true,
        [KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED]
          ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
            ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT] - 1
            : 0
          : EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT]
          ? EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKE_COUNT] + 1
          : 1,
      }));
      const rawData = {
        Action_Type: EngagementBarData?.[KEYS?.END_POINTS?.FEED?.MAPPINGS?.LIKED] ? "dislike" : "Liked",
        Item_Type: "vendor",
        Item_Id: EngagementBarData?.Vendor_ID,
        User_Id: USER_ID,
      };
      TriggerLikeBlurb?.handleTrigger(rawData);
    } else {
      setSignInModalVisible((prev) => !prev);
    }
  };

  function handleBookmarkPressed() {
    // if (authData?.data?.Permissions?.Pin) {
    const rawData = {
      Item_ID: EngagementBarData?.Vendor_ID,
      Created_By: USER_ID,
      Item_Type: "vendor",
      Action_Type: EngagementBarData?.Book_Marked ? "Remove" : "Add",
    };
    TriggerBookmark.handleTrigger(rawData);
    setEngagementBarData((prev) => ({
      ...prev,
      Book_Marked: !prev?.Book_Marked,
    }));
    // } else {
    //   setSignInModalVisible((prev) => !prev);
    // }
  }
  return (
    <>
      <div className="engagement-bar-container" style={{ width: "150px", alignSelf: "flex-start" }}>
        <div className="engagement-bar-items" role="button" data-tooltip={"Total Boards"} data-inverted="true">
          <FeatherIcon icon="folder" size={13} color={"#5570f1"} />
          <p className="vendor-engagement-bar-items-count">{EngagementBarData?.Board_Count}</p>
        </div>
        <div className="engagement-bar-items" role="button" data-tooltip={"Bookmark"} data-inverted="true">
          <FeatherIcon
            icon="bookmark"
            size={13}
            color={EngagementBarData?.Book_Marked ? "#5570f1" : "#5570f1"}
            onClick={() => handleBookmarkPressed()}
            fill={EngagementBarData?.Book_Marked ? "#5570f1" : "none"}
          />
        </div>
        <VendorShareIcon
          shareLink={`/vendor/${PARAMS?.name}${PARAMS?.tab ? `/${PARAMS?.tab}` : ""}`}
          cardType={"vendor"}
          data={data}
          page={"vendor-page"}
        />
      </div>
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible(false)} /> : null}
    </>
  );
};

export default VendorEngagement;
