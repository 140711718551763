import React from "react";

import Card from "./component/card";
import Content from "./component/content";
// import ProductHighlights from "../../../components/providers/component/providerProducts/component/productHighlights";
import RelatedProductsButton from "./component/relatedProductsButton";
import Title from "./component/title";
import VendorDetails from "./component/vendorDetails";
import ActionButtons from "./component/actionButtons";
import ProductHighlights from "./component/productHighlights";
import ProductTopics from "../productCard/productTopics";
import EngagementBar from "../engagementBar/engagementBar";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import ProductFirmographics from "../newProductCard/components/productFirmographics";
import SmallCardFirmographics from "./component/smallCardFirmographics";
import { isMobile } from "react-device-detect";
function ProductsCards(props) {
  const signalTrackingProductsObject = {
    Action: "view product clicked",
    page: "product page",
    section: props?.section,
    context: props?.allProducts?.Product_Name,
  };
  const signalTrackingBlurbsObject = {
    Action: "view blurb clicked",
    page: "blurb page-product",
    section: props?.section,
    context: props?.allProducts?.Product_Name,
  };
  const signalTrackingVendorObject = {
    Action: "vendor title clicked",
    page: "vendor page",
    section: props?.section,
    context: props?.allProducts?.Vendor_Name,
  };
  return (
    <Card page={props?.page} allProducts={props.allProducts} parent={props?.parent} type={props?.type}>
      <div
        className={
          props?.type == "RelatedProducts"
            ? "vertical-product-card__header"
            : "vertical-product-card-for-provider__header"
        }
      >
        <div
          className={
            props?.type == "RelatedProducts"
              ? "vertical-product-card__title"
              : "vertical-product-card-for-provider__title"
          }
        >
          <Title
            page={props?.page}
            signalTrackingProductsObject={signalTrackingProductsObject}
            allProducts={props.allProducts}
            type={props?.type}
          />
          {props?.type == "RelatedProducts" ? (
            <VendorDetails
              page={props?.page}
              signalTrackingVendorObject={signalTrackingVendorObject}
              allProducts={props.allProducts}
              type={props?.type}
            />
          ) : null}
        </div>
      </div>
      {props?.type == "RelatedProducts" ? (
        <>
          {/* <ProductHighlights page={props?.page} allProducts={props.allProducts} type={props?.type} /> */}
          <Content page={props?.page} allProducts={props.allProducts} type={props?.type} />
          <div className="m-b-xs">
            <ProductTopics page={props?.page} Data={props.allProducts} />
          </div>
        </>
      ) : (
        <>
          <Content page={props?.page} allProducts={props.allProducts} type={props?.type} />
          <ProductHighlights page={props?.page} allProducts={props.allProducts} type={props?.type} />
        </>
      )}
      {/* <ProductFirmographics data={props?.allProducts} type={props?.type} page={props?.page} /> */}
      {props?.type == "RelatedProducts" ? (
        <RelatedProductsButton
          page={props?.page}
          signalTrackingProductsObject={signalTrackingProductsObject}
          allProducts={props.allProducts}
        />
      ) : (
        <ActionButtons
          page={props?.page}
          signalTrackingProductsObject={signalTrackingProductsObject}
          signalTrackingBlurbsObject={signalTrackingBlurbsObject}
          allProducts={props.allProducts}
          type={props?.type}
        />
      )}
      <div className="m-t-mini" style={{ width: isMobile ? "90%" : "50%" }}>
        <EngagementBar
          shareLink={`/${ReplaceBlankSpaceByHyphen(
            props?.allProducts?.Vendor_Name
          )}/product/${ReplaceBlankSpaceByHyphen(
            props?.allProducts?.Product_Name,
            props?.allProducts?.Product_ID
          )}/blurbs`}
          cardType={"product"}
          data={props?.allProducts}
          page={props?.page}
          keyName={"Product_ID"}
          engageSource={"product engagement from small card"}
        />
      </div>
    </Card>
  );
}

export default ProductsCards;
