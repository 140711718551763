import React, { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import CompleteStep from "./completeStep";
import CreateNewVendorPortal from "./createNewVendorPortal";

const CompleteProfile = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [showModal, setShowModal] = useState(false);
  const COMPLETE_PROFILE = KEYS?.JOIN_ORGANIZATION;
  const percentage = 18;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSkipPressed = () => {
    navigate("/");
    dispatch(tempAuthDetails({}));
  };
  const handleContinuePressed = () => {
    // setShowModal(true);
    navigate("/");
  };
  return (
    <div class="splitted-page__content">
      {showModal ? (
        <CreateNewVendorPortal handleClose={() => setShowModal(false)} />
      ) : null}
      <div class="splitted-page__container">
        <div class="m-y-auto">
          <CompleteStep name={COMPLETE_PROFILE?.EMAIL_VERIFICATION} />
          <CompleteStep name={COMPLETE_PROFILE?.ORGANIZATION_STEP_COMPLETE} />
          <div className="grid-max-auto">
            <div>
              <div type="checkbox" className="otp-verification-checkbox" />
            </div>
            <div className="vendor-onboard-container">
              <h1 className="otp-verification-title m-b-md">
                {COMPLETE_PROFILE?.COMPLETE_PROFILE}
              </h1>
              <div className="flex m-y-md">
                <div style={{ width: "60%", height: "100%" }}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth={10}
                    styles={{
                      trail: {
                        stroke: "#F4F5FA",
                        strokeLinecap: "butt",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      path: {
                        stroke: `#FFCC91`,
                        strokeLinecap: "butt",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        transformOrigin: "center center",
                      },
                      background: {
                        fill: "#FFF",
                      },
                    }}
                  />
                </div>
                <div className="m-x-mini">
                  <p className="profile-complete-text">
                    {COMPLETE_PROFILE?.COMPLETION_START} {percentage}%{" "}
                    {COMPLETE_PROFILE?.COMPLETION_END}
                  </p>
                  <p className="profile-complete-sub-text m-b-sm">
                    {COMPLETE_PROFILE?.COMPLETION_TEXT}
                  </p>
                </div>
              </div>

              <p className="otp-verification-resend-text m-b-md">
                {COMPLETE_PROFILE?.SKIP_MSG}
              </p>
              <div className="flex space-between">
                <div
                  className="create-account-button"
                  role={"button"}
                  onClick={() => handleContinuePressed()}
                >
                  {COMPLETE_PROFILE?.CONTINUE}
                </div>
                {/* <div
                  className="skip-button"
                  role={"button"}
                  onClick={() => handleSkipPressed()}
                >
                  {COMPLETE_PROFILE?.SKIP_TEXT}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
