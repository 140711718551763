import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { memo } from "react";

const CommentIcon = (props) => {
  const [commentData, setCommentData] = useState(props?.data);

  useEffect(() => {
    if (props?.commentCount)
      setCommentData((prev) => ({
        ...prev,
        Comment_Count: prev?.Comment_Count ? prev?.Comment_Count + 1 : 1,
      }));
  }, [props?.commentCount]);
  return (
    <>
      <div
        className="engagement-bar-items"
        role="button"
        onClick={() => props?.handleViewComments()}
        data-tooltip={"Comment"}
        data-inverted="true"
      >
        <FeatherIcon icon="message-square" size={15} color={"#323232"} />
        {/* <p className="engagement-bar-items-count" style={{ color: "#606060" }}>
          {Math.floor(Math.random() * 50) + 1}
        </p> */}
        <p className="engagement-bar-items-count">{commentData?.Comment_Count ? commentData?.Comment_Count : null}</p>
      </div>
    </>
  );
};

export default memo(CommentIcon);
