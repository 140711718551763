import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateMetaTags } from "../../../src/components/SEO/seo";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import StickyTab from "../../utlis/hoc/tabNavigator/stickyTab";
import { GetIdFormLink } from "../../utlis/hof/hof";
import BlurbsTab from "../ProductCategories/components/tabs/blurbsTab";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import BlurbsTabTopic from "./component/blurbTab";
import TopicHeader from "./component/topicHeader";
import UseCaseTabTopic from "./component/useCaseTab";

const TopicPage = (props) => {
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const scrollPosition = useScrollPosition();
  const TYPE = props?.TYPE;
  const CATEGORY = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  useEffect(() => {
    const seoBody = {
      type: "topic",
      id: CATEGORY?.Id,
      tab: tabIndex,
    };
    updateMetaTags(seoBody);
  }, []);

  return (
    <>
      <Header show={true} />
      <div className="attached-navbar m-b-md">
        {/* <div className="fixed-nav-container flex space-between align-items-bottom">
          <SearchHeaderBreadCrumb
            page={props?.page}
            parent={"topicPage"}
            type={tabIndex}
            params={CATEGORY?.Name}
          />
        </div> */}
      </div>
      <div className="business-profile-landing-page-container ui container">
        <TopicHeader tabData={KEYS?.TOPIC_TAB} tabIndex={tabIndex} page={props?.page} />
        <StickyTab
          tabIndex={tabIndex}
          tabData={KEYS?.TOPIC_TAB}
          scrollPosition={scrollPosition}
          navigatePath={"topic"}
          page={props?.page}
        />
        {tabIndex == "blurbs" ? (
          <BlurbsTabTopic page={props?.page} />
        ) : tabIndex == "use-cases" ? (
          <UseCaseTabTopic page={props?.page} />
        ) : (
          <BlurbsTab page={props?.page} />
        )}
      </div>
      <Footer page={props?.page} />
    </>
  );
};

export default TopicPage;
