import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductSkeleton = (props) => {
  const SKELETON_LENGTH = props?.loadingLength ?? 10;
  return (
    <SkeletonTheme>
      {Array(SKELETON_LENGTH)
        .fill()
        .map((item, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>
            <div style={{ width: "25%", alignSelf: "center", height: 140 }}>
              <Skeleton height={"98%"} width={"85%"} style={{ alignSelf: "center" }} />
            </div>
            <div style={{ width: "50%", marginLeft: 5 }}>
              <Skeleton width={"80%"} height={20} />
              <Skeleton height={15} width={"50%"} />
              <p style={{ marginTop: 10 }}>
                <Skeleton width={"95%"} count={4} />
              </p>
            </div>
            <div style={{ width: "25%" }}>
              <div>
                <Skeleton width={`80%`} count={2} />
              </div>
              <div style={{ marginTop: "7%" }}>
                <Skeleton height={35} width={"90%"} count={2} />
              </div>
            </div>
          </div>
        ))}
    </SkeletonTheme>
  );
};

export default ProductSkeleton;
