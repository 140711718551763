import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePutPutUpdateUserProfileMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import InputFieldProfile from "./inputFieldProfile";
import PasswordField from "../../../auth/signUp/onBoard/components/passwordField";

const BasicDetails = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const userData = props?.data;
  // const [error, setError] = useState(null);
  const error = props?.error;
  const UpdateProfilePostApi = useEnCryptPostApi(
    `/updateUserProfile`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePutPutUpdateUserProfileMutation
  );
  const EDIT_PROFILE = KEYS?.EDIT_PROFILE;
  const GetCountryCode = useEnCryptGetApi(
    `/getCountryDialingCode`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const CountryCode = GetCountryCode?.data?.data;
  const GetCountryData = useEnCryptGetApi(
    `/s3Processor/getCountryList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const DropDownData = GetCountryData?.data?.data?.allCountries;
  useEffect(() => {
    if (UpdateProfilePostApi?.data?.status) {
      props?.handleSuccessMessage(true);
      setTimeout(() => {
        props?.handleSuccessMessage(false);
      }, 2000);
    }
  }, [UpdateProfilePostApi?.data]);
  return (
    <div className="m-t-xs">
      <div className="portal-section__content">
        <div className="field" id="field-user__email">
          <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label>
          <InputFieldProfile
            label={PROFILE_DETAILS?.FIRST_NAME_LABEL}
            value={userData?.firstName}
            changeText={(val) => props?.handleBasicDetails({ ...userData, firstName: val })}
            error={error?.firstName}
            removeBottomPadding={true}
          />
        </div>

        <div className="field" id="field-user__email">
          <label for="user__email">{PROFILE_DETAILS?.LAST_NAME}</label>
          <InputFieldProfile
            label={PROFILE_DETAILS?.LAST_NAME_LABEL}
            value={userData?.lastName}
            changeText={(val) => props?.handleBasicDetails({ ...userData, lastName: val })}
            error={error?.lastName}
            removeBottomPadding={true}
          />
        </div>
        <div className="field" id="field-user__email">
          <label>{"Linkedin Profile Link"}</label>
          <InputFieldProfile
            label={"Linkedin Profile Link"}
            value={userData?.linkedInProfileUrl}
            changeText={(val) =>
              props?.handleBasicDetails({
                ...userData,
                linkedInProfileUrl: val,
              })
            }
            icon={"linkedin"}
            error={error?.linkedInProfileUrl}
            removeBottomPadding={true}
          />
        </div>

        <div className="field disabled" id="field-user__email">
          <label for="user__email">{PROFILE_DETAILS?.WORK_EMAIL}</label>
          <InputFieldProfile
            label={PROFILE_DETAILS?.WORK_EMAIL}
            value={userData?.emailId}
            changeText={(val) =>
              props?.handleBasicDetails({
                ...userData,
                emailId: val,
              })
            }
            icon={"mail"}
            error={error?.emailId}
            removeBottomPadding={true}
            disable={false}
          />
        </div>
        {authData?.data?.userExist ? null : (
          <div className="field disabled m-b-lg" id="field-user__email">
            <label for="user__email">{"Password"}</label>
            <PasswordField
              label={"Password"}
              value={userData?.password}
              changeText={(val) =>
                props?.handleBasicDetails({
                  ...userData,
                  password: val,
                })
              }
              icon={"lock"}
              error={error?.password}
              removeBottomPadding={true}
              disable={false}
            />
          </div>
        )}
        <div className="field">
          <div className="field m-b-none">
            <label>
              {PROFILE_DETAILS?.WORK_PHONE_NUMBER}
              <span className="field-hint">{EDIT_PROFILE?.OPTIONAL}</span>
            </label>
          </div>

          <div className="grid-1-10">
            <SingleSelectNewDropdown
              handleSelectedData={(val) => props?.handleBasicDetails({ ...userData, phoneCode: val })}
              placeholder="+1"
              data={CountryCode}
              selectedVal={userData?.phoneCode}
            />
            <InputFieldProfile
              label={PROFILE_DETAILS?.WORK_PHONE_NUMBER_LABEL}
              value={userData?.phoneNumber}
              changeText={(val) => props?.handleBasicDetails({ ...userData, phoneNumber: val })}
              error={error?.phoneNumber}
              icon={"smartphone"}
              type={"tel"}
              removeBottomPadding={true}
            />
          </div>
        </div>
        <div className="field" id="field-user__country_id">
          <label for="user__country_id">{PROFILE_DETAILS?.COUNTRY}</label>

          <SingleSelectNewDropdown
            handleSelectedData={(val) => props?.handleBasicDetails({ ...userData, country: val })}
            data={DropDownData}
            placeholder={PROFILE_DETAILS?.COUNTRY_LABEL}
            flag={true}
            selectedVal={userData?.country}
          />
        </div>
        {/* <div className="grid-form grid-form--2-equal-cols">
          <div className="field" id="field-user__country_id">
            <label for="user__country_id">{PROFILE_DETAILS?.COUNTRY}</label>

            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleBasicDetails({ ...userData, country: val })
              }
              data={DropDownData}
              placeholder={PROFILE_DETAILS?.COUNTRY_LABEL}
              flag={true}
              selectedVal={userData?.country}
            />
          </div>
          <div className="field" id="field-user__country_id">
            <label for="user__country_id">{PROFILE_DETAILS?.STATE}</label>

            <SingleSelectNewDropdown
              handleSelectedData={(val) =>
                props?.handleBasicDetails({ ...userData, country: val })
              }
              data={DropDownData}
              placeholder={PROFILE_DETAILS?.STATE_LABEL}
              flag={true}
              selectedVal={userData?.country}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BasicDetails;
