import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CommentsSkeleton = () => {
  return (
    <SkeletonTheme>
      {Array(7)
        .fill()
        .map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}
          >
            <div
              style={{
                width: "75px",
                alignSelf: "center",
                height: "50px",
                borderRadius: "5px",
              }}
            >
              <Skeleton
                height={"98%"}
                width={"85%"}
                style={{ alignSelf: "center" }}
              />
            </div>
            <div style={{ width: "85%", marginLeft: 5 }}>
              <Skeleton width={"80%"} height={20} />
              <Skeleton height={15} width={"50%"} />
            </div>
          </div>
        ))}
    </SkeletonTheme>
  );
};

export default CommentsSkeleton;
