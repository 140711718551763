import React from "react";
import ProviderCardInfo from "./provideCardInfo";

const ProviderCardFirmo = (props) => {
  const VENDOR_DATA = props?.data;
  const PAGE_SOURCE = props?.pageType;

  return (
    <div
      div
      className={`${
        PAGE_SOURCE === "vendor page" || "product page-customers" ? "vendor-firmo-table-view" : "grid-four-columns-auto"
      } more-information-container m-t-auto`}
    >
      <ProviderCardInfo
        data={VENDOR_DATA}
        icon={"users"}
        navigate={"blurbs"}
        name="Employees"
        keyName={"Employee_Range"}
        page={props?.page}
        section={props?.section}
      />
      <ProviderCardInfo
        data={VENDOR_DATA}
        icon={"tag"}
        name="Products"
        navigate={"products"}
        keyName="Products"
        page={props?.page}
        section={props?.section}
        length={true}
      />
      <ProviderCardInfo
        data={VENDOR_DATA}
        icon={"pie-chart"}
        navigate={"blurbs"}
        name="Revenue"
        keyName={"Revenue_Range"}
        page={props?.page}
        section={props?.section}
      />
      <ProviderCardInfo
        data={VENDOR_DATA}
        icon={"pie-chart"}
        navigate={"blurbs"}
        name="Founded"
        keyName={"Founded"}
        page={props?.page}
        section={props?.section}
      />
    </div>
  );
};

export default ProviderCardFirmo;
