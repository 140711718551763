import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import ProposalBasicDetails from "../../../addProposal/component/proposalBasicDetails";
import { validateAddProposalPage1 } from "../../../../utlis/hof/hof";
const EditProposalBasicDetails = (props) => {
  const [basicDetails, setBasicDetails] = useState({});
  const [companyErrors, setCompanyErrors] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const proposalDetails = props?.proposalDetails;
  const handleDetails = (val) => {
    setBasicDetails(val);
  };
  useEffect(() => {
    setBasicDetails(proposalDetails);
  }, [proposalDetails]);
  const handleSaveClicked = (val) => {
    const Validate1 = validateAddProposalPage1(basicDetails);
    setCompanyErrors(Validate1);
    setTimeout(() => {
      setCompanyErrors({});
    }, 3000);
    if (!Object.keys(Validate1)?.length) {
      props.handleDetails(basicDetails);
      props?.handleClose();
    }
  };
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        style={{ background: "rgba(232, 230, 230, 0.6)" }}
      >
        <div
          style={{
            width: "60%",
            height: "70%",
            background: "#fff",
            borderRadius: "10px",
          }}
        >
          <div style={{ overflow: "auto", height: "100%", width: "100%" }}>
            <ProposalBasicDetails
              //   validateEmailId={props?.validateEmailId}
              companyErrors={companyErrors}
              handleDetails={handleDetails}
              proposalDetails={basicDetails}
            />
          </div>

          <div className="company-details-footer-wrapper">
            <div className="footer-detail-list-wrapper"></div>
            <div className="flex footer-detail-list-action-button-main-wrapper">
              <div
                onClick={() => handleSaveClicked()}
                className="company-details-footer-next m-x-mini"
              >
                Save
              </div>
              <div
                onClick={() => props?.handleClose()}
                className="company-details-footer-back"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default EditProposalBasicDetails;
