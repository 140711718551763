import React, { useEffect } from "react";
import ProviderTab from "./component/providerTab/providerTab";
import Sidenavbar from "./component/sidenavbar";
import SubCategoryPage from "./component/subCategoryPage";
import SearchResultFilter from "../../../searchResult/components/filter/searchResultFilter";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useDispatch, useSelector } from "react-redux";
import { selectedTopic } from "../../../../contexts/reducer/homeReducer";

const Products = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const TYPE = props.type;
  const VendorName = props?.vendorName;
  const dispatch = useDispatch();

  const SearchResultData = useEnCryptPostApi(
    `/getProductSearchResult`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    const rawData = {
      searchText: VendorName,
      page: 1,
      type: TYPE,
      filters: [],
    };
    SearchResultData?.handleTrigger(rawData);
  }, [VendorName]);

  const handleTopicPressed = (val) => {
    dispatch(selectedTopic({ ...val, isSelected: true }));
  };
  return (
    <div className="ui container">
      <div className="grid-3-1 tablet-landscape-1-col grid-col-gap-md">
        {TYPE == "vendors" ? (
          <div>
            <ProviderTab
              page={props?.page}
              categoryId={props?.categoryId}
              vendorName={props?.vendorName}
            />
          </div>
        ) : (
          <div>
            <SubCategoryPage
              page={props?.page}
              categoryId={props?.categoryId}
              vendorName={props?.vendorName}
              handleScrollOnClick={props?.handleScrollOnClick}
              type={"categoryPage"}
              handleTopicPressed={handleTopicPressed}
            />
          </div>
        )}
        <div>
          {/* <Sidenavbar type={props.type} /> */}
          <SearchResultFilter
            page={props?.page}
            data={SearchResultData?.data?.filters}
            // handleFilterData={handleFilterData}
            // handleRemoveFilterData={handleRemoveFilterData}
            // handleApplyChanges={handleApplyChanges}
          />
        </div>
      </div>
    </div>
  );
};

export default Products;
