import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserPermissionQuery, useGetUserPreferencesQuery } from "../../../../adapters/xhr/crudServiceApi";
import { selectedUserFn } from "../../../../contexts/reducer/chatReducer";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import PreferencesList from "../../../managePreference/components/preferencesList";
import PreferencesSelection from "../../../managePreference/components/preferencesSelection";
import PermissionContent from "../permission/permissionContent";
import PermissionSets from "../permission/permissionSets";
import AllChatList from "../vendorChat/allChatList/allChatList";
import SelectedChat from "../vendorChat/selectedChat/selectedChat";

function VendorChatTab(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  const dispatch = useDispatch();
  const [featureActiveList, setFeatureActiveList] = useState(1);
  const [activeConversionId, setActiveConversionId] = useState(null);
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const permissionsDetails = props?.permissionsDetails;
  const VENDOR_ID = props?.VENDOR_ID;
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedUser);
  const GetAllVendorChatList = useEnCryptGetApi(
    `/getVendorConversation?Page=1&Vendor_ID=${vendorId}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetUserPermissionQuery
  );
  function handleActiveConversionId(val) {
    setActiveConversionId(val);
  }
  const checkUserSelector =
    typeof selectedVendorSelector === "object" && Object.keys(selectedVendorSelector).length ? false : true;
  const VendorListData = GetAllVendorChatList.data?.data?.length ? GetAllVendorChatList.data?.data : [];
  useEffect(() => {
    if (VendorListData?.length && checkUserSelector) {
      dispatch(selectedUserFn(VendorListData[0]));
    }
  }, [VendorListData]);
  const handleFeatureList = (val) => {
    setFeatureActiveList(val);
  };
  function handleAddNewPermissionSet() {
    setFeatureActiveList((prev) => prev + 1);
  }
  useEffect(() => {
    if (chatSocket.connected) {
      chatSocket.on("VendorUpdatedList", function(data) {
        GetAllVendorChatList.refetch();
      });
    }
  }, [chatSocket]);

  return (
    <>
      {VendorListData?.length ? (
        <div className="vendor-chat-main-container-grid-1-3 tablet-1-col container">
          <AllChatList
            // handleActiveConversionId={handleActiveConversionId}
            VENDOR_ID={VENDOR_ID}
            handleAddNewPermissionSet={handleAddNewPermissionSet}
            // handleFeatureList={handleFeatureList}
            // featureActiveList={featureActiveList}
            // activeConversionId={activeConversionId}
            data={VendorListData}
          />
          <SelectedChat
            // activeConversionId={activeConversionId}
            VENDOR_ID={VENDOR_ID}
            // data={VendorListData}
            // featureActiveList={featureActiveList}
          />
        </div>
      ) : (
        <div className="direct-message-not-empty-chat-list-message-container">
          <h1 className="direct-message-not-empty-chat-list-message">
            You have not received direct messages from any buyer.
          </h1>
        </div>
      )}
    </>
  );
}

export default VendorChatTab;
