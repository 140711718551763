import React from "react";
import ProposalBasicDetails from "./proposalBasicDetails";
import ProposalQuestions from "./proposalQuestions/proposalQuestions";
import ProposalSubmit from "./proposalSubmit/proposalSubmit";
import ProposalTemplate from "./proposalTemplate/proposalTemplate";

const ProposalDetailList = (props) => {
  const screenToShow = props?.footerNumberClicked;
  const proposalDetails = props?.proposalDetails;
  const USER_CAN_EDIT = true;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {screenToShow == 1 ? (
        <ProposalTemplate
          USER_CAN_EDIT={USER_CAN_EDIT}
          //   validateEmailId={props?.validateEmailId}
          handleFooterNumberFromTemplateClicked={props?.handleFooterNumberFromTemplateClicked}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
        />
      ) : screenToShow == 2 ? (
        <ProposalBasicDetails
          USER_CAN_EDIT={USER_CAN_EDIT}
          //   validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
        />
      ) : screenToShow == 3 ? (
        <ProposalQuestions
          USER_CAN_EDIT={USER_CAN_EDIT}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
        />
      ) : (
        <ProposalSubmit
          USER_CAN_EDIT={USER_CAN_EDIT}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
          handleScreenToShow={props?.handleScreenToShow}
        />
      )}
    </div>
  );
};

export default ProposalDetailList;

{
  /* screenToShow == 2 ? (
        <AddFeature
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : (
        <PlanAndPricing
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) */
}
