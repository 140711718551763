import React from 'react';
import BoardRoundSpinner from '../../../../../../utlis/spinner/boardRoundSpinner';
import SummaryStatsCard from '../../../../../vendorDashboard/component/summaryStats.js/summaryStatsCard';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useEnCryptGetApi } from '../../../../../../utlis/customHooks/customHooks';
import { useGetRequestQuery } from '../../../../../../adapters/xhr/generalApi';

const LinkedInHighlights = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const User_ID = authData?.data?.userId;

  const ProductExpAnalytics = useEnCryptGetApi(
    `/getProductExpertTotalMetrics?userId=${User_ID}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );

  const ProductExpHighlights = ProductExpAnalytics?.data?.data?.data;
  const overViewStats = [
    { Count: ProductExpHighlights?.Total_Posts, Id: 1, Type: 'Total Posts' },
    {
      Id: 2,
      Type: 'Total Likes',
      Count: ProductExpHighlights?.Total_Likes,
    },
    {
      Id: 3,
      Type: 'Total Comments',
      Count: ProductExpHighlights?.Total_Comments,
    },
  ];
  const InfoData = 'Linked In analytics';
  return (
    <div
      className={`dashboard-summary-stats-list-main-wrapper p-r  modern-card ${
        ProductExpAnalytics?.isLoading || ProductExpAnalytics?.isFetching ? 'dashboard-card-is-loading' : ''
      }`}
    >
      {ProductExpAnalytics?.isLoading || ProductExpAnalytics?.isFetching ? (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <BoardRoundSpinner />
        </div>
      ) : null}
      <div
        style={{
          width: '100%',
          textAlign: 'start',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        className=' p-x-mini p-y-mini'
      >
        <h2 className='dashboard-summary-overview-title-header p-x-base '>Linkedin Analytics</h2>
        <div style={{ marginTop: '5px' }} data-inverted data-tooltip={InfoData}>
          <FeatherIcon icon={'info'} size='20' role='button' color={'#5570f1'} />
        </div>
      </div>

      {overViewStats?.map((list) => {
        return <SummaryStatsCard data={list} />;
      })}
    </div>
  );
};

export default LinkedInHighlights;
