import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetBoardsDataQuery, useGetRequestQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import BlurbCardLarge from "../../../../utlis/hoc/blurbCard/blurbCard";
import CardList from "../../../../utlis/hoc/productCard/cardList";
import ProviderCard from "../../../../utlis/hoc/providerCard/providerCard";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import SearchResultFilter from "../../../searchResult/components/filter/searchResultFilter";
import { MobilePaginatedItems } from "../../../searchResult/components/pagination/paginationMobileView";
import { PaginatedItems } from "../../../searchResult/components/pagination/paginationReact";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import GlobalFilter from "../../../productPageNew/components/tabs/globalFilter/globalFilter";
import _ from "lodash";
import ListCount from "../../../../utlis/hoc/productCard/listCount";
import SelectedFilterList from "../../../searchResult/components/filter/selectedFilterList";
import FilterMobileView from "../../../searchResult/components/filter/mobile/filterMobileView";
import { EncryptId, GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import NewBlurbCard from "../../../../utlis/hoc/newBlurbCard/newBlurbCard";
import * as NO_CONTENT from "../../../../config/config";

const BoardBlurbTab = (props) => {
  const [pageNo, setPageNo] = useState(1);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const VENDOR_NAME = PARAMS?.vendor;
  const productId = props?.productId;

  const ProductData = useEnCryptGetApi(
    `/getBookmarksOrUserLikes?User_ID=${USER_ID}&Item_Type=blurb&type=${PARAMS.type}`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    useGetBoardsDataQuery
  );

  const BLURB_DATA = ProductData?.data?.data;

  const HandlePageNumber = (val) => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setPageNo(val);
    }, 300);
  };

  const handleFilterData = (val) => {
    let newData = [...SelectedFilter];
    newData.push(val);
    setSelectedFilter(newData);
    HandlePageNumber(1);
    setRemountComponent(Math.random());
  };

  const handleRemoveFilterData = (val) => {
    HandlePageNumber(1);
    const NewData = SelectedFilter?.filter((item) => {
      return val?.name !== item.name;
    });
    setSelectedFilter(NewData);
    setRemountComponent(Math.random());
  };

  const handleApplyChanges = (val) => {
    if (val?.length > 0) {
      const data = _.uniqBy([...SelectedFilter, ...val], "name");
      setSelectedFilter(data);
    } else setSelectedFilter([]);
    setRemountComponent(Math.random());
  };

  const handleClearData = (val) => {
    if (SelectedFilter?.length) {
      setSelectedFilter([]);
      setRemountComponent(Math.random());
    }
  };

  const signalTrackingBlurbObject = {
    Action: `${KEYS?.PROVIDER_BLURBS.MORE} clicked`,
    page: "blurb page-product",
    section: "related blurbs",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewAllBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbObject?.Action,
      Target: {
        page: signalTrackingBlurbObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingBlurbObject?.section,
        context: productId?.Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div key={remountComponent}>
      {ProductData?.isLoading || ProductData?.isFetching ? (
        <ProductSkeleton page={props?.page} />
      ) : BLURB_DATA?.length ? (
        <NewBlurbCard data={BLURB_DATA} parent={props?.parent} page={props?.page} section={"board detail Blurbs"} />
      ) : (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">
            {PARAMS?.type === "bookmark"
              ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BOOKMARK_BOARD_BLURB
              : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.LIKE_BOARD_BLURB}
          </div>
        </div>
      )}
      {/* {ProductData?.data?.data?.length >= 6 ? (
        <a
          class="display-block m-t-sm text-center"
          onClick={() =>
            handleViewAllBlurbLinkClicked(
              `/${ReplaceBlankSpaceByHyphen(
                VENDOR_NAME
              )}/${ReplaceBlankSpaceByHyphen(PRODUCT?.Name)}/blurbs/${EncryptId(
                PRODUCT?.Id
              )}`
            )
          }
          href={`/${ReplaceBlankSpaceByHyphen(
            VENDOR_NAME
          )}/${ReplaceBlankSpaceByHyphen(PRODUCT?.Name)}/blurbs/${EncryptId(
            PRODUCT?.Id
          )}`}
        >
          {KEYS?.PROVIDER_BLURBS.MORE}
        </a>
      ) : null} */}
    </div>
  );
};

export default BoardBlurbTab;
