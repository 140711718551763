import React from "react";
import { useParams } from "react-router";
import {
  DecryptId,
  GetIdFormLink,
  ToTitleCase,
} from "../../../../utlis/hof/hof";
import Footer from "../../../home/blades/footer";
import Header from "../../../home/header/header";
import SearchHeaderBreadCrumb from "../../../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import HeaderSEO from "../../../SEO/header";
import AllProductsList from "./allProductsList/allProductsList";
import ProductsHeader from "./productsHeader/productsHeader";

function ViewAllProducts(props) {
  const PARAMS = useParams();
  // const vendorName = PARAMS?.name;
  const VENDOR_NAME = PARAMS?.name
    ? PARAMS?.name
    : PARAMS?.vendor
    ? ToTitleCase(PARAMS?.vendor?.replaceAll("-", " "))
    : "";
  const VENDOR_ID = PARAMS?.name
    ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id
    : DecryptId(PARAMS?.id)?.Id;
  return (
    <div id="app">
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      <Header show={true} />
      <main>
        <div className="page-content  page-content--top-reset">
          <div className="attached-navbar m-b-md">
            <div className="container flex space-between align-items-bottom">
              <SearchHeaderBreadCrumb
                parent={"providerPage"}
                type={"Provider"}
                params={VENDOR_NAME}
              />
            </div>
          </div>
          <ProductsHeader
            vendorName={PARAMS?.name ? VENDOR_NAME?.Name : VENDOR_NAME}
            vendorId={VENDOR_ID}
          />
          <AllProductsList
            vendorName={PARAMS?.name ? VENDOR_NAME?.Name : VENDOR_NAME}
            vendorId={VENDOR_ID}
          />
        </div>
      </main>
      <Footer page={props?.page} />
    </div>
  );
}

export default ViewAllProducts;
