import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ReplaceBlankSpaceByHyphen,
  ToTitleCase,
} from "../../../../../../../utlis/hof/hof";

function CategoriesList(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);

  let data = props?.data;
  const navigation = useNavigate();
  return (
    <div>
      <div className="categories__results-message text-xs text-xs m-b-xs m-t-base">
        {props?.data?.length} product{" "}
        {props?.data?.length == 1 ? "category" : "categories"}
      </div>
      <div>
        {data?.map((item) => {
          return (
            <a
              role="button"
              className="categories__list-item"
              onClick={() =>
                navigation(
                  `/category/${ReplaceBlankSpaceByHyphen(
                    item?.Product_Category_Name,
                    item?.Product_Category_ID
                  )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
                )
              }
            >
              <h2
                className="categories__list-item__title"
                data-highlight="Address Data"
              >
                {ToTitleCase(item?.Product_Category_Name)}
              </h2>

              {/* <div
                class="categories__list-item__subtitle"
                data-highlight="Consumer Identity Data"
              >
                Consumer Identity Data
              </div> */}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default CategoriesList;
