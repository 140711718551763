import React,{useEffect} from "react";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import ProposalHeader from "./component/proposalHeader/proposalHeader";
import ProposalSummary from "./component/proposalSummary/proposalSummary";
import SharedProposalTable from "../sharedProposal/component/proposalTable/proposalTable";
import ProposalTable from "./component/proposalTable/proposalTable";
import { updateMetaTags } from "../../../src/components/SEO/seo";
import TabNavigator from "../../utlis/hoc/tabNavigator/tabNavigator";
import { useParams } from "react-router";

const Proposals = (props) => {
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  useEffect(() => {
    const seoBody = {
      type: "proposals",
    };
    updateMetaTags(seoBody);
  }, []);

  const PROPOSAL_TAB = [
    {
      label: "My proposal",
      value: "my-proposal",
      display: true,
    },
    {
      label: "Shared proposal",
      value: "shared-proposal",
      display: true,
    },
  ];
  return (
    <div id="app" class="data-products__show">
      <Header page={props?.page} show={true} />
      <div className="attached-navbar m-b-md">
        <div className="p-x-md flex space-between align-items-bottom container">
          <SearchHeaderBreadCrumb
            page={props?.page}
            parent={"productPage"}
            type={"Product"}
            params={"Proposal"}
            data={[{ name: "Home", id: "1", link: "/" }]}
          />
        </div>
      </div>
      <div className="container">
        <div className="flex tab-navigator-container m-b-sm">
          {PROPOSAL_TAB?.map((item, index) => (
            <TabNavigator
              tabIndex={tabIndex}
              tabItem={item}
              index={index + 1}
              navigatePath={"proposals"}
              page={props?.page}
            />
          ))}
        </div>
        {tabIndex === "shared-proposal" ? (
          <SharedProposalTable />
        ) : (
          <>
            <ProposalHeader />
            <ProposalSummary />
            <ProposalTable />
          </>
        )}
      </div>
    </div>
  );
};

export default Proposals;
