import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import OctateChatCard from "./octateChatCard";

const ChatListVendorWrapper = (props) => {
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const [conversationList, setConversationList] = useState([]);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  useEffect(() => {
    setConversationList(octateVendorSelector ? octateVendorSelector : []);
  }, [octateVendorSelector]);

  // useEffect(() => {
  //   if (selectedVendorSelector?.Conversation_ID) {
  //     let conversationListArr = conversationList?.length ? [...conversationList] : [];
  //     const ConversionIdExist = conversationListArr.find(
  //       (element) => element.Vendor_ID == selectedVendorSelector?.Vendor_ID
  //     );
  //   }
  // }, [selectedVendorSelector?.Conversation_ID]);

  return (
    <div className="chat-list-wrapper">
      {conversationList?.map((item) => (
        <OctateChatCard data={item} />
      ))}
    </div>
  );
};

export default ChatListVendorWrapper;
