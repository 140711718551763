import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import CompleteStep from "./completeStep";
import LoadingDetails from "./loadingDetails";
import OrganizationStep from "./organizationStep";

const JoinOrClaimOrganization = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const tempDetails = useSelector((state) => state?.authDetails?.tempDetails);
  const authData = useSelector((state) => state?.authDetails?.details);

  const emailId = authData?.data?.emailId
    ? authData?.data?.emailId
    : tempDetails?.data?.emailId;

  const GetApiData = useEnCryptGetApi(
    `/getOrganizationForSelection?emailId=${emailId}&userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  return (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        <div class="m-y-auto">
          <CompleteStep name={KEYS?.JOIN_ORGANIZATION?.EMAIL_VERIFICATION} />

          {GetApiData?.isLoading || GetApiData?.isFetching ? (
            <LoadingDetails />
          ) : GetApiData?.data?.data ? (
            <OrganizationStep
              data={GetApiData?.data?.data}
              completeStep={() => props?.completeStep()}
              emailId={emailId}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default JoinOrClaimOrganization;
