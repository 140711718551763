import React from "react";
import { useLocalStorage } from "../../../utlis/customHooks/useLocalStorage";
import Footer from "../../home/blades/footer";
import Header from "../../home/header/header";
import HeaderAuth from "../../authHome/header/header";
import ProfileHeader from "./components/profileHeader";
import ProfileHelp from "./components/profileHelp";
import SearchHeaderBreadCrumb from "../../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { tempAuthDetails } from "../../../contexts/reducer/authReducer";
import { useNavigate } from "react-router";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEffect } from "react";
import OnboardVendor from "../../businessProfile/components/tabs/components/onboardVendor";
import { useState } from "react";

const Profile = () => {
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewBusinessProfileClicked = () => {
    if (authData?.data?.isValidDomain) {
      navigate("/join-organization");
    }
  };

  const GetVendorStatus = useEnCryptGetApi(
    `/getVendorStatusForUser?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const handleModalVisible = () => {
    setModalVisible((prev) => !prev);
  };

  useEffect(() => {
    if (GetVendorStatus?.data?.data) {
      setUser({ ...authData?.data, ...GetVendorStatus?.data?.data });
    }
  }, [GetVendorStatus?.data?.data]);

  return (
    <>
      <Header show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb parent={"profile"} params={""} />
          </div>
        </div>
      </div>
      <div class="page-content page-content--reset page-content--top-reset">
        <div class="attached-navbar">
          <div class="container flex space-between align-items-bottom"></div>
        </div>
        <div class="container p-y-md" style={{ maxWidth: "1120px" }}>
          <ProfileHeader />
          <div class="grid-auto-max tablet-1-col grid-col-gap-lg" key={authData?.data?.buyerView}>
            <div class="my-profile__menu-items grid-three-columns tablet-2-col grid-col-gap-md grid-row-gap-md grid-row-gap-md">
              {(authData?.data?.requestAccepted || authData?.data?.adminStatus) && !authData?.data?.buyerView ? (
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href={`/business-profile/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
                  // onClick={handleViewBusinessProfileClicked}
                  style={{ marginTop: "24px" }}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-briefcase big"></i>
                    <h4 class="m-t-xs" style={{ color: "#000" }}>
                      {" "}
                      Business Profile{" "}
                    </h4>
                    <p class="text-sm tablet-hidden fw-400 text-grey-3">View and Update your profile here</p>
                  </div>
                </a>
              ) : null}
              {(authData?.data?.requestAccepted || authData?.data?.adminStatus) && !authData?.data?.buyerView ? (
                <>
                  <a
                    class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                    href={`/sales-hub/proposals`}
                    // onClick={handleViewBusinessProfileClicked}
                    style={{ marginTop: "24px" }}
                  >
                    <div class="modern-card__content text-center">
                      <i class="feather icon icon-briefcase big"></i>
                      <h4 class="m-t-xs" style={{ color: "#000" }}>
                        Sales hub{" "}
                      </h4>
                      <p class="text-sm tablet-hidden fw-400 text-grey-3">Manage your buyer interaction here</p>
                    </div>
                  </a>
                </>
              ) : null}
              <>
                {authData?.data?.isValidDomain ? (
                  <a
                    class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                    href="/proposals/my-proposal"
                    // onClick={handleViewBusinessProfileClicked}
                    style={{ marginTop: "24px" }}
                  >
                    <div class="modern-card__content text-center">
                      <i class="feather icon icon-inbox big"></i>
                      <h4 class="m-t-xs" style={{ color: "#000" }}>
                        {" "}
                        Manage Proposals{" "}
                      </h4>
                      <p class="text-sm tablet-hidden fw-400 text-grey-3">Manage your Proposals</p>
                    </div>
                  </a>
                ) : null}
              </>
              <>
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href="/profile/edit-profile"
                  style={{ marginTop: "24px" }}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-settings big"></i>
                    <h4 class="m-t-xs" style={{ color: "#000" }}>
                      {" "}
                      {KEYS?.MY_PROFILE_PAGE.PROFILE_TEXT}
                    </h4>

                    <p class="text-sm tablet-hidden fw-400 text-grey-3">{KEYS?.MY_PROFILE_PAGE.PROFILE_DISC}</p>
                  </div>
                </a>
              </>
              {authData?.data?.isValidDomain &&
              !(authData?.data?.requestAccepted || authData?.data?.adminStatus) &&
              !authData?.data?.buyerView ? (
                <>
                  <a
                    class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                    // href="/business-profile"
                    onClick={handleViewBusinessProfileClicked}
                    style={{ marginTop: "24px" }}
                    role="button"
                  >
                    <div class="modern-card__content text-center">
                      <i class="feather icon icon-inbox big"></i>
                      <h4 class="m-t-xs" style={{ color: "#000" }}>
                        {" "}
                        Manage Business{" "}
                      </h4>
                      <p class="text-sm tablet-hidden fw-400 text-grey-3">Manage your business</p>
                    </div>
                  </a>
                </>
              ) : null}

              <>
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href="/user-boards"
                  style={{ marginTop: "24px" }}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-grid big"></i>
                    <h4 class="m-t-xs" style={{ color: "#000" }}>
                      Manage Boards
                    </h4>

                    <p class="text-sm tablet-hidden fw-400 text-grey-3">Manage your boards, bookmarks, likes</p>
                  </div>
                </a>
              </>
              <>
                <a
                  class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                  href="/manage-preferences"
                  style={{ marginTop: "24px" }}
                >
                  <div class="modern-card__content text-center">
                    <i class="feather icon icon-bell big"></i>
                    <h4 class="m-t-xs" style={{ color: "#000" }}>
                      Preferences
                    </h4>
                    <p class="text-sm tablet-hidden fw-400 text-grey-3">Manage your preferences</p>
                  </div>
                </a>
              </>
              <>
                {superAdminStatus ? (
                  <a
                    class="modern-card modern-card--bordered modern-card--elevated-on-hover"
                    onClick={() => handleModalVisible()}
                    style={{ marginTop: "24px" }}
                  >
                    <div class="modern-card__content text-center">
                      <i class="feather icon icon-inbox big"></i>
                      <h4 class="m-t-xs" style={{ color: "#000" }}>
                        {" "}
                        Invite Vendor{" "}
                      </h4>
                      <p class="text-sm tablet-hidden fw-400 text-grey-3">Invite Vendor</p>
                    </div>
                  </a>
                ) : null}
              </>
            </div>
            {modalVisible ? <OnboardVendor handleCloseModal={() => setModalVisible((prev) => !prev)} /> : null}
            <ProfileHelp />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
