import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import { useSelector } from "react-redux";
import BlurbPageBusinessDetails from "../blurbDetail/components/businessDeatils/blurbPageBusinessDetails";
import Footer from "../home/blades/footer";
import FaqAside from "./faqAside";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import FaqDetailCard from "./faqDetailCard";
import { GetIdFormLink } from "../../utlis/hof/hof";
import { updateMetaTags } from "../../../src/components/SEO/seo";

function FaqDetailPage(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const page = props?.page;
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const FAQ_ID = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  useEffect(() => {
    const seoBody = {
      type: "faq",
      id: FAQ_ID?.Id
    };
   
    updateMetaTags(seoBody);
  }, []);

  const GetApiData = useEnCryptGetApi(
    `/getFAQForDetailPage?FAQ_ID=${FAQ_ID?.Id}&User_ID=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const Data = GetApiData?.data?.data;
  const Selected_FAQ = typeof Data?.Selected_FAQ === "object" ? Data?.Selected_FAQ : {};
  const Related_FAQ = typeof Data?.Related_FAQ === "object" && Data?.Related_FAQ?.length ? Data?.Related_FAQ : [];
  const BreadCrumbObject = Data?.breadcrum?.length ? Data?.breadcrum : [];
  return (
    <div id="app" className="landing-page__show ">
      <Header page={props?.page} show={true} />
      <div className="attached-navbar">
        <div className="fixed-nav-container flex space-between align-items-bottom ui container  ">
          <SearchHeaderBreadCrumb
            page={"blurbDetail"}
            parent={"blurbDetail"}
            type={props?.type}
            data={BreadCrumbObject}
          />
        </div>
      </div>
      <div className="faq-grid-2-1 tablet-landscape-1-col ui container " style={{ columnGap: "0px" }}>
        {Data ? (
          <>
            <div>
              <FaqDetailCard data={Selected_FAQ} />
            </div>
            <div className="mobile-hidden">
              <FaqAside data={Related_FAQ} />
            </div>
          </>
        ) : null}
      </div>
      <Footer page={props?.page} />
    </div>
  );
}

export default FaqDetailPage;
