import React from "react";
import { useState } from "react";
import Comments from "../../../utlis/hoc/comments/comments";
import EngagementBar from "../../../utlis/hoc/engagementBar/engagementBar";
import ProductActions from "../../../utlis/hoc/newProductCard/components/productActions";
import ProductContent from "../../../utlis/hoc/newProductCard/components/productContent";
import ProductHeader from "../../../utlis/hoc/newProductCard/components/productHeader";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
function ProductCard(props) {
  const [viewComments, setViewComments] = useState(false);
  const [dragOpacity, setDragOpacity] = useState(1);
  const [hovered, setHovered] = useState(false);
  const cardType = props?.cardType;
  const item = props?.item;
  const index = props?.index;
  const onDragOver = (e, id) => {
    e.preventDefault();
    setDragOpacity(0.3);
  };
  const onDragStart = (e, id) => {
    e.dataTransfer.setData("current index", id);
    // setDragOpacity(0.2);
  };
  const onDragEnd = () => {
    setDragOpacity(1);
  };
  const onDrop = (e, index, value) => {
    const currentIndex = e.dataTransfer.getData("current index");
    props?.handleChangePosition(currentIndex, index, value);
    setDragOpacity(1);
  };
  const handleDragLeave = () => {
    // Set the opacity back to 1 when the draggable element leaves the target element
    setDragOpacity(1);
  };
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index, "Products")}
      onDragEnd={(e) => onDragEnd(e, index)}
      onDragLeave={handleDragLeave}
      className="product-card cursor-for-drag p-r"
      key={item?.Product_ID}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ opacity: dragOpacity }}
    >
      {/* {hovered ? (
        <div
          data-inverted="true"
          data-position="top left"
          data-tooltip={"Drag to change position"}
          style={{
            position: "absolute",
            top: "45%",
            left: "40%",
          }}
        >
          <FeatherIcon
            icon="shuffle"
            size="65"
            color={"rgba(85, 112, 241, 0.5)"}
            className="filter-input-field-search-icon"
          />
        </div>
      ) : null} */}
      <ProductHeader
        boardUserId={props?.boardUserId}
        keyName={"Product_ID"}
        data={item}
        cardType={cardType}
        page={props?.page}
        section={props?.section}
      />
      <div className="product-card-content">
        <ProductContent data={item} cardType={cardType} page={props?.page} section={props?.section} />
        <ProductActions data={item} cardType={cardType} page={props?.page} section={props?.section} />
      </div>
      <div className="new-card-engagement-bar-container m-b-xs blurb-card-engagement-wrapper">
        <EngagementBar
          cardType={cardType}
          page={props?.page}
          data={item}
          section={props?.section}
          searchText={props?.searchText}
          keyName={"Product_ID"}
          engageSource={"Product engagement from product card"}
          handleViewComments={() => setViewComments((prev) => !prev)}
        />
      </div>
      {viewComments && <Comments />}
    </div>
  );
}

export default ProductCard;
