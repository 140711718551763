import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-paste-smart";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";
import SearchProductCategory from "../../addProduct/component/searchProductCategory";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";

// import ProductImageAndFile from "./productImageAndFile";
const BasicBlurbDetails = (props) => {
  const [searchIndustryText, setSearchIndustryText] = useState("a");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;
  const error = props?.companyErrors;
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const StaticDropdownData = props?.StaticDropdownData;
  // const productBasicDetails = props?.data;
  const productBasicDetails = props?.productDetails;
  const [description, setDescription] = useState(productBasicDetails?.description);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const USER_ID = authData?.data?.userId;

  //   https://thebox-dev.portqii.com/core/vendor/vendorOnboardingSearch?searchText=auto&type=industry
  const industryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchIndustryText}&type=industry`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const GetProductFormVendorIdApi = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${vendorId}&limit=15&status=${vendorDetails?.Status}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const contentTypeDropDownData = useEnCryptGetApi(
    `/getJourneyDetails`,
    KEYS?.END_POINTS?.DATAVERIFICATION?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data?.data;
  const ADD_BLURB = KEYS?.ADD_BLURB;
  const handleSearchWithApi = (key) => {
    setSearchIndustryText(key);
  };
  const handleApplyCountryClicked = (val) => {};
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...productBasicDetails,
      Compatible_Softwares: val,
    });
  };
  const concatIsSelectedCompatible = concatIsSelectedAllData(
    productBasicDetails?.Compatible_Softwares?.length ? productBasicDetails?.Compatible_Softwares : [],
    StaticDropdownData?.Compatible_Softwares,
    "id"
  );
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [false] }],
          ["bold", "italic", "underline", "strike", "link", "clean"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  function handleDescription(value) {
    setDescription(value);
  }
  useEffect(() => {
    if (description?.length)
      props?.handleDetails({
        ...productBasicDetails,
        description: description,
      });
    // else setDescription(productBasicDetails?.description);
  }, [description]);
  return (
    <div className="company-profile-basic-details-main-container">
      <div className="product-profile-basic-details-left-side-container">
        {/* <div>
          <h5 class="company-header-sub-title">
            {ADD_BLURB?.BASIC_INFORMATION_LABEL}
          </h5>
        </div> */}
        {/* <div
          class="field p-r  m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          {true ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {ADD_BLURB?.VENDOR_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            disable={true}
            label={ADD_BLURB?.VENDOR_NAME_LABEL}
            value={StaticDropdownData?.Vendor?.Vendor_Name}
            // changeText={(val) =>
            //   props?.handleDetails({ ...companyDetails, Vendor_Name: val })
            // }
            // error={error?.Vendor_Name}
          />
        </div> */}
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.title_level_1 ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.BLURB_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_BLURB?.BLURB_NAME_LABEL}
            value={productBasicDetails?.title_level_1}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                title_level_1: val,
              })
            }
            error={error?.title_level_1}
          />
        </div>

        {/* <div class="field p-r m-t-md" id="field-user__phone_number_prefix">
          {productBasicDetails?.description ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.DESCRIPTION_LABEL}
            </label>
          ) : null}
          <TextArea
            value={productBasicDetails?.description}
            className="m-b-sm"
            label={ADD_BLURB?.DESCRIPTION_LABEL}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                description: val,
              })
            }
            subTitleTop={"Please add at least 50 characters to describe your requirement."}
            error={error?.description}
          />
        </div> */}
        <div className="m-y-sm p-r p-b-smy">
          <label className="company-details-label-input-field-filled-absolute">{ADD_BLURB?.DESCRIPTION_LABEL}</label>
          <ReactQuill
            modules={modules}
            theme="snow"
            value={description ?? productBasicDetails?.description}
            onChange={handleDescription}
            placeholder={ADD_BLURB?.DESCRIPTION_LABEL}
          />
          {error?.description ? <span class="input-field-text-error-text-area">{error?.description}</span> : null}
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_ID: val?.Product_ID,
                Product_Page_URL: val?.Product_Page_URL,
                Product_Name: val?.Product_Name,
                Vendor_ID: val?.Vendor_ID,
                Vendor_Name: val?.Vendor_Name,
                Account_Logo: val?.Account_Logo,
              })
            }
            data={GetProductFormVendorIdApi?.data?.data}
            placeholder={"Select product"}
            selectedVal={{
              Product_ID: productBasicDetails?.Product_ID,
              Product_Page_URL: productBasicDetails?.Product_Page_URL,
              Product_Name: productBasicDetails?.Product_Name,
              Vendor_ID: productBasicDetails?.Vendor_ID,
              Vendor_Name: productBasicDetails?.Vendor_Name,
              Account_Logo: productBasicDetails?.Account_Logo,
            }}
            name={"Product_Name"}
            label={"Product Name"}
            returnObject={true}
            //   handleSearchWithApi={handleSearchWithApi}
            //   searchWithApi={true}
          />
          {error?.Industry ? <span class="input-field-dropdown-error">{error?.Industry}</span> : null}
        </div>
        <div class="field-company-profile p-r m-y-base" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Content_Type: val?.Content_Type,
                Journey_Stage: val?.Journey_Stage,
              })
            }
            data={contentTypeDropDownData?.journeyDetails}
            placeholder={ADD_BLURB?.CONTENT_TYPE_LABEL}
            // flag={true}
            selectedVal={{
              Content_Type: productBasicDetails?.Content_Type,
              Journey_Stage: productBasicDetails?.Journey_Stage,
            }}
            name={"Content_Type"}
            rightSideContent={true}
            rightName={"Journey_Stage"}
            returnObject={true}
            label={ADD_BLURB?.CONTENT_TYPE}
          />
          {error?.Content_Type ? <span class="input-field-dropdown-error">{error?.Content_Type}</span> : null}
        </div>
        <div style={{ marginTop: "40px" }}>
          <SearchProductCategory
            productBasicDetails={productBasicDetails}
            handleDetails={props?.handleDetails}
            cardType={"add-blurbs"}
          />
        </div>

        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.Page_URL ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.PAGE_URL}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_BLURB?.PAGE_URL_LABEL}
            value={productBasicDetails?.Page_URL}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Page_URL: val,
              })
            }
            error={error?.Page_URL}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.call_to_action_name ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.CTA_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_BLURB?.CTA_NAME_LABEL}
            value={productBasicDetails?.call_to_action_name}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                call_to_action_name: val,
              })
            }
            error={error?.call_to_action_name}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.call_to_action_link ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.CTA_LINK}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_BLURB?.CTA_LINK_LABEL}
            value={productBasicDetails?.call_to_action_link}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                call_to_action_link: val,
              })
            }
            error={error?.call_to_action_link}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.image_src ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.IMAGE_SRC}
            </label>
          ) : null}
          {/* image_src */}
          <InputFieldProfile
            label={ADD_BLURB?.IMAGE_SRC_LABEL}
            value={productBasicDetails?.image_src}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                image_src: val,
              })
            }
            // error={error?.Relative_Position}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.Relative_Position ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_BLURB?.POSITION}
            </label>
          ) : null}
          {/* image_src */}
          <InputFieldProfile
            label={ADD_BLURB?.POSITION_LABEL}
            value={productBasicDetails?.Relative_Position}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Relative_Position: parseInt(val?.replace(/[^0-9]/g, "")),
              })
            }
            error={error?.Relative_Position}
          />
        </div>
      </div>
      <div className="company-profile-basic-details-right-side-container">
        {/* <h3 className="company-profile-feature-services-content-title">
          Upload Cover Image
        </h3> */}
        {/* <AddBlurbImage
          handleDetails={props?.handleDetails}
          image={productBasicDetails}
          handleImageFile={props?.handleImageFile}
        /> */}
      </div>
    </div>
  );
};

export default BasicBlurbDetails;
