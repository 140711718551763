import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
const CompanyDetailsHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  return (
    <div className="company-details-header-main-wrapper">
      <div className="company-details-header-title-text">
        {props?.productPageLabel
          ? props?.productPageLabel
          : authData?.data?.Persona === "Business User"
          ? KEYS?.COMPANY_PROFILE?.EDIT_ORAGANIZATION
          : KEYS?.COMPANY_PROFILE?.NEW_ORGANIZATION}
      </div>
      <div
        onClick={() => props?.handleClose()}
        className="company-details-header-title-icon"
      >
        <FeatherIcon icon="x" size="20" />
      </div>
    </div>
  );
};

export default CompanyDetailsHeader;
