import React from "react";
import { useSelector } from "react-redux";
// import * as KEYS from "../../../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
function ProvidersActionButton(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  return (
    <div class="provider-profile__alternative__footer">
      <a
        class="fluid outlined rounded button"
        href={`/vendor/${ReplaceBlankSpaceByHyphen(
          props?.data?.Vendor_Name,
          props?.data?.Vendor_ID
        )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
      >
        {KEYS?.VENDOR_ALTERNATIVE.VIEW}
      </a>
    </div>
  );
}

export default ProvidersActionButton;
