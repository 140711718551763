import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import InputFieldNew from "../../signUp/onBoard/components/InputFieldNew";
import VendorList from "./vendorList";
import FeatherIcon from "feather-icons-react";
import * as CONFIG_KEYS from "../../../../config/config";
const VendorSearch = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [domainUrl, setDomainUrl] = useState("");
  const [searchText, setSearchText] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);
  const [error, setError] = useState({});
  const [vendorData, setVendorData] = useState([]);
  const [vendorStatus, setVendorStatus] = useState(true);
  const organizationData = props?.organizationData;
  const JOIN_ORGANIZATION = KEYS?.JOIN_ORGANIZATION;
  const MANAGE_BUSINESS_KEYS = CONFIG_KEYS?.CONFIG?.MANAGE_BUSINESS;
  const GetApiData = useEnCryptGetApi(
    `/vendor/searchVendorByDomain?Domain_URL=${searchText}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    searchText?.length ? false : true
  );

  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      setVendorData(GetApiData?.data?.data);
    } else if (GetApiData?.data?.data?.status === false) {
      setVendorData([]);
      setVendorStatus(false);
    }
  }, [GetApiData?.data?.data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const domainRegex =
      /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;
    // /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;

    if (domainRegex.test(domainUrl)) {
      setSearchText(domainUrl);
    } else {
      setError({ domainUrl: "Please enter valid domain" });
      setTimeout(() => {
        setError({});
      }, 1000);
    }
  };
  const handleChangeText = (val) => {
    setDomainUrl(val);
    // props?.handleDomainUrl(val);
    setVendorStatus(true);
    setRemountComponent(Math.random());
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="field m-b-base" id="field-domain__url" style={{ position: "relative" }}>
          <label className="otp-verification-resend-text">Search for company domain</label>
          <InputFieldNew
            label={"example.com"}
            value={domainUrl}
            // icon={"search"}
            changeText={(val) => handleChangeText(val)}
            error={error?.domainUrl}
          />
          <FeatherIcon
            icon="search"
            size="35"
            color="#FFB527"
            className="searchIcon"
            role="button"
            style={{ right: "2px", top: "20px" }}
            onClick={(e) => handleSubmit(e)}
          />
        </div>
      </form>
      <div key={remountComponent}>
        {vendorData?.map((item) => {
          if (organizationData?.length) {
            const VENDOR_STATUS = organizationData?.some((val) => val?.Vendor_ID === item?.Vendor_ID);
            if (!VENDOR_STATUS) {
              return (
                <div className="m-b-sm">
                  <VendorList
                    data={item}
                    source={"searchResult"}
                    // handleButtonVisible={() => props?.handleButtonVisible()}
                  />
                </div>
              );
            } else {
              return (
                <div className="vendor-onboarding-unverified-text">{MANAGE_BUSINESS_KEYS?.SEARCH_REJECTED_VENDORS}</div>
              );
            }
          } else
            return (
              <div className="m-b-sm">
                <VendorList
                  data={item}
                  source={"searchResult"}
                  // handleButtonVisible={() => props?.handleButtonVisible()}
                />
              </div>
            );
        })}

        {vendorStatus === false ? (
          <p className="otp-verification-resend-text m-b-md" style={{ flexWrap: "wrap", width: "100%" }}>
            No matching company found for your
            <strong style={{ padding: "0px 5px" }}>{`${domainUrl}`}</strong>
            <p style={{margin:'0px'}} className="resend-button" role={"button"} onClick={() => props?.handleModal(domainUrl)}>
              {JOIN_ORGANIZATION?.CREATE_NEW}
            </p>
          </p>
        ) : null}
      </div>
    </>
  );
};

export default VendorSearch;
