import React, { useState, useEffect } from "react";
import Header from "../home/header/header";
import BusinessProfileHeader from "./components/businessProfileHeader/businessProfileHeader";
import BusinessDetails from "./components/businessOverview/businessDetails";
import BusinessFeed from "./components/tabs/businessFeed";
import VendorProducts from "./components/tabs/vendorProducts";
import UserManagement from "./components/tabs/userManagement";
import useScrollPosition from "../../utlis/customHooks/useScrollPosition";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import FaqTab from "./components/tabs/faqTab";
import UseCases from "./components/tabs/useCases";
import StickyTab from "../../utlis/hoc/tabNavigator/stickyTab";
import { GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import ProviderAlternative from "../providers/component/providersMainContent/providerAlternatives/providerAlternative";
import ProposalsTab from "./components/tabs/proposalsTab";
import ProposalOverview from "./components/proposalOverView/proposalOverview";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import VendorCustomers from "./components/tabs/vendorCustomers";
import Footer from "../home/blades/footer";
import BoardsTab from "../productPageNew/components/tabs/boardsTab";
import { updateMetaTags } from "../../../src/components/SEO/seo";
import PermissionTab from "./components/tabs/permissionTab";
import PageLoading from "../../utlis/spinner/pageLoading";
import * as CONFIG_KEYS from "../../config/config";
import { allPermissionsFn } from "../../contexts/reducer/permissionsReducer";
import VendorChatTab from "./components/tabs/vendorChatTab";
import SuperAdmin from "./components/tabs/superAdmin";
const BusinessProfile = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const authData = useSelector((state) => state?.authDetails?.details);
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const [activeRowProposal, setActiveRowProposal] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  // let permissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  // permissionsDetails = props?.type === "buyer" ? {} : permissionsDetails;
  const scrollPosition = useScrollPosition();
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const dispatch = useDispatch();
  const vendorId =
    props?.type == "buyer"
      ? PARAMS?.name
        ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id
        : authData?.data?.vendorId
      : authData.data?.vendorId;
  const VENDOR_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : authData?.data?.vendorId;
  const VENDOR_NAME = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Name : null;
  const emailId = authData?.data?.emailId;
  const navigatePath = props?.type === "buyer" ? "vendor" : "business-profile";
  const page = `${props?.page}`;
  const GetVendorStatus = useEnCryptGetApi(
    `/getVendorStatusForUser?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  // /permission/getPermissionsForUser
  const domain = emailId ? emailId.split("@").pop() : null;
  const SkipGetPermissions = props?.type !== "buyer" || domain == "portqii.com" || domain == "octate.ai" ? false : true;
  const GetUserPermissionStatus = useEnCryptGetApi(
    `/permission/getPermissionsForUser?User_ID=${authData?.data?.userId}&Vendor_ID=${vendorId} `,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    SkipGetPermissions
  );
  const permissionsDetails =
    (domain == "portqii.com" || domain == "octate.ai") && GetUserPermissionStatus?.data?.data?.status
      ? { ...GetUserPermissionStatus?.data?.data, vendorId: VENDOR_ID }
      : props?.type === "buyer"
      ? {}
      : GetUserPermissionStatus?.data?.data
      ? { ...GetUserPermissionStatus?.data?.data, vendorId: VENDOR_ID }
      : {};
  useEffect(() => {
    if ((domain == "portqii.com" || domain == "octate.ai") && GetUserPermissionStatus?.data?.data?.status) {
      dispatch(allPermissionsFn({ ...GetUserPermissionStatus?.data?.data, vendorId: VENDOR_ID }));
    } else if (props?.type === "buyer") {
      dispatch(allPermissionsFn({}));
    } else if (GetUserPermissionStatus?.data?.data) {
      dispatch(allPermissionsFn({ ...GetUserPermissionStatus?.data?.data, vendorId: VENDOR_ID }));
    }
  }, [GetUserPermissionStatus?.data?.data]);

  useEffect(() => {
    const seoBody = {
      type: "vendor",
      id: VENDOR_ID,
      tab: tabIndex,
    };
    updateMetaTags(seoBody);
  }, []);

  useEffect(() => {
    if (GetVendorStatus?.data?.data) {
      setUser({ ...authData?.data, ...GetVendorStatus?.data?.data });
    }
  }, [GetVendorStatus?.data?.data]);

  const ADMIN_TAB_DATA =
    superAdminStatus && props?.type !== "buyer"
      ? [
          { label: PERMISSIONS_KEYS.VENDOR_CHAT_TAB, value: PERMISSIONS_KEYS.VENDOR_CHAT, display: true },
          { label: "Teams", value: "teams", display: true },
          { label: "Permission", value: "permission", display: true },
          { label: "Super Admin", value: "super-admin", display: true },
        ]
      : [
          { label: PERMISSIONS_KEYS.VENDOR_CHAT_TAB, value: PERMISSIONS_KEYS.VENDOR_CHAT, display: true },
          { label: "Teams", value: "teams", display: true },
          { label: "Permission", value: "permission", display: true },
        ];

  let BUSINESS_PROFILE_TAB = KEYS?.BUSINESS_PROFILE_TAB;
  const handleActiveRowData = (val, index) => {
    setActiveRowProposal(val);
  };
  // authData?.data?.adminStatus &&
  BUSINESS_PROFILE_TAB =
    props?.type !== "buyer"
      ? [...BUSINESS_PROFILE_TAB, ...ADMIN_TAB_DATA]
      : props?.type === "buyer"
      ? KEYS?.VENDOR_PAGE_TAB
      : [...BUSINESS_PROFILE_TAB];

  return GetVendorStatus?.isLoading || GetVendorStatus?.isFetching || !GetVendorStatus?.data?.data ? (
    <PageLoading />
  ) : (authData?.data?.requestAccepted && !authData?.data?.buyerView) ||
    (authData?.data?.adminStatus && !authData?.data?.buyerView) ||
    props?.type === "buyer" ||
    (GetVendorStatus?.data?.data?.requestAccepted && !authData?.data?.buyerView) ||
    (GetVendorStatus?.data?.data?.adminStatus && !authData?.data?.buyerView) ? (
    <>
      <Header page={page} show={true} />
      {props?.type === "buyer" && (
        <div className="attached-navbar ">
          <div className="fixed-nav-container flex space-between align-items-bottom ui container">
            <SearchHeaderBreadCrumb page={page} parent={"providerPage"} type={"Provider"} params={VENDOR_NAME} />
          </div>
        </div>
      )}
      <div className="business-profile-landing-page-container ui container">
        <BusinessProfileHeader
          permissionsDetails={permissionsDetails}
          page={page}
          tabIndex={tabIndex}
          tabData={BUSINESS_PROFILE_TAB}
          type={props?.type}
          navigatePath={navigatePath}
          VENDOR_ID={VENDOR_ID}
        />

        <StickyTab
          permissionsDetails={permissionsDetails}
          tabIndex={tabIndex}
          tabData={BUSINESS_PROFILE_TAB}
          scrollPosition={scrollPosition}
          navigatePath={navigatePath}
          page={page}
        />
        <div
          id="use-management-table"
          className={`${
            tabIndex == "permission" || tabIndex == "super-admin" ? "" : "grid-business-profile mobile-1-col"
          }`}
        >
          {tabIndex == "blurbs" ? (
            <BusinessFeed
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BLURBS]}
              tabIndex={tabIndex}
              page={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == PERMISSIONS_KEYS.VENDOR_CHAT ? (
            <VendorChatTab
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.PRODUCT]}
              tabIndex={tabIndex}
              page={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == "products" ? (
            <VendorProducts
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.PRODUCT]}
              tabIndex={tabIndex}
              page={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == "permission" ? (
            <PermissionTab
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.PERMISSIONS]}
              tabIndex={tabIndex}
              page={page}
            />
          ) : tabIndex == "teams" ? (
            <UserManagement
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.TEAMS]}
              tabIndex={tabIndex}
              page={page}
            />
          ) : tabIndex == "overview" ? (
            <BusinessDetails
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BUSINESS_PROFILE]}
              page={page}
              tabIndex={tabIndex}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == "faq" ? (
            <FaqTab
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.FAQ]}
              page={page}
              tabIndex={tabIndex}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : tabIndex == "boards" ? (
            <BoardsTab
              type={props?.type}
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BOARDS]}
              page={page}
              cardType={"vendor"}
              tabIndex={tabIndex}
              vendorId={VENDOR_ID}
            />
          ) : tabIndex == "use-cases" ? (
            <UseCases
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.USE_CASE]}
              tabIndex={tabIndex}
              page={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          ) : props?.type === "buyer" && (tabIndex == "alternate-vendor" || tabIndex == "alternate-vendors") ? (
            <div className="one-column-grid">
              <ProviderAlternative
                page={page}
                parent={page}
                vendorName={PARAMS?.name?.replaceAll("-", " ")}
                type={props?.type}
                tabIndex={tabIndex}
              />
            </div>
          ) : tabIndex == "customers" ? (
            <VendorCustomers
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.CUSTOMERS]}
              page={page}
              parent={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
              tabIndex={tabIndex}
            />
          ) : tabIndex == "super-admin" ? (
            <SuperAdmin
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.CUSTOMERS]}
              page={page}
              parent={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
              tabIndex={tabIndex}
            />
          ) : (
            <BusinessFeed
              permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BLURBS]}
              tabIndex={tabIndex}
              page={page}
              VENDOR_ID={VENDOR_ID}
              type={props?.type}
            />
          )}
          {tabIndex == "permission" || tabIndex == "vendor-chat" || tabIndex == "super-admin" ? null : (
            <div className="mobile-hidden">
              {tabIndex == "proposals" ? (
                <ProposalOverview
                  permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.PROPOSAL]}
                  page={page}
                  type={props?.type}
                  activeRowProposal={activeRowProposal}
                />
              ) : (
                <BusinessDetails
                  permissionsDetails={permissionsDetails?.[PERMISSIONS_KEYS.BUSINESS_PROFILE]}
                  page={page}
                  type={props?.type}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Footer page={page} />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default BusinessProfile;
