import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  useBlurbProcessorMutation,
  useBlurbProcessorPutMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from '../../adapters/xhr/generalApi';
import * as ENV_KEYS from '../../config/configKeys';
import { editUseCaseDetails } from '../../contexts/reducer/homeReducer';
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from '../../utlis/customHooks/customHooks';
import {
  BreakText,
  ReplaceBlankSpaceByHyphen,
  validateCompanyProfilePage3,
  validateProductProfilePage1,
  validateProductProfilePage2,
  validateUseCaseSave,
} from '../../utlis/hof/hof';
import CompanyDetailsFooter from '../companyProfile/component/companyDetailsFooter';
import CompanyDetailsHeader from '../companyProfile/component/companyDetailsHeader';
import UseCaseDetailList from './component/useCaseDetailList';
const AddUseCases = (props) => {
  const REF_DATA = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_USE = {
    SUCCESS_MESSAGE: 'Resource details updated successfully',
    USE_CASE_PAGE_TITLE: 'New Resource',
    USE_CASE_NAME: 'Resource Title',
    USE_CASE_NAME_LABEL: 'Resource Title',
    SUPPORTED_LANGUAGES: 'Supported Languages',
    SUPPORTED_LANGUAGES_LABEL: 'Supported Languages',
    SELECT_USE_CASE_SEGMENT: 'Select Resource Segment',
    SELECT_USE_CASE_SEGMENT_LABEL: 'Select Resource Segment',
    SELECT_INDUSTRY: 'Select Industry',
    SELECT_INDUSTRY_LABEL: 'Select Industry',
    SOFTWARE_TYPE: 'Software Type',
    SOFTWARE_TYPE_LABEL: 'Software Type',
    DESCRIPTION: 'Resource Description',
    DESCRIPTION_LABEL: 'Resource Description',
    USE_CASE_PAGE_TITLE_EDIT: 'Edit case study',
    BACK: 'Back',
    SAVE: 'Save',
    NEXT: 'Next',
  };
  const KEYS = { ...REF_DATA, ADD_USE_CASE: ADD_USE };
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const navigate = useNavigate();
  const [companyErrors, setCompanyErrors] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [imageFile, setImageFile] = useState({});
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const editProduct = props?.editProduct;
  const ADD_USE_CASE = KEYS?.ADD_USE_CASE;

  const GetBlurbDataFromApi = useEnCryptGetApi(
    `/getUseCaseBlurb?blurbID=${props?.Id}&page=usecase`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const RequestMethod = editProduct ? useBlurbProcessorPutMutation : useBlurbProcessorMutation;
  const RequestApi = editProduct ? `/addNewUseCaseBlurbUpdate` : `/addNewUseCaseBlurb`;
  const blurbsPostData = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL, RequestMethod);

  useEffect(() => {
    if (GetBlurbDataFromApi?.data?.data) {
      if (editProduct) {
        setProductDetails(GetBlurbDataFromApi?.data?.data);
      }
    }
  }, [GetBlurbDataFromApi?.data]);
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const footerList = [
    {
      name: 'Basic Details',
      id: 1,
    },
    // {
    //   name: "Add Features",
    //   id: 2,
    // },
    // {
    //   name: "Plans & Pricing",
    //   id: 3,
    // },
  ];
  const validateEmailId = authData?.data?.emailId ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf('@') + 1) : null;
  useEffect(() => {
    if (blurbsPostData?.data?.status) {
      setUpdateStatus(true);
      props?.handleClose(false);

      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: editProduct ? ENV_KEYS.CONFIG.USECASE_UPDATED : ENV_KEYS.CONFIG.USECASE_CREATED,
        Item_Type: 'Use case',
        Item_Id: productDetails?.blurbID ? productDetails?.blurbID : blurbsPostData?.data?.blurbID,
        Target: {
          page: 'Use case detail page',
          link: `/${ReplaceBlankSpaceByHyphen(productDetails?.Vendor_Name)}/usecase/${ReplaceBlankSpaceByHyphen(
            BreakText(productDetails?.title_level_1)
          )}-${productDetails?.blurbID ? productDetails?.blurbID : blurbsPostData?.data?.blurbID}`,
        },
        Object: {
          section: null,
          context: editProduct ? `Use case updated` : `Use case created`,
          page: '/business-profile/usecase',
          Persona: 'Business user',
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [blurbsPostData]);
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    const Validate3 = validateCompanyProfilePage3(productDetails);
    if (val <= 2) {
      const Validate1 = validateProductProfilePage1(productDetails);
      setCompanyErrors(Validate1);
      if (!Object.keys(Validate1)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 3) {
      const Validate2 = validateProductProfilePage2(productDetails);
      setCompanyErrors(Validate2);
      if (!Object.keys(Validate2)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 4) {
      setFooterNumberClicked(val);
    } else {
      return;
    }
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  const handleImageFile = (val) => {
    if (val) {
      setImageFile(val);
    }
  };
  const handleSaveClicked = () => {
    const validateSaveBlurbs = validateUseCaseSave(productDetails);
    setCompanyErrors(validateSaveBlurbs);
    setTimeout(() => {
      setCompanyErrors({});
    }, 3000);
    if (!Object.keys(validateSaveBlurbs)?.length) {
      blurbsPostData?.handleTrigger({
        ...productDetails,
        userId: authData?.data?.userId,
      });
      dispatch(
        editUseCaseDetails({
          ...productDetails,
          userId: authData?.data?.userId,
          Modified_User: authData?.data?.userName,
          Modify_At: Math.floor(Date.now() / 1000),
          Use_Case_Customers: productDetails?.Anonymous_Customer?.Anonymous_Customer_Status
            ? [
                ...productDetails?.Use_Case_Customers,
                {
                  Customer_ID: productDetails?.Anonymous_Customer?.Anonymous_Customer_ID,
                  Customer_Name: productDetails?.Anonymous_Customer?.Anonymous_Customer_Name,
                },
              ]
            : [...productDetails?.Use_Case_Customers],
        })
      );
      setTimeout(() => {
        dispatch(editUseCaseDetails({}));
      }, 5000);
    }
  };
  return (
    <div className='company-profile-main-wrapper'>
      <div className='company-details-header-main-wrapper-ref'>
        <CompanyDetailsHeader
          productPageLabel={editProduct ? ADD_USE_CASE?.USE_CASE_PAGE_TITLE_EDIT : ADD_USE_CASE?.USE_CASE_PAGE_TITLE}
          handleClose={() => props?.handleClose()}
        />
      </div>
      {UpdateStatus ? (
        <div class='modern-message modern-message--positive modern-message--elevated'>
          <i class='feather icon icon-check-circle message__icon'></i>
          <div class='message__content'>
            <p class='message__text'>{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className='company-profile-details-main-container-ref'>
        <UseCaseDetailList
          handleImageFile={handleImageFile}
          validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
          footerNumberClicked={footerNumberClicked}
        />
      </div>
      <div className='company-details-footer-main-wrapper'>
        <CompanyDetailsFooter
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={blurbsPostData}
        />
      </div>
    </div>
  );
};

export default AddUseCases;

{
  /*
  "ADD_BLURB":{
    "SUCCESS_MESSAGE":'Product details updated successfully',
    "USE_CASE_PAGE_TITLE":"New Product"
    "BLURB_NAME":'Product Name',
    "BLURB_NAME_LABEL":'Product Name',
    "SUPPORTED_LANGUAGES":"Supported Languages",
    "SUPPORTED_LANGUAGES_LABEL":"Supported Languages",
    "SELECT_BLURB_SEGMENT":"Select Product Segment",
    "SELECT_BLURB_SEGMENT_LABEL":"Select Product Segment",
    "SELECT_INDUSTRY":"Select Industry",
    "SELECT_INDUSTRY_LABEL":"Select Industry",
    "SOFTWARE_TYPE":"Software Type",
    "SOFTWARE_TYPE_LABEL":"Software Type",
    "DESCRIPTION":"Product Description",
    "DESCRIPTION_LABEL":"Product Description",
    "BACK":'Back',
    "SAVE":'Save',
    "NEXT":'Next',
  }
  */
}
