import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const EmailVerificationOctate = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const SIGN_UP_BANNER = KEYS?.SIGN_UP_SCREEN;
  const navigate = useNavigate();

  const CONSUMER_ACC = props?.consumerAccount;

  const texts = CONSUMER_ACC ? SIGN_UP_BANNER?.BUYER_MESSAGES : SIGN_UP_BANNER?.VENDOR_MESSAGES;
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % texts.length);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, texts.length]);

  return (
    <>
      <div class="splitted-page__aside">
        <div className="signUp-vertical-line"></div>
        <div class="splitted-page__container signup-split-container">
          <div className="signUp-image-container">
            <img
              class="octateImage"
              data-src={require("../../../../assets/images/octateActiveLogo.png")}
              src={require("../../../../assets/images/octateActiveLogo.png")}
              alt={"Octate logo"}
              onClick={() => navigate("/")}
              role="button"
            />
            <p className="subText-signup">
              {CONSUMER_ACC ? SIGN_UP_BANNER?.OCTATE_BANNER_BUYER_HEADER : SIGN_UP_BANNER?.OCTATE_BANNER_VENDOR_HEADER}
            </p>
            {/* <p className="subText-signup">{SIGN_UP_BANNER?.OCTATE_BANNER_TITLE_END}</p> */}
          </div>
          <div style={{ width: "85%" }} key={currentIndex}>
            <>
              <h2 className="banner-branding-header">{texts[currentIndex]?.title}</h2>
              <p className="banner-branding-subheading">{texts[currentIndex]?.subTitle}</p>
            </>
          </div>

          <div>
            {/* <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.DISCOVER_PRODUCTS}</p>
              <FeatherIcon icon="search" size="25" color="#060606" className="signUp-icons" />
            </div>
            <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.FOLLOW_TOPICS}</p>
              <FeatherIcon icon="hash" size="25" color="#060606" className="signUp-icons" />
            </div>
            <div className="signUp-footer">
              <p className="subText-signup">{SIGN_UP_BANNER?.CURATE_BOARDS}</p>
              <FeatherIcon icon="plus-circle" size="25" color="#060606" className="signUp-icons" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationOctate;
