import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import EditProposalBasicDetails from "../../../editProposal/component/editProposalBasicDetails/editProposalBasicDetails";
import * as ADD_PROPOSAL_KEYS from "../../../../config/config";
const BasicInformationHeader = (props) => {
  const [showPortal, setShowPortal] = useState(false);
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const hideEditProposalBasicDetails =
    PROPOSAL_TYPE == "answer" || proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft" || !USER_CAN_EDIT ? true : false;

  return (
    <>
      {showPortal ? (
        <EditProposalBasicDetails
          handleDetails={props?.handleDetails}
          companyErrors={props?.companyErrors}
          proposalDetails={proposalDetails}
          handleClose={() => setShowPortal(false)}
        />
      ) : null}
      <div className="proposal-submit-page-header-collapse-main-wrapper">
        <div className="proposal-submit-page-header-collapse-left-wrapper p-x-sm">
          <FeatherIcon
            onClick={props?.handleToggleSection}
            icon={props?.showSection ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#130F26"}
            // onClick={() => setShowVendorFAQ(!showVendorFAQ)}
          />
          <h3 className="proposal-submit-page-header-title p-x-mini">{props?.headerTitle}</h3>
        </div>
        {props?.hideEdit || hideEditProposalBasicDetails ? null : (
          <div
            onClick={() => setShowPortal(true)}
            className="proposal-submit-page-header-collapse-right-wrapper p-x-sm"
          >
            <FeatherIcon
              icon="edit-3"
              size="20"
              role="button"
              // className="blurb-detail-social"
              color={"#ABAFB1"}
              //   onClick={() => handleEditFAQ(item)}
              //   style={{ marginLeft: "10px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BasicInformationHeader;
