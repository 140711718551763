import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { validateSignInForm } from "../../../../utlis/hof/hof";
import InputFieldNew from "../../signUp/onBoard/components/InputFieldNew";
import PasswordField from "../../signUp/onBoard/components/passwordField";
import _ from "lodash";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useLocalStorage } from "../../../../utlis/customHooks/useLocalStorage";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
import * as SECRET_KEYS from "../../../../config/configKeys";
const SignInForm = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const [workEmail, setWorkEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const SIGN_IN = KEYS?.USER_SIGNIN;
  const ONBOARDING = KEYS?.USER_ONBOARDING;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const signalTrackingSignInObject = {
    Action: SECRET_KEYS.CONFIG.SIGN_IN_COMPETE,
    page: "home page",
    section: "sign in",
  };

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );

  const UserSignIn = useEnCryptPostApi(`/login/userSignIn`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestMutation);

  useEffect(() => {
    if (UserSignIn?.data?.userExist) {
      const signalTrackingPostData = {
        Actor: UserSignIn?.data?.userId,
        SignalCreatedAt: new Date().toString(),
        Action: signalTrackingSignInObject?.Action,
        Item_Type: null,
        Item_Id: null,
        Persona: UserSignIn?.data?.Persona == "Business user" ? "Business user" : "Buyer",
        User_Type: "Known",
        Target: {
          page: "home",
          link: "/",
        },
        Object: {
          section: signalTrackingSignInObject?.section,
          context: "Sign in",
          page: props?.page,
          Persona: UserSignIn?.data?.Persona == "Business user" ? "Business user" : "Buyer",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      setUser({ ...authUser?.data, ...UserSignIn?.data });
      navigate(location.state?.path ? location.state?.path : "/", {
        replace: true,
      });
    } else if (UserSignIn?.data?.Verify_OTP) {
      dispatch(
        tempAuthDetails({
          data: { ...UserSignIn?.data?.data },
          target: true ? "/" : "/join-organization",
          verifyOTP: true,
        })
      );
      navigate("/email-verification", { replace: true });
    } else if (!UserSignIn?.data?.status) {
      setError({
        emailId: UserSignIn?.data?.emailId,
        password: UserSignIn?.data?.password,
      });
    }
  }, [UserSignIn?.data]);

  const handleSubmit = () => {
    const rawData = {
      emailId: workEmail,
      password: password,
      deviceId: authData?.data?.deviceId,
    };
    const validate = validateSignInForm(rawData);

    setError(validate);

    if (_.isEmpty(validate)) UserSignIn?.handleTrigger(rawData);
  };

  const handleForgotPassword = () => {
    dispatch(
      tempAuthDetails({
        data: { ...authData?.data },
        resetPassword: true,
      })
    );
    navigate("/verify-email", { replace: true });
  };
  return (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        <div className="only-mobile">
          <img
            className=".octate-mobile"
            width="150px"
            height="50px"
            style={{ marginRight: "8px" }}
            src={require("../../../../assets/images/octateLogo181527.svg").default}
            alt={"Octate AI logo"}
            onClick={() => navigate("/")}
          />
        </div>
        <h4 className="already-account-text">
          {SIGN_IN?.DONT_HAVE_A_CREATE_ACCOUNT}{" "}
          <a onClick={() => navigate("/sign-up")} role="button" style={{ color: "#1371c3" }}>
            {SIGN_IN?.SIGN_UP}
          </a>
        </h4>
        <div class="user-registration-container">
          <div className="sign-up-page-header-text m-b-sm m-t-md ">{SIGN_IN?.OCTATE_AI}</div>
          {/* <div className="sign-up-page-text m-b-sm">
          Please provide following details
        </div> */}
          <div className="sign-up-page-text m-b-sm">{SIGN_IN?.CREDENTIALS}</div>
          <div className="sign-up-page-text m-b-sm">
            <InputFieldNew
              label={ONBOARDING?.EMAIL_ADDRESS}
              value={workEmail}
              changeText={(val) => setWorkEmail(val)}
              error={error?.emailId}
              icon={"mail"}
              type={"email"}
              required={true}
              maxLength={SECRET_KEYS?.CONFIG?.EMAIL_CHARACTER_LIMIT}
            />
          </div>
          <PasswordField
            label={ONBOARDING?.PASSWORD}
            value={password}
            changeText={(val) => setPassword(val)}
            error={error?.password}
            type={"password"}
            icon={"lock"}
            required={true}
          />
          <div
            style={{
              alignSelf: "end",
              textAlign: "end",
              marginTop: "-10px",
              marginBottom: "15px",
              marginTop: "15px",
              color: "#1371c3",
            }}
            onClick={() => handleForgotPassword()}
            role={"button"}
          >
            {SIGN_IN?.FORGOT_PASSWORD}
          </div>
          {UserSignIn?.isLoading ? (
            <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className="create-account-button" role={"button"} onClick={() => handleSubmit()}>
              {SIGN_IN?.LOGIN}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
