import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import BriefCase from "../../../../../assets/svgIcons/briefCase";
import CategoriesSvg from "../../../../../assets/svgIcons/categoriesSvg";
import HashSvg from "../../../../../assets/svgIcons/hashSvg";
import ProductsSvg from "../../../../../assets/svgIcons/productsSvg";
import ProviderSvg from "../../../../../assets/svgIcons/provider";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { useNavigate } from "react-router";
const ProvidersList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const navigation = useNavigate();
  const TYPE =
    props?.name == "Attributes"
      ? "Attributes"
      : props?.name == "Categories"
      ? "Category"
      : props?.name == "Use Cases"
      ? "Use case"
      : props?.name == "Vendors"
      ? "Vendor"
      : props?.name == "Products"
      ? "Product"
      : "";
  const SignalNames =
    props?.name == "Attributes"
      ? "Attributes"
      : props?.name == "Categories"
      ? "categories page"
      : props?.name == "Use Cases"
      ? "Use-Cases"
      : props?.name == "Vendors"
      ? "vendor page"
      : props?.name == "Products"
      ? "product page"
      : "";
  const signalTrackingSearchObject = {
    Action: SECRET_KEYS.CONFIG.SEARCH_CLICKED,
    page: SignalNames,
    section: "search box",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleSearchClicked = (item) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingSearchObject?.Action,
      Item_Type: TYPE,
      Item_Id: item?.id,
      Target: {
        page: signalTrackingSearchObject?.page,
        link: `/${GetItemType(item)}/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}`,
      },
      Object: {
        section: signalTrackingSearchObject?.section,
        context: item?.name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
    // navigation(`/${GetItemType(item)}/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}${GetItemTab(item)}`);
  };

  const GetItemType = (val) => {
    const TYPE =
      props?.name == "Attributes"
        ? "Attributes"
        : props?.name == "Categories"
        ? "category"
        : props?.name == "Use Cases"
        ? "Use-Cases"
        : props?.name == "Vendors"
        ? "vendor"
        : props?.name == "Products"
        ? `${ReplaceBlankSpaceByHyphen(val?.vendorName)}/product`
        : "";

    return TYPE;
  };
  const GetItemTab = (val) => {
    const TYPE =
      props?.name == "Categories"
        ? `/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
        : props?.name == "Vendors"
        ? `/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`
        : props?.name == "Products"
        ? `/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`
        : "";

    return TYPE;
  };
  return (
    <div className="result-group">
      <div className="header">
        {props?.name == "Attributes" ? (
          <HashSvg />
        ) : props?.name == "Categories" ? (
          <CategoriesSvg />
        ) : props?.name == "Use Cases" ? (
          <BriefCase />
        ) : props?.name == "Vendors" ? (
          <ProviderSvg />
        ) : props?.name == "Products" ? (
          <ProductsSvg />
        ) : null}
        <span>{props?.name}</span>
      </div>
      {props?.list?.map((item) => {
        const itemNameWithHyphen = item?.name.replace(/ /g, "-");
        const inputText = props?.inputText?.replace(/[.*+?^${}()|[\]\\]/g, "") || "";
        const parts = itemNameWithHyphen?.split(new RegExp(`(${inputText})`, "gi"));
        return (
          <div onClick={() => handleSearchClicked(item)}>
            <a
              className="result cursor--pointer"
              href={`/${GetItemType(item)}/${ReplaceBlankSpaceByHyphen(item?.name, item?.id)}${GetItemTab(item)}`}
            >
              <div className="content search-header-dropdown-mapped-items">
                {parts.map((part, i) => (
                  <span
                    key={i}
                    style={{
                      fontWeight: part.toLowerCase() == props?.inputText.toLowerCase() ? "bold" : "normal",
                      textAlign: "start",
                      padding:
                        part[0] == "-"
                          ? "0px 0px 0px 5px"
                          : part[part?.length - 1] == "-"
                          ? "0px 5px 0px 0px"
                          : "0px 0px 0px 0px",
                    }}
                    // className="wrap-text-single-line"
                  >
                    {part?.replaceAll("-", " ")}
                  </span>
                ))}
              </div>
              <i className="feather icon icon-arrow-right "></i>
            </a>
          </div>
        );
      })}
    </div>
  );
};
export default ProvidersList;
