import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import FeatherIcon from "feather-icons-react";
import { GetIdFormLink, ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import * as NO_CONTENT from "../../../../config/config";
import VendorFaq from "../../../businessProfile/components/tabs/components/vendorFaq";

const FaqTab = (props) => {
  const [showProductFAQ, setShowProductFAQ] = useState(true);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const FAQ = KEYS?.FREQUENTLY_ASKED_QUESTION;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const GetApiData = useEnCryptGetApi(
    `/getFrequentlyAskedQuestions?type=product&id=${PRODUCT?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  return GetApiData?.data?.data?.length > 0 ? (
    // <div className="grid-business-profile m-t-sm mobile-1-col">
    <div class="one-column-grid business-profile-teams-container">
      <div class="provider-faq m-b-md">
        <div
          style={{ background: "#f1f3f9", padding: "8px 15px 8px 15px", borderRadius: "4px" }}
          className="flex space-between"
        >
          <h3 style={{ margin: "0px", color: "#45464e;" }}>
            {FAQ?.TITLE} {PRODUCT?.Name}
          </h3>
          <FeatherIcon
            icon={showProductFAQ ? "chevron-down" : "chevron-right"}
            size="20"
            role="button"
            // className="blurb-detail-social"
            color={"#ABAFB1"}
            onClick={() => setShowProductFAQ(!showProductFAQ)}
          />
        </div>
        {showProductFAQ ? (
          <div class="dtrd-faq dtrd-faq--static">
            {GetApiData?.data?.data?.map((item) => {
              return <VendorFaq data={item} />;
            })}
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="search__result data-product-card no-content-card">
      <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_FAQ_PRODUCT}</div>
    </div>
  );
};

export default FaqTab;
