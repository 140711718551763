import React from "react";
var __html = require("./privacyHtml.js");
var template = { __html: __html };

const Privacy = () => {
  return (
    <>
      <div dangerouslySetInnerHTML={template} />
    </>
  );
};

export default Privacy;
