import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
// import * as KEYS from "../../../../../config/config";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../../utlis/customHooks/customHooks";
import VendorCard from "../../../../../utlis/hoc/vendorCard/vendorCard";
import { EncryptId, GetIdFormLink, ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../../../../utlis/hof/hof";
import TabHeader from "../../../../businessProfile/components/tabs/components/tabHeader";
import ProvidersActionButton from "./providersActionButton";
import * as NO_CONTENT from "../../../../../config/config";

function ProviderAlternative(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorObject = {
    Action: `${KEYS?.VENDOR_ALTERNATIVE.MORE} clicked`,
    page: "alternative vendor page",
    section: "related vendors",
  };
  // https://thebox-dev.portqii.com/search_backend/getVendorCompetitorsForVendorPage?vendorName=Cashbook
  // https://thebox-dev.portqii.com/core/getCompetitors?vendorId=1
  const vendorName = GetIdFormLink(props?.vendorName);
  const GetApiData = useEnCryptGetApi(
    `/getCompetitors?vendorId=${vendorName?.Id}&limit=6&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  // https://thebox-dev.portqii.com/core/getCompetitors?vendorId=1
  const allProducts = GetApiData?.data?.data;
  const handleViewAllVendorsLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Item_Type: signalTrackingVendorObject?.page,
      Item_Id: null,
      Action: signalTrackingVendorObject?.Action,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: vendorName?.Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return allProducts?.length && vendorName?.Name ? (
    <div class="provider-profile__main-content-section" id="provider-profile-alternatives">
      {props?.type === "buyer" ? (
        <TabHeader tabIndex={props?.tabIndex} type={props?.type} />
      ) : (
        <>
          <h2>
            {ToTitleCase(vendorName?.Name)} {KEYS?.VENDOR_ALTERNATIVE.TITLE}
          </h2>
          <div class="provider-profile__subtitle">
            {KEYS?.VENDOR_ALTERNATIVE.SUB_TITLE} {vendorName?.Name}.
          </div>
        </>
      )}
      <VendorCard page={props?.page} section={signalTrackingVendorObject?.section} data={allProducts} />

      {allProducts?.length >= 6 ? (
        <div
          onClick={() =>
            handleViewAllVendorsLinkClicked(
              `/${ReplaceBlankSpaceByHyphen(vendorName?.Name)}/alternative-vendors/${EncryptId(vendorName?.Id)}`
            )
          }
        >
          <a
            class="display-block m-t-sm text-center"
            href={`/${ReplaceBlankSpaceByHyphen(vendorName?.Name)}/alternative-vendors/${EncryptId(vendorName?.Id)}`}
          >
            {KEYS?.VENDOR_ALTERNATIVE.MORE}
          </a>
        </div>
      ) : null}
    </div>
  ) : allProducts?.length === 0 ? (
    <div className="search__result data-product-card no-content-card">
      <div className="no-content-text"> {NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_ALTERNATE_VENDOR}</div>
    </div>
  ) : null;
}

export default ProviderAlternative;
