import React from "react";
import { Link } from "react-router-dom";
import NoResult from "../../../components/searchResult/components/noResult";
import "../../../styles/new-cards.css";
import BlurbCardWrapper from "./components/blurbCardWrapper";

const NewBlurbCard = (props) => {
  const VENDOR_DATA = props?.data;
  const cardType = "blurb";
  const permissionsDetails = props?.permissionsDetails;
  return VENDOR_DATA?.length > 0 ? (
    VENDOR_DATA?.map((item, index) => (
      <BlurbCardWrapper permissionsDetails={permissionsDetails} item={item} props={props} index={index} />
    ))
  ) : (
    <NoResult cardType={cardType} page={props?.page} section={props?.section} type={"blurbs"} />
  );
};

export default NewBlurbCard;
