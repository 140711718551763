import React from "react";
import BasicInformation from "../bascInformation/basicInformation";
import * as CONFIG_KEYS from "../../../../config/config";
import Question from "./selectedQuestionComponent/question";
import VendorResponse from "./selectedQuestionComponent/vendorResponse";
import Comments from "./selectedQuestionComponent/comments/comments";
const SelectedQuestion = (props) => {
  const selectedQuestionIndex = props?.selectedQuestionIndex;
  const proposalType = props?.PROPOSAL_TYPE;
  const proposalDetails = props?.proposalDetails;
  const Vendor_Id = props?.Vendor_Id;

  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const sectionLength = proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length;
  const CanEdit = proposalDetails?.[ADD_PROPOSAL_KEYS.CAN_EDIT];
  const questionLength = sectionLength
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION][0]?.[ADD_QUESTION_KEYS?.QUESTION_LIST]?.length
    : false;

  const selectedQuestion = questionLength
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION][selectedQuestionIndex?.sectionIndex]?.[
        ADD_QUESTION_KEYS?.QUESTION_LIST
      ][selectedQuestionIndex?.questionIndex]
    : false;
  const selectedSection = questionLength
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION][selectedQuestionIndex?.sectionIndex]
    : false;
  const handleEditSaveClicked = (val) => {
    let SectionData = [...proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]];
    let QuestionData = [
      ...proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION][selectedQuestionIndex?.sectionIndex]?.[
        ADD_QUESTION_KEYS?.QUESTION_LIST
      ],
    ];

    QuestionData[selectedQuestionIndex?.questionIndex] = val;
    SectionData[selectedQuestionIndex?.sectionIndex] = {
      ...SectionData[selectedQuestionIndex?.sectionIndex],
      [ADD_QUESTION_KEYS?.QUESTION_LIST]: QuestionData,
    };
    props?.handleDetails({
      ...proposalDetails,
      [ADD_PROPOSAL_KEYS?.PROPOSAL_QUESTION]: SectionData,
    });
  };
  return (
    <div style={{ width: "100%", textAlign: "start", padding: "10px 15px", zIndex: 10000000 }}>
      <Question
        CanEdit={CanEdit}
        handleCloseSelectedVendor={props?.handleCloseSelectedVendor}
        proposalType={proposalType}
        selectedQuestion={selectedQuestion}
        handleSaveClicked={handleEditSaveClicked}
      />
      <VendorResponse selectedQuestion={selectedQuestion} />
      <Comments
        notificationJson={props?.notificationJson}
        selectedSection={selectedSection}
        proposalType={proposalType}
        Vendor_Id={Vendor_Id}
        selectedQuestion={selectedQuestion}
        proposalId={props?.proposalId}
      />
    </div>
  );
};
export default SelectedQuestion;
