import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetProposalQuery,
  useGetRequestQuery,
} from "../../../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../../../config/config";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
const ProposalSummary = (props) => {
  const [toggleSummary, setToggleSummary] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const authData = useSelector((state) => state?.authDetails?.details);
  const PROPOSAL_HEADER_KEYS = KEYS?.BUYER_PROPOSAL_LANDING_PAGE;
  const WEEK_DROPDOWN_DATA = API_KEYS?.WEEK_DROPDOWN_DATA;
  const [overallStats, setOverallStats] = useState([]);
  const proposalDetails = props?.proposalDetails;
  const SummaryArray = [
    {
      name: "Status",
      id: 1,
      count: proposalDetails?.[ADD_PROPOSAL_KEYS?.STATUS],
    },
    {
      name: "Proposal Owner",
      id: 1,
      count: proposalDetails?.[ADD_PROPOSAL_KEYS?.USER_NAME]
        ? proposalDetails?.[ADD_PROPOSAL_KEYS?.USER_NAME]
        : "Sanket",
    },
    {
      name: "Created Date",
      id: 1,
      count: proposalDetails?.[ADD_PROPOSAL_KEYS?.CREATED_DATE]
        ? moment(
            proposalDetails?.[ADD_PROPOSAL_KEYS?.CREATED_DATE] * 1000
          ).format("DD MMM yyyy")
        : "Not Selected",
    },
    {
      name: "Vendors",
      id: 1,
      count: proposalDetails?.[ADD_PROPOSAL_KEYS?.VENDORS]?.length
        ? proposalDetails?.[ADD_PROPOSAL_KEYS?.VENDORS]?.length
        : "No Vendors",
    },
  ];
  const getProposalOverallStats = useEnCryptGetApi(
    `/proposalOverallStats?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetProposalQuery
  );
  useEffect(() => {
    if (getProposalOverallStats?.data?.data?.length) {
      setOverallStats(getProposalOverallStats?.data?.data);
    }
  }, [getProposalOverallStats?.data]);

  //   {
  //     "name": "All Proposal",
  //     "id": 1,
  //     "count": 2
  // }
  const handleToggleSummary = (val) => {
    setToggleSummary(!toggleSummary);
  };
  return (
    <div className="proposal-summary-main-wrapper">
      <div
        className={`proposal-summary-header-main-wrapper ${
          toggleSummary ? "p-b-md" : ""
        } `}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            className="octate-logo "
            width="25px"
            height="25px"
            src={require("../../../../assets/images/folderSvgIcon.svg").default}
            alt={"folder image"}
          />
          <h3 className="proposal-summary-edit-question">
            Proposal Highlights
          </h3>
        </div>
        {/* <FeatherIcon
          style={{ cursor: "pointer" }}
          // onClick={handleEditClicked}
          icon={"chevron-up"}
          size="22"
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={handleToggleSummary}
        >
          <span>
            <p className="link-color cursor--pointer">
              {toggleSummary ? "Hide info" : "Show info"}
            </p>
          </span>
          <span>
            <FeatherIcon
              style={{ cursor: "pointer" }}
              className={"link-color m-l-base"}
              // onClick={handleEditClicked}
              icon={"info"}
              size="17"
            />
          </span>
        </div>
      </div>
      {toggleSummary ? (
        <div className="proposal-summary-stats-main-wrapper">
          {SummaryArray?.map((list) => {
            return (
              <div className="proposal-summary-stats-wrapper">
                <div>
                  <p className="proposal-summary-content-stats-text">
                    {list?.[API_KEYS?.PROPOSAL_SUMMARY?.NAME]}
                  </p>
                </div>
                <div>
                  <p
                    className={`proposal-summary-content-stats-count--summary-single-proposal ${
                      list?.[API_KEYS?.PROPOSAL_SUMMARY?.NAME] ==
                      "Proposal Owner"
                        ? "link-color--vendor-name"
                        : ""
                    }`}
                  >
                    {list?.[API_KEYS?.PROPOSAL_SUMMARY?.COUNT]}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ProposalSummary;
