import React from "react";
import { useSelector } from "react-redux";
import ProductsSvg from "../../../../assets/svgIcons/productsSvg";
import ProviderSvg from "../../../../assets/svgIcons/provider";
// import * as KEYS from "../../../../config/config";
import {
  GetIdFormLink,
  ReplaceBlankSpaceByHyphen,
} from "../../../../utlis/hof/hof";
function SearchHeaderNavBarActions(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const PROVIDERS = KEYS?.HEADER_SEARCH_TYPE.PROVIDER;
  const PRODUCTS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PRODUCT_LABEL;
  const PROVIDERS_LABEL = KEYS?.HEADER_SEARCH_TYPE.PROVIDER_LABEL;
  const NAME =
    props?.parent == "searchPage"
      ? props?.params
      : GetIdFormLink(props?.params);
  return (
    <div className="attached-navbar__actions">
      <a
        className={props?.type == PRODUCTS ? "active" : ""}
        aria-current="page"
        href={`${
          props?.parent == "searchPage"
            ? NAME
              ? `/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(NAME)}`
              : `/search/${PRODUCTS}`
            : `/category/${ReplaceBlankSpaceByHyphen(NAME?.Name, NAME?.Id)}`
        }`}
      >
        <div className="m-r-base inline-flex">
          <ProductsSvg />
        </div>
        {PRODUCTS_LABEL}
      </a>
      <a
        className={props?.type == PROVIDERS ? "active" : ""}
        href={`${
          props?.parent == "searchPage"
            ? NAME
              ? `/search/${PROVIDERS}/${ReplaceBlankSpaceByHyphen(NAME)}`
              : `/search/${PROVIDERS}`
            : `/category/${ReplaceBlankSpaceByHyphen(
                NAME?.Name,
                NAME?.Id
              )}/${PROVIDERS}`
        }`}
      >
        <div className="m-r-base inline-flex">
          <ProviderSvg />
        </div>
        {PROVIDERS_LABEL}
      </a>
    </div>
  );
}

export default SearchHeaderNavBarActions;
