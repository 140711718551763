import React, { useState } from "react";
import { useEffect } from "react";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import RoundSpinner from "../../../../utlis/spinner/roundSpinner";

const CompleteStepModal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const DISPLAY_DATA = props?.displayText;
  const navigatePath = props?.navigatePath;
  useEffect(() => {
    if (DISPLAY_DATA) {
      setTimeout(() => {
        window.location.replace(navigatePath);
      }, 5000);
    }
  }, [DISPLAY_DATA]);

  return ReactDom.createPortal(
    <>
      <div class="ui dimmer modals page transition visible active portalModal" id="deactivate_modal">
        <div
          class="ui modal modal--left-aligned front transition visible active"
          style={{ height: "150px", padding: "10px", textAlign: "center" }}
        >
          <div className="join-org-modal">{DISPLAY_DATA ? <h4>{DISPLAY_DATA}</h4> : <RoundSpinner />}</div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default CompleteStepModal;
