import React, { useEffect } from "react";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import ProposalHeader from "./component/proposalHeader/proposalHeader";
import ProposalSummary from "./component/proposalSummary/proposalSummary";
import ProposalTable from "./component/proposalTable/proposalTable";
import { updateMetaTags } from "../../../src/components/SEO/seo";

const SharedProposals = (props) => {
  const BREAD_CRUMB_BOARDS = [
    { name: "Home", active: true, link: "/" },
    { name: "Manage Proposal", active: true, link: "/manage-proposals" },
    { name: "Shared Proposals", active: false },
  ];
  useEffect(() => {
    const seoBody = {
      type: "proposals",
    };
    updateMetaTags(seoBody);
  }, []);
  return (
    <div id="app" class="data-products__show">
      <Header page={props?.page} show={true} />
      <div className="">
        <div className="attached-navbar m-b-md">
          <div className="p-x-md flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb
              page={props?.page}
              parent={"productPage"}
              type={"Product"}
              // params={"Proposal"}
              data={BREAD_CRUMB_BOARDS}
            />
          </div>
        </div>
        {/* <ProposalHeader />
        <ProposalSummary /> */}
        <ProposalTable />
      </div>
    </div>
  );
};

export default SharedProposals;
