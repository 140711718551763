import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import * as CONFIG_KEYS from "../../../config/config";
import SignInModal from "../signInModal/signInModal";
import BoardIcon from "./components/boardIcon";
import BookmarkIcon from "./components/bookmarkIcon";
import CommentIcon from "./components/commentIcon";
import LikeIcon from "./components/likeIcon";
import ShareIcon from "./components/shareIcon";
import ViewMore from "./components/viewMore";
import { isMobileOnly } from "react-device-detect";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const EngagementBar = (props) => {
  // const [viewComments, setViewComments] = useState(false);
  const [shouldDisplayMessage, setShouldDisplayMessage] = useState(false);
  const PERMISSIONS_KEYS = CONFIG_KEYS.CONFIG.PERMISSION_RESOURCE_KEYS;
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const BoardPermissions = allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS];
  const CanUpdateBoard =
    allPermissionsDetails?.[PERMISSIONS_KEYS.BOARDS] && props?.page == "business profile" ? BoardPermissions?.Update : true;
  const cardType = props?.cardType;
  const tabIndex = props?.tabIndex;
  const permissionsDetails = props?.permissionsDetails;
  const DATA = props?.data;
  const CreatedAt = DATA?.Created_At ? moment(DATA?.Created_At * 1000).format("DD MMM YYYY hh:mm ") : "";
  const UpdatedAt = DATA?.Modify_At ? moment(DATA?.Modify_At * 1000).format("DD MMM YYYY hh:mm ") : CreatedAt ? CreatedAt : "";

  useEffect(() => {
    // Check if the cookie is present
    const cookieString = document.cookie;
    const cookieValue = cookieString
      ?.split("; ")
      ?.find((row) => row.startsWith("addToBoardCookie"))
      ?.split("=")[1];
    if (cookieValue) setShouldDisplayMessage(false);
    else setShouldDisplayMessage(true);
  }, []);

  const handleAcceptCookie = () => {
    // Set the cookie to expire in 1 day
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    document.cookie = `addToBoardCookie=messageShowed; expires=${expirationDate.toUTCString()}; path=/`;
    setShouldDisplayMessage(false);
  };
  return (
    <>
      {permissionsDetails?.Read && DATA?.Modified_User ? (
        <div className="p-b-mini p-r">
          <h3 className="created-by-on-each-card">{`Last updated by ${DATA?.Modified_User} at ${UpdatedAt}`}</h3>
          <div className="created-by-modal-main-wrapper">
            {DATA?.Created_User ? <h3 className="created-by-on-each-card">{`Created by: ${DATA?.Created_User}`}</h3> : null}
            {CreatedAt ? <h3 className="created-by-on-each-card">{`Created at: ${CreatedAt}`}</h3> : null}
            {DATA?.Modified_User ? <h3 className="created-by-on-each-card">{`Updated by: ${DATA?.Modified_User}`}</h3> : null}
            {UpdatedAt ? <h3 className="created-by-on-each-card">{`Updated at: ${UpdatedAt}`}</h3> : null}
          </div>
        </div>
      ) : null}

      <div className="engagement-bar-container p-r">
        {props?.cardType === "vendor" ? null : (
          <>
            <CommentIcon
              cardType={cardType}
              handleViewComments={() =>
                authData?.data?.Permissions?.Comment ? props?.handleViewComments() : setSignInModalVisible(!signInModalVisible)
              }
              page={props?.page}
              data={props?.data}
              commentCount={props?.commentCount}
              commentDisable={props?.commentDisable}
            />
            <LikeIcon
              cardType={cardType}
              data={props?.data}
              page={props?.page}
              handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
              keyName={props?.keyName}
            />
          </>
        )}

        <BookmarkIcon
          handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
          cardType={cardType}
          data={props?.data}
          page={props?.page}
          engageSource={props?.engageSource}
          keyName={props?.keyName}
        />
        <ShareIcon
          shareLink={props?.shareLink}
          cardType={props?.cardType}
          data={props?.data}
          page={props?.page}
          engageSource={props?.engageSource}
          keyName={props?.keyName}
        />
        {CanUpdateBoard ? (
          <BoardIcon
            cardType={cardType}
            data={props?.data}
            page={props?.page}
            keyName={props?.keyName}
            handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
            engageSource={props?.engageSource}
          />
        ) : null}

        {props?.page?.includes("business profile") || permissionsDetails?.Delete || props?.page?.includes("vendor page") ? (
          <ViewMore
            permissionsDetails={permissionsDetails}
            tabIndex={tabIndex}
            cardType={cardType}
            canDelete={props?.canDelete}
            handleSignInModalVisible={() => setSignInModalVisible(!signInModalVisible)}
            data={props?.data}
            page={props?.page}
            engageSource={props?.engageSource}
          />
        ) : null}
        {shouldDisplayMessage && props?.page === "blurb detail page" && false ? (
          <div className="blurb-detail-page-engagement-popup">
            <p>Liked this blurb? Add to a board and share with your team mates.</p>
            <p className="okay-pop-up-text" onClick={() => handleAcceptCookie()}>
              Got it!
            </p>
          </div>
        ) : null}
      </div>
      {/* {viewComments ? <CommentList /> : null} */}
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible(false)} /> : null}
    </>
  );
};

export default EngagementBar;
