import React from "react";
import { useSelector } from "react-redux";
import BlurbTags from "./blurbTags";
import * as ENV_KEYS from "../../../../config/configKeys";
import parse from "html-react-parser";

const BlurbDescription = (props) => {
  const BLURB_DATA = props?.data;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const blurbImage = BLURB_DATA?.Media?.valid
    ? BLURB_DATA?.Media?.SRC
    : BLURB_DATA?.image_src
    ? BLURB_DATA?.image_src
    : BLURB_DATA?.account_logo
    ? BLURB_DATA?.account_logo
    : KEYS?.CORRUPT_IMAGE_ICON;
  return (
    <div class="square">
      <img
        class="blurb-detail-image-floating"
        data-src={
          blurbImage ? blurbImage : BLURB_DATA?.account_logo ? BLURB_DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON
        }
        src={blurbImage ? blurbImage : BLURB_DATA?.account_logo ? BLURB_DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
        }}
        alt={`${BLURB_DATA?.title_level_1} ${ENV_KEYS?.CONFIG?.BLURB_IMAGE}`}
      />

      <div>
        {BLURB_DATA?.description ? <p className="blurb-detail-description">{parse(BLURB_DATA?.description)}</p> : null}
        <BlurbTags data={BLURB_DATA} page={props?.page} />
      </div>
    </div>
  );
};

export default BlurbDescription;
