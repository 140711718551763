import React from "react";

function SearchFilter(props) {
  const handleTextChange = (e) => {
    props?.handleTextChange(e?.target?.value);
  };
  return (
    <div class="field item-search-filters__filter" id="field-keywords">
      <div class="icon-wrapper icon-wrapper--left">
        <input
          placeholder="Filter by keywords..."
          icon_left="search"
          class="item-search-filters__filter--search"
          id="keywords"
          type="text"
          value={props?.filterText}
          onChange={(e) => handleTextChange(e)}
        />
        <i class="feather icon icon-search icon--left m-r-none"></i>
      </div>
    </div>
  );
}

export default SearchFilter;
