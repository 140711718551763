import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import PricingDetails from "./component/pricingDetails/pricingDetails";
import PricingHighlights from "./component/pricingHighlights/pricingHighlights";
// import * as KEYS from "../../../../config/config";

function Pricing(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const productName = GetIdFormLink(props?.productName)?.Name;
  const productId = GetIdFormLink(props?.productName)?.Id;
  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getVendorBlurbsForVendorPage?vendorName=Cashbook
    `/getProductPricing?product_ID=${7716}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  return GetApiData?.data?.data ? (
    <section class="content-section">
      <h2 class="header">{KEYS?.PRODUCT_PAGE.PRICING.PRICING_HEADER}</h2>
      <div>
        <div class="product-content__pricing">
          <PricingHighlights
            productName={props?.productName}
            data={GetApiData}
          />
          {/* <PricingDetails data={StaticData?.details} /> */}
        </div>
      </div>
    </section>
  ) : null;
}

export default Pricing;
