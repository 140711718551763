import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import ExploreCategory from "../home/header/component/exploreCategories/exploreCategories";
import PostYourData from "../home/header/component/postYourData/postYourData";
import ListYourData from "../home/header/component/listYourData/listYourData";
import NotificationHeader from "./component/notificationHeader";
import NotificationList from "./component/notificationList";
import { useSelector } from "react-redux";
import { useGetNotificationQuery, useGetRequestQuery } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import NotificationDrawer from "../home/header/component/headerDrawer/notificationDrawer";
const Notifications = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const authData = useSelector((state) => state?.authDetails?.details);
  const socket = useSelector((state) => state?.homeDetails?.socket);
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  const userId = authData?.data?.userId;
  const [count, setCount] = useState(0);
  const handleSideBarClicked = (val) => {
    setShowDrawer(!showDrawer);
  };
  // const handleNotificationRed = () => {
  //   setTimeout(() => {
  //     setShowDrawer(!showDrawer);
  //   }, 200);
  // };
  // getUnreadNotificaiton
  // const GetProductDataFromApi = useEnCryptGetApi(
  //   `/getUnreadNotification?user_Id=${userId}`,
  //   "notifications",
  //   useGetNotificationQuery,
  //   userId ? false : true
  // );
  // ("http://thebox-dev.portqii.com/",{path:"/notifications/socket.io"});
  // function handler(data) {
  //   setCount(JSON.parse(data)?.count ? JSON.parse(data)?.count : 0);
  // }
  // useEffect(() => {
  //   if (socket?.connected) {
  //     socket.on(userId, handler);
  //     return () => socket.off(userId, handler);
  //   }
  // }, [socket]);

  return authData?.data?.userExist ? (
    <div className="">
      <div
        onClick={handleSideBarClicked}
        className="navbar__item navbar__item navbar__sub-menu-toggle navbar__mega-menu p-r"
      >
        {count ? (
          <div className="notification-count-main-wrapper">
            <p className="notification-count-count-text">{count}</p>
          </div>
        ) : null}
        <FeatherIcon icon="bell" size="25" className="notification_icon_color" />
      </div>
      {/* {showDrawer ? (
        <nav
          className={`${
            showDrawer
              ? "navbar__mobile-menu--notification slide-in--on--notification"
              : "navbar__mobile-menu--notification "
          }`}
        >
          <NotificationHeader handleSideBarClicked={handleSideBarClicked} />
          <NotificationList
            count={count}
            handleNotificationRed={handleNotificationRed}
            Data={GetProductDataFromApi?.data?.data}
            isLoading={GetProductDataFromApi?.isLoading}
          />
        </nav>
      ) : null} */}
      {showDrawer ? <NotificationDrawer handleClose={() => setShowDrawer(false)} visible={showDrawer} /> : null}

      <div className="slide-in-overlay"></div>
    </div>
  ) : null;
};

export default Notifications;
