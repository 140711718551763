import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import ProductsCards from "../../../../utlis/hoc/productCardSmall/productsCards";
// import * as KEYS from "../../../../config/config";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
function RelatedProducts(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const productName = GetIdFormLink(props?.productName)?.Name;
  const productId = props?.productId?.Id;
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  // https://thebox-dev.portqii.com/search_backend/getVendorProductsForVendorPage?vendorName=Cashbook
  // https://thebox-dev.portqii.com/search_backend/getProductsRelatedToVendorProduct?productId=7087&limit=6
  const GetApiData = useEnCryptGetApi(
    `/getProductsRelatedToVendorProduct?productId=${productId}&limit=6&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const allProducts = GetApiData?.data?.data;
  return allProducts?.length ? (
    <div className="content-section">
      <h2 className="header">{KEYS?.RELATED_PRODUCT.TITLE}</h2>
      <div className="grid-two-columns tablet-1-col">
        {allProducts?.length
          ? allProducts.map((products) => {
              return (
                <ProductsCards
                  page={props?.page}
                  section={"similar products"}
                  allProducts={products}
                  parent={props?.parent}
                  type={"RelatedProducts"}
                />
              );
            })
          : null}
      </div>
    </div>
  ) : null;
}

export default RelatedProducts;
