import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useAddCommentsApiMutation, usePostRequestMutation } from "../../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../../utlis/customHooks/customHooks";
import * as CONFIG_KEYS from "../../../../../../config/config";
import * as SECRET_KEYS from "../../../../../../config/configKeys";
const CommentFooter = (props) => {
  const [commentText, setCommentText] = useState("");
  const PROPOSAL_COMMENT_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL_COMMENTS_KEYS;
  const ADD_SECTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_SECTION;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_QUESTION_KEYS = CONFIG_KEYS?.CONFIG?.ADD_QUESTION;
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const userId = authData?.data?.userId;
  const replyData = props?.replyData ? props?.replyData : null;
  const textAreaRef = useRef(null);
  const proposalId = props?.proposalId;
  const proposalType = props?.proposalType;
  const selectedQuestion = props?.QUESTION_ID;
  const Vendor_Id = props?.Vendor_Id;
  const selectedSection = props?.selectedSection;
  const handleChangeText = (e) => {
    setCommentText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  const vendorPostData = useEnCryptPostApi(
    `/proposal/comment`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useAddCommentsApiMutation
  );
  useEffect(() => {
    // Comments_ID
    if (vendorPostData?.data?.Comments_ID) {
      const Action =
        proposalType == "answer" ? SECRET_KEYS.CONFIG.VENDOR_COMMENTED : SECRET_KEYS.CONFIG.BUYER_COMMENTED;
      const Target = {
        Id: proposalType == "answer" ? [Vendor_Id] : [Vendor_Id],
        type: proposalType == "answer" ? "Buyer" : "Vendor",
        page: "proposal page",
        link: proposalType == "answer" ? "/proposals" : "/sales-hub/proposals",
      };
      const Object = {
        Id: proposalId,
        type: "Proposal",
        [ADD_PROPOSAL_KEYS?.VENDOR_ID]: [Vendor_Id],
        [ADD_QUESTION_KEYS?.QUESTION_ID]: [selectedQuestion],
        [ADD_SECTION_KEYS?.SECTION_ID]: [selectedSection?.[ADD_SECTION_KEYS?.SECTION_ID]],
        section: null,
        Comments_ID: vendorPostData?.data?.Comments_ID,
        context: proposalType == "answer" ? " Vendor Commented" : "Buyer Commented",
        page: proposalType == "answer" ? "vendor proposal page" : "buyer proposal page",
      };
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        SignalCreatedAt: new Date().getTime() / 1000,
        Action: Action,
        Comments_ID: vendorPostData?.data?.Comments_ID,
        Target: Target,
        Object: Object,
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [vendorPostData?.data]);
  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 10)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
      if (commentText) {
        handleSendClicked();
      }
    }
    //Here you can even write the logic to select the value from the drop down or something.
  };
  const handleSendClicked = (val) => {
    //   // /proposal/comment
    if (commentText) {
      const commentRawObj = {
        User_ID: userId,
        Comment: commentText,
        [PROPOSAL_COMMENT_KEYS?.VENDOR_ID]: proposalType == "answer" ? Vendor_Id : null,
        ID: `${Vendor_Id}_${selectedQuestion}`,
        Comment_Type: "Proposal",
        // Delete_Status: 1,
        Comment_Date_Time: new Date().getTime() / 1000,
      };
      vendorPostData?.handleTrigger(
        replyData
          ? {
              ...commentRawObj,
              [PROPOSAL_COMMENT_KEYS?.PARENT_COMMENT_ID]: replyData?.[PROPOSAL_COMMENT_KEYS?.COMMENT_ID],
            }
          : commentRawObj
      );
      props?.handleSendClicked(
        replyData
          ? {
              ...commentRawObj,
              [PROPOSAL_COMMENT_KEYS?.PARENT_COMMENT_ID]: replyData?.[PROPOSAL_COMMENT_KEYS?.COMMENT_ID],
              [PROPOSAL_COMMENT_KEYS?.REPLY]: replyData,
            }
          : commentRawObj
      );

      props?.removeReply();
      setCommentText("");
    }
  };
  return (
    <div className="proposal-comments-comment-footer-wrapper--with-reply-wrapper">
      {replyData ? (
        <div className="proposal-comments-comment-reply-wrapper p-r">
          <div className="proposal-comments-comment-list-comment-more-options-container">
            <FeatherIcon style={{ cursor: "pointer" }} onClick={props?.removeReply} icon={"x"} size="17" />
          </div>
          <p className={`proposal-comment-reply-text`}>{replyData?.[PROPOSAL_COMMENT_KEYS?.COMMENT]}</p>
        </div>
      ) : null}
      <div className="proposal-comments-comment-footer-wrapper">
        <div className="proposal-comments-comment-footer-text-input-main-wrapper m-x-mini">
          <textarea
            ref={textAreaRef}
            // style={{
            //   height: `${textAreaHeight ? textAreaHeight : 10}px`,
            // }}
            onKeyDown={onKeyDown}
            rows={1}
            id="note"
            type={"text"}
            value={commentText}
            onInput={handleInput}
            onChange={(e) => handleChangeText(e)}
            placeholder="Your message"
            // className="comment-input-style"
          />
        </div>
        <div
          data-inverted="true"
          data-position="left center"
          // role="button"
          data-tooltip={!commentText ? "Comments cannot be empty" : null}
          onClick={handleSendClicked}
          className={`proposal-comments-comment-footer-action-button-main-wrapper ${
            commentText ? "cursor--pointer" : "cursor--disable"
          }`}
        >
          <p className="proposal-comments-comment-footer-action-button-text">Send</p>
          <FeatherIcon icon={"send"} size="17" color={"#080808"} />
        </div>
      </div>
    </div>
  );
};

export default CommentFooter;
