import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useEnCryptGetApi,
  useEnCryptPostApi,
  useEnCryptSignalTrackingApi,
} from "../../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import ChangeRoles from "./changeRoles";
import { isMobile } from "react-device-detect";
import ChooseLicense from "./chooseLicense";
import LoadingSpinner from "../../../../../utlis/spinner/loadingSpinner";
import RoundSpinner from "../../../../../utlis/spinner/roundSpinner";
import { useGetRequestCrudQuery, usePostRequestCrudMutation } from "../../../../../adapters/xhr/crudServiceApi";
import * as ENV_KEYS from "../../../../../config/configKeys";
const InviteUser = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [emailAddress, setEmailAddress] = useState("");
  const [userPermissions, setUserPermissions] = useState([]);
  const [error, setError] = useState({});
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [ChooseLicenseVisible, setChooseLicenseVisible] = useState(false);

  const InviteUserPostRequest = useEnCryptPostApi(
    `/sendInvite`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestCrudMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const CheckLicense = useEnCryptGetApi(
    `/noOfLicenseExistIfVendor?Vendor_ID=${authData?.data?.vendorId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );
  function handleApplyClicked(val) {
    setUserPermissions(val);
  }
  const handleSendInvite = () => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (CheckLicense?.data?.data?.No_Of_License > 0) {
      if (!emailRegex.test(emailAddress)) {
        setError({ emailAddress: "Please enter valid email address" });
      } else if (!userPermissions?.Permission_Set_ID) {
        setError({ permissions: "Please select a role before inviting" });
      } else {
        setError((prev) => {});
        const RAW_DATA = {
          Invited_By: authData?.data?.userId,
          Email_Address: emailAddress,
          Vendor_Name: authData?.data?.vendorName,
          Vendor_ID: authData?.data?.vendorId,
          senderEmailAddress: authData?.data?.emailId,
          Permission_Set_ID: userPermissions?.Permission_Set_ID,
        };
        InviteUserPostRequest?.handleTrigger(RAW_DATA);
      }
    } else {
      setChooseLicenseVisible((prev) => !prev);
    }
  };

  useEffect(() => {
    if (InviteUserPostRequest?.data?.Status) {
      setInviteSuccess((prev) => !prev);
      setError({});
      setEmailAddress("");
      setTimeout(() => {
        setInviteSuccess((prev) => !prev);
      }, 1000);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.USER_INVITED,
        Item_Type: null,
        Item_Id: null,
        User_Type: "Known",
        Persona: "Business user",
        Target: {
          page: null,
          link: null,
          id: InviteUserPostRequest?.data?.userId,
          vendorId: authData?.data?.vendorId,
        },
        Object: {
          section: null,
          context: `Vendor invited`,
          page: "/business-profile/teams",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else if (InviteUserPostRequest?.data?.UpdateLicense) {
      setChooseLicenseVisible((prev) => !prev);
      setError({ emailAddress: InviteUserPostRequest?.data?.Message });
    } else {
      setError({ emailAddress: InviteUserPostRequest?.data?.Message });
    }
  }, [InviteUserPostRequest?.data]);
  return (
    <div className="business-profile-teams-container ">
      <h3 className="team-management-title m-b-xs">Add user</h3>
      <div
        className="p-r"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <input
          placeholder="name@example.com"
          className="user-invite-input-field"
          value={emailAddress}
          onChange={(val) => setEmailAddress(val.target.value)}
        />
        <ChangeRoles autoWidth={true} handleApplyClicked={handleApplyClicked} invite={true} />
        {InviteUserPostRequest?.isLoading ? (
          <div style={{ margin: "0px 40px" }}>
            <RoundSpinner />
          </div>
        ) : (
          <button
            className="user-invite-button"
            onClick={() => handleSendInvite()}
            // style={emailAddress?.length ? {} : { filter: "opacity(50%)", cursor: "not-allowed" }}
          >
            Add user
          </button>
        )}
        {error?.emailAddress ? <span class="invite-users-input-field-text-error">{error?.emailAddress}</span> : null}
        {error?.permissions ? <span class="invite-users-input-field-text-error">{error?.permissions}</span> : null}
        {inviteSuccess ? (
          <span class="invite-users-input-field-text-error" style={{ color: "#07ce00" }}>
            User invited successfully
          </span>
        ) : null}
        {ChooseLicenseVisible ? <ChooseLicense handleClose={() => setChooseLicenseVisible((prev) => !prev)} /> : null}
      </div>
    </div>
  );
};

export default InviteUser;
