import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducer/authReducer";
import chatReducer from "../reducer/chatReducer";
import dashboardReducer from "../reducer/dashboardReducer";
import homeReducer from "../reducer/homeReducer";
import notificationReducer from "../reducer/notificationReducer";
import permissionsReducer from "../reducer/permissionsReducer";
// this is the global store which contains the state and the middleware
import { api, apiNew } from "../redux/slice";
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    homeDetails: homeReducer,
    authDetails: authReducer,
    notificationDetails: notificationReducer,
    dashboardDetails: dashboardReducer,
    permissionsDetails: permissionsReducer,
    chatDetails: chatReducer,
    [api.reducerPath]: api.reducer,
    [apiNew.reducerPath]: apiNew.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false,
    }).concat([api.middleware, apiNew.middleware]),
  devTools: true,
});
