import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "../../../../../utlis/hoc/dropdown/dropdown";
import DropdownFilter from "./dropdownFilter";
import SearchFilter from "./searchFilter";

function Filters(props) {
  const [dropdownFilterData, setDropdownFilterData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const REF_DROPDOWN = KEYS?.MULTI_SELECT_DROPDOWN;
  const handleApplyCountryClicked = (val) => {
    setDropdownFilterData(val);
  };
  const handleSelectedDataOnChange = (val) => {
    setDropdownFilterData(val);
  };
  const handleTextChange = (val) => {
    if (val) {
      setFilterText(val);
    } else {
      setFilterText("");
    }
  };
  const handleFilterClicked = () => {
  };
  return (
    <div class="dtrd-form">
      <div class="provider-products__filters">
        <SearchFilter
          handleTextChange={handleTextChange}
          filterText={filterText}
        />
        {/* <DropdownFilter /> */}{" "}
        <Dropdown
          data={REF_DROPDOWN}
          handleApplyClicked={handleApplyCountryClicked}
          label={"Filters by country"}
          handleSelectedDataOnChange={handleSelectedDataOnChange}
        />
        <button
          onClick={handleFilterClicked}
          class="small modern primary button"
        >
          Filter
        </button>
      </div>
    </div>
  );
}

export default Filters;
