import React from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { Link } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import MoreOptionsList from "../../newProductCard/components/moreOptionsList";
import { useState } from "react";
import * as SECRET_KEYS from "../../../../config/configKeys";
import FeedbackForm from "../../globalModals/feedbackForm";
const VendorHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const boardUserId = props?.boardUserId;
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const cardType = props?.cardType;
  const page = props?.page;
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "vendor",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleMenuOptions = () => {
    setFeedbackModalVisible((prev) => !prev);
  };
  return (
    <div className="product-card-header">
      <div className="product-card-header-details">
        {DATA?.Vendor_Logo_URL ? (
          <img
            class="product-card-logo"
            alt={` ${DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
            data-src={DATA?.Vendor_Logo_URL ? DATA?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
            src={DATA?.Vendor_Logo_URL ? DATA?.Vendor_Logo_URL : KEYS?.CORRUPT_IMAGE_ICON}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        ) : (
          <img
            class="product-card-logo"
            alt={` ${DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
            data-src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
            src={require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
          />
        )}

        <div className="product-card-vendor-details">
          <div
            onClick={() =>
              handleVendorNameLinkClicked(
                `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                  KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
                }`
              )
            }
          >
            <a
              href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
              }`}
            >
              <p className="product-card-product-name">
                {DATA?.Vendor_Name}
                {/* <img
                class="vendor-card-verified-logo"
                alt={` ${DATA?.Vendor_Name} thumbnail`}
                data-src={require("../../../../assets/onboardingSvg/verified-red.svg").default}
                src={require("../../../../assets/onboardingSvg/verified-red.svg").default}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                }}
              /> */}
              </p>
            </a>
          </div>

          {/* <Link
            to={`/vendor/${ReplaceBlankSpaceByHyphen(
              DATA?.Vendor_Name,
              DATA?.Vendor_ID
            )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
          > */}
          <p
            className="product-card-vendor-name"
            // onClick={() =>
            //   handleVendorNameLinkClicked(
            //     `/vendor/${ReplaceBlankSpaceByHyphen(
            //       DATA?.Vendor_Name,
            //       DATA?.Vendor_ID
            //     )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`
            //   )
            // }
          >
            {DATA?.Industry} {DATA?.Vendor_HQ_Country && DATA?.Industry ? " - " : ""} {DATA?.Vendor_HQ_Country}
          </p>
          {/* </Link> */}

          {/* <p className="product-card-vendor-name">5K Followers</p> */}
        </div>
      </div>
      <div tabIndex={0} onBlur={handleBlur} className="product-card-header-options p-r">
        {/* <div className="product-card-follow-button">
          <i class="feather icon icon-plus"></i>
          <p className="product-card-follow-button-text">Follow</p>
        </div> */}
        <FeatherIcon
          onClick={() => setToggleMenu(!toggleMenu)}
          icon="chevron-down"
          size="20"
          className="product-card-more-options cursor--pointer"
        />
        {toggleMenu && (
          <div className="view-more-container-all-cards-header  modern-card modern-card--elevated">
            <MoreOptionsList
              keyName={props?.keyName}
              data={DATA}
              cardType={cardType}
              page={page}
              handleToggleMenu={handleToggleMenu}
              boardUserId={boardUserId}
              handleMenuOptions={handleMenuOptions}
            />
          </div>
        )}
        {feedbackModalVisible ? (
          <FeedbackForm
            handleClose={() => setFeedbackModalVisible((prev) => !prev)}
            cardType={cardType}
            keyName={props?.keyName}
            data={DATA}
          />
        ) : null}
      </div>
    </div>
  );
};

export default VendorHeader;
