import React from "react";
import { useState } from "react";
import parse from "html-react-parser";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="business-profile-description">
      {isReadMore && text?.length > 280 ? parse(text?.slice(0, 280)) : parse(text)}
      {text?.length > 280 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...show more" : " show less"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
