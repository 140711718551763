import React, { memo, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import AddToBoard from "../../Boards/addToBoard";

const BoardIcon = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  const page = props?.page;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [EngagementBarData, setEngagementBarData] = useState({ ...props?.data });
  const USER_ID = authData?.data?.userId;
  const [modalVisible, setModalVisible] = useState(false);
  const keyName = props?.keyName;
  const cardType = props?.cardType;
  useEffect(() => {
    setEngagementBarData(props?.data);
  }, [props?.data]);
  function handleBoardIconClicked() {
    // if (authData?.data?.Permissions?.Pin) {
    setModalVisible((prev) => !prev);
    // } else {
    //   props?.handleSignInModalVisible();
    // }
  }
  const handleClose = (val) => {
    setModalVisible(false);
    if (val == "item-added") {
      setEngagementBarData((prev) => ({
        ...prev,
        Board_Count: EngagementBarData?.Board_Count ? EngagementBarData?.Board_Count + 1 : 1,
      }));
    }
  };
  return (
    <>
      <div
        className="engagement-bar-items"
        role="button"
        onClick={handleBoardIconClicked}
        data-tooltip={"Add to board"}
        data-inverted="true"
      >
        <FeatherIcon icon="plus-square" size={16} color={"#323232"} />
        <p className="engagement-bar-items-count">
          {EngagementBarData?.Board_Count ? EngagementBarData?.Board_Count : null}
        </p>
      </div>
      {modalVisible ? (
        <AddToBoard page={page} data={props?.data} keyName={keyName} cardType={cardType} handleClose={handleClose} />
      ) : null}
    </>
  );
};

export default memo(BoardIcon);
