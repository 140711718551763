import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import AddFaq from "../../../../addFaq/addFaq";
import AddBlurb from "../../../../addBlurb/addBlurb";
import AddProduct from "../../../../addProduct/addProduct";
import AddUseCases from "../../../../addUseCases/addUseCase";
import { useParams } from "react-router";

const AddBlurbOrProductPortal = (props) => {
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        // style={{
        //   width: "100dvw",
        //   height: "100dvh",
        //   position: "absolute",
        //   top: "0px",
        // }}
      >
        {/* <div className="ui longer modal--max modal modal--left-aligned"> */}
        {tabIndex == "faq" ? (
          <AddFaq editProduct={props?.editProduct} handleClose={() => props?.handleClose()} data={props?.data} />
        ) : tabIndex == "blurbs" ? (
          <AddBlurb
            editProduct={props?.editProduct}
            handleClose={() => props?.handleClose()}
            Id={props?.Id}
          />
        ) : tabIndex == "products" ? (
          <AddProduct
            editProduct={props?.editProduct}
            handleClose={() => props?.handleClose()}
            Id={props?.Id}
          />
        ) : tabIndex == "use-cases" ? (
          <AddUseCases
            editProduct={props?.editProduct}
            handleClose={() => props?.handleClose()}
            Id={props?.Id}
          />
        ) : null}
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")
  );
};

export default AddBlurbOrProductPortal;
