import React from "react";
import * as CONFIG_KEYS from "../../../../config/config";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import "react-datepicker/dist/react-datepicker.css";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import moment from "moment";
const TimelineAndLicense = (props) => {
  const proposalDetails = props?.proposalDetails;
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const hideTimeLine = PROPOSAL_TYPE == "answer" && !proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE];
  const hideLicense = PROPOSAL_TYPE == "answer" && !proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE];
  // || PROPOSAL_TYPE == "question"
  const hideAddSection =
    PROPOSAL_TYPE == "answer" || proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.STATUS] !== "Draft" || !USER_CAN_EDIT
      ? true
      : false;
  return (
    <div className="proposal-submit-timeline-and-vendors-content-main-wrapper">
      <h3 className="proposal-submit-timeline-and-vendors-content-title">
        Provide usage & timeline related information{" "}
      </h3>
      {hideTimeLine ? null : (
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE] ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {/* {ADD_PROPOSAL?.PROPOSAL_TITLE} */}
              Select Your Timeline
            </label>
          ) : null}
          {hideAddSection ? (
            <div className="date-field-wrapper-position-relative">
              <FeatherIcon
                icon={"calendar"}
                size="17"
                color={"#6E7079"}
                style={{ zIndex: 20, margin: "0px 0px 5px 0px" }}
                className="input-icons"
              />
              <DatePicker
                disabled
                placeholderText={"Select your Timeline"}
                selected={
                  proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                    ? proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                    : false
                }
                excludeDateIntervals={[
                  {
                    start: new Date(new Date().setFullYear(new Date().getFullYear() - 50)),
                    end: new Date(),
                  },
                ]}
                onChange={(val) =>
                  props?.handleDetails({
                    ...proposalDetails,
                    [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]: val,
                  })
                } //only when value has changed
              />
            </div>
          ) : (
            <div className="date-field-wrapper-position-relative">
              <FeatherIcon
                icon={"calendar"}
                size="17"
                color={"#6E7079"}
                style={{ zIndex: 20, margin: "0px 0px 5px 0px" }}
                className="input-icons"
              />
              <DatePicker
                placeholderText={"Select your Timeline"}
                selected={
                  proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                    ? proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]
                    : false
                }
                excludeDateIntervals={[
                  {
                    start: new Date(new Date().setFullYear(new Date().getFullYear() - 50)),
                    end: new Date(),
                  },
                ]}
                onChange={(val) =>
                  props?.handleDetails({
                    ...proposalDetails,
                    [ADD_PRODUCT_CONFIG_KEYS?.PROPOSAL_TIMELINE]: val,
                  })
                } //only when value has changed
              />
            </div>
          )}
        </div>
      )}

      {hideLicense ? null : (
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE] ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {"Number of Licenses"}
            </label>
          ) : null}
          <InputFieldProfile
            removeBottomPadding={true}
            label={hideAddSection ? "Not Selected" : "Number of Licenses"}
            type={"number"}
            disable={hideAddSection}
            value={proposalDetails?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]}
            changeText={(val) =>
              props?.handleDetails({
                ...proposalDetails,
                [ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]: val > 0 ? val : "",
              })
            }
            //   error={error?.[ADD_PRODUCT_CONFIG_KEYS?.NUMBER_OF_LICENSE]}
          />
        </div>
      )}
    </div>
  );
};

export default TimelineAndLicense;
