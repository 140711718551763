import React from "react";

function Star() {
  return (
    <div class="vertical-product-card__fact-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4627 4.08099C11.6834 3.63706 12.3166 3.63706 12.5373 4.08099L14.6411 8.31361C14.7287 8.48994 14.8973 8.61204 15.0922 8.64033L19.7908 9.32237C20.284 9.39397 20.48 10.0009 20.1218 10.3474L16.7281 13.63C16.5854 13.7681 16.5202 13.9678 16.554 14.1634L17.3555 18.8044C17.4401 19.294 16.9269 19.6683 16.4865 19.4383L12.2778 17.2402C12.1037 17.1493 11.8963 17.1493 11.7222 17.2402L7.5135 19.4383C7.07308 19.6683 6.55993 19.294 6.64449 18.8044L7.44599 14.1634C7.47978 13.9678 7.4146 13.7681 7.2719 13.63L3.87824 10.3474C3.52 10.0009 3.71596 9.39397 4.2092 9.32237L8.90784 8.64033C9.10271 8.61204 9.2713 8.48994 9.35894 8.31361L11.4627 4.08099Z"
          fill="#1371C3"
        ></path>
      </svg>
    </div>
  );
}

export default Star;
