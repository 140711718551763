import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";

const CategoryFilterDropdownList = (props) => {
  const [listData, setListData] = useState(props?.data);
  const [filteredData, setFilteredData] = useState(props?.data);
  const NAME = props?.NAME ? props?.NAME : "name";
  const handleFilteredData = (e) => {
    const INPUT = e?.target?.value;
    if (INPUT?.length) {
      const rawData = listData?.length
        ? listData?.filter((item) => {
            return item?.[NAME]?.toLowerCase()?.includes(INPUT?.toLowerCase());
          })
        : [];
      setFilteredData(rawData);
    } else {
      const remainingItems = [
        ...listData.filter((item) => item.isSelected),
        ...listData.filter((item) => !item.isSelected),
      ];
      setFilteredData(remainingItems);
    }
  };
  const handleData = (val) => {
    let newData = [...listData];
    const data = newData.map((item) =>
      item?.[NAME] === val?.[NAME] ? { ...val, isSelected: !val?.isSelected } : item
    );
    setListData(data);
    setFilteredData(data);
  };

  const handleApplyChanges = () => {
    // const DATA = listData.filter((item) => item.isSelected);
    props?.handleApplyChanges(listData);
  };

  return (
    <div style={{ textAlign: "start" }} className="filter-search-container">
      <div className="filter-search-input-container">
        <FeatherIcon icon="search" size="20" color={"#130F26"} className="filter-input-field-search-icon" />
        <input
          className="filter-search-input"
          placeholder={`Search ${props?.keyName}`}
          onChange={(e) => handleFilteredData(e)}
          autoFocus={true}
        />
        <FeatherIcon
          icon={"x"}
          size={20}
          color={"#000000"}
          // className="m-l-xs"
          role="button"
          onClick={() => props?.handleToggleFilter()}
          className="filter-input-field-close-icon"
        />
      </div>
      <div className="filter-search-results-container">
        {filteredData?.length ? (
          <>
            <div className="filter-search-results-scroll">
              {filteredData?.map((item, index) => {
                return (
                  <div className="filter-list-items" key={item?.[NAME]}>
                    <input
                      type="checkbox"
                      name="standard_attribute"
                      id={item?.[NAME]}
                      value={item?.[NAME]}
                      checked={item?.isSelected}
                      onChange={() => handleData(item, index)}
                      className="filter-checkbox"
                      role={"button"}
                    />
                    <span
                      className={`filter-list-items-text ${item?.isSelected ? `filter-list-items-text-selected` : ""}`}
                    >
                      {`${item?.[NAME]} (${item?.Total_Count})`}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex align-items-center justify-flex-end m-t-mini">
              <div className="filter-search-cancel-button" role={"button"} onClick={() => props?.handleToggleFilter()}>
                Cancel
              </div>
              <div className="filter-search-apply-button" onClick={() => handleApplyChanges()}>
                Apply
              </div>
            </div>
          </>
        ) : (
          <p
            className="filter-search-results-text"
            // onClick={() => props?.handleData(item)}
          >
            {`No results found`}
          </p>
        )}
      </div>
    </div>
  );
};

export default CategoryFilterDropdownList;
