import { filter } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { DecryptApi, DecryptId, GetIdFormLink } from "../../utlis/hof/hof";
import Footer from "../home/blades/footer";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import HeaderSEO from "../SEO/header";
import MetaSEO from "../SEO/MetaSEO";
import About from "./component/about/about";
import Blurbs from "./component/blurbs/blurbs";
import Boards from "./component/boards/boards";
import Categories from "./component/categories/categories";
import Features from "./component/features/features";
import Pricing from "./component/pricing/pricing";
import ProductProfile from "./component/productProfile/productProfile";
import Questions from "./component/questions/questions";
import RelatedProducts from "./component/relatedProducts/relatedProducts";
import Requirements from "./component/requirements/requirements";
import StickySideBar from "./component/stickySideBar/stickySideBar";
import SummaryStats from "./component/summaryStats/summaryStats";
import UseCases from "./component/useCases/useCases";
function ProductPage(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const PRODUCT_NAME = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Name;
  const PRODUCT_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name) : DecryptId(PARAMS?.id);

  const ProductDetails = useSelector((state) => state?.homeDetails?.productDetails);

  const GetApiData = useEnCryptGetApi(
    `/getNavigationPath?product_ID=${PRODUCT_ID?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const GetSEOTags = (data) => {
    const tags = data?.map((item) => item?.Product_Category_Name)?.filter((val) => val);

    return tags;
  };

  return (
    <div id="app" class="data-products__show" data-controller="scroll-position" data-scroll-position="0">
      {/* <HeaderSEO data={PRODUCT_NAME} /> */}
      {/* {ProductDetails ? (
        <MetaSEO
          title={ProductDetails?.Product_Name}
          description={ProductDetails?.Product_Description}
          Keywords={GetSEOTags(ProductDetails?.Product_Catagories)}
        />
      ) : null} */}
      <Header page={props?.page} show={true} />
      <div className="page-content  page-content--top-reset">
        <div className="attached-navbar m-b-md">
          <div className="container flex space-between align-items-bottom">
            <SearchHeaderBreadCrumb
              page={props?.page}
              parent={"productPage"}
              type={"Product"}
              params={PRODUCT_NAME}
              data={GetApiData?.data?.data}
            />
          </div>
        </div>
        <ProductProfile
          section="product profile"
          page={props?.page}
          productName={PRODUCT_NAME}
          productId={PRODUCT_ID}
        />
        <div className="product-main-area ui container">
          <div className="container">
            <div className="product-main-area__grid tablet-landscape-1-col">
              <div className="product-content">
                {/* <SummaryStats productName={PRODUCT_NAME} /> */}
                <About page={props?.page} productName={PRODUCT_NAME} productId={PRODUCT_ID} />
                {/* <Pricing productName={PRODUCT_NAME} /> */}
                {/* <UseCases header={"Use Cases"} productName={PRODUCT_NAME} /> */}
                {/* <Categories productName={PRODUCT_NAME} /> */}
                <Features page={props?.page} productName={PRODUCT_NAME} productId={PRODUCT_ID} />
                <Blurbs page={props?.page} parent={"ProductPage"} productName={PRODUCT_NAME} productId={PRODUCT_ID} />
                {/* <Boards productName={PRODUCT_NAME} /> */}
                <RelatedProducts
                  page={props?.page}
                  parent={"ProductPage"}
                  productName={PRODUCT_NAME}
                  productId={PRODUCT_ID}
                />
                <Questions productName={PRODUCT_NAME} productId={PRODUCT_ID} />

                {/* <Requirements productName={PRODUCT_NAME} /> */}
              </div>
              {/* <div className="product-sidebar">
                <StickySideBar productName={PRODUCT_NAME} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer page={props?.page} />
    </div>
  );
}

export default ProductPage;
