import React from "react";
import { useSelector } from "react-redux";
import BlurbTags from "../../../blurbDetail/components/blurb/blurbTags";
import * as ENV_KEYS from "../../../../config/configKeys";
import parse from "html-react-parser";

const UseCaseDescription = (props) => {
  const BLURB_DATA = props?.data;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const blurbImage = BLURB_DATA?.Media?.valid
    ? BLURB_DATA?.Media?.SRC
    : BLURB_DATA?.image_src
    ? BLURB_DATA?.image_src
    : BLURB_DATA?.account_logo
    ? BLURB_DATA?.account_logo
    : KEYS?.CORRUPT_IMAGE_ICON;
  return (
    <>
      <img
        class="new-use-case-detail-image"
        data-src={
          blurbImage ? blurbImage : BLURB_DATA?.account_logo ? BLURB_DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON
        }
        src={blurbImage ? blurbImage : BLURB_DATA?.account_logo ? BLURB_DATA?.account_logo : KEYS?.CORRUPT_IMAGE_ICON}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
        }}
        alt={`${BLURB_DATA?.title_level_1} ${ENV_KEYS?.CONFIG?.BLURB_IMAGE}`}
      />

      <div className="new-use-case-detail-description">
        {BLURB_DATA?.description ? (
          <p className="new-use-case-detail-description" style={{ margin: "0px" }}>
            {parse(BLURB_DATA?.description)}
          </p>
        ) : null}
      </div>
    </>
  );
};

export default UseCaseDescription;
