import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestCrudQuery, usePostRequestCrudMutation } from "../../../../../adapters/xhr/crudServiceApi";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../../../../config/configKeys";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import ConfirmDeleteModal from "../../../../../utlis/hoc/engagementBar/components/confirmDeleteModal";
import ChangeRoles from "./changeRoles";
import ChooseLicense from "./chooseLicense";
const UserManageTable = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [LicenseModalVisible, setLicenseModalVisible] = useState(false);
  const type = props?.type;
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    role: "Admin",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const tableHeader = props?.tableHeader;

  const GetApiData = useEnCryptGetApi(
    `/getUserStatusForAdmin?vendorId=${authData?.data?.vendorId}&status=${props?.vendorStatus}&userId=${authData?.data?.userId}&type=${props?.tableType}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );

  const tableData = GetApiData?.data?.data;

  const AcceptOrRejectUser = useEnCryptPostApi(
    `/userManagement/acceptOrReject`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestCrudMutation
  );
  const RemoveAcceptedUser = useEnCryptPostApi(`/removeJoinedUsers`, KEYS?.END_POINTS?.CORE?.END_POINT_URL, usePostRequestCrudMutation);

  const CheckLicense = useEnCryptGetApi(
    `/noOfLicenseExistIfVendor?Vendor_ID=${authData?.data?.vendorId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleAcceptOrReject = (action, val) => {
    if (action === "Accept" || action === "Reject") {
      if (CheckLicense?.data?.data?.No_Of_License > 0 || action === "Reject") {
        const rawData = {
          vendorId: authData?.data?.vendorId,
          userId: val?.userId,
          action: action,
          actionAt: new Date().toString(),
          roles: [{}],
        };
        AcceptOrRejectUser?.handleTrigger(rawData);
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          Action: action === "Accept" ? ENV_KEYS.CONFIG.USER_ACCEPTED : ENV_KEYS.CONFIG.USER_REJECTED,
          Item_Type: null,
          Item_Id: null,
          User_Type: "Known",
          Persona: "Business user",
          Target: {
            page: null,
            link: null,
            id: val?.userId,
          },
          Object: {
            section: null,
            context: `User ${action === "Accept" ? "accepted" : "rejected"}`,
            page: "/business-profile/teams",
            Persona: "Business user",
          },
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      } else {
        setLicenseModalVisible(true);
      }
    } else {
      const RemoveRawData = {
        Vendor_ID: authData?.data?.vendorId,
        User_ID: val?.userId,
      };
      if (props?.tableType === "Accepted") {
        HandleModal();
        setDeleteData(RemoveRawData);
      }
    }
  };

  const HandleModal = () => {
    setModalVisible((prev) => !prev);
  };

  const handleRemoveAcceptedUser = () => {
    HandleModal();
    RemoveAcceptedUser?.handleTrigger(deleteData);
    const signalTrackingPostData = {
      Actor: authData?.data?.userId,
      Action: ENV_KEYS.CONFIG.USER_REMOVED,
      Item_Type: null,
      Item_Id: null,
      User_Type: "Known",
      Persona: "Business user",
      Target: {
        page: null,
        link: null,
        id: deleteData?.User_ID,
      },
      Object: {
        section: null,
        context: `User removed`,
        page: "/business-profile/teams",
        Persona: "Business user",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const EpochDateConvertor = (val) => {
    let date = new Date(val * 1000);
    let options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    const converted = date.toLocaleDateString("en-GB", options);
    return converted;
  };

  const handleSortTableByColumn = (val) => {};

  return (
    <>
      {modalVisible ? (
        <ConfirmDeleteModal closeModal={() => HandleModal()} handleSubmit={() => handleRemoveAcceptedUser()} type={"user"} />
      ) : null}
      {LicenseModalVisible ? <ChooseLicense handleClose={() => setLicenseModalVisible((prev) => !prev)} /> : null}

      <div className="m-y-xs user-management-table-container">
        <table className="user-management-table">
          <thead className="border-table">
            {tableHeader?.map((item) => {
              return (
                <th className="table-header-text">
                  <div className="table-header-content">
                    {item?.DISPLAY_NAME}{" "}
                    <img
                      src={require("../../../../../assets/images/sort.svg").default}
                      style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                      role={"button"}
                      onClick={() => handleSortTableByColumn(item)}
                      alt="sort"
                    />
                  </div>
                </th>
              );
            })}
          </thead>

          {tableData?.map((item) => {
            return (
              <tr>
                {tableHeader?.map((table) => {
                  if (table?.INTERNAL_NAME === "action") {
                    return (
                      <td className="user-management-action-button-container">
                        {type == "pending" || true ? (
                          <>
                            {item[table?.INTERNAL_NAME]?.map((action) => {
                              return (
                                <div
                                  className={`user-manage-action-button-${action?.buttonColor}`}
                                  onClick={() => handleAcceptOrReject(action?.buttonText, item)}
                                >
                                  {action?.buttonText}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className={`user-manage-action-button-${"red"}`} onClick={() => handleAcceptOrReject("remove", item)}>
                            {"Remove"}
                          </div>
                        )}
                      </td>
                    );
                  } else if (
                    table?.INTERNAL_NAME === "rejectedTime" ||
                    table?.INTERNAL_NAME === "requestedTime" ||
                    table?.INTERNAL_NAME === "Invited_On"
                  ) {
                    return <td className="table-body-text">{EpochDateConvertor(item[table?.INTERNAL_NAME])}</td>;
                  } else if (table?.INTERNAL_NAME === "role") {
                    return (
                      <td className="table-body-text">
                        <ChangeRoles data={item} item={item} />
                        {/* {item[table?.INTERNAL_NAME]} */}
                      </td>
                    );
                  } else return <td className="table-body-text">{item[table?.INTERNAL_NAME]}</td>;
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default UserManageTable;
