import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CommentInput from "./commentInput";
import ReadMore from "../../../../components/businessProfile/components/businessOverview/ReadMore";
import CommentOptions from "./commentOptions";
import CommentReplyCard from "./commentReplyCard";
import { useEnCryptPostApi } from "../../../customHooks/customHooks";
import { usePostCommentForCardMutation } from "../../../../adapters/xhr/generalApi";
import { GetNameInitials } from "../../../hof/hof";

const InitialCommentCard = ({ item, data, replyCard, handleCommentCount, cardType }) => {
  const [commentData, setCommentData] = useState(item?.Comment);
  const [viewReplies, setViewReplies] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [commentInputVisible, setCommentInputVisible] = useState(false);
  const textAreaRef = useRef(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  //* Get comment time difference in hour, min and days for a comment
  const providedDate = new Date(item?.Comment_Created_Date_Time * 1000);
  const currentDate = new Date();
  const diffInMs = currentDate.getTime() - providedDate.getTime();
  const diffInMins = Math.round(diffInMs / (1000 * 60));
  const diffInHours = Math.round(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.round(diffInHours / 24);

  const EditComment = useEnCryptPostApi(
    `/comment/editComment`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  const TriggerLikeComment = useEnCryptPostApi(
    `/likeManagement`,
    KEYS?.END_POINTS?.ENGAGEMENT?.END_POINT_URL,
    usePostCommentForCardMutation
  );

  useEffect(() => {
    setCommentData(item?.Comment);
  }, [item]);

  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 10)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };

  const handleChangeText = (e) => {
    setCommentData(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };

  const handleSaveClicked = (val) => {
    if (commentData) {
      const rawData = {
        User_ID: USER_ID,
        Comment: commentData,
        Comment_GUID: val?.commentGUID,
      };
      setEditComment((prev) => !prev);
      EditComment?.handleTrigger(rawData);
      setCommentData("");
    }
  };

  const handleCloseInput = () => {
    setCommentInputVisible(false);
    setViewReplies(true);
  };

  const handleCommentLike = () => {
    const rawData = {
      Action_Type: item?.Liked ? "dislike" : "Liked",
      Item_Type: "Comment",
      Item_Id: item?.commentGUID,
      User_Id: USER_ID,
    };
    TriggerLikeComment?.handleTrigger(rawData);
  };

  const HandleCloseEdit = () => {
    setCommentData(item?.Comment);
    setEditComment(false);
  };
  const initials = GetNameInitials(item?.userName ? item?.userName : "Anon Name");
  return (
    <div className="comment-list-container" key={item?.Comments_ID}>
      {/* <img
        className="comment-card-user-image"
        alt="User image"
        data-src={require("../../../../assets/images/profileIconPlaceholder.png")}
        src={require("../../../../assets/images/profileIconPlaceholder.png")}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
        }}
      /> */}
      <div className="dtrd-navbar__avatar-box">
        <div class="dtrd-navbar__avatar-box navbar__user">
          <div class="circular-avatar">
            {item?.Privacy_Status === "Anonymous" ? (
              <img
                src={require("../../../../assets/images/anonymous_image.png")}
                alt="vendor-image"
                className="comment-list-user-image"
              />
            ) : (
              <div class="user-initials user-initials--primary" style={{ width: "40px", height: "40px" }}>
                {initials}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ flex: "1" }}>
        <div className={`comment-text-container ${item?.Delete_Status ? "deleted-comment-container" : ""}`}>
          {item?.Delete_Status ? null : (
            <div className="flex space-between m-b-half-base">
              <h4 className="comment-card-username">{item?.userName}</h4>
              <div className="flex align-items-center">
                <p className="comment-engagement-time">
                  {diffInMins < 60
                    ? `${diffInMins ? diffInMins : 1}min`
                    : diffInHours < 24
                    ? `${diffInHours}hr`
                    : `${diffInDays}d`}
                </p>
                {item?.Edited ? <p className="comment-engagement-time">(edited)</p> : null}
                <CommentOptions data={item} handleEditPressed={() => setEditComment((prev) => !prev)} />
              </div>
            </div>
          )}
          {editComment ? (
            <div>
              <textarea
                //   ref={textAreaRef}
                style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
                // onKeyDown={onKeyDown}
                rows={1}
                id="note"
                type={"text"}
                value={commentData}
                autoFocus={true}
                onInput={handleInput}
                onChange={(e) => handleChangeText(e)}
                placeholder="Add comment"
                className={`input-field-text outline-input-focus m-t-mini`}
              />
              <div className={`flex justify-flex-end m-half-base`}>
                <p
                  className="comment-card-post-button"
                  style={{ margin: "0px", color: "#D13732" }}
                  role="button"
                  onClick={() => HandleCloseEdit()}
                >
                  Cancel
                </p>
                <p
                  className="comment-card-post-button"
                  data-inverted="true"
                  data-position="left center"
                  data-tooltip={!commentData ? "Comments cannot be empty" : null}
                  onClick={() => handleSaveClicked(item)}
                  style={{ margin: "0px" }}
                  role="button"
                >
                  Save
                </p>
              </div>
              <div
                onClick={() => handleSaveClicked(item)}
                className={`flex justify-flex-end m-l-half-base ${commentData ? "cursor--pointer" : "cursor--disable"}`}
              ></div>
            </div>
          ) : (
            <ReadMore>{item?.Comment}</ReadMore>
          )}
        </div>
        <div className="comment-engagement-container">
          {item?.Delete_Status ? null : (
            <>
              <div
                className={`comment-like-button ${item?.Liked ? "comment-liked-focus" : ""}`}
                onClick={() => handleCommentLike()}
              >
                Like
              </div>{" "}
              ·{"  "}
            </>
          )}
          <p className="comment-engagement-count">
            {item?.likeCount} like{item?.likeCount == 1 ? "" : "s"}
          </p>
          {!replyCard ? (
            <>
              |{" "}
              {item?.Delete_Status ? null : (
                <>
                  <div className="comment-like-button" onClick={() => setCommentInputVisible((prev) => !prev)}>
                    Reply
                  </div>
                  ·{"  "}
                </>
              )}
              <p className="comment-engagement-count" onClick={() => setViewReplies((prev) => !prev)} role="button">
                {item?.totalCommentCount} repl{item?.totalCommentCount === 1 ? "y" : "ies"}
              </p>{" "}
            </>
          ) : null}
        </div>
        {commentInputVisible && (
          <CommentInput
            parentData={item}
            data={data}
            handleCloseInput={() => handleCloseInput()}
            handleCommentCount={handleCommentCount}
            cardType={cardType}
          />
        )}
        {viewReplies && <CommentReplyCard parentData={item} data={data} />}
      </div>
    </div>
  );
};

export default InitialCommentCard;
