import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import RequestCustomer from "../../../components/companyProfile/component/vendorCustomer/components/requestCustomer";
import RequestCategory from "../../../components/searchResult/components/allCategories/component/requestCategory/requestCategory";
import DropdownNewOptions from "./components/drodownNewOptions";

const SingleSelectNewDropdown = (props) => {
  const [inputText, setInputText] = useState("");
  const [dropDownFocus, setDropDownFocus] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false);
  const REQUEST_CATEGORY = useSelector((state) => state?.homeDetails?.requestCategoryStatus);
  const name = props?.name ? props?.name : "name";
  const REQUIRED = props?.required;
  const removeFiltering = props?.removeFiltering;
  useEffect(() => {
    if (props?.returnObject) {
      setInputText(props?.selectedVal?.[name]);
    } else {
      setInputText(props?.selectedVal);
    }
    // if (props?.returnObject && props?.searchWithApi) {
    //   if (props?.selectedVal?.[name]) {
    //     props?.handleSearchWithApi(props?.selectedVal?.[name]);
    //   } else {
    //     props?.handleSearchWithApi(props?.selectedVal);
    //   }
    // }
  }, [props?.selectedVal]);

  const handleSelectItem = (val) => {
    if (props?.returnObject) {
      setInputText(val?.[name]);
    } else {
      setInputText(val);
    }
    setDropDownFocus(false);
    props?.handleSelectedData(val);
  };

  const handleCloseDropdown = () => {
    setTimeout(() => {
      setDropDownFocus(false);
    }, 500);
  };

  const handleFocusInput = () => {
    setDropDownFocus(!dropDownFocus);
  };
  const handleChangeInput = (e) => {
    props?.type == "number" ? setInputText(e.target.value.replace(/[^0-9+]/g, "")) : setInputText(e.target.value);
    if (props?.getSearchedText) {
      props?.handleChangeSearchText(e.target.value);
    }
    if (props?.searchWithApi) {
      if (e.target?.value) {
        props?.handleSearchWithApi(e.target.value);
      } else {
        props?.handleSearchWithApi("a");
      }
    }
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    props?.handleChangeText(inputText);
    setDropDownFocus(false);
  };

  return (
    <div className="input-field-wrapper">
      {dropDownFocus ? (
        <DropdownNewOptions
          removeFiltering={removeFiltering}
          returnObject={props?.returnObject}
          rightName={props?.rightName}
          rightSideContent={props?.rightSideContent}
          name={props?.name}
          handleSelectItem={handleSelectItem}
          // inputText={props?.searchWithApi ? "" : inputText}
          inputText={inputText}
          data={props?.data}
          flag={props?.flag}
          enableViewDomain={props?.enableViewDomain}
          type={props?.type}
          requestCategory={() => setRequestModalVisible((prev) => !prev)}
          requestCustomer={() => setAddCustomerModalVisible((prev) => !prev)}
        />
      ) : null}
      {requestModalVisible ? <RequestCategory handleClose={() => setRequestModalVisible((prev) => !prev)} /> : null}
      {addCustomerModalVisible ? (
        <RequestCustomer handleClose={() => setAddCustomerModalVisible((prev) => !prev)} />
      ) : null}
      {inputText && props?.label ? (
        <label for="user__email" className="company-details-label-input-field-filled-absolute">
          {props?.label}
        </label>
      ) : null}

      {dropDownFocus ? (
        <FeatherIcon icon={"chevron-up"} size="17" color={"#6E7079"} className="dropdown-icons" />
      ) : (
        <FeatherIcon icon={"chevron-down"} size="17" color={"#6E7079"} className="dropdown-icons" />
      )}
      <form onSubmit={handleSubmit}>
        <input
          className={`input-field-text ${REQUIRED ? "required-border-right" : ""}`}
          // class="search"
          autocomplete="off"
          tabindex="0"
          placeholder={`${props?.placeholder}`}
          onFocus={handleFocusInput}
          onBlur={() => handleCloseDropdown()}
          onChange={handleChangeInput}
          value={inputText}
          style={{ height: "44px" }}
        />
      </form>
      {props?.infoText ? (
        <p className="input-field-info-text" style={{ textAlign: "start" }}>
          {props?.infoText}
        </p>
      ) : null}
      {REQUEST_CATEGORY && props?.type === "ProductCategory" ? (
        <div className="category-request-submitted">
          Product category request submitted. We will reach to you once the category is live
        </div>
      ) : null}
      <div class="text" onClick={() => setDropDownFocus(true)}>
        {/* {dropDownFocus ? null : selectedItem} */}
      </div>
    </div>
  );
};

export default SingleSelectNewDropdown;
