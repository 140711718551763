import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import * as SECRET_KEYS from "../../../config/configKeys";
import { GetNameInitials, ReplaceBlankSpaceByHyphen } from "../../../utlis/hof/hof";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import * as CONFIG_KEYS from "../../../config/config";
import {
  usePostRequestMutation,
  usePostCommentForCardMutation,
  useUpdateUserEmailMutation,
} from "../../../adapters/xhr/generalApi";
import { useEffect } from "react";
import { octateVendorFn, selectedVendorFn } from "../../../contexts/reducer/chatReducer";
import * as ENV_KEYS from "../../../config/configKeys";
const ChatRoomInput = (props) => {
  const [error, setError] = useState("");
  const [commentText, setCommentText] = useState("");
  const [isAnonymousUser, setIsAnonymousUser] = useState(false);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const octateVendorSelector = useSelector((state) => state?.chatDetails?.octateVendor);
  const OctateVendorId = octateVendorSelector?.length ? octateVendorSelector[0]?.Vendor_ID : null;
  const authData = useSelector((state) => state?.authDetails?.details);
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const BUYER_INPUT_CHAT = CONFIG_KEYS.CONFIG.DIRECT_MESSAGE.BUYER_CHAT;
  const USER_ID = authData?.data?.userId;
  const fromVendor = props?.formVendor ? true : false;
  //   //   Post https://thebox-dev.portqii.com/directmessage/newMessage
  const GetApiData = useEnCryptPostApi(
    `/updateConversationAnonymous_Name`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useUpdateUserEmailMutation
  );
  const PostComment = useEnCryptPostApi(
    `/newMessage`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const updateMuteParticipants = useEnCryptPostApi(
    `/updateConversationDisableStatus`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useUpdateUserEmailMutation
  );
  useEffect(() => {
    if (PostComment?.data?.Conversation_ID && !selectedVendorSelector?.Conversation_ID) {
      dispatch(
        selectedVendorFn({
          ...selectedVendorSelector,
          Conversation_ID: PostComment?.data?.Conversation_ID,
          Date_Time: parseInt(new Date().getTime() / 1000),
        })
      );
    }
    if (
      PostComment?.data?.Conversation_ID &&
      !selectedVendorSelector?.Conversation_ID &&
      selectedVendorSelector?.Vendor_Name == "Octate.ai"
    ) {
      dispatch(
        octateVendorFn([
          {
            ...selectedVendorSelector,
            Conversation_ID: PostComment?.data?.Conversation_ID,
            Date_Time: parseInt(new Date().getTime() / 1000),
          },
        ])
      );
    }
  }, [PostComment?.data]);
  const initials = GetNameInitials(
    authData?.data?.userExist
      ? authData?.data?.userName
        ? authData?.data?.userName
        : authData?.data?.firstName + " " + authData?.data?.lastName
      : "Anon Name"
  );
  const handleSendClicked = (e) => {
    e.preventDefault();
    if (commentText) {
      const rawData = {
        Vendor_ID: selectedVendorSelector?.Vendor_ID,
        User_ID: USER_ID,
        Message_Text: commentText,
        Conversation_ID: selectedVendorSelector?.Conversation_ID ? selectedVendorSelector?.Conversation_ID : null,
        From_Vendor: fromVendor,
        Anonymous_Name: selectedVendorSelector?.Anonymous_Name,
        Date_Time: parseInt(new Date().getTime() / 1000),
      };
      PostComment?.handleTrigger(rawData);
      setCommentText("");
    }
  };

  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };
  function handleReopenClicked() {
    updateMuteParticipants.handleTrigger({
      Conversation_ID: selectedVendorSelector?.Conversation_ID,
      Disable_Flag: !selectedVendorSelector?.Disable_Flag,
    });
    setTimeout(() => {
      dispatch(selectedVendorFn({ ...selectedVendorSelector, Disable_Flag: !selectedVendorSelector?.Disable_Flag }));
    }, 500);
  }
  const handleChangeText = (e) => {
    setCommentText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
      if (commentText) {
        const rawData = {
          Vendor_ID: selectedVendorSelector?.Vendor_ID,
          User_ID: USER_ID,
          Message_Text: commentText,
          Conversation_ID: selectedVendorSelector?.Conversation_ID ? selectedVendorSelector?.Conversation_ID : null,
          From_Vendor: fromVendor,
          Anonymous_Name: selectedVendorSelector?.Anonymous_Name,
          Date_Time: parseInt(new Date().getTime() / 1000),
        };
        PostComment?.handleTrigger(rawData);
        const signalTrackingPostData = {
          Actor: USER_ID,
          SignalCreatedAt: new Date().toString(),
          Action: ENV_KEYS.CONFIG.DM_CLICKED,
          Item_Type: fromVendor ? "Message form vendor" : "Message from buyer",
          Item_Id: selectedVendorSelector?.Vendor_ID,
          Target: {
            page: null,
            link: null,
          },
          Object: {
            section: "Direct message search",
            context: null,
          },
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
        setCommentText("");
      }
      // handleSendClicked();
    }
    //Here you can even write the logic to select the value from the drop down or something.
  };
  function handleHideIdentity() {
    if (selectedVendorSelector?.Conversation_ID) {
      GetApiData.handleTrigger({
        Conversation_ID: selectedVendorSelector?.Conversation_ID,
        Anonymous_Name: !selectedVendorSelector?.Anonymous_Name,
      });
    }

    setTimeout(() => {
      dispatch(
        selectedVendorFn({ ...selectedVendorSelector, Anonymous_Name: !selectedVendorSelector?.Anonymous_Name })
      );
      props?.handleClose();
    }, 500);
  }
  return (
    <div className="comments-container m-b-sm m-t-base">
      {selectedVendorSelector?.Disable_Flag ? (
        <div className="direct-message-send-message-main-container  ">
          <div className="text-area-container">
            <h2 className="direct-message-send-message-disable-chat-des">{BUYER_INPUT_CHAT.DISABLE_DESC}</h2>
          </div>
          <div className="send-message-text-container">
            <div onClick={handleReopenClicked} className="send-message-button-container cursor--pointer">
              {BUYER_INPUT_CHAT.DISABLE_BUTTON}
            </div>
          </div>
        </div>
      ) : !selectedVendorSelector?.Verified ? (
        <div className="direct-message-send-message-main-container  ">
          <div className="text-area-container">
            <h2 className="direct-message-send-message-disable-chat-des">{BUYER_INPUT_CHAT.UN_VERIFIED_VENDOR}</h2>
          </div>
        </div>
      ) : (
        <div className="direct-message-send-message-main-container  ">
          <div className="text-area-container">
            <textarea
              onKeyDown={onKeyDown}
              ref={textAreaRef}
              style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
              rows={1}
              id="note"
              type={"text"}
              value={commentText}
              onInput={handleInput}
              onChange={(e) => handleChangeText(e)}
              placeholder={`Write a message`}
              className={`input-field-text ${error ? "background-error" : ""}`}
            />
          </div>
          <div className="send-message-text-container">
            {OctateVendorId && OctateVendorId == selectedVendorSelector?.Vendor_ID ? null : (
              <div className="direct-message-chat-header-container-selected-vendor-sub-container">
                <input
                  type="checkbox"
                  className="table-checkbox"
                  checked={selectedVendorSelector?.Anonymous_Name}
                  onClick={handleHideIdentity}
                />
                <p className="filter-list-items-text m-l-half-base">{"Hide my identity"}</p>
              </div>
            )}

            <div onClick={handleSendClicked} className="send-message-button-container cursor--pointer">
              Send
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatRoomInput;
