import React, { useState } from "react";
import Notifications from "../../notifications/notifications";
import BrowseUseCases from "./component/browseUseCases/browseUseCases";
import BrowseUseCasesDesktop from "./component/browseUseCases/browseUseCasesDesktop";
import ExploreCategory from "./component/exploreCategories/exploreCategories";
import ExploreCategoryDesktop from "./component/exploreCategories/exploreCategoriesDesktop";
import HeaderSearch from "./component/headerSearch/headerSearch";
import ListYourData from "./component/listYourData/listYourData";
import ListYourDataDesktop from "./component/listYourData/listYourDataDesktop";
import LiveSupport from "./component/liveSupport/liveSupport";
import LiveSupportDesktop from "./component/liveSupport/liveSupportDesktop";
import NavItems from "./component/navItems/navItems";
import OctateIcon from "./component/octateIcon/octateIcon";
import OctateIconDesktop from "./component/octateIcon/octateIconDesktop";
import PostYourData from "./component/postYourData/postYourData";
import PostYourDataDesktop from "./component/postYourData/postYourDataDesktop";
import Profile from "./component/profile/profile";
import ProfileDesktop from "./component/profile/profileDesktop";
import SideDrawerButton from "./component/sideDrawerButton/sideDrawerButton";
import TalkToDataExpert from "./component/talkToDataExpert/talkToDataExpert";
import TalkToDataExpertDesktop from "./component/talkToDataExpert/talkToDataExpertDeskTop";
const Header = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const handleSideBarClicked = (val) => {
    setShowDrawer(val);
  };
  return (
    <header className="dtrd-navbar">
      <div className="dtrd-navbar__desktop">
        {/* <div className="dtrd-navbar__top tablet-hidden">
          <div className="container">
            <nav className="navbar__menu">
              <ExploreCategoryDesktop />
              <BrowseUseCasesDesktop />
            </nav>
            <nav className="navbar__menu">
              <PostYourDataDesktop />
              <TalkToDataExpertDesktop />
              <LiveSupportDesktop />
              <ListYourDataDesktop />
            </nav>
          </div>
        </div> */}
        <div className="dtrd-navbar__bottom tablet-hidden">
          <div className="octate-header">
            <OctateIconDesktop />
            {props?.show ? <HeaderSearch type={props?.type} /> : null}
            <ProfileDesktop />
          </div>
        </div>
      </div>
      <div className="dtrd-navbar__mobile only-tablet">
        <div className="container align-items-center m-b-0">
          <SideDrawerButton handleSideBarClicked={handleSideBarClicked} />
          <OctateIcon />
          <Notifications />
          <Profile />
        </div>
      </div>
      {props?.show ? (
        <div className="dtrd-navbar__mobile only-tablet">
          <div className="container ">
            <HeaderSearch type={props?.type} />
          </div>
        </div>
      ) : null}

      <nav
        className={`${
          showDrawer
            ? "navbar__mobile-menu only-tablet slide-in--on"
            : "navbar__mobile-menu only-tablet "
        }`}
      >
        <ExploreCategory handleSideBarClicked={handleSideBarClicked} />
        {/* <BrowseUseCases /> */}
        <PostYourData />
        {/* <TalkToDataExpert />
        <LiveSupport /> */}
        <ListYourData />
      </nav>
      <div className="slide-in-overlay"></div>
    </header>
  );
};
export default Header;
