import React, { useEffect } from "react";
import ChatListCard from "./components/chatListCard";
import { useDispatch, useSelector } from "react-redux";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import BoxSpinner from "../../../utlis/spinner/boxSpinner";
import TabNavigator from "../../../utlis/hoc/tabNavigator/tabNavigator";
import { useState } from "react";
import ChatListWrapper from "./components/chatListWrapper";
import InfiniteScrollComp from "../../../utlis/hoc/infiniteScroll/infiniteScroll";
import _ from "lodash";
import { userChatListFn } from "../../../contexts/reducer/chatReducer";
import ChatListVendorWrapper from "./chatListVendorWrapper";
import * as ENV_KEYS from "../../../config/configKeys";
function ChatList(props) {
  const [selectedTab, setSelectedTab] = useState("vendors");
  const [conversationList, setConversationList] = useState([]);
  const [PageNo, setPageNo] = useState(1);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const buyerView = !authData?.data?.buyerView && authData?.data?.Persona == "Business User" ? false : true;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  const dispatch = useDispatch();
  const vendorId = authData?.data?.vendorId;
  const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const GetApiData = useEnCryptGetApi(
    `/getUserConversation?User_ID=${USER_ID}&Page=${1}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetRequestQuery
  );
  // useEffect(() => {
  //   if (!buyerView) {
  //     setSelectedTab("octate");
  //   }
  // }, [buyerView]);
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    const signalTrackingPostData = {
      Actor: authData?.data?.userId,
      SignalCreatedAt: new Date().toString(),
      Action: ENV_KEYS.CONFIG.DM_CLICKED,
      Item_Type: "Direct message window",
      Item_Id: null,
      Target: {
        page: null,
        link: null,
      },
      Object: {
        section: "Direct message",
        context: "Direct message window",
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  }, []);
  useEffect(() => {
    const socketConnected = typeof chatSocket === "object" && Object.keys(chatSocket).length ? true : false;
    if (socketConnected) {
      chatSocket.on("UserUpdatedList", function(data) {
        GetApiData.refetch();
      });
    }
  }, [chatSocket]);
  const CHAT_LIST_TAB =
    !buyerView && false
      ? []
      : [
          {
            label: "Vendors",
            value: "vendors",
            display: true,
          },
          {
            label: "Octate AI",
            value: "octate",
            display: true,
          },
        ];
  useEffect(() => {
    if (selectedVendorSelector?.Conversation_ID) {
      let conversationListArr = conversationList?.length ? [...conversationList] : [];
      const ConversionIdExist = conversationListArr.find(
        (element) => element.Conversation_ID == selectedVendorSelector?.Conversation_ID
      );
      if (!ConversionIdExist) {
        conversationListArr.unshift(selectedVendorSelector);
        setConversationList(conversationListArr);
      }
    }
  }, [selectedVendorSelector?.Conversation_ID]);
  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      let newData = GetApiData?.data?.data?.length ? [...GetApiData?.data?.data] : [];
      let allNewData = [...conversationList];
      const concatData = newData.concat[allNewData];
      const uniqueData = _.uniqBy(concatData, "Conversation_ID");
      dispatch(userChatListFn(GetApiData?.data?.data));
      setConversationList(GetApiData?.data?.data);
    }
  }, [GetApiData?.data?.data]);

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };

  return (
    <div className="chat-list-wrapper-main-wrapper-min-height">
      <div className="flex tab-navigator-container">
        {CHAT_LIST_TAB?.map((item, index) => {
          if (item?.display)
            return (
              <div className="flex m-t-base" key={item?.value} style={{ width: "50%" }}>
                <h2
                  className={`tab-index-text ${selectedTab == item?.value ? "tab-index-selected" : null}`}
                  onClick={() => setSelectedTab(item?.value)}
                  style={{ width: "100%" }}
                >
                  {item?.label}
                </h2>
              </div>
            );
        })}
      </div>
      {conversationList?.length > 0 && selectedTab === "vendors"  ? (
        <InfiniteScrollComp
          Data={conversationList}
          hasMoreData={GetApiData?.data?.data?.length ? true : false}
          fetchData={fetchData}
        >
          <ChatListWrapper data={conversationList} />
        </InfiniteScrollComp>
      ) : selectedTab === "octate" ? (
        <ChatListVendorWrapper data={conversationList} />
      ) : null}
      {GetApiData?.isLoading && selectedTab === "vendors" ? (
        <BoxSpinner />
      ) : !conversationList?.length && selectedTab !== "octate" ? (
        <div className="direct-message-not-empty-chat-list-message-container">
          <h1 className="direct-message-not-empty-chat-list-message">
            You have not interacted with anyone so far. Start engaging with vendors now.
          </h1>
        </div>
      ) : null}
    </div>
  );
}

export default ChatList;
