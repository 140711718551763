import React from "react";

function PieChart() {
  return (
    <div className="vertical-product-card__fact-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1913 4.08437C11.1913 3.78726 10.933 3.55453 10.6403 3.60533C6.65392 4.29714 3.62256 7.77331 3.62256 11.9575C3.62256 16.6394 7.418 20.4349 12.0999 20.4349C14.7616 20.4349 17.1367 19.2082 18.6909 17.2894C18.8782 17.0582 18.8002 16.7191 18.5401 16.5746L11.4485 12.6348C11.2898 12.5466 11.1913 12.3793 11.1913 12.1977V4.08437Z"
          fill="#1371C3"
        ></path>
        <path
          d="M19.4243 14.9877C19.6842 15.1321 20.0131 15.0195 20.1105 14.7387C20.413 13.8675 20.5773 12.9317 20.5773 11.9575C20.5773 7.7731 17.5456 4.29677 13.5589 3.60522C13.2662 3.55444 13.0079 3.78717 13.0079 4.08427V11.1289C13.0079 11.3104 13.1064 11.4777 13.2651 11.5659L19.4243 14.9877Z"
          fill="#1371C3"
        ></path>
      </svg>
    </div>
  );
}

export default PieChart;
