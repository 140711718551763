import React from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import AddQuestionWithoutQuestion from "./addQuestionWithoutQuestions";
import AddQuestionWithQuestion from "./addQuestionWithQuestions";
const AddQuestion = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const sectionIndex = props?.sectionIndex;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const hideAddAndEditQuestion = PROPOSAL_TYPE == "answer" || PROPOSAL_TYPE == "question" || !USER_CAN_EDIT ? true : false;
  const hideAddQuestion = PROPOSAL_TYPE == "answer" || !USER_CAN_EDIT ? true : false;
  const proposalQuestionArray = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
    ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION][sectionIndex]
    : null;

  const handleAddQuestionClicked = (val) => {};
  const handleDeleteClicked = (val) => {
    let questionListArray = proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.length
      ? proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]
      : [];
    questionListArray.splice(val, 1);
    let questionArray = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
      ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]
      : [];
    questionArray[sectionIndex] = {
      ...questionArray[sectionIndex],
      [ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]: questionListArray,
    };
    ////////////////////////////////////////////////////////////////////////////////////////
    props?.handleDetails({
      ...proposalDetails,
      [[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]]: questionArray,
    });
  };
  const handleSaveClicked = (val, questionListIndex) => {
    let questionListArray = proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.length
      ? proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]
      : [];
    if (questionListIndex) {
      questionListArray[questionListIndex - 1] = val;
    } else {
      questionListArray.push(val);
    }
    // questionListArray.push(val);
    // ////////////////////////////////////////////////////////////////////////
    let questionArray = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length
      ? proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]
      : [];
    questionArray[sectionIndex] = {
      ...questionArray[sectionIndex],
      [ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]: questionListArray,
    };
    ////////////////////////////////////////////////////////////////////////////////////////
    props?.handleDetails({
      ...proposalDetails,
      [[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]]: questionArray,
    });
  };
  return !proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_QUESTION]?.length ? (
    <AddQuestionWithoutQuestion
      hideAddQuestion={hideAddQuestion}
      hideAddAndEditQuestion={hideAddAndEditQuestion}
      disableAddQuestion={true}
      proposalQuestionArray={proposalQuestionArray}
      handleAddQuestionClicked={handleAddQuestionClicked}
      handleSaveClicked={handleSaveClicked}
    />
  ) : proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.length ? (
    <AddQuestionWithQuestion
      hideAddQuestion={hideAddQuestion}
      hideAddAndEditQuestion={hideAddAndEditQuestion}
      proposalQuestionArray={proposalQuestionArray}
      handleAddQuestionClicked={handleAddQuestionClicked}
      handleSaveClicked={handleSaveClicked}
      handleDeleteClicked={handleDeleteClicked}
    />
  ) : (
    <AddQuestionWithoutQuestion
      hideAddQuestion={hideAddQuestion}
      hideAddAndEditQuestion={hideAddAndEditQuestion}
      proposalQuestionArray={proposalQuestionArray}
      handleAddQuestionClicked={handleAddQuestionClicked}
      handleSaveClicked={handleSaveClicked}
    />
  );
};

export default AddQuestion;
