import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import TableContentMoreOptionsList from "./tableContentMoreOptionsList";
import ProposalVendorPortal from "./proposalVendorPortal";
import * as CONFIG_KEYS from "../../../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import { useNavigate } from "react-router";
import { vendorProposalSelectedTabFn } from "../../../../../contexts/reducer/notificationReducer";
import { useDispatch } from "react-redux";
const TableContentMoreOptions = (props) => {
  const API_KEYS = CONFIG_KEYS?.CONFIG?.PROPOSAL?.PROPOSAL_TABLE;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showProposalModal, setShowProposalModal] = useState({
    show: false,
    index: 1,
  });
  const permissionsDetails = props?.permissionsDetails;
  const ProposalId = props?.list?.[API_KEYS?.PROPOSAL_ID];
  const notificationJson = props?.notificationJson;
  const handleActionClicked = (val) => {
    if (val == "preview") {
      // setShowProposalModal({
      //   show: true,
      //   index: 1,
      // });
      // Item_Id, Item_Type, Shared_By, Shared_With, Shared_At, Access_Type
      dispatch(vendorProposalSelectedTabFn(1));
      navigate(
        `/proposal/vendor/${ReplaceBlankSpaceByHyphen(
          props?.list?.[API_KEYS?.PROPOSAL_TITLE],
          props?.list?.[API_KEYS?.PROPOSAL_ID]
        )}`
      );
    } else if (val == "edit") {
      // setShowProposalModal({
      //   show: true,
      //   index: 2,
      // });
      dispatch(vendorProposalSelectedTabFn(2));
      navigate(
        `/proposal/vendor/${ReplaceBlankSpaceByHyphen(
          props?.list?.[API_KEYS?.PROPOSAL_TITLE],
          props?.list?.[API_KEYS?.PROPOSAL_ID]
        )}`
      );
    }
  };
  useEffect(() => {
    if (notificationJson?.["Object"]?.Id == props?.list?.[API_KEYS?.PROPOSAL_ID]) {
      handleActionClicked("edit");
    }
  }, [notificationJson]);
  const handleShowMenuModal = () => {
    setShowModal(!showModal);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowModal(false);
    }
  };
  const handleClose = (val) => {
    setShowProposalModal({
      show: false,
      index: 1,
    });
  };
  return (
    <>
      {showProposalModal?.show ? (
        <ProposalVendorPortal
          permissionsDetails={permissionsDetails}
          handleClose={handleClose}
          notificationJson={notificationJson}
          showProposalModal={showProposalModal}
          Id={ProposalId}
        />
      ) : null}

      <div style={{ position: "relative" }} onBlur={handleBlur} tabIndex={0} onClick={handleShowMenuModal}>
        <FeatherIcon icon="menu" size={20} color={"#757575"} />
        {showModal ? (
          <div className="proposal-table-content-menu-options-list-container--top  modern-card modern-card--elevated">
            <TableContentMoreOptionsList
              permissionsDetails={permissionsDetails}
              handleActionClicked={handleActionClicked}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TableContentMoreOptions;
