import React from "react";

function BriefCase() {
  return (
    <svg
      class="icon-suitcase"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.4335"
        y="4.6"
        width="12.1333"
        height="9.46667"
        rx="1.4"
        stroke="#5C7284"
        stroke-width="1.2"
      ></rect>
      <line
        x1="2.5"
        y1="9.4"
        x2="14.5"
        y2="9.4"
        stroke="#5C7284"
        stroke-width="1.2"
      ></line>
      <path
        d="M6.5 4.66667V3C6.5 2.44772 6.94772 2 7.5 2H9.5C10.0523 2 10.5 2.44772 10.5 3V4.66667"
        stroke="#5C7284"
        stroke-width="1.2"
      ></path>
    </svg>
  );
}

export default BriefCase;
