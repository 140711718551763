import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetMyBoardsDataQuery } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import CreateNewBoard from "../../utlis/hoc/Boards/createNewBoard";
import { detectMob } from "../../utlis/hof/hof";
import Header from "../home/header/header";
import SearchHeaderBreadCrumb from "../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import AllBoardsList from "./allBoardsList";
const MyBoards = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [getAllBoardsList, setGetAllBoardsList] = useState({});
  const [EditModalVisible, setEditModalVisible] = useState(false);
  const [EditBoardData, setEditBoardData] = useState({});
  const [selectedTab, setSelectedTab] = useState("Private");
  const MOBILE_DEVICE = detectMob();
  const USER_ID = authData?.data?.userId;

  // {Private Boards: Array(3), Public Boards: Array(16), Shared Boards: Array(0)}
  const GetApiData = useEnCryptGetApi(
    `/getUserBoardsForMyBoards?userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetMyBoardsDataQuery
  );

  const BREAD_CRUMB_BOARDS = [
    { name: "Home", active: true, link: "/" },
    { name: "My Account", active: true, link: "/profile" },
    { name: "My Boards", active: false },
  ];
  useEffect(() => {
    if (GetApiData?.data?.data && Object.keys(GetApiData?.data?.data)?.length) {
      setGetAllBoardsList(GetApiData?.data?.data);
    }
  }, [GetApiData?.data?.data]);
  const handleEditBoard = (val) => {
    setEditBoardData(val);
    setEditModalVisible((prev) => !prev);
  };
  const getAllBoardsListKeys = Object.keys(getAllBoardsList);
  // const grouped_data = _.groupBy(users, Math.floor);
  const TabData = [
    {
      label: "Private",
      value: "private",
      display: true,
    },
    {
      label: "Public",
      value: "public",
      display: true,
    },
    {
      label: "Shared",
      value: "shared",
      display: true,
    },
  ];

  return (
    <div style={{ background: "#fff" }}>
      <Header show={true} />
      <div className="attached-navbar m-b-sm">
        <div className="container flex space-between align-items-bottom">
          <SearchHeaderBreadCrumb parent={"boards"} data={BREAD_CRUMB_BOARDS} />
        </div>
      </div>
      <div
        style={{ display: "block", height: "100%", width: MOBILE_DEVICE ? "100%" : "80%", background: "#fff" }}
        className="container m-b-sm"
      >
        <div className="my-boards-header-container">
          <h1 className="my-boards-header-text">My Boards</h1>
          <div className="filter-search-apply-button" onClick={() => handleEditBoard({})}>
            Create New
          </div>
        </div>
        {MOBILE_DEVICE ? (
          <div className="flex" style={{ overflowX: "auto" }}>
            {TabData.map((item) => {
              return (
                <div className="flex m-t-base" key={item?.value}>
                  <h2
                    className={`tab-index-text ${selectedTab == item?.label ? "tab-index-selected" : null}`}
                    onClick={() => setSelectedTab(item?.label)}
                  >
                    {item?.label}
                  </h2>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "minmax(270px, 1fr) minmax(270px, 1fr) minmax(270px,  1fr)",
              rowGap: "10px",
              columnGap: "15px",
              overflow: "auto",
              minHeight: "50vh",
            }}
            // className="board-list-main-list-section-wrapper"
          >
            {getAllBoardsListKeys?.map((label) => {
              return (
                <div
                  className={`${
                    label === "Private Boards"
                      ? "private-board-background"
                      : label === "Public Boards"
                      ? "public-board-background"
                      : ""
                  } board-list-content-wrapper`}
                >
                  <h5 className="board-list-content-main-header">{label}</h5>
                  <AllBoardsList data={getAllBoardsList} label={label} />
                </div>
              );
            })}
          </div>
        )}
        {MOBILE_DEVICE ? (
          <div className="m-t-md">
            <AllBoardsList data={getAllBoardsList} label={`${selectedTab} Boards`} />
          </div>
        ) : null}
      </div>

      {EditModalVisible ? (
        <CreateNewBoard
          data={EditBoardData}
          edit={Object.keys(EditBoardData)?.length ? true : false}
          handleClose={() => setEditModalVisible((prev) => !prev)}
        />
      ) : null}
    </div>
  );
};

export default MyBoards;
