import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import AddQuestionType from "./addQuestionType";
import AddQuestionTypeList from "./addQuestionTypeList";
import { validateProposalQuestionFormError } from "../../../../utlis/hof/hof";
const AddSectionAndQuestion = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const addType = props?.addType;
  const allData = props?.allData;
  //   const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [fields, setFields] = useState(addType == "section" ? {} : []);
  const [proposalQuestionFormError, setProposalQuestionFormError] = useState({});
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const questionListIndex = props?.questionListIndex;
  const proposalBasicDetails = fields;
  const data = addType == "section" ? props?.data : props?.data;
  useEffect(() => {
    setFields(addType == "section" ? props?.data : questionListIndex ? props?.data : {});
  }, [data]);

  const questionFields = (val) => {
    // let qList = fields?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.length
    //   ? fields?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]
    //   : [];
    // qList.push(val);

    const questionTyp = val?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]
      ? val?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]
      : "Text Box";
    if (
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button"
    ) {
      setFields({
        ...val,
        [ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]: questionTyp,
      });
    } else {
      setFields({
        ...val,
        [ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE]: questionTyp,
        [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: {
          [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: "",
        },
      });
    }
  };
  const questionTypeList = (val, index) => {
    let metaData = fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
      ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]
      : [];
    metaData[index] = val;
    setFields({
      ...fields,
      [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: metaData,
    });
  };
  const handleAddOptionsClicked = (val) => {
    let metaData = fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
      ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]
      : [];
    metaData.push({
      [ADD_QUESTION_CONFIG_KEYS?.QUESTION_OPTIONS]: "",
      [ADD_QUESTION_CONFIG_KEYS?.ANSWER]: null,
    });
    setFields({
      ...fields,
      [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: metaData,
    });
  };
  const handleDeleteQuestionOptions = (val) => {
    let metaData = fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.length
      ? fields?.[ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]
      : [];
    metaData.splice(val, 1);
    setFields({
      ...fields,
      [ADD_QUESTION_CONFIG_KEYS?.PROPOSAL_META_DATA]: metaData,
    });
  };
  const handleSaveClicked = () => {
    let validateErrors = {};
    if (addType == "section") {
      validateErrors = validateProposalQuestionFormError(fields, addType, false, allData);
    } else if (
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button"
    ) {
      validateErrors = validateProposalQuestionFormError(fields, addType, true);
    } else {
      validateErrors = validateProposalQuestionFormError(fields, addType, false);
    }
    setProposalQuestionFormError(validateErrors);
    setTimeout(() => {
      setProposalQuestionFormError({});
    }, 3000);
    if (!Object.keys(validateErrors)?.length) {
      props?.handleSaveClicked({ ...fields, User_ID: userId });
    }
  };
  return (
    <div
      className={`confirm-delete-portal-main-wrapper ${
        addType == "section" ? "" : "confirm-delete-portal-main-wrapper--width"
      } p-r`}
    >
      <div className="add-section-and-question-header-wrapper">
        <div>
          <h3 className="add-section-and-question-content-header-text">
            {addType == "section"
              ? PROPOSAL_ADD_SECTION?.SECTION_HEADER
              : `${questionListIndex ? "Edit" : "Add"} Question (${props?.questionLength ? props?.questionLength : 1})`}
          </h3>
        </div>
        {questionListIndex ? (
          <div
            onClick={questionListIndex ? props?.handleDeleteClicked : props?.handleClose}
            className="dis-f-r cursor--pointer"
            style={{
              borderRadius: "5px",
              border: "1px solid #ffff",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "5px 10px",
            }}
          >
            <p className="add-section-and-question-delete-text">{PROPOSAL_ADD_SECTION?.SECTION_DELETE}</p>
            <FeatherIcon icon="trash" size="12" color="#ffffff" />
          </div>
        ) : null}
      </div>
      {/* <div className="add-section-and-question-header-content-wrapper"> */}
      {addType == "section" ? (
        <div class="field p-r m-y-company-profile-input-wrapper m-x-sm" id="field-user__email">
          {proposalBasicDetails?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE] ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {PROPOSAL_ADD_SECTION?.SECTION_TITLE}
            </label>
          ) : null}
          <InputFieldProfile
            label={PROPOSAL_ADD_SECTION?.SECTION_TITLE}
            value={proposalBasicDetails?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]}
            changeText={(val) =>
              setFields({
                ...fields,
                [ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]: val,
              })
            }
            error={proposalQuestionFormError?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]}
          />
        </div>
      ) : (
        <AddQuestionType
          questionFields={questionFields}
          fields={fields}
          proposalQuestionFormError={proposalQuestionFormError}
        />
      )}
      {fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button" ? (
        <AddQuestionTypeList
          inputType={fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ? "checkbox" : "radio"}
          questionTypeList={questionTypeList}
          handleAddOptionsClicked={handleAddOptionsClicked}
          handleDeleteQuestionOptions={handleDeleteQuestionOptions}
          fields={fields}
          proposalQuestionFormError={proposalQuestionFormError}
        />
      ) : null}
      {/* </div> */}
      <div className="confirm-delete-portal-header-actions-wrapper ">
        <div onClick={handleSaveClicked}>
          <p className="m-x-sm add-section-and-question-action-save-text cursor--pointer">
            {PROPOSAL_ADD_SECTION?.SAVE}
          </p>
        </div>
        <div onClick={props?.handleClose}>
          <p className="add-section-and-question-action-cancel-text cursor--pointer">{PROPOSAL_ADD_SECTION?.CANCEL}</p>
        </div>
      </div>
    </div>
  );
};

export default AddSectionAndQuestion;
