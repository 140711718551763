import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './contexts/redux/reducer';
import './styles/product-expert.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.href && window.location.href.includes('octate')) {
  // console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        {/* <DirectMessageWrapper> */}
        <App />
        {/* </DirectMessageWrapper> */}
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
