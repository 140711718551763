import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
// import * as KEYS from "../../../config/config";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import * as SECRET_KEYS from "../../../config/configKeys";
const DataProviderFooter = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const DATA = props?.data;
  const PROVIDER_DATA = KEYS?.PROVIDER_DATA;
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const signalTrackingVendorsObject = {
    Action: SECRET_KEYS.CONFIG.VIEW_VEDNOR_CLICKED,
    page: "vendor page",
    section: props?.section,
    context: DATA?.Vendor_Name,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleViewVendorClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorsObject?.Action,
      Item_Type: signalTrackingVendorsObject?.page,
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorsObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorsObject?.section,
        context: DATA?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      <div className="vertical-provider-card__footer">
        <a
          className="vertical-provider-card__cta small primary modern button"
          onClick={() =>
            handleViewVendorClicked(
              `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
                KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
              }`
            )
          }
          href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
            KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
          }`}
        >
          {PROVIDER_DATA?.VIEW_ALL}
          <i className="feather icon icon-arrow-right "></i>
        </a>
      </div>
    </>
  );
};

export default DataProviderFooter;
