import React, { useState } from "react";
import { useSelector } from "react-redux";
import EmailVerificationOctate from "../../signUp/emailVerification/emailVerificationOctate";
import InvitePasswordForm from "./invitePasswordForm";
import InviteOtp from "../inviteOtp/inviteOtp";

const InvitePassword = () => {
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const tempDetails = useSelector((state) => state?.authDetails?.tempDetails);

  return (
    <main>
      <div class="page-content page-content--reset ">
        <div class="splitted-page">
          <EmailVerificationOctate />
          {PasswordVisible ? (
            <InvitePasswordForm handleModalVisible={() => setPasswordVisible((prev) => !prev)} />
          ) : (
            <InviteOtp />
          )}
        </div>
      </div>
    </main>
  );
};

export default InvitePassword;
