import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  useAddProductsApiMutation,
  useAddProductsPutApiMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../adapters/xhr/generalApi";
import * as ENV_KEYS from "../../config/configKeys";
import { editProductDetails } from "../../contexts/reducer/homeReducer";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import {
  ReplaceBlankSpaceByHyphen,
  validateCompanyProfilePage3,
  validateProductProfilePage1,
  validateProductProfilePage2,
  validateProductProfilePageWithoutCategory,
} from "../../utlis/hof/hof";
import CompanyDetailsFooter from "../companyProfile/component/companyDetailsFooter";
import CompanyDetailsHeader from "../companyProfile/component/companyDetailsHeader";
import ProductDetailsList from "./component/productDetailsList";

const AddProduct = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyErrors, setCompanyErrors] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const authData = useSelector((state) => state?.authDetails?.details);
  const REQUEST_CATEGORY = useSelector((state) => state?.homeDetails?.requestCategoryStatus);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const editProduct = props?.editProduct;

  // https://thebox-dev.portqii.com/core/vendor/productOnboardingGetProduct?Product_ID=17258&User_ID=546
  const GetProductDataFromApi = useEnCryptGetApi(
    `/vendor/productOnboardingGetProduct?Product_ID=${props?.Id}&User_ID=${authData?.data?.userId}&status=${vendorDetails?.Status}&Vendor_ID=${vendorId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (GetProductDataFromApi?.data?.data) {
      if (editProduct) {
        setProductDetails(GetProductDataFromApi?.data?.data);
      }
    }
  }, [GetProductDataFromApi?.data]);
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;

  const footerList = [
    {
      name: "Basic Details",
      id: 1,
    },
    {
      name: "Add Features",
      id: 2,
    },
    {
      name: "Customers",
      id: 3,
    },
  ];
  const validateEmailId = authData?.data?.emailId
    ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
    : null;
  const RequestMethod = editProduct ? useAddProductsPutApiMutation : useAddProductsApiMutation;
  const RequestApi = editProduct ? `/vendor/productOnboardingUpdate` : `/vendor/productOnboarding`;
  const vendorPostData = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, RequestMethod);
  useEffect(() => {
    if (vendorPostData?.data?.status) {
      setUpdateStatus(true);
      props?.handleClose(false);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: editProduct ? ENV_KEYS.CONFIG.PRODUCT_UPDATED : ENV_KEYS.CONFIG.PRODUCT_CREATED,
        Item_Type: "Product",
        Item_Id: productDetails?.Product_ID ? productDetails?.Product_ID : vendorPostData?.data?.Product_ID,
        Target: {
          page: "Product detail page",
          link: `/${ReplaceBlankSpaceByHyphen(productDetails?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
            productDetails?.Product_Name,
            productDetails?.Product_ID ? productDetails?.Product_ID : vendorPostData?.data?.Product_ID
          )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`,
        },
        Object: {
          section: null,
          context: editProduct ? `Product updated` : `Product created`,
          page: "/business-profile/product",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
      // setTimeout(() => {
      //   navigate("/");
      // }, 2000);
    } else if (vendorPostData?.data?.status === false) {
      setErrorModalVisible(true);
      setTimeout(() => {
        setErrorModalVisible(false);
      }, 5000);
    }
  }, [vendorPostData?.data]);
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    const Validate3 = validateCompanyProfilePage3(productDetails);
    if (val <= 2) {
      const Validate1 = REQUEST_CATEGORY
        ? validateProductProfilePageWithoutCategory(productDetails)
        : validateProductProfilePage1(productDetails);
      setCompanyErrors(Validate1);
      setTimeout(() => {
        setCompanyErrors({});
      }, 3000);
      if (!Object.keys(Validate1)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 3) {
      // const Validate2 = validateProductProfilePage2(productDetails);
      // setCompanyErrors(Validate2);
      // setTimeout(() => {
      //   setCompanyErrors({});
      // }, 3000);
      // if (!Object.keys(Validate2)?.length) {
      setFooterNumberClicked(val);
      // }
    } else if (val <= 4) {
      setFooterNumberClicked(val);
    } else {
      return;
    }
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  const handleSaveClicked = () => {
    // const Validate2 = validateCompanyProfilePage2(productDetails);
    // setCompanyErrors(Validate2);
    // if (!Object.keys(Validate2)?.length) {
    const Validate2 = validateProductProfilePage2(productDetails);
    setCompanyErrors(Validate2);
    const FilterEmptyFeatureDataData =
      productDetails?.Product_Feature?.length > 1
        ? productDetails?.Product_Feature
        : productDetails?.Product_Feature?.filter((val) => {
            return val?.Feature_Group_Name;
          });
    const FilterEmptyPricingDataData =
      productDetails?.Product_Pricing?.length > 1
        ? productDetails?.Product_Pricing
        : productDetails?.Product_Pricing?.filter((val) => {
            return val?.Plan_Name && val?.Price;
          });
    // if (!Object.keys(Validate2)?.length) {
    vendorPostData.handleTrigger({
      ...productDetails,
      Product_Pricing: FilterEmptyPricingDataData,
      Product_Feature: FilterEmptyFeatureDataData,
      User_ID: authData?.data?.userId,
      Vendor_ID: vendorId,
    });
    dispatch(
      editProductDetails({
        ...productDetails,
        Product_Pricing: FilterEmptyPricingDataData,
        Product_Feature: FilterEmptyFeatureDataData,
        User_ID: authData?.data?.userId,
        Vendor_ID: vendorId,
        Modified_User: authData?.data?.userName,
        Modify_At: Math.floor(Date.now() / 1000),
      })
    );
    setTimeout(() => {
      dispatch(editProductDetails({}));
    }, 5000);
    // }

    // }
  };
  return (
    <div className="company-profile-main-wrapper">
      {errorModalVisible ? (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "10px",
            width: "98vw",
          }}
        >
          <div
            class="modern-message modern-message--positive modern-message--elevated modal-error-color"
            style={{ alignItems: "center" }}
          >
            <i class="feather icon icon-alert-circle message__icon" style={{ color: "red" }}></i>
            <div class="message__content">
              <p class="modal-error-text">Product Already exists</p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="company-details-header-main-wrapper-ref">
        <CompanyDetailsHeader
          productPageLabel={editProduct ? ADD_PRODUCT?.PRODUCT_PAGE_TITLE_EDIT : ADD_PRODUCT?.PRODUCT_PAGE_TITLE}
          handleClose={props?.handleClose}
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="company-profile-details-main-container-ref">
        <ProductDetailsList
          productStaticData={ADD_PRODUCT}
          validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
          footerNumberClicked={footerNumberClicked}
        />
      </div>
      <div className="company-details-footer-main-wrapper">
        <CompanyDetailsFooter
          // productStaticData={ADD_PRODUCT}
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={vendorPostData}
        />
      </div>
    </div>
  );
};

export default AddProduct;

{
  /*
  "ADD_PRODUCT":{
    "SUCCESS_MESSAGE":'Product details updated successfully',
    "PRODUCT_PAGE_TITLE":"New Product"
    "PRODUCT_NAME":'Product Name',
    "PRODUCT_NAME_LABEL":'Product Name',
    "SUPPORTED_LANGUAGES":"Supported Languages",
    "SUPPORTED_LANGUAGES_LABEL":"Supported Languages",
    "SELECT_PRODUCT_SEGMENT":"Select Product Segment",
    "SELECT_PRODUCT_SEGMENT_LABEL":"Select Product Segment",
    "SELECT_INDUSTRY":"Select Industry",
    "SELECT_INDUSTRY_LABEL":"Select Industry",
    "SOFTWARE_TYPE":"Software Type",
    "SOFTWARE_TYPE_LABEL":"Software Type",
    "DESCRIPTION":"Product Description",
    "DESCRIPTION_LABEL":"Product Description",
    "BACK":'Back',
    "SAVE":'Save',
    "NEXT":'Next',
  }
  */
}
