import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ToTitleCase } from "../../../../../utlis/hof/hof";
import RelatedSearch from "./relatedSearch";
import { useParams } from "react-router";

// import * as KEYS from "../../../../../config/config";

function CategoryDescription(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAMS = useParams();
  const PRODUCT_CAT_ID = GetIdFormLink(PARAMS?.name)?.Id;

  const GetApiData = useEnCryptGetApi(
    `/getProductCategoryDescription?id=${PRODUCT_CAT_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  return (
    <div className="landing-page__header">
      <h1 className="landing-page__title">{props?.vendorName}</h1>

      <div className="landing-page__description m-b-xs">
        <div>
          <p>
            <div>
              {KEYS?.CATEGORY_PAGE.PRODUCT_PAGE_CAT_QUES} {props?.vendorName} ?
            </div>
          </p>
          <p>
            {/* {KEYS?.CATEGORY_PAGE.PRODUCT_PAGE_CAT_DISC}  */}
            {GetApiData?.data?.data?.Product_Category_Description}
            {/* <a
              className="m-l-base arrow-right-suffix"
              data-controller="active-anchor-link scroll-to-target"
              data-scroll-target="#guide"
              data-scroll-target-offset="40"
              data-active-anchor="#guide"
              href="#"
            >
              {KEYS?.CATEGORY_PAGE.PRODUCT_PAGE_CAT_LEARNMORE_LBL}
            </a> */}
          </p>
        </div>
      </div>

      {/* <div className="m-t-sm">
        <div className="text-grey-4 text-md fw-400 m-b-base">
          {KEYS?.CATEGORY_PAGE.PRODUCT_PAGE_CAT_RELATEDSEARCH_LBL}
        </div>
        <RelatedSearch />
      </div> */}
    </div>
  );
}

export default CategoryDescription;
