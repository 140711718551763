import React from "react";
import { createSlice } from "@reduxjs/toolkit";
//  this component is used to handle the the data of the detail blurb selected
export const chatReducer = createSlice({
  name: "chat",
  initialState: {
    selectedVendor: {},
    selectedUser: {},
    userChatList: [],
    octateVendor: [],
  },
  reducers: {
    selectedVendorFn: (state, action) => {
      state.selectedVendor = action.payload;
    },
    octateVendorFn: (state, action) => {
      state.octateVendor = action.payload;
    },
    selectedUserFn: (state, action) => {
      state.selectedUser = action.payload;
    },
    userChatListFn: (state, action) => {
      state.userChatList = action.payload;
    },
  },
});

export const { selectedVendorFn, selectedUserFn, userChatListFn, octateVendorFn } = chatReducer.actions;
export default chatReducer.reducer;
