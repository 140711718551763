import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
// import QuestionList from "./editProposalComponent/questionList";
// import SelectedQuestion from "./editProposalComponent/selectedQuestion";
import * as CONFIG_KEYS from "../../../config/config";
import QuestionList from "../../answerProposal/component/editProposalComponent/questionList";
import SelectedQuestion from "../../answerProposal/component/editProposalComponent/selectedQuestion";
const ShowVendorProposal = (props) => {
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const proposalType = props?.proposalType;
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const screenToShow = props?.footerNumberClicked;
  const [selectedQuestion, setSelectedQuestion] = useState({
    sectionIndex: null,
    questionIndex: null,
  });
  const vendorDetails = props?.vendorDetails;
  const Vendor_Id = vendorDetails?.[PROPOSAL_KEYS?.VENDOR_ID];
  const proposalId = props?.proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID];
  const notificationJson = props?.notificationJson;
  // getVendorProposalResponse
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getVendorProposalResponse?Proposal_ID=${proposalId}&Vendor_ID=${Vendor_Id}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );
  const proposalDetails = GetProductDataFromApi?.data?.data;

  const handleQuestionClicked = (sectionIndex, questionIndex) => {
    setSelectedQuestion({
      sectionIndex: sectionIndex,
      questionIndex: questionIndex,
    });
  };
  return (
    <div className="answer-proposal-grid-business-profile m-t-sm m-x-mini mobile-1-col">
      <div
        className="mobile-hidden"
        style={{ alignItems: "start", textAlign: "start" }}
      >
        <QuestionList
          notificationJson={notificationJson}
          Vendor_Id={Vendor_Id}
          handleQuestionClicked={handleQuestionClicked}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          type={props?.type}
          proposalDetails={proposalDetails}
          selectedQuestion={selectedQuestion}
        />
      </div>
      <div style={{ background: "#ffff", borderRadius: "15px" }}>
        <SelectedQuestion
          notificationJson={notificationJson}
          proposalId={proposalId}
          Vendor_Id={Vendor_Id}
          handleCloseSelectedVendor={props?.handleCloseSelectedVendor}
          proposalType={proposalType}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          selectedQuestionIndex={selectedQuestion}
          // StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={props?.handleDetails}
          proposalDetails={proposalDetails}
        />
      </div>
    </div>
  );
};

export default ShowVendorProposal;
