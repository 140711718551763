import React from "react";

const SideDrawerButton = (props) => {
  const handleSideBarClicked = () => {
    props?.handleSideBarClicked(true);
  };
  return (
    <div
      onClick={handleSideBarClicked}
      className="navbar__menu-burger-btn no-tap-highlight"
      data-controller="slide-in-toggle"
      data-slide-in-target=".navbar__mobile-menu"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default SideDrawerButton;
