import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
const ShareDetailList = (props) => {
  const DATA = props?.data;
  const HOST_NAME = new URL(window.location.href);
  const NEW_URL = HOST_NAME.protocol + HOST_NAME.hostname;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PAGE_URL = `${window.location.origin}/board/${ReplaceBlankSpaceByHyphen(DATA?.Name, DATA?.Board_ID)}`;
  const iconSize = 25;
  const title =
    props?.cardType == "product"
      ? props?.data?.Product_Name
      : props?.cardType == "vendor-page"
      ? props?.data?.Vendor_Name
      : props?.cardType
      ? props?.data?.title_level_1
      : " share";

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/board/${ReplaceBlankSpaceByHyphen(DATA?.Name, DATA?.Board_ID)}?utm_source=octate_link`
    );
    props?.shareSignalTracking("copied to clipboard");
    props?.handleToggleMenu();
  };
  return (
    <>
      <div className="share-single-item ">
        <div
          onClick={() => handleCopyToClipboard()}
          style={{ width: "100%", padding: "5px", borderBottom: "1px solid #e1e2e9" }}
          className="flex align-items-center p-y-mini"
        >
          <img
            src={require("../../assets/images/Share icons/3.svg").default}
            height={30}
            width={30}
            className="m-x-base"
          />
          <p className="share-desc-title">Copy link</p>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("twitter share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <TwitterShareButton url={`${PAGE_URL}?utm_source=twitter`} title={title} className="flex">
            <img
              src={require("../../assets/images/Share icons/4.svg").default}
              height={30}
              width={30}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Twitter</p>
          </TwitterShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("linkedin share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <LinkedinShareButton url={`${PAGE_URL}?utm_source=linkedin`} title={title} className="flex">
            <img
              src={require("../../assets/images/Share icons/1.svg").default}
              height={30}
              width={30}
              className="m-x-base"
            />
            <p className="share-desc-title">Share on Linkedin</p>
          </LinkedinShareButton>
        </div>
      </div>
      <div className="share-single-item">
        <div
          onClick={() => props?.shareSignalTracking("facebook share")}
          style={{ width: "100%", padding: "5px" }}
          className="flex align-items-center"
        >
          <FacebookShareButton url={`${PAGE_URL}?utm_source=facebook`} title={title} className="flex">
            <img
              src={require("../../assets/images/Share icons/2.svg").default}
              height={30}
              width={30}
              className="m-x-base"
            />
            <p className="share-desc-title"> Share on Facebook</p>
          </FacebookShareButton>
        </div>
      </div>
    </>
  );
};

export default ShareDetailList;
