import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
import ConfirmClosePortal from "../../../addProposal/component/confirmClosePortal";
import * as ADD_PROPOSAL_KEYS from "../../../../config/config";
const AnswerProposalHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [showCloseModal, setShowCloseModal] = useState(false);
  const footerNumberClicked = props?.footerNumberClicked;
  const isLoading = props?.isLoading;
  const proposalDetails = props?.proposalDetails;
  const Can_Edit = proposalDetails?.[PROPOSAL_KEYS.CAN_EDIT];
  const handleActionsClicked = (val) => {
    if (val == "close") {
      props?.handleClose();
    } else {
      props?.handleSaveAnswerClicked("Draft");
    }
  };
  return (
    <>
      {showCloseModal ? (
        <ConfirmClosePortal
          proposalPublished={props?.proposalPublished}
          vendorPostData={props?.vendorPostData}
          handleClose={() => setShowCloseModal(false)}
          handleActionsClicked={handleActionsClicked}
        />
      ) : null}
      <div className="company-details-header-main-wrapper">
        <div className="company-details-header-title-text">
          {props?.productPageLabel}
          {/* {props?.productPageLabel
          ? props?.productPageLabel
          : authData?.data?.Persona === "Business User"
          ? KEYS?.COMPANY_PROFILE?.EDIT_ORAGANIZATION
          : KEYS?.COMPANY_PROFILE?.NEW_ORGANIZATION} */}
        </div>
        <div
          // onClick={() => props?.handleClose()}
          className="answer-proposal-header-title-icon"
        >
          {footerNumberClicked > 1 && Can_Edit ? (
            <div
              onClick={() => props?.handleFooterNumberClicked(props?.footerNumberClicked - 1)}
              className="company-details-footer-back m-x-mini"
            >
              {/* {COMPANY_PROFILE?.NEXT} */}
              Preview
            </div>
          ) : Can_Edit ? (
            <div
              onClick={() => props?.handleFooterNumberClicked(props?.footerNumberClicked + 1)}
              className="company-details-footer-back m-x-mini"
            >
              {/* {COMPANY_PROFILE?.NEXT} */}
              Edit
            </div>
          ) : null}
          {footerNumberClicked > 1 && Can_Edit ? (
            <>
              {isLoading ? (
                <div style={{ height: "38px", width: "55px" }} className="company-details-footer-next m-r-sm">
                  <LoadingSpinner size={"small"} />
                </div>
              ) : (
                <>
                  <div
                    onClick={() => props?.handleSaveAnswerClicked("Published")}
                    className="company-details-footer-back m-x-mini"
                  >
                    {/* {COMPANY_PROFILE?.NEXT} */}
                    Publish
                  </div>
                  <div
                    onClick={() => props?.handleSaveAnswerClicked("Draft")}
                    className="company-details-footer-back m-x-mini"
                  >
                    {/* {COMPANY_PROFILE?.NEXT} */}
                    Save
                  </div>
                </>
              )}
            </>
          ) : null}

          <div onClick={() => setShowCloseModal(true)} className="company-details-footer-next">
            {/* {COMPANY_PROFILE?.NEXT} */}
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default AnswerProposalHeader;
