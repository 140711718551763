import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import AddSectionAndQuestionPortal from "../addSectionAndQuestionPortal/addSectionAndQuestionPortal";
import TextArea from "../../../../utlis/hoc/textArea/textArea";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import moment from "moment";
const AddQuestionWithQuestion = (props) => {
  const [showPortal, setShowPortal] = useState(false);
  const [showEditPortal, setShowEditPortal] = useState({
    showEditPortal: false,
    index: null,
  });

  const hideAddQuestion = props?.hideAddQuestion;
  const hideAddAndEditQuestion = props?.hideAddAndEditQuestion;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_PROPOSAL = KEYS?.ADD_PROPOSAL;
  const ADD_PROPOSAL_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const proposalQuestionArray = props?.proposalQuestionArray;
  const handleSaveClicked = (val) => {
    props?.handleSaveClicked(val);
    setShowPortal(!showPortal);
  };
  const handleEditClicked = (val) => {
    setShowEditPortal({ showEditPortal: true, index: val });
  };
  const handleAddQuestionClicked = () => {
    setShowPortal(!showPortal);
  };
  const handleEditSaveClicked = (val) => {
    props?.handleSaveClicked(val, showEditPortal?.index + 1);
    setShowEditPortal({ showEditPortal: false, index: null });
  };
  const handleDeleteClicked = (val) => {
    props?.handleDeleteClicked(showEditPortal?.index);
    setShowEditPortal({ showEditPortal: false, index: null });
  };

  return (
    <>
      {showPortal ? (
        <AddSectionAndQuestionPortal
          handleClose={() => setShowPortal(false)}
          addType={"question"}
          handleSaveClicked={handleSaveClicked}
          data={props?.proposalQuestionArray}
          questionLength={proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.length + 1}
        />
      ) : null}
      {showEditPortal?.showEditPortal ? (
        <AddSectionAndQuestionPortal
          handleClose={() => setShowEditPortal({ showEditPortal: false, index: null })}
          addType={"question"}
          questionListIndex={true}
          handleSaveClicked={handleEditSaveClicked}
          handleDeleteClicked={handleDeleteClicked}
          questionLength={showEditPortal?.index + 1}
          data={proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST][showEditPortal?.index]}
        />
      ) : null}
      <div className="proposal-show-questions-question-container">
        {proposalQuestionArray?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_LIST]?.map((list, index) => {
          return (
            <div className="proposal-show-question-list-main-wrapper">
              <div className="proposal-show-question-list-question-main-wrapper">
                <div className="proposal-show-question-list-question-wrapper">
                  <p className="proposal-show-question-list-question-number">{index + 1}</p>
                  <p className="proposal-show-question-list-question-title">
                    {list?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_NAME]}
                  </p>
                </div>
                {list?.[ADD_PROPOSAL_CONFIG_KEYS?.STATUS] !== "Published" && !props?.hideAddQuestion ? (
                  <p onClick={() => handleEditClicked(index)} className="proposal-show-question-list-edit-icon">
                    <FeatherIcon
                      icon="edit-3"
                      size="20"
                      role="button"
                      // className="blurb-detail-social"
                      color={"#ABAFB1"}
                      // onClick={() => handleEditFAQ(item)}
                      style={{ marginLeft: "10px" }}
                    />
                  </p>
                ) : hideAddAndEditQuestion ? null : (
                  <p onClick={() => handleEditClicked(index)} className="proposal-show-question-list-edit-icon">
                    <FeatherIcon
                      icon="edit-3"
                      size="20"
                      role="button"
                      // className="blurb-detail-social"
                      color={"#ABAFB1"}
                      // onClick={() => handleEditFAQ(item)}
                      style={{ marginLeft: "10px" }}
                    />
                  </p>
                )}
              </div>

              {list?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ? (
                <div className="proposal-show-question-list-checkbox-question-wrapper">
                  {list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.map((metaData) => {
                    return (
                      <div className="proposal-show-question-list-checkbox-question-options-wrapper">
                        <input
                          type="checkbox"
                          // disabled
                          className="checkbox-input-style-proposal-add-question-options-type-list p-x-base"
                          checked={
                            metaData?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                              ? metaData?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                              : false
                          }
                        ></input>
                        <p className="proposal-show-question-list-question-options-title">
                          {metaData?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_OPTIONS]}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : list?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button" ? (
                <div className="proposal-show-question-list-checkbox-question-wrapper">
                  {list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.map((metaData) => {
                    return (
                      <div className="proposal-show-question-list-checkbox-question-options-wrapper">
                        <input
                          type="radio"
                          // disabled
                          className="checkbox-input-style-proposal-add-question-options-type-list p-x-base"
                          checked={
                            metaData?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                              ? metaData?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                              : false
                          }
                        ></input>
                        <p className="proposal-show-question-list-question-options-title">
                          {metaData?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_OPTIONS]}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : list?.[ADD_SECTION_CONFIG_KEYS?.QUESTION_TYPE] == "Date Picker" ? (
                <div className="proposal-show-question-list-answer-main-wrapper">
                  <div
                    class="field p-r m-x-sm"
                    style={{
                      width: "80%",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                    id="field-user__email"
                  >
                    <InputFieldProfile
                      label={"dd/mm/yy"}
                      icon={"calendar"}
                      disable={true}
                      removeBottomPadding={true}
                      value={
                        list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA][ADD_SECTION_CONFIG_KEYS?.ANSWER]
                          ? moment(
                              list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA][ADD_SECTION_CONFIG_KEYS?.ANSWER] *
                                1000
                            ).format("DD MMM yyyy")
                          : null
                      }
                      // changeText={(val) =>
                      //   props?.handleDetails({
                      //     ...list,
                      //     [ADD_SECTION_CONFIG_KEYS?.ANSWER]: val,
                      //   })
                      // }
                      // error={error?.[ADD_PRODUCT_CONFIG_KEYS?.BUSINESS_DOMAIN]}
                    />
                  </div>
                </div>
              ) : (
                <div className="proposal-show-question-list-answer-main-wrapper">
                  <div class="field p-r m-x-sm" id="field-user__phone_number_prefix" style={{ width: "80%" }}>
                    {list?.[ADD_SECTION_CONFIG_KEYS?.ANSWER] ? (
                      <label for="user__email" className="company-details-label-input-field-filled-absolute">
                        Enter your text here
                        {/* {ADD_PROPOSAL?.DESCRIPTION} */}
                      </label>
                    ) : null}
                    <TextArea
                      disabled={true}
                      removeMargin={true}
                      value={
                        list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                          ? list?.[ADD_SECTION_CONFIG_KEYS?.PROPOSAL_META_DATA]?.[ADD_SECTION_CONFIG_KEYS?.ANSWER]
                          : ""
                      }
                      className="m-b-sm"
                      label={""}
                      // changeText={(val) =>
                      //   props?.handleDetails({
                      //     ...proposalBasicDetails,
                      //     [ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_DESCRIPTION]: val,
                      //   })
                      // }
                      // subTitleTop={
                      //   "Please add at least 50 characters to describe your answer."
                      // }
                      // error={
                      //   error?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_DESCRIPTION]
                      // }
                    />
                  </div>
                </div>
              )}
              {list?.[ADD_SECTION_CONFIG_KEYS?.USER_NAME] ? (
                <div className="proposal-preview-proposal-username-wrapper">
                  <p>Created by:</p>
                  <p>{list?.[ADD_SECTION_CONFIG_KEYS?.USER_NAME]}</p>
                </div>
              ) : null}
            </div>
          );
        })}
        {/* <div className="proposal-add-questions-add-question-sub-container">
          <img
            class=" ls-is-cached lazyloaded"
            src={
              require("../../../../assets/images/emptyQuestionSvg.svg").default
            }
            style={{ height: "65px", width: "65px" }}
            alt={"profile"}
          />
          <p className="proposal-add-section-no-question-to-display-text">
            {ADD_PROPOSAL?.ADD_Q_NO_QUESTION_TO_DISPLAY}
          </p>
        </div> */}
        {hideAddQuestion ? null : (
          <div className="proposal-add-questions-add-question-sub-container cursor--pointer">
            <div
              onClick={handleAddQuestionClicked}
              style={{
                // border: "1px solid #5570f1",
                // borderRadius: "5px",
                display: "flex",
                justifyContent: "start",
                width: "60%",
                alignItems: "center",
                flexDirection: "row",
                padding: "5px 10px",
              }}
            >
              <FeatherIcon icon="plus" size="15" color="#5570f1" />
              <p className="proposal-add-question-add-question-text p-x-base">{ADD_PROPOSAL?.ADD_Q_NEW_QUESTION}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddQuestionWithQuestion;
