import React from 'react';
import { useSelector } from 'react-redux';
import { detectMob } from '../../../../../utlis/hof/hof';
import TabNavigator from '../../../../../utlis/hoc/tabNavigator/tabNavigator';
import ProductExpDetails from './productExpDetails';

const ProductExpHeader = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PRODUCT_EXPERT_TAB = props?.tabData;

  const MOBILE_DEVICE = detectMob();

  return (
    <div className='business-profile-container'>
      <ProductExpDetails page={props?.page} />
      <div className='flex tab-navigator-container'>
        {PRODUCT_EXPERT_TAB?.map((item, index) => {
          if (item?.display || MOBILE_DEVICE)
            return (
              <TabNavigator page={props?.page} tabIndex={props?.tabIndex} tabItem={item} index={index + 1} navigatePath={'productExpert'} />
            );
        })}
      </div>
    </div>
  );
};

export default ProductExpHeader;
