import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import ChatCard from "./chatCard";

function AllChatList(props) {
  const CHAT_LIST = props?.data;
  return (
    <div style={{ borderRight: "1px solid #d3d3d3", overflow: "auto" }} class="p-x-sm m-t-base">
      <div class="dtrd-form--modern dtrd-form">
        {CHAT_LIST?.map((item, index) => {
          return (
            <ChatCard
              // activeConversionId={props?.activeConversionId}
              // handleActiveConversionId={props?.handleActiveConversionId}
              allData={CHAT_LIST}
              featureActiveList={props?.featureActiveList}
              newIndex={index}
              data={item}
              handleFeatureList={props?.handleFeatureList}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AllChatList;
let data = {
  Conversation_ID: 16,
  User_ID: 35,
  User_Name: "sanket rannore",
  Message_Text: "Port dev new",
  Date_Time: 1684489212,
};
