import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import SmallCardSkeleton from "../../../../utlis/hoc/skeleton/smallCardSkeleton";
import VendorCard from "../../../../utlis/hoc/vendorCard/vendorCard";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import * as NO_CONTENT from "../../../../config/config";

function ProductCustomers(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));

  const GetApiData = useEnCryptGetApi(
    `/getProductCustomers?productId=${PRODUCT?.Id}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const CUSTOMERS = GetApiData?.data?.data;

  return (
    <div class="provider-profile__main-content-section one-column-grid" id="provider-profile-alternatives">
      {GetApiData?.isLoading || GetApiData?.isFetching ? (
        <div className="grid-two-columns">
          <SmallCardSkeleton skeletonLength={4} />
        </div>
      ) : CUSTOMERS?.length ? (
        <VendorCard page={props?.page} section={props?.page} data={CUSTOMERS} tabIndex={props?.tabIndex} />
      ) : GetApiData?.data?.data?.length === 0 ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">{NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.PRODUCT_PAGE_CUSTOMER}</div>
        </div>
      ) : null}
    </div>
  );
}

export default ProductCustomers;
