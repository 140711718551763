import React, { memo } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import * as SECRET_KEYS from "../../../../../config/configKeys";
const ProfileHoverDetails = (props) => {
  const userName = props?.userName;
  const emailAddress = props?.emailAddress;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  return userName ? (
    <div onClick={props?.handleCardClicked} className="navbar__sub-menu cursor--pointer" style={{ minWidth: "200px", left: "-180px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          textAlign: "center",
          height: "max-content",
          padding: "0px",
        }}
      >
        <p style={{ margin: "0px", padding: "0px", fontSize: "15px", fontWeight: "400" }}>{userName}</p>
        <p style={{ margin: "0px", fontSize: "10px", fontWeight: "400" }}>{emailAddress}</p>
      </div>
    </div>
  ) : null;
};
export default memo(ProfileHoverDetails);
