import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import { GetNameInitials } from "../../../../../utlis/hof/hof";
import { useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import { useEffect } from "react";
import { selectedVendorFn } from "../../../../../contexts/reducer/chatReducer";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import * as CONFIG_KEYS from "../../../../../config/config";
const VendorChatRoomInput = (props) => {
  const [error, setError] = useState("");
  const [commentText, setCommentText] = useState("");
  const [isAnonymousUser, setIsAnonymousUser] = useState(false);
  const textAreaRef = useRef(null);
  const VENDOR_ID = props?.VENDOR_ID;
  const VENDOR_INPUT_CHAT = CONFIG_KEYS.CONFIG.DIRECT_MESSAGE.VENDOR_CHAT;
  const dispatch = useDispatch();
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  //   const selectedVendorSelector = useSelector((state) => state?.chatDetails?.selectedVendor);
  const selectedVendorSelector = props?.selectedVendorData;
  const USER_ID = authData?.data?.userId;
  const fromVendor = props?.formVendor ? true : false;
  //   //   Post https://thebox-dev.portqii.com/directmessage/newMessage
  const PostComment = useEnCryptPostApi(
    `/newMessage`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    usePostRequestMutation
  );
  //   useEffect(() => {
  //     if (PostComment?.data?.Conversation_ID && !selectedVendorSelector?.Conversation_ID) {
  //       dispatch(
  //         selectedVendorFn({
  //           ...selectedVendorSelector,
  //           Conversation_ID: PostComment?.data?.Conversation_ID,
  //           Date_Time: parseInt(new Date().getTime() / 1000),
  //         })
  //       );
  //     }
  //   }, [PostComment?.data]);
  const initials = GetNameInitials(
    authData?.data?.userExist
      ? authData?.data?.userName
        ? authData?.data?.userName
        : authData?.data?.firstName + " " + authData?.data?.lastName
      : "Anon Name"
  );
  const handleSendClicked = (e) => {
    e.preventDefault();
    if (commentText) {
      const rawData = {
        Vendor_ID: VENDOR_ID,
        User_ID: USER_ID,
        Message_Text: commentText,
        Conversation_ID: selectedVendorSelector?.Conversation_ID ? selectedVendorSelector?.Conversation_ID : null,
        From_Vendor: fromVendor,
        Date_Time: parseInt(new Date().getTime() / 1000),
      };
      PostComment?.handleTrigger(rawData);
      setCommentText("");
    }
  };

  const handleInput = (element) => {
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
    if (textAreaRef.current.scrollHeight > 100) {
      textAreaRef.current.style.overflow = "auto";
    } else {
      textAreaRef.current.style.overflow = "hidden";
    }
  };

  const handleChangeText = (e) => {
    setCommentText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value);
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
      if (commentText) {
        const rawData = {
          Vendor_ID: VENDOR_ID,
          User_ID: USER_ID,
          Message_Text: commentText,
          Conversation_ID: selectedVendorSelector?.Conversation_ID ? selectedVendorSelector?.Conversation_ID : null,
          From_Vendor: fromVendor,
          Date_Time: parseInt(new Date().getTime() / 1000),
        };
        PostComment?.handleTrigger(rawData);
        setCommentText("");
      }
    }
    //Here you can even write the logic to select the value from the drop down or something.
  };
  return (
    <div className="comments-container m-b-sm">
      {selectedVendorSelector?.Disable_Flag ? (
        <div className="direct-message-send-message-main-container  ">
          <div className="text-area-container">
            <h2 className="direct-message-send-message-disable-chat-des">{VENDOR_INPUT_CHAT?.DISABLE_DESC}</h2>
          </div>
        </div>
      ) : (
        <div className="direct-message-send-message-main-container  ">
          <div className="text-area-container">
            <textarea
              ref={textAreaRef}
              style={{ backgroundColor: "rgba(239, 241, 249, 0.6)" }}
              rows={1}
              id="note"
              onKeyDown={onKeyDown}
              type={"text"}
              value={commentText}
              onInput={handleInput}
              onChange={(e) => handleChangeText(e)}
              placeholder={`Write a message`}
              className={`input-field-text ${error ? "background-error" : ""}`}
            />
          </div>
          <div className="send-message-text-container">
            <div onClick={handleSendClicked} className="send-message-button-container cursor--pointer">
              Send
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorChatRoomInput;
