import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetUserPermissionQuery, useGetUserPreferencesQuery } from "../../../../adapters/xhr/crudServiceApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import PreferencesList from "../../../managePreference/components/preferencesList";
import PreferencesSelection from "../../../managePreference/components/preferencesSelection";
import PermissionContent from "../permission/permissionContent";
import PermissionSets from "../permission/permissionSets";

function PermissionTab(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [featureActiveList, setFeatureActiveList] = useState(1);
  const userId = authData?.data?.userId;
  const vendorId = authData?.data?.vendorId;
  const permissionsDetails = props?.permissionsDetails;
  const GetPermissionSets = useEnCryptGetApi(
    `/permission/getPermissionSet?userId=${userId}&vendorId=${vendorId} `,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetUserPermissionQuery
  );
  const PermissionData = GetPermissionSets.data?.data?.length ? GetPermissionSets.data?.data : [];
  // /permission/getPermissionSet?userId=150
  // const DummyData = [
  // {
  //   Permission_Set_Name: "Product Expert",
  //   Permission_Set_ID: "123",
  //   Permissions: {
  //     Resource: [
  //       { Display_Name: "Controls", Internal_Name: "Control_Name" },
  //       { Display_Name: "Vendor", Internal_Name: "Vendor" },
  //       { Display_Name: "Product", Internal_Name: "Product" },
  //       { Display_Name: "Proposal", Internal_Name: "Proposal" },
  //     ],
  //     Controls: [
  //       {
  //         Vendor: true,
  //         Product: false,
  //         Proposal: "not_supported",
  //         Control_ID: 1,
  //         Control_Name: "Edit",
  //       },
  //       {
  //         Vendor: "not_supported",
  //         Product: "not_supported",
  //         Proposal: true,
  //         Control_ID: 2,
  //         Control_Name: "Answer",
  //       },
  //     ],
  //   },
  // },
  //   {
  //     Permission_Set_Name: "Admin",
  //     Permission_Set_ID: "123",
  //     Permissions: {
  //       Resource: [
  //         { Display_Name: "Controls", Internal_Name: "Control_Name" },
  //         { Display_Name: "Vendor", Internal_Name: "Vendor" },
  //         { Display_Name: "Product", Internal_Name: "Product" },
  //         { Display_Name: "Proposal", Internal_Name: "Proposal" },
  //       ],
  //       Controls: [
  //         {
  //           Vendor: true,
  //           Product: false,
  //           Proposal: "not_supported",
  //           Control_ID: 1,
  //           Control_Name: "Edit",
  //         },
  //         {
  //           Vendor: "not_supported",
  //           Product: "not_supported",
  //           Proposal: "not_supported",
  //           Control_ID: 2,
  //           Control_Name: "Answer",
  //         },
  //       ],
  //     },
  //   },
  // ];
  const NewDummyData = [
    {
      Permission_Set_Name: "Product Expert",
      Permission_Set_ID: 123,
      Resource: [
        {
          Resource_Name: "Vendor",
          Resource_ID: 1,
          Controls: [
            {
              Display_Name: "Create",
              Status: false,
              Can_Edit: false,
            },
            {
              Display_Name: "Read",
              Status: false,
              Can_Edit: false,
            },
            {
              Display_Name: "Update",
              Status: false,
              Can_Edit: true,
            },
            {
              Display_Name: "Delete",
              Status: false,
              Can_Edit: false,
            },
          ],
        },
        {
          Resource_Name: "Proposal",
          Resource_ID: 2,
          Controls: [
            {
              Display_Name: "Create",
              Status: false,
              Can_Edit: false,
            },
            {
              Display_Name: "Read",
              Status: false,
              Can_Edit: false,
            },
            {
              Display_Name: "Update",
              Status: false,
              Can_Edit: true,
            },
            {
              Display_Name: "Delete",
              Status: false,
              Can_Edit: false,
            },
          ],
        },
      ],
    },
  ];

  const handleFeatureList = (val) => {
    setFeatureActiveList(val);
  };
  function handleAddNewPermissionSet() {
    setFeatureActiveList((prev) => prev + 1);
  }
  return (
    <div className="grid-1-3 tablet-1-col container">
      <PermissionSets
        handleAddNewPermissionSet={handleAddNewPermissionSet}
        handleFeatureList={handleFeatureList}
        featureActiveList={featureActiveList}
        data={PermissionData}
      />
      <PermissionContent featureActiveList={featureActiveList} data={PermissionData} />
    </div>
  );
}

export default PermissionTab;

const data = [
  {
    Permission_Set_Name: "Product Expert",
    Permission_Set_ID: "123",
    Permission_Section: [
      {
        Section_Name: "Proposals",
        Section_Id: 123,
        Permissions: {
          Controls: [
            { Display_Name: "Resource", Internal_Name: "Resource_Name" },
            { Display_Name: "Add", Internal_Name: "Add" },
            { Display_Name: "Delete", Internal_Name: "Delete" },
            { Display_Name: "Update", Internal_Name: "Update" },
          ],
          Resource: [
            {
              Add: true,
              Update: false,
              Delete: "not_supported",
              Resource_ID: 1,
              Resource_Name: "Vendor",
            },
            {
              Add: true,
              Update: true,
              Delete: "not_supported",
              Resource_ID: 2,
              Resource_Name: "Blurb",
            },
          ],
        },
      },
    ],
  },
];

const updatePermissions = {
  Permission_Set_ID: 1,
  Section_Id: 22,
  Permissions: {
    Add: true,
    Update: true,
    Delete: "not_supported",
    Resource_ID: 2,
    Resource_Name: "Blurb",
  },
};
