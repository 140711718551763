import React, { useState } from "react";
import Header from "../header";
import BasicInformationContent from "./basicInformationContent";

const BasicInformation = (props) => {
  const [showSection, setShowSection] = useState(false);
  const proposalDetails = props?.proposalDetails;
  return (
    <div style={{ width: "100%" }}>
      <Header
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        headerTitle={"Basic Information"}
        handleScreenToShow={props?.handleScreenToShow}
      />
      {showSection ? (
        <BasicInformationContent preview={props?.preview ? true : false} proposalDetails={proposalDetails} />
      ) : null}
    </div>
  );
};

export default BasicInformation;
