import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import AnswerProposal from "../../../../answerProposal/anwerProposal";
// import AddProposal from "../../addProposal/addProposal";

const ProposalVendorPortal = (props) => {
  const tabIndex = props?.tabIndex;
  const Id = props?.Id;
  const editProduct = props?.editProduct;
  const showProposalModal = props?.showProposalModal;
  const notificationJson = props?.notificationJson;
  const permissionsDetails = props?.permissionsDetails;
  return ReactDom.createPortal(
    <>
      <div className=" ui dimmer modals page visible active portalModal" style={{ textAlign: "start" }}>
        <AnswerProposal
          permissionsDetails={permissionsDetails}
          editProduct={editProduct}
          notificationJson={notificationJson}
          screenIndex={showProposalModal?.index}
          handleClose={props?.handleClose}
          Id={Id}
        />
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")
  );
};

export default ProposalVendorPortal;
