import React from "react";
import { createSlice } from "@reduxjs/toolkit";
//  this component is used to handle the the data of the detail blurb selected
export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState: {
    dashboardFilter: [],
  },
  reducers: {
    dashboardFilterFn: (state, action) => {
      state.dashboardFilter = action.payload;
    },
  },
});

export const { dashboardFilterFn } = dashboardReducer.actions;
export default dashboardReducer.reducer;
