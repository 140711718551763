import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../../../utlis/customHooks/customHooks";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import BlurbTags from "../../../blurbDetail/components/blurb/blurbTags";
import { ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../../config/configKeys";
const RelatedTopics = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const [showRelatedTopics, setShowRelatedTopics] = useState(true);
  const [initialCount, setInitialCount] = useState(6);
  const page = props?.page;
  const signalTrackingAllCategoriesObject = {
    Action: SECRET_KEYS.CONFIG.RELATED_TOPIC_CLICKED,
    page: "topic page",
    section: "overview",
    context: "all categories",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleExploreCategoryLinkClicked = (item) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingAllCategoriesObject?.Action,
      Item_Type: "Topic",
      Item_Id: item?.Tag_Id,
      Target: {
        page: signalTrackingAllCategoriesObject?.page,
        link: `/topic/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Tag_Id)}/blurbs`,
      },
      Object: {
        section: signalTrackingAllCategoriesObject?.section,
        context: item?.Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const GetApiData = useEnCryptGetApi(
    props?.type === "products"
      ? `/getRelatedTopicsForProductPage?productId=${props?.ID}`
      : `/getRelatedTopicsForVendorPage?vendorId=${props?.ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery,
    props?.ID ? false : true
  );
  return GetApiData?.data?.data?.length ? (
    <aside className="m-t-sm">
      <div className="flex space-between" role="button" onClick={() => setShowRelatedTopics(!showRelatedTopics)}>
        <h3 className="overview-title" style={{ margin: "0px" }}>
          Related Topics
        </h3>
        <FeatherIcon icon={showRelatedTopics ? "chevron-down" : "chevron-right"} size="20" color={"#ABAFB1"} />
      </div>
      {showRelatedTopics ? (
        <>
          <div className="flex flex-wrap m-t-xs">
            {GetApiData?.data?.data?.slice(0, initialCount)?.map((item) => {
              return (
                <div onClick={() => handleExploreCategoryLinkClicked(item)}>
                  <a
                    href={`/topic/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Tag_Id)}/blurbs`}
                    // role="button"
                  >
                    <h6 className="blurb-detail-tags">{item?.Name}</h6>
                  </a>
                </div>
              );
            })}
          </div>
          {GetApiData?.data?.data?.length > 6 && (
            <a
              className="related-topics-show-more-button"
              onClick={() => setInitialCount((prev) => (prev > 6 ? 6 : GetApiData?.data?.data?.length))}
            >
              {initialCount == 6 ? "show more" : "show less"}
            </a>
          )}
        </>
      ) : null}
    </aside>
  ) : null;
};

export default RelatedTopics;
