import React from "react";
import AddingCustomer from "../../companyProfile/component/addingCustomers";
import AddFeature from "./addFeature";
import BasicProductDetails from "./basicProductDetails";
import PlanAndPricing from "./planAndPricing";

const ProductDetailsList = (props) => {
  const screenToShow = props?.footerNumberClicked;
  const productDetails = props?.productDetails;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {screenToShow == 1 ? (
        <BasicProductDetails
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : screenToShow == 2 ? (
        <AddFeature
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : (
        // <PlanAndPricing
        //   StaticDropdownData={StaticDropdownData}
        //   companyErrors={props?.companyErrors}
        //   handleDetails={handleDetails}
        //   productDetails={productDetails}
        // />
        <AddingCustomer
          data={productDetails}
          handleDetails={handleDetails}
          keyName={"Product_Customers"}
        />
      )}
    </div>
  );
};

export default ProductDetailsList;
