import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddProductsApiMutation,
  useBlurbProcessorMutation,
  usePostRequestMutation,
} from "../../../../adapters/xhr/generalApi";
import AddBlurbOrProductPortal from "../../../../components/businessProfile/components/businessProfileHeader/components/addBlurbOrProductPortal";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import ConfirmDeleteModal from "./confirmDeleteModal";
import ShareDetail from "./shareDetail";
import ViewMoreList from "./viewMoreList";
import * as ENV_KEYS from "../../../../config/configKeys";
import { editBlurbDetails, editProductDetails, editUseCaseDetails } from "../../../../contexts/reducer/homeReducer";
const ViewMore = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const vendorDetails = useSelector((state) => state?.homeDetails?.vendorData);
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const USER_ID = authData?.data?.userId;
  const cardType = props?.cardType;
  const itemData = props?.data;
  const tabIndex = props?.tabIndex;
  const permissionsDetails = props?.permissionsDetails;
  const blurbsPostData = useEnCryptPostApi(
    `/approveUpdatedBlurb`,
    KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
    useBlurbProcessorMutation
  );
  const blurbHideData = useEnCryptPostApi(
    `/updateIrrelevantBlurbs`,
    KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
    useBlurbProcessorMutation
  );
  const DeleteUseCase = useEnCryptPostApi(
    `/deleteUseCaseBlurb`,
    KEYS?.END_POINTS?.BLURB_PROCESSOR?.END_POINT_URL,
    useBlurbProcessorMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  // https://thebox-dev.portqii.com/core/vendor/deleteProduct?productId=17300&userId=625
  const blurbProductData = useEnCryptPostApi(
    `/vendor/deleteProduct`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useAddProductsApiMutation
  );
  const handleShareBlurbClicked = (val) => {
    setToggleMenu(!toggleMenu);
  };

  const Id =
    props?.cardType == "product"
      ? props?.data?.Product_ID
      : props?.cardType == "blurb"
      ? props?.data?.blurbID
      : props?.cardType == "usecase"
      ? props?.data?.blurbID
      : " share";
  useEffect(() => {
    if (!showModal) {
      // document.body.style.overflow = "auto";
    }
  }, []);
  const handleCloseModal = () => {
    setShowModal(false);
    // document.body.style.overflow = "auto";
  };
  const handleActionClicked = (val) => {
    if (val == "edit") {
      if (cardType == "product" || cardType == "blurb" || cardType == "usecase") {
        // window.scrollTo(0, 0);
        setShowModal(true);
        // document.body.style.overflow = "hidden";
      }
    } else if (val === "delete") setModalVisible(true);
    else if (val == "delete" && cardType == "usecase") {
    }
    setTimeout(() => {
      setToggleMenu(!toggleMenu);
    }, 100);
  };

  const handleToggleModal = (val) => {
    setModalVisible(!modalVisible);
  };

  const handleConfirmDelete = () => {
    if (cardType == "blurb") {
      const data = {
        message: "Mark for delete",
        blurbId: props?.data?.blurbID,
        type: "delete",
        status: vendorDetails?.Status,
        userId: authData?.data?.userId,
      };
      blurbHideData?.handleTrigger(data);
      dispatch(
        editBlurbDetails({
          blurbID: props?.data?.blurbID,
          status: "Delete",
        })
      );
      setTimeout(() => {
        dispatch(editBlurbDetails({}));
      }, 3000);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.BLURB_DELETED,
        Item_Type: "Blurb",
        Item_Id: props?.data?.blurbID,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: null,
          context: `Blurb deleted`,
          page: "/business-profile/blurbs",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else if (cardType == "product") {
      const data = { productId: Id, userId: authData?.data?.userId, vendorId: authData?.data?.vendorId };
      blurbProductData?.handleTrigger(data);
      dispatch(
        editProductDetails({
          Product_ID: Id,
          status: "Delete",
        })
      );
      setTimeout(() => {
        dispatch(editProductDetails({}));
      }, 3000);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.PRODUCT_DELETED,
        Item_Type: "Product",
        Item_Id: Id,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: null,
          context: `Product deleted`,
          page: "/business-profile/blurbs",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else if (cardType == "usecase") {
      const data = {
        blurbID: props?.data?.blurbID,
        userId: authData?.data?.userId,
      };
      DeleteUseCase?.handleTrigger(data);
      dispatch(editUseCaseDetails({ blurbID: props?.data?.blurbID, status: "Delete" }));
      setTimeout(() => {
        dispatch(editUseCaseDetails({}));
      }, 3000);
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.USECASE_DELETED,
        Item_Type: "Use case",
        Item_Id: props?.data?.blurbID,
        Target: {
          page: null,
          link: null,
        },
        Object: {
          section: null,
          context: `Use case deleted`,
          page: "/business-profile/blurbs",
          Persona: "Business user",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
    setModalVisible(false);
  };

  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  return (
    <>
      {showModal && (cardType == "product" || cardType == "blurb" || cardType == "usecase") ? (
        <AddBlurbOrProductPortal handleClose={handleCloseModal} editProduct={true} Id={Id} tabIndex={tabIndex} />
      ) : null}

      {modalVisible ? (
        <ConfirmDeleteModal
          closeModal={() => handleToggleModal()}
          handleSubmit={() => handleConfirmDelete()}
          type={cardType}
        />
      ) : null}
      {permissionsDetails?.Update || permissionsDetails?.Delete ? (
        <div tabIndex={0} onBlur={handleBlur} className="engagement-bar-items p-r">
          <span className="engagement-view-more-text" onClick={() => handleShareBlurbClicked()}>
            View More
          </span>
          {toggleMenu && (
            <div className="view-more-container  modern-card modern-card--elevated">
              <ViewMoreList
                canDelete={props?.canDelete}
                permissionsDetails={permissionsDetails}
                tabIndex={tabIndex}
                handleActionClicked={handleActionClicked}
                shareLink={props?.shareLink}
                cardType={props?.cardType}
                handleToggleMenu={handleToggleMenu}
                data={itemData}
                blurbsPostData={blurbsPostData}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ViewMore;
