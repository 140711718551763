import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import AddNewPermissionSetPortal from "./addNewPermissionPortal";
import PermissionSetCard from "./permissionSetCard";
import { usePostUserPermissionsMutation } from "../../../../adapters/xhr/crudServiceApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
const PermissionSets = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [openPermissionSetPortal, setOpenPermissionSetPortal] = useState(false);
  const handleFeatureList = (val) => {
    props?.handleFeatureList(val);
  };
  // /permission/addPermissionSet
  const AddPermissionSetPostApi = useEnCryptPostApi(
    `/permission/addPermissionSet`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPermissionsMutation
  );
  // /permission/deletePermissionSet
  const RemovePermissionSetPostApi = useEnCryptPostApi(
    `/permission/deletePermissionSet`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPermissionsMutation
  );
  const handleAddNewPermissionSet = () => {
    setOpenPermissionSetPortal(true);
    // props?.handleAddNewPermissionSet();
  };
  function handleDeleteClicked(val) {
    RemovePermissionSetPostApi.handleTrigger(val);
    setOpenPermissionSetPortal(false);
  }
  function handleSaveClicked(val) {
    AddPermissionSetPostApi.handleTrigger(val);
    setOpenPermissionSetPortal(false);
    //   {
    //     "Permission_Set_Name": "new permission1",
    //     "User_ID": 35,
    //     "Vendor_ID": 14505
    // }
  }
  const CHANNEL_DATA = props?.data;
  return (
    <div style={{ borderRight: "1px solid #d3d3d3" }} class="p-x-sm m-t-base">
      <div class="dtrd-form--modern dtrd-form">
        <div class="edit-profile-feature-list-container-flex">
          <div onClick={handleAddNewPermissionSet} className={`add-new-permission-set-wrapper`}>
            <FeatherIcon icon="plus" size="20" className="permission-set-add-icon" />
            <p className="permission-set-title-text--add-permission-set p-l-base"> {"Add new permission set"}</p>
          </div>
        </div>
        {CHANNEL_DATA?.map((item, index) => {
          return (
            <PermissionSetCard
              allData={CHANNEL_DATA}
              featureActiveList={props?.featureActiveList}
              index={index}
              data={item}
              handleFeatureList={() => props?.handleFeatureList(index + 1)}
            />
          );
        })}
      </div>
      {openPermissionSetPortal ? (
        <AddNewPermissionSetPortal
          handleClose={() => setOpenPermissionSetPortal(false)}
          handleDeleteClicked={handleDeleteClicked}
          handleSaveClicked={handleSaveClicked}
          allData={CHANNEL_DATA}
          data={null}
        />
      ) : null}
    </div>
  );
};

export default PermissionSets;
