import React from 'react';
import FeatherIcon from 'feather-icons-react';
const TextArea = (props) => {
  const error = props?.error;
  const REQUIRED = props?.required;
  return (
    <div className={props?.error ? 'm-b-lg p-r' : props?.removeMargin ? 'p-r' : 'm-b-lg p-r'}>
      {/* <label
        className={`input-field-label ${error ? "error" : ""} `}
        for={props?.label}
      >
        {props?.label}
      </label> */}

      <textarea
        id={props?.label}
        type={props?.type ? props?.type : 'text'}
        value={props?.value}
        disabled={props?.disabled ? true : false}
        name={props?.label}
        placeholder={props?.label}
        onChange={(e) => props?.changeText(e.target.value.length == 1 ? e.target.value?.trim() : e.target.value)}
        className={props?.error ? 'input-text-area background-error' : `input-text-area  ${REQUIRED ? 'required-border-right' : ''}`}
        rows={4}
        cols={40}
        autoComplete='off'
        style={props?.icon ? { paddingLeft: '30px' } : { paddingLeft: '10px' }}
        maxLength={1500}
      />
      {props?.subTitleTop ? (
        <div className='flex'>
          {/* <label className="company-details-subtitle-text-area ">{props?.subTitleTop}</label> */}
          <label className='input-field-info-text'>{props?.subTitleTop}</label>
        </div>
      ) : null}

      {error ? <span class='input-field-text-error-text-area'>{error}</span> : null}
    </div>
  );
};

export default TextArea;
