import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useAddProposalApiMutation, usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";
import PreviewTemplatePortal from "./previewTemplatePortal";
function TemplateCard(props) {
  const { data } = props;
  const [showPreviewTemplate, setShowPreviewTemplate] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  //   useProposalTemplate
  const getProposalTemplateData = useEnCryptPostApi(
    `/useProposalTemplate`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostRequestMutation
  );
  const getPreviewProposalTemplateData = useEnCryptPostApi(
    `/useProposalTemplate`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (getPreviewProposalTemplateData?.data) {
      setShowPreviewTemplate(true);
    }
  }, [getPreviewProposalTemplateData?.data]);
  useEffect(() => {
    if (getProposalTemplateData?.data) {
      props?.handleFooterNumberFromTemplateClicked(2, getProposalTemplateData?.data);
    }
  }, [getProposalTemplateData?.data]);
  const handleFooterNumberFromTemplateClicked = () => {
    getProposalTemplateData.handleTrigger({
      Proposal_ID: data?.Proposal_ID,
    });
  };
  function handlePreviewTemplateClicked() {
    getPreviewProposalTemplateData.handleTrigger({
      Proposal_ID: data?.Proposal_ID,
    });
  }
  return (
    <div class="provider-profile__alternative">
      <div class="provider-profile__alternative__content">
        <div data-position="top center" data-tooltip={data?.Proposal_Title}>
          <h3 class="proposal-card-title">{isMobile ? null : data?.Proposal_Title}</h3>
        </div>
        <div  className="vertical-provider-card__subtitle p-t-base">
          <i class="feather icon icon-align-left " title="Product categories"></i>
          {data?.Product_Category_Name}
        </div>
        <div
          style={{ height: "90px", paddingTop: "10px" }}
          data-position="left center"
          data-tooltip={isMobile ? null : data?.Proposal_Description}
        >
          <p class="provider-profile__alternative__header-description vendor-card-description-wrap-text">
            {data?.Proposal_Description}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "10px",
          height: "30px",
        }}
      >
        <div onClick={handlePreviewTemplateClicked} class="vertical-product-card-for-provider__footer">
          {getPreviewProposalTemplateData.isLoading ? (
            <div className="vertical-product-card-for-provider__cta small primary modern button">
              <LoadingSpinner />
            </div>
          ) : (
            <div class="vertical-product-card-for-provider__cta small primary modern button">{"Preview template"}</div>
          )}
        </div>
        <div onClick={handleFooterNumberFromTemplateClicked} class="vertical-product-card-for-provider__footer">
          {getProposalTemplateData.isLoading ? (
            <div className="vertical-product-card-for-provider__cta small primary modern button">
              <LoadingSpinner />
            </div>
          ) : (
            <div class="vertical-product-card-for-provider__cta small primary modern button">
              {"Use template"}
              {/* <i className="feather icon icon-arrow-right "></i> */}
            </div>
          )}
        </div>
      </div>
      {showPreviewTemplate ? (
        <PreviewTemplatePortal
          handleClose={() => setShowPreviewTemplate(false)}
          proposalDetails={getPreviewProposalTemplateData?.data}
        />
      ) : null}
    </div>
  );
}

export default TemplateCard;
