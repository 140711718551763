import React from "react";
import { useSelector } from "react-redux";
import ExploreCategoryDesktop from "../../../../home/header/component/exploreCategories/exploreCategoriesDesktop";
import ListYourDataDesktop from "../../../../home/header/component/listYourData/listYourDataDesktop";
// import PostYourDataDesktop from "../postYourData/postYourDataDesktop";
import PostYourDataDesktop from "../../../../home/header/component/postYourData/postYourDataDesktop";
import Notifications from "../../../../notifications/notifications";
import Request from "../../../../home/header/component/request/request";
import OctateHelp from "../octateHelp/octateHelp";

const NavItems = (props) => {
  const authData = useSelector((state) => state?.authDetails?.details);
  return (
    <>
      <div className="dtrd-navbar__top tablet-hidden">
        <nav className="navbar__menu">
          <ExploreCategoryDesktop page={props?.page} />
          <div style={{ zIndex: 10, marginRight: "0px", marginLeft: "20px" }}>
            <Request page={props?.page} />
          </div>
          {/* {props?.page?.includes("home") ? ( */}
          <div style={{marginRight: "0px", marginLeft: "125px" }}>
            <OctateHelp page={props?.page} />
          </div>
          {/* ) : null} */}

          {authData?.data?.userExist ? (
            <div style={{marginRight: "0px", marginLeft: "0px" }}>
              <Notifications />
            </div>
          ) : null}
          {/* {authData?.data?.userExist ? null : (
            <>
              <ListYourDataDesktop page={props?.page} />
              <PostYourDataDesktop page={props?.page} />
            </>
          )} */}
        </nav>
      </div>
    </>
  );
};

export default NavItems;
