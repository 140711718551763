import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../../../utlis/hof/hof";
import * as SECRET_KEYS from "../../../../config/configKeys";
const UseCaseCTA = (props) => {
  const BLURB_DATA = props?.data;
  const UrlUtmData = useSelector((state) => state?.homeDetails?.utmUrl);
  const utmQuery = BLURB_DATA?.call_to_action_link?.includes("?");
  const CTA_BUTTON = BLURB_DATA?.call_to_action_name;
  const CTA_LINK = ` ${BLURB_DATA?.call_to_action_link?.replace(/\/$/, "")}${utmQuery ? "&" : "?"}${UrlUtmData &&
    UrlUtmData}&utm_id=${BLURB_DATA?.Vendor_ID}`;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.CTA_LINK_CLICKED,
    page: props?.page,
    section: "Blurb detail CTA",
  };

  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleCTALinkPressed = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: "Vendor",
      Item_Id: val?.Vendor_ID,
      Target: {
        page: "external link",
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}/${
          KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
        }`,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: CTA_LINK,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);

    window.open(CTA_LINK, "_blank");
  };

  return (
    <div>
      <a
        className="blurb-detail-cta-button m-b-sm"
        role="button"
        target="_blank"
        // href={CTA_LINK}
        onClick={() => handleCTALinkPressed(BLURB_DATA)}
      >
        {CTA_BUTTON && CTA_BUTTON?.length < 25 ? CTA_BUTTON : "Learn more"}
      </a>
    </div>
  );
};

export default UseCaseCTA;
