import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  useAddProductsApiMutation,
  useAddProposalApiMutation,
  useAddProposalPutApiMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import {
  CheckIfAnswerChanged,
  CheckIfVendorProposalPublished,
  GetIdFormLink,
  getIndexOfSectionsAndQuestions,
  validateAddProposalPage1,
  validateAddProposalPage2,
  validateAddProposalPage3,
} from "../../utlis/hof/hof";
import * as ADD_PROPOSAL_KEYS from "../../config/config";
import * as SECRET_KEYS from "../../config/configKeys";
import ProposalList from "./component/proposalList";
import AnswerProposalHeader from "./component/answerProposalHeader/answerProposalHeader";
import { notificationTargetFn, vendorProposalSelectedTabFn } from "../../contexts/reducer/notificationReducer";
const AnswerProposal = (props) => {
  const screenIndex = props?.screenIndex;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const notificationJson = props?.notificationJson;
  const dispatch = useDispatch();
  // const [footerNumberClicked, setFooterNumberClicked] = useState(screenIndex ? screenIndex : 1);
  const footerNumberClicked = useSelector((state) => state?.notificationDetails?.vendorProposalSelectedTab);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const [proposalRefData, setProposalRefData] = useState({});
  const permissionsDetails = props?.permissionsDetails;
  const CanUpdate = permissionsDetails?.Answer ? true : false;
  const navigate = useNavigate();
  const PARAMS = useParams();
  const PROPOSAL_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : props?.Id;
  const [companyErrors, setCompanyErrors] = useState({});
  const [proposalDetails, setProductDetails] = useState({});
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const PROPOSAL_QUESTION_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_QUESTION;
  const authData = useSelector((state) => state?.authDetails?.details);
  const PROPOSAL_TYPE = "answer";
  const editProduct = props?.editProduct;
  const vendorId = authData?.data?.vendorId;

  // https://thebox-dev.portqii.com/core/vendor/productOnboardingGetProduct?Product_ID=17258&User_ID=546
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getVendorProposal?Proposal_ID=${PROPOSAL_ID}&Vendor_ID=${vendorId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );

  useEffect(() => {
    if (GetProductDataFromApi?.data?.data) {
      setProductDetails({
        ...GetProductDataFromApi?.data?.data,
        [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? new Date(GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE] * 1000)
          : null,
        [PROPOSAL_KEYS.CAN_EDIT]: GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS.CAN_EDIT]
          ? CanUpdate
            ? true
            : false
          : true,
      });
      const val1 = JSON.stringify({ ...GetProductDataFromApi?.data?.data });
      setProposalRefData(val1);
    }
    // setProductDetails(DUMMY_ARRAY);
  }, [GetProductDataFromApi?.data]);
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;

  const footerList = [
    {
      name: "Proposal Brief",
      id: 1,
    },
    // {
    //   name: "Template Section",
    //   id: 2,
    // },
    {
      name: "Edit Questionnaire",
      id: 2,
    },
    {
      name: "Preview & Submit",
      id: 2,
    },
  ];
  const validateEmailId = authData?.data?.emailId
    ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
    : null;

  // https://thebox-dev.portqii.com/proposals/addProposal
  const vendorPostData = useEnCryptPostApi(
    `/addProposalAnswers`,  
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useAddProposalPutApiMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  useEffect(() => {
    if (vendorPostData?.data) {
      setUpdateStatus(true);
      if (PARAMS?.name) {
        navigate(`/sales-hub/proposals`);
      } else {
        props?.handleClose(false);
      }
      // setTimeout(() => {
      //   navigate("/");
      // }, 2000);
    }
  }, [vendorPostData?.data]);
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    // if (val <= 2) {
    //   const Validate1 = validateAddProposalPage1(proposalDetails);
    //   setCompanyErrors(Validate1);
    //   setTimeout(() => {
    //     setCompanyErrors({});
    //   }, 3000);
    //   if (!Object.keys(Validate1)?.length) {
    //     setFooterNumberClicked(val);
    //   }
    // } else if (val <= 3) {
    //   const Validate2 = validateAddProposalPage2(proposalDetails);
    //   setCompanyErrors(Validate2);
    //   setTimeout(() => {
    //     setCompanyErrors({});
    //   }, 3000);
    //   if (!Object.keys(Validate2)?.length) {
    //     setFooterNumberClicked(val);
    //   }
    // } else if (val <= 4) {
    //   setFooterNumberClicked(val);
    // } else {
    //   return;
    // }
    // setFooterNumberClicked(val);
    dispatch(vendorProposalSelectedTabFn(val));
  };
  const handleScreenToShow = (val) => {
    // setFooterNumberClicked(val);
    dispatch(vendorProposalSelectedTabFn(val));
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  const handleSaveClicked = () => {
    const Validate2 = validateAddProposalPage3(proposalDetails);
    setCompanyErrors(Validate2);

    if (!Object.keys(Validate2)?.length) {
      vendorPostData.handleTrigger({
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.PARTICIPANTS]: proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length,
        [PROPOSAL_KEYS?.STATUS]: proposalDetails?.[PROPOSAL_KEYS?.STATUS]
          ? proposalDetails?.[PROPOSAL_KEYS?.STATUS]
          : "Draft",
        [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE].getTime() / 1000
          : null,
        [PROPOSAL_KEYS?.TARGET_DATE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE].getTime() / 1000
          : null,
      });
    }
  };
  const triggerSignalTracking = () => {
    const refData = JSON.parse(proposalRefData);
    const oldValue = refData?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length
      ? [...refData?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]]
      : [];
    const checkIfPublished = CheckIfVendorProposalPublished(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]);
    if (!checkIfPublished) {
      const Action = SECRET_KEYS.CONFIG.VENDOR_PUBLISHED;
      const Target = {
        Id: null,
        type: "Buyer",
        page: "proposal page",
        link: "/proposals",
      };
      const Object = {
        Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
        type: "Proposal",
        section: null,
        [PROPOSAL_KEYS?.VENDOR_ID]: vendorId,
        context: "Vendor Published Proposal",
        page: "vendor proposal page",
      };
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        SignalCreatedAt: new Date().getTime() / 1000,
        Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
        Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
        Action: Action,
        Target: Target,
        Object: Object,
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else {
      const checkIfAnswerChangedVal = CheckIfAnswerChanged(
        oldValue,
        proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]
      );
      if (checkIfAnswerChangedVal?.QuestionId && checkIfAnswerChangedVal?.QuestionId?.length) {
        const Action = SECRET_KEYS.CONFIG.ANSWER_EDITED;
        const Target = {
          Id: null,
          type: "Buyer",
          page: "proposal page",
          link: "/proposals",
        };
        const Object = {
          Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          type: "Proposal",
          [PROPOSAL_KEYS?.VENDOR_ID]: vendorId,
          [PROPOSAL_QUESTION_KEYS?.QUESTION_ID]: checkIfAnswerChangedVal?.QuestionId,
          section: null,
          context: "Answer Edited",
          page: "vendor proposal page",
        };
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          SignalCreatedAt: new Date().getTime() / 1000,
          Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
          Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          Action: Action,
          Target: Target,
          Object: Object,
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
    }
  };
  const handleSaveAnswerClicked = (val) => {
    const sectionAndQuestionIndex = getIndexOfSectionsAndQuestions(proposalDetails);
    if (val == "Draft") {
      vendorPostData.handleTrigger({
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.VENDOR_ID]: proposalDetails?.[PROPOSAL_KEYS?.VENDOR_ID]
          ? proposalDetails?.[PROPOSAL_KEYS?.VENDOR_ID]
          : 1,
        // authData?.data?.vendorId
      });
    } else {
      vendorPostData.handleTrigger({
        ...proposalDetails,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.STATUS]: "Submitted",
        [PROPOSAL_KEYS?.VENDOR_ID]: vendorId,
        //  proposalDetails?.[PROPOSAL_KEYS?.VENDOR_ID]
        // ? proposalDetails?.[PROPOSAL_KEYS?.VENDOR_ID]
        // : 1
        // authData?.data?.vendorId
      });

      triggerSignalTracking();
    }
    if (PARAMS?.name) {
      dispatch(notificationTargetFn({}));
    }
  };
  const handleCloseClicked = () => {
    if (PARAMS?.name) {
      navigate(`/sales-hub/proposals`);
      dispatch(notificationTargetFn(null));
    } else {
      props?.handleClose(false);
    }
  };
  return (
    <div className="company-profile-main-wrapper" style={{ background: "#F5F5F5" }}>
      <div className="company-details-header-main-wrapper-ref">
        <AnswerProposalHeader
          proposalPublished={
            proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID] && proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft"
              ? true
              : false
          }
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          productPageLabel={proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE]}
          proposalDetails={proposalDetails}
          handleClose={handleCloseClicked}
          isLoading={vendorPostData?.isLoading}
          vendorPostData={vendorPostData}
          footerNumberClicked={footerNumberClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          handleSaveAnswerClicked={handleSaveAnswerClicked}
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="answer-proposal-details-main-container-ref">
        <ProposalList
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          notificationJson={notificationJson}
          //   productStaticData={ADD_PRODUCT}
          //   validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
          footerNumberClicked={footerNumberClicked}
          handleScreenToShow={handleScreenToShow}
        />
      </div>
    </div>
  );
};

export default AnswerProposal;
