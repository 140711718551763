import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import DataProviderCard from "../../../../utlis/hoc/providerCardSmall/dataProviderCard";

function ProviderCardList(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const GetApiData = useEnCryptGetApi(
    // https://thebox-dev.portqii.com/search_backend/getPopularVendorsForCategory?product_category_id=1&page=1
    // https://thebox-dev.portqii.com/search_backend/getPopularProductForCategory?product_category_id=1&page=1
    `/getPopularVendorsForCategory?product_category_id=${props?.categoryId}&page=1&limit=6`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );
  const popularProductsInCategory = GetApiData?.data?.data;
  return (
    <div>
      <div>
        <DataProviderCard
          page={props?.page}
          section={props?.section}
          data={popularProductsInCategory}
          isLoading={GetApiData?.isLoading}
          isFetching={GetApiData?.isFetching}
          skeletonLength={6}
        />
      </div>
    </div>
  );
}

export default ProviderCardList;
