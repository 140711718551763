import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { DecryptApi, ReplaceBlankSpaceByHyphen } from "../../../../../utlis/hof/hof";
import ProvidersList from "./companyList";
import TopicList from "./topicList";
import * as SECRET_KEYS from "../../../../../config/configKeys";
// import * as KEYS from "../../../../../config/config";
const SearchInputDropDown = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const searchedList = DecryptApi(props?.searchedList);
  const KeysInObject = Object.keys(searchedList?.data);
  const PRODUCTS = KEYS?.HEADER_SEARCH_TYPE.PRODUCT;
  const signalTrackingSearchObject = {
    Action: SECRET_KEYS.CONFIG.SEARCH_CLICKED,
    page: "search page",
    section: "search box",
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleSearchClicked = () => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingSearchObject?.Action,
      Item_Type: null,
      Item_Id: null,
      Target: {
        page: signalTrackingSearchObject?.page,
        link: `/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(props?.inputText)}`,
      },
      Object: {
        section: signalTrackingSearchObject?.section,
        context: props?.inputText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <>
      {KeysInObject?.length
        ? KeysInObject?.map((list) => {
            if (list == "Topics") {
              return (
                <TopicList page={props?.page} list={searchedList?.data[list]} inputText={props?.inputText} name={""} />
              );
            } else {
              return (
                <ProvidersList
                  page={props?.page}
                  list={searchedList?.data[list]}
                  inputText={props?.inputText}
                  name={list}
                />
              );
            }
          })
        : null}
      <a
        className="result"
        onClick={handleSearchClicked}
        href={`/search/${PRODUCTS}/${ReplaceBlankSpaceByHyphen(props?.inputText)}`}
      >
        <div className="content">
          <i className="feather icon icon-search "></i>
          <div>
            {KEYS?.SEARCH_INPUT_DROPDOWN.LABEL}
            <em className="main-header-search-results-search-text">{`${" "}"${props?.inputText}"`}</em>
          </div>
        </div>
      </a>
    </>
  );
};
export default SearchInputDropDown;
