import React from "react";
import { useSelector } from "react-redux";
import VendorFirmographics from "./vendorFirmographics";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import * as SECRET_KEYS from "../../../../config/configKeys";
import ProviderCardFirmo from "../../providerCardSmall/providerCardFirmo";

const VendorContent = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const signalTrackingVendorObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.Action,
      Item_Type: "Vendor",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: props?.searchText,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div className="product-card-content-container">
      <div
        onClick={() =>
          handleVendorNameLinkClicked(
            `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`
          )
        }
      >
        <a
          href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${
            KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
          }`}
          className="product-card-description"
        >
          <h2 className="product-card-description">{DATA?.About}</h2>
        </a>
      </div>

      {/* <VendorFirmographics data={DATA} page={props?.page} section={props?.section} /> */}
      <ProviderCardFirmo data={DATA} page={props?.page} section={props?.section} />
    </div>
  );
};

export default VendorContent;
