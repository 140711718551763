import React from "react";
import { GetIdFormLink, ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../../../utlis/hof/hof";
import { useParams } from "react-router";

const ProductTags = (props) => {
  const PARAMS = useParams();
  const CATEGORY = props?.page === "Topic" ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " ")) : null;
  const BLURB_DATA = props?.data?.Tags?.filter((val) => val?.Level === 4)?.slice(0, 3);
  const ITEM_EXISTS = BLURB_DATA?.some((item) => item?.Tag_Id === CATEGORY?.Id);

  const TAGS_DATA =
    props?.page === "Topic"
      ? ITEM_EXISTS
        ? BLURB_DATA
        : [...BLURB_DATA, { Name: ToTitleCase(CATEGORY?.Name), Tag_Id: CATEGORY?.Id }]
      : BLURB_DATA;

  return (
    <div className="blurb-detail-tags-container" style={{ padding: "10px 0px" }}>
      {TAGS_DATA?.map((item) => {
        return (
          <a href={`/topic/${ReplaceBlankSpaceByHyphen(item?.Name, item?.Tag_Id)}/blurbs`} role="button">
            <h6 className={`${CATEGORY?.Id === item?.Tag_Id ? "blurb-detail-tags-highlight" : "blurb-detail-tags"}`}>
              {item?.Name}{" "}
            </h6>
          </a>
        );
      })}
    </div>
  );
};

export default ProductTags;
