import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen, detectMob } from "../../../hof/hof";
import { useSelector } from "react-redux";
import { useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import AddProposalPortal from "../../../../components/proposals/component/addProposalPortal";
import * as SECRET_KEYS from "../../../../config/configKeys";
const ProductActions = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [showAddProposalModal, setShowAddProposalModal] = useState({});
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const PARAMS = useParams();
  const SEARCH_TEXT = PARAMS?.name?.replaceAll("-", " ");

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.PRODUCT_CARD_ACTION_BUTTON,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleActionButtonCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Item_Type: "product",
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: SEARCH_TEXT,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const handleAddToProposal = (ID, Vendor_Name) => {
    setShowAddProposalModal({
      show: true,
      vendorDetails: {
        Vendor_Name: Vendor_Name,
        Vendor_ID: ID,
        isSelected: true,
        Vendor_Selection_Way: "Product Card",
      },
    });
  };

  return (
    <div className="product-card-action-container">
      {showAddProposalModal?.show ? (
        <AddProposalPortal
          handleClose={() => setShowAddProposalModal({ show: false, vendorDetails: {} })}
          vendorDetails={showAddProposalModal?.vendorDetails}
        />
      ) : null}
      <Link
        to={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          DATA?.Product_Name,
          DATA?.Product_ID
        )}/features`}
      >
        <div
          className="product-card-action-button-blue"
          onClick={() =>
            handleActionButtonCLicked(
              `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                DATA?.Product_Name,
                DATA?.Product_ID
              )}/features`
            )
          }
        >
          View Features
        </div>
      </Link>
      <Link
        to={`/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          DATA?.Product_Name,
          DATA?.Product_ID
        )}/blurbs`}
      >
        <div
          className="product-card-action-button"
          onClick={() =>
            handleActionButtonCLicked(
              `/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
                DATA?.Product_Name,
                DATA?.Product_ID
              )}/blurbs`
            )
          }
        >
          View Blurbs
        </div>
      </Link>
      <a className="mobile-hidden">
        <div
          className="product-card-action-button"
          style={{ margin: "0px" }}
          onClick={() => handleAddToProposal(DATA?.Vendor_ID, DATA?.Vendor_Name)}
        >
          Add to proposal
        </div>
      </a>
    </div>
  );
};

export default ProductActions;
