import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../../customHooks/customHooks";
// import * as KEYS from "../../../../config/config";
import { EncryptId, ReplaceBlankSpaceByHyphen } from "../../../hof/hof";
import * as SECRET_KEYS from "../../../../config/configKeys";
function ActionButtons(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const productName = props?.allProducts?.Product_Name;
  const productId = props?.allProducts?.Product_ID;
  const productPageUrl = props?.allProducts?.Product_Page_URL;
  const signalTrackingProductsObject = props?.signalTrackingProductsObject;
  const signalTrackingBlurbsObject = props?.signalTrackingBlurbsObject;
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleProductLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingProductsObject?.Action,
      Item_Type:'product',
      Item_Id: productId,
      Target: {
        page: signalTrackingProductsObject?.page,
        link: `/${ReplaceBlankSpaceByHyphen(props?.allProducts?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          productName,
          productId
        )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`,
      },
      Object: {
        section: signalTrackingProductsObject?.section,
        context: productName,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleBlurbLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBlurbsObject?.Action,
      Item_Type: 'blurb',
      Item_Id:productId,
      Target: {
        page: signalTrackingBlurbsObject?.page,
        link: `/${ReplaceBlankSpaceByHyphen(props?.allProducts?.Vendor_Name)}/${ReplaceBlankSpaceByHyphen(
          productName
        )}/blurbs/${EncryptId(productId)}`,
      },
      Object: {
        section: signalTrackingBlurbsObject?.section,
        context: productName,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  return (
    <div class="vertical-product-card-for-provider__footer grid-two-columns tablet-1-col grid-col-gap-base">
      <a
        class="vertical-product-card-for-provider__cta small outlined rounded button"
        onClick={handleBlurbLinkClicked}
        href={`/${ReplaceBlankSpaceByHyphen(props?.allProducts?.Vendor_Name)}/${ReplaceBlankSpaceByHyphen(
          productName
        )}/blurbs/${EncryptId(productId)}`}
      >
        {KEYS?.ACTION_BUTTONS.REQUEST}
      </a>
      <a
        class="vertical-product-card-for-provider__cta small primary modern button"
        onClick={handleProductLinkClicked}
        href={`/${ReplaceBlankSpaceByHyphen(props?.allProducts?.Vendor_Name)}/product/${ReplaceBlankSpaceByHyphen(
          productName,
          productId
        )}/${KEYS?.DEFAULT_TAB_SELECTED?.CATEGORY_PAGE}`}
      >
        {KEYS?.ACTION_BUTTONS.VIEW_PRODUCT}
      </a>
    </div>
  );
}

export default ActionButtons;
