import React, { useState, useEffect } from "react";

function CountdownTimer({ seconds, onResend }) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const displayTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  };

  return (
    <>
      {timeLeft > 0 ? (
        <div className="resend-button">{`(${displayTimeLeft()})`}</div>
      ) : (
        <div className="resend-button" onClick={() => onResend()} role="button">
          Resend OTP
        </div>
      )}
    </>
  );
}

export default CountdownTimer;
