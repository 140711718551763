import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import { concatIsSelectedAllData } from "../../../utlis/hof/hof";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import MultiSelectCustomer from "./multiSelectCustomer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-paste-smart";
const BasicUseCaseDetails = (props) => {
  const [searchIndustryText, setSearchIndustryText] = useState("");
  //   const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const REF_DATA = useSelector((state) => state?.authDetails?.uxConfig);
  const allPermissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const vendorId = allPermissionsDetails?.vendorId ? allPermissionsDetails?.vendorId : authData?.data?.vendorId;
  const showVendorName = allPermissionsDetails?.vendorId == authData?.data?.vendorId ? true : false;
  const ADD_USE = {
    SUCCESS_MESSAGE: "Resource details updated successfully",
    USE_CASE_PAGE_TITLE: "New Resource",
    USE_CASE_NAME: "Resource Title",
    VENDOR_NAME: "Vendor name",
    USE_CASE_NAME_LABEL: "Resource Title",
    SUPPORTED_LANGUAGES: "Supported Languages",
    SUPPORTED_LANGUAGES_LABEL: "Supported Languages",
    SELECT_USE_CASE_SEGMENT: "Select Resource Segment",
    SELECT_USE_CASE_SEGMENT_LABEL: "Select Resource Segment",
    SELECT_INDUSTRY: "Select Industry",
    SELECT_INDUSTRY_LABEL: "Select Industry",
    SOFTWARE_TYPE: "Software Type",
    SOFTWARE_TYPE_LABEL: "Software Type",
    DESCRIPTION: "Resource Description",
    DESCRIPTION_LABEL: "Resource Description",
    CTA_LINK: "CTA link",
    CTA_LINK_LABEL: "https://CTA-link",
    PAGE_URL: "Page URL",
    PAGE_URL_LABEL: "https://page-url-link",
    CTA_NAME_LABEL: "CTA Name",
    CTA_NAME: "CTA Name",
    INDUSTRY: "Industry",
    INDUSTRY_LABEL: "Select Industry",
    CUSTOMERS_LABEL: "Select Customer",
    USE_CASE_TYPE_LABEL: "Select Type of Resource",
    USE_CASE_TYPE: "Type of Resource",
    CUSTOMERS: "Customer",
    PRODUCT: "Product",
    USE_CASE_PAGE_TITLE_EDIT: "Edit Resource",
    PRODUCT_LABEL: "Select Product",
    IMAGE_SRC_LABEL: "https://image-src",
    IMAGE_SRC: "Image",
    BACK: "Back",
    SAVE: "Save",
    NEXT: "Next",
    ANONYMOUS_CUSTOMER_NAME: "Customer Name",
    ANONYMOUS_CUSTOMER_COUNTRY: "Customer Country",
  };
  const KEYS = { ...REF_DATA, ADD_USE_CASE: ADD_USE };
  const error = props?.companyErrors;
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const StaticDropdownData = props?.StaticDropdownData;
  // const productBasicDetails = props?.data;
  const productBasicDetails = props?.productDetails;
  const [description, setDescription] = useState(productBasicDetails?.description);
  //   https://thebox-dev.portqii.com/core/vendor/vendorOnboardingSearch?searchText=auto&type=industry
  const industryDropdownData = useEnCryptGetApi(
    `/vendor/vendorOnboardingSearch?searchText=${searchIndustryText}&type=industry`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const customerDropdownData = [
    { Customer_ID: 1, Customer_Name: "Cashbook" },
    { Customer_ID: 2, Customer_Name: "Aaviner" },
    { Customer_ID: 3, Customer_Name: "Altec" },
  ];
  const GetProductFormVendorIdApi = useEnCryptGetApi(
    `/getVendorProductsForVendorPage?vendorId=${vendorId}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;

  const GetCountryData = useEnCryptGetApi(
    `/s3Processor/getCountryList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const DropDownData = GetCountryData?.data?.data?.allCountries;
  const ADD_USE_CASE = KEYS?.ADD_USE_CASE;
  const handleSearchWithApi = (key) => {
    setSearchIndustryText(key);
  };
  const handleApplyCountryClicked = (val) => {};
  const handleSelectedDataOnChange = (val) => {
    props?.handleDetails({
      ...productBasicDetails,
      Compatible_Softwares: val,
    });
  };
  const handleChangeSearchText = (val) => {
    props?.handleDetails({
      ...productBasicDetails,
      Use_Case_Customers: {
        Customer_Name: val,
        Customer_ID: null,
      },
    });
  };
  const concatIsSelectedCompatible = concatIsSelectedAllData(
    productBasicDetails?.Compatible_Softwares?.length ? productBasicDetails?.Compatible_Softwares : [],
    StaticDropdownData?.Compatible_Softwares,
    "id"
  );
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [false] }],
          ["bold", "italic", "underline", "strike", "link", "clean"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );
  function handleDescription(value) {
    setDescription(value);
  }
  useEffect(() => {
    if (description?.length)
      props?.handleDetails({
        ...productBasicDetails,
        description: description,
      });
    // else setDescription(productBasicDetails?.description);
  }, [description]);
  return (
    <div className="company-profile-basic-details-main-container">
      <div className="product-profile-basic-details-left-side-container">
        {/* <div>
          <h5 class="company-header-sub-title">
            {ADD_USE_CASE?.BASIC_INFORMATION_LABEL}
          </h5>
        </div> */}
        {showVendorName ? (
          <div class="field p-r  m-y-company-profile-input-wrapper" id="field-user__email">
            {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
            {true ? (
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                {ADD_USE_CASE?.VENDOR_NAME}
              </label>
            ) : null}
            <InputFieldProfile
              disable={true}
              label={ADD_USE_CASE?.VENDOR_NAME_LABEL}
              value={StaticDropdownData?.Vendor?.Vendor_Name}
              // changeText={(val) =>
              //   props?.handleDetails({ ...companyDetails, Vendor_Name: val })
              // }
              // error={error?.Vendor_Name}
            />
          </div>
        ) : null}

        <div class="field-company-profile p-r m-y-base" id="field-user__country_id">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Page_Type: val?.name,
              })
            }
            data={StaticDropdownData?.Use_Case_Type}
            placeholder={ADD_USE_CASE?.USE_CASE_TYPE_LABEL}
            selectedVal={{ name: productBasicDetails?.Page_Type }}
            returnObject={true}
            label={ADD_USE_CASE?.USE_CASE_TYPE}
          />
          {error?.Page_Type ? <span class="input-field-dropdown-error">{error?.Page_Type}</span> : null}
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.title_level_1 ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.USE_CASE_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_USE_CASE?.USE_CASE_NAME_LABEL}
            value={productBasicDetails?.title_level_1}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                title_level_1: val,
              })
            }
            error={error?.title_level_1}
          />
        </div>

        {/* <div class="field p-r m-t-md" id="field-user__phone_number_prefix">
          {productBasicDetails?.description ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.DESCRIPTION_LABEL}
            </label>
          ) : null}
          <TextArea
            value={productBasicDetails?.description}
            className="m-b-sm"
            label={ADD_USE_CASE?.DESCRIPTION_LABEL}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                description: val,
              })
            }
            subTitleTop={"Please add at least 50 characters"}
            error={error?.description}
          />
        </div> */}
        <div className="m-y-sm m-b-lg p-r">
          <label className="company-details-label-input-field-filled-absolute">{ADD_USE_CASE?.DESCRIPTION_LABEL}</label>
          <ReactQuill
            modules={modules}
            theme="snow"
            value={description ?? productBasicDetails?.description}
            onChange={handleDescription}
            placeholder={ADD_USE_CASE?.DESCRIPTION_LABEL}
          />
          {error?.description ? <span class="input-field-dropdown-error">{error?.description}</span> : null}
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Product_ID: val?.Product_ID,
                Product_Page_URL: val?.Product_Page_URL,
                Product_Name: val?.Product_Name,
                Vendor_ID: val?.Vendor_ID,
                Vendor_Name: val?.Vendor_Name,
                account_logo: val?.Account_Logo,
              })
            }
            data={GetProductFormVendorIdApi}
            placeholder={ADD_USE_CASE?.PRODUCT_LABEL}
            selectedVal={{
              Product_ID: productBasicDetails?.Product_ID,
              Product_Page_URL: productBasicDetails?.Product_Page_URL,
              Product_Name: productBasicDetails?.Product_Name,
              Vendor_ID: productBasicDetails?.Vendor_ID,
              Vendor_Name: productBasicDetails?.Vendor_Name,
              account_logo: productBasicDetails?.Account_Logo,
            }}
            label={ADD_USE_CASE?.PRODUCT}
            returnObject={true}
            name={"Product_Name"}
            //   handleSearchWithApi={handleSearchWithApi}
            //   searchWithApi={true}
          />
          {error?.Use_Cases_Product ? <span class="input-field-dropdown-error">{error?.Use_Cases_Product}</span> : null}
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Use_Case_Industry: val,
              })
            }
            data={industryDropdownData}
            placeholder={ADD_USE_CASE?.INDUSTRY_LABEL}
            selectedVal={productBasicDetails?.Use_Case_Industry}
            label={ADD_USE_CASE?.INDUSTRY}
            returnObject={true}
            //   handleSearchWithApi={handleSearchWithApi}
            //   searchWithApi={true}
          />
          {error?.Use_Case_Industry ? <span class="input-field-dropdown-error">{error?.Use_Case_Industry}</span> : null}
        </div>
        <div class="field-company-profile p-r m-t-xs" id="field-user__country_id">
          {/* <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Use_Case_Customers: val,
              })
            }
            name={"Customer_Name"}
            data={customerDropdownData}
            placeholder={ADD_USE_CASE?.CUSTOMERS_LABEL}
            getSearchedText={true}
            handleChangeSearchText={handleChangeSearchText}
            // flag={true}
            selectedVal={productBasicDetails?.Use_Case_Customers}
            returnObject={true}
            label={ADD_USE_CASE?.CUSTOMERS}
          /> */}
          <MultiSelectCustomer
            error={error}
            productBasicDetails={productBasicDetails}
            handleDetails={props?.handleDetails}
            minimizeMargin={true}
          />
          {error?.Use_Case_Customers ? (
            <span class="input-field-dropdown-error">{error?.Use_Case_Customers}</span>
          ) : null}
        </div>
        <div className={"flex"}>
          <>
            <input
              type="checkbox"
              name="standard_attribute"
              id={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status}
              value={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status}
              checked={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status}
              onChange={() =>
                props?.handleDetails({
                  ...productBasicDetails,
                  Anonymous_Customer: {
                    ...productBasicDetails?.Anonymous_Customer,
                    Anonymous_Customer_Status: !productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status,
                  },
                })
              }
              className="filter-checkbox"
              role={"button"}
              style={{ height: "17px" }}
            />
            <span
              className={`filter-list-items-text ${
                productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status
                  ? `filter-list-items-text-selected`
                  : ""
              }`}
            >
              {/* {productBasicDetails?.name} */}
              Add Anonymous Customer
            </span>
          </>
        </div>
        <div class="grid-two-columns p-relative-company">
          {productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status ? (
            <div class="field p-r m-y-sm" id="field-user__email">
              {productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Name ? (
                <label for="user__email" className="company-details-label-input-field-filled-absolute">
                  {ADD_USE_CASE?.ANONYMOUS_CUSTOMER_NAME}
                </label>
              ) : null}
              <InputFieldProfile
                label={"Customer name"}
                value={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Name}
                changeText={(val) =>
                  props?.handleDetails({
                    ...productBasicDetails,
                    Anonymous_Customer: { ...productBasicDetails?.Anonymous_Customer, Anonymous_Customer_Name: val },
                  })
                }
                error={error?.Anonymous_Customer_Name}
                removeBottomPadding={true}
              />
            </div>
          ) : null}
          {productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Status ? (
            <div class="field p-r m-y-sm" id="field-user__email">
              {productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Country ? (
                <label for="user__email" className="company-details-label-input-field-filled-absolute">
                  {ADD_USE_CASE?.ANONYMOUS_CUSTOMER_COUNTRY}
                </label>
              ) : null}
              {/* <InputFieldProfile
                label={"Customer country"}
                value={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Country}
                changeText={(val) =>
                  props?.handleDetails({
                    ...productBasicDetails,
                    Anonymous_Customer: { ...productBasicDetails?.Anonymous_Customer, Anonymous_Customer_Country: val },
                  })
                }
                error={error?.Anonymous_Customer_Country}
                removeBottomPadding={true}
              /> */}
              <SingleSelectNewDropdown
                handleSelectedData={(val) =>
                  props?.handleDetails({
                    ...productBasicDetails,
                    Anonymous_Customer: { ...productBasicDetails?.Anonymous_Customer, Anonymous_Customer_Country: val },
                  })
                }
                data={DropDownData}
                placeholder={"Customer country"}
                flag={true}
                selectedVal={productBasicDetails?.Anonymous_Customer?.Anonymous_Customer_Country}
                name={"name"}
              />
              {error?.Anonymous_Customer_Country ? (
                <span class="input-field-dropdown-error">{error?.Anonymous_Customer_Country}</span>
              ) : null}
            </div>
          ) : null}
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.Page_URL ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.PAGE_URL}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_USE_CASE?.PAGE_URL_LABEL}
            value={productBasicDetails?.Page_URL}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                Page_URL: val,
              })
            }
            error={error?.Page_URL}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.call_to_action_name ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.CTA_NAME}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_USE_CASE?.CTA_NAME_LABEL}
            value={productBasicDetails?.call_to_action_name}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                call_to_action_name: val,
              })
            }
            error={error?.call_to_action_name}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.call_to_action_link ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.CTA_LINK}
            </label>
          ) : null}
          <InputFieldProfile
            label={ADD_USE_CASE?.CTA_LINK_LABEL}
            value={productBasicDetails?.call_to_action_link}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                call_to_action_link: val,
              })
            }
            error={error?.call_to_action_link}
          />
        </div>
        <div class="field p-r m-y-company-profile-input-wrapper" id="field-user__email">
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {productBasicDetails?.image_src ? (
            <label for="user__email" className="company-details-label-input-field-filled-absolute">
              {ADD_USE_CASE?.IMAGE_SRC}
            </label>
          ) : null}
          {/* image_src */}
          <InputFieldProfile
            label={ADD_USE_CASE?.IMAGE_SRC_LABEL}
            value={productBasicDetails?.image_src}
            changeText={(val) =>
              props?.handleDetails({
                ...productBasicDetails,
                image_src: val,
              })
            }
            // error={error?.Relative_Position}
          />
        </div>
      </div>
      <div className="company-profile-basic-details-right-side-container">
        {/* <h3 className="company-profile-feature-services-content-title">
          Upload Cover Image
        </h3> */}
        {/* <AddBlurbImage
          handleDetails={props?.handleDetails}
          image={productBasicDetails}
          handleImageFile={props?.handleImageFile}
        /> */}
      </div>
    </div>
  );
};

export default BasicUseCaseDetails;
