import React from "react";

function Card(props) {
  return (
    <div
      class={
        props?.parent == "ProductPage"
          ? "vertical-product-card modern-card modern-card--elevated"
          : "vertical-product-card-for-provider modern-card modern-card--elevated"
      }
    >
      {props?.children}
    </div>
  );
}
// vertical-product-card modern-card modern-card--elevated
export default Card;
