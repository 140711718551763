import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import RoundSpinner from "../../../../../utlis/spinner/roundSpinner";
import * as CONFIG_KEYS from "../../../../../config/config";
const ConfirmPublishPortal = (props) => {
  const tabIndex = props?.tabIndex;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PORTAL_DETAILS = CONFIG_KEYS?.CONFIG?.VENDOR_PUBLISH_PORTAL;
  const isLoading = props?.vendorPostData?.isLoading;
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        style={{ background: "rgba(232, 230, 230, 0.4)" }}
      >
        <div className="confirm-delete-portal-main-wrapper-publish-vendor p-r">
          <div className="confirm-delete-portal-wrapper-publish-vendor">
            <div style={{ borderBottom: "1px solid #d3d3d3" }} className="confirm-delete-portal-header-wrapper">
              <div>
                <h3 className="p-l-base confirm-delete-portal-header-content-header-text">{PORTAL_DETAILS?.TITLE}</h3>
              </div>
              {/* <div onClick={() => props?.handleClose()} className="cursor--pointer">
              <FeatherIcon icon="x" size="25" />
            </div> */}
            </div>
            <div className="confirm-delete-portal-header-content-wrapper p-y-sm">
              <div>
                <h3 className="confirm-delete-portal-header-content-text">{PORTAL_DETAILS?.DESCRIPTION}</h3>
              </div>
            </div>
            <div className="confirm-delete-portal-header-actions-wrapper-publish-vendor  m-y-base ">
              {isLoading ? (
                <div className="p-x-md p-y-base">
                  <RoundSpinner />
                </div>
              ) : (
                <>
                  <div onClick={() => props?.handleActionsClicked("Confirm")}>
                    <p className="m-x-sm confirm-delete-portal-header-action-publish-text cursor--pointer">
                      {PORTAL_DETAILS?.CONFIRM_BUTTON}
                    </p>
                  </div>
                  <div onClick={() => props?.handleClose()}>
                    <p className="confirm-delete-portal-header-action-cancel-text cursor--pointer">
                      {PORTAL_DETAILS?.CLOSE_BUTTON}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default ConfirmPublishPortal;
