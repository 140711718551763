import React, { useEffect } from "react";
import Header from "../home/header/header";
import { useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { useSelector } from "react-redux";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import { useState } from "react";
import * as ENV_KEYS from "../../config/configKeys";
const UserInvite = () => {
  const [actionPressed, setActionPressed] = useState("");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  const UserInvite = useEnCryptPostApi(
    `/InviteManagement`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const CheckLicense = useEnCryptGetApi(
    `/noOfLicenseExist?Vendor_ID=${authData?.data?.vendorId}&User_ID=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );

  const handleRequest = (val) => {
    setActionPressed(val);
    const RAW_DATA = {
      User_ID: authData?.data?.userId,
      Action: val,
    };
    UserInvite?.handleTrigger(RAW_DATA);
  };

  useEffect(() => {
    if (UserInvite?.data?.Status) {
      window.location.replace(actionPressed === "Accept" ? "/business-profile/blurbs" : "/");
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Action: ENV_KEYS.CONFIG.INVITED_VENDOR_SIGNUP_COMPLETE,
        Item_Type: null,
        Item_Id: null,
        Persona: actionPressed === "Accept" ? "Business user" : "Buyer",
        User_Type: "Known",
        Target: {
          page: "business profile",
          link: "/business-profile/blurbs",
        },
        Object: {
          section: null,
          context: `Invited vendor signup complete`,
          page: "/user-invite",
          Persona: actionPressed === "Accept" ? "Business user" : "Buyer",
        },
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    }
  }, [UserInvite?.data]);
  return (
    <>
      <Header show={true} />
      <div className="user-invite-container container">
        {CheckLicense?.data?.data?.No_Of_License > 0 ? (
          <div className="invite-container">
            <img src={require("../../assets/images/OctateLogo.png")} className="user-invite-octate-image" alt="octate-image" />
            <h1 className="invite-header">You have been invited to join the business.</h1>
            <div></div>
            <div className="flex space-evenly align-items-center" style={{ width: "100%" }}>
              <div className="product-card-action-button" onClick={() => handleRequest("Reject")}>
                Reject
              </div>
              <div className="product-card-action-button-blue" onClick={() => handleRequest("Accept")}>
                Accept
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="invite-header">Maximum limit reached</h1>
            <p>Please connect with your admin to increase the license</p>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInvite;
