import React, { useState } from "react";
import ShowFilter from "./component/showFilter";
import * as DASHBOARD_KEYS from "../../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { dashboardFilterFn } from "../../../../contexts/reducer/dashboardReducer";
import DashboardTabs from "./dashboardTabs";
const DropdownFilter = (props) => {
  const DASHBOARD_CONFIG_KEYS = DASHBOARD_KEYS?.CONFIG?.VENDOR_DASHBOARD;
  const FILTER_KEYS = DASHBOARD_CONFIG_KEYS?.FILTERS;
  const dashboardFilterDetails = useSelector(
    (state) => state?.dashboardDetails?.dashboardFilter
  );
  const dispatch = useDispatch();
  const selectedTab = props?.selectedTab;
  const handleFilterSelected = (val) => {
    const selectedFilter = dashboardFilterDetails?.map((list) => {
      if (list?.[FILTER_KEYS?.TYPE] == val?.[FILTER_KEYS.TYPE]) {
        return val;
      } else {
        return list;
      }
    });
    dispatch(dashboardFilterFn(selectedFilter));
    // setFilterList(selectedFilter);
  };
  return (
    <div className="dashboard-filter-list-main-wrapper">
      {/* <DashboardTabs
        selectedTab={selectedTab}
        handleTabClicked={props?.handleTabClicked}
      /> */}
      {/* <div
        className="flex m-t-base m-x-half-base"
        style={{ borderRight: "3px solid #d3d3d3" }}
      >
        <h2 className={`dashboard-filter-tab-selected-index-text`}>
          {selectedTab == 1
            ? "Visitor Analytics"
            : selectedTab == 2
            ? "Engagements Analytics"
            : "Content Analytics"}
        </h2>
      </div> */}
      {dashboardFilterDetails?.map((value) => {
        return (
          <ShowFilter
            handleFilterSelected={handleFilterSelected}
            label={value?.[FILTER_KEYS?.LABEL]}
            type={value?.[FILTER_KEYS?.TYPE]}
          />
        );
      })}
    </div>
  );
};

export default DropdownFilter;
