import React from "react";
import { useState } from "react";
import Switch from "react-switch";
import { usePostUserPreferencesMutation } from "../../../adapters/xhr/crudServiceApi";
import { useEnCryptPostApi } from "../../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
const PreferencesSwitch = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [Preferences, setPreferences] = useState(props?.checked);
  const PreferencesPostApi = useEnCryptPostApi(
    `/updateUserPreferences`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostUserPreferencesMutation
  );

  const handleChange = (val) => {
    setPreferences((prev) => !prev);
    let RAW_DATA = {};
    props?.section
      ? (RAW_DATA = {
          Persona: props?.data?.persona,
          personaDisplayName: props?.data?.personaDisplayName,
          personaGlobalChannelOptin: val,
          User_ID: authData?.data?.userId,
          User_Persona: authData?.data?.Persona,
          channel: props?.channel,
        })
      : (RAW_DATA = {
          ...props?.data,
          sectionElementDisplayValue: val,
          User_ID: authData?.data?.userId,
          User_Persona: authData?.data?.Persona,
          channel: props?.channel,
        });
    PreferencesPostApi?.handleTrigger(RAW_DATA);
  };

  return (
    <Switch
      offColor="#F1EBFF"
      onColor="#CAD3FC"
      offHandleColor="#BBC5CB"
      onHandleColor="#5570F1"
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      height={20}
      width={40}
      className="react-switch"
      onChange={handleChange}
      checked={Preferences}
    />
  );
};

export default PreferencesSwitch;
