import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import FilterSearch from "../../../searchResult/components/filter/filterSearch";
import CategoryFilterDropdownList from "./categoryFilterDropdownList";

const CategoryFilter = (props) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const productCategoryDropdownData = useEnCryptGetApi(
    `/getProductCategoryForProposalTemplates`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  )?.data?.data;
  const listData = productCategoryDropdownData?.length ? productCategoryDropdownData : [];
  function handleCheckedData(val, index) {
    let newData = [...dropDownData];
    const data = newData.map((item) =>
      item?.Item_Name === val?.Item_Name ? { ...val, isSelected: !val?.isSelected } : item
    );
    setDropDownData(data);
    const DATA = data
      .filter((item) => item.isSelected)
      .map((list) => {
        return list.Item_ID;
      });
    props?.getAllCategoriesId(DATA);
  }
  useEffect(() => {
    if (listData?.length) {
      setDropDownData(listData);
    } else {
      setDropDownData([]);
    }
  }, [listData]);
  const handleToggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilterOpen(false);
    }
  };
  function handleApplyChanges(val) {
    setDropDownData(val);
    const DATA = val
      .filter((item) => item.isSelected)
      .map((list) => {
        return list.Item_ID;
      });
    props?.getAllCategoriesId(DATA);
    setFilterOpen(false);
  }
  return (
    <div tabIndex={0} onBlur={handleBlur}>
      <div className="filter-section-header-container">
        <div className="filter-section-header">{"Search product categories"}</div>
        <FeatherIcon icon="search" size="25" color={"#130F26"} role="button" onClick={() => handleToggleFilter()} />
      </div>
      <div className="filter-list-items-container">
        {dropDownData?.map((item, index) => {
          if (item?.isSelected)
            return (
              <div className="filter-list-items" key={item?.name}>
                <input
                  type="checkbox"
                  name="standard_attribute"
                  id={item?.Item_Name}
                  value={item?.Item_Name}
                  checked={item?.isSelected}
                  onChange={() => handleCheckedData(item, index)}
                  className="filter-checkbox"
                  role={"button"}
                />
                <span className={`filter-list-items-text ${item?.isSelected ? `filter-list-items-text-selected` : ""}`}>
                  {`${item?.Item_Name} (${item?.Total_Count})`}
                </span>
              </div>
            );
        })}
        {filterOpen ? (
          <CategoryFilterDropdownList
            data={dropDownData}
            handleToggleFilter={handleToggleFilter}
            keyName={"product categories"}
            NAME={"Item_Name"}
            handleApplyChanges={handleApplyChanges}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CategoryFilter;
