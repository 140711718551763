import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import EmailVerificationOctate from "../signUp/emailVerification/emailVerificationOctate";
import VerifyEmailForm from "./components/verifyEmailForm";

const VerifyEmail = () => {
  const tempAuthData = useSelector((state) => state?.authDetails?.tempDetails);
  return tempAuthData?.resetPassword ? (
    <main>
      {/* <HeaderSEO data={props?.headerTitle} /> */}
      <div class="page-content page-content--reset ">
        <div class="splitted-page">
          <EmailVerificationOctate />
          <VerifyEmailForm />
        </div>
      </div>
    </main>
  ) : (
    <Navigate to="/" replace />
  );
};

export default VerifyEmail;
