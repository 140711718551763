import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptSignalTrackingApi } from "../../../../../utlis/customHooks/customHooks";
import { GetIdFormLink, ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../../../../utlis/hof/hof";
import ProductProfileSkeleton from "../../../../productPage/component/skeleton/productProfile";
import ProductEngagement from "./productEngagement";
import * as SECRET_KEYS from "../../../../../config/configKeys";
import SignInModal from "../../../../../utlis/hoc/signInModal/signInModal";
const ProductDetails = (props) => {
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const PARAMS = useParams();
  const navigate = useNavigate();
  const PRODUCT = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
  const signalTrackingVendorObject = {
    action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: "vendor page",
    section: props?.section,
  };

  const ProductData = useEnCryptGetApi(
    `/getProductDetailsForProductPage?productId=${PRODUCT?.Id}&userId=${USER_ID}`,
    KEYS?.END_POINTS?.SEARCH?.END_POINT_URL,
    useGetRequestQuery
  );

  props?.handleProductData(ProductData?.data?.data);

  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameLinkClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingVendorObject?.action,
      Item_Type: "Vendor",
      Item_Id: val?.Vendor_ID,
      Target: {
        page: signalTrackingVendorObject?.page,
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}`,
      },
      Object: {
        section: signalTrackingVendorObject?.section,
        context: val?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  const handleManageProposal = () => {
    if (authData?.data?.userExist) {
      navigate("/proposals");
    } else {
      setSignInModalVisible((prev) => !prev);
    }
  };

  return (
    <>
      {signInModalVisible ? <SignInModal handleClose={() => setSignInModalVisible((prev) => !prev)} /> : null}

      <div className="grid-one-columns tablet-1-col">
        {ProductData?.isLoading || ProductData?.isFetching ? (
          <ProductProfileSkeleton />
        ) : (
          <>
            <div className="flex flex-wrap space-between m-y-xs">
              <div
                className="flex"
                style={{
                  width: "80%",
                }}
              >
                <img
                  class=" ls-is-cached lazyloaded"
                  data-src={
                    ProductData?.data?.data?.Account_Logo
                      ? ProductData?.data?.data?.Account_Logo
                      : KEYS?.CORRUPT_IMAGE_ICON
                  }
                  src={
                    ProductData?.data?.data?.Account_Logo
                      ? ProductData?.data?.data?.Account_Logo
                      : KEYS?.CORRUPT_IMAGE_ICON
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
                  }}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                    border: "1px solid #f5f5f5",
                  }}
                  alt={`${ProductData?.data?.data?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
                />

                <div className="flex-list space-evenly m-l-sm">
                  <h1 class="product-hero__header-title-name">
                    {`${ToTitleCase(ProductData?.data?.data?.Product_Name)} `}
                    {/* <span className="vendor-name-append">by {ProductData?.data?.data?.Vendor_Name}</span> */}
                    <div
                      onClick={() => {
                        handleVendorNameLinkClicked(ProductData?.data?.data);
                      }}
                    >
                      <a
                        class="vendor-name-append"
                        href={`/vendor/${ReplaceBlankSpaceByHyphen(
                          ProductData?.data?.data?.Vendor_Name,
                          ProductData?.data?.data?.Vendor_ID
                        )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
                      >
                        by {ProductData?.data?.data?.Vendor_Name}
                      </a>
                    </div>
                  </h1>
                  {ProductData?.data?.data ? <ProductEngagement data={ProductData?.data?.data} /> : null}
                  {/* <div class="product-header-title-provider-container m-b-base">
                  {ProductData?.data?.data ? (
                    <a
                      class="product-header-title-provider"
                      onClick={() => {
                        handleVendorNameLinkClicked(ProductData?.data?.data);
                      }}
                      href={`/vendor/${ReplaceBlankSpaceByHyphen(
                        ProductData?.data?.data?.Vendor_Name,
                        ProductData?.data?.data?.Vendor_ID
                      )}/${KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE}`}
                    >
                      {ProductData?.data?.data?.Vendor_Name}
                    </a>
                  ) : null}
                </div> */}
                  {/* <p
                className=" landing-page__description m-b-xs"
                // className="vendor-onboarding-verified-text"
                style={{ maxWidth: "100%" }}
              >
                {ProductData?.data?.data?.Product_Description}
              </p> */}
                </div>
              </div>
              <div className="business-profile-button-container mobile-hidden">
                <div className="business-profile-header-button" role={"button"} onClick={() => handleManageProposal()}>
                  Manage Proposal
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProductDetails;
