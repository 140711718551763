import { replace } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import PasswordField from "../../signUp/onBoard/components/passwordField";
import LoadingSpinner from "../../../../utlis/spinner/loadingSpinner";

const ResetPasswordForm = (props) => {
  const [password, setPassword] = useState({});
  const [error, setError] = useState(null);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const RESET_PASSWORD = KEYS?.RESET_PASSWORD;
  const authData = useSelector((state) => state?.authDetails?.details);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ResetPassword = useEnCryptPostApi(
    `/resetForgotPassword`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );

  useEffect(() => {
    if (ResetPassword?.data?.status) {
      dispatch(tempAuthDetails({}));
      navigate("/", { replace: true });
    } else if (!ResetPassword?.data?.status) {
      setError({ password: ResetPassword?.data?.message });
    }
  }, [ResetPassword?.data]);

  const handleSubmit = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@$^*()\[\]{}\\|;:'",<.>/?`\-_=+])(?=.{8,})\S+$/;

    if (!password.password) {
      setError({ password: RESET_PASSWORD?.PASSWORD_REQUIRED });
    } else if (!passwordRegex.test(password.password)) {
      setError({
        password: RESET_PASSWORD?.PASSWORD_PATTERN,
      });
    } else {
      if (password?.password === password?.confirmPassword) {
        const rawData = {
          password: password?.password,
          userId: authData?.data?.userId,
        };
        ResetPassword?.handleTrigger(rawData);
      } else {
        setError({
          confirmPassword: RESET_PASSWORD?.PASSWORD_DO_NOT_MATCH,
        });
      }
    }

    // navigate("/email-verification", { replace: true });
    // const validate = validateForms(rawData);
    // setError(validate);
    // if (_.isEmpty(validate)) OnboardingDetails?.handleTrigger(rawData);
  };
  return (
    <div class="splitted-page__content">
      <div class="splitted-page__container">
        <h4 className="already-account-text m-b-mini ">
          {RESET_PASSWORD?.DONT_HAVE_A_CREATE_ACCOUNT}{" "}
          <a href="/sign-up" style={{ color: "#1371c3" }}>
            {RESET_PASSWORD?.SIGN_UP}
          </a>
        </h4>
        <div class="user-registration-container">
          <div className="sign-up-page-header-text m-b-sm m-t-md ">{RESET_PASSWORD?.FORGOT_PASSWORD_TITLE}</div>
          {/* <div className="sign-up-page-text m-b-sm">
            Please provide following details
          </div> */}
          {/* <div className="sign-up-page-text m-b-md">
            {RESET_PASSWORD?.FORGOT_PASSWORD_SUB_TITLE}
          </div> */}
          <div className="m-b-xs">
            <PasswordField
              label={RESET_PASSWORD?.PASSWORD}
              value={password?.password}
              changeText={(val) => setPassword({ ...password, password: val })}
              error={error?.password}
              type={"password"}
              icon={"lock"}
            />
          </div>

          <PasswordField
            label={RESET_PASSWORD?.CONFIRM_PASSWORD}
            value={password?.confirmPassword}
            changeText={(val) => setPassword({ ...password, confirmPassword: val })}
            error={error?.confirmPassword}
            type={"password"}
            icon={"lock"}
          />
          {ResetPassword?.isLoading ? (
            <div className="button primary fluid create-account-button" style={{ width: "170px" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className="create-account-button m-t-md" role={"button"} onClick={() => handleSubmit()}>
              {RESET_PASSWORD?.RESET_PASSWORD}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
