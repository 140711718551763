import React from "react";
import SelectedSingleChatCard from "./selectedSingleCard";
import _ from "lodash";
import moment from "moment/moment";
import { Waypoint } from "react-waypoint";
function SelectedCardList(props) {
  //   const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  //   //  http://localhost:8080/getUserMessages?Conversation_ID=6&Page=1
  //   const GetApiData = useEnCryptGetApi(
  //     `/getUserMessages?Conversation_ID=6&Page=1`,
  //     KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
  //     useGetRequestQuery
  //   );
  const newData = props?.newData;
  const MoreNewData = newData?.data?.data?.length ? true : false;
  const NewDataIsLoading = newData?.isLoading || newData?.isFetching ? false : true;
  const allChatList = props?.allChatList?.length ? props?.allChatList : [];
  const formatDate = (date) => {
    // const newDate = new Date(date);
    const options = { weekday: "long", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    // const newDate = new Date(date);
    const options = { hour: "numeric", minute: "numeric" };
    return date.toLocaleTimeString(undefined, options);
  };
  const fetchData = (val) => {
    if (MoreNewData && NewDataIsLoading) {
      // alert('fetch more')
      props?.fetchData(val);
    }
  };
  const groupByData = _.groupBy(allChatList, (obj) => moment.unix(obj.Date_Time).format("MM/DD/YYYY"));
  const DataToGroupBy =
    typeof groupByData === "object" && Object.keys(groupByData).length ? Object.keys(groupByData) : [];
  return (
    <div className="main-selected-chat-list-main-wrapper p-b-mini">
      <Waypoint  fireOnRapidScroll={true} topOffset="-50px" onEnter={fetchData} />
      {DataToGroupBy?.map((objKeys) => {
        const today = new Date();
        const targetDate = new Date(objKeys);

        const time = formatTime(targetDate);
        const formattedDate = formatDate(targetDate);

        const isToday = targetDate.toDateString() === today.toDateString();
        const isTomorrow = targetDate.toDateString() === new Date(today.getTime() + 24 * 60 * 60 * 1000).toDateString();
        const isYesterday =
          targetDate.toDateString() === new Date(today.getTime() - 24 * 60 * 60 * 1000).toDateString();
        const isWithinSevenDays = targetDate <= new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

        let displayText;

        if (isToday) {
          displayText = "Today";
        } else if (isTomorrow) {
          displayText = "Tomorrow";
        } else if (isYesterday) {
          displayText = "Yesterday";
        } else if (isWithinSevenDays) {
          const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          displayText = days[targetDate.getDay()];
        } else {
          displayText = formattedDate;
        }

        return (
          <div key={objKeys}>
            <div className="main-selected-chat-list-group-by-date-wrapper">
              <h5>{displayText}</h5>
            </div>
            <div className="main-selected-chat-list-group-by-date-wrapper-card-gap">
              {groupByData[objKeys].map((list) => {
                return <SelectedSingleChatCard key={list?.Message_ID} item={list} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
    // <div className="main-selected-chat-list-main-wrapper">
    // {allChatList.map((list) => {
    //   return <SelectedSingleChatCard item={list} />;
    // })}
    // </div>
  );
}

export default SelectedCardList;
