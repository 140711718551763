import React from "react";
import moment from "moment";
import * as NOTIFICATION_KEYS from "../../../../config/config";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ChangeAccess from "./changeAccess";
import { GetNameInitials } from "../../../../utlis/hof/hof";
import { useAddProposalApiMutation, usePostProposalTeamsApiMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
const TeamsCard = (props) => {
  const dispatch = useDispatch();
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "1s",
      ss: "%ss",
      m: "1 min",
      mm: "%d min",
      h: "1 hr",
      hh: "%d hr",
      d: "1 d",
      dd: "%d d",
      M: "1 mo",
      MM: "%d mo",
      y: "1 yr",
      yy: "%d yr",
    },
  });
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const proposalDetails = props?.proposalDetails;
  const TEAMS_KEYS_API = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const ADD_PROPOSAL_CONFIG_KEYS = NOTIFICATION_KEYS?.CONFIG?.ADD_PROPOSAL;
  const data = props?.data;
  const UserName = data?.[TEAMS_KEYS_API.FULL_NAME];
  const SharedUserId = data?.Shared_With;
  const emailAddress = data?.[TEAMS_KEYS_API.EMAIL];
  const Status = data?.[TEAMS_KEYS_API.STATUS];
  const Access = data?.[TEAMS_KEYS_API.ACCESS];
  const PROPOSAL_USER_ID = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.CREATED_BY];
  const PROPOSAL_ID = proposalDetails?.[ADD_PROPOSAL_CONFIG_KEYS?.PROPOSAL_ID];
  const DELETE_API = `/deleteSharedProposals`;
  const vendorPostData = useEnCryptPostApi(
    DELETE_API,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostProposalTeamsApiMutation
  );
  const handleRemoveSharedUsers = () => {
    if (!vendorPostData.isLoading) {
      vendorPostData.handleTrigger({
        Proposal_ID: [PROPOSAL_ID],
        User_ID: SharedUserId,
      });
    }
  };
  const initials = GetNameInitials(UserName ? UserName : emailAddress);
  //   const time = moment(data?.[TEAMS_KEYS_API?.CREATED_ON] * 1000).fromNow();
  const navigate = useNavigate();
  return (
    <div className={`notification-card-main-wrapper m-y-base`}>
      <div className="notification-card-left-side-main-wrapper">
        <div className="teams-card-image-main-wrapper">
          <h5 className="teams-card-image-text">{initials}</h5>
        </div>
      </div>
      <div className="teams-card-right-side-main-wrapper ">
        <div className="teams-card-description-text-wrapper">
          <h3 className="teams-card-description-text">{data?.[TEAMS_KEYS_API.FULL_NAME]}</h3>
          <p className="notification-card-date-text">{data?.[TEAMS_KEYS_API.EMAIL]}</p>
        </div>
        {/* <div className="teams-card-status-text-wrapper">
          <p
            className={`teams-status-check-text ${
              data?.[TEAMS_KEYS_API.STATUS] == "Pending"
                ? "status-pending"
                : data?.[TEAMS_KEYS_API.STATUS] == "Joined"
                ? "status-joined"
                : "status-owner"
            }`}
          >
            {data?.[TEAMS_KEYS_API.STATUS]}
          </p>
        </div> */}
        {Access == "owner" ? (
          <div className="teams-card-status-text-wrapper">
            <p className={`teams-status-check-text status-owner`}>{`Owner`}</p>
          </div>
        ) : PROPOSAL_USER_ID == userId && USER_CAN_EDIT ? (
          <div>
            <ChangeAccess data={data} />
          </div>
        ) : (
          <div className="teams-card-status-text-wrapper">
            <p
              className={`teams-status-check-text ${
                Access == "owner" ? "status-owner" : Access == "can_edit" ? "status-joined" : "status-pending"
              }`}
            >
              {`${Access == "owner" ? "Owner" : Access == "can_edit" ? "Can Edit" : "Can View"}`}
            </p>
          </div>
        )}
        {Access == "owner" ? null : PROPOSAL_USER_ID == userId ? (
          <div onClick={handleRemoveSharedUsers} className="teams-card-status-text-wrapper cursor--pointer">
            {vendorPostData.isLoading ? (
              <p className={`teams-status-check-text status-remove`}>{"Loading..."}</p>
            ) : (
              <p className={`teams-status-check-text status-remove`}>{"Remove"}</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TeamsCard;
