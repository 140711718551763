import { replace } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CreateNewVendorPortal from "./createNewVendorPortal";
import VendorList from "./vendorList";
import VendorSearch from "./vendorSearch";

const OrganizationStep = ({ data, emailId }) => {
  const [showModal, setShowModal] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const [organizationData, setOrganizationData] = useState(data);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const JOIN_ORGANIZATION = KEYS?.JOIN_ORGANIZATION;
  // const organizationData = props?.data;
  const navigate = useNavigate();

  const REQUEST_STATUS =
    authData?.data?.requestAccepted || authData?.data?.requestPending || authData?.data?.adminStatus;

  const [, domain] = emailId?.split("@");

  const handleDomainUrlForModal = (val, type) => {
    window.scrollTo(0, 0);
    if (type === "search") {
      setDomainUrl(val);
      setShowModal(true);
    } else if (val === "noResult") {
      setDomainUrl(domain);
      setShowModal(true);
    }
  };

  return (
    <>
      {showModal ? (
        <CreateNewVendorPortal
          pageToNavigate={"home"}
          handleClose={() => setShowModal(false)}
          action={"createNew"}
          domainUrl={domainUrl}
        />
      ) : null}
      <div className="grid-max-auto">
        <div>
          <div type="checkbox" className="otp-verification-checkbox" />
        </div>
        <div className="vendor-onboard-container">
          <h1 className="otp-verification-title">{JOIN_ORGANIZATION?.JOIN_ORG_TITLE}</h1>
          {organizationData?.length > 0 ? (
            <>
              <p className="otp-verification-text m-b-sm">{JOIN_ORGANIZATION?.SUB_TITLE}</p>
            </>
          ) : (
            <>
              <p className="otp-verification-text m-b-sm">
                Unable to find a matching company for your email domain <strong>{domain}</strong>. Search here if email
                domain differs from company domain, otherwise
                <a className="resend-button" role={"button"} onClick={() => handleDomainUrlForModal("noResult")}>
                  create a new company
                </a>
              </p>
              {/* <p className="otp-verification-resend-text m-b-md">
                {JOIN_ORGANIZATION?.NO_MATCH_QUESTION}
                <p>{JOIN_ORGANIZATION?.CREATE_NEW}</p>
              </p> */}
            </>
          )}
          {organizationData?.map((item) => {
            return (
              <div className="m-b-sm">
                <VendorList data={item} handleButtonVisible={() => setButtonVisible(true)} />
              </div>
            );
          })}
          {REQUEST_STATUS ? null : (
            <>
              <p className="otp-verification-resend-text m-b-sm">Not the company you are looking for?</p>
            </>
          )}
          {REQUEST_STATUS ? null : (
            <VendorSearch
              organizationData={organizationData}
              handleSimilarData={(val) => setOrganizationData(val)}
              handleButtonVisible={() => setButtonVisible(true)}
              handleModal={(val) => handleDomainUrlForModal(val, "search")}
              // handleDomainUrl={(val) => handleDomainUrlForModal(val, "search")}
            />
          )}

          {buttonVisible ? (
            <div
              className="create-account-button"
              role={"button"}
              // onClick={() => props?.completeStep()}
              onClick={() => navigate("/", { replace: true })}
            >
              {JOIN_ORGANIZATION?.CONTINUE}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OrganizationStep;
