import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  useAddProductsApiMutation,
  useAddProposalApiMutation,
  useAddProposalPutApiMutation,
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import {
  GetIdFormLink,
  getIndexOfSectionsAndQuestions,
  TrackQuestionAdded,
  TrackVendorAdded,
  validateAddProposalPage1,
  validateAddProposalPage2,
  validateAddProposalPage3,
} from "../../utlis/hof/hof";
import * as ADD_PROPOSAL_KEYS from "../../config/config";
import ProposalList from "../answerProposal/component/proposalList";
import AnswerProposalHeader from "../answerProposal/component/answerProposalHeader/answerProposalHeader";
import ProposalSummary from "./component/proposalSummary/proposalSummary";
import AnswerProposalTab from "./component/answerProposalTab/answerProposalTab";
import EditProposalHeader from "./component/editProposalHeader/editProposalHeader";
import EditProposalList from "./component/editProposalList";
import * as SECRET_KEYS from "../../config/configKeys";
import { useParams } from "react-router";
import { notificationTargetFn } from "../../contexts/reducer/notificationReducer";
const EditQuestionProposal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const notificationJson = props?.notificationJson;
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [proposalRefData, setProposalRefData] = useState({});
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const PARAMS = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyErrors, setCompanyErrors] = useState({});
  const [proposalDetails, setProductDetails] = useState({});
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const PROPOSAL_QUESTION_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_QUESTION;
  const authData = useSelector((state) => state?.authDetails?.details);
  const userId = authData?.data?.userId;
  const PROPOSAL_TYPE = "question";
  const editProduct = props?.editProduct;
  const USER_CAN_EDIT = proposalDetails?.[PROPOSAL_KEYS?.CAN_EDIT];
  const PROPOSAL_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : props?.Id;
  // https://thebox-dev.portqii.com/core/vendor/productOnboardingGetProduct?Product_ID=17258&User_ID=546
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getProposal/${PROPOSAL_ID}?QuestionCount=1&userId=${userId}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );

  // /lockProposal?Proposal_ID=123&User_ID=123
  const lockProposal = useEnCryptSignalTrackingApi(
    `/lockProposal`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostRequestMutation
  );
  const unlockProposal = useEnCryptPostApi(
    `/unlockProposal`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (unlockProposal.data?.status) {
      setProductDetails({});
    }
  }, [unlockProposal?.data]);
  function runLockProposalAfterEvery2Min(val) {
    if (
      proposalDetails?.[PROPOSAL_KEYS?.IS_SHARED] &&
      proposalDetails?.[PROPOSAL_KEYS?.CAN_EDIT] &&
      !proposalDetails?.Is_Locked
    ) {
      lockProposal.handleTrigger({
        Proposal_ID: PROPOSAL_ID,
        User_ID: userId,
      });
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      runLockProposalAfterEvery2Min();
    }, 20000);
    return () => clearInterval(interval);
  }, [proposalDetails]);
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  useEffect(() => {
    if (GetProductDataFromApi?.data?.data) {
      setProductDetails({
        ...GetProductDataFromApi?.data?.data,
        [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? new Date(GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE] * 1000)
          : null,
      });
      if (
        GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.IS_SHARED] &&
        GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.CAN_EDIT] &&
        !GetProductDataFromApi?.data?.data?.Is_Locked
      ) {
        lockProposal.handleTrigger({
          Proposal_ID: GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          User_ID: userId,
        });
      }
      const val1 = JSON.stringify({ ...GetProductDataFromApi?.data?.data });
      setProposalRefData(val1);
    }
    // setProductDetails(DUMMY_ARRAY);
  }, [GetProductDataFromApi?.data]);
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;
  const validateEmailId = authData?.data?.emailId
    ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
    : null;
  // https://thebox-dev.portqii.com/proposals/addProposal
  const vendorPostData = useEnCryptPostApi(
    `/updateProposal`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useAddProposalPutApiMutation
  );
  useEffect(() => {
    if (vendorPostData?.data) {
      setProductDetails({});
      setUpdateStatus(true);
      if (PARAMS?.name) {
        navigate(-1);
      } else {
        props?.handleClose(false);
      }
      // props?.handleClose(false);
    }
  }, [vendorPostData?.data]);
  const handleCloseClicked = () => {
    unlockProposal.handleTrigger({
      Proposal_ID: PROPOSAL_ID,
      User_ID: userId,
    });

    if (PARAMS?.name) {
      navigate(-1);
      dispatch(notificationTargetFn(null));
    } else {
      props?.handleClose(false);
    }
  };
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    setFooterNumberClicked(val);
  };
  const handleScreenToShow = (val) => {
    setFooterNumberClicked(val);
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  const triggerSignalTracking = () => {
    const refData = JSON.parse(proposalRefData);
    // question changed
    const oldValue = refData?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]?.length
      ? [...refData?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]]
      : [];
    // vendor changed
    const oldVendorData = refData?.[PROPOSAL_KEYS?.VENDORS]?.length ? [...refData?.[PROPOSAL_KEYS?.VENDORS]] : [];
    // track question added
    const QuestionAdded = TrackQuestionAdded(oldValue, proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_QUESTION]);
    // track vendor added
    const VendorAdded = TrackVendorAdded(oldVendorData, proposalDetails?.[PROPOSAL_KEYS?.VENDORS]);
    const allVendorIds = proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length
      ? proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.map((list) => {
          return list?.[PROPOSAL_KEYS?.VENDOR_ID];
        })
      : [];
    if (proposalDetails?.[PROPOSAL_KEYS?.STATUS] == "Draft" || !proposalDetails?.[PROPOSAL_KEYS?.STATUS]) {
      const Action = SECRET_KEYS.CONFIG.BUYER_PUBLISHED;

      const Target = {
        Id: allVendorIds,
        type: "Vendor",
        page: "business profile",
        link: "/sales-hub/proposals",
      };
      const Object = {
        Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
          ? proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
          : proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
        type: "Proposal",
        section: null,
        context: "Buyer Published",
        page: "buyer proposal page",
      };
      const signalTrackingPostData = {
        Actor: authData?.data?.userId,
        Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
        Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
        SignalCreatedAt: new Date().getTime() / 1000,
        Action: Action,
        Target: Target,
        Object: Object,
      };
      SignalTracking?.handleTrigger(signalTrackingPostData);
    } else {
      if (VendorAdded?.NewVendor && VendorAdded?.NewVendor?.length) {
        const Action = SECRET_KEYS.CONFIG.VENDOR_ADDED;
        const Target = {
          Id: VendorAdded?.NewVendor,
          type: "Vendor",
          page: "business profile",
          link: "/sales-hub/proposals",
        };
        const Object = {
          Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          type: "Proposal",
          section: null,
          context: "Vendor Added",
          page: "buyer proposal page",
        };
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
          Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          SignalCreatedAt: new Date().getTime() / 1000,
          Action: Action,
          Target: Target,
          Object: Object,
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
      if (QuestionAdded?.QuestionId && QuestionAdded?.QuestionId?.length) {
        const Action = SECRET_KEYS.CONFIG.QUESTION_EDITED;
        const Target = {
          Id: allVendorIds,
          type: "Vendor",
          page: "business profile",
          link: "/sales-hub/proposals",
        };
        const Object = {
          Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          type: "Proposal",
          [PROPOSAL_QUESTION_KEYS?.QUESTION_ID]: QuestionAdded?.QuestionId,
          section: null,
          context: "Question Edited",
          page: "buyer proposal page",
        };
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
          Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          SignalCreatedAt: new Date().getTime() / 1000,
          Action: Action,
          Target: Target,
          Object: Object,
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
      if (VendorAdded?.DeletedVendor && VendorAdded?.DeletedVendor?.length) {
        const Action = SECRET_KEYS.CONFIG.VENDOR_DELETED;
        const Target = {
          Id: VendorAdded?.DeletedVendor,
          type: "Vendor",
          page: "business profile",
          link: "/sales-hub/proposals",
        };
        const Object = {
          Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          type: "Proposal",
          section: null,
          context: "Vendor Deleted",
          page: "buyer proposal page",
        };
        const signalTrackingPostData = {
          Actor: authData?.data?.userId,
          Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
          Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
          SignalCreatedAt: new Date().getTime() / 1000,
          Action: Action,
          Target: Target,
          Object: Object,
        };
        SignalTracking?.handleTrigger(signalTrackingPostData);
      }
    }
  };
  const handleSaveClicked = (status) => {
    const sectionAndQuestionIndex = getIndexOfSectionsAndQuestions(proposalDetails);
    if (status == "Draft") {
      const DataToPost = {
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.PARTICIPANTS]: proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length,
        [PROPOSAL_KEYS?.STATUS]:
          proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft" && proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
            ? "In Progress"
            : "Draft",
      };
      setProductDetails(DataToPost);
      vendorPostData.handleTrigger({
        ...DataToPost,
        [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
          : null,
        [PROPOSAL_KEYS?.TARGET_DATE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
          ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
          : null,
      });
    } else {
      const DataToPost = {
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.PARTICIPANTS]: proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length,
        [PROPOSAL_KEYS?.STATUS]:
          proposalDetails?.[PROPOSAL_KEYS?.STATUS] && proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
            ? "Published"
            : "Published",
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
      };
      setProductDetails(DataToPost);
      const Validate2 = validateAddProposalPage3(proposalDetails);
      setCompanyErrors(Validate2);
      setTimeout(() => {
        setCompanyErrors({});
      }, 3000);
      if (!Object.keys(Validate2)?.length) {
        vendorPostData.handleTrigger({
          ...DataToPost,
          [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
          [PROPOSAL_KEYS?.TARGET_DATE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
        });
        triggerSignalTracking();
      }
    }
    if (PARAMS?.name) {
      dispatch(notificationTargetFn(null));
    }
  };
  return (
    <div className="company-profile-main-wrapper" style={{ background: "#F5F5F5" }}>
      <div className="company-details-header-main-wrapper-ref">
        <EditProposalHeader
          USER_CAN_EDIT={USER_CAN_EDIT}
          companyErrors={companyErrors}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          proposalPublished={
            proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID] && proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft"
              ? true
              : false
          }
          productPageLabel={proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE]}
          proposalDetails={proposalDetails}
          handleClose={handleCloseClicked}
          isLoading={vendorPostData?.isLoading}
          vendorPostData={vendorPostData}
          footerNumberClicked={footerNumberClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          handleSaveAnswerClicked={handleSaveClicked}
        />
      </div>
      <div className="edit-question-proposal-summary-main-wrapper">
        <ProposalSummary USER_CAN_EDIT={USER_CAN_EDIT} proposalDetails={proposalDetails} />
      </div>

      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}

      <div className="edit-question-proposal-details-main-container-ref">
        <EditProposalList
          USER_CAN_EDIT={USER_CAN_EDIT}
          notificationJson={notificationJson}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
          footerNumberClicked={footerNumberClicked}
          handleScreenToShow={handleScreenToShow}
        />
      </div>
    </div>
  );
};

export default EditQuestionProposal;
