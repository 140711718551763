import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useState } from "react";

const PostFilter = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleShareBlurbClicked = (val) => {
    setToggleMenu(!toggleMenu);
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };

  const SortFilterData = [
    {
      name: "Newest first",
      value: "Created_At",
      order: "desc",
    },
    {
      name: "Oldest First",
      value: "Created_At",
      order: "asc",
    },
    // {
    //   name: "Sort by date descending",
    //   value: "",
    //   order: "asc",
    // },
  ];
  return (
    <div style={{ width: "20%" }}>
      <div tabIndex={0} onBlur={handleBlur} className="engagement-bar-items p-r p-x-xs" style={{ justifyContent: "flex-end" }}>
        {/* <span className="engagement-view-more-text" onClick={() => handleShareBlurbClicked()}> */}
        <div className="flex items-center" role="button" onClick={() => handleShareBlurbClicked()}>
          <FeatherIcon icon={"menu"} size={20} fill={"#5e6366"} color={"#5e6366"} style={{ marginLeft: "20px" }} />
        </div>
        {/* </span> */}
        {toggleMenu && (
          <div className="view-more-container modern-card modern-card--elevated" style={{ top: "30px", bottom: "0px", minHeight: "99px" }}>
            {SortFilterData?.map((data) => {
              return (
                <div className="share-single-item">
                  <div
                    className="action-button-container"
                    onClick={() => [props?.handleSortPost(data?.value, data?.order), handleShareBlurbClicked()]}
                    style={{ width: "100%", padding: "5px" }}
                  >
                    <p className="share-desc-title">{data?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostFilter;
