import React from "react";
import { useSelector } from "react-redux";
import { useGetBlurbsApiQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import NewUseCaseCard from "../../../../utlis/hoc/newUseCaseCard/newUseCaseCard";
import UseCaseCardList from "../../../../utlis/hoc/useCaseCard/useCaseCardList";
import ProductSkeleton from "../../../searchResult/components/skeleton/productSkeleton";
import TabHeader from "./components/tabHeader";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScrollComp from "../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import * as NO_CONTENT from "../../../../config/config";

const UseCases = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const EDITED_USECASE = useSelector((state) => state?.homeDetails?.editUseCaseData);
  const [lazyLoadData, setLazyLoadData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [noResultsData, setNoResultsData] = useState(false);
  const USER_ID = authData?.data?.userId;
  const VENDOR_ID = props?.VENDOR_ID;
  const cardType = "use-case";
  const permissionsDetails = props?.permissionsDetails;
  const GetApiData = useEnCryptGetApi(
    `/getAllUseCaseBlurbs?vendorId=${VENDOR_ID}&limit=30&userId=${USER_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetBlurbsApiQuery
  );

  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      if (lazyLoadData?.length >= 20) {
        setLazyLoadData((prev) => prev.concat(GetApiData?.data?.data));
      } else {
        setLazyLoadData(GetApiData?.data?.data);
      }
    } else if (typeof GetApiData?.data?.data === "object" && GetApiData?.data?.data?.length === 0) {
      setNoResultsData(true);
    }
  }, [GetApiData?.data?.data]);

  const fetchData = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    if (typeof EDITED_USECASE === "object" && Object.keys(EDITED_USECASE)?.length > 1) {
      const BLURB_INDEX = lazyLoadData?.findIndex((item) => item?.blurbID === EDITED_USECASE?.blurbID);
      let tempData = [...lazyLoadData];
      if (EDITED_USECASE?.status == "Delete") {
        tempData.splice(1, tempData[BLURB_INDEX]);
      } else {
        tempData[BLURB_INDEX] = EDITED_USECASE;
      }
      setLazyLoadData(tempData);
    }
  }, [EDITED_USECASE]);

  return (
    <div className="provider_profile_products-list grid-gap-md one-column-grid">
      <TabHeader
        permissionsDetails={permissionsDetails}
        dataLength={GetApiData?.data?.data?.length}
        tabIndex={props?.tabIndex}
        type={props?.type}
      />
      <div className="one-column-grid">
        {GetApiData?.isLoading || GetApiData?.isFetching ? (
          <ProductSkeleton page={props?.page} />
        ) : lazyLoadData?.length > 0 ? (
          <InfiniteScrollComp
            Data={lazyLoadData}
            hasMoreData={GetApiData?.data?.data?.length >= 30}
            fetchData={fetchData}
          >
            <NewUseCaseCard
              permissionsDetails={permissionsDetails}
              cardType={cardType}
              page={props?.page}
              data={lazyLoadData}
              parent={props?.page}
            />
          </InfiniteScrollComp>
        ) : noResultsData ? (
          <div className="search__result data-product-card no-content-card">
            <div className="no-content-text">
              {props?.type === "buyer"
                ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_USE_CASE
                : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_USE_CASE}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UseCases;
