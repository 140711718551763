import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAddItemsToBoardMutation, useAddItemsToBoardPutMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../customHooks/customHooks";
import { GetIdFormLink } from "../../../hof/hof";

const MoreOptionsList = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const USER_ID = authData?.data?.userId;
  const keyName = props?.keyName;
  const data = props?.data;
  const ACTIONS =
    props?.cardType == "blurb"
      ? "blurb"
      : props?.cardType == "product"
      ? "product"
      : props?.cardType == "usecase"
      ? "use case"
      : props?.cardType == "vendor"
      ? "vendor"
      : "";
  const RemoveItemsBoard = useEnCryptPostApi(
    `/board/removeItemFromBoard`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useAddItemsToBoardPutMutation
  );
  const handleRemoveItemsFromBoard = () => {
    const BOARDS_PARAMS_DETAILS = GetIdFormLink(PARAMS?.name?.replaceAll("-", " "));
    const BOARD_ID = BOARDS_PARAMS_DETAILS.Id;
    const BOARD_NAME = BOARDS_PARAMS_DETAILS.Name;
    RemoveItemsBoard.handleTrigger({
      Item_ID: data?.[keyName],
      Item_Type: ACTIONS,
      Board_ID: BOARD_ID,
      Updated_At: parseInt(new Date().getTime() / 1000),
    });

    setTimeout(() => {
      props?.handleToggleMenu(false);
    }, 2000);
  };

  const handleOptions = (val) => {
    props?.handleMenuOptions("feedback");
  };
  return (
    <>
      <div className="share-single-item">
        {props?.page == "board detail page" && USER_ID === props?.boardUserId ? (
          <div
            className="action-button-container"
            onClick={() => handleRemoveItemsFromBoard("remove-from-board")}
            style={{ width: "100%", padding: "5px" }}
          >
            {RemoveItemsBoard?.isLoading ? (
              <p className="share-desc-title">loading...</p>
            ) : (
              <p className="share-desc-title">{`Remove ${ACTIONS} from board`}</p>
            )}
          </div>
        ) : null}
        <div className="action-button-container" style={{ width: "100%" }}>
          <p className="share-desc-title" onClick={() => handleOptions("feedback")}>
            Feedback
          </p>
        </div>
      </div>
    </>
  );
};

export default MoreOptionsList;
