import React, { useState } from "react";
import PortalDiv from "../../../../../businessProfile/components/tabs/components/portalDiv";
import { useEnCryptPostApi } from "../../../../../../utlis/customHooks/customHooks";
import { useSelector } from "react-redux";
import InputFieldProfile from "../../../../../profile/editProfile/component/inputFieldProfile";
import TextArea from "../../../../../../utlis/hoc/textArea/textArea";
import { validateProductExpertCreatePost } from "../../../../../../utlis/hof/hof";
import { useEffect } from "react";
import { useProductExpCreateNewPostMutation } from "../../../../../../adapters/xhr/generalApi";
import _ from "lodash";
import LoadingSpinner from "../../../../../../utlis/spinner/loadingSpinner";

const NewPost = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [error, setError] = useState({});
  const [formDetails, setFormDetails] = useState({});

  useEffect(() => {
    if (props?.data) {
      setFormDetails(props?.data);
    } else if (props?.Link) {
      setFormDetails({ Link: props?.link });
    }
  }, [props?.link, props?.data]);

  const handleCloseModal = () => {
    props?.handleToggleModal();
  };

  const ProductExpPost = useEnCryptPostApi(
    `/createLinkedInPost`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useProductExpCreateNewPostMutation
  );
  const ProductExpDraft = useEnCryptPostApi(
    `/addLinkedInDraftPost`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useProductExpCreateNewPostMutation
  );

  useEffect(() => {
    if (ProductExpPost?.data?.status || ProductExpDraft?.data?.status) {
      setTimeout(() => {
        handleCloseModal();
      }, 3000);
    }
  }, [ProductExpPost?.data, ProductExpDraft?.data]);

  const handleNewPost = (status) => {
    const VALIDATE = validateProductExpertCreatePost(formDetails);
    setError(VALIDATE);

    setTimeout(() => {
      setError({});
    }, 3000);

    if (_.isEmpty(VALIDATE)) {
      const RAW_DATA = { User_ID: authData?.data?.userId, Post_Details: { ...formDetails, Status: status } };
      if (status === "Draft") ProductExpDraft?.handleTrigger(RAW_DATA);
      else ProductExpPost?.handleTrigger(RAW_DATA);
    }
  };

  return (
    <PortalDiv>
      <div className="add-to-board-modal portalModal">
        <div className="add-to-board-container--main-wrapper" style={{ width: "50%", minHeight: "auto" }}>
          {ProductExpPost?.data || ProductExpDraft?.data ? (
            <div
              style={{
                background: ProductExpPost?.data?.status || ProductExpDraft?.data?.status ? "#008000" : "#c00",
              }}
              className="success-message-pop-up"
            >
              <h3 className="success-message-style-board">{ProductExpPost?.data?.message || ProductExpDraft?.data?.message}</h3>
            </div>
          ) : null}

          <div className="add-to-board-container-header-wrapper">
            <div className="close-icon">
              <a className="slide-in__close-btn closeButton" onClick={() => handleCloseModal()}>
                <i className="feather icon icon-x m-none"></i>
              </a>
            </div>
            <h1 className="add-to-board-header-text">Add new post</h1>
          </div>
          <div className="request-category-container">
            <div class="field p-r m-y-sm m-b-lg" id="field-user__phone_number_prefix">
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                {"Post Name"}
              </label>
              <InputFieldProfile
                label={"Post Name"}
                value={formDetails?.Post_Name}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Post_Name: val }))}
                error={error?.Post_Name}
                removeBottomPadding={true}
                // infoText={true}
                required={true}
              />
            </div>
            <div class="field p-r m-t-md" id="field-user__phone_number_prefix">
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                {"Description"}
              </label>
              <TextArea
                value={formDetails?.Description}
                className="m-b-sm"
                label={"Add your thoughts and tags"}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Description: val }))}
                subTitleTop={"Please add at least 50 characters to describe your requirement."}
                error={error?.Description}
                // removeMargin={true}
                required={true}
              />
            </div>
            <div class="field p-r m-b-md" id="field-user__phone_number_prefix">
              <label for="user__email" className="company-details-label-input-field-filled-absolute">
                {"Link"}
              </label>
              <InputFieldProfile
                label={"Add content link from octate"}
                value={formDetails?.Link}
                changeText={(val) => setFormDetails((prev) => ({ ...prev, Link: val }))}
                error={error?.Link}
                removeBottomPadding={true}
                // infoText={true}
                required={true}
              />
            </div>
            {/* <LinkPreview fallback={<h1>No meta found</h1>} url='https://www.youtube.com/watch?v=dQw4w9WgXcQ' width='400px' /> */}

            <div className="flex justify-flex-end">
              <button className="product-exp-save-draft-btn" onClick={() => handleNewPost("Draft")}>
                Save Draft
              </button>
              {ProductExpPost?.isLoading ? (
                <button className="product-exp-submit-btn" onClick={() => handleNewPost("Live")}>
                  <LoadingSpinner />
                </button>
              ) : (
                <button className="product-exp-submit-btn" onClick={() => handleNewPost("Live")}>
                  Publish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PortalDiv>
  );
};

export default NewPost;
