import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import SingleSelectNewDropdown from "../../../utlis/hoc/singleSelectDropdown/singleSelectNewDropdown";
import InputFieldProfile from "../../profile/editProfile/component/inputFieldProfile";
import CompanyAddLocations from "./companyAddLocations";

const CompanyCommunicationSetup = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const error = props?.companyErrors;
  const COMPANY_PROFILE = KEYS?.COMPANY_PROFILE;
  //   const companyDetails = props?.data;
  const companyDetails = props?.companyDetails;
  const GetCountryData = useEnCryptGetApi(
    `/s3Processor/getCountryList`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const handleLocationDetails = (val) => {
    props?.handleDetails({ ...companyDetails, Location: val });
  };
  const DropDownData = GetCountryData?.data?.data?.allCountries;
  return (
    <div style={{ width: "100%" }}>
      <div className="company-profile-communication-setup-container">
        <div>
          <h5 class="company-header-sub-title">
            {COMPANY_PROFILE?.BASIC_INFORMATION_LABEL}
          </h5>
        </div>
        <div
          class="field p-r  m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          {/* <label for="user__email">{PROFILE_DETAILS?.FIRST_NAME}</label> */}
          {companyDetails?.Vendor_HQ_Phone ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.COMPANY_PHONE_NUMBER}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.COMPANY_PHONE_NUMBER_LABEL}
            value={companyDetails?.Vendor_HQ_Phone}
            changeText={(val) =>
              props?.handleDetails({
                ...companyDetails,
                Vendor_HQ_Phone: val?.replace(/[^0-9]/g, ""),
              })
            }
            error={error?.Vendor_HQ_Phone}
          />
        </div>
        {/* <div
          class="field p-r  m-y-company-profile-input-wrapper"
          id="field-user__email"
        >
          {companyDetails?.Vendor_HQ_Country ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.HEADQUARTERS}
            </label>
          ) : null}
          <InputFieldProfile
            label={COMPANY_PROFILE?.HEADQUARTERS_LABEL}
            value={companyDetails?.Vendor_HQ_Country}
            changeText={(val) =>
              props?.handleDetails({
                ...companyDetails,
                Vendor_HQ_Country: val,
              })
            }
            error={error?.Vendor_HQ_Country}
          />
        </div> */}
        <div class="p-relative-company" id="field-user__country_id">
          {companyDetails?.Vendor_HQ_Country ? (
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {COMPANY_PROFILE?.HEADQUARTERS}
            </label>
          ) : null}

          <SingleSelectNewDropdown
            handleSelectedData={(val) =>
              props?.handleDetails({
                ...companyDetails,
                Vendor_HQ_Country: val,
              })
            }
            data={DropDownData}
            placeholder={COMPANY_PROFILE?.HEADQUARTERS_LABEL}
            flag={true}
            selectedVal={companyDetails?.Vendor_HQ_Country}
            name={"name"}
          />
          {/* {companyDetails?.Vendor_HQ_Country ? (
            <span class="input-field-text-error">
              {companyDetails?.Vendor_HQ_Country}
            </span>
          ) : null} */}
        </div>
      </div>
      <CompanyAddLocations
        locationDetailsList={companyDetails?.Location}
        handleLocationDetails={handleLocationDetails}
      />
    </div>
  );
};

export default CompanyCommunicationSetup;
