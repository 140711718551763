import React, { useState } from "react";
import FavoriteVendorList from "./favoriteVendorList";
import RecommendedVendorList from "./recommendedVendorList";
import * as CONFIG_KEYS from "../../../../../config/config";
const RecommendedVendors = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const proposalDetails = props?.proposalDetails;
  const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const handleTabClicked = (val) => {
    setActiveTab(val);
  };
  const handleVendorDetails = (val) => {
    props?.handleDetails({
      ...proposalDetails,
      [ADD_PRODUCT_CONFIG_KEYS?.VENDORS]: val,
    });
  };

  return (
    <div className="proposal-submit-recommended-vendor-main-wrapper">
      <div className="proposal-submit-recommended-vendor-tab-wrapper">
        <div
          onClick={() => handleTabClicked(1)}
          className={`${
            activeTab == 1 ? "tab-width vendor-tab-text--active" : "tab-width"
          }`}
        >
          <h3 className="proposal-submit-recommended-vendor-tab-text ">
            Recommended Vendors
          </h3>
        </div>
        <div
          onClick={() => handleTabClicked(2)}
          className={`${
            activeTab == 2 ? " tab-width vendor-tab-text--active" : "tab-width"
          }`}
        >
          <h3 className="proposal-submit-recommended-vendor-tab-text">
            Search Vendors
          </h3>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {activeTab == 1 ? (
          <RecommendedVendorList
            proposalDetails={proposalDetails}
            handleVendorDetails={handleVendorDetails}
          />
        ) : (
          <FavoriteVendorList
            proposalDetails={proposalDetails}
            handleVendorDetails={handleVendorDetails}
            handleDetails={props?.handleDetails}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendedVendors;
