import React, { useState } from "react";
import { useSelector } from "react-redux";
import LinkedInHighlights from "./linkedInHighlights";
import LinkedInGraph from "./linkedInGraph";
import AnalyticsPostList from "./analyticsPostList";

const LinkedInAnalytics = () => {
  const [selectedPost, setSelectedPost] = useState({});
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);

  return (
    <>
      <div className="flex flex-column">
        <LinkedInHighlights />
        <LinkedInGraph selectedPost={selectedPost} />
      </div>
      <AnalyticsPostList handleSelectPost={(val) => setSelectedPost(val)} />
    </>
  );
};

export default LinkedInAnalytics;
