import React from "react";
import BusinessDetails from "../../businessProfile/components/businessOverview/businessDetails";
import EditProposal from "./editProposal";
import PreviewProposal from "./previewProposal";
import * as CONFIG_KEYS from "../../../config/config";
import ProposalOverview from "../../businessProfile/components/proposalOverView/proposalOverview";
const ProposalList = (props) => {
  const screenToShow = props?.footerNumberClicked;
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const notificationJson = props?.notificationJson;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {screenToShow == 1 ? (
        <div className="answer-proposal-grid-business-profile-reverse m-x-mini m-t-sm mobile-1-col">
          <div
            style={{
              background: "#ffff",
              borderRadius: "15px",
            }}
          >
            <PreviewProposal
              //   validateEmailId={props?.validateEmailId}
              PROPOSAL_TYPE={PROPOSAL_TYPE}
              StaticDropdownData={StaticDropdownData}
              companyErrors={props?.companyErrors}
              handleDetails={handleDetails}
              proposalDetails={proposalDetails}
            />
          </div>
          <div
            className="mobile-hidden"
            style={{ alignItems: "start", textAlign: "start" }}
          >
            <ProposalOverview
              PROPOSAL_TYPE={PROPOSAL_TYPE}
              type={props?.type}
              activeRowProposal={proposalDetails}
            />
          </div>
        </div>
      ) : (
        <EditProposal
          notificationJson={notificationJson}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
        />
      )}
    </div>
  );
};

export default ProposalList;

{
  /* screenToShow == 2 ? (
        <AddFeature
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : (
        <PlanAndPricing
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) */
}
