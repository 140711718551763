import React from "react";
import BasicUseCaseDetails from "./basicUseCaseDetails";

const UseCaseDetailList = (props) => {
  const screenToShow = props?.footerNumberClicked;
  const productDetails = props?.productDetails;
  const handleDetails = (val) => {
    props?.handleDetails(val);
  };
  const StaticDropdownData = props?.StaticDropdownData;
  return (
    <div className="company-profile-details-main-container">
      {screenToShow == 1 ? (
        <BasicUseCaseDetails
          handleImageFile={props?.handleImageFile}
          validateEmailId={props?.validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={props?.companyErrors}
          handleDetails={handleDetails}
          productDetails={productDetails}
        />
      ) : null}
    </div>
  );
};

export default UseCaseDetailList;
