import React from "react";
import * as SECRET_KEY from "../../../../config/config";
import PreviewVendorList from "./previewVendorList";
const PreviewVendors = (props) => {
  const ADD_PROPOSAL_KEYS = SECRET_KEY?.CONFIG?.ADD_PROPOSAL;
  const proposalDetails = props?.proposalDetails;
  const vendorList = proposalDetails?.[ADD_PROPOSAL_KEYS?.VENDORS]?.length
    ? proposalDetails?.[ADD_PROPOSAL_KEYS?.VENDORS]
    : [];

  return (
    <div className="proposal-submit-timeline-and-vendors-content-add-vendor-main-wrapper">
      <h3 className="proposal-submit-timeline-and-vendors-content-title-submit">Select Vendors to Submit</h3>
      <div className="proposal-submit-selected-v-recommended-v-main-wrapper">
        <PreviewVendorList proposalDetails={proposalDetails} selectedVendorWithFilter={vendorList} />
      </div>
    </div>
  );
};

export default PreviewVendors;
