import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAddProposalApiMutation, useGetRequestQuery, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useEnCryptSignalTrackingApi } from "../../utlis/customHooks/customHooks";
import {
  getIndexOfSectionsAndQuestions,
  validateAddProposalPage1,
  validateAddProposalPage2,
  validateAddProposalPage3,
} from "../../utlis/hof/hof";
import ProposalDetailList from "./component/proposalDetailList";
import * as ADD_PROPOSAL_KEYS from "../../config/config";
import * as SECRET_KEYS from "../../config/configKeys";
import ProposalDetailsFooter from "./component/proposalDetailsFooter";
import ProposalHeader from "./component/proposalHeader";
const AddProposal = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const [companyErrors, setCompanyErrors] = useState({});
  const [proposalDetails, setProductDetails] = useState({
    Business_Needs: [],
    Vendors: [],
    Proposal_Question: [],
    Can_Edit: true,
  });
  const vendorDetails = props?.vendorDetails;
  const PROPOSAL_KEYS = ADD_PROPOSAL_KEYS?.CONFIG?.ADD_PROPOSAL;
  const authData = useSelector((state) => state?.authDetails?.details);
  const editProduct = props?.editProduct;
  // https://thebox-dev.portqii.com/core/vendor/productOnboardingGetProduct?Product_ID=17258&User_ID=546
  const GetProductDataFromApi = useEnCryptGetApi(
    `/getProposal/${props?.Id}`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useGetRequestQuery
  );
  const SignalTracking = useEnCryptSignalTrackingApi(`/signalTrack`, "notifications", usePostRequestMutation);
  useEffect(() => {
    if (vendorDetails?.[PROPOSAL_KEYS?.VENDOR_ID]) {
      setProductDetails({
        ...proposalDetails,
        [PROPOSAL_KEYS?.VENDORS]: [vendorDetails],
      });
    }
  }, [vendorDetails]);
  useEffect(() => {
    if (GetProductDataFromApi?.data?.data) {
      if (editProduct) {
        setProductDetails({
          ...GetProductDataFromApi?.data?.data,
          [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(GetProductDataFromApi?.data?.data?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE] * 1000)
            : null,
        });
      }
    }
  }, [GetProductDataFromApi?.data]);
  const ADD_PRODUCT = KEYS?.ADD_PRODUCT;
  const footerList = [
    {
      name: "Proposal Template",
      id: 1,
    },
    {
      name: "Proposal Brief",
      id: 2,
    },
    // {
    //   name: "Template Section",
    //   id: 2,
    // },
    {
      name: "Edit Questionnaire",
      id: 2,
    },
    {
      name: "Preview & Submit",
      id: 2,
    },
  ];
  const validateEmailId = authData?.data?.emailId
    ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
    : null;
  const USER_CAN_EDIT = true;
  // https://thebox-dev.portqii.com/proposals/addProposal
  const vendorPostData = useEnCryptPostApi(
    `/addProposal`,
    KEYS?.END_POINTS?.PROPOSALS?.END_POINT_URL,
    useAddProposalApiMutation
  );
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  useEffect(() => {
    if (vendorPostData?.data) {
      setUpdateStatus(true);
      props?.handleClose(false);
    }
  }, [vendorPostData?.data]);
  // https://thebox-dev.portqii.com/core/vendor/getProductConfig?userId=475
  const StaticData = useEnCryptGetApi(
    `/vendor/getProductConfig?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data;
  const handleFooterNumberClicked = (val) => {
    if (val <= 2) {
      setFooterNumberClicked(val);
    } else if (val <= 3) {
      const Validate1 = validateAddProposalPage1(proposalDetails);
      setCompanyErrors(Validate1);
      setTimeout(() => {
        setCompanyErrors({});
      }, 3000);
      if (!Object.keys(Validate1)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 4) {
      const Validate2 = validateAddProposalPage2(proposalDetails);
      setCompanyErrors(Validate2);
      setTimeout(() => {
        setCompanyErrors({});
      }, 3000);
      if (!Object.keys(Validate2)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 5) {
      setFooterNumberClicked(val);
    } else {
      return;
    }
  };
  const handleScreenToShow = (val) => {
    setFooterNumberClicked(val);
  };
  const handleDetails = (val) => {
    setProductDetails(val);
  };
  const triggerSignalTracking = () => {
    // SECRET_KEYS
    const Action = SECRET_KEYS.CONFIG.BUYER_PUBLISHED;
    const allVendorIds = proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length
      ? proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.map((list) => {
          return list?.[PROPOSAL_KEYS?.VENDOR_ID];
        })
      : [];
    const Target = {
      Id: allVendorIds,
      type: "Vendor",
      page: "business profile",
      link: "/sales-hub/proposals",
    };
    const Object = {
      Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
        ? proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
        : proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
      type: "Proposal",
      section: null,
      context: "Proposal Published",
      page: "buyer proposal page",
    };
    const signalTrackingPostData = {
      Actor: authData?.data?.userId,
      SignalCreatedAt: new Date().getTime() / 1000,
      Item_Type: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TITLE],
      Item_Id: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID],
      Action: Action,
      Target: Target,
      Object: Object,
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  const handleFooterNumberFromTemplateClicked = (sec, content) => {
    setFooterNumberClicked(sec);
    if (content) {
      setProductDetails(content);
    } else {
      setProductDetails({});
    }
  };
  const handleSaveClicked = (status) => {
    const sectionAndQuestionIndex = getIndexOfSectionsAndQuestions(proposalDetails);
    if (status == "Draft") {
      const DataToPost = {
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.PARTICIPANTS]: proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length,
        [PROPOSAL_KEYS?.STATUS]:
          proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft" && proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
            ? "In Progress"
            : "Draft",
      };
      setProductDetails(DataToPost);
      const Validate2 = validateAddProposalPage1(proposalDetails);
      setCompanyErrors(Validate2);
      if (!Object.keys(Validate2)?.length) {
        vendorPostData.handleTrigger({
          ...DataToPost,
          [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
          [PROPOSAL_KEYS?.TARGET_DATE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
        });
      }
    } else {
      const DataToPost = {
        ...proposalDetails,
        userId: authData?.data?.userId,
        [PROPOSAL_KEYS?.CREATED_BY]: proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          ? proposalDetails?.[PROPOSAL_KEYS?.CREATED_BY]
          : authData?.data?.userId,
        [PROPOSAL_KEYS?.UPDATE_BY]: authData?.data?.userId,
        [PROPOSAL_KEYS?.PROPOSAL_QUESTION]: sectionAndQuestionIndex,
        [PROPOSAL_KEYS?.PARTICIPANTS]: proposalDetails?.[PROPOSAL_KEYS?.VENDORS]?.length,
        [PROPOSAL_KEYS?.STATUS]:
          proposalDetails?.[PROPOSAL_KEYS?.STATUS] && proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID]
            ? "Published"
            : "Published",
      };
      setProductDetails(DataToPost);
      const Validate2 = validateAddProposalPage3(proposalDetails);
      setCompanyErrors(Validate2);
      if (!Object.keys(Validate2)?.length) {
        vendorPostData.handleTrigger({
          ...DataToPost,
          [PROPOSAL_KEYS?.PROPOSAL_TIMELINE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
          [PROPOSAL_KEYS?.TARGET_DATE]: proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE]
            ? new Date(proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_TIMELINE])?.getTime() / 1000
            : null,
        });
      }
      if (proposalDetails?.[PROPOSAL_KEYS?.STATUS] == "Draft" || !proposalDetails?.[PROPOSAL_KEYS?.STATUS]) {
        triggerSignalTracking();
      }
    }
  };
  return (
    <div className="company-profile-main-wrapper">
      <div className="company-details-header-main-wrapper-ref">
        <ProposalHeader
          USER_CAN_EDIT={USER_CAN_EDIT}
          productPageLabel={"New Proposal"}
          handleClose={props?.handleClose}
          proposalPublished={
            proposalDetails?.[PROPOSAL_KEYS?.PROPOSAL_ID] && proposalDetails?.[PROPOSAL_KEYS?.STATUS] !== "Draft"
              ? true
              : false
          }
          handleSaveClicked={handleSaveClicked}
          vendorPostData={vendorPostData}
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="company-profile-details-main-container-ref">
        <ProposalDetailList
          handleFooterNumberFromTemplateClicked={handleFooterNumberFromTemplateClicked}
          USER_CAN_EDIT={USER_CAN_EDIT}
          //   productStaticData={ADD_PRODUCT}
          //   validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          proposalDetails={proposalDetails}
          footerNumberClicked={footerNumberClicked}
          handleScreenToShow={handleScreenToShow}
        />
      </div>
      <div className="company-details-footer-main-wrapper">
        <ProposalDetailsFooter
          USER_CAN_EDIT={USER_CAN_EDIT}
          // productStaticData={ADD_PRODUCT}
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={vendorPostData}
          proposalDetails={proposalDetails}
        />
      </div>
    </div>
  );
};

export default AddProposal;
