import React from "react";
import FeatherIcon from "feather-icons-react";
const OctateIconDesktop = () => {
  return (
    <a className="navbar__item navbar__brand" href="/">
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          alignSelf: "center",
        }}
        className="navbar__item-content"
      >
        <img
          className="hide-logo-laptop"
          width="30px"
          height="30px"
          // style={{ marginRight: "8px" }}
          src={require("../../../../../assets/images/OctateIcon.ico")}
          alt={"octate.ai logo"}
        ></img>

        <img
          className="laptop-hidden"
          width="150px"
          height="25px"
          style={{ alignSelf: "center" }}
          data-src={require("../../../../../assets/images/octateActiveLogo.png")}
          src={require("../../../../../assets/images/octateActiveLogo.png")}
          alt={"octate.ai logo"}
        ></img>
      </div>
    </a>
  );
};
export default OctateIconDesktop;
