import React from "react";
import "../../styles/spinner.css";

const LoadingSpinner = (props) => {
  const size = props?.size;
  return (
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      {size == "small" ? null : (
        <>
          <div></div>
          <div></div>
        </>
      )}
    </div>
  );
};

export default LoadingSpinner;
