import React, { useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../config/configKeys";
import { useEnCryptPostApi } from "../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen } from "../../utlis/hof/hof";
import ShareDetailList from "./shareBoardList";
function ShareBoard(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const [toggleMenu, setToggleMenu] = useState(false);
  const USER_ID = authData?.data?.userId;
  const itemData = props?.data;
  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.SHARE_CLICKED,
    page: props?.page,
    section: "",
  };
  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const shareSignalTracking = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: "boards",
      Item_Id: itemData?.Board_ID,
      Target: {
        page: "Board detail page",
        link: `/board/${ReplaceBlankSpaceByHyphen(itemData?.Name, itemData?.Board_ID)}`,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: "board_shared",
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };
  function handleShareBoardClicked() {
    setToggleMenu(!toggleMenu);
  }
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setToggleMenu(false);
    }
  };
  const handleToggleMenu = (val) => {
    setToggleMenu(val);
  };
  return (
    <div tabIndex={0} onBlur={handleBlur} className="p-r">
      <FeatherIcon
        icon={"share"}
        size={20}
        // className={"board-item-trash-icon"}
        role="button"
        onClick={() => handleShareBoardClicked(itemData)}
        color={"#080808"}
      />
      {toggleMenu && (
        <div className="share-container-board modern-card modern-card--elevated">
          <ShareDetailList
            shareSignalTracking={shareSignalTracking}
            shareLink={props?.shareLink}
            cardType={props?.cardType}
            handleToggleMenu={handleToggleMenu}
            data={itemData}
          />
        </div>
      )}
    </div>
  );
}

export default ShareBoard;
{
  /* <FeatherIcon
          icon={"share"}
          size={20}
          className={"board-item-trash-icon"}
          role="button"
          // onClick={() => handleEditBoard(item)}
          color={"#080808"}
        /> */
}
