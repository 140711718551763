import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link, useParams } from "react-router-dom";
import { ReplaceBlankSpaceByHyphen } from "../../hof/hof";
import { useSelector } from "react-redux";
import { useEnCryptSignalTrackingApi } from "../../customHooks/customHooks";
import { usePostRequestMutation } from "../../../adapters/xhr/generalApi";
import * as SECRET_KEYS from "../../../config/configKeys";
const ProviderCardInfo = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const DATA = props?.data;
  const PARAMS = useParams();
  const SEARCH_TEXT = PARAMS?.name?.replaceAll("-", " ");

  const signalTrackingObject = {
    Action: SECRET_KEYS.CONFIG.FORMOGRAPICS_CLICKED,
    page: props?.page,
    section: props?.section,
  };
  const SignalTracking = useEnCryptSignalTrackingApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleFirmographicsCLicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingObject?.Action,
      Item_Type: signalTrackingObject?.page,
      Item_Id: DATA?.Vendor_ID,
      Target: {
        page: signalTrackingObject?.page,
        link: val,
      },
      Object: {
        section: signalTrackingObject?.section,
        context: SEARCH_TEXT,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return DATA?.[props?.keyName] ? (
    props?.name === "Products" ? (
      <a href={`/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${props?.navigate}`}>
        <div
          className="flex-container center-container additional-info-vendor-page"
          role="button"
          onClick={() =>
            handleFirmographicsCLicked(
              `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${props?.navigate}`
            )
          }
        >
          {/* {props?.icon && (
          <FeatherIcon
            icon={props?.icon}
            size="20"
            style={{ marginRight: "10px" }}
            color={"#8B8D97"}

            // className="blurb-detail-social"
          />
        )} */}
          {
            <div data-tooltip={props?.name} data-inverted="true">
              <img
                src={
                  props?.name === "Employees"
                    ? require("../../../assets/cardIcons/vendorCardIcons/1.png")
                    : props?.name === "Products"
                    ? require("../../../assets/cardIcons/vendorCardIcons/2.png")
                    : props?.name === "Revenue"
                    ? require("../../../assets/cardIcons/vendorCardIcons/3.png")
                    : props?.name === "Founded"
                    ? require("../../../assets/cardIcons/vendorCardIcons/4.png")
                    : require("../../../assets/cardIcons/vendorCardIcons/1.png")
                }
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
              />
            </div>
          }
          <div className="small-card-info-text">
            {props?.length ? DATA?.[props?.keyName]?.length : DATA?.[props?.keyName]}
          </div>
        </div>
      </a>
    ) : (
      <a>
        <div
          className="additional-info-vendor-page"
          onClick={() =>
            handleFirmographicsCLicked(
              `/vendor/${ReplaceBlankSpaceByHyphen(DATA?.Vendor_Name, DATA?.Vendor_ID)}/${props?.navigate}`
            )
          }
        >
          {/* {props?.icon && (
          <FeatherIcon
            icon={props?.icon}
            size="20"
            style={{ marginRight: "10px" }}
            color={"#8B8D97"}

            // className="blurb-detail-social"
          />
        )} */}
          {
            <div data-tooltip={props?.name} data-inverted="true">
              <img
                src={
                  props?.name === "Employees"
                    ? require("../../../assets/cardIcons/vendorCardIcons/1.png")
                    : props?.name === "Products"
                    ? require("../../../assets/cardIcons/vendorCardIcons/2.png")
                    : props?.name === "Revenue"
                    ? require("../../../assets/cardIcons/vendorCardIcons/3.png")
                    : props?.name === "Founded"
                    ? require("../../../assets/cardIcons/vendorCardIcons/4.png")
                    : require("../../../assets/cardIcons/vendorCardIcons/1.png")
                }
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
              />
            </div>
          }
          <div className="small-card-info-text">
            {props?.length ? DATA?.[props?.keyName]?.length : DATA?.[props?.keyName]}
          </div>
        </div>
      </a>
    )
  ) : null;
};

export default ProviderCardInfo;
