import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
const InfiniteScrollComp = (props) => {
  const Data = props?.Data;
  const hasMoreData = props?.hasMoreData;
  //  > 10 ? Data?.length - 4 : Data?.length
  return (
    <InfiniteScroll
      dataLength={Data?.length > 10 ? Data?.length - 4 : Data?.length} //This is important field to render the next data
      next={props?.fetchData}
      hasMore={hasMoreData}
      // inverse={loadInverse}
      style={{ overflow: "visible" }}
      // loader={<h4>Loading...</h4>}
      // endMessage={
      //   <p style={{ textAlign: "center" }}>
      //     <h1 className="infinite-scroll-completed-text">You're All Caught Up</h1>
      //   </p>
      // }
      // below props only if you need pull down functionality
      // refreshFunction={this.refresh}
      // pullDownToRefresh
      // pullDownToRefreshThreshold={50}
      // pullDownToRefreshContent={
      //   <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
      // }
      // releaseToRefreshContent={
      //   <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
      // }
    >
      {props?.children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollComp;
