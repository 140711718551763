import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { usePostRequestCrudMutation, usePutRequestCrudMutation } from "../../adapters/xhr/crudServiceApi";
import { useGetRequestQuery, usePostRequestImageMutation, usePostRequestMutation } from "../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi, useImageUploadApi } from "../../utlis/customHooks/customHooks";
import { useLocalStorage } from "../../utlis/customHooks/useLocalStorage";
import {
  GetIdFormLink,
  ValidateCompanyProfileCustomer,
  validateCompanyProfilePage1,
  validateCompanyProfilePage2,
  validateCompanyProfilePage3,
} from "../../utlis/hof/hof";
import CompanyDetailsList from "./component/comapnyDetailsList";
import CompanyDetailsFooter from "./component/companyDetailsFooter";
import CompanyDetailsHeader from "./component/companyDetailsHeader";

const CompanyProfile = (props) => {
  const superAdminStatus = useSelector((state) => state?.authDetails?.superAdminStatus);
  const permissionsDetails = useSelector((state) => state?.permissionsDetails?.allPermissions);
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const [footerNumberClicked, setFooterNumberClicked] = useState(1);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const navigate = useNavigate();
  const [companyErrors, setCompanyErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const formData = new FormData();
  const [companyDetails, setCompanyDetails] = useState({});
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();
  const VENDOR_ID = PARAMS?.name ? GetIdFormLink(PARAMS?.name?.replaceAll("-", " "))?.Id : authData?.data?.vendorId;
  const profileImageUpload = useImageUploadApi(usePostRequestImageMutation);

  const footerList = [
    {
      name: "Organization Details",
      id: 1,
    },
    {
      name: "Communication Setup",
      id: 2,
    },
    {
      name: "Customers",
      id: 3,
    },
    // {
    //   name: "Setup Features",
    //   id: 3,
    // },
  ];
  // const validateEmailId = authData?.data?.emailId
  //   ? authData?.data?.emailId?.slice(authData?.data?.emailId.indexOf("@") + 1)
  //   : null;
  const validateEmailId = props?.domainUrl ? props?.domainUrl : null;
  const runVendorEndpoint = validateEmailId ? true : false;
  const GetApiData = useEnCryptGetApi(
    `/getVendorDetailsOnVendorId?vendorId=${VENDOR_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery,
    runVendorEndpoint
  );
  useEffect(() => {
    if (GetApiData?.data?.data?.Vendor_ID) setCompanyDetails(GetApiData?.data?.data);
  }, [GetApiData?.data?.data]);
  const CheckIfUpdate = companyDetails?.Vendor_ID ? true : false;
  const RequestMethod = CheckIfUpdate ? usePutRequestCrudMutation : usePostRequestCrudMutation;
  const RequestApi = CheckIfUpdate ? `/vendor/vendorOnboardingUpdate` : `/vendor/vendorOnboarding`;
  // https://thebox-dev.portqii.com/core/vendor/vendorOnboarding
  const vendorPostData = useEnCryptPostApi(RequestApi, KEYS?.END_POINTS?.CORE?.END_POINT_URL, RequestMethod);
  useEffect(() => {
    if (vendorPostData?.data?.status) {
      setUpdateStatus(true);
      // setUser({ ...authData?.data, Persona: "Business User" });
      if (formData && vendorPostData?.data?.vendorId) {
        formData.append("image", selectedFile);
        formData.append("userId", authData?.data?.userId);
        formData.append("type", "companyIcon");
        formData.append("vendorId", superAdminStatus ? permissionsDetails?.vendorId : vendorPostData?.data?.vendorId);
        profileImageUpload?.handleTrigger(formData);
      }
      if (props?.pageToNavigate == "home") {
        navigate("/");
      } else {
        props?.handleClose();
      }
    }
  }, [vendorPostData?.data]);
  // https://thebox-dev.portqii.com/core/vendor/vendorOnboardingConfiguration
  const StaticData = useEnCryptGetApi(
    `/vendor/vendorOnboardingConfiguration`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const StaticDropdownData = StaticData?.data?.data?.config;
  const handleFooterNumberClicked = (val) => {
    const Validate3 = validateCompanyProfilePage3(companyDetails);

    if (val <= 2) {
      const Validate1 = validateCompanyProfilePage1(companyDetails);
      setCompanyErrors(Validate1);

      if (!Object.keys(Validate1)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 3) {
      const Validate2 = validateCompanyProfilePage2(companyDetails);
      setCompanyErrors(Validate2);
      if (!Object.keys(Validate2)?.length) {
        setFooterNumberClicked(val);
      }
    } else if (val <= 4) {
      const Validate = ValidateCompanyProfileCustomer(companyDetails?.Vendor_Customers);
      setCompanyErrors(Validate);
      if (!Object.keys(Validate)?.length) {
        // setFooterNumberClicked(val);
      }
    } else {
      return;
    }
    setTimeout(() => {
      setCompanyErrors({});
    }, 2000);
  };
  const handleDetails = (val) => {
    setCompanyDetails(val);
  };
  const handleSaveClicked = (val) => {
    const Validate2 = ValidateCompanyProfileCustomer(companyDetails?.Vendor_Customers);
    setCompanyErrors(Validate2);
    let WithDomainURL = {
      ...companyDetails,
      Status: val ? val : companyDetails?.Status,
      emailId: authData?.data?.emailId,
      User_ID: authData?.data?.userId,
    };

    let WithoutDOmainURL = {
      ...companyDetails,
      Domain_URL: `https://${validateEmailId}`,
      Status: val ? val : companyDetails?.Status,
      emailId: authData?.data?.emailId,
      User_ID: authData?.data?.userId,
    };

    delete WithDomainURL?.Vendor_Logo_URL;
    delete WithoutDOmainURL?.Vendor_Logo_URL;

    if (!Object.keys(Validate2)?.length) {
      vendorPostData.handleTrigger(companyDetails?.Domain_URL ? WithDomainURL : WithoutDOmainURL);
    }
  };
  const handleSelectedImage = (val) => {
    setSelectedFile(val);
  };
  return (
    <div className="company-profile-main-wrapper">
      <div className="company-details-header-main-wrapper-ref">
        <CompanyDetailsHeader
          handleClose={props?.handleClose}
          productPageLabel={
            props?.action === "createNew"
              ? KEYS?.COMPANY_PROFILE?.NEW_ORGANIZATION
              : KEYS?.COMPANY_PROFILE?.EDIT_ORAGANIZATION
          }
        />
      </div>
      {UpdateStatus ? (
        <div class="modern-message modern-message--positive modern-message--elevated">
          <i class="feather icon icon-check-circle message__icon"></i>
          <div class="message__content">
            <p class="message__text">{KEYS?.COMPANY_PROFILE?.SUCCESS_MESSAGE}</p>
          </div>
        </div>
      ) : null}
      <div className="company-profile-details-main-container-ref">
        <CompanyDetailsList
          validateEmailId={validateEmailId}
          StaticDropdownData={StaticDropdownData}
          companyErrors={companyErrors}
          handleDetails={handleDetails}
          companyDetails={companyDetails}
          footerNumberClicked={footerNumberClicked}
          action={props?.action}
          handleSelectedImage={handleSelectedImage}
          setCompanyErrors={(val) => setCompanyErrors(val)}
        />
      </div>
      <div className="company-details-footer-main-wrapper">
        <CompanyDetailsFooter
          footerList={footerList}
          handleSaveClicked={handleSaveClicked}
          handleFooterNumberClicked={handleFooterNumberClicked}
          footerNumberClicked={footerNumberClicked}
          vendorPostData={vendorPostData}
          action={props?.action}
        />
      </div>
    </div>
  );
};

export default CompanyProfile;

{
  /*
  COMPANY_PROFILE:{
    "SUCCESS_MESSAGE":'Vendor details updated successfully',
    "NEW_ORGANIZATION":'New Organization',
    "BASIC_INFORMATION_LABEL":'Please provide some basic information to assist you better',
    "ORGANIZATION_NAME":'Organization Name',
    "ORGANIZATION_NAME_LABEL":'Organization Name',
    "ABOUT":'About',
    "ABOUT_LABEL":'About The Organization',
    "BUSINESS_FOCUS":'Business Focus',
    "BUSINESS_FOCUS_LABEL":'Business Focus',
    "ESTABLISHED_ON":'Established On',
    "ESTABLISHED_ON_LABEL":'Established On',
    "ORGANIZATION_SIZE":'Organization Size',
    "ORGANIZATION_SIZE_LABEL":'Organization Size',
    "ORGANIZATION_TYPE":'Organization Type',
    "ORGANIZATION_TYPE_LABEL":'Organization Type',
    "COMPANY_WEBSITE":'Company Website',
    "COMPANY_WEBSITE_LABEL":'Company Website',
    "COMPANY_PHONE_NUMBER":'Company Phone Number',
    "COMPANY_PHONE_NUMBER_LABEL":'Company Phone Number',
    "HEADQUARTERS":' Headquarters',
    "HEADQUARTERS_LABEL":' Headquarters',
    "ADD_LOCATION":'Add Location',
    "BUILDING_NAME":'Building Name',
    "BUILDING_NAME_LABEL":'Building Name',
    "ADDRESS":'Address',
    "ADDRESS_LABEL":'Address',
    "COUNTRY":'Country',
    "COUNTRY_LABEL":'Country',
    "PINCODE":'Pincode',
    "PINCODE_LABEL":'Pincode',
    "BACK":'Back',
    "SAVE":'Save',
    "NEXT":'Next',
  }
  */
}
