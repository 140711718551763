import React from "react";
import Answers from "./answers/answers";
import BasicInformation from "./bascInformation/basicInformation";
import TimelineParent from "./timelineAndLicense/timelineParent";
import * as CONFIG_KEYS from "../../../config/config";
import ManageTeams from "./manageTeams/manageTeams";
const PreviewProposal = (props) => {
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const proposalDetails = props?.proposalDetails;
  const ADD_PROPOSAL_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  const hideTimeLine =
    PROPOSAL_TYPE == "answer" &&
    !proposalDetails?.[ADD_PROPOSAL_KEYS?.PROPOSAL_TIMELINE] &&
    !proposalDetails?.[ADD_PROPOSAL_KEYS?.NUMBER_OF_LICENSE];
  const hideTeams = PROPOSAL_TYPE == "answer" ? true : false;
  return (
    <div style={{ width: "100%" }}>
      <BasicInformation
        USER_CAN_EDIT={USER_CAN_EDIT}
        PROPOSAL_TYPE={PROPOSAL_TYPE}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
        handleScreenToShow={props?.handleScreenToShow}
      />
      <Answers
        USER_CAN_EDIT={USER_CAN_EDIT}
        PROPOSAL_TYPE={PROPOSAL_TYPE}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
        companyErrors={props?.companyErrors}
      />
      {hideTimeLine ? null : (
        <TimelineParent
          USER_CAN_EDIT={USER_CAN_EDIT}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
          companyErrors={props?.companyErrors}
        />
      )}
      {hideTeams ? null : (
        <ManageTeams
          USER_CAN_EDIT={USER_CAN_EDIT}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          proposalDetails={proposalDetails}
          handleDetails={props?.handleDetails}
          companyErrors={props?.companyErrors}
        />
      )}

      {/* <Questions
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
        companyErrors={props?.companyErrors}
      />
      <TimelineAndVendors
        companyErrors={props?.companyErrors}
        proposalDetails={proposalDetails}
        handleDetails={props?.handleDetails}
      /> */}
    </div>
  );
};

export default PreviewProposal;
