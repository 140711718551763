export const CONFIG = {
  TOKEN: "VGhlYm94UG9ydHFpaTIwMjJUT0tFTl9TRUNSRVQ=",
  SITE_KEY_GOOGLE: "6Lc6rOAhAAAAAPBNLnyVjqm0S0Ziic9e6EPXDSKv",
  BASE_END_POINT: "https://octate.ai",
  BUYER_PUBLISHED: "Buyer_Published",
  VENDOR_PUBLISHED: "Vendor_Published",
  VENDOR_ADDED: "Vendor_Added",
  ANSWER_EDITED: "Answer_Edited",
  VENDOR_COMMENTED: "Vendor_Commented",
  BUYER_COMMENTED: "Buyer_Commented",
  SIGN_IN_CLICKED: "Clicked",
  CREATE_ACCOUNT_CLICKED: "Clicked",
  CTA_LINK_CLICKED: "Clicked",
  VENDOR_NAME_CLICKED: "Clicked",
  RELATED_TOPIC_CLICKED: "Clicked",
  VIEW_ALL_PRODUCTS: "Clicked",
  VIEW_ALL_VENDORS_CLICKED: "Clicked",
  QUESTION_EDITED: "Question_Edited",
  VENDOR_DELETED: "Vendor_Deleted",
  ALL_CATEGORIES_CLICKED: "Clicked",
  ALL_PRODUCTS: "all products",
  SEARCH_CLICKED: "Clicked",
  VENDOR_DELETE_PROPOSAL: "Vendor_Deleted_Proposal",
  BUYER_DELETE_PROPOSAL: "Buyer_Deleted_Proposal",
  FILTERS: "Filters",
  CATEGORIES_CLICKED: "Clicked",
  BLURB_TITLE_CONTENT_CLICKED: "Clicked",
  BOOKMARK_CLICKED: "Clicked",
  LIKE_CLICKED: "Clicked",
  SHARE_CLICKED: "Clicked",
  PRODUCT_CARD_ACTION_BUTTON: "Clicked",
  PRODUCT_NAME_CLICKED: "Clicked",
  FORMOGRAPICS_CLICKED: "Clicked",
  VENDOR_PRODUCT_CLICKED: "Clicked",
  VENDOR_CARD_ACTION_BUTTON: "Clicked",
  VIEW_BLURB_CLICKED: "Clicked",
  VIEW_PRODUCT_CLICKED: "Clicked",
  VIEW_VEDNOR_CLICKED: "Clicked",
  VENDOR_CARD_CLICKED: "Clicked",
  BUYER_SHARES_PROPOSAL: "Proposal_Shared",
  USER_VISITED: "Visited",
  SIGN_UP_START: "Signup Start",
  SIGN_UP_COMPLETE: "Signup Complete",
  SIGN_IN_COMPETE: "Signin",
  SIGN_OUT: "Signout",
  INVITED_VENDOR_SIGNUP_START: "Invited vendor signup start",
  INVITED_VENDOR_SIGNUP_COMPLETE: "Invited vendor signup complete",
  VENDOR_INVITED: "Vendor invited",
  USER_REMOVED: "User removed",
  USER_REJECTED: "User rejected",
  USER_ACCEPTED: "User accepted",
  LICENSE_ADDED: "License added",
  USER_INVITED: "User invited",
  BLURB_CREATED: "Create",
  BLURB_UPDATED: "Update",
  BLURB_DELETED: "Delete",
  PRODUCT_CREATED: "Create",
  PRODUCT_UPDATED: "Update",
  PRODUCT_DELETED: "Delete",
  FAQ_CREATED: "Create",
  FAQ_UPDATED: "Update",
  FAQ_DELETED: "Delete",
  USECASE_CREATED: "Create",
  USECASE_UPDATED: "Update",
  USECASE_DELETED: "Delete",
  BOARD_CREATED: "Create",
  BOARD_UPDATED: "Update",
  BOARD_DELETED: "Delete",
  CUSTOMERS_CREATED: "Create",
  CUSTOMERS_UPDATED: "Update",
  CUSTOMERS_DELETED: "Delete",
  PERMISSIONS_CREATED: "Create",
  PERMISSIONS_UPDATED: "Update",
  PERMISSIONS_DELETED: "Delete",
  LIKE_BLURB: "Liked_Blurb",
  COMMENT_USE_CASE: "Like_Use_Case",
  COMMENT_PRODUCT: "Comment_Product",
  COMMENT_BLURB: "Comment_Blurb",
  COMMENT_USE_CASE: "Comment_Use_Case",
  REPLY_PRODUCT: "Reply_Product",
  REPLY_BLURB: "Reply_Blurb",
  REPLY_USE_CASE: "Reply_Use_Case",
  LIKE_PRODUCT: "Liked_Product",
  DM_CLICKED: "Clicked",
  CLICKED: "Clicked",
  BROWSER_SPEED_BASE_END_POINT:"https://a.usbrowserspeed.com/cs?pid=48a021d87720f17403d730658979d7f60e9cec91937e82072c66f611748dd47d&puid=%7B%22client_id%22%3A%225cf8299dd025a6b9ea0a63b87cab6e448b81b6b7c0bf3c895291104b87b0cd78%22%2C%22partner%22%3A%22portqii%22%2C%22purpose%22%3A%22Octate%20visitor%20tracking%22%2C%22visitor_id%22%3A%22user_identifier%22%7D",
  BROWSER_SPEED_HEM: "",
  BROWSER_SPEED_REDIRECT_TO: "",
  LINKED_IN_SECRET: 'linkein$%$AI*&&*20BC',
  LINKED_IN_SCOPE: 'r_organization_social,w_member_social,r_basicprofile,rw_ads',
  LINKED_IN_CLIENT_ID: '862gvk6n4w00h9',
  LINKED_IN_CLIENT_SECRET: 'no05xCWciMvFElzK',
   EMAIL_CHARACTER_LIMIT: 50,
};
