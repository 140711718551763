import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetRequestQuery, usePostRequestMutation } from "../../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi, useEnCryptPostApi } from "../../../../../utlis/customHooks/customHooks";
import InfiniteScrollComp from "../../../../../utlis/hoc/infiniteScroll/infiniteScroll";
import InverseInfiniteScroll from "../../../../../utlis/hoc/infiniteScroll/inverseInfiniteScroll";
import BoxSpinner from "../../../../../utlis/spinner/boxSpinner";
import ChatList from "../../../../directMessage/chatRoom/chatList";
import ChatRoomInput from "../../../../directMessage/chatRoom/chatRoomInput";
import SelectedCardList from "./selectedCardList";
import VendorChatRoomInput from "./vendorChatRoomInput";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRef } from "react";
import { Waypoint } from "react-waypoint";
function SelectedCardContent(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const selectedUserSelector = useSelector((state) => state?.chatDetails?.selectedUser);
  const chatRoomListRef = useRef();
  const data = props?.data;
  // const featureActiveList = props?.featureActiveList;
  // const findActiveIndex = data?.length
  //   ? data.findIndex((val) => {
  //       return val.Conversation_ID == props?.activeConversionId;
  //     })
  //   : 0;
  // const selectedVendorData = data?.length ? data[findActiveIndex] : {};
  const chatSocket = useSelector((state) => state?.homeDetails?.chatSocket);
  const [conversationList, setConversationList] = useState([]);
  const [PageNo, setPageNo] = useState(1);
  const authData = useSelector((state) => state?.authDetails?.details);
  const userName = authData?.data?.userName;
  const VENDOR_ID = props?.VENDOR_ID;
  //  http://localhost:8080/getUserMessages?Conversation_ID=6&Page=1
  const Conversation_ID = selectedUserSelector?.Conversation_ID ? selectedUserSelector?.Conversation_ID : null;
  useEffect(() => {
    setConversationList([]);
    setPageNo(1);
  }, [selectedUserSelector]);
  const GetApiData = useEnCryptGetApi(
    `/getVendorMessages?Conversation_ID=${Conversation_ID}&Page=${PageNo}`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    useGetRequestQuery
  );
  const postSeenConversion = useEnCryptPostApi(
    `/updateSeenMessage`,
    KEYS?.END_POINTS?.DIRECT_MESSAGE?.END_POINT_URL,
    usePostRequestMutation
  );
  const concatNewArrayFn = useCallback(
    (val) => {
      if (Conversation_ID === val?.Conversation_ID) {
        const newData = { ...val, User_Name: val?.From_Vendor ? val?.User_Name : selectedUserSelector?.User_Name };
        let newArray = conversationList?.length ? [...conversationList] : [];
        newArray.push(newData);
        setConversationList(newArray);
        postSeenConversion.handleTrigger({
          Conversation_ID: Conversation_ID,
          From_Vendor: true,
          Vendor_ID: VENDOR_ID,
          Date_TIme: parseInt(new Date().getTime() / 1000),
        });
      }
    },
    [Conversation_ID, conversationList]
  );
  function handler(data) {
    const newArray = concatNewArrayFn(data);
  }
  useEffect(() => {
    const socketConnected = typeof chatSocket === "object" && Object.keys(chatSocket).length ? true : false;
    if (socketConnected) {
      chatSocket.emit("join", `conversion_vendor_${Conversation_ID}`);
    }
    postSeenConversion.handleTrigger({
      Conversation_ID: Conversation_ID,
      From_Vendor: true,
      Vendor_ID: VENDOR_ID,
      Updated_At: parseInt(new Date().getTime() / 1000),
    });
  }, [Conversation_ID]);
  useEffect(() => {
    if (chatSocket.connected) {
      chatSocket.on(`chat_room_message`, handler);
      return () => chatSocket.off("chat_room_message", handler);
    }
  }, [chatSocket, Conversation_ID, conversationList]);
  useEffect(() => {
    if (GetApiData?.data?.data?.length) {
      const newData = [...GetApiData?.data?.data];
      const concatData = newData.concat(conversationList);
      setConversationList((prev) => concatData);
    }
  }, [GetApiData?.data?.data]);
  const goToPrevScroll = useCallback(
    (oldHeight) => {
      const viewportBottom = oldHeight?.viewportBottom;
      const viewportTop = oldHeight?.viewportTop;
      const { current } = chatRoomListRef;
      if (current && PageNo > 1) {
        setTimeout(() => {
          current.scrollTop = viewportBottom * 2 + chatRoomListRef?.current?.scrollTop;
        }, 1500);

        // current.scrollTop = current.scrollHeight  + current.scrollTop;
      }
    },
    [PageNo]
  );
  const fetchData = async (canScroll) => {
    if (GetApiData?.data?.data?.length) {
      goToPrevScroll(canScroll);
      setPageNo((prev) => prev + 1);
    }

    // goToPrevScroll(canScroll);

    // if (!canScroll) {
    //   setTimeout(() => {
    //     setPageNo((prev) => prev + 1);
    //   }, 500);
    // }
  };
  //   const allChatList = GetApiData?.data?.data?.length ? GetApiData?.data?.data : [];
  return (
    <div
      style={{ borderRight: "1px solid #d3d3d3", paddingRight: "20px" }}
      className="chat-room-content-main-wrapper--vendor-side p-t-sm"
    >
      <div ref={chatRoomListRef} id="scrollableDiv11" className="chat-room-content-list-main-wrapper">
        {/* <InverseInfiniteScroll
          id="scrollableDiv"
          Data={conversationList}
          hasMoreData={GetApiData?.data?.data?.length ? true : false}
          fetchData={fetchData}
        > */}
        {/* <InfiniteScroll
          scrollableTarget="scrollableDiv11"
          dataLength={conversationList} //This is important field to render the next data
          next={() => fetchData(GetApiData?.isLoading || GetApiData?.isFetching)}
          hasMore={GetApiData?.data?.data?.length ? true : false}
          inverse={true}
          style={{ display: "flex", flexDirection: "column-reverse" }}
        > */}
        <SelectedCardList newData={GetApiData} fetchData={fetchData} allChatList={conversationList} />
        {/* </InfiniteScroll> */}

        {/* </InverseInfiniteScroll> */}
        {GetApiData?.isLoading || GetApiData?.isFetching ? <BoxSpinner /> : null}
      </div>
      {conversationList?.length ? (
        <div className="chat-room-input-list-main-wrapper">
          <VendorChatRoomInput
            selectedVendorData={selectedUserSelector}
            VENDOR_ID={VENDOR_ID}
            formVendor={true}
            key={selectedUserSelector?.Conversation_ID}
          />
        </div>
      ) : null}
    </div>
  );
}

export default SelectedCardContent;
