import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import InputFieldProfile from "../../../profile/editProfile/component/inputFieldProfile";
import * as ADD_SECTION__KEYS from "../../../../config/config";
import { validateProposalQuestionFormError } from "../../../../utlis/hof/hof";
import AddQuestionType from "../addSectionAndQuestionPortal/addQuestionType";
import AddQuestionTypeList from "../addSectionAndQuestionPortal/addQuestionTypeList";
const SectionMoreOptions = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const ADD_SECTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_SECTION;
  const ADD_QUESTION_CONFIG_KEYS = ADD_SECTION__KEYS?.CONFIG?.ADD_QUESTION;
  const addType = props?.addType;
  const allData = props?.allData;
  //   const ADD_PRODUCT_CONFIG_KEYS = CONFIG_KEYS?.CONFIG?.ADD_PROPOSAL;
  const [fields, setFields] = useState(addType == "section" ? {} : []);
  const [proposalQuestionFormError, setProposalQuestionFormError] = useState(
    {}
  );
  const PROPOSAL_ADD_SECTION = KEYS?.PROPOSAL_ADD_SECTION;
  const questionListIndex = props?.questionListIndex;
  const proposalBasicDetails = fields;
  const data = props?.data;
  useEffect(() => {
    setFields(questionListIndex ? props?.data : {});
  }, [data]);
  const handleSaveClicked = () => {
    let validateErrors = {};
    if (addType == "section") {
      const checkIfEdited =
        props?.data?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]?.length &&
        fields?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]?.length &&
        fields?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]?.toLowerCase() ==
          props?.data?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]?.toLowerCase();
      validateErrors = checkIfEdited
        ? {}
        : validateProposalQuestionFormError(fields, addType, false, allData);
    } else if (
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Check Box" ||
      fields?.[ADD_QUESTION_CONFIG_KEYS?.QUESTION_TYPE] == "Radio Button"
    ) {
      validateErrors = validateProposalQuestionFormError(fields, addType, true);
    } else {
      validateErrors = validateProposalQuestionFormError(
        fields,
        addType,
        false
      );
    }
    setProposalQuestionFormError(validateErrors);
    setTimeout(() => {
      setProposalQuestionFormError({});
    }, 3000);
    if (!Object.keys(validateErrors)?.length) {
      props?.handleSaveClicked(fields);
    }
  };
  return (
    <div
      className={`confirm-delete-portal-main-wrapper ${
        addType == "section" ? "" : "confirm-delete-portal-main-wrapper--width"
      } p-r`}
    >
      <div className="add-section-and-question-header-wrapper">
        <div>
          <h3 className="add-section-and-question-content-header-text">
            {questionListIndex
              ? "Edit Section"
              : PROPOSAL_ADD_SECTION?.SECTION_HEADER}
          </h3>
        </div>
        {questionListIndex ? (
          <div
            onClick={
              questionListIndex
                ? props?.handleDeleteClicked
                : props?.handleClose
            }
            className="dis-f-r cursor--pointer"
            style={{
              borderRadius: "5px",
              border: "1px solid #ffff",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "5px 10px",
            }}
          >
            <p className="add-section-and-question-delete-text">
              {PROPOSAL_ADD_SECTION?.SECTION_DELETE}
            </p>
            <FeatherIcon icon="trash" size="12" color="#ffffff" />
          </div>
        ) : null}
      </div>
      <div
        class="field p-r m-y-company-profile-input-wrapper m-x-sm"
        id="field-user__email"
      >
        {proposalBasicDetails?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE] ? (
          <>
            <label
              for="user__email"
              className="company-details-label-input-field-filled-absolute"
            >
              {`${PROPOSAL_ADD_SECTION?.SECTION_TITLE}`}
            </label>

            <label
              className={`company-details-label-input-field-filled-absolute--add-section-char-limit ${
                fields?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]?.length == 50
                  ? "add-section-char-limit--error"
                  : ""
              }`}
            >
              {`${50 -
                fields?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]
                  ?.length} characters remaining`}
            </label>
          </>
        ) : null}

        <InputFieldProfile
          label={PROPOSAL_ADD_SECTION?.SECTION_TITLE}
          value={proposalBasicDetails?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]}
          changeText={(val) =>
            setFields({
              ...fields,
              [ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]:
                val?.length == 1
                  ? val?.trim()
                  : val.length > 50
                  ? fields?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]
                  : val,
            })
          }
          error={
            proposalQuestionFormError?.[ADD_SECTION_CONFIG_KEYS?.SECTION_TITLE]
          }
        />
      </div>
      <div className="confirm-delete-portal-header-actions-wrapper ">
        <div onClick={handleSaveClicked}>
          <p className="m-x-sm add-section-and-question-action-save-text cursor--pointer">
            {PROPOSAL_ADD_SECTION?.SAVE}
          </p>
        </div>
        <div onClick={props?.handleClose}>
          <p className="add-section-and-question-action-cancel-text cursor--pointer">
            {PROPOSAL_ADD_SECTION?.CANCEL}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionMoreOptions;
