import React from "react";

const CompleteStep = (props) => {
  const taskName = props?.name;
  return (
    <div className="flex align-items-center m-y-md">
      <img
        class=" ls-is-cached lazyloaded"
        data-src={
          require("../../../../assets/onboardingSvg/round-check.svg").default
        }
        src={
          require("../../../../assets/onboardingSvg/round-check.svg").default
        }
        alt={"Round Check"}
      />
      <h1 className="otp-verification-title" style={{ margin: "0% 15px" }}>
        {taskName}
      </h1>
    </div>
  );
};

export default CompleteStep;
