import React, { useState } from "react";
import "../../../styles/new-cards.css";
import NoResult from "../../../components/searchResult/components/noResult";
import ProductCard from "./components/productCard";

const NewProductCard = (props) => {
  const ProductData = props?.data;
  const permissionsDetails = props?.permissionsDetails;
  const cardType = "product";
  return ProductData?.length > 0
    ? ProductData?.map((item, index) => (
        <ProductCard permissionsDetails={permissionsDetails} props={props} item={item} />
      ))
    : null;
  // <NoResult cardType={cardType} page={props?.page} section={props?.section} type={"products"} />
};

export default NewProductCard;
