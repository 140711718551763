import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopularDetailContent from "./popularDetailContent";
import PopularUseCaseMedia from "./popularUseCaseMedia";
const PopularDetail = ({ data, activeCategory }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const cardType = "popular case studies";

  const POPULAR_DATA = data?.[activeCategory] ?? data;

  useEffect(() => {
    setCurrentIndex((prev) => 0);
  }, [data?.[activeCategory]]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % POPULAR_DATA?.length);
    }, 9000);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, POPULAR_DATA?.length]);

  return POPULAR_DATA?.length ? (
    <>
      {/* <div
        onClick={() => setCurrentIndex((prevIndex) => (prevIndex === 0 ? POPULAR_DATA?.length - 1 : prevIndex - 1))}
        className={"navigate-icon-left mobile-hidden"}
      >
        <FeatherIcon icon={"chevron-left"} size="30" />
      </div> */}
      <div className="popular-detail-view-container" key={currentIndex}>
        <div className="popular-detail-view-content-container">
          <PopularDetailContent data={POPULAR_DATA[currentIndex] ? POPULAR_DATA[currentIndex] : POPULAR_DATA[0]} />
          <div className="slider-dots-container">
            {POPULAR_DATA?.map((item, index) => (
              <div
                className={` ${currentIndex === index ? "slider-dots-active" : "slider-dots"}`}
                onClick={() => setCurrentIndex(index)}
                role="button"
              ></div>
            ))}
          </div>
        </div>
        <div className="popular-detail-view-image-container mobile-hidden tablet-hidden">
          <PopularUseCaseMedia
            cardType={cardType}
            page={"Home"}
            section={"popular usecase"}
            data={POPULAR_DATA[currentIndex] ? POPULAR_DATA[currentIndex] : POPULAR_DATA[0]}
          />
        </div>
      </div>
      {/* <div
        onClick={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % POPULAR_DATA?.length)}
        className={"navigate-icon-right mobile-hidden"}
      >
        <FeatherIcon icon={"chevron-right"} size="30" />
      </div> */}
    </>
  ) : null;
};

export default PopularDetail;
