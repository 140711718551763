import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ChatListCard from "./chatListCard";

const ChatListWrapper = (props) => {
  const MESSAGE_LIST = props?.data ? props?.data : [];

  return (
    <div className="chat-list-wrapper">
      {MESSAGE_LIST?.map((item) => (
        <ChatListCard data={item} />
      ))}
      {!MESSAGE_LIST?.length ? (
        <div className="direct-message-not-empty-chat-list-message-container">
          <h1 className="direct-message-not-empty-chat-list-message">
            You have not interacted with anyone so far. Start engaging with vendors now.
          </h1>
        </div>
      ) : null}
    </div>
  );
};

export default ChatListWrapper;
