import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePostRequestMutation, usePutPutUpdateUserProfileMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { validateEditProfileForms, validateEditProfileOnboardForms } from "../../../../utlis/hof/hof";
import BasicDetails from "./basicDetails";
import ProfessionalDetails from "./professionalDetails";
import ProfilePictureDesktop from "./profilePictureDesktop";
import _ from "lodash";
import { useLocalStorage } from "../../../../utlis/customHooks/useLocalStorage";
import BusinessDetails from "./businessDetails";
import { tempAuthDetails } from "../../../../contexts/reducer/authReducer";
import { useNavigate } from "react-router";
const ProfileDetails = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PROFILE_DETAILS = KEYS?.PROFILE_DETAILS;
  const [allProfileDetails, setAllProfileDetails] = useState(props?.data ? props?.data : {});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [UpdateStatus, setUpdateStatus] = useState(false);
  useEffect(() => {
    if (props?.data) {
      setAllProfileDetails(props?.data);
    }
  }, [props?.data]);
  const UpdateProfilePostApi = useEnCryptPostApi(
    `/updateUserProfile`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePutPutUpdateUserProfileMutation
  );

  const OnboardingDetails = useEnCryptPostApi(
    `/signup/insertSignUpDetails`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    usePostRequestMutation
  );
  useEffect(() => {
    if (UpdateProfilePostApi?.data?.status) {
      setUpdateStatus(true);
      setUser({
        ...authData?.data,
        ...allProfileDetails,
        userName: allProfileDetails?.firstName + " " + allProfileDetails?.lastName,
      });
      setTimeout(() => {
        setUpdateStatus(false);
      }, 2000);
    }
  }, [UpdateProfilePostApi?.data]);
  const PROFILE = KEYS?.EDIT_PROFILE;
  const userData = props?.data;
  const onSubmit = (e) => {
    e.preventDefault();
    const rawData = { ...allProfileDetails, userId: authData?.data?.userId };
    const RAW_DATA = {
      ...allProfileDetails,
      Persona: "Consumer",
      userId: authUser?.data?.userId,
      deviceId: authData?.data?.deviceId,
      role: "",
    };
    const validate = authUser?.data?.userExist
      ? validateEditProfileForms(rawData)
      : validateEditProfileOnboardForms(RAW_DATA);
    setError(validate);
    if (_.isEmpty(validate)) {
      authUser?.data?.userExist
        ? UpdateProfilePostApi?.handleTrigger(rawData)
        : OnboardingDetails?.handleTrigger(RAW_DATA);
    }
  };

  useEffect(() => {
    if (OnboardingDetails?.data?.status) {
      dispatch(
        tempAuthDetails({
          data: { ...OnboardingDetails?.data },
          source: props?.page,
          target: "/",
          verifyOTP: true,
        })
      );
      navigate("/email-verification", { replace: true });
    } else if (!OnboardingDetails?.data?.status) {
      setError({ emailId: OnboardingDetails?.data?.message });
    }
  }, [OnboardingDetails?.data]);

  const handleBasicDetails = (val) => {
    if (val) {
      setAllProfileDetails({ ...allProfileDetails, ...val });
    }
  };
  const handleProfessionalDetails = (val) => {
    if (val) {
      setAllProfileDetails({ ...allProfileDetails, ...val });
    }
  };
  return (
    <div className="edit-profile-container">
      <div class="container p-r">
        {UpdateStatus ? (
          <div class="modern-message modern-message--positive modern-message--elevated successful-profile-pop-up">
            <i class="feather icon icon-check-circle message__icon"></i>
            <div class="message__content">
              <p class="message__text">{PROFILE?.UPDATE_SUCCESSFUL}</p>
            </div>
          </div>
        ) : null}
        {props?.featureActiveList === "business-details" ? (
          <BusinessDetails />
        ) : (
          <form class="dtrd-form--modern dtrd-form p-b-md" onSubmit={onSubmit}>
            <div
              class="flex-column"
              // style={{ display: "flex", flexDirection: "column" }}
            >
              {props?.featureActiveList === "user-profile" ? (
                <BasicDetails error={error} handleBasicDetails={handleBasicDetails} data={allProfileDetails} />
              ) : props?.featureActiveList == "professional-details" ? (
                <ProfessionalDetails
                  error={error}
                  handleProfessionalDetails={handleProfessionalDetails}
                  data={allProfileDetails}
                />
              ) : (
                <BasicDetails error={error} handleBasicDetails={handleBasicDetails} data={allProfileDetails} />
              )}
            </div>
            {props?.featureActiveList === "business-details" ? null : (
              <input
                type="submit"
                name="commit"
                value={PROFILE_DETAILS?.SAVE_BUTTON}
                class="edit-profile-button"
                data-disable-with={PROFILE_DETAILS?.SAVE_BUTTON}
              />
            )}
          </form>
        )}
      </div>
      <div>
        {props?.featureActiveList === "professional-details" ||
        props?.featureActiveList === "business-details" ? null : (
          <ProfilePictureDesktop handleProfessionalDetails={handleProfessionalDetails} data={allProfileDetails} />
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;
