import React from "react";
import { useSelector } from "react-redux";
import { usePostRequestMutation } from "../../../../adapters/xhr/generalApi";
import { useEnCryptPostApi } from "../../../../utlis/customHooks/customHooks";
import { ReplaceBlankSpaceByHyphen, ToTitleCase } from "../../../../utlis/hof/hof";
import HeaderSEO from "../../../SEO/header";
import MetaSEO from "../../../SEO/MetaSEO";
import * as SECRET_KEYS from "../../../../config/configKeys";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const UseCaseHeader = (props) => {
  const BLURB_DATA = props?.data;

  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;

  const GetSEOTags = (data) => {
    const tags = data?.map((item) => item?.Name)?.filter((val) => val);
    return tags;
  };

  const signalTrackingBreadCrumbObject = {
    Action: SECRET_KEYS.CONFIG.VENDOR_NAME_CLICKED,
    page: props?.page,
    section: "Usecase detail header",
  };
  const SignalTracking = useEnCryptPostApi(
    `/insertSignalActivityDetails`,
    KEYS?.END_POINTS?.SIGNAL_TRACKING?.END_POINT_URL,
    usePostRequestMutation
  );
  const handleVendorNameClicked = (val) => {
    const signalTrackingPostData = {
      Actor: USER_ID,
      SignalCreatedAt: new Date().toString(),
      Action: signalTrackingBreadCrumbObject?.Action,
      Item_Type: "Vendor",
      Item_Id: val?.Vendor_ID,
      Target: {
        page: "vendor page",
        link: `/vendor/${ReplaceBlankSpaceByHyphen(val?.Vendor_Name, val?.Vendor_ID)}`,
      },
      Object: {
        section: signalTrackingBreadCrumbObject?.section,
        context: val?.Vendor_Name,
        page: props?.page,
      },
    };
    SignalTracking?.handleTrigger(signalTrackingPostData);
  };

  return (
    <div className="new-use-case-detail-header-container">
      {/* <HeaderSEO data={BLURB_DATA?.title_level_1} /> */}
      <MetaSEO
        title={BLURB_DATA?.title_level_1}
        description={BLURB_DATA?.description}
        Keywords={GetSEOTags(BLURB_DATA?.tags)}
      />
      <div className="flex-container" style={{ width: "70%" }}>
        <div className="header-image-border">
          <img
            class="blurb-detail-header-image"
            data-src={
              BLURB_DATA?.account_logo
                ? BLURB_DATA?.account_logo
                : require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default
            }
            src={
              BLURB_DATA?.account_logo
                ? BLURB_DATA?.account_logo
                : require("../../../../assets/images/Vendor_Placeholder_Icon.svg").default
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = KEYS?.CORRUPT_IMAGE_ICON;
            }}
            alt={`${BLURB_DATA?.Vendor_Name} ${SECRET_KEYS?.CONFIG?.VENDOR_LOGO}`}
          />
        </div>
        <div onClick={() => handleVendorNameClicked(BLURB_DATA)} className="blurb-header-vendor-name-container">
          <a
            className="blurb-header-vendor-name"
            role={"button"}
            href={`/vendor/${ReplaceBlankSpaceByHyphen(BLURB_DATA?.Vendor_Name, BLURB_DATA?.Vendor_ID)}/${
              KEYS?.DEFAULT_TAB_SELECTED?.BUSINESS_PAGE
            }`}
          >
            {ToTitleCase(BLURB_DATA?.Vendor_Name)}
          </a>
          <div className="flex additional-info-vendor-page" style={{ marginTop: "5px" }}>
            <FeatherIcon icon={"book"} size="13" style={{ marginRight: "5px" }} />
            <p data-inverted data-tooltip={"Type of Page"} className="use-case-card__disclaimer">
              {BLURB_DATA?.Page_Type}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCaseHeader;
