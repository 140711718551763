import React from "react";
import UseCaseCtaButton from "../../../../../utlis/hoc/newUseCaseCard/components/useCaseCtaButton";
import PopularUseCaseHeader from "./popularUseCaseHeader";
import PopularUseCaseContent from "./popularUsecaseContent";
import PopularUseCaseMedia from "./popularUseCaseMedia";

const PopularDetailContent = (props) => {
  const cardType = "popular case studies";
  const POPULAR_DATA = props?.data;

  return (
    <div className="popular-detail-content-opacity" key={POPULAR_DATA}>
      <PopularUseCaseHeader
        keyName={"blurbID"}
        data={POPULAR_DATA}
        cardType={cardType}
        page={props?.page}
        section={props?.section}
        searchText={props?.searchText}
      />
      <div className="popular-detail-view-image-container m-y-md only-mobile">
        <PopularUseCaseMedia cardType={cardType} page={"Home"} section={"popular usecase"} data={POPULAR_DATA} />
      </div>
      <PopularUseCaseContent
        data={POPULAR_DATA}
        cardType={cardType}
        page={props?.page}
        section={props?.section}
        searchText={props?.searchText}
      />
      <div style={{ padding: "0px 16px", marginTop: "3%" }}>
        <UseCaseCtaButton cardType={cardType} page={props?.page} section={props?.section} data={POPULAR_DATA} />
      </div>
    </div>
  );
};

export default PopularDetailContent;
