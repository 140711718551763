import React from "react";
import { useSelector } from "react-redux";
import { useGetRequestCrudQuery } from "../../../../adapters/xhr/crudServiceApi";
import { useGetRequestQuery } from "../../../../adapters/xhr/generalApi";
import { useEnCryptGetApi } from "../../../../utlis/customHooks/customHooks";
import SmallCardSkeleton from "../../../../utlis/hoc/skeleton/smallCardSkeleton";
import VendorCard from "../../../../utlis/hoc/vendorCard/vendorCard";
import { GetIdFormLink } from "../../../../utlis/hof/hof";
import * as NO_CONTENT from "../../../../config/config";

function VendorCustomers(props) {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const USER_ID = authData?.data?.userId;
  const VENDOR_ID = props?.VENDOR_ID;

  const GetApiData = useEnCryptGetApi(
    `/getVendorCustomers?vendorId=${VENDOR_ID}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestCrudQuery
  );

  const CUSTOMERS = GetApiData?.data?.data;

  return (
    <div class="provider-profile__main-content-section one-column-grid" id="provider-profile-alternatives">
      {GetApiData?.isLoading || GetApiData?.isFetching ? (
        <div className="grid-two-columns">
          <SmallCardSkeleton skeletonLength={4} />
        </div>
      ) : CUSTOMERS?.length ? (
        <VendorCard
          page={props?.page}
          section={props?.page}
          data={CUSTOMERS}
          tabIndex={props?.tabIndex}
          type={props?.type}
        />
      ) : GetApiData?.data?.data?.length === 0 ? (
        <div className="search__result data-product-card no-content-card">
          <div className="no-content-text">
            {props?.type === "buyer"
              ? NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.VENDOR_PAGE_CUSTOMER
              : NO_CONTENT?.CONFIG?.NO_CONTENT_MESSAGE?.BUSINESS_PROFILE_CUSTOMER}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default VendorCustomers;
