import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import PreviewTemplate from "./previewTemplate";
// import AddSectionAndQuestion from "./addSectionAndQuestion";
import FeatherIcon from "feather-icons-react";
const PreviewTemplatePortal = (props) => {
  const proposalDetails = props?.proposalDetails;
  return ReactDom.createPortal(
    <>
      <div
        className=" ui dimmer modals page visible active portalModal"
        style={{ background: "rgba(222,222,222,0.2)" }}
      >
        <div
          style={{
            height: "50%",
            width: "50%",
            minHeight: "550px",
            minWidth: "400px",
            background: "#fff",
            padding: "0px 0px 0px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "5px 5px 5px 5px",
              justifyContent: "space-between",
              borderBottom: "1px solid #d3d3d3",
              background: " rgb(239, 241, 249)",
            }}
          >
            <h5 className="preview-template-header-text">{proposalDetails?.Proposal_Title}</h5>
            <div onClick={() => props?.handleClose()} className="cursor--pointer">
              <FeatherIcon icon={"x"} size="20" color={"#130F26"} />
            </div>
          </div>
          <div className="preview-template-main-content">
            <PreviewTemplate proposalDetails={proposalDetails} />
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default PreviewTemplatePortal;
