import React from "react";
import Header from "../../home/header/header";
import SearchHeaderBreadCrumb from "../../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import ExpertPost from "./components/tabs/posts/expertPost";
import ProductExpHeader from "./components/productExpHeader/productExpHeader";
import { Navigate, useParams } from "react-router";
import useScrollPosition from "../../../utlis/customHooks/useScrollPosition";
import StickyTab from "../../../utlis/hoc/tabNavigator/stickyTab";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import LoadingSpinner from "../../../utlis/spinner/loadingSpinner";
import { useLocalStorage } from "../../../utlis/customHooks/useLocalStorage";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import LinkedInAnalytics from "./components/tabs/analytics/linkedInAnalytics";
import Drafts from "./components/tabs/posts/drafts";
import Footer from "../../home/blades/footer";

const ProductExp = (props) => {
  const [authUser, setUser] = useLocalStorage("user");
  const authData = useSelector((state) => state?.authDetails?.details);
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const page = `ProductExpert`;
  const PARAMS = useParams();
  const tabIndex = PARAMS?.tab;
  const scrollPosition = useScrollPosition();

  const ProductExpertStatus = useEnCryptGetApi(
    `/getProductExpertStatus?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.PRODUCT_EXPERT?.END_POINT_URL,
    useGetRequestQuery
  );

  const BREAD_CRUMB_PRODUCT_EXPERT = [
    {
      name: "Home",
      active: true,
      link: "/",
    },
    {
      name: "Product Expert",
      active: false,
      //   link: '/search/use-cases',
    },
  ];
  const PRODUCT_EXPERT_TAB = [
    {
      label: "Posts",
      value: "posts",
      display: true,
    },
    {
      label: "Drafts",
      value: "drafts",
      display: true,
    },
    {
      label: "Dashboard",
      value: "dashboard",
      display: true,
    },
  ];

  useEffect(() => {
    if (ProductExpertStatus?.data?.data?.status) {
      setUser({ ...authData?.data, ...ProductExpertStatus?.data?.data });
    }
  }, [ProductExpertStatus?.data?.data]);

  return ProductExpertStatus?.isLoading || ProductExpertStatus?.isFetching || !ProductExpertStatus?.data?.data ? (
    <LoadingSpinner />
  ) : ProductExpertStatus?.data?.data?.isProductExpert || authData?.data?.isProductExpert ? (
    <div>
      <Header show={true} />
      <div className="attached-navbar ">
        <div className="fixed-nav-container flex space-between align-items-bottom ui container">
          <SearchHeaderBreadCrumb page={page} data={BREAD_CRUMB_PRODUCT_EXPERT} />
        </div>
      </div>
      <div className="business-profile-landing-page-container ui container">
        <ProductExpHeader page={page} tabIndex={tabIndex} tabData={PRODUCT_EXPERT_TAB} />

        <StickyTab
          tabIndex={tabIndex}
          tabData={PRODUCT_EXPERT_TAB}
          scrollPosition={scrollPosition}
          navigatePath={"productExpert"}
          page={page}
        />
        <div
          id="use-management-table"
          className={`${tabIndex == "permission" || tabIndex == "super-admin" ? "" : "grid-business-profile mobile-1-col"}`}
        >
          {tabIndex == "posts" && <ExpertPost />}
          {tabIndex == "drafts" && <Drafts />}
          {tabIndex == "dashboard" && <LinkedInAnalytics />}
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default ProductExp;
