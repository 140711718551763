import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useState } from "react";
import { useEnCryptGetApi } from "../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../adapters/xhr/generalApi";
import BlurbCard from "../blurbDetail/components/blurb/blurbCard";
import FaqSocial from "./faqSocial";
import FaqContent from "./faqContent";
import FaqDesc from "./faqDesc";
import FaqHeader from "./faqHeader";
import ProductActions from "../../utlis/hoc/newProductCard/components/productActions";
import FaqActionButtons from "./faqActionButton";

const FaqDetailCard = (props) => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const authData = useSelector((state) => state?.authDetails?.details);
  const PARAMS = useParams();

  const USER_ID = authData?.data?.userId;

  //to get the id from the url of blurb id
  const FAQ_DETAILS = PARAMS?.name;
  const FAQ_ID = FAQ_DETAILS?.split("-").at(-1);
  const Data = props?.data;
  return (
    <BlurbCard>
      <FaqSocial data={Data} page={props?.page} />
      <FaqContent data={Data} page={props?.page} />
      <FaqHeader data={Data} page={props?.page} />
      <div style={{ alignItems: "center" }} className="product-card-content">
        <div>
          <FaqDesc data={Data} page={props?.page} />
        </div>
        <FaqActionButtons data={Data} page={props?.page} />
      </div>
    </BlurbCard>
  );
};

export default FaqDetailCard;
