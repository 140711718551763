import React from "react";
import SearchHeaderBreadCrumb from "../../searchResult/components/seachHeader/searchHeaderBreadCrumb";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEnCryptGetApi } from "../../../utlis/customHooks/customHooks";
import { useGetRequestQuery } from "../../../adapters/xhr/generalApi";
import AvatarCard from "../editProfileOld/components/avatar";
import ProfileCard from "../editProfileOld/components/profileCard";
import LocationCard from "../editProfileOld/components/locationCard";
import PositionCard from "../editProfileOld/components/positionCard";
import CloseAccountCard from "../editProfileOld/components/closeAccountCard";
import CloseAccountModal from "../editProfileOld/components/closeAccountModal";
import BasicDetails from "./component/basicDetails";
import ProfilePictureDesktop from "./component/profilePictureDesktop";
import UserFeatureList from "./component/userFeatureList";
import ProfileDetails from "./component/profileDetails";
import { useGetRequestNewQuery } from "../../../adapters/xhr/coreService";
import Header from "../../home/header/header";
import { useNavigate, useParams } from "react-router";

const EditProfileNew = () => {
  const KEYS = useSelector((state) => state?.authDetails?.uxConfig);
  const PARAM = useParams();
  const navigate = useNavigate()
  const [closeAccountModal, setCloseAccountModal] = useState(false);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const [featureActiveList, setFeatureActiveList] = useState(1);
  const selectedTab = PARAM?.tab;
  const PROFILE = KEYS?.EDIT_PROFILE;
  const authData = useSelector((state) => state?.authDetails?.details);

  const handleModal = () => {
    setCloseAccountModal(!closeAccountModal);
  };
  const handleFeatureList = (val) => {
    setFeatureActiveList(val);
  };

  const GetApiData = useEnCryptGetApi(
    `/userProfileInformation?userId=${authData?.data?.userId}`,
    KEYS?.END_POINTS?.CORE?.END_POINT_URL,
    useGetRequestQuery
  );
  const ProfileData = GetApiData?.data?.data;
  const EDIT_PROFILE_TAB = [
    {
      label: "Profile Information",
      value: "user-profile",
      display: true,
    },
    {
      label: "Professional Details",
      value: "professional-details",
      display: true,
    },
  ];

  const DISPLAY_TAB = authData?.data?.isValidDomain
    ? [
        ...EDIT_PROFILE_TAB,
        {
          label: "Business Details",
          value: "business-details",
        },
      ]
    : EDIT_PROFILE_TAB;
  return (
    <div style={{ zIndex: 10000, position: "relative", width: "100%", background: "rgba(255,255,255)" }}>
      <Header show={true} />
      <div class="attached-navbar">
        <div class="container flex space-between align-items-bottom">
          <SearchHeaderBreadCrumb
            parent={"editProfile"}
            // type={props?.type}
            params={"profile"}
          />
        </div>
      </div>
      <div className="container">
        <div className="flex tab-navigator-container">
          {DISPLAY_TAB?.map((item) => {
            return (
              <div className="flex m-t-base" key={item?.value}>
                <h2
                  className={`tab-index-text ${selectedTab == item?.value ? "tab-index-selected" : null}`}
                  onClick={() => navigate(`/profile/edit-profile/${item?.value}`)}
                >
                  {item?.label}
                </h2>
              </div>
            );
          })}
        </div>
        <div className="grid-3-1 tablet-1-col edit-profile-padding-right" style={{ background: "rgba(255,255,255)" }}>
          {/* <div className="tablet-hidden"> */}
          {/* <UserFeatureList handleFeatureList={handleFeatureList} featureActiveList={featureActiveList} /> */}
          {/* </div> */}

          <ProfileDetails
            featureActiveList={selectedTab}
            data={ProfileData}
            handleSuccessMessage={(val) => setUpdateStatus(val)}
          />
          {/* <div className="flex">
          <div class="container p-y-md">
            {UpdateStatus ? (
              <div class="modern-message modern-message--positive modern-message--elevated">
                <i class="feather icon icon-check-circle message__icon"></i>
                <div class="message__content">
                  <p class="message__text">{PROFILE?.UPDATE_SUCCESSFUL}</p>
                </div>
              </div>
            ) : null}
            <ProfileDetails
              data={ProfileData}
              handleSuccessMessage={(val) => setUpdateStatus(val)}
            />
          </div>
          <div>
            <ProfilePictureDesktop data={ProfileData} />
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditProfileNew;
