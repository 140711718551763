import React, { useState } from "react";
import Header from "../../../addProposal/component/proposalSubmit/component/header";
import BasicInformationContent from "./basicInformationContent";
import BasicInformationHeader from "./basicInformationHeader";

const BasicInformation = (props) => {
  const [showSection, setShowSection] = useState(false);
  const proposalDetails = props?.proposalDetails;
  const PROPOSAL_TYPE = props?.PROPOSAL_TYPE;
  const USER_CAN_EDIT = props?.USER_CAN_EDIT;
  return (
    <div style={{ width: "100%" }}>
      <BasicInformationHeader
        USER_CAN_EDIT={USER_CAN_EDIT}
        PROPOSAL_TYPE={PROPOSAL_TYPE}
        proposalDetails={proposalDetails}
        handleToggleSection={() => setShowSection(!showSection)}
        showSection={showSection}
        headerTitle={"Basic Information"}
        handleScreenToShow={props?.handleScreenToShow}
        handleDetails={props?.handleDetails}
      />
      {showSection ? (
        <BasicInformationContent
          USER_CAN_EDIT={USER_CAN_EDIT}
          PROPOSAL_TYPE={PROPOSAL_TYPE}
          proposalDetails={proposalDetails}
        />
      ) : null}
    </div>
  );
};

export default BasicInformation;
